import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  IconButton,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  BiDialpad,
  BiHive,
  BiLayer,
  BiNotepad,
  BiSelectMultiple,
} from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';

const Stocktaking: React.FC = () => {
  const stepStyled = {
    color: 'gray.500',
    size: 'sm',
    rounded: 'full',
    w: '10',
    h: '10',
    marginRight: '10',
    _after: {
      content: `""`,
      position: 'absolute',
      top: '45px',
      width: '1px',
      height: '100%',
      bg: 'gray.300',
      opacity: '0.6',
    },
  };
  const activeStepStyled = {
    _active: {
      bg: 'primary',
      color: 'white',
    },
    _after: {
      bg: 'primary',
    },
  };
  const headingStyled = {
    size: 'xs',
    fontSize: 'sm',
    color: 'black',
    fontWeight: 'semibold',
  };
  const infoStyled = {
    fontSize: 'sm',
    color: 'gray.500',
  };
  const inactiveStyled = {
    pointerEvents: 'none',
    opacity: '0.4',
  };
  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.stocktaking}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.mms.stocktaking.stocktakingMode}>
              {strings.stocktaking}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md">{strings.stocktaking}</Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <List>
            <ListItem py="4" sx={activeStepStyled}>
              <Stack direction="row" justifyContent="space-between">
                <Flex direction="row" width="80%">
                  <IconButton
                    sx={stepStyled}
                    variant="outline"
                    aria-label="Send email"
                    icon={<BiHive />}
                  />
                  <Text sx={infoStyled}>
                    {strings.step_1}:
                    <Heading sx={headingStyled}>
                      {strings.freeze_inventory}
                    </Heading>
                    {strings.no_transferring}
                  </Text>
                </Flex>
                <Box width="20%">
                  <Button colorScheme="primary" type="submit" width="100%">
                    {strings.freeze_inventory}
                  </Button>
                </Box>
              </Stack>
            </ListItem>
            <ListItem py="4">
              <Stack direction="row" justifyContent="space-between">
                <Flex direction="row" width="80%">
                  <IconButton
                    sx={stepStyled}
                    variant="outline"
                    aria-label="Send email"
                    icon={<BiLayer />}
                  />
                  <Text sx={infoStyled}>
                    {strings.step_2}:
                    <Heading sx={headingStyled}>
                      {strings.create_inventory_list}
                    </Heading>
                    {strings.download_as}
                  </Text>
                </Flex>
                <Box width="20%">
                  <Button colorScheme="primary" type="submit" width="100%">
                    {strings.download_pdf}
                  </Button>
                </Box>
              </Stack>
            </ListItem>
            <ListItem py="4">
              <Stack direction="row" justifyContent="space-between">
                <Flex direction="row" width="80%">
                  <IconButton
                    sx={stepStyled}
                    variant="outline"
                    aria-label="Send email"
                    icon={<BiSelectMultiple />}
                  />
                  <Text sx={infoStyled}>
                    {strings.step_3}:
                    <Heading sx={headingStyled}>
                      {strings.correct_inventory}
                    </Heading>
                    {strings.transfer_items}
                  </Text>
                </Flex>
                <Box width="20%">
                  <RouterLink to={routes.mms.stocktaking.correctInventory}>
                    <Button colorScheme="primary" type="button" width="100%">
                      {strings.correct_inventory}
                    </Button>
                  </RouterLink>
                </Box>
              </Stack>
            </ListItem>
            <ListItem py="4" sx={inactiveStyled}>
              <Stack direction="row" justifyContent="space-between">
                <Flex direction="row" width="80%">
                  <IconButton
                    sx={stepStyled}
                    variant="outline"
                    aria-label="Send email"
                    icon={<BiNotepad />}
                  />
                  <Text sx={infoStyled}>
                    {strings.step_4}:
                    <Heading sx={headingStyled}>
                      {strings.create_delta_list}
                    </Heading>
                    {strings.what_change}
                  </Text>
                </Flex>
                <Box width="20%">
                  <Button colorScheme="primary" type="submit" width="100%">
                    {strings.download__delta_list_pdf}
                  </Button>
                </Box>
              </Stack>
            </ListItem>
            <ListItem py="4" sx={inactiveStyled}>
              <Stack direction="row" justifyContent="space-between">
                <Flex direction="row" width="80%">
                  <IconButton
                    variant="outline"
                    color="gray.500"
                    size="sm"
                    rounded="full"
                    w="10"
                    h="10"
                    marginRight="10"
                    aria-label="Send email"
                    icon={<BiDialpad />}
                  />
                  <Text sx={infoStyled}>
                    {strings.step_5}:
                    <Heading sx={headingStyled}>
                      {strings.unfreeze_inventory_end}
                    </Heading>
                  </Text>
                </Flex>
                <Box width="20%">
                  <RouterLink to={routes.mms.stocktaking.correctInventory}>
                    <Button colorScheme="primary" type="submit" width="100%">
                      {strings.unfreeze_inventory}
                    </Button>
                  </RouterLink>
                </Box>
              </Stack>
            </ListItem>
          </List>
        </Stack>
      </Stack>
    </>
  );
};
export default Stocktaking;
