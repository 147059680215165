import {
  Box,
  Flex,
  Heading,
  Image,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import FeedbackAnalysisItem from 'pages/dashboard/feedback/Analysis/FeedbackAnalysisItem';
import React, { forwardRef, useEffect, useState } from 'react';
import { DateFormat } from 'utils/DateFormat';

const infoStyles = {
  color: 'gray.900',
  fontWeight: 'normal',
};

interface Props {
  feedbackAnalysis: any;
  ref: any;
  startingSN: number;
  filterParams: any;
}

const FeedbackAnalysisPDF = forwardRef((props: Props, ref: any) => {
  const { feedbackAnalysis, startingSN, filterParams } = props;
  const [showFilterParams, setShowFilterParams] = useState<boolean>(false);

  useEffect(() => {
    const filterParamsFlag =
      filterParams?.keyword ||
      filterParams?.type ||
      filterParams?.apartment_name ||
      filterParams?.start_date ||
      filterParams?.end_date;
    setShowFilterParams(!!filterParamsFlag);
  }, [
    filterParams?.apartment_name,
    filterParams?.end_date,
    filterParams?.keyword,
    filterParams?.start_date,
    filterParams?.type,
  ]);

  const selectQuestionType = (type: any) => {
    const typeName = type.toLowerCase();
    const questionType: { [key: string]: string } = {
      rating: strings.rating,
      open: strings.open_ended,
      closed: strings.closed_ended,
    };
    return questionType[typeName];
  };

  return (
    <Box ref={ref} id="feedback-print">
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <Image src={Logo} width="80px" alt="ListInfo Logo" />
          <Flex direction="column" ml="16px">
            <Heading size="lg" mb="8px" textTransform="capitalize">
              Listinfo Service Center
            </Heading>
            <Heading size="sm">Appartementvermittlung Familie Clausen</Heading>
          </Flex>
        </Flex>
        <Heading
          size="md"
          textTransform="capitalize"
          marginTop="10px"
          marginRight="10px">
          {strings.feedback_analysis}
        </Heading>
      </Flex>
      <Box mt="8px">
        {showFilterParams && (
          <Box id="feedback-filter-block" mb="8px">
            <Box>
              <Text as="span" fontWeight={900} fontSize="18px">
                {strings.filter}
              </Text>
            </Box>

            {filterParams?.keyword && (
              <Box>
                <Text
                  as="span"
                  fontWeight={700}
                  className="filter-keyword-text">
                  {strings.text_search}:
                </Text>
                &ensp;{filterParams?.keyword}
              </Box>
            )}
            {filterParams?.type && (
              <Box>
                <Text
                  as="span"
                  fontWeight={700}
                  className="filter-keyword-text">
                  {strings.type}:
                </Text>
                &ensp;{selectQuestionType(filterParams?.type)}
              </Box>
            )}
            {filterParams?.apartment_name && (
              <Box>
                <Text
                  as="span"
                  fontWeight={700}
                  className="filter-keyword-text">
                  {strings.object}:
                </Text>
                &ensp;{filterParams?.apartment_name}
              </Box>
            )}
            {filterParams?.start_date && (
              <Box>
                <Text
                  as="span"
                  fontWeight={700}
                  className="filter-keyword-text">
                  {strings.date}:
                </Text>
                &ensp;{DateFormat(filterParams?.start_date)}&nbsp;-&nbsp;
                {DateFormat(filterParams?.end_date)}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <TableContainer overflowY="auto">
        <Table
          size="sm"
          style={{
            whiteSpace: 'normal',
          }}>
          <Thead
            style={{
              borderBottom: '1px solid #000',
            }}>
            <Tr>
              <Th color="blackAlpha.900">{strings.sn}</Th>
              <Th color="blackAlpha.900" name="title">
                {strings.title}
              </Th>
              <Th color="blackAlpha.900" name="types">
                {strings.type}
              </Th>
              <Th color="blackAlpha.900" textAlign="center">
                {strings.result}
              </Th>
            </Tr>
          </Thead>
          <Tbody id="feedback-pdf-table-body">
            {feedbackAnalysis?.data?.map((feedbackItem: any, index: number) => {
              return (
                <FeedbackAnalysisItem
                  key={feedbackItem.id}
                  index={startingSN + index}
                  data={feedbackItem}
                />
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
});

FeedbackAnalysisPDF.displayName = 'FeedbackAnalysisPDF';

export default FeedbackAnalysisPDF;
