import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;
class UserDocumentResource extends Resource {
  constructor() {
    super('dms/user-document');
  }

  userGrouplist(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: 'dms/user-document-group',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  getUserList(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: 'dms/user',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  userDetail(id: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: 'dms/user/' + id,
      method: 'get',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }
}

export { UserDocumentResource as default };
