import { FormControl } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import {
  Button,
  Icon,
  InputGroup,
  InputRightElement,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiAddToQueue, BiCalendar, BiTrashAlt } from 'react-icons/bi';
import { formatPrice } from 'utils';
import {
  enumerateDaysBetweenDates,
  getFormattedRangeDate,
} from 'utils/DateFormat';

interface Props {
  service: any;
  selectedBooking: any;
  setExtraServices: any;
  includeDepartureDay: boolean;
  booking?: any;
  setError?: any;
}

const BreadBookingOrderFormEdit: React.FC<Props> = (props) => {
  let {
    service,
    selectedBooking,
    includeDepartureDay,
    setExtraServices,
    booking,
    setError,
  } = props;

  const unitPrice = service?.prices[0]?.unit_price;
  const minDate = moment(selectedBooking?.bookings?.fromdate)
    .add(1, 'days')
    .toDate();
  const maxDate = includeDepartureDay
    ? moment(selectedBooking?.bookings?.tilldate).toDate()
    : moment(selectedBooking?.bookings?.tilldate).subtract(1, 'days').toDate();

  const [additionalService, setAdditionalService] = useState<any>(() => {
    let filteredService = booking?.filter(
      (ele: any) => ele.service_id === service.id
    );
    if (filteredService?.length > 0) {
      return filteredService?.map((ele: any) => ({
        id: ele.id,
        serviceId: service.id,
        name: ele.service_name,
        startDate: moment(ele.from_date).toDate(),
        endDate: moment(ele.till_date).toDate(),
        days: enumerateDaysBetweenDates(
          moment(ele.from_date).toDate(),
          moment(ele.till_date).toDate()
        ),
        quantity: ele.quantity,
        price: ele.total_price,
      }));
    } else {
      return [
        {
          id: `0${Date.now()}`,
          serviceId: service.id,
          name: service.name,
          startDate: minDate,
          endDate: maxDate,
          quantity: 0,
          days: enumerateDaysBetweenDates(minDate, maxDate),
          price: 0,
        },
      ];
    }
  });
  const [bookedDays, setBookedDays] = useState<any>(() => {
    let filteredService = booking?.filter(
      (ele: any) => ele.service_id === service.id
    );
    let uniqueArray2: any = [];
    if (filteredService?.length > 0) {
      let totalDays = filteredService
        .map((ele: any) => {
          let days = enumerateDaysBetweenDates(
            moment(ele.from_date).toDate(),
            moment(ele.till_date).toDate()
          );
          return days;
        })
        .flat();
      let uniqueArray = totalDays.filter(function (item: any, pos: number) {
        return totalDays.indexOf(item) === pos;
      });
      uniqueArray2 = uniqueArray.map((item: any) => new Date(item));
    }
    return uniqueArray2;
  });

  useEffect(() => {
    setExtraServices(additionalService, service.id);
  }, [additionalService, service.id, setExtraServices]);
  useEffect(() => {
    let error = '';
    let filteredBooking = booking?.filter(
      (ele: any) => ele.service_id === service.id
    );
    function granulateBookings(fullBooking: any) {
      let datum: any = [];
      for (let i = 0; i < fullBooking.length; i++) {
        const element = fullBooking[i];
        let formatedDays = element.days.map((ele: any) => {
          return {
            day: ele,
            quantity: element.quantity,
          };
        });
        datum.push(formatedDays);
      }
      datum = datum.flat();
      return datum;
    }

    if (additionalService.length > 0) {
      let alldays = additionalService
        .map((ele: any) => {
          return ele.days;
        })
        .flat();
      let DuplicateDates = alldays.filter((item: any, index: number) => {
        return alldays.indexOf(item) !== index;
      });
      if (DuplicateDates.length > 0) {
        error = strings.multiple_bookings_in_same_date_not_allowed;
        setError(error);
        return;
      }
    }

    if (filteredBooking?.length > 0) {
      let prevBooking = filteredBooking.map((ele: any) => {
        return {
          id: ele.id,
          serviceId: ele.service_id,
          name: ele.service_name,
          startDate: moment(ele.from_date).toDate(),
          endDate: moment(ele.till_date).toDate(),
          days: enumerateDaysBetweenDates(
            moment(ele.from_date).toDate(),
            moment(ele.till_date).toDate()
          ),
          quantity: ele.quantity,
        };
      });
      let granualPrevBooking = granulateBookings(prevBooking);
      let granualBooking = granulateBookings(additionalService);

      for (let i = 0; i < granualPrevBooking.length; i++) {
        const { day, quantity } = granualPrevBooking[i];
        let initDate = new Date();
        let tomorrow = initDate.setDate(initDate.getDate() + 1);
        let currDate: any = new Date(day);
        currDate = currDate.setHours(12); // set time to 12:00
        let isPast = currDate <= tomorrow;
        // can only edit orders for tomorrow or later
        // must edit before 12 PM today for tomorrow's order.
        if (isPast) {
          let newData = granualBooking.find((ele: any) => {
            return ele.day === day;
          });
          let newQuantity = newData?.quantity;
          let isEqual = newQuantity === quantity;
          if (!isEqual) {
            error = strings.cannot_change_quantity_in_past;
            break;
          } else {
            error = '';
          }
        }
      }
      return setError(error);
    }
  }, [additionalService, booking, service.id, setError]);

  const handleDateChange = (dates: [Date, Date], formID: any) => {
    const [start, end] = dates;

    let dayList = enumerateDaysBetweenDates(start, end);

    if (formID) {
      let LsingleService = additionalService.find(
        (item: any) => item.id === formID
      );
      let Linfo = [...additionalService];
      let Lindex = additionalService.findIndex(
        (item: any) => item.id === formID
      );
      Linfo[Lindex] = {
        ...LsingleService,
        startDate: start,
        endDate: end,
        days: dayList,
      };

      setAdditionalService(Linfo);

      let DAT = Linfo.map((day: any) => day.days).flat();
      let uniqueArray = DAT.filter(function (item: any, pos: number) {
        return DAT.indexOf(item) === pos;
      });
      let uniqueArray2 = uniqueArray.map((item: any) => new Date(item));
      setBookedDays(uniqueArray2);
    }
  };

  const onQuantityChange = (e: any, formId: any) => {
    let qty = Number(e.target.value);
    if (qty < 0) {
      return;
    }
    if (formId) {
      let LsingleService = additionalService.find(
        (item: any) => item.id === formId
      );
      let Linfo = [...additionalService];
      let Lindex = Linfo.findIndex((item: any) => item.id === formId);
      let Ldays = LsingleService?.days?.length;

      Linfo[Lindex] = {
        ...LsingleService,
        quantity: qty,
        price: qty * unitPrice * Ldays,
      };
      setAdditionalService(Linfo);
      return;
    }
  };
  const onAdditionalServicePlus = () => {
    let index = additionalService.length;
    let nwService = {
      id: `${index}${Date.now()}`,
      serviceId: service.id,
      name: service.name,
      booking_fee: 2,
      startDate: null,
      endDate: null,
      quantity: 0,
      days: [],
      price: 0,
    };
    setAdditionalService([...additionalService, nwService]);
  };
  const onAdditionalServiceMinus = (id: string) => {
    let index = additionalService.findIndex((item: any) => item.id === id);
    let newService = [...additionalService];
    newService.splice(index, 1);

    let alldays = newService
      .map((ele: any) => {
        return ele.days;
      })
      .flat();
    let uniqueDays = alldays.filter((item, index) => {
      return alldays.indexOf(item) === index;
    });
    let uniqueDays2 = uniqueDays.map((item: any) => new Date(item));

    setBookedDays(uniqueDays2);
    setAdditionalService(newService);
  };
  function checkForInvalidDates(days: Date[]) {
    let isPast = false;
    let initDate = new Date();
    initDate.setDate(initDate.getDate() + 1);
    let tomorrow = initDate;
    for (let i = 0; i < days.length; i++) {
      let currDate: any = new Date(days[i]);
      currDate = currDate.setHours(12); // set time to 12:00
      // can only edit orders for tomorrow or later
      // must edit before 12 PM today for tomorrow's order.
      if (currDate <= tomorrow) {
        isPast = true;
        break;
      }
    }
    return isPast;
  }

  return (
    <>
      {additionalService.length > 0 &&
        additionalService.map((item: any, index: number) => (
          <Tr key={item.id}>
            <Td>{item.name}</Td>
            <Td>
              <FormControl>
                <InputGroup>
                  <InputRightElement
                    children={<BiCalendar />}
                    color="gray.400"
                  />
                  <ReactDatePicker
                    placeholderText={strings.select_date}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomDateInput />}
                    onChange={(data: any) => handleDateChange(data, item.id)}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    highlightDates={bookedDays}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectsRange
                    value={getFormattedRangeDate(item.startDate, item.endDate)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
            </Td>
            <Td isNumeric>
              <FormControl>
                <Input
                  type="number"
                  min={0}
                  placeholder={strings.quantity}
                  disabled={checkForInvalidDates(item.days)}
                  onChange={(e) => onQuantityChange(e, item.id)}
                  value={item.quantity}
                />
              </FormControl>
            </Td>
            <Td isNumeric>
              <Text>{formatPrice(unitPrice)} &euro;</Text>
            </Td>
            <Td isNumeric>
              <Text>
                {formatPrice(item.quantity * unitPrice * item.days.length)}
                &nbsp;&euro;
              </Text>
            </Td>
            <Td>
              {index === 0 ? (
                <Button
                  type="button"
                  variant="link"
                  p={3}
                  color="gray.800"
                  justifyContent="flex-start"
                  onClick={() => onAdditionalServicePlus()}>
                  <Icon as={BiAddToQueue} w="4" h="4" mr={2} />
                  <Text>{strings.add_new_service}</Text>
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="link"
                  p={3}
                  color="gray.800"
                  justifyContent="flex-start"
                  disabled={checkForInvalidDates(item.days)}
                  onClick={() => onAdditionalServiceMinus(item.id)}>
                  <Icon as={BiTrashAlt} w="4" h="4" mr={2} />
                  <Text>{strings.delete}</Text>
                </Button>
              )}
            </Td>
          </Tr>
        ))}
    </>
  );
};

export default BreadBookingOrderFormEdit;
