import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import KeyAPI from 'api/key';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import EditKeyForm from 'pages/dashboard/Key/KeyList/EditKeyForm';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQuery } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

const EditKey: React.FC = () => {
  const { id }: { id: string } = useParams();
  const keyAPI = new KeyAPI();
  const toast = useToast();
  const history = useHistory();
  const { search } = useLocation();

  const methods = useForm<any>();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const [errorMessage, setErrorMessage] = useState('');

  const { data: keyDetails } = useQuery([`keyDetail`], async () => {
    return await keyAPI.get(id).then((res) => res.data.data);
  });

  const keyService = useMutation(
    (data: any) => keyAPI.update(keyDetails?.id, data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.key_updated_successfully,
          status: 'success',
          isClosable: true,
        });
        history.push(
          routes.key.keyList.view.replace(':id', res.data.data.rfid)
        );
      },
      onError: () => {
        setErrorMessage(strings.key_updated_error);
      },
    }
  );

  const onSubmit = (data: any) => {
    const formData = { ...data };
    formData.apartmentId = data.apartmentId.value;

    keyService.mutate(formData);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>KMS | {strings.edit_key}</title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.key.keyList.list + search}>
              {strings.key_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{keyDetails?.rfid}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.edit_key}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {keyService.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                    {!errorMessage && strings.notification_creation_failed}
                  </Alert>
                )}

                <EditKeyForm keyDetails={keyDetails} />
                <ButtonGroup>
                  <Button colorScheme="primary" type="submit">
                    {strings.save_now}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditKey;
