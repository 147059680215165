import {
  Avatar,
  Box,
  Flex,
  Icon,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { getTag } from 'components/common/PriorityTag';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ResponsibleType, TICKET_TYPE } from 'constants/schema';
import React from 'react';
import { BiBuildingHouse, BiEuro, BiRotateLeft } from 'react-icons/bi';
import { BsBuilding } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { truncateString } from 'utils';
import { DateFormat, DateTimeFormatToUTC } from 'utils/DateFormat';

interface Props {
  ticketId: number;
  title: string;
  appointment_date: string;
  object: string;
  assignedUserStatus: boolean;
  assignedUserName: string;
  external_company?: { id: number; name: string };
  recursive: string;
  billable: boolean;
  priority: TICKET_TYPE;
  avatar: string;
  dueDate: string;
  search?: any;
  personInCharge: ResponsibleType | null;
  substitutePersonInCharge: ResponsibleType | null;
  linkTarget?: '_blank' | '_self';
}

const TaskListItem: React.FC<Props> = (props) => {
  const {
    ticketId,
    assignedUserStatus,
    assignedUserName,
    external_company,
    title,
    appointment_date,
    object,
    recursive,
    billable,
    priority,
    avatar,
    dueDate,
    search,
    personInCharge,
    substitutePersonInCharge,
    linkTarget = '_self',
  } = props;

  const responsiblePerson = personInCharge || substitutePersonInCharge;

  return (
    <Box
      border="1px"
      borderColor="gray.300"
      p="2"
      position="relative"
      rounded="sm">
      <Stack direction="row" spacing="3">
        <Stack spcing="2" flex="1">
          <Stack direction="row">
            <Link
              target={linkTarget}
              to={
                routes.ticket.task.view.replace(':id', ticketId.toString()) +
                (search ?? '')
              }>
              <Tooltip hasArrow label={title} fontSize="xs">
                <Text
                  color="gray.600"
                  fontWeight="medium"
                  _hover={{ color: 'blue.300' }}>
                  {truncateString(title, 20)}
                </Text>
              </Tooltip>
            </Link>

            <Spacer />
            <Flex>
              {recursive && (
                <Icon
                  as={BiRotateLeft}
                  title={strings.recursive_task}
                  w="4"
                  h="4"
                  color="orange.400"
                  _hover={{ cursor: 'pointer' }}
                />
              )}

              {billable && (
                <Icon
                  as={BiEuro}
                  title={strings.billable}
                  w="4"
                  h="4"
                  color="blue.400"
                  _hover={{ cursor: 'pointer' }}
                />
              )}
              <Tooltip hasArrow label={strings.due_date} fontSize="xs">
                <Text
                  fontSize="12px"
                  fontWeight="normal"
                  color="gray.400"
                  ml="2">
                  {DateFormat(dueDate)}
                </Text>
              </Tooltip>
            </Flex>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end">
            <Stack direction="column" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                {assignedUserName && (
                  <>
                    <Avatar src={avatar} w="4" h="4" />
                    <Tooltip hasArrow label={strings.assignee} fontSize="xs">
                      <Text fontSize="xs" fontWeight="normal" color="gray.400">
                        <CustomTagTitle
                          status={assignedUserStatus}
                          name={assignedUserName}
                        />
                      </Text>
                    </Tooltip>
                  </>
                )}
                {/* <Spacer /> */}
              </Stack>
              {external_company && (
                <Flex
                  title={external_company?.name}
                  _hover={{ cursor: 'pointer' }}>
                  <Icon as={BsBuilding} w="4" h="4" color="gray.400" />
                  <Tooltip
                    hasArrow
                    label={strings.external_company}
                    fontSize="xs">
                    <Text
                      fontSize="xs"
                      fontWeight="light"
                      color="gray.500"
                      ml="2">
                      {truncateString(external_company?.name, 10)}
                    </Text>
                  </Tooltip>
                </Flex>
              )}
            </Stack>
            <Flex
              flexDir="column"
              justifyContent="space-between"
              alignItems="center"
              height="100%">
              {object && (
                <Flex
                  title={object}
                  _hover={{ cursor: 'pointer' }}
                  style={{ marginLeft: 'auto' }}>
                  <Icon as={BiBuildingHouse} w="4" h="4" color="gray.400" />
                  <Tooltip hasArrow label={strings.object} fontSize="xs">
                    <Text
                      fontSize="xs"
                      fontWeight="light"
                      color="gray.500"
                      ml="1">
                      {truncateString(object, 20)}
                    </Text>
                  </Tooltip>
                </Flex>
              )}
              {responsiblePerson && (
                <Flex
                  title={responsiblePerson.name}
                  _hover={{ cursor: 'pointer' }}
                  style={{ marginLeft: 'auto' }}>
                  <Tooltip
                    hasArrow
                    label={strings.person_in_charge}
                    fontSize="xs">
                    <Text
                      fontSize="xs"
                      fontWeight="light"
                      color="gray.500"
                      ml="1">
                      ({truncateString(responsiblePerson.name, 18)})
                    </Text>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          </Stack>
          <Box borderBottom="1px" borderColor="gray.100"></Box>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Text
                fontSize="0.65rem"
                fontWeight="normal"
                color="gray.400"
                lineHeight={1}>
                {appointment_date ? strings.appointment : ''}
              </Text>
              <Text
                fontSize="sm"
                fontWeight="normal"
                color="gray.400"
                alignSelf="left"
                lineHeight={1}
                marginTop="0.1rem!important">
                {DateTimeFormatToUTC(appointment_date)}
              </Text>
            </Stack>
            <Tooltip hasArrow label={strings.priority} fontSize="xs">
              <Flex alignSelf="right">{getTag(priority)}</Flex>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TaskListItem;
