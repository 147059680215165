import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ObjectDocumentResource from 'api/object_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import DMSDocumentSearch from 'components/dms/DMSDocumentSearch';
import ObjectDocumentListItem from 'components/object_document/ObjectDocumentListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import {
  DataWrapperSchema,
  DMSDocumentFilterParams,
  DMSObjectDocumentSchema,
} from 'constants/schema';
import React, { useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCloudUpload } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';

const DMSObjectList: React.FC = () => {
  const { id }: any = useParams();
  let objectDocumentAPI = new ObjectDocumentResource();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const createdAt = Number(searchValues.created_at);
  const availableFromDate = Number(searchValues.available_from_date);
  const availableToDate = Number(searchValues.available_to_date);

  const [filterParams, setFilterParams] = useState<DMSDocumentFilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    created_at: createdAt ? new Date(createdAt) : null,
    available_from_date: availableFromDate ? new Date(availableFromDate) : null,
    available_to_date: availableToDate ? new Date(availableToDate) : null,
  });

  const objectDocumentList = useQuery<
    DataWrapperSchema<DMSObjectDocumentSchema[]>
  >(
    [
      'objectDocumentList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        created_at: filterParams.created_at,
        available_from_date: filterParams.available_from_date,
        available_to_date: filterParams.available_to_date,
      },
    ],
    async () => {
      const { keyword, created_at, available_from_date, available_to_date } =
        filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        apartment_id: id,
      };
      if (keyword) queryParams.keyword = keyword;
      if (created_at) queryParams.created_at = DateFormatYMD(created_at);
      if (available_from_date)
        queryParams.available_from_date = DateFormatYMD(available_from_date);
      if (available_to_date)
        queryParams.available_to_date = DateFormatYMD(available_to_date);

      const response = await objectDocumentAPI.list(queryParams);
      return response?.data;
    }
  );

  const objectDocumentQuery = useQuery([`objectDocument${id}`], () =>
    objectDocumentAPI.objectDetail(id).then((res) => res.data.data)
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.dms} | {objectDocumentQuery?.data?.name ?? ' '}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.objectDocuments.list + search}>
              {strings.document_objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{objectDocumentQuery?.data?.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.object_documents}
          </Heading>
          <RouterLink
            to={routes.dms.objectDocuments.objects.upload.replace(
              ':id',
              id.toString()
            )}>
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
              size="sm">
              {strings.upload_document}
            </Button>
          </RouterLink>
        </Flex>
        <Stack>
          <DMSDocumentSearch
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.document_name}</Th>
                  <Th>{strings.upload_date}</Th>
                  <Th>{strings.from}</Th>
                  <Th>{strings.until}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!objectDocumentList.isLoading &&
                  objectDocumentList?.data?.data?.map(
                    (objectDocumentData, index: number) => (
                      <ObjectDocumentListItem
                        key={objectDocumentData.id}
                        index={startingSN + index}
                        objectDocumentData={objectDocumentData}
                      />
                    )
                  )}
                {objectDocumentList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={objectDocumentList}
        />
      </Stack>
    </>
  );
};

export default DMSObjectList;
