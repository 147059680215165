import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useRadioGroup,
} from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CustomRadio from 'components/common/CustomRadio';
import { strings } from 'config/localization';
import { STOCK_ACTIVITIES_ENTITIES } from 'constants/common';
import { TransferItemSchema } from 'constants/schema';
import useMMSUserAndWarehouseInfo from 'hooks/query-hooks/useMMSUserAndWarehouseInfo';
import React, { useEffect, useMemo } from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';

const { STOCK_ACTIVITY_ACTIONS, WAREHOUSE_SHELVES, USERS } =
  STOCK_ACTIVITIES_ENTITIES;

function DestinationInput({
  control,
}: {
  control: Control<TransferItemSchema>;
}) {
  const {
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useFormContext<TransferItemSchema>();

  const sourceEntityType = useWatch({
    control,
    name: 'source_entity_type',
  });

  const destinationEntityType = useWatch({
    control,
    name: 'destination_entity_type',
  });

  // Reset destination dropdown along with room, rack and shelf
  // when user changes the destination radio buttons
  useEffect(() => {
    reset({
      ...getValues(),
      destination_entity_id: null,
      room_id: null,
      rack_id: null,
      shelf_id: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationEntityType]);

  const { warehouses, users } = useMMSUserAndWarehouseInfo();

  const destinationOptions = useMemo(() => {
    // Warehouse options if warehouse is selected
    const warehouseOptions = warehouses?.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    const userOptions = users?.map((item) => ({
      label: `${item.name}`,
      value: item.id,
    }));

    // Decide which options to display
    switch (destinationEntityType) {
      case STOCK_ACTIVITY_ACTIONS:
        return [];
      case WAREHOUSE_SHELVES:
        return warehouseOptions;
      case USERS:
        return userOptions;
    }
  }, [destinationEntityType, warehouses, users]);

  // Source Entity (dropdown) is not required when type is `New/Fresh`
  const isDestinationEntityRequired =
    destinationEntityType !== STOCK_ACTIVITY_ACTIONS;
  const isInputError = !!(
    errors?.destination_entity_type || errors?.destination_entity_id
  );

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'SchemeTypeRadio',
    defaultValue: 'STOCK_ACTIVITY_ACTIONS',
    onChange: (destinationEntityType) => {
      setValue('destination_entity_type', destinationEntityType);
    },
    value: destinationEntityType,
  });

  const group = getRootProps();
  return (
    <FormControl isRequired>
      <FormLabel>{strings.destination}</FormLabel>
      <Stack direction="row" mb="2" tabIndex={5}>
        <Flex {...group}>
          <CustomRadio
            type={'radioNav'}
            {...getRadioProps({ value: STOCK_ACTIVITY_ACTIONS })}
            isDisabled={sourceEntityType === STOCK_ACTIVITY_ACTIONS}>
            {strings.consumed}
          </CustomRadio>
          <CustomRadio
            type={'radioNav'}
            {...getRadioProps({ value: WAREHOUSE_SHELVES })}>
            {strings.warehouse}
          </CustomRadio>
          <CustomRadio type={'radioNav'} {...getRadioProps({ value: USERS })}>
            {strings.employee}
          </CustomRadio>
        </Flex>
      </Stack>
      <FormControl
        isInvalid={isInputError}
        isRequired={isDestinationEntityRequired}>
        <Controller
          control={control}
          name="destination_entity_id"
          rules={{
            required: isDestinationEntityRequired
              ? strings.required_destination
              : false,
          }}
          render={({ field }) => (
            <ReactSelect
              {...field}
              placeholder={strings.select}
              styles={reactSelectStyles}
              options={destinationOptions}
              isSearchable
              tabIndex="6"
              isDisabled={destinationEntityType === STOCK_ACTIVITY_ACTIONS}
              required
            />
          )}
        />
        <FormErrorMessage>
          {isInputError && strings.required_destination}
        </FormErrorMessage>
      </FormControl>
    </FormControl>
  );
}

export default DestinationInput;
