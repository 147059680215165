import http from 'utils/http';
import Resource from './resource';

class RMSDashObjectsResource extends Resource {
  constructor() {
    super(`rms/dashboard`);
  }
  dashboardObjectInfo(id: number) {
    return http({
      url: `${this.uri}/apartment/${id}`,
      method: 'GET',
    });
  }
  dashboardObjectReservations(id: number, query: any = {}) {
    return http({
      url: `rms/dashboard/apartment-reservations/${id}`,
      method: 'GET',
      params: query,
    });
  }
  dashboardCustomerReservations(id: number, query: any = {}) {
    return http({
      url: `rms/dashboard/customer-bookings/${id}`,
      method: 'GET',
      params: query,
    });
  }

  dashboardOwnerObjects(v_office_id: number) {
    return http({
      url: `${this.uri}/owner-apartments`,
      method: 'GET',
      params: { v_office_id },
    });
  }
}

export { RMSDashObjectsResource as default };
