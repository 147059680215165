import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FerryBookingResource from 'api/ferry_booking';
import { wrapperStyles } from 'assets/css/commonStyles';
import FerryBookingListItem from 'components/bms_ferry_booking/FerryBookingListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  BREAD_BOOKING_STATUS_OPTIONS,
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import { FerryBookingSchema } from 'constants/schema';
import { BookingStatus } from 'constants/schemas/reservationBooking';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  status: string;
  keyword: string;
}

const FerryBookingList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    status: searchValues.status ?? '',
    keyword: searchValues.keyword ?? '',
  });
  const ferryBookingAPI = new FerryBookingResource();

  const ferryBookingList = useQuery(
    [
      'ferryBookingList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: filterParams.status,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await ferryBookingAPI.list(queryParams);
      return response?.data;
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      status: '',
      keyword: '',
    }));
    history.push(routes.bms.ferryBooking.list);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bms} | {strings.all_bookings}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.bms.ferryBooking.list}>
              {strings.all_bookings}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.ferry_bookings}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="keyword"
                    value={filterParams.keyword}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.status}</FormLabel>
                <Select
                  name="status"
                  value={filterParams.status}
                  placeholder={strings.select}
                  onChange={handleInputChange}
                  rounded="sm">
                  {BREAD_BOOKING_STATUS_OPTIONS?.map((item: BookingStatus) => {
                    return (
                      <option key={item} value={item}>
                        {strings[item]}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.booking_number}</Th>
                  <Th>{strings.booking_date}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.actions}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!ferryBookingList.isLoading &&
                  ferryBookingList?.data?.data?.map(
                    (ferryBookingData: FerryBookingSchema, index: number) => (
                      <FerryBookingListItem
                        ferryBookingData={ferryBookingData}
                        key={ferryBookingData.id}
                        index={startingSN + index}
                        search={search}
                      />
                    )
                  )}
                {ferryBookingList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          dataList={ferryBookingList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default FerryBookingList;
