import { Icon, RadioGroup, Table, Tbody, Td, Th } from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import 'react-perfect-scrollbar/dist/css/styles.css';
import LabelTags from './LabelTags';

interface Props {
  data?: any;
}
const MergeHistoryDetails: React.FC<Props> = (props) => {
  let { data } = props;
  const [defaults, setDefaults] = useState<any>({});
  useEffect(() => {
    let df: any = {};
    if (data) {
      data.forEach((ele: any) => {
        let col = Object.entries(ele.merged_columns);
        col.forEach(([key, val]) => {
          df[key] = ele.id;
        });
      });
      setDefaults(df);
    }
  }, [data]);

  if (!data) return null;

  return (
    <>
      <Table bg="white" p="4" shadow="box" rounded="sm">
        <Tbody>
          <RadioGroup as="tr" value={defaults.first_name}>
            <Th>{strings.first_name}</Th>
            {data.map(({ id, contact: { first_name } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.first_name && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {first_name ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.last_name}>
            <Th>{strings.last_name}</Th>

            {data.map(({ id, contact: { last_name } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.last_name && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {last_name ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.type}>
            <Th>{strings.label}</Th>

            {data.map(({ id, contact: { type } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.type && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                <LabelTags label={type} />
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.source}>
            <Th>{strings.source}</Th>

            {data.map(
              ({
                id,
                contact: { source },
              }: {
                id: number;
                contact: { source: 'manual' | 'v-office' };
              }) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  {id === defaults.source && (
                    <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                  )}
                  {strings[source]}
                </Td>
              )
            )}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.location}>
            <Th>{strings.city}</Th>

            {data.map(({ id, contact: { location } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.location && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {location ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.street}>
            <Th>{strings.street}</Th>

            {data.map(({ id, contact: { street } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.street && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {street ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.house_no}>
            <Th>{strings.house_no}</Th>

            {data.map(({ id, contact: { house_no } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.house_no && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {house_no ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.zip_code}>
            <Th>{strings.post_code}</Th>

            {data.map(({ id, contact: { zip_code } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.zip_code && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {zip_code ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.country}>
            <Th>{strings.country}</Th>

            {data.map(({ id, contact: { country } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.country && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {country ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.phone}>
            <Th>{strings.phone_number}</Th>

            {data.map(({ id, contact: { phone } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.phone && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {phone ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.mobile}>
            <Th>{strings.mobile_number}</Th>

            {data.map(({ id, contact: { mobile } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.mobile && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {mobile ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.email}>
            <Th>{strings.email}</Th>

            {data.map(({ id, contact: { email } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.email && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {email ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.company}>
            <Th>{strings.company}</Th>

            {data.map(({ id, contact: { company } }: any) => (
              <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                {id === defaults.company && (
                  <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                )}
                {company ?? '-'}
              </Td>
            ))}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.fellow_travellers}>
            <Th>{strings.fellow_travelers}</Th>

            {data.map(
              ({
                id,
                contact: {
                  contactDetail: { fellow_travellers },
                },
              }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  {id === defaults.fellow_travellers && (
                    <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                  )}
                  {fellow_travellers ?? '-'}
                </Td>
              )
            )}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.pet}>
            <Th>{strings.pets}</Th>

            {data.map(
              ({
                id,
                contact: {
                  contactDetail: { pet },
                },
              }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  {id === defaults.pet && (
                    <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                  )}
                  {pet ?? '-'}
                </Td>
              )
            )}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.equipment_features}>
            <Th>{strings.equipment_features}</Th>

            {data.map(
              ({
                id,
                contact: {
                  contactDetail: { equipment_features },
                },
              }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  {id === defaults.equipment_features && (
                    <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                  )}
                  {equipment_features ?? '-'}
                </Td>
              )
            )}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.services}>
            <Th>{strings.services}</Th>

            {data.map(
              ({
                id,
                contact: {
                  contactDetail: { services },
                },
              }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  {id === defaults.services && (
                    <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                  )}
                  {services ?? '-'}
                </Td>
              )
            )}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.external_notes}>
            <Th>{strings.notes_external}</Th>

            {data.map(
              ({
                id,
                contact: {
                  contactDetail: { external_notes },
                },
              }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  {id === defaults.external_notes && (
                    <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                  )}
                  {external_notes ?? '-'}
                </Td>
              )
            )}
          </RadioGroup>
          <RadioGroup as="tr" value={defaults.internal_notes}>
            <Th>{strings.notes_internal}</Th>

            {data.map(
              ({
                id,
                contact: {
                  contactDetail: { internal_notes },
                },
              }: any) => (
                <Td key={id} role="group" _hover={{ bg: 'gray.100' }}>
                  {id === defaults.internal_notes && (
                    <Icon as={IoIosCheckmarkCircle} mr={2} color="gray.400" />
                  )}
                  {internal_notes ?? '-'}
                </Td>
              )
            )}
          </RadioGroup>
        </Tbody>
      </Table>
    </>
  );
};

export default MergeHistoryDetails;
