import {
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabPanelsProps,
  TabProps,
  Tabs,
  TabsProps,
} from '@chakra-ui/tabs';
import React from 'react';

const CustomTabs = ({ children, ...rest }: TabsProps) => {
  return (
    <Tabs variant="unstyled" {...rest}>
      {children}
    </Tabs>
  );
};

const CustomTabList = ({ children, ...rest }: TabListProps) => {
  return (
    <TabList flexWrap="wrap" gridGap="2" {...rest}>
      {children}
    </TabList>
  );
};

const CustomTab = ({ children, ...rest }: TabProps) => {
  return (
    <Tab
      fontSize="sm"
      p={2}
      lineHeight="5px"
      borderWidth="1px"
      borderRadius="full"
      boxShadow="md"
      _focus={{
        boxShadow: 'outline',
      }}
      _selected={{
        bg: 'primary.600',
        color: 'white',
        borderColor: 'primary.600',
      }}
      {...rest}>
      {children}
    </Tab>
  );
};

const CustomTabPanels = ({ children, ...rest }: TabPanelsProps) => {
  return <TabPanels {...rest}>{children}</TabPanels>;
};

const CustomTabPanel = ({ children, ...rest }: TabPanelProps) => {
  return (
    <TabPanel p={0} {...rest}>
      {children}
    </TabPanel>
  );
};

CustomTabs.TabList = CustomTabList;
CustomTabs.Tab = CustomTab;
CustomTabs.TabPanels = CustomTabPanels;
CustomTabs.TabPanel = CustomTabPanel;

export default CustomTabs;
