import {
  AspectRatio,
  GridItem,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  image: string;
  bgColor: string;
  link: string;
}

const titleStyled = {
  color: 'white',
  align: 'center',
  textAlign: 'center',
  fontSize: [16, 16, 20],
};

const QRItemList: React.FC<Props> = (props) => {
  const { title, image, bgColor, link } = props;

  return (
    <Tooltip hasArrow label={title}>
      <Link to={link}>
        <AspectRatio ratio={1}>
          <GridItem backgroundColor={bgColor}>
            <Stack spacing={[2, 4]} alignItems="center" maxWidth="30%">
              <Image src={image} w="auto" h="24"></Image>
              <Text sx={titleStyled}>{title}</Text>
            </Stack>
          </GridItem>
        </AspectRatio>
      </Link>
    </Tooltip>
  );
};

export default QRItemList;
