import {
  Avatar,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { UserLoginLogSchema } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { utcToLocal } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const UserLoginLogList: React.FC = () => {
  const userAPI = new UserResource();
  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
  });

  const loginLogList = useQuery(
    [
      'loginLogList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await userAPI.loginLogList(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.user} | {strings.all_login_logs}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.all_login_logs}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.user_login_log}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          align={['start', 'end']}>
          <FormControl>
            <FormLabel>{strings.search}</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BiSearch />}
                color="gray.400"
              />
              <Input
                type="text"
                name="keyword"
                value={filterParams.keyword}
                onChange={handleInputChange}
              />
            </InputGroup>
          </FormControl>

          <Button colorScheme="primary" variant="outline" onClick={handleReset}>
            {strings.reset_filter}
          </Button>
        </Stack>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.date_time}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loginLogList.isLoading &&
                loginLogList?.data?.data?.map(
                  (userData: UserLoginLogSchema, index: number) => (
                    <Tr key={index}>
                      <Td>{startingSN + index}</Td>
                      <Td>
                        <HStack>
                          <Avatar
                            size="sm"
                            rounded="full"
                            src={userData.user.profile_picture_url}
                          />
                          <Text
                            fontWeight="medium"
                            color="gray.500"
                            _hover={{ color: 'gray.600' }}>
                            {userData.user.first_name}&nbsp;
                            {userData.user.last_name}
                          </Text>
                        </HStack>
                      </Td>
                      <Td>{strings[userData.status] ?? '-'}</Td>
                      <Td>
                        {utcToLocal(userData.created_at).replace(' ', ', ')}
                      </Td>
                    </Tr>
                  )
                )}
              {loginLogList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={loginLogList}
      />
    </Stack>
  );
};

export default UserLoginLogList;
