import http from 'utils/http';
import Resource from './resource';

class ThirdPartyPartnerResource extends Resource {
  constructor() {
    super('rms/third-party-partner');
  }

  getAll() {
    return http({
      url: 'rms/third-party-partner-all',
      method: 'get',
    });
  }

  update(id: any, resource: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'post',
      data: resource,
    });
  }
}

export { ThirdPartyPartnerResource as default };
