export enum FeedbackActionTypes {
  SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA',
  SET_APARTMENT_DATA = 'SET_APARTMENT_DATA',
}

export interface FeedbackAction {
  data: any;
  type: FeedbackActionTypes;
}

/**
 * Action to set customer data.
 * @param {object} customer
 * @returns {object}
 */
export function setCustomerData(customer: any): FeedbackAction {
  return {
    data: customer,
    type: FeedbackActionTypes.SET_CUSTOMER_DATA,
  };
}

/**
 * Action to 	set apartment and owner data.
 * @param {object} apartment
 * @returns {object}
 */
export function setApartmentData(apartment: any): FeedbackAction {
  return {
    data: apartment,
    type: FeedbackActionTypes.SET_APARTMENT_DATA,
  };
}
