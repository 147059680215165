import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import BookingTerminalResource from 'api/booking_terminal';
import { wrapperStyles } from 'assets/css/commonStyles';
import CheckInOutListItem from 'components/bms_onboarding/CheckInOutListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import {
  CheckInOutFilterParams,
  CheckInOutListSchema,
  DataWrapperSchema,
} from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import CheckInOutSearch from './CheckInOutSearch';

const CheckOutList: React.FC = () => {
  const BookingTerminalAPI = new BookingTerminalResource();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];
  const defaultIndex = defaultIndexIdentifier(searchValues);

  const toDate = Number(searchValues.date);

  const [filterParams, setFilterParams] = useState<CheckInOutFilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword || '',
    date: toDate ? new Date(toDate) : new Date(),
  });

  const checkOutQuery = useQuery(['checkOutList', filterParams], async () => {
    let { keyword, date } = filterParams;
    const queryParams: any = {
      page: filterParams.currentPage,
      limit: filterParams.pageSize,
    };
    if (keyword) queryParams.keyword = keyword;
    if (date) queryParams.to_date = DateFormatYMD(date);

    const res = await BookingTerminalAPI.getCheckOutList(queryParams);
    return res.data as DataWrapperSchema<CheckInOutListSchema[]>;
  });

  const { data: checkOutList, isLoading } = checkOutQuery;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterParams((prev) => {
      return { ...prev, currentPage: INITIAL_CURRENT_PAGE, [name]: value };
    });
  };

  const handleDateChange = (date: Date) => {
    setFilterParams((prev) => {
      return { ...prev, currentPage: INITIAL_CURRENT_PAGE, date: date };
    });
  };

  const handleReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      date: new Date(),
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.date) data.date = Date.parse(data.date);

    const searchURL = changeURL(data);
    history.push(`?tab=checkout&${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack spacing="4">
      <CheckInOutSearch
        defaultAccordionIndex={defaultIndex}
        filterParams={filterParams}
        handleDateChange={handleDateChange}
        handleInputChange={handleInputChange}
        handleReset={handleReset}
        dateLabel={strings.until}
      />
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.booking_number}</Th>
                <Th>{strings.customer_name}</Th>
                <Th>{strings.apartment}</Th>
                <Th>{strings.from}</Th>
                <Th>{strings.until}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {checkOutList?.data?.map((data, index: number) => (
                <CheckInOutListItem
                  key={data.reservation_id}
                  index={startingSN + index}
                  data={data}
                  isCheckIn={false}
                />
              ))}
              {isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={checkOutQuery}
      />
    </Stack>
  );
};

export default CheckOutList;
