import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import RestaurantReservationResource from 'api/restaurant_reservation';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { TOAST_DURATION, TOAST_STATUS } from 'constants/common';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

const RestaurantReservationDetails: React.FC = () => {
  const { id: restaurantReservationId } = useParams<{ id: string }>();

  let restaurantReservationAPI = new RestaurantReservationResource();
  const toast = useToast();

  const { data: restaurantReservationDetail, isLoading } = useQuery(
    ['restaurantReservation-details-page', restaurantReservationId],
    () => {
      if (!restaurantReservationId) return null; // { data: null };
      return restaurantReservationAPI
        .get(restaurantReservationId)
        .then((res) => res.data.data)
        .catch(() => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: TOAST_STATUS.ERROR,
            duration: TOAST_DURATION.LONG,
            isClosable: true,
          });
        });
    }
  );

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.restaurant_reservation} | &nbsp;
            {strings.restaurant_reservation_detail}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.restaurant.restaurantReservation.list}>
              {strings.restaurant_reservation}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.restaurant_reservation_detail}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex>
          <Heading size="md" textTransform="capitalize">
            {strings.restaurant_reservation_detail}{' '}
            {restaurantReservationDetail?.name}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Grid gap="4" templateColumns={['repeat(4, 1fr)']} w="100%">
            <GridItem>
              <FormControl>
                <FormLabel>{strings.booking_number}</FormLabel>
                <Box>{restaurantReservationDetail?.booking_number}</Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel>{strings.restaurant}</FormLabel>
                <Box>
                  <RouterLink
                    to={routes.restaurant.restaurant.details.replace(
                      ':id',
                      restaurantReservationDetail?.restaurant?.id.toString()
                    )}>
                    {restaurantReservationDetail?.restaurant?.name}
                  </RouterLink>
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.salutation}</FormLabel>
                <Box>{restaurantReservationDetail?.salutation}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.title}</FormLabel>
                <Box>{restaurantReservationDetail?.title || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.first_name}</FormLabel>
                <Box>{restaurantReservationDetail?.first_name}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.last_name}</FormLabel>
                <Box>{restaurantReservationDetail?.last_name}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.email}</FormLabel>
                <Box>{restaurantReservationDetail?.email || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.phone_number}</FormLabel>
                <Box>{restaurantReservationDetail?.phone_number || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>{strings.no_of_people}</FormLabel>
                <Box>{restaurantReservationDetail?.no_of_people || '-'}</Box>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{strings.booking_date}</FormLabel>
                <Box>{restaurantReservationDetail?.booking_date}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.booking_time}</FormLabel>
                <Box>{restaurantReservationDetail?.booking_time}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.alternative_booking_date}</FormLabel>
                <Box>
                  {restaurantReservationDetail?.alternative_booking_date || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.alternative_booking_time}</FormLabel>
                <Box>
                  {restaurantReservationDetail?.alternative_booking_time || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={4}>
              <FormControl>
                <FormLabel>{strings.additional_request}</FormLabel>
                <Box>
                  {restaurantReservationDetail?.additional_request || '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default RestaurantReservationDetails;
