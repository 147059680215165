import { Box } from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReactSelectOptionSchema } from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

export default function ObjectDropdown() {
  const apartmentAPI = new FeedbackResource();

  const { data: apartmentList, isLoading } = useQuery<
    ReactSelectOptionSchema[]
  >({
    queryKey: [`object-dashboard-apartment-list`],
    queryFn: () =>
      apartmentAPI.getApartmentList({ limit: 500, page: 1 }).then((res) => {
        const formattedData = res.data.data.map(
          (apartment: { id: number; name: string }) => {
            return {
              label: apartment.name,
              value: apartment.id,
            };
          }
        );
        return formattedData;
      }),
    refetchOnWindowFocus: false,
  });

  const navigateToDifferentApartment = (
    apartment: ReactSelectOptionSchema | null
  ) => {
    if (!apartment) return;
    window.open(
      routes.objectDashboard.dashboard.objectBoard.replace(
        ':id',
        apartment.value.toString()
      )
    );
  };

  return (
    <Box width="300px">
      <ReactSelect
        placeholder={strings.enter_object_name_here}
        options={apartmentList}
        styles={reactSelectStyles}
        isLoading={isLoading}
        onChange={navigateToDifferentApartment}
        isClearable
      />
    </Box>
  );
}
