import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { cleanData } from 'utils';

const CALCULATION = ['USAGE', 'NIGHT', 'PERC', 'MONTH', 'WEEK', 'FLAT'];
const TYPES = [
  'PET',
  'CRIB',
  'COMMISSION',
  'HANDTOWELS',
  'HIGHCHAIR',
  'LINEN',
  'RENT',
  'DISBURSEMENT',
  'CANCELLATION',
  'FINALCLEANING',
  'DISCOUNT',
  'BOOKING_FEE',
  'VTAX',
];
const PROVIDER: ['owner', 'self'] = ['owner', 'self'];
const INVOICE_RECEPIENT: ['both', 'customer', 'owner'] = [
  'both',
  'customer',
  'owner',
];
interface Props {
  handleAdvancedSearch: any;
  isStatus?: boolean;
  handleResetSearch?: () => void;
  isLoading?: string | null;
}
const InitialValues = {
  name: '',
  type: '',
  calculation: '',
  providers: '',
  invoice_recipient: '',
};

const ServiceSearch: React.FC<Props> = ({ isStatus = true, ...props }) => {
  const { handleAdvancedSearch, handleResetSearch, isLoading } = props;
  const [formValue, setFormValue] = useState<any>(InitialValues);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    const finalData: any = cleanData(formValue);
    handleAdvancedSearch(finalData, 'filter');
  }, [formValue, handleAdvancedSearch]);

  const handleReset = () => {
    setFormValue(InitialValues);
    if (handleResetSearch !== undefined) handleResetSearch();
    else handleAdvancedSearch({}, 'reset');
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack direction="row" spacing="4">
            <Grid
              gap="4"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.service_name}</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    value={formValue.name}
                    placeholder={strings.service_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.calculation}</FormLabel>
                  <Select
                    rounded="sm"
                    name="calculation"
                    value={formValue.calculation}
                    placeholder={strings.select_calculation}
                    onChange={handleInputChange}>
                    {CALCULATION.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.type}</FormLabel>
                  <Select
                    rounded="sm"
                    name="type"
                    value={formValue.type}
                    placeholder={strings.select_type}
                    onChange={handleInputChange}>
                    {TYPES.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.provider}</FormLabel>
                  <Select
                    rounded="sm"
                    name="providers"
                    value={formValue.providers}
                    placeholder={strings.select_provider}
                    onChange={handleInputChange}>
                    {PROVIDER.map((item) => (
                      <option value={item} key={item}>
                        {strings[item].toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.invoice_recepient}</FormLabel>
                  <Select
                    rounded="sm"
                    name="invoice_recipient"
                    value={formValue.invoice_recipient}
                    placeholder={strings.select_invoice_recepient}
                    onChange={handleInputChange}>
                    {INVOICE_RECEPIENT.map((item) => (
                      <option value={item} key={item}>
                        {strings[item].toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              {/* <GridItem>
                <FormControl>
                  <FormLabel>{strings.category}</FormLabel>
                  <Select id="type" rounded="sm" placeholder={strings.category}
                  {...register('category')}
                  >
                    <option></option>
                  </Select>
                </FormControl>
              </GridItem> */}
            </Grid>
          </Stack>
          <Stack direction="row" spacing="4" pt="4">
            <Button
              colorScheme="primary"
              onClick={handleReset}
              isLoading={isLoading === 'reset'}
              variant="outline">
              {strings.reset_filter}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ServiceSearch;
