import { IconButton, List, ListItem, Td, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { BreadDailyViewSchema } from 'constants/schema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
interface Props {
  breadDailyViewData: BreadDailyViewSchema;
  index: number;
  search: any;
}
const BreadDailyViewListItem: React.FC<Props> = (props) => {
  const { breadDailyViewData, index, search } = props;

  const getBrotchen = (bookings: any) => {
    if (bookings.length > 0) {
      return (
        <List>
          {bookings.map((res: any, index: number) => {
            return (
              <ListItem
                key={index}
                display="flex"
                mt={index !== 0 ? 4 : 0}
                color="gray.500">
                {res.quantity} x {res.service_name}
              </ListItem>
            );
          })}
        </List>
      );
    }
    return '-';
  };

  return (
    <Tr key={breadDailyViewData.id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.bs.breadDailyView.details.replace(
              ':id',
              breadDailyViewData.id.toString()
            ) + search
          }>
          {breadDailyViewData?.customer_name || '-'}
        </RouterLink>
      </Td>
      <Td>
        {breadDailyViewData?.reservation?.apartment?.apartment_name || '-'}
      </Td>
      <Td>{breadDailyViewData?.additional_info?.booking_number || '-'}</Td>
      <Td>{breadDailyViewData?.guest_allocation?.allocation_number || '-'}</Td>
      <Td>{getBrotchen(breadDailyViewData?.bookings)}</Td>
      <Td>
        <RouterLink
          to={
            routes.bs.breadDailyView.details.replace(
              ':id',
              breadDailyViewData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_bread_daily_view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_bread_daily_view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default BreadDailyViewListItem;
