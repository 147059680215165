import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  useToast,
} from '@chakra-ui/react';
import KeyAPI from 'api/key';
import KeyLogAPI from 'api/keyLogs';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { V_OFFICE_URL } from 'constants/common';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import KeyNewStatusForm from './KeyNewStatusForm';

const LogInfo: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef(null);
  const toast = useToast();
  const params = useParams<any>();
  let scannedKeyId = params.id;

  const methods = useForm<any>();

  const keyLogAPI = new KeyLogAPI();
  const keyAPI = new KeyAPI();

  const history = useHistory();

  const { data: keyDetails, isLoading: keyDetailsLoading } = useQuery(
    ['get-key-details-from-key-id', scannedKeyId],
    () => keyAPI.get(scannedKeyId).then((res: any) => res.data.data),
    {
      refetchOnWindowFocus: false,
      enabled: !!scannedKeyId,
    }
  );
  const isInStock: boolean = keyDetails?.status === 'in stock';

  const keyService = useMutation((data) => keyLogAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.key_updated_successfully,
        status: 'success',
        isClosable: true,
      });
      history.push(routes.key.KeyLogs.list);
    },
    onError: (error: any) => {
      setIsLoading(false);
      // The interceptor is already sending a toast error message for status code 400 and 500
      if (error?.response?.status !== 400 && error?.response?.status !== 500) {
        toast({
          title: error?.response?.data?.message || strings.error,
          status: 'error',
          isClosable: true,
        });
      }
    },
  });

  const onSubmit = (data: any) => {
    if (
      !isInStock ||
      data?.customerId ||
      data?.externalCompanyId ||
      data?.notes ||
      data?.reservationId
    ) {
      const formData = { ...data };
      formData.keyId = keyDetails.id;
      formData.reservationId = data?.reservationId?.id;
      formData.customerId = data?.customerId?.id;

      if (!formData.reservationId) delete formData.reservationId;
      if (!formData.customerId) delete formData.customerId;

      if (data?.externalCompanyId?.id) {
        formData.externalCompanyId = data.externalCompanyId.id;
      }
      setIsLoading(true);
      keyService.mutate(formData);
    } else {
      toast({
        title: strings.please_fill_in_all_mandatory_fields,
        status: 'error',
        isClosable: true,
      });
    }
  };

  let currentStatus = '-';
  if (keyDetails?.status === 'in stock') {
    currentStatus = strings.in_stock;
  } else if (keyDetails?.status === 'given out') {
    currentStatus = strings.given_out;
  } else if (keyDetails?.status === 'lost') {
    currentStatus = strings.lost;
  }

  if (keyDetailsLoading) {
    return <CenterSpinner />;
  }

  const imageUrl = `${V_OFFICE_URL}/image/s/${keyDetails?.apartment?.images?.id}`;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>KMS | {scannedKeyId}</title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_logs}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_handling}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.view}>
              {scannedKeyId}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.key_handling}
          </Heading>
        </Flex>
        <Flex justify="space-between" alignItems="flex-end">
          <Heading size="sm" textTransform="capitalize">
            {strings.key_information}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Grid
            gap="4"
            templateRows={['repeat(2, auto)']}
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(5, 1fr)',
              'repeat(5, 1fr)',
              'repeat(6, 1fr)',
            ]}
            w="100%">
            <GridItem rowSpan={2} colSpan={2}>
              <Image src={imageUrl} alt={keyDetails?.apartment?.name ?? '-'} />
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.key_id}</FormLabel>
                <Box sx={infoStyles}>{scannedKeyId ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.registered_date}
                </FormLabel>
                <Box sx={infoStyles}>
                  {keyDetails?.createdAt?.substring(0, 10) ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.object}</FormLabel>
                <Box sx={infoStyles}>{keyDetails?.apartment?.name ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.registered_by}</FormLabel>
                <Box sx={infoStyles}>
                  {keyDetails?.createUser?.fullName ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 4, 4, 2, 4]}>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
                <Box sx={infoStyles}>{keyDetails?.description ?? '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>

        <Flex justify="space-between">
          <Heading size="sm" textTransform="capitalize">
            {strings.key_current_status}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.status}</FormLabel>
                <Box sx={infoStyles}>
                  {keyDetails?.status ? currentStatus : '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.booking_number}</FormLabel>
                <Box sx={infoStyles}>
                  {keyDetails?.keyLog?.[0]?.reservation?.bookingNumber ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.customer}</FormLabel>
                <Box sx={infoStyles}>
                  {keyDetails?.keyLog?.[0]?.customer?.fullName ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.notes}</FormLabel>
                <Box sx={infoStyles}>
                  {keyDetails?.keyLog?.[0]?.notes
                    ? keyDetails?.keyLog?.[0]?.notes
                    : '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>

        <Flex justify="space-between">
          <Heading size="sm" textTransform="capitalize">
            {strings.key_new_status}
          </Heading>
        </Flex>

        <FormProvider {...methods}>
          <form ref={form}>
            <Stack sx={wrapperStyles}>
              <KeyNewStatusForm isInStock={isInStock} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  onClick={methods.handleSubmit(onSubmit)}
                  isLoading={isLoading}>
                  {strings.confirm}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </>
  );
};

export default LogInfo;
