import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { getChatMessages } from 'api/chat';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
  ROLE_ADMIN,
} from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch, BiShow } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { getStartingSerialNumber, truncateString } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';

const CHAT_STATUS_ARCHIVED = 'archived';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  closedAt: string;
  keyword: string;
}

const ArchivedChat: React.FC = () => {
  /**
   * Initialize
   */
  const [isLoading, setIsLoading] = useState<boolean>();
  const searchFormRef = useRef<HTMLFormElement>(null);
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const url_keyword = searchParams.get('keyword') ?? '';
  const url_closed_at = searchParams.get('closedAt');
  let closed_at: any = url_closed_at ? new Date(url_closed_at) : '';

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    closedAt: closed_at,
    keyword: url_keyword ?? '',
  });

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const chatList = useQuery(
    [
      'archivedChatList',
      {
        page: filterParams.currentPage,
        closedArchivedAt: filterParams.closedAt,
        name: filterParams.keyword,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams: any = {
        status: CHAT_STATUS_ARCHIVED,
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (loggedInUser.role !== ROLE_ADMIN)
        queryParams.userId = loggedInUser.id;
      if (filterParams.closedAt)
        queryParams.closedArchivedAt = filterParams.closedAt;
      if (filterParams.keyword) queryParams.name = filterParams.keyword;
      const response = await getChatMessages(queryParams);
      response && setIsLoading(false);
      return response?.data;
    }
  );

  /**
   * Handle Reset
   * Reset inputs
   */

  const handleReset = () => {
    searchFormRef.current?.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      closedAt: '',
      keyword: '',
    }));
    history.push(routes.chat.archived.list);
  };

  const handleKeywordChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    changeURL(name, value);
  };

  const handleClosedDateChange = (date: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      closedAt: date,
    }));
    changeURL('closedAt', date);
  };

  const changeURL = (name: any, value: any) => {
    let data = { ...filterParams, [name]: value };
    const date = DateFormatYMD(data.closedAt);
    let serchKeys = [];
    if (data.keyword) {
      serchKeys.push(`keyword=${data.keyword}`);
    }
    if (date) {
      serchKeys.push(`closedAt=${date}`);
    }
    let searchURL = serchKeys.join('&');
    history.push(`?${searchURL}`);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.chat} | {strings.all_archived_chats}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.chat.default}>
            {strings.chat}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.chat.archived.list + search}>
            {strings.all_archived_chats}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Heading size="md" textTransform="capitalize">
        {strings.archived_chat}
      </Heading>
      <Stack sx={wrapperStyles}>
        <form ref={searchFormRef} onSubmit={(e) => e.preventDefault()}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.search}</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BiSearch />}
                  color="gray.400"
                />
                <Input
                  name="keyword"
                  type="text"
                  value={filterParams.keyword}
                  onChange={handleKeywordChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.closed_at}</FormLabel>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  children={<BiCalendar />}
                  color="gray.400"
                />
                <ReactDatePicker
                  placeholderText={strings.select_date}
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                  name="closedAt"
                  value={DateFormatYMD(filterParams.closedAt)}
                  customInput={
                    <CustomDateInput value={filterParams.closedAt} />
                  }
                  onChange={handleClosedDateChange}
                />
              </InputGroup>
            </FormControl>

            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                onClick={handleReset}
                variant="outline">
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack bg="white" p="3" shadow="box">
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.customer_name}</Th>
                <Th isNumeric>{strings.closed_at}</Th>
                <Th>{strings.last_message}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {chatList?.data?.data?.map((data: any, index: number) => (
                <Tr key={data._id}>
                  <Td>{startingSN + index}</Td>
                  <Td
                    fontWeight="medium"
                    color="gray.500"
                    _hover={{ color: 'gray.600' }}>
                    <RouterLink
                      to={
                        routes.chat.archived.details.replace(
                          ':id',
                          data._id.toString()
                        ) + search
                      }>
                      {data.name}
                    </RouterLink>
                  </Td>
                  <Td isNumeric>{DateFormat(data.close_archive_date)}</Td>
                  <Td>
                    {truncateString(
                      data?.last_chat?.message ?? data?.last_chat?.message_type
                    )}
                  </Td>
                  <Td>
                    <RouterLink
                      to={
                        routes.chat.archived.details.replace(
                          ':id',
                          data._id.toString()
                        ) + search
                      }>
                      <Tooltip hasArrow label={strings.view}>
                        <IconButton
                          icon={<BiShow />}
                          variant="link"
                          aria-label={strings.view}
                          color="green.300"
                          minW="6"
                        />
                      </Tooltip>
                    </RouterLink>
                  </Td>
                </Tr>
              ))}
              {chatList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={chatList}
      />
    </Stack>
  );
};

export default ArchivedChat;
