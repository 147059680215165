import {
  Box,
  Center,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { DE, EN, PL } from 'assets/icons';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import { EXTERNAL_LINK_MENU } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import { LanguageTypesSchema, serviceRoute } from 'constants/schema';
import { ThemeContext } from 'context/ThemeContext';
import { serviceListData } from 'data/serviceListData';
import React, { useContext, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { IconType } from 'react-icons/lib';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { checkPermissions } from 'utils/listInfo';

const navBoxStyles = {
  p: [4, 4, 4, 8],
  color: 'white',
  rounded: 'sm',
  cursor: 'pointer',
  transition: 'ease-in-out',
  transitionDuration: '0.35s',
};

const iconStyles = {
  w: [6, 8, 12, 12],
  h: [6, 8, 12, 12],
  m: 'auto',
};

const innerBoxStyled = {
  spacing: [2, 2, 4, 4],
  direction: 'column',
  alignItem: 'center',
};

const titleStyled = {
  color: 'white',
  align: 'center',
  textAlign: 'center',
  fontSize: [8, 10, 12, 14],
};
const linkStyled = {
  textDecoration: 'none',
};

const SplashScreen: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);
  const history = useHistory();

  let currentLanguage = strings.getLanguage();

  const [currentFlag, setCurrentFlag] = useState(() =>
    currentLanguage === 'pl' ? PL : currentLanguage === 'de' ? DE : EN
  );

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const handleLanguage = (Language: LanguageTypesSchema) => {
    setCurrentFlag(Language === 'en' ? EN : Language === 'de' ? DE : PL);
    localStorage.setItem('language', Language);
    window.location.reload();
  };

  const handleSplashTileNavigation = (
    serviceName: string,
    theme: string,
    routes: serviceRoute[]
  ) => {
    setTheme(theme);
    let authorizedRoute = routes.find((currentRoute: serviceRoute) =>
      checkPermissions(userPermissions, [PermissionRequest[currentRoute[1]]])
    );
    if (authorizedRoute !== undefined) {
      if (EXTERNAL_LINK_MENU.includes(serviceName)) {
        return window.open(authorizedRoute[0], '_blank');
      }
      return history.push(authorizedRoute[0]);
    }
  };

  /**
   * Returns Icon or Image depending upon params
   *
   * @param icon
   * @returns Image | Icon
   */
  const getIcon = (icon: IconType | string) => {
    if (typeof icon === 'string') {
      return <Image src={icon} sx={iconStyles} color="white" />;
    }
    return <Icon as={icon} sx={iconStyles} />;
  };

  return (
    <>
      <Stack spacing="8">
        <Stack direction="row" justifyContent="center" position="relative">
          <Stack direction="column" spacing="8">
            <Stack spacing="4">
              <Center>
                <Image src={Logo} w={[10, 16, 20, 24]} />
              </Center>
              <Stack>
                <Text
                  align="center"
                  fontSize={['sm', 'lg', 'xl', '2xl']}
                  fontWeight="medium">
                  {strings.listinfo_service_center}
                </Text>
              </Stack>
            </Stack>
            <Stack spacing="4">
              <Text
                align="center"
                fontSize={['sm', 'lg', 'xl', '2xl']}
                fontWeight="bold">
                {strings.choose_subsystem}
              </Text>
            </Stack>
          </Stack>

          <Stack pos="absolute" right="5%">
            <Menu>
              <MenuButton
                p={2}
                transition="all 0.2s"
                _hover={{ borderBottom: 'gray.300' }}
                _expanded={{ borderBottom: 'gray.400' }}
                as={Box}>
                <Flex>
                  <MenuItem
                    as={Box}
                    icon={<Image src={currentFlag} w="6" h="6" />}>
                    {currentLanguage?.toLocaleUpperCase()}
                  </MenuItem>
                  <Box pt="2">
                    <BiChevronDown />
                  </Box>
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => handleLanguage('de')}
                  icon={<Image src={DE} w="6" h="6" />}
                  as={Box}>
                  DE
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => handleLanguage('en')}
                  icon={<Image src={EN} w="6" h="6" />}
                  as={Box}>
                  EN
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  onClick={() => handleLanguage('pl')}
                  icon={<Image src={PL} w="6" h="6" />}
                  as={Box}>
                  PL
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
        <Center>
          <SimpleGrid
            columns={[2, 3, 4, 4]}
            spacing="6"
            maxW="container.lg"
            w="full">
            {serviceListData.map((item) => (
              <HideControl
                key={item.id}
                hideFor={item.hideForLabel}
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  sx={linkStyled}
                  onClick={() =>
                    handleSplashTileNavigation(
                      item.serviceName,
                      item.theme,
                      item.routes
                    )
                  }>
                  <Tooltip hasArrow label={strings[item.tooltipLabel]}>
                    <Box
                      bg={item.bg}
                      sx={navBoxStyles}
                      boxShadow={item.boxShadow}
                      _hover={item.hover}>
                      <Stack sx={innerBoxStyled}>
                        {getIcon(item.icon)}
                        <Text sx={titleStyled}>
                          {strings[item.splashLabel]}
                        </Text>
                      </Stack>
                    </Box>
                  </Tooltip>
                </Link>
              </HideControl>
            ))}
          </SimpleGrid>
        </Center>
      </Stack>
    </>
  );
};
export default SplashScreen;
