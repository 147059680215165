import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  RadioGroup,
  Stack,
  Textarea,
  useRadioGroup,
} from '@chakra-ui/react';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CustomRadio from 'components/common/CustomRadio';
import CustomTabs from 'components/common/CustomTab';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import {
  LANGUAGE_OPTIONS,
  SERVICE_GROUP_SEARCH_API,
  TITLE_MAPPER,
  V_SERVICE_SEARCH_API,
} from 'constants/common';
import {
  LanguageAbbreviationType,
  ServiceSchema,
  ServiceTypeOptions,
  ServiceTypes,
} from 'constants/schema';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import { fallbackGermanValue } from 'utils';
import ServiceSchemeForm from './ServiceSchemeForm';

interface Props {
  serviceData?: ServiceSchema;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const PRICE_SCHEME_WITHOUT_COST: ServiceTypes[] = [
  'PriceSchemeByNone',
  'PriceSchemeByRequest',
];

const ServiceForm: React.FC<Props> = (props) => {
  const { serviceData, setErrorMessage } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext();

  const [groupInputKey, setGroupInputKey] = useState<string>(
    serviceData?.service_group?.name ?? ''
  );
  const [vServiceInputKey, setVServiceInputKey] = useState<string>(
    serviceData?.v_service?.name ?? ''
  );
  const [schemePage, setSchemePage] = useState(
    serviceData?.price_scheme_type || 'PriceSchemeByNone'
  );

  const titles = new Map<LanguageAbbreviationType, string>();

  serviceData?.titles.forEach((item) => {
    titles.set(item.language, item.title);
  });

  useEffect(() => {
    setValue(
      'price_scheme_type',
      (serviceData && serviceData?.price_scheme_type) || 'PriceSchemeByNone'
    );
  }, [serviceData, setValue]);

  /**
   * Fetch service groups
   */
  const {
    loading: serviceGroupLoading,
    result: groupQueryResult,
    setQuery: setServiceGroupQuery,
  } = useWordSearch(
    `${SERVICE_GROUP_SEARCH_API}/?page=1&limit=50&include_dog_service=1`
  );

  const {
    loading: VserviceLoading,
    result: VserviceQueryResult,
    setQuery: setVserviceQuery,
  } = useWordSearch(
    `${V_SERVICE_SEARCH_API}/?page=1&limit=50&include_dog_service=1`
  );

  const groupList = groupQueryResult?.data;
  const vServiceList = VserviceQueryResult?.data;

  const groupOptions = groupList?.map((group: any) => ({
    label: group.name,
    value: group.id,
  }));

  const vServiceOptions = vServiceList?.map((vService: any) => ({
    label: vService.name,
    value: vService.id,
  }));

  //set v-office dropdown
  useEffect(() => {
    if (!serviceData?.v_service?.id) return;
    let vService = {
      value: serviceData?.v_service?.id,
      label: serviceData?.v_service?.name,
    };

    setVserviceQuery({ keyword: vService.label });
    setValue('v_service_id', vService);
  }, [serviceData, setValue, setVserviceQuery]);
  //set service group dropdown
  useEffect(() => {
    if (!serviceData?.service_group?.id) return;
    let serviceGroup = {
      value: serviceData?.service_group?.id,
      label: serviceData?.service_group?.name,
    };
    setServiceGroupQuery({ keyword: serviceGroup.label });
    setValue('service_group_id', serviceGroup);
  }, [serviceData, setServiceGroupQuery, setValue]);

  const handleSchemeType = (schemeName: any) => {
    setValue('price_scheme_type', schemeName);
    setSchemePage(schemeName);
  };

  const handleGroupInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setGroupInputKey(value);
      setServiceGroupQuery({ keyword: value });
    }
  };
  const handleVserviceInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setVServiceInputKey(value);
      setVserviceQuery({ keyword: value });
    }
  };
  const options: ServiceTypeOptions = [
    { label: 'none', value: 'PriceSchemeByNone' },
    { label: 'by_quantity', value: 'PriceSchemeByQuantity' },
    { label: 'by_duration', value: 'PriceSchemeByDuration' },
    { label: 'by_day', value: 'PriceSchemeByDay' },
    { label: 'newspaper', value: 'PriceSchemeNewspaper' },
    { label: 'one_time_price', value: 'PriceSchemeOneTime' },
    { label: 'beach_chair', value: 'PriceSchemeBeachChair' },
    { label: 'by_request', value: 'PriceSchemeByRequest' },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'SchemeTypeRadio',
    defaultValue: serviceData?.price_scheme_type ?? 'PriceSchemeByNone',
    onChange: handleSchemeType,
  });

  const group = getRootProps();

  useEffect(() => {
    // Errors to show as alert because when the langauge tabs are changing,
    // Some input fields are not on screen and cannot be focused.
    let error: string = '';

    if (errors.titles_de) {
      error = `${strings.de}: ${errors.titles_de.message}`;
    }

    setErrorMessage(error);
  }, [setErrorMessage, errors.titles_de]);

  return (
    <form>
      <CustomTabs
        defaultIndex={0}
        display="flex"
        flexDirection="column"
        gridGap="4">
        <Stack sx={wrapperStyles} spacing="4">
          <CustomTabs.TabList>
            {LANGUAGE_OPTIONS.map((lang) => (
              <CustomTabs.Tab key={lang.value}>
                {strings[lang.value]}
              </CustomTabs.Tab>
            ))}
          </CustomTabs.TabList>
          <Grid
            gap="4"
            templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
            flex="1"
            w="100%">
            <GridItem>
              <CustomTabs.TabPanels>
                {LANGUAGE_OPTIONS.map((lang) => (
                  <CustomTabs.TabPanel key={lang.value}>
                    <FormControl
                      isInvalid={!!errors?.[TITLE_MAPPER[lang.value]]}
                      isRequired={lang.value === 'de'}>
                      <FormLabel>
                        {strings.service_name} ({strings[lang.value]})
                      </FormLabel>
                      <Input
                        type="text"
                        defaultValue={
                          titles.get(lang.value) ||
                          fallbackGermanValue(lang.value, serviceData?.name)
                        }
                        placeholder={strings.service_name}
                        {...register(TITLE_MAPPER[lang.value], {
                          required:
                            lang.value === 'de' && strings.title_required,
                        })}
                      />

                      <FormErrorMessage>
                        {errors?.[TITLE_MAPPER[lang.value]] &&
                          errors?.[TITLE_MAPPER[lang.value]]?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </CustomTabs.TabPanel>
                ))}
              </CustomTabs.TabPanels>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors?.product_code}>
                <FormLabel>{strings.product_code}</FormLabel>
                <Input
                  type="text"
                  defaultValue={serviceData?.product_code}
                  placeholder={strings.product_code}
                  {...register('product_code')}
                />
                <FormErrorMessage>
                  {errors?.product_code && errors?.product_code?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors?.service_group_id}>
                <FormLabel>{strings.service_group}</FormLabel>
                <Controller
                  control={control}
                  name="service_group_id"
                  rules={{ required: strings.required_service_group }}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="service_group_id"
                      placeholder={strings.select}
                      options={groupOptions}
                      isLoading={serviceGroupLoading}
                      styles={reactSelectStyles}
                      onInputChange={handleGroupInputChange}
                      inputValue={groupInputKey}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors?.service_group_id &&
                    errors?.service_group_id?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors?.v_service_id}>
                <FormLabel>{strings.v_office_service}</FormLabel>
                <Controller
                  control={control}
                  name="v_service_id"
                  rules={{ required: strings.required_voffice_service }}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="v_service_id"
                      placeholder={strings.select}
                      options={vServiceOptions}
                      isLoading={VserviceLoading}
                      styles={reactSelectStyles}
                      onInputChange={handleVserviceInputChange}
                      inputValue={vServiceInputKey}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors?.v_service_id && errors?.v_service_id?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors?.description} isRequired>
                <FormLabel>{strings.description}</FormLabel>
                <Textarea
                  defaultValue={serviceData?.description}
                  placeholder={strings.description}
                  {...register('description', {
                    required: strings.required_descriptions,
                  })}
                />
                <FormErrorMessage>
                  {errors?.description && errors?.description?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors?.cover} isRequired={true}>
                <FormLabel>{strings.cover_image}</FormLabel>
                <ImageUploader
                  fileKey="cover"
                  required={true}
                  uploadedFiles={serviceData?.cover}
                  title={strings.drag_icon_or_click_to_select_icon}
                />
                <FormErrorMessage>
                  {errors?.cover && errors?.cover?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <Stack sx={wrapperStyles}>
          <Stack spacing="4">
            <Grid
              gap="4"
              templateColumns="repeat(1, 1fr)"
              flex="1"
              w="100%"
              overflow="auto">
              <GridItem>
                <FormControl isInvalid={!!errors?.price_scheme_type} isRequired>
                  <FormLabel>{strings.price_scheme_type}</FormLabel>
                  <Controller
                    name="price_scheme_type"
                    rules={{
                      required: strings.required,
                    }}
                    defaultValue={serviceData?.price_scheme_type}
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        id="price_scheme_type"
                        onChange={handleSchemeType}>
                        <Flex {...group} my="2">
                          {options.map(({ value, label }) => {
                            const radio = getRadioProps({ value });
                            return (
                              <CustomRadio
                                type={'radioNav'}
                                key={value}
                                {...radio}>
                                {strings[label]}
                              </CustomRadio>
                            );
                          })}
                        </Flex>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage>
                    {errors?.price_scheme_type &&
                      errors?.price_scheme_type?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack spacing="4" mt="4">
            {/* Some price scheme does not need form to fill in price */}
            {PRICE_SCHEME_WITHOUT_COST.includes(schemePage) ? null : (
              <ServiceSchemeForm
                schemeType={getValues('price_scheme_type')}
                schemeData={serviceData}
              />
            )}
          </Stack>
        </Stack>
      </CustomTabs>
    </form>
  );
};

export default ServiceForm;
