import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { Binary } from 'constants/schema';
import {
  ImageSchema,
  InfoSchema,
  NotesSchema,
} from 'constants/schemas/objektSchema';
import React from 'react';
import FeaturesPanel from './AccordionComponents/FeaturesPanel';
import GeneralPanel from './AccordionComponents/GeneralPanel';
import NotesPanel from './AccordionComponents/NotesPanel';
import PicturePanel from './AccordionComponents/PicturePanel';
import RepairPanel from './AccordionComponents/RepairPanel';

interface ObjektDetailsInfoProps {
  objektInfo: InfoSchema;
  ownerInfo: any;
  informOwnerRepair: Binary;
  ownerRepairsAllowed: Binary;
  allowedRepairsMaxPrice: number | null;
  notes: NotesSchema[] | null;
  objectImages: ImageSchema[];
  isApartmentDetailLoading: boolean;
}

const ObjektDetailsInfo: React.FC<ObjektDetailsInfoProps> = (props) => {
  let {
    objektInfo,
    ownerInfo,
    informOwnerRepair,
    ownerRepairsAllowed,
    allowedRepairsMaxPrice,
    notes,
    objectImages,
    isApartmentDetailLoading,
  } = props;

  let { description, headline } = objektInfo;

  return (
    <Accordion defaultIndex={[0]} allowToggle>
      <AccordionItem bg="gray.50" title="Notes">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text as="h4" fontWeight="medium">
                {strings.notes}
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <NotesPanel notes={notes} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50" title="Repair">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text as="h4" fontWeight="medium">
                {strings.repairs}
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <RepairPanel
            informOwnerRepairs={informOwnerRepair}
            ownerRepairsAllowed={ownerRepairsAllowed}
            allowedRepairsMaxPrice={allowedRepairsMaxPrice}
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50" title="General">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text as="h4" fontWeight="medium">
                {strings.general} (vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <GeneralPanel ownerInfo={ownerInfo} objektInfo={objektInfo} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50" title="Features">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text as="h4" fontWeight="medium">
                {strings.features}(vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="2" overflow="auto">
          <FeaturesPanel objektInfo={objektInfo} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50" title="Text">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text as="h4" fontWeight="medium">
                {strings.text} (vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="2">
          <Heading as="h3" size="sm" color="gray.800" mt="2">
            {headline?.de}
          </Heading>
          <Text as="p" mt="2" mb="2">
            {description?.de}
          </Text>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50" title="Picture">
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text as="h4" fontWeight="medium">
                {strings.picture} (vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="2">
          <PicturePanel
            images={objectImages}
            isApartmentDetailLoading={isApartmentDetailLoading}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ObjektDetailsInfo;
