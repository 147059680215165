import {
  Box,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { capitalizeFirstLetter } from 'utils';
import { enumerateDaysBetweenDatesYMD } from 'utils/DateFormat';
import PriceDifference from './PriceDifference';

interface Props {
  breadBookingData: any;
}

const BreadBookingBillingServiceInfo: React.FC<Props> = (props) => {
  const { breadBookingData } = props;
  let { bakery_reservation_billing_particular: billingDetails, reservation } =
    breadBookingData;

  let bookingPeriodDates = enumerateDaysBetweenDatesYMD(
    reservation?.bookings?.fromdate,
    reservation?.bookings?.tilldate
  );
  let services = [
    ...new Set(billingDetails.map((item: any) => item.service_id)),
  ];

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.service}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <PerfectScrollbar>
              <Thead>
                <Tr pos="relative">
                  <Th fontWeight="bold" color="blackAlpha.900" pos="sticky">
                    {strings.bread_type}/{strings.booking_period}
                  </Th>
                  {bookingPeriodDates.length !== 0 &&
                    bookingPeriodDates.map((date: string) => (
                      <Th
                        key={date}
                        fontWeight="bold"
                        color="blackAlpha.900"
                        isNumeric>
                        {`${date.split('-')[2]}.${date.split('-')[1]}`}
                      </Th>
                    ))}
                </Tr>
              </Thead>

              <Tbody>
                {services.map((service_number: any, index: number) => {
                  let serviceData = billingDetails.filter(
                    (item: any) => item.service_id === service_number
                  );
                  return (
                    <Tr
                      key={service_number}
                      pos="relative"
                      _hover={{ bg: 'gray.50' }}>
                      <Td pos="sticky">
                        {capitalizeFirstLetter(serviceData[0]?.service?.name)}
                      </Td>

                      {bookingPeriodDates.length !== 0 &&
                        bookingPeriodDates.map((day: string) => {
                          let breadQuantity = serviceData.find(
                            (item: any) => item.order_date === day
                          );
                          return (
                            <Td key={day + service_number} isNumeric>
                              {breadQuantity?.reconciled_quantity !== null &&
                              breadQuantity?.reconciled_quantity !==
                                undefined ? (
                                <Box bg="gray.200" p="2" rounded="sm" isNumeric>
                                  {breadQuantity?.reconciled_quantity}
                                  <sub>{breadQuantity?.order_quantity}</sub>
                                </Box>
                              ) : (
                                breadQuantity?.order_quantity ?? 0
                              )}
                            </Td>
                          );
                        })}
                    </Tr>
                  );
                })}
              </Tbody>
            </PerfectScrollbar>
          </Table>
        </TableContainer>
      </Stack>
      <PriceDifference breadBookingData={breadBookingData} />
    </>
  );
};
export default BreadBookingBillingServiceInfo;
