import { Flex, Icon, Text } from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import { FaCircle } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

interface Status {
  icon: IconType;
  color: string;
  text: 'in_stock' | 'lost' | 'given_out' | 'none';
  bg: string;
}

interface KeyStatus {
  [key: string]: Status;
}

interface Props {
  status: any;
}

const keyStatusOptions: KeyStatus = {
  'in stock': {
    icon: BiArchiveIn,
    color: 'green.300',
    text: 'in_stock',
    bg: 'green.300',
  },
  lost: {
    icon: FaCircle,
    color: 'red.300',
    text: 'lost',
    bg: 'green.300',
  },
  'given out': {
    icon: BiArchiveOut,
    color: 'orange.300',
    text: 'given_out',
    bg: 'green.300',
  },
};

const noStatus = {
  icon: undefined,
  color: 'red.300',
  text: 'none',
};

const KeyStatus: React.FC<Props> = (props: Props) => {
  const { status } = props;
  const keyStatus = keyStatusOptions[status] ?? noStatus;
  return (
    <>
      <Flex alignItems="center">
        <Icon as={keyStatus.icon} color={keyStatus.color} />
        <Text color={keyStatus.color} ml="1">
          {strings[keyStatus.text]}
        </Text>
      </Flex>
    </>
  );
};

export default KeyStatus;
