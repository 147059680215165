import {
  BOOKING_DETAILS,
  IS_SECOND_SCREEN_OPEN,
  MIRROR_SECOND_SCREEN,
  SECOND_SCREEN_CHANNEL,
  SECOND_SCREEN_STATUS,
} from 'constants/common';
import React, { useEffect, useRef, useState } from 'react';
import CheckInScreen from './CheckInScreen';
import WelcomeScreen from './WelcomeScreen';

const SHOW_WELCOME = 'welcome';
const SHOW_DETAILS = 'details';

const SecondScreen = () => {
  const [currentScreen, setCurrentScreen] = useState<string>(SHOW_WELCOME);
  const [bookingInfo, setBookingInfo] = useState<any>(null);
  const [tempDataStorage, setTempDataStorage] = useState<any>(null);
  const secondScreenRef = useRef<any>(null);

  // Full Screen toggle on Keyboard F
  useEffect(() => {
    const fullScreenHandler = () => {
      const fullscreenElement = document.fullscreenElement;
      if (!fullscreenElement) {
        secondScreenRef.current?.requestFullscreen();
      } else {
        document?.exitFullscreen();
      }
    };
    const fullScreenListener = (e: KeyboardEvent) => {
      if (e.code === 'KeyF') fullScreenHandler();
    };
    document.addEventListener('keypress', fullScreenListener);
    return () => document.removeEventListener('keypress', fullScreenListener);
  }, []);

  useEffect(() => {
    const broadCastAPI = new BroadcastChannel(SECOND_SCREEN_CHANNEL);

    // Notify second screen is already open on second screen reload
    broadCastAPI.postMessage({
      name: SECOND_SCREEN_STATUS,
      value: true,
    });

    // Notify when second screen window is closed
    const closeWindowListener = () => {
      broadCastAPI.postMessage({
        name: SECOND_SCREEN_STATUS,
        value: false,
      });
    };
    window.addEventListener('beforeunload', closeWindowListener);

    // For which screen to show in second screen
    broadCastAPI.onmessage = (event) => {
      // When second screen is already open
      if (event.data.name === IS_SECOND_SCREEN_OPEN) {
        broadCastAPI.postMessage({
          name: SECOND_SCREEN_STATUS,
          value: true,
        });
      }

      // when broadcast api sends mirror command
      if (event.data.name === MIRROR_SECOND_SCREEN) {
        if (event.data.value) setCurrentScreen(SHOW_DETAILS);
        else {
          setCurrentScreen(SHOW_WELCOME);
          setBookingInfo(null);
          setTempDataStorage(null);
        }
      }

      // when booking details is received
      if (event.data.name === BOOKING_DETAILS) {
        setTempDataStorage(event.data.value);
      }
    };

    return () => {
      window.removeEventListener('beforeunload', closeWindowListener);
      broadCastAPI.close();
    };
  }, []);

  useEffect(() => {
    if (currentScreen === SHOW_DETAILS && !!tempDataStorage) {
      setBookingInfo(tempDataStorage);
    }
  }, [currentScreen, tempDataStorage]);

  return (
    <div ref={secondScreenRef}>
      {currentScreen === SHOW_DETAILS && bookingInfo ? (
        <CheckInScreen bookingInfo={bookingInfo} />
      ) : (
        <WelcomeScreen />
      )}
    </div>
  );
};

export default SecondScreen;
