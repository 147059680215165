import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import * as stockActivitiesAPI from 'api/stock-activities';
import { wrapperStyles } from 'assets/css/commonStyles';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import TransferItemForm from 'components/transfer/TransferItemForm';
import TransferLogListItem from 'components/transfer/TransferLogListItem';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

const TransferItems: React.FC = () => {
  const { data: logList, isLoading: isLogListLoading } = useQuery(
    'fetchTransferLogListLatestFifteen',
    async () => {
      const queryParams: any = {
        page: 1,
        limit: 5,
      };
      const response = await stockActivitiesAPI.getTransferLogs(queryParams);
      return response?.data?.data?.data;
    }
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.transfer_item}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.all_transfer_item}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.transfer_item}
          </Heading>
        </Flex>

        <TransferItemForm />

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.article}</Th>
                  <Th>{strings.source}</Th>
                  <Th>{strings.destination}</Th>
                  <Th isNumeric>{strings.transfer_quantity}</Th>
                  <Th>{strings.transfer_by}</Th>
                  <Th isNumeric>{strings.transfer_date}</Th>
                  <Th>{strings.transfer_source}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {logList?.map((log: any, index: number) => (
                  <TransferLogListItem
                    logData={log}
                    index={index + 1}
                    key={log.id}
                  />
                ))}
                {isLogListLoading && <TableSkeletonLoader rows={5} cols={8} />}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  );
};

export default TransferItems;
