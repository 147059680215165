import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;
class ObjectDocumentResource extends Resource {
  constructor() {
    super('dms/object-document');
  }

  objectGrouplist(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: 'dms/object-document-group',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  getObjectList(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: 'dms/apartment',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  objectDetail(id: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: 'dms/apartment/' + id,
      method: 'get',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }
}

export { ObjectDocumentResource as default };
