import {
  Box,
  Divider,
  Flex,
  List,
  ListIcon,
  ListItem,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import FeedbackQuestionTypePDF from 'components/feedback/FeedbackQuestionTypePDF';
import { strings } from 'config/localization';
import React, { forwardRef } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { BsArrowReturnRight } from 'react-icons/bs';
import { DateFormat, utcToLocal } from 'utils/DateFormat';

const titleStyled = {
  color: 'gray.600',
  fontWeight: 'medium',
  mr: '5px',
};

const borderStyled = {
  borderColor: 'gray.200',
  my: '2',
  _last: {
    display: 'none',
  },
};

interface Props {
  feedbackDetail: any;
  ref: any;
}
const FeedbackDetailsPDF = forwardRef((props: Props, ref: any) => {
  const { feedbackDetail } = props;
  return (
    <Box id="feedback-detail-print" ref={ref} m={0} w="100%">
      <Box bg="white" p={5} mb="3">
        <Box mb="5">
          <Text fontSize="lg" color="gray.600" fontWeight="bold">
            {`${feedbackDetail?.reservation?.customer_info?.forename} ${feedbackDetail?.reservation?.customer_info?.surname}`}
          </Text>
        </Box>
        <Box mb="6">
          <Text sx={titleStyled} as="span">
            {strings.feedback_received}:
          </Text>
          <Text as="span">{utcToLocal(feedbackDetail?.feedback_at)}</Text>
        </Box>
        <Box mb="6">
          <Text sx={titleStyled} as="span">
            {strings.booking_number}:
          </Text>
          <Text as="span">{feedbackDetail?.reservation?.booking_number}</Text>
        </Box>
        <Box mb="6">
          <Text sx={titleStyled} as="span">
            {strings.apartmant}:
          </Text>
          <Text as="span">{feedbackDetail?.reservation?.apartment?.name}</Text>
        </Box>
        <Box>
          <Text sx={titleStyled} as="span">
            {strings.time_period}:
          </Text>
          <Text as="span">{`${DateFormat(
            feedbackDetail?.reservation?.from_date
          )} - ${DateFormat(feedbackDetail?.reservation?.to_date)}`}</Text>
        </Box>
      </Box>
      <Box bg="white" p={5} mb="3" fontSize="sm">
        <Box>
          <List spacing={3}>
            <ListItem fontWeight={600}>
              <Flex>
                <ListIcon as={AiOutlineStar} fontSize="lg" />
                <Box>
                  {
                    strings.How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole
                  }
                </Box>
              </Flex>
            </ListItem>
            <Tooltip
              hasArrow
              label={strings.the_rating_is_out_of_10}
              placement="bottom"
              zIndex="popover">
              <ListItem fontSize="sm" w="60px">
                <ListIcon as={BsArrowReturnRight} fontSize="lg" />
                {feedbackDetail?.rating}
              </ListItem>
            </Tooltip>
          </List>
        </Box>
        <Divider sx={borderStyled}></Divider>
        {feedbackDetail?.feedback_response?.map(
          (response: any, index: number) => (
            <FeedbackQuestionTypePDF
              response={response}
              index={index}
              key={index}
            />
          )
        )}
      </Box>
    </Box>
  );
});
FeedbackDetailsPDF.displayName = 'FeedbackDetailsPDF';
export default FeedbackDetailsPDF;
