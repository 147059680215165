import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import BakeryBillingResource from 'api/bakery_billing';
import BreadBookingBillingInfo from 'components/bms_bread_booking_billing/BreadBookingBillingInfo';
import BreadBookingBillingServiceInfo from 'components/bms_bread_booking_billing/BreadBookingBillingServiceInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const BreadBookingBillingDetail: React.FC = () => {
  const { id }: any = useParams();
  const { search } = useLocation();

  const bakeryBillingAPI = new BakeryBillingResource();
  const bakeryBillingDetail = useQuery([`bakery-billing-details${id}`], () =>
    bakeryBillingAPI.get(id).then((res) => res.data.data)
  );

  if (bakeryBillingDetail.isLoading) {
    return <CenterSpinner />;
  } else if (bakeryBillingDetail.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        {strings.error}
      </Alert>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bs} |&nbsp;
            {bakeryBillingDetail?.data?.reservation?.booking_number ?? '-'}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bs}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bs.breadBookingBilling.list + search}>
              {strings.bread_booking_billing}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {bakeryBillingDetail?.data?.reservation?.booking_number ?? '-'}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <BreadBookingBillingInfo
          breadBookingData={bakeryBillingDetail.data}
          search={search}
        />

        <BreadBookingBillingServiceInfo
          breadBookingData={bakeryBillingDetail.data}
        />
      </Stack>
    </>
  );
};

export default BreadBookingBillingDetail;
