import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ServiceResource from 'api/sms_services';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ServiceSchema } from 'constants/schema';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  serviceData: ServiceSchema;
  index: number;
  draggableProvider: any;
  search?: string;
}
const ServiceListItem: React.FC<Props> = (props) => {
  const { serviceData, index, draggableProvider, search } = props;
  let { status } = serviceData;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const [localStatus, setLocalStatus] = useState<number>(status);
  const checkEditPermission = useIsPermitted([PermissionRequest['read:sms']]);

  const serviceAPI = new ServiceResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const updateStatus = useMutation((data: any) =>
    serviceAPI.updateService(serviceData.id, data)
  );

  const deleteService = useMutation(() => serviceAPI.destroy(serviceData.id), {
    onSuccess: () => {
      queryClient.invalidateQueries('serviceList');
      setDeleteModalOpen(false);
      toast({
        title: strings.service_deleted,
        status: 'success',
        isClosable: true,
      });
    },
    onError: (error: any) => {
      setDeleteErrorMsg(error?.response?.data?.message);
    },
  });

  function submitStatusUpdate(formData: any) {
    updateStatus.mutate(formData, {
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
        setLocalStatus(status);
      },
    });
  }

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteService.mutate();
  };

  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  function handleStatusChange(e: any) {
    let checked = e.target.checked ? 1 : 0;
    let stateObj = { status: checked, _method: 'PUT' };
    submitStatusUpdate(stateObj);
    setLocalStatus(checked);
  }
  return (
    <Tr
      key={serviceData.id}
      ref={draggableProvider.innerRef}
      {...draggableProvider.draggableProps}
      {...draggableProvider.dragHandleProps}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.sms.services.details.replace(
              ':id',
              serviceData.id.toString()
            ) + search
          }>
          <HStack>
            <Avatar size="sm" rounded="full" src={serviceData?.cover} />
            <Text fontWeight="semibold">{serviceData.name}</Text>
          </HStack>
        </RouterLink>
      </Td>
      <Td>{serviceData?.service_group?.name}</Td>
      <Td>
        {strings.getString(
          serviceData.price_scheme_type.toLowerCase().split(' ').join('_')
        )}
      </Td>
      <Td>
        <FormControl display="flex" alignItems="center">
          <Switch
            colorScheme="green"
            isDisabled={!checkEditPermission}
            onChange={handleStatusChange}
            isChecked={!!localStatus}
          />
        </FormControl>
      </Td>

      <Td>
        <RouterLink
          to={
            routes.sms.services.details.replace(
              ':id',
              serviceData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_service}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_service}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={
            routes.sms.services.edit.replace(':id', serviceData.id.toString()) +
            search
          }>
          <Tooltip hasArrow label={strings.edit_service}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_service}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_service}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_service}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_service}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteService.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ServiceListItem;
