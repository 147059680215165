import { Checkbox, Grid, Heading, VStack } from '@chakra-ui/react';
import {
  PartnerGoodiesSchema,
  ThirdPartyPartnerSchema,
} from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data: ThirdPartyPartnerSchema;
  selectedValue?: PartnerGoodiesSchema[];
}

const PartnerGoodiesCheckboxGroup: React.FC<Props> = (props) => {
  const { data, selectedValue } = props;
  const { register, watch, setValue } = useFormContext();
  let selectedItems = watch('partner_goodies_ids') || [];

  useEffect(() => {
    if (selectedValue) {
      const initialSelectedIds = selectedValue.map((item) => item.id);
      setValue('partner_goodies_ids', initialSelectedIds);
      selectedItems = initialSelectedIds;
    }
  }, [selectedValue, setValue]);

  const handleCheckboxToggle = (value: string) => {
    const updatedSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter((id: string) => id !== value)
      : [...selectedItems, value];
    setValue('partner_goodies_ids', updatedSelectedItems);
  };

  return (
    <React.Fragment>
      {data?.partner_goodies?.length > 0 && (
        <VStack alignItems="left">
          <Heading size="xs" fontWeight="600">
            {data.name}
          </Heading>
          <Grid gap={2} templateColumns="1fr">
            {data.partner_goodies.map((item, index) => (
              <Grid key={index} templateColumns="auto 1fr" gap="2">
                <Checkbox
                  isChecked={selectedItems.includes(item.id)}
                  onChange={() => handleCheckboxToggle(item.id)}
                  colorScheme="green"
                />
                <span>{item.name}</span>
              </Grid>
            ))}
          </Grid>
        </VStack>
      )}
    </React.Fragment>
  );
};

export default PartnerGoodiesCheckboxGroup;
