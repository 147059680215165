import { IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { RestaurantReservationListSchema } from 'constants/schema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  restaurantReservationData: RestaurantReservationListSchema;
  index: number;
  search: string;
}

const RestaurantReservationListItem: React.FC<Props> = (props) => {
  const { restaurantReservationData, index, search } = props;
  const {
    id,
    booking_number,
    first_name,
    last_name,
    email,
    phone_number,
    booking_date,
    booking_time,
    restaurant,
    restaurant_id,
  } = restaurantReservationData;

  return (
    <Tr key={id}>
      <Td>{index}</Td>
      <Td>{first_name ?? '-'}</Td>
      <Td>{last_name ?? '-'}</Td>
      <Td>{email ?? '-'}</Td>
      <Td>{phone_number ?? '-'}</Td>
      <Td>{booking_date ?? '-'}</Td>
      <Td>{booking_time ?? '-'}</Td>
      <Td>
        <RouterLink
          to={
            routes.restaurant.restaurant.details.replace(
              ':id',
              restaurant_id.toString()
            ) + search
          }>
          {restaurant ?? '-'}
        </RouterLink>
      </Td>
      <Td>{booking_number ?? '-'}</Td>

      <Td>
        <RouterLink
          to={
            routes.restaurant.restaurantReservation.details.replace(
              ':id',
              id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default RestaurantReservationListItem;
