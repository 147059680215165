import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import chat from './chat';
import feedback from './feedback';
import ticket from './ticket';

/**
 * Persist Auth Reducer.
 */
const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  chat: chat,
  ticket: ticket,
  feedback: feedback,
});
