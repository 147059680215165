import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MeerSyltResource from 'api/sms_meer_sylt';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import MeerSyltForm from 'components/sms_service/MeerSyltForm';
import { strings } from 'config/localization';
import { TOAST_DURATION, TOAST_STATUS } from 'constants/common';
import routes from 'constants/routes';
import { MeerSyltFormSchema, MeerSyltSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const AddMeerSylt: React.FC = () => {
  const { id: meerSyltId } = useParams<{ id: string }>();

  const methods = useForm<MeerSyltSchema>();
  const toast = useToast();
  let meerSyltAPI = new MeerSyltResource();

  const [errMsg, setErrMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const { data: meerSyltDetails, isLoading: meerSyltDataLoading } = useQuery(
    ['meer-sylt-data-page', meerSyltId],
    () => {
      if (!meerSyltId) return null; // { data: null };
      return meerSyltAPI
        .get(meerSyltId)
        .then((res) => res.data.data)
        .catch(() => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: TOAST_STATUS.ERROR,
            duration: TOAST_DURATION.LONG,
            isClosable: true,
          });
        });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const createMeerSylt = useMutation((data: any) => meerSyltAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.meer_sylt_created_successfully,
        status: TOAST_STATUS.SUCCESS,
        isClosable: true,
      });
      const id = res?.data?.data?.id;
      history.push(routes.sms.meerSylt.details.replace(':id', id.toString()));
    },
  });

  const updateMeerSylt = useMutation(
    (data: any) => meerSyltAPI.update(parseInt(meerSyltId), data),
    {
      onSuccess: () => {
        toast({
          title: strings.meer_sylt_updated_successfully,
          status: TOAST_STATUS.SUCCESS,
          isClosable: true,
        });
        history.push(
          routes.sms.meerSylt.details.replace(':id', meerSyltId.toString())
        );
      },
    }
  );

  const constructFormData = (formData: MeerSyltFormSchema): FormData => {
    const data = new FormData();

    if (formData.image) {
      data.append('image', formData.image);
    }

    data.append('title', formData.titles_de);
    data.append('text', formData.descriptions_de);
    data.append('link', formData.link);

    data.append('is_active', formData.is_active ? String(1) : String(0));
    data.append(
      'is_load_on_iframe',
      formData.is_load_on_iframe ? String(1) : String(0)
    );
    data.append('descriptions[en]', formData.descriptions_en);
    data.append('descriptions[de]', formData.descriptions_de);
    data.append('descriptions[pl]', formData.descriptions_pl);
    data.append('descriptions[da]', formData.descriptions_da);
    data.append('descriptions[zh]', formData.descriptions_zh);
    data.append('titles[de]', formData.titles_de);
    data.append('titles[en]', formData.titles_en);
    data.append('titles[pl]', formData.titles_pl);
    data.append('titles[da]', formData.titles_da);
    data.append('titles[zh]', formData.titles_zh);

    return data;
  };

  const onSubmit = async (formData: any) => {
    let API;
    const data = constructFormData(formData);
    if (meerSyltId) {
      API = updateMeerSylt;
      data.append('_method', 'PATCH');
    } else {
      API = createMeerSylt;
    }

    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          type MeerSyltKeys = keyof MeerSyltSchema;
          const backendErrors = err.response.data.errors;
          const { setError } = methods;
          Object.keys(backendErrors).forEach((key) => {
            const formKey = key as MeerSyltKeys;
            if (formKey in formData) {
              setError(formKey, { message: backendErrors[key][0] });
            }
          });
        }

        setErrMsg(
          `${strings.meer_sylt} ${
            meerSyltId
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  if (meerSyltDataLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.meer_sylt} | &nbsp;
            {meerSyltId ? strings.edit_meer_sylt : strings.add_meer_sylt}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.sms.meerSylt.list}>
              {strings.meer_sylt}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {meerSyltId ? strings.edit_meer_sylt : strings.add_meer_sylt}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {meerSyltId ? meerSyltDetails?.title : strings.add_meer_sylt}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {errMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errMsg} || err
                  </Alert>
                )}
                {errorMessage.map((error) => (
                  <Alert status="error" key={error}>
                    <AlertIcon />
                    {error}
                  </Alert>
                ))}

                <MeerSyltForm
                  data={meerSyltDetails}
                  setErrorMessage={setErrorMessage}
                />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {!meerSyltId ? strings.add_now : strings.update}
                  </Button>
                  <Button
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddMeerSylt;
