import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;

class BreadBookingResource extends Resource {
  constructor() {
    super('rms/service-reservations');
  }
  createBakeryBooking(formData: any) {
    return http({
      url: '/rms/bakery-reservations/',
      method: 'post',
      data: formData,
    });
  }
  updateBreadBooking(id: number | string, formData: any) {
    return http({
      url: '/rms/bakery-reservations/' + id,
      method: 'put',
      data: formData,
    });
  }
  cancelBreadBooking(id: number | string) {
    return http({
      url: '/rms/bakery-reservations/' + id,
      method: 'DELETE',
    });
  }
  getBreadBooking(id: number | string) {
    return http({
      url: '/rms/service-reservations-bakery/' + id,
      method: 'get',
    });
  }
  getBreadBookingList(query: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: '/rms/service-reservations-bakery',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }
}

export { BreadBookingResource as default };
