import TicketDashboardResource from 'api/ticket-dashboard';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import TaskListItem from 'components/task/TaskListItem';
import { TicketInfoSchema, TicketStatusSchema } from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';

interface Props {
  type: TicketStatusSchema | 'in-progress';
  keyword?: string;
  resourceId: number;
  dashboardType: 'customer' | 'object';
}

const TicketArea: React.FC<Props> = (props) => {
  const { type, keyword, resourceId, dashboardType } = props;
  const dashboardAPI = new TicketDashboardResource();
  const { data: bookingInfo, isLoading } = useQuery(
    [
      `${type}-dashboard-${dashboardType}-ticket`,
      type,
      keyword,
      dashboardType,
      resourceId,
    ],
    () => {
      let dashAPI =
        dashboardType === 'object'
          ? dashboardAPI.dashboardObjectTickets
          : dashboardAPI.dashboardCustomerTickets;

      return dashAPI(resourceId, {
        status: type,
        title: keyword,
      }).then((res) => res.data?.data);
    },
    { refetchOnWindowFocus: false }
  );
  if (isLoading) {
    return <TicketSkeletonLoader rows={3} />;
  } else if (!bookingInfo) return null;
  return bookingInfo.map((task: TicketInfoSchema) => (
    <div key={task.id}>
      <TaskListItem
        linkTarget="_blank"
        ticketId={task.id}
        title={task.title}
        appointment_date={task?.appointment_date}
        object={task.apartment?.name}
        assignedUserStatus={task.assigned_user?.status}
        assignedUserName={task.assigned_user?.name}
        external_company={task.external_company}
        recursive={task.recursive_interval}
        billable={task.is_billable}
        priority={task.priority}
        avatar={task.assigned_user?.image_url}
        dueDate={task.due_date}
        personInCharge={task?.apartment?.responsible_person}
        substitutePersonInCharge={
          task?.apartment?.substitute_responsible_person
        }
      />
    </div>
  ));
};

export default TicketArea;
