import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { country_list } from 'constants/countryList';
import { ContactInputSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { validateNumber, validEmail } from 'utils/validate';

interface Props {
  data?: any;
}

const ContactForm: React.FC<Props> = (props) => {
  let { data } = props;

  const [isFromVoffice, setIsFromVoffice] = useState(false);
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<ContactInputSchema>();
  useEffect(() => {
    if (data) {
      setValue('first_name', data.first_name);
      setValue('last_name', data.last_name);
      setValue('street', data.street);
      setValue('source', data.source);
      setValue('zip_code', data.zip_code);
      setValue('house_no', data.house_no);
      setValue('location', data.location);
      setValue('country', data.country);
      setValue('phone', data.phone);
      setValue('mobile', data.mobile);
      setValue('email', data.email);
      setValue('company', data.company);
      setValue('type', data.type);
      setValue(
        'is_subscribed',
        data.is_subscribed === 'true' || data.is_subscribed === true
          ? true
          : false
      );
      setValue('fellow_travellers', data.contactDetail.fellow_travellers);
      setValue('pet', data.contactDetail.pet);
      setValue('equipment_features', data.contactDetail.equipment_features);
      setValue('services', data.contactDetail.services);
      setValue('external_notes', data.contactDetail.external_notes);
      setValue('internal_notes', data.contactDetail.internal_notes);
      let isFromVoffice = data.source === 'v-office';
      setIsFromVoffice(isFromVoffice);
    }
  }, [data, setValue]);

  return (
    <form>
      <Stack direction="column" spacing="8">
        <Stack direction="row" spacing="8">
          <Grid
            gap="4"
            templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl
                isDisabled={isFromVoffice}
                isRequired
                isInvalid={!!errors.first_name}>
                <FormLabel>{strings.first_name}</FormLabel>
                <Input
                  {...register('first_name', {
                    required: strings.required_first_name,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  type="text"
                  placeholder={strings.first_name}
                  defaultValue={data?.first_name}
                />
                <FormErrorMessage>
                  {errors.first_name && errors.first_name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isDisabled={isFromVoffice}
                isRequired
                isInvalid={!!errors.last_name}>
                <FormLabel>{strings.last_name}</FormLabel>
                <Input
                  {...register('last_name', {
                    required: strings.required_last_name,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  type="text"
                  placeholder={strings.last_name}
                  defaultValue={data?.last_name}
                />
                <FormErrorMessage>
                  {errors.last_name && errors.last_name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled={isFromVoffice}>
                <FormLabel>{strings.street}</FormLabel>
                <Input
                  {...register('street')}
                  type="text"
                  placeholder={strings.street}
                  defaultValue={data?.street}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isDisabled={isFromVoffice}
                isInvalid={!!errors?.house_no}>
                <FormLabel>{strings.house_no}</FormLabel>
                <Input
                  {...register('house_no', {
                    validate: (value) => {
                      if (!value) {
                        return true;
                      }
                    },
                  })}
                  type="text"
                  placeholder={strings.house_no}
                />
                <FormErrorMessage>
                  {errors?.house_no && errors?.house_no?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isDisabled={isFromVoffice}
                isInvalid={!!errors?.zip_code}>
                <FormLabel>{strings.post_code}</FormLabel>
                <Input
                  {...register('zip_code', {
                    validate: (value) => {
                      if (!value) {
                        return true;
                      } else {
                        return (
                          validateNumber(value) || strings.valid_only_number
                        );
                      }
                    },
                  })}
                  type="text"
                  isRequired={false}
                  placeholder={strings.post_code}
                  defaultValue={data?.zip_code}
                />
                <FormErrorMessage>
                  {errors?.zip_code && errors?.zip_code?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled={isFromVoffice}>
                <FormLabel>{strings.town}</FormLabel>
                <Input
                  {...register('location')}
                  type="text"
                  placeholder={strings.town}
                  defaultValue={data?.location}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled={isFromVoffice}>
                <FormLabel>{strings.country}</FormLabel>
                <Select
                  {...register('country')}
                  placeholder={strings.select + ' ' + strings.country}
                  rounded="sm"
                  defaultValue={data?.country}>
                  {country_list.map((countryName) => (
                    <option key={countryName} value={countryName}>
                      {countryName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled={isFromVoffice}>
                <FormLabel>{strings.phone}</FormLabel>
                <Input
                  {...register('phone')}
                  type="text"
                  placeholder={strings.phone}
                  defaultValue={data?.phone}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled={isFromVoffice}>
                <FormLabel>{strings.mobile}</FormLabel>
                <Input
                  {...register('mobile')}
                  type="text"
                  placeholder={strings.mobile}
                  defaultValue={data?.mobile}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isDisabled={isFromVoffice}
                isInvalid={!!errors?.email}>
                <FormLabel>{strings.email}</FormLabel>
                <Input
                  {...register('email', {
                    validate: (value) => {
                      if (value) {
                        return validEmail(value) || strings.valid_email_address;
                      }
                    },
                  })}
                  type="email"
                  placeholder={strings.email_placeholder}
                  defaultValue={data?.email}
                />
                <FormErrorMessage>
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled={isFromVoffice}>
                <FormLabel>{strings.company}</FormLabel>
                <Input
                  {...register('company')}
                  type="text"
                  placeholder={strings.company}
                  defaultValue={data?.company}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isDisabled={isFromVoffice}
                isRequired
                isInvalid={!!errors.type}>
                <FormLabel>{strings.label}</FormLabel>
                <Controller
                  name="type"
                  rules={{
                    required: strings.required_label,
                  }}
                  defaultValue={data?.type}
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} id="type">
                      <Stack spacing={5} direction="row">
                        {isFromVoffice && (
                          <>
                            <Radio value="owner">{strings.owner}</Radio>
                            <Radio value="guest">{strings.guest}</Radio>
                          </>
                        )}
                        <Radio value="lead">{strings.lead}</Radio>
                        <Radio value="supplier">{strings.supplier}</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>
                  {errors.type && errors.type?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.fellow_travelers}</FormLabel>
                <Input
                  {...register('fellow_travellers')}
                  type="text"
                  placeholder={strings.fellow_travelers}
                  defaultValue={data?.fellow_travellers}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.pets}</FormLabel>
                <Input
                  {...register('pet')}
                  type="text"
                  placeholder={strings.pets}
                  defaultValue={data?.contactDetail?.pet}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.equipment_features}</FormLabel>
                <Select
                  {...register('equipment_features')}
                  placeholder={
                    strings.select + ' ' + strings.equipment_features
                  }
                  rounded="sm"
                  defaultValue={data?.equipment_features}>
                  <option value="cleaner">{strings.janitor}</option>
                  <option value="admin">{strings.admin}</option>
                  <option value="support">{strings.support}</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.services}</FormLabel>
                <Select
                  {...register('services')}
                  placeholder={strings.select + ' ' + strings.services}
                  rounded="sm"
                  defaultValue={data?.services}>
                  <option value="cleaner">{strings.janitor}</option>
                  <option value="admin">{strings.admin}</option>
                  <option value="support">{strings.support}</option>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{strings.notes_external}</FormLabel>
                <Textarea
                  {...register('external_notes')}
                  placeholder={strings.notes_external}
                  defaultValue={data?.contactDetail?.external_notes}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.notes_internal}</FormLabel>
                <Textarea
                  {...register('internal_notes')}
                  placeholder={strings.notes_internal}
                  defaultValue={data?.contactDetail?.internal_notes}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.newsletter}</FormLabel>
                <Controller
                  name="is_subscribed"
                  defaultValue={data?.is_subscribed}
                  render={({ field }) => {
                    field.value =
                      field.value === 'true' || field.value === true
                        ? 'true'
                        : 'false';
                    return (
                      <RadioGroup {...field} id="is_subscribed">
                        <Stack spacing={5} direction="row">
                          <Radio value="true">{strings.subscribe}</Radio>
                          <Radio value="false">{strings.unsubscribe}</Radio>
                        </Stack>
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </form>
  );
};

export default ContactForm;
