import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import CallbacksResource from 'api/callbacks';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { DateFormat } from 'utils/DateFormat';

type CallbackListItem = {
  id: number;
  title: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  callback_date: string;
  callback_time: string;
  callback_time_nxt: string;
};

interface Props {
  data: CallbackListItem;
  index: number;
  search: string;
}

const CallbackListItem: React.FC<Props> = (props) => {
  const { data, index, search } = props;
  const {
    id,
    title,
    first_name,
    last_name,
    email,
    phone_number,
    callback_date,
    callback_time: call_time_from,
    callback_time_nxt: call_time_to,
  } = data;

  const toast = useToast();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');

  const queryClient = useQueryClient();

  const CallbackAPI = new CallbacksResource();

  const deleteCallback = useMutation((id: number) => CallbackAPI.destroy(id), {
    onSuccess: () => {
      setDeleteModalOpen(false);
      toast({
        position: 'bottom',
        title: strings.success,
        description: strings.callback_deleted_successfully,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      queryClient.invalidateQueries('callback-list');
    },
    onError: (error: any) => {
      setDeleteErrorMsg(error?.response?.data?.error?.message);
    },
  });

  const handleDelete = () => {
    deleteCallback.mutate(id);
  };

  const handleDeleteModal = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(false);
  };

  let callbackDate: string = DateFormat(callback_date) ?? '-';
  let callbackTimeFrom = call_time_from?.substring(0, 5) ?? '-';
  let callbackTimeTo = call_time_to?.substring(0, 5) ?? '-';

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>
        {title === 1 && strings.mr + ' '}
        {title === 2 && strings.mrs + ' '}
        {title === 3 && strings.misc + ' '}
        {first_name} {last_name}
      </Td>
      <Td>{email ?? '-'}</Td>
      <Td>{phone_number ?? '-'}</Td>
      <Td>{callbackDate}</Td>
      <Td>
        {callbackTimeFrom} / {callbackTimeTo}
      </Td>
      <Td>
        <RouterLink
          to={
            routes.bms.callback.details.replace(':id', id.toString()) + search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            onClick={handleDeleteModal}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={handleCancelDelete}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {strings.delete} {strings.callback}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              <Text color="heading">{strings.confirm_delete}</Text>
              <Text color="heading">{`${strings.name}: ${first_name} ${last_name}`}</Text>
              <Text color="heading">{`${strings.callback_date}: ${callbackDate}`}</Text>
              <Text color="heading">{`${strings.time}: ${callbackTimeFrom} / ${callbackTimeTo}`}</Text>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[PermissionRequest['read:callbacks']]}
                  renderNoAccess={(data: any) => data}>
                  <Button
                    colorScheme="red"
                    onClick={handleDelete}
                    isLoading={deleteCallback.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>
                <Button
                  variant="outline"
                  onClick={handleCancelDelete}
                  isDisabled={deleteCallback.isLoading}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default CallbackListItem;
