import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { InventorySchema } from 'constants/schema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link as RouterLink } from 'react-router-dom';
export interface InventoryListItemProps {
  inventory: InventorySchema;
  index: number;
  search?: any;
}

const InventoryListItem: React.FC<InventoryListItemProps> = (props) => {
  const { inventory, index, search } = props;

  const isMinStockReached =
    inventory.total_quantity <= parseInt(inventory.min_stock, 10);
  const isMaxStockReached =
    inventory.total_quantity >= parseInt(inventory.max_stock, 10);

  return (
    <Tr key={index}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.mms.inventory.details.replace(
              ':id',
              inventory.id.toString()
            ) + search
          }>
          <CustomTagTitle
            type="text"
            status={inventory.status}
            name={inventory.name || '-'}
          />
        </RouterLink>
      </Td>
      <Td isNumeric>
        {isMinStockReached && inventory.total_quantity !== 0 && (
          <Tooltip
            hasArrow
            label={`${strings.min_stock_reached_description} ${inventory.min_stock}.`}>
            <span>
              <CustomTagTitle
                bg="purple.100"
                rounded="full"
                color="purple.400"
                fontSize="10px"
                size="sm"
                name={strings.min_stock_reached}
              />
            </span>
          </Tooltip>
        )}
        {inventory.total_quantity === 0 && (
          <Tooltip
            hasArrow
            label={`${strings.min_stock_reached_description} ${inventory.min_stock}.`}>
            <span>
              <CustomTagTitle
                bg="red.100"
                rounded="full"
                color="red.400"
                fontSize="10px"
                size="sm"
                name={strings.out_of_stock}
              />
            </span>
          </Tooltip>
        )}
        {isMaxStockReached && (
          <Tooltip
            hasArrow
            label={`${strings.max_stock_reached_description} ${inventory.max_stock}.`}>
            <span>
              <CustomTagTitle
                bg="gray.100"
                rounded="full"
                color="gray.400"
                fontSize="10px"
                size="sm"
                name={strings.max_stock_reached}
              />
            </span>
          </Tooltip>
        )}
        <Text as="span" ml="2">
          {inventory.total_quantity}
        </Text>
      </Td>
      <Td>{inventory.unit_name}</Td>
      <Td>
        <RouterLink
          to={
            routes.mms.inventory.details.replace(
              ':id',
              inventory.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={`${strings.view} ${strings.inventory}`}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label="View Type"
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default InventoryListItem;
