import { AxiosPromise } from 'axios';
import http from 'utils/http';
import Resource from './resource';
class BakeryBillingResource extends Resource {
  constructor() {
    super('rms/bakery-billing');
  }

  getSum(id: any): AxiosPromise<any> {
    return http({
      url: '/rms/bakery-billing-sum/sum/' + id,
      method: 'get',
    });
  }
  export(query: any) {
    return http({
      url: '/' + this.uri + '/export',
      params: query,
      method: 'get',
      responseType: 'blob',
    });
  }
  updateBilling(resource: any): AxiosPromise<any> {
    return http({
      url: '/rms/bakery-billing',
      method: 'put',
      data: resource,
    });
  }
  closeBill(id: string | number): AxiosPromise<any> {
    return http({
      url: '/rms/bakery-billing-confirm/' + id,
      method: 'put',
    });
  }
}

export { BakeryBillingResource as default };
