import { IconType } from 'react-icons/lib';
import { LANGUAGE_OPTIONS } from './common';

export type DataWrapperSchema<T> = {
  data: T;
  meta: Meta;
  links: {
    first: string;
    last: string | null;
    next: string | null;
    prev: null;
  };
};

export interface Meta {
  current_page: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}
export interface DataWrapperWithSuccess<T> {
  data: T;
  success: boolean;
  message: string;
}
export type Binary = 0 | 1;

export type TicketSchema = { [key: string]: Object[] };

export type LanguageTypesSchema = 'de' | 'pl' | 'en';
export type UserLanguageTypeSchema = 'en' | 'pl' | 'da' | 'zh';

export type UserSchema = {
  foreign_language: UserLanguageTypeSchema[] | null;
  status: boolean | undefined;
  id: number;
  first_name: string;
  last_name: string;
  contact_number: string;
  address: string;
  email: string;
  role: string;
  role_id: number;
  profile_picture_url: string;
  has_2fa_enabled: boolean;
  created_at: string;
  permissions: string[];
  email_verified_at?: string;
  is_deletable: boolean;
};

export type UserLoginLogSchema = {
  id: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    profile_picture_url: string;
  };
  status: 'login' | 'logout';
  login_ip: string;
  created_at: string;
};

export type ExternalCompanySchema = {
  id: number;
  name: string;
  email: string | null;
  phone: string;
};

export type TicketInfoSchema = {
  id: number;
  title: string;
  apartment_id: number | null;
  apartment: {
    name: string;
    person_in_charge: number | null;
    substitute_person_in_charge: number | null;
    responsible_person: ResponsibleType | null;
    substitute_responsible_person: ResponsibleType | null;
  };
  assigned_user_id: number | null;
  assigned_user: {
    name: string;
    status: boolean;
    image_url: string;
  };
  external_company: {
    id: number;
    name: string;
    phone: string;
  };
  customer_id: number | null;
  cost_recipient: number | null;
  house_owner: number | null;
  priority: TICKET_TYPE;
  description: string;
  recursive_interval: string;
  recursive_from: string;
  recursive_until: string;
  updated_by: number;
  status: string;
  is_billable: boolean;
  created_by_user: string;
  created_at: string;
  updated_by_user: string;
  updated_at: string;
  source: string;
  due_date: string;
  appointment_date: string;
};

export type RoleSchema = {
  id: number;
  name: string;
  description: string;
  users_count: number;
};

export type TicketLogSchema = { [key: string]: Object[] };

export type TicketCommentSchema = { [key: string]: Object[] };

export type PermissionItemSchema = {
  id: string;
  name: string;
  guard_name: string;
};

export type PermissionGroupSchema = {
  id: number;
  name: string;
  permissions: [PermissionItemSchema];
};

export type ApartmentSchema = {
  id: number;
  name: string;
};

export type CustomerSchema = {
  id: number;
  name: string;
};
export type PasswordSchema = {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
  confirm_password: string;
};

export type BillableSchema = {
  is_billable: boolean;
  spent_time: string;
  is_spent_time_updated: boolean;
};

export type ArticleTypeSchema = {
  name: string;
  description: string;
  created_by: number;
  updated_by: number;
};

export type ArticleUnitSchema = {
  name: string;
  description: string;
  created_by: number;
  updated_by: number;
};

export type ArticleSchema = {
  id: number;
  name: string;
  article_unit_type_id: number | null;
  articleUnitType: {
    id: number;
    name: string;
  };
  article_category_id: number | null;
  purchase_price: number | string;
  selling_price: number;
  seller: string;
  seller_detail: string;
  max_stock_limit: number | null;
  min_stock_limit: number | null;
  ean_number: string;
  description: string;
  image: FileList;
  file_name: string;
  mime_type: string;
  file_size: number;
  file_path: string;
  created_by: number;
  updated_by: number;
};

/**
 * Queries types for filtering article of MMS
 */
export type MMSUserSchema = {
  name: string;
  id: string;
  can_hold_item: 0 | 1;
  email: string;
};
export type ArticleFilterSchema = {
  name?: string;
  article_unit_type_id?: number;
  article_category_id?: number;
};

export type ShelfItemSchema = {
  id: number;
  name: string;
  warehouse_rack_id: number;
  created_by: number;
  updated_by: number | null;
  created_at: string;
  updated_at: string;
};

export type RackItemSchema = {
  id: number;
  name: string;
  warehouse_room_id: number;
  created_by: number;
  updated_by: number | null;
  created_at: string;
  updated_at: string;
  shelves: [ShelfItemSchema];
};

export type RoomItemSchema = {
  id: number;
  name: string;
  warehouse_id: number;
  created_by: number;
  updated_by: number | null;
  created_at: string;
  updated_at: string;
  racks: [RackItemSchema];
};

export type WarehouseSchema = {
  id: number;
  name: string;
  description: string;
  created_by: number;
  updated_by: number | null;
  created_at: string;
  updated_at: string;
  rooms: [RoomItemSchema];
};

export type TransferTypes =
  | 'STOCK_ACTIVITY_ACTIONS'
  | 'WAREHOUSE_SHELVES'
  | 'USERS';

export type TransferTypeLabels = 'fresh' | 'warehouse' | 'employee';
export type TransferTypeOptions = {
  label: TransferTypeLabels;
  value: TransferTypes;
}[];

export type StockTypes = 'max' | 'min' | 'out';

export type StockTypeLabels =
  | 'maximum_stock'
  | 'minimum_stock'
  | 'out_of_stock';
export type StockTypeOptions = {
  label: StockTypeLabels;
  value: StockTypes;
}[];

export type ReactSelectOptionSchema = {
  value: string | number;
  label: string;
};

export type MMSReactSelectOptionSchema = ReactSelectOptionSchema & {
  unit?: string;
};

export type ArticleSelectOptionSchema = {
  id: number;
  name: string;
  unit?: string;
};

export type TransferItemSchema = {
  article_id: ArticleSelectOptionSchema | null;
  quantity: number | null;
  source_entity_type: string;
  source_entity_id?: MMSReactSelectOptionSchema | null;
  destination_entity_type: string;
  destination_entity_id?: MMSReactSelectOptionSchema | null;
  room_id?: MMSReactSelectOptionSchema | null;
  rack_id?: MMSReactSelectOptionSchema | null;
  shelf_id?: MMSReactSelectOptionSchema | null;
};

export type SuggestedWarehouse = {
  article_id: number;
  article_name: string;
  available_quantity: string;
  entity_type: string;
  min_stock: string;
  max_stock: string;
  rack: string;
  room: string;
  shelf: string;
  shelf_id: string;
  warehouse: string;
};

export type SuggestedUser = {
  article_id: number;
  article_name: string;
  available_quantity: string;
  entity_type: string;
  min_stock: string;
  max_stock: string;
  user: string;
  user_id: number;
};

export type TransferLogSourceDestination = {
  item: string;
  id: number;
};

export type TransferLogQueryParams = {
  limit: number;
  page: number;
  article?: string;
  source?: TransferLogSourceDestination | null;
  destination?: TransferLogSourceDestination | null;
  transferred_by?: number | null;
  transferred_from?: Date | null;
  transferred_to?: Date | null;
  transfer_source?: string;
};

export type TransferLogSearchFilterInputs = {
  article: string;
  transferred_by: string | number;
  transfer_source: string;
  source_entity_type: string;
  source_entity_id: string | number;
  destination_entity_type: string;
  destination_entity_id: string | number;
  transferred_from: Date | null;
  transferred_to: Date | null;
};

export type InventorySchema = {
  id: number;
  name: string;
  unit_name: string;
  unit_description: string;
  total_quantity: number;
  min_stock: string;
  max_stock: string;
  status: boolean;
};

export type InventorySearchInput = {
  article: string;
  currentStock: string;
};
export type MyInventorySearchInput = {
  article: string;
};

export type InventoryWarehouseSchema = SuggestedWarehouse;
export type InventoryUserSchema = SuggestedUser;

export type InventoryDetailSchema = {
  warehouses: [InventoryWarehouseSchema];
  users: [InventoryUserSchema];
  apartments: [];
};

export type WarehouseSearchSchema = {
  currentPage: number;
  pageSize: number;
  keyword: string;
};

export type ChatUserSchema = {
  call_duration_days: number;
  created_date: string;
  email: string;
  in_progress_chat: number;
  name: string;
  number_of_chats: number;
  number_of_messages_received: number;
  number_of_messages_sent: number;
  status: boolean;
  total_call_duration: string;
  user_id: number;
  _id: string;
};

export type MetaSchema = {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
};

export type TicketStatusSchema = 'open' | 'inProgress' | 'done' | 'closed';

export type UploadFileSchema = {
  name: string;
  path: string;
  size: number;
  type: string;
};

export type SourceType = 'manual' | 'v-office' | 'app';

export type LabelType =
  | 'owner'
  | 'app'
  | 'web_app'
  | 'guest'
  | 'lead'
  | 'supplier';

export type YesNo = '0' | '1';

export type ContactSchema = {
  v_office_id: number | null;
  id: number;
  number: string | null;
  last_name: string;
  first_name: string;
  street: string | null;
  zip_code: string | null;
  location: string | null;
  company: string | null;
  house_no: string | null;
  country: string | null;
  language: string | null;
  letter_opening: string | null;
  letter_closing: string | null;
  salutation: string | null;
  title: string | null;
  birth_date: string | null;
  owner: boolean;
  phone: string | null;
  business_phone: string | null;
  mobile: string | null;
  fax: string | null;
  email: string | null;
  in_the: string | null;
  homepage: string | null;
  type: LabelType;
  source: SourceType;
  is_subscribed: boolean;
  contactEvent: {
    id: number;
    contact_id: number;
    event_id: number;
    event: string;
    event_time: string;
    created_at: string;
    updated_at: string;
  };
  contactDetail: {
    contact_id: number;
    fellow_travellers: string | null;
    pet: string | null;
    equipment_features: string | null;
    services: string | null;
    internal_notes: string | null;
    external_notes: string | null;
    is_merged: boolean;
    created_by: string | null;
    updated_by: string | number;
    created_at: Date;
    updated_at: Date;
  };
  contactMergeDetail?: {
    id: number;
    contact_id: number;
    details: {
      merge: number[];
      event: number[];
    };
  };
};

export type ContactInputSchema = {
  v_office_id: number | null;
  id: number;
  number: string | null;
  last_name: string | null;
  first_name: string | null;
  street: string | null;
  zip_code: string | null;
  location: string | null;
  company: string | null;
  house_no: string | null;
  country: string | null;
  language: string | null;
  letter_opening: string | null;
  letter_closing: string | null;
  salutation: string | null;
  title: string | null;
  birth_date: string | null;
  owner: boolean;
  phone: string | null;
  business_phone: string | null;
  mobile: string | null;
  fax: string | null;
  email: string | null;
  in_the: string | null;
  homepage: string | null;
  type: LabelType;
  source: SourceType;
  fellow_travellers: string | null;
  pet: string | null;
  equipment_features: string | null;
  services: string | null;
  internal_notes: string | null;
  external_notes: string | null;
  created_by: number;
  is_subscribed: boolean;
};

export type EventLogSchema = {
  id: number;
  contact_id: number;
  event_id: number;
  event_time: string;
  updated_at: string;
} & (DocumentEvent | BookingEvent | TicketCallEvent);

interface TicketCallEvent {
  event: 'ticket' | 'call';
  event_description: null;
}

interface DocumentEvent {
  event: 'document';
  event_description: {
    id: number;
    name: string;
    file_name: string;
    file_path: string;
    invoice_no: null | unknown;
    v_office_id: number;
  };
}

interface BookingEvent {
  event: 'booking';
  event_description: {
    id: number;
    booking_number: string;
    booking_id: number;
    apartment_id: number;
    status: string;
    booking_date: Date;
    booking_amount: number;
    action: string;
    booking_period: string;
  };
}

export type ServiceGroupSchema = {
  id: number;
  name: string;
  service_group_id: number;
  early_deliverable: YesNo;
  is_default: YesNo;
  show_on_page: YesNo;
  description: string;
  early_deliverable_start: YesNo;
  booking_fee: number;
  booking_fee_type: string;
  pay_to_vendor: YesNo;
  price_per_night: number;
  is_dog_service: YesNo;
  color: string;
  status: boolean;
  icon: string;
  cover: string;
  service_per_night: 0 | 1;
  descriptions: ServiceDescriptionsItem[];
  titles: ServiceTitleItem[];
};

export type ServiceGroupFormSchema = {
  id: number;
  service_group_id: number;
  early_deliverable: YesNo;
  is_default: YesNo;
  show_on_page: YesNo;
  early_deliverable_start: YesNo;
  booking_fee: number;
  booking_fee_type: string;
  pay_to_vendor: YesNo;
  price_per_night: number;
  is_dog_service: YesNo;
  color: string;
  status: boolean;
  icon: string;
  cover: string;
  service_per_night: 0 | 1;
  descriptions_de: string;
  descriptions_en: string;
  descriptions_pl: string;
  descriptions_da: string;
  descriptions_zh: string;
  titles_de: string;
  titles_en: string;
  titles_pl: string;
  titles_da: string;
  titles_zh: string;
};

export type ServiceDescriptionsItem = {
  id: number;
  language: LanguageAbbreviationType;
  description: string;
};

export type ServiceTitleItem = {
  id: number;
  language: LanguageAbbreviationType;
  title: string;
};

export type LanguageAbbreviationType = typeof LANGUAGE_OPTIONS[number]['value'];

export type LanguageDescriptionType =
  | 'description_german'
  | 'description_english'
  | 'description_danish'
  | 'description_polish';

export type ServiceSchema = {
  id: number;
  name: string;
  service_group_id: number;
  v_service: {
    id: number;
    name: string;
  };
  service_group: {
    id: number;
    name: string;
  };
  price_scheme_type: ServiceTypes;
  v_service_id: number;
  unit_price: number;
  description: string;
  product_code: string;
  status: number;
  cover: string;
  titles: ServiceTitleItem[];
  prices: any[]; // Todo: Give proper type for all price_scheme_type
};
export type ServiceTypes =
  | 'PriceSchemeByNone'
  | 'PriceSchemeByQuantity'
  | 'PriceSchemeByDuration'
  | 'PriceSchemeByDay'
  | 'PriceSchemeNewspaper'
  | 'PriceSchemeOneTime'
  | 'PriceSchemeByRequest'
  | 'PriceSchemeBeachChair';

export type ServiceTypeLabels =
  | 'none'
  | 'by_quantity'
  | 'by_duration'
  | 'by_day'
  | 'newspaper'
  | 'one_time_price'
  | 'by_request'
  | 'beach_chair';

export type ServiceTypeOptions = {
  label: ServiceTypeLabels;
  value: ServiceTypes;
}[];

export type BreadBookingSchema = {
  id: number;
  booking_id: number;
  status: string;
  reservation: {
    booking_number: string;
    apartment: {
      apartment_name: string;
    };
  };
  booking_date: string;
  customer: Customer;
  customer_name: string;
  action: string;
  services: string;
  apartment: {
    apartment_name: string;
  };
  booking_period: string;
  service: ServiceSchema;
  include_departure_day: boolean;
  bookings: any;
  payment: any;
};

export type BreadPriceBreakdownSchema = {
  taxableSum: number;
  bookingFee: number;
  vat19: number;
  vat7: number;
  totalBread: number;
  subTotal: number;
  finalTotal: number;
};

export type DrivingOrderSchema = {
  id: number;
  apartment: Apartment;
};

export type BreadDailyViewSchema = {
  id: number;
  customer: any;
  customer_name: string;
  additional_info: {
    booking_number: string;
  };
  bookings: any;
  payment: any;
  reservation: {
    bookings: {
      unit: string;
    };
    apartment: {
      apartment_name: string;
    };
  };
  delivery_date: string;
  guest_allocation: {
    allocation_number: number;
  };
};

export type ReservationRequestSchema = {
  id: number;
  booking_number: string;
  apartment: Apartment;
  customer: Customer;
  bookings: Bookings;
  booking_period: string;
  customer_name: string;
  service_bookings: any;
  created_at: string;
};

export type FerryBookingSchema = {
  id: number;
  client_booking_number: string;
  booking_date: string;
  status: 'confirmed' | 'cancelled' | 'pending';
  amount: number;
  customer_info: any;
  vehicles: any;
  apartment: {
    apartment_name: string;
  };
  booking_period: string;
  customer_name: string;
  bookings: any;
  service_bookings: any;
  customer: Customer;
};

export type GeneralDocumentSchema = {
  id: number;
  name: string;
  availability: string;
  availability_from_date: string;
  availability_to_date: string;
};

export type CustomerDocumentSchema = {
  id: number;
  name: string;
  availability_from_date: string | null;
  availability_to_date: string | null;
  created_at: string;
  file_name: string;
  file_path: string;
  customer: {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
  };
};

export type FAQSchema = {
  id: number;
  title: string;
  alias: string;
  description: string;
  status: 0 | 1;
  helpful_content: any;
  file_object: string;
  file_contact: string;
  file_object_name: string;
  file_contact_name: string;
  publish_to: {
    web: string;
    chat: string;
    internes: string;
  };
  faq_group: {
    id: number;
    name: string;
    status: 0 | 1;
  };
};

export type FeedbackQuestionSchema = {
  id: number;
  name: string;
  type: string;
  status: 0 | 1;
  serial: string;
};

export type FeedbackMailSchema = {
  id: number;
  status: string;
  reservation_id: number;
  last_email_sent_at: Date;
  feedback_at: Date;
  publish: string;
  rating: number;
  rating_calculation_flag: number;
  reservation: {
    id: string;
    apartment_id: number;
    booking_number: Date;
    from_date: Date;
    to_date: Date;
    publish: string;
    customer_info: {
      surname: string;
      forename: string;
      mainAddress: {
        country: string;
        postalcode: number;
      };
    };
    apartment: {
      id: number;
      name: string;
    };
  };
};

export type FeedbackSchema = {
  reservation_id: number;
  status: string;
  last_email_sent_at: Date;
  feedback_at: Date;
  publish: string;
  rating: number;
  rating_calculation_flag: number;
  reservation: {
    id: number;
    apartment_id: number;
    booking_number: string;
    from_date: Date;
    to_date: Date;
    publish: string;
    customer_info: Customer;
    apartment: {
      id: number;
      name: string;
    };
  };
};

export type MailStatus =
  | 'pending'
  | 'confirmed'
  | 'sent'
  | 'received'
  | 'closed'
  | 'no_email';

export type FEEDBACK_PUBLISH_STATUS_TYPE =
  | 'score_calculation'
  | 'object_publish'
  | 'website_publish'
  | 'not_publish';

export type ImportTypes = 'availabilities' | 'units' | 'services';
export type ImportTypesArray = ImportTypes[];
export interface NewsletterForm {
  subject: string;
  message: string;
  recipient_count: number;
  recipient_group: any;
  status: string;
  file: any;
}
export interface NewsletterDetail {
  id: number;
  subject: string;
  message: string;
  recipient_count: number;
  recipient_group: string[];
  status: string;
  created_at: string;
  updated_at: string;
  newsletterFiles: any;
}

export type PNSOptions = 'web' | 'app';
export type PNSOptionsArray = PNSOptions[];

export type MDM_LOGS_IMPORT_TYPES =
  | 'apartment'
  | 'contact'
  | 'booking'
  | 'invoice'
  | 'service'
  | 'newsletter_contact';
export type MDM_LOGS_IMPORT_TYPES_ARRAY = MDM_LOGS_IMPORT_TYPES[];

export type PRICE_CALCULATION_OPTION = {
  value: 0 | 1;
  text: 'per_day' | 'per_night';
};

export type TICKET_TYPE = 'low' | 'medium' | 'high' | 'urgent';

export type menuLabel =
  | 'user'
  | 'chat'
  | 'ticket'
  | 'crm'
  | 'contactCenter'
  | 'feedback'
  | 'mms'
  | 'mdm'
  | 'dms'
  | 'oms'
  | 'sms'
  | 'bms'
  | 'bs'
  | 'pms'
  | 'pns'
  | 'faq'
  | 'newsletter'
  | 'key'
  | 'aerialMap'
  | 'dashboard'
  | 'travelytics'
  | 'object-dashboard'
  | 'third-party-partner'
  | 'restaurant';

export type sidebarMenuListType = {
  service: string;
  menuLabel: menuLabel;
};

export type serviceRoute = [path: string, permission: permissionRoleSchema];

export type permissionRoleSchema =
  | 'read:user'
  | 'manage:external-companies'
  | 'read:login-log'
  | 'read:chat'
  | 'read:chat-metrics'
  | 'read:ticket'
  | 'read:crm'
  | 'merge:contacts'
  | 'read:contact-center'
  | 'read:feedback'
  | 'manage:feedback'
  | 'read:mms'
  | 'manage:stocktaking'
  | 'read:mdm'
  | 'read:dms'
  | 'read:customer-dms'
  | 'read:owner-dms'
  | 'read:object-dms'
  | 'read:user-dms'
  | 'read:oms'
  | 'read:sms'
  | 'read:reservations'
  | 'read:callbacks'
  | 'read:booking-terminals'
  | 'read:bookings'
  | 'read:bread-service'
  | 'read:bread-driving-order'
  | 'read:pms'
  | 'read:pns'
  | 'read:faq'
  | 'read:internal-faq'
  | 'read:faq-group'
  | 'read:newsletter'
  | 'read:keylog'
  | 'read:keylist'
  | 'read:aerial-map'
  | 'read:dashboard'
  | 'read:travelytics'
  | 'read:object-dashboard'
  | 'manage:cross-selling'
  | 'manage:third-party-partner'
  | 'manage:restaurant';

export type subMenusComp =
  | 'UserSubMenuList'
  | 'ChatSubMenuList'
  | 'TicketSubMenuList'
  | 'CrmSubMenuList'
  | 'ContactCenterSubMenuList'
  | 'MmsSubMenuList'
  | 'MdmSubMenuList'
  | 'FeedbackSubMenuList'
  | 'DMSSubMenuList'
  | 'OmsSubMenuList'
  | 'SMSSubMenuList'
  | 'BMSSubMenuList'
  | 'BSSubMenuList'
  | 'PMSSubMenuList'
  | 'PNSSubMenuList'
  | 'FAQSubMenuList'
  | 'NewsletterSubMenuList'
  | 'KeySubMenuList'
  | 'ObjectDashboardSubMenu'
  | 'ThirdPartyPartnerSubMenuList'
  | 'RestaurantSubMenuList'
  | 'null';

export type subMenuDataType = {
  base?: string; // Only required if there are multiple routes under that submenu
  link: string;
  text: subMenuTextType;
  hideFor?: string;
  permission: permissionRoleSchema;
};

export type subMenuTextType =
  | 'user_list'
  | 'user_roles'
  | 'external_companies'
  | 'user_login_log'
  | 'chat'
  | 'closed_chat'
  | 'archived_chat'
  | 'chat_metrics'
  | 'ticket_board'
  | 'ticket_list'
  | 'objects'
  | 'contacts'
  | 'merge_contact_sidebar'
  | 'uploads'
  | 'bookings'
  | 'invoices'
  | 'newsletter'
  | 'imports'
  | 'logs'
  | 'services'
  | 'service_group'
  | 'reservations'
  | 'reservation_requests'
  | 'ferry_bookings'
  | 'callbacks'
  | 'check_in_out'
  | 'marketing_email'
  | 'marketing_email_settings'
  | 'bread_bookings'
  | 'bread_booking_billing'
  | 'bread_daily_view'
  | 'bread_driving_order'
  | 'faq'
  | 'internal_faq'
  | 'group'
  | 'general_documents'
  | 'document_objects'
  | 'document_customers'
  | 'document_owners'
  | 'users_document'
  | 'credit_card'
  | 'paypal'
  | 'invoice_payment'
  | 'payment_links'
  | 'user'
  | 'total_calls'
  | 'feedback'
  | 'dashboard'
  | 'analysis'
  | 'mails'
  | 'questions'
  | 'setting'
  | 'newsletters'
  | 'drafts'
  | 'key_logs'
  | 'key_list'
  | 'notifications'
  | 'transfer_item'
  | 'inventories'
  | 'articles'
  | 'objects'
  | 'warehouses'
  | 'types'
  | 'units'
  | 'transfer_logs'
  | 'my_inventory'
  | 'my_transfer_logs'
  | 'qr_code'
  | 'cross_selling'
  | 'membership_level'
  | 'third_party_partner'
  | 'restaurant'
  | 'restaurant_reservation'
  | 'meer_sylt';

export type ServiceListSchema = {
  id: number;
  serviceName: menuLabel;
  hideForLabel: string;
  tooltipLabel: toolTipLabel;
  theme: string;
  themeColor: string;
  icon: IconType | string;
  splashLabel: splashLabel;
  bg: string;
  boxShadow: string;
  hover: {
    bg: string;
    boxShadow: string;
  };
  rootLink: string;
  routes: serviceRoute[];
  subMenusComp: subMenusComp;
};

export type splashLabel =
  | 'user_management'
  | 'chat'
  | 'ticket'
  | 'crm'
  | 'contact_center'
  | 'feedback'
  | 'mms'
  | 'mdm'
  | 'dms'
  | 'objects'
  | 'sms'
  | 'bms'
  | 'bs'
  | 'pms'
  | 'pns'
  | 'faq'
  | 'newsletter'
  | 'key'
  | 'aerial_map'
  | 'dashboard'
  | 'travelytics'
  | 'object_dashboard'
  | 'third_party_partner'
  | 'restaurant';

export type toolTipLabel =
  | 'user_management_system'
  | 'chat_system'
  | 'ticket_system'
  | 'customer_relation_management'
  | 'contact_center'
  | 'feedback_system'
  | 'merchandise_management_system'
  | 'master_data_magnagement'
  | 'document_management_system'
  | 'object_management_system'
  | 'service_management_system'
  | 'booking_management_system'
  | 'bread_system'
  | 'payment_management_system'
  | 'push_notification_system'
  | 'frequently_ask_question'
  | 'newsletter'
  | 'key_management_system'
  | 'aerial_map'
  | 'dashboard'
  | 'travelytics'
  | 'object_dashboard'
  | 'third_party_partner'
  | 'restaurant';

export type qrDataSchema = {
  title: string;
  value: string;
};

export type LanguageOptions = {
  value: UserLanguageTypeSchema;
  label: string;
};
export type ContactCenterUserListSchema = {
  id: number;
  name: string;
  total_call: string;
  total_incoming_call: string;
  incoming_call_duration: string;
  total_outgoing_call: string;
  outgoing_call_duration: string;
  average_call_duration: string;
  total_duration: string;
};

export interface ContactCenterTotalCallsSchema {
  id: number;
  customerPhoneNumber: string;
  direction: callDirection;
  startedAt: number;
  endedAt: number;
  calledDate: Date;
  internalPhoneNumber: string;
  missedCallReason: null;
  userId: number;
  contactId: number;
  createdAt: Date;
  user: ContactCenterUser;
  contact: ContactInformation;
  duration: string;
}

export type callDirection = 'inbound' | 'outbound';

export interface ContactInformation {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumbers: PhoneNumber[];
  customer_full_name: string;
}

export interface PhoneNumber {
  id: number;
  label: string;
  value: string;
}

export interface ContactCenterUser {
  id: number;
  name: string;
  email: string;
}

export type CallHistorySchema = {
  direction: 'inbound' | 'outbound';
  startedAt: number;
  endedAt: number;
  calledDate: string;
  duration: string;
};

export type AppointmentTodoExport = 'assignee' | 'externalCompany' | null;

export interface DMSDocumentFilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
  created_at: Date | null;
  available_from_date: Date | null;
  available_to_date: Date | null;
}

export type DMSDocumentSchema = {
  id: number;
  name: string;
  available_from_date: string;
  available_to_date: string;
  file_name: string;
  file_path: string;
  created_at: string;
};

export type DMSObjectDocumentSchema = DMSDocumentSchema & {
  apartment: {
    id: number;
    name: string;
  };
};

export type DMSCustomerObjectDocumentSchema = DMSDocumentSchema & {
  customer: {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
  };
};

export type DMSOwnerObjectDocumentSchema = DMSDocumentSchema & {
  owner: {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
  };
};

export type DMSUserDocumentSchema = DMSDocumentSchema & {
  user: {
    id: number;
    name: string;
  };
};

export type CheckInOutListSchema = {
  booking_number: string;
  customer_name: string;
  apartment_name: string;
  fromdate: string;
  tilldate: string;
  reservation_id: number;
  check_in_at: string | null;
  check_out_at: string | null;
};

export type CheckInOutDetailsSchema = {
  booking_number: string;
  booking: {
    adults: string;
    babys: string;
    children: string;
    fromdate: string;
    tilldate: string;
    unit: string;
  };
  customer: Omit<Customer, 'title'>;
  apartment: {
    id: number;
    fullname: string;
    image: {
      id: number;
      name: string;
    };
  };
  payment: Object | null;
  booking_terminals: {
    check_in_at: string;
    check_out_at: string;
  } | null;
  customer_id: number;
  reservation_id: number;
  booking_id: number;
};

export type CheckInOutFilterParams = {
  currentPage: number;
  pageSize: number;
  keyword: string;
  date: Date | null;
};

export type MainAddress = {
  city: string;
  street: string;
  country: string;
  postalcode: string;
  house_number: string;
};

export interface Customer {
  id: number;
  email: string;
  title: string;
  mobile: string;
  surname: string;
  forename: string;
  mainAddress: MainAddress;
}

export type Bookings = {
  hund?: number;
  dogs?: number;
  unit: number;
  babys: number;
  adults: number;
  children: number;
  fromdate: string;
  tilldate: string;
  amenities: any[];
  dog_breed?: any;
};

export type Address = {
  city: string;
  street: string;
  country: string;
  appendix: string;
  postalcode: string;
  housenumber: string;
};

export type Apartment = {
  id: number;
  apartment_name: string;
  info: {
    address: Address;
  };
};

export type ReservationListSchema = {
  id: number;
  booking_number: string;
  customer: Customer;
  bookings: Bookings;
  booking_period: string;
  apartment: Apartment;
  status: string;
  updated_at: string;
  booking_date: string;
  email_date: string | null;
  email_status: ReservationEmailStatus;
};

export type ReservationEmailStatus = 'planned' | 'sent' | 'resent';

export type PAYMENT_METHODS =
  | 'PAYPAL'
  | 'VISA'
  | 'MASTER'
  | 'MASTERCARD'
  | 'AMEX'
  | 'UNIONPAY';

export type PAYMENT_STATUS = 'confirmed' | 'pending';

export type PAYABLE_TYPE =
  | 'apartment_reservation'
  | 'service_reservation'
  | 'ferry_booking'
  | 'payment_link';

export type PaymentSchema = {
  id: number;
  payment_method: PAYMENT_METHODS;
  status: PAYMENT_STATUS;
  payable_type: PAYABLE_TYPE;
  payable_id: number;
  customer_name: string;
  date_time: string;
  customer_detail: {
    email_address: string;
  };
  amount: number;
  card_number: string;
};

export type PaymentLinkSchema = {
  id: number;
  status: PAYMENT_STATUS;
  customer_name: string;
  created_at: string;
  email: string;
  message: string;
  amount: number;
  email_sent_at: string;
  booking_number: string;
  payment: {
    payment_method: PAYMENT_METHODS;
    date_time: string;
  };
};

export type FeedbackQuestionListType = {
  id: number;
  name: string;
  serial: number;
  status: Binary;
  type: 'open' | 'closed' | 'rating';
  categories: PublishValueType[];
};

export type PublishValueType = 'start_page' | 'object' | 'service';

export type PublishOptionType = {
  value: PublishValueType;
  label: string;
};

export type ResponsibleType = {
  id: number;
  name: string;
};

export type UpdateNoteDataSchema = {
  apartment_id?: number;
  description: string;
};

export interface CrossSellingDetailInterface {
  id: number;
  service_name: string;
  description_german: string;
  description_english: string;
  description_danish: string;
  description_polish: string;
  description_chinese: string;
  link: string;
  image: string;
  updated_by: number;
  created_at: string;
  updated_at: string;
  user: {
    id: number;
    name: string;
  };
}

export type CrossSellingFormType = {
  service_name: string;
  description_german: string;
  description_english: string;
  description_danish: string;
  description_polish: string;
  description_chinese: string;
  link: string;
  image: string;
};

export interface CrossSellingEmailPayloadInterface {
  language: LanguageAbbreviationType;
  cross_selling_id: number[];
}

export type BookingStatus = 'confirmed' | 'pending';

export interface DashboardBookingListInterface {
  id: number;
  booking_number: string;
  booking_date: string;
  customer?: {
    surname: string;
    forename: string;
    mainAddress: {
      country: string;
      postalcode: string;
    };
  };
  status: BookingStatus;
  booking_period: string;
  apartment: {
    id: number;
    apartment_name: string;
  };
}

export type DashboardContactSchema = Omit<
  ContactSchema,
  'contactEvent' | 'contactMergeDetail'
>;
export interface DashboardOwnerDocument {
  id: number;
  name: string;
  available_from_date?: string;
  available_to_date?: string;
  file_name: string;
  file_path: string;
  owner: {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    email?: string;
  };
  created_at?: string;
}

export interface CustomerDocumentEmailPayloadInterface {
  email: string;
  customer_document_id: number[];
}

export interface MarketingEmailDaySetting {
  id: number;
  settings: MarketingSetting;
}
export interface MarketingSetting {
  email_notification_days: number;
}

export type UpdateMarketingEmailDayPayload = Omit<
  MarketingEmailDaySetting,
  'id'
>;
export interface DashboardCustomerContactList
  extends Omit<ContactSchema, 'contactEvent'> {
  contactEvent: BookingEvent[];
}

type KeyStatus = 'given out' | 'in stock';

export interface KeyDetailSchema {
  id: string;
  rfid: string;
  description: string;
  status: KeyStatus;
  apartmentId: number;
  createdBy: null | number;
  updatedBy: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
  keyLog: {
    id: string;
    notes: null;
    statusFrom: KeyStatus;
    statusTo: KeyStatus;
    customerId: number;
    keyId: string;
    reservationId: number;
    createdBy: number;
    createdAt: string;
    updatedAt: string;
    externalCompanyId: number;
    reservation: {
      id: number;
      bookingNumber: string;
    };
    customer: {
      id: number;
      firstName: string;
      lastName: string;
      fullName: string;
    };
  }[];
  createUser: null;
  updateUser: {
    id: number;
    fullName: string;
    deletedAt: null;
  };
  apartment: {
    id: number;
    name: string;
    images: {
      id: number;
      ask: number;
      name: string;
      width: number;
      height: number;
    };
  };
}

export interface PartnerGoodiesSchema {
  id: string;
  name: string;
  description: string;
  is_active: boolean;
}

export interface ThirdPartyPartnerSchema {
  id: string;
  name: string;
  brief_description: string;
  description: string;
  is_active: boolean;
  icon: string;
  partner_goodies: PartnerGoodiesSchema[];
}
export interface MembershipLevelSchema {
  id: string;
  name: string;
  description: string;
  brief_description: string;
  is_active: boolean;
  is_published: boolean;
  is_internal: boolean;
  number_of_booking: number;
  partner_goodies: PartnerGoodiesSchema[];
  icon: string;
  booking_discount: number;
  final_cleaning_discount: number;
  laundry_discount: number;
  website_condition_discount: number;
}

export interface RestaurantSchema {
  id: number;
  name: string;
  description: string;
  short_description: string;
  street: string;
  zip_code: string;
  house_number: string;
  location: string;
  city: string;
  website: string | null;
  reservation_count: number;
  is_top_pick: Binary;
  menu: string;
  opening_time: any;
  logo: string;
  cover: string;
  images: RestaurantImages[];
  slug: string | null;
  restaurant_reservation: Binary;
}

export interface RestaurantImages {
  file_url: string;
  file_name: string;
  file_type: string;
  file_size: number;
}

export interface OpeningTime {
  day: string;
  timing: string;
}

export enum MenuType {
  LINK = 'link',
  FILE = 'file',
}

export interface RestaurantReservationListSchema {
  id: string;
  booking_number: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  booking_date: string;
  booking_time: string;
  restaurant: string;
  restaurant_id: string;
}

export interface RestaurantFormDataObject {
  logo?: File | Blob | string | null;
  cover?: File | Blob | string | null;
  images?: File[];
  name: string;
  zip_code: string;
  street: string;
  house_number: string;
  location: string;
  menu?: string;
  openingTimes?: OpeningTime[];
  menu_file?: File | Blob | string | null;
  website?: string | null;
  description?: string | null;
  short_description?: string | null;
  is_top_pick: boolean;
  slug: string;
  restaurant_reservation: boolean;
}

export type MeerSyltSchema = {
  id: number;
  title: string;
  text: string;
  link: string;
  image: File | Blob | string;
  is_active: boolean;
  is_load_on_iframe: boolean;
  order: number;
  titles: MeerSyltTitleItem[];
  descriptions: MeerSyltDescriptionItem[];
};

export type MeerSyltTitleItem = {
  id: number;
  language: LanguageAbbreviationType;
  title: string;
};

export type MeerSyltDescriptionItem = {
  id: number;
  language: LanguageAbbreviationType;
  description: string;
};

export type MeerSyltFormSchema = {
  id: number;
  title: string;
  text: string;
  link: string;
  image: File | Blob | string;
  is_active: boolean;
  is_load_on_iframe: boolean;
  order: number;
  descriptions_de: string;
  descriptions_en: string;
  descriptions_pl: string;
  descriptions_da: string;
  descriptions_zh: string;
  titles_de: string;
  titles_en: string;
  titles_pl: string;
  titles_da: string;
  titles_zh: string;
};
