import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ArticleUnitsResource from 'api/article-units';
import { wrapperStyles } from 'assets/css/commonStyles';
import UnitListItem from 'components/article-unit/UnitListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const Units: React.FC = () => {
  const articleUnitsAPI = new ArticleUnitsResource();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const url_keyword = searchParams.get('keyword');

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: url_keyword ?? '',
  });

  // Access Control
  const checkAddUnitPermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.units.add
  );

  const unitList = useQuery(
    [
      'articleUnitList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await articleUnitsAPI.list(queryParams);
      return response?.data?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleReset = () => {
    history.push(routes.mms.units.list);
    if (!filterParams.keyword) return;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.all_units}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.mms.units.list}>
              {strings.all_units}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.units}
          </Heading>
          <Button
            type="button"
            colorScheme="primary"
            size="sm"
            onClick={checkAddUnitPermission}>
            {strings.add_unit}
          </Button>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="keyword"
                    value={filterParams.keyword}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>
              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  onClick={handleReset}
                  variant="outline">
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.unit_name}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {unitList?.data?.data.map((unit: any, index: number) => (
                  <UnitListItem
                    data={unit}
                    key={unit.id}
                    index={startingSN + index}
                    search={search}
                  />
                ))}
                {unitList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={unitList}
        />
      </Stack>
    </>
  );
};

export default Units;
