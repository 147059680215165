import { MeerSyltSchema } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

export interface UpdateOrderData {
  old_order: number;
  new_order: number;
}
class SMSMeerSyltResource extends Resource {
  constructor() {
    super('rms/meer-sylt');
  }

  updateOrder(data: UpdateOrderData) {
    return http({
      url: `rms/meer-sylt-order-update`,
      method: 'PUT',
      data: data,
    });
  }

  // This function performs an update operation by sending a POST request to the server with the provided resource data.
  // Note: Since FormData cannot be sent in a PUT request, we're using a POST request to mimic the update operation.
  update(id: number, resource: MeerSyltSchema) {
    return http({
      url: `/${this.uri}/${id}`,
      method: 'post',
      data: resource,
    });
  }
}

export { SMSMeerSyltResource as default };
