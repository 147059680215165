import {
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getChatUserDetail } from 'api/chat';
import { wrapperStyles } from 'assets/css/commonStyles';
import ChatMessageDetails from 'components/chat/ChatMessageDetails';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';

const ArchivedChatDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const [scrollEl, setScrollEl] = useState<any>();
  const { search } = useLocation();

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [scrollEl]);

  const {
    isLoading,
    data: archivedChatDetails,
    isError,
  } = useQuery(
    ['chatDetail', id],
    () => getChatUserDetail(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      retry: false,
    }
  );

  if (isError) {
    history.push(routes.chat.archived.list);
  }

  if (isLoading) return <CenterSpinner />;

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.chat} | {archivedChatDetails?.name}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.chat.default}>
            {strings.chat}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.chat.archived.list + search}>
            {strings.archived_chat}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink> {archivedChatDetails?.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading size="md" textTransform="capitalize">
        {strings.archived_chat_details}
      </Heading>
      <Stack direction="column" flex="1" spacing="4" pos="relative">
        <Box p="4" bg="white" shadow="box" rounded="md">
          <Stack direction="row" spacing="6">
            <Avatar size="md" showBorder shadow="lg" />
            <Stack direction="column" alignSelf="center">
              <Heading size="sm" fontWeight="medium">
                {archivedChatDetails?.name}
              </Heading>
            </Stack>
            <Spacer />
            <Stack direction="row" color="gray.300" alignSelf="flex-end">
              <Text>{DateFormat(archivedChatDetails.close_archive_date)}</Text>
            </Stack>
          </Stack>
        </Box>
        <Stack sx={wrapperStyles}>
          <PerfectScrollbar
            containerRef={(ref: any) => {
              setScrollEl(ref);
            }}>
            <Stack p="4">
              {archivedChatDetails?.messages?.map((message: any) => {
                return (
                  <ChatMessageDetails key={message.sender} message={message} />
                );
              })}
            </Stack>
          </PerfectScrollbar>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ArchivedChatDetails;
