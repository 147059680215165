import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import BakeryBillingResource from 'api/bakery_billing';
import BreadBookingBillingInfo from 'components/bms_bread_booking_billing/BreadBookingBillingInfo';
import BreadBookingBillingServiceInfoEdit from 'components/bms_bread_booking_billing/BreadBookingBillingServiceInfoEdit';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const BreadBookingBillingEdit: React.FC = () => {
  const { id }: any = useParams();
  const { search } = useLocation();

  const bakeryBillingAPI = new BakeryBillingResource();

  const bakeryBillingDetail = useQuery(
    [`bakery-billing-edit${id}`, id],
    () =>
      bakeryBillingAPI
        .get(id)
        .then((res) => res.data.data)
        .catch(() => {
          throw new Error('Error');
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (bakeryBillingDetail.isLoading) {
    return <CenterSpinner />;
  } else if (bakeryBillingDetail.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        {strings.error}
      </Alert>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bs} | {strings.edit_bread_booking}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bs}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bs.breadBookingBilling.list + search}>
              {strings.bread_booking_billing}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {bakeryBillingDetail?.data?.reservation?.booking_number ?? '-'}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <BreadBookingBillingInfo breadBookingData={bakeryBillingDetail.data} />

        <BreadBookingBillingServiceInfoEdit
          breadBookingData={bakeryBillingDetail.data}
        />
      </Stack>
    </>
  );
};

export default BreadBookingBillingEdit;
