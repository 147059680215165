import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ArticleTypesResource from 'api/article-types';
import { wrapperStyles } from 'assets/css/commonStyles';
import TypeForm from 'components/article-type/TypeForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ArticleTypeSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import history from 'utils/history';

const defaultValues = {
  name: '',
  description: '',
};

const AddType: React.FC = () => {
  // Library Init
  const methods = useForm<ArticleTypeSchema>({
    defaultValues: defaultValues,
  });
  const toast = useToast();

  // API Resource
  const articleTypesAPI = new ArticleTypesResource();

  // Local State
  const [errMsg, setErrMsg] = useState('');

  // Redux Selector
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const createArticleType = useMutation((data: any) =>
    articleTypesAPI.store(data)
  );

  const onSubmit = (data: ArticleTypeSchema) => {
    data.created_by = loggedInUser.id;
    createArticleType.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: `${strings.type} ${strings.has_been_created}`,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.mms.types.list);
      },
      onError: () =>
        setErrMsg(`${strings.type} ${strings.has_not_been_created}`),
    });
  };

  const handleReset = () => {
    methods.reset();
    setErrMsg('');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.add_type}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.types.list}>
              {strings.type}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.mms.types.add}>
              {strings.add_type}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md">{strings.add_type}</Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <Stack direction="column" spacing="4">
                <TypeForm />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    isLoading={createArticleType.isLoading}
                    type="submit">
                    {strings.add_now}
                  </Button>
                  <Button variant="outline" onClick={handleReset}>
                    {strings.clear}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddType;
