import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { Flex, Heading, Stack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import RMSCrossSellingResource from 'api/rms-cross-selling';
import { wrapperStyles } from 'assets/css/commonStyles';
import CrossSellingFrom from 'components/object-dashboard/cross-selling/CrossSellingFrom';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { CrossSellingFormType } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { buildFormData } from 'utils';

const AddCrossSelling = () => {
  const history = useHistory();
  const methods = useForm<CrossSellingFormType>();
  const toast = useToast();
  const crossSellingAPi = new RMSCrossSellingResource();
  const queryClient = useQueryClient();

  const addCrossSelling = useMutation({
    mutationFn: (data: any) => crossSellingAPi.store(data),
    onSuccess: () => {
      toast({
        title: strings.successfully_added_cross_selling,
        status: 'success',
        isClosable: true,
        duration: 3000,
      });
      queryClient.invalidateQueries({
        queryKey: ['cross-selling-list'],
      });
      history.push(routes.objectDashboard.crossSelling.list);
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
      });
    },
  });

  const onSubmit = (formData: any) => {
    if (!formData?.image) {
      methods.setError('image', { message: strings.required });
      return;
    }
    addCrossSelling.mutate(buildFormData(formData));
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.dashboard} | {strings.add_cross_selling}
        </title>
      </Helmet>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.objectDashboard.crossSelling.list}>
            {strings.cross_selling}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.add_cross_selling}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.add_cross_selling}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="5">
              <CrossSellingFrom />
              <ButtonGroup>
                <Button
                  type="submit"
                  colorScheme="primary"
                  isLoading={addCrossSelling.isLoading}>
                  {strings.add}
                </Button>
                <Button
                  variant="outline"
                  colorScheme="primary"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddCrossSelling;
