import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import RestaurantResource from 'api/restaurant';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { TOAST_DURATION, TOAST_STATUS } from 'constants/common';
import routes from 'constants/routes';
import { OpeningTime, RestaurantImages } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BiArrowFromLeft } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

const RestaurantDetails: React.FC = () => {
  const { id: restaurantId } = useParams<{ id: string }>();

  let RestaurantAPI = new RestaurantResource();
  const toast = useToast();

  const { data: restaurantDetail, isLoading } = useQuery(
    ['restaurant-details-page', restaurantId],
    () => {
      if (!restaurantId) return null; // { data: null };
      return RestaurantAPI.get(restaurantId)
        .then((res) => res.data.data)
        .catch(() => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: TOAST_STATUS.ERROR,
            duration: TOAST_DURATION.LONG,
            isClosable: true,
          });
        });
    }
  );

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }

  const openingTimes = restaurantDetail?.opening_time
    ? JSON.parse(restaurantDetail?.opening_time)
    : '';

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.restaurant} | &nbsp;
            {strings.restaurant_detail}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.restaurant.restaurant.list}>
              {strings.restaurant}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.restaurant_detail}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.restaurant_detail} {restaurantDetail?.name}
          </Heading>
          <RouterLink
            to={routes.restaurant.restaurant.edit.replace(
              ':id',
              restaurantId.toString()
            )}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.edit_restaurant}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Grid
            gap="4"
            gridTemplateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%"
            alignItems="center">
            <GridItem colSpan={[1, 2]}>
              <FormControl>
                <FormLabel>{strings.name}</FormLabel>
                <Box>{restaurantDetail?.name}</Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 2]}>
              <FormControl>
                <FormLabel>{strings.slug}</FormLabel>
                <Box>{restaurantDetail?.slug}</Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 2]}>
              <FormControl>
                <FormLabel>{strings.possible}</FormLabel>
                {!!restaurantDetail?.restaurant_reservation
                  ? strings.yes
                  : strings.no}
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 2]}>
              <FormControl>
                <FormLabel>{strings.is_top_pick}</FormLabel>
                {!!restaurantDetail?.is_top_pick ? strings.yes : strings.no}
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{strings.street}</FormLabel>
                <Text>{restaurantDetail?.street}</Text>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.house_number}</FormLabel>
                <Text>{restaurantDetail?.house_number}</Text>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.zip_code}</FormLabel>
                <Text>{restaurantDetail?.zip_code}</Text>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{strings.location}</FormLabel>
                <Text>{restaurantDetail?.location}</Text>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 4]}>
              <FormControl>
                <FormLabel>{strings.website}</FormLabel>
                <Text>
                  {restaurantDetail?.website ? (
                    <Link
                      as={RouterLink}
                      to={restaurantDetail?.website}
                      target="_blank"
                      rel="noreferrer">
                      <Flex alignItems="center">
                        <Text marginRight="2" color="blue.600">
                          {strings.view_website}
                        </Text>
                        <Icon as={BiArrowFromLeft} color="blue.600" />
                      </Flex>
                    </Link>
                  ) : (
                    '-'
                  )}
                </Text>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[1, 4]}>
              <FormControl>
                <FormLabel>{strings.menu}</FormLabel>
                <Text>
                  {restaurantDetail?.menu ? (
                    <Link
                      as={RouterLink}
                      to={restaurantDetail?.menu}
                      target="_blank"
                      color="blue"
                      rel="noreferrer">
                      <Flex alignItems="center">
                        <Text marginRight={2} color="blue.600">
                          {strings.view_menu}
                        </Text>
                        <Icon as={BiArrowFromLeft} color="blue.600" />
                      </Flex>
                    </Link>
                  ) : (
                    '-'
                  )}
                </Text>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 4]}>
              <FormControl>
                <FormLabel>{strings.opening_time}</FormLabel>
                <Grid templateColumns="repeat(2, 1fr)" gap="4">
                  {Array.isArray(openingTimes) &&
                    openingTimes?.map(
                      (openingTime: OpeningTime, index: number) => (
                        <React.Fragment key={index}>
                          <Text>{openingTime.day}:</Text>
                          <Box key={index}>
                            <Text>{openingTime.timing}</Text>
                          </Box>
                        </React.Fragment>
                      )
                    )}
                </Grid>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 4]}>
              <FormControl>
                <FormLabel>{strings.reservation_count}</FormLabel>
                <Text>{restaurantDetail?.reservation_count}</Text>
              </FormControl>
            </GridItem>

            <GridItem colSpan={[2, 4]}>
              <FormControl>
                <FormLabel>{strings.short_description}</FormLabel>
                <Text>{restaurantDetail?.short_description || '-'}</Text>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 4]}>
              <FormControl>
                <FormLabel>{strings.description}</FormLabel>
                <Text>{restaurantDetail?.description || '-'}</Text>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 4]}>
              <FormControl>
                <FormLabel>{strings.logo}</FormLabel>
                <Box>
                  <Image
                    src={restaurantDetail?.logo}
                    alt={restaurantDetail?.logo}
                    width="150"
                    height="150"
                  />
                </Box>
              </FormControl>
            </GridItem>
            <GridItem colSpan={[2, 4]}>
              <FormControl>
                <FormLabel>{strings.cover_image}</FormLabel>
                <Box>
                  <Image
                    src={restaurantDetail?.cover}
                    alt={restaurantDetail?.cover}
                    width="150"
                    height="150"
                  />
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
        <Stack sx={wrapperStyles}>
          <Heading fontSize="14px">{strings.images}</Heading>
          <Grid gap="4" templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}>
            {restaurantDetail?.images?.map(
              (item: RestaurantImages, index: number) => (
                <GridItem key={index}>
                  <Box>
                    <Image
                      src={item?.file_url}
                      alt={item?.file_name}
                      width="150"
                      height="150"
                    />
                  </Box>
                </GridItem>
              )
            )}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default RestaurantDetails;
