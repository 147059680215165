import { Box, Grid, GridItem, Heading, Stack } from '@chakra-ui/layout';
import { Flex, Image } from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import { ReservationBooking } from 'constants/schemas/reservationBooking';
import BookingServicePDF from 'pages/dashboard/bms/Booking/BookingServicePDF';
import React, { forwardRef } from 'react';

const infoStyles = {
  color: 'gray.900',
  fontWeight: 'normal',
};

interface Props {
  bookingDetailData: ReservationBooking;
  ref: any;
}

const BookingDetailsPDF = forwardRef((props: Props, ref: any) => {
  const { bookingDetailData } = props;
  const { customer, bookings, payment, reservation, status } =
    bookingDetailData;

  return (
    <Stack direction="column" id="booking-detail-print" ref={ref}>
      <Flex justify="space-between">
        <Stack pl={2} direction="row" alignItems="center">
          <Image src={Logo} width="80px" alt="ListInfo Logo" />
          <Stack ml="10px !important">
            <Heading size="lg" textTransform="capitalize">
              Listinfo Service Center
            </Heading>
            <Heading size="sm">Appartementvermittlung Familie Clausen</Heading>
          </Stack>
        </Stack>
        <Heading
          size="md"
          textTransform="capitalize"
          marginTop="10px"
          marginRight="10px">
          {strings.booking_detail}
        </Heading>
      </Flex>
      <Stack direction="column" p={6} marginTop="0 !important">
        <Grid gap="2" templateColumns={['repeat(2, 1fr)']} w="100%">
          <GridItem span={6}>
            <Box sx={infoStyles}>
              {strings.booking_number}: {reservation?.booking_number ?? '-'}
            </Box>
          </GridItem>
          <GridItem span={6}>
            <Box sx={infoStyles}>
              {strings.customer}: {bookingDetailData?.customer_name ?? '-'}
            </Box>
          </GridItem>
          <GridItem span={6}>
            <Box sx={infoStyles}>
              {strings.booking_period}: {reservation?.booking_period ?? '-'}
            </Box>
          </GridItem>
          <GridItem span={6}>
            <Box sx={infoStyles}>
              {strings.mobile}: {customer?.mobile ?? '-'}
            </Box>
          </GridItem>
          <GridItem span={6}>
            <Box sx={infoStyles}>
              {strings.units}: {reservation?.bookings?.unit ?? '-'}
            </Box>
          </GridItem>
          <GridItem span={6}>
            <Box sx={infoStyles}>
              {strings.status}: {strings[status] ?? '-'}
            </Box>
          </GridItem>
        </Grid>
      </Stack>
      {bookings?.length > 0 && (
        <BookingServicePDF
          bookings={bookings}
          payment={payment}
          status={status}
        />
      )}
    </Stack>
  );
});

BookingDetailsPDF.displayName = 'BookingDetailsPDF';

export default BookingDetailsPDF;
