import { Box, Grid } from '@chakra-ui/react';
import {
  SecondScreenBackdropEffect,
  SecondScreenBackgroundStyle,
} from 'assets/css/commonStyles';
import BackgroundImage from 'assets/images/banner2.jpg';
import { strings } from 'config/localization';
import React, { useEffect, useRef } from 'react';

const WelcomeScreen = () => {
  const text1 = useRef<any>(null);
  const text2 = useRef<any>(null);

  useEffect(() => {
    const texts = strings.second_screen_welcome_text.split(' ');
    const elts = {
      text1: text1.current,
      text2: text2.current,
    };
    const morphTime = 1;
    const cooldownTime = 0.25;

    let textIndex = texts.length - 1;
    let time = new Date();
    let morph = 0;
    let cooldown = cooldownTime;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];

    function doMorph() {
      morph -= cooldown;
      cooldown = 0;

      let fraction = morph / morphTime;

      if (fraction > 1) {
        cooldown = cooldownTime;
        fraction = 1;
      }

      setMorph(fraction);
    }

    // A lot of the magic happens here, this is what applies the blur filter to the text.
    function setMorph(fraction: any) {
      elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      fraction = 1 - fraction;
      elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      elts.text1.textContent = texts[textIndex % texts.length];
      elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    }

    function doCooldown() {
      morph = 0;

      elts.text2.style.filter = '';
      elts.text2.style.opacity = '100%';

      elts.text1.style.filter = '';
      elts.text1.style.opacity = '0%';
    }

    // Animation loop, which is called every frame.
    function animate() {
      requestAnimationFrame(animate);

      let newTime = new Date();
      let shouldIncrementIndex = cooldown > 0;
      let dt = (newTime.getTime() - time.getTime()) / 1000;
      time = newTime;

      cooldown -= dt;

      if (cooldown <= 0) {
        if (shouldIncrementIndex) {
          textIndex++;
        }

        doMorph();
      } else {
        doCooldown();
      }
    }

    // Start the animation.
    animate();
  }, []);

  return (
    <Box sx={SecondScreenBackgroundStyle} backgroundImage={BackgroundImage}>
      <Grid sx={SecondScreenBackdropEffect} placeItems="center">
        <Box position="absolute" top="calc(50% - 12vh)">
          <Box
            color="white"
            fontSize="10vw"
            fontWeight="900"
            letterSpacing={8}
            textTransform="uppercase"
            filter="url(#threshold) blur(0.6px);">
            <span
              className="animated-second-screen-welcome-text"
              ref={text1}></span>
            <span
              className="animated-second-screen-welcome-text"
              ref={text2}></span>
          </Box>
          <svg>
            <defs>
              <filter id="threshold">
                <feColorMatrix
                  in="SourceGraphic"
                  type="matrix"
                  values="1 0 0 0 0
									0 1 0 0 0
									0 0 1 0 0
									0 0 0 255 -140"
                />
              </filter>
            </defs>
          </svg>
        </Box>
      </Grid>
    </Box>
  );
};

export default WelcomeScreen;
