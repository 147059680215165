import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
interface Props {
  reservationRequestData: any;
}

const ReservationRequestCustomerInfo: React.FC<Props> = (props) => {
  const { reservationRequestData: customerData } = props;

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.customer_detail}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
          ]}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.first_name}</FormLabel>
              <Box sx={infoStyles}>{customerData?.forename || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.sur_name}</FormLabel>
              <Box sx={infoStyles}>{customerData?.surname || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.email}</FormLabel>
              <Box sx={infoStyles}>{customerData?.email || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.mobile}</FormLabel>
              <Box sx={infoStyles}>{customerData?.mobile || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.country}</FormLabel>
              <Box sx={infoStyles}>
                {customerData?.mainAddress?.country || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.street}</FormLabel>
              <Box sx={infoStyles}>
                {customerData?.mainAddress?.street || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.house_number}</FormLabel>
              <Box sx={infoStyles}>
                {customerData?.mainAddress?.house_number ?? '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.post_code}</FormLabel>
              <Box sx={infoStyles}>
                {customerData?.mainAddress?.postalcode || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.city}</FormLabel>
              <Box sx={infoStyles}>
                {customerData?.mainAddress?.city || '-'}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};
export default ReservationRequestCustomerInfo;
