import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import GeneralDocumentResource from 'api/general_document';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { DMSDocumentSchema } from 'constants/schema';
import FileSaver from 'file-saver';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useState } from 'react';
import { BiCloudDownload, BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { formatFileNameDMS } from 'utils';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  generalDocumentData: DMSDocumentSchema;
  index: number;
}
const GeneralDocumentListItem: React.FC<Props> = (props) => {
  const { generalDocumentData, index } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const queryClient = useQueryClient();
  const toast = useToast();

  const generalDocumentAPI = new GeneralDocumentResource();

  const downloadDocument = useMutation(generalDocumentAPI.downloadDocument, {
    onSuccess: (res) => {
      FileSaver.saveAs(res.data, formatFileNameDMS(generalDocumentData));
    },
    onError: () => {
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const deleteGeneralDocument = useMutation(
    () => generalDocumentAPI.destroy(generalDocumentData.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('generalDocumentList');
        setDeleteModalOpen(false);
        toast({
          title: strings.general_document_deleted,
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.message);
      },
    }
  );

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteGeneralDocument.mutate();
  };

  const onDownloadClick = () =>
    downloadDocument.mutate(generalDocumentData.file_name);

  const isDeletePermitted: boolean = useIsPermitted([
    PermissionRequest['manage:dms'],
  ]);

  return (
    <Tr key={generalDocumentData.id}>
      <Td>{index}</Td>
      <Td>{generalDocumentData?.name}</Td>
      <Td>{generalDocumentData?.created_at || '-'} </Td>
      <Td>{DateFormat(generalDocumentData?.available_from_date) || '-'}</Td>
      <Td>{DateFormat(generalDocumentData?.available_to_date) || '-'}</Td>

      <Td>
        <RouterLink
          to={routes.dms.generalList.edit.replace(
            ':id',
            generalDocumentData.id.toString()
          )}>
          <Tooltip hasArrow label={strings.edit_general_document}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_general_document}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_general_document}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_general_document}
            color="red.300"
            minW="6"
            isDisabled={!isDeletePermitted}
            onClick={onDeletePress}
          />
        </Tooltip>
        {generalDocumentData?.file_path && (
          <Tooltip hasArrow label={strings.download}>
            <IconButton
              icon={<BiCloudDownload />}
              variant="link"
              aria-label={strings.download}
              minW="6"
              onClick={onDownloadClick}
            />
          </Tooltip>
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_general_document}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteGeneralDocument.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default GeneralDocumentListItem;
