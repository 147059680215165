import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import {
  ChatActionTypes,
  setCurrentChatUser as setCurrentChatUserInStore,
  setEmployeeList,
} from 'actions/data/chat';
import { getChatMetrics, getChatUserDetail } from 'api/chat';
import ChatBot from 'components/chat/ChatBot';
import ChatNotAvailable from 'components/chat/ChatNotAvailable';
import ChatSearch from 'components/chat/ChatSearch';
import ChatUserDetails from 'components/chat/ChatUserDetails';
import TicketForm from 'components/chat/TicketForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { socket } from 'services/socket';
import UserStatusSwitch from './UserStatusSwitch';

const PHONE_RINGING_TIMEOUT = 60000; // 60 secs;

interface Message {
  message: String;
  sender: String;
  time: String;
}

interface LocationState {
  currentChatUserId: string;
}

const Chat: React.FC = () => {
  /**
   * Initialize
   */
  const dispatch = useDispatch();
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [room, setRoom] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [empName, setEmpName] = useState('');
  const location = useLocation<LocationState>();
  const [currentChatUserId, setCurrentChatUserId] = useState('');
  const [phoneRingingRoom, setPhoneRingingRoom] = useState<string>(
    () => localStorage.getItem('phoneRingingRoom') || ''
  );
  const [callInProgressRoom, setCallInProgressRoom] = useState<string>(
    () => localStorage.getItem('callInProgressRoom') || ''
  );
  const [modalBodyText, setModalBodyText] = useState<string>('');

  const {
    loggedInUser,
    currentChatUserDetails,
    employees,
    loggedInUserPermissions,
    employeeSearchLoading: isLoading,
  } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      currentChatUserDetails: state?.data?.chat.currentChatUser,
      employees: state?.data?.chat.employees,
      loggedInUserPermissions: state?.data?.auth?.permissions,
      employeeSearchLoading: state?.data?.chat.employeeSearchLoading,
    }),
    shallowEqual
  );

  const [currentChatUser, setCurrentChatUser] = useState<any>();
  const toast = useToast();
  const isLocationStateRead = useRef(false);
  const { observer, visible } = useIntersectionObserver();

  useEffect(() => {
    dispatch({ type: ChatActionTypes.SET_CHAT_PAGE_VISIBLE, data: visible });

    return () => {
      dispatch({ type: ChatActionTypes.SET_CHAT_PAGE_VISIBLE, data: false });
    };
  }, [dispatch, visible]);

  /**
   * Handle sockets related to call
   */
  useEffect(() => {
    socket.on('joined call', (data: any) => {
      handleSetPhoneRingingRoom('');
      handleCallInProgressRoom(room);
      window.open(`${process.env.REACT_APP_MEDIASOUP_URL}=${room}`, '_blank');
    });
    socket.on('declined call', (data: any) => {
      setModalBodyText(strings.miss_called);
      setModalOpen(true);
      handleSetPhoneRingingRoom('');
    });
    socket.on('call ended', (data: any) => {
      handleSetPhoneRingingRoom('');
      handleCallInProgressRoom('');
    });
    socket.on('call dropped', (data: any) => {
      setModalBodyText(strings.call_cancelled);
      handleSetPhoneRingingRoom('');
      handleCallInProgressRoom('');
      setModalOpen(true);
    });
    return () => {
      socket.off('joined call');
      socket.off('declined call');
      socket.off('call ended');
      socket.off('call dropped');
    };
  });

  /**
   * Check Employee List
   */
  const fetchEmployeeList = async () => {
    if (employees.length === 0) {
      const response = await getChatMetrics();
      dispatch(setEmployeeList(response.data));
    }
  };

  /**
   * Current Chat User Details by Id
   */
  const fetchCurrentChatUser = async () => {
    if (currentChatUserId === '') return currentChatUserDetails;
    const response = await getChatUserDetail(currentChatUserId);
    setCurrentChatUserId('');
    setCurrentChatUser(response.data.data);
    dispatch(setCurrentChatUserInStore(response.data.data));
  };

  useQuery([`fetchEmployeeList`, employees], () => fetchEmployeeList());

  useQuery(
    currentChatUserId && [`fetchCurrentChatUser`, currentChatUserId],
    () => fetchCurrentChatUser(),
    {
      enabled: !!isLocationStateRead.current,
    }
  );

  /**
   * Check Room
   */
  useEffect(() => {
    if (currentChatUser?.room_name) {
      const { first_name, last_name } = loggedInUser;
      const username = `${first_name} ${last_name}`;
      const { room_name, messages } = currentChatUser;
      setChatMessages(messages);
      setRoom(room_name);
      setEmpName(username);
      socket.emit(
        'join room',
        {
          username: loggedInUser.name,
          roomName: room_name,
          isAdmin: true,
        },
        (cb: any) => {
          if (cb.status !== 'ok')
            toast({
              title: strings.join_room_error,
              status: 'error',
              isClosable: true,
            });
        }
      );
    }
  }, [currentChatUser, currentChatUserDetails, loggedInUser, toast]);

  useEffect(() => {
    if (
      Object.keys(currentChatUserDetails).length > 0 &&
      currentChatUserId === ''
    ) {
      setCurrentChatUser(currentChatUserDetails);
    }
  }, [currentChatUserDetails, currentChatUserId]);

  useEffect(() => {
    const currentChatUserId = location.state?.currentChatUserId;
    if (currentChatUserId) {
      setCurrentChatUserId(currentChatUserId);
      // clear history.location.state
      window.history.replaceState({}, document.title);
    }
    isLocationStateRead.current = true;
  }, [location.state]);

  /**
   * If the customer doesn't receive or decline the call for 60 secs,
   * set phone icon styles to default
   */
  useEffect(() => {
    if (!phoneRingingRoom) return;
    const timer = setTimeout(() => {
      handleSetPhoneRingingRoom('');
      setModalOpen(true);
    }, PHONE_RINGING_TIMEOUT);
    return () => clearTimeout(timer);
  }, [phoneRingingRoom]);

  /**
   * Persist state for phoneRingingRoom and callInProgressRoom so that
   * active phone call is shown correctly on page refresh
   *
   */
  useEffect(() => {
    localStorage.setItem('phoneRingingRoom', phoneRingingRoom);
    return () => localStorage.setItem('phoneRingingRoom', '');
  }, [phoneRingingRoom]);

  useEffect(() => {
    localStorage.setItem('callInProgressRoom', callInProgressRoom);
    return () => localStorage.setItem('phoneRingingRoom', '');
  }, [callInProgressRoom]);

  /**
   * Clean up:
   * Emptify currentChatUser in redux store
   */
  useEffect(() => {
    return () => {
      dispatch(setCurrentChatUserInStore({}));
    };
  }, [dispatch]);

  /**
   * Store the name of the room for which the phone is ringing
   * Send empty string in the param if none of the phone is ringing
   *
   * @param roomName
   */
  const handleSetPhoneRingingRoom = (roomName: string) => {
    setPhoneRingingRoom(roomName);
  };

  /**
   * Store the name of the room whose call is in progress
   * Send empty string in the param if no room is in a call
   *
   * @param roomName
   */
  const handleCallInProgressRoom = (roomName: string) => {
    setCallInProgressRoom(roomName);
  };

  const isChatMsgDetailAvailable = currentChatUser?._id;

  if (isLoading) return <CenterSpinner />;

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.chat} | {strings.chat}
        </title>
      </Helmet>
      <div ref={observer}></div>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.chat}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Heading size="md" textTransform="capitalize">
          {strings.chat}
        </Heading>
        <Box>
          <UserStatusSwitch
            loggedInId={loggedInUser.id}
            loggedInUserPermissions={loggedInUserPermissions}
            isInActiveCall={callInProgressRoom !== ''}
          />
        </Box>
      </Flex>
      <Stack direction="row" spacing="4">
        <ChatSearch
          phoneRingingRoom={phoneRingingRoom}
          callInProgressRoom={callInProgressRoom}
        />
        {isChatMsgDetailAvailable ? (
          <>
            <Stack direction="column" flex="1" spacing="2" pos="relative">
              <ChatUserDetails
                chatMessageId={currentChatUser._id}
                type={
                  currentChatUser.registered_user_id === 0
                    ? 'closed'
                    : 'archived'
                }
                customerName={currentChatUser.name}
                roomName={room}
                userIdFrom={loggedInUser.id}
                phoneRingingRoom={phoneRingingRoom}
                callInProgressRoom={callInProgressRoom}
                handleSetPhoneRingingRoom={handleSetPhoneRingingRoom}
              />
              <ChatBot
                chatMessageDetails={chatMessages}
                user={empName}
                roomName={room}
                userId={loggedInUser.id}
              />
            </Stack>
            <HideControl
              hideFor="chat-service"
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <TicketForm roomName={room} />
            </HideControl>
          </>
        ) : (
          <ChatNotAvailable
            message={strings.chat_not_selected}
            imageWidth="15%"
          />
        )}
      </Stack>
      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => {
          setModalOpen(false);
          setModalBodyText('');
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>{modalBodyText}</ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Chat;
