import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { strings } from 'config/localization';
import React, { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  onClose: any;
}
const Disable2FA: React.FC<Props> = (props) => {
  const { onClose } = props;

  const queryClient = useQueryClient();
  const userAPI = new UserResource();
  const toast = useToast();

  const [error, setError] = useState<any>();
  const passwordRef = useRef<HTMLInputElement>(null);

  const disable2FA = useMutation((data: any) => userAPI.disable2FA(data), {
    onSuccess: (res: any) => {
      if (res?.data?.success) {
        onClose();
        toast({
          title: strings.fa_Disable,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('user-auth');
      } else {
        setError(strings.incorrect_password);
      }
    },
    onError: () => {
      setError(strings.invalid_data);
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    const password = passwordRef.current?.value;
    if (!password) {
      setError(strings.incorrect_password);
      passwordRef.current?.focus();
      return;
    }
    const data = {
      'current-password': password,
    };
    disable2FA.mutate(data);
  };

  return (
    <ModalContent>
      <form onSubmit={onSubmit}>
        <ModalHeader fontSize="1rem" w="95%">
          {strings.fa_disable_title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack dir="column" spacing="4">
            <Stack>
              <Grid
                gap="4"
                templateColumns={['repeat(1, 1fr)']}
                flex="1"
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.password}</FormLabel>
                    <Input
                      type="password"
                      placeholder={strings.password}
                      ref={passwordRef}
                      autoFocus
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </Stack>
            {error && (
              <Alert status="error" mb={2}>
                <AlertIcon />
                {error}
              </Alert>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="primary" type="submit">
              {strings.disable_2FA}
            </Button>
            <Button variant="outline" onClick={onClose}>
              {strings.cancel}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};

export default Disable2FA;
