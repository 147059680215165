import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import ArticleTypesResource from 'api/article-types';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

interface Props {
  data: any;
  index: number;
  search?: string;
}

const TypeListItem = (props: Props) => {
  // props
  const { data, index, search } = props;

  // Library Init
  const queryClient = useQueryClient();

  // API Resource
  const articleTypesAPI = new ArticleTypesResource();

  // Local State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');

  // Access Control
  const checkEditTypePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.types.edit.replace(':id', data.id.toString())
  );

  const deleteType = useMutation((id: number) => articleTypesAPI.destroy(id), {
    onSuccess: (res: any) => {
      setIsModalOpen(false);
      queryClient.invalidateQueries('articleTypeList');
    },
    onError: (error: any) => {
      if (error?.response?.data?.error?.code === 422)
        setDeleteMsg(strings.delete_type_error);
    },
  });

  const handleDelete = () => {
    deleteType.mutate(data.id);
  };

  const handleDeleteModal = () => {
    setDeleteMsg('');
    setIsModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteMsg('');
    setIsModalOpen(false);
  };

  return (
    <Tr key={index}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.mms.types.details.replace(':id', data.id.toString()) + search
          }>
          {data.name}
        </RouterLink>
      </Td>
      <Td>{data.description}</Td>
      <Td>
        <RouterLink
          to={
            routes.mms.types.details.replace(':id', data.id.toString()) + search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <Link onClick={checkEditTypePermission}>
          <Tooltip hasArrow label={strings.edit}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </Link>

        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            onClick={handleDeleteModal}
          />
        </Tooltip>
        <Modal isOpen={isModalOpen} isCentered onClose={handleCancelDelete}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{`${strings.delete} ${strings.type}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}

              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[
                    PermissionRequest['read:mms'],
                    PermissionRequest['manage:article'],
                  ]}
                  renderNoAccess={(data: any) => data}>
                  <Button
                    type="button"
                    colorScheme="red"
                    onClick={handleDelete}
                    isLoading={deleteType.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleCancelDelete}
                  isDisabled={deleteType.isLoading}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default TypeListItem;
