import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Divider, Grid, Stack, Text } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';
import { useToast } from '@chakra-ui/toast';
import RMSObjectsResource from 'api/rms-objects';
import { strings } from 'config/localization';
import { Binary } from 'constants/schema';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';

interface Props {
  disableRepair?: boolean;
  ownerRepairsAllowed: Binary;
  informOwnerRepairs: Binary;
  allowedRepairsMaxPrice: number | null;
}

const RepairPanel: React.FC<Props> = (props) => {
  const {
    disableRepair = false,
    ownerRepairsAllowed,
    informOwnerRepairs,
    allowedRepairsMaxPrice,
  } = props;

  const { id } = useParams<{ id: string }>();
  const objectID = Number(id);
  const queryClient = useQueryClient();
  const toast = useToast();
  const methods = useForm<any>();
  const apartmentAPI = new RMSObjectsResource();

  const { handleSubmit, register, setValue } = methods;

  setValue('allowed_repairs_max_price', allowedRepairsMaxPrice ?? 0);

  const apartmentDetailKey = `apartmentDetails-${objectID}`;

  const updateObject = useMutation(
    (data: any) => apartmentAPI.update(objectID, data),
    {
      onMutate: async (updateData) => {
        await queryClient.cancelQueries(apartmentDetailKey);
        const previousData = queryClient.getQueryData(apartmentDetailKey);
        queryClient.setQueryData(apartmentDetailKey, (oldQueryData: any) => ({
          ...oldQueryData,
          ...updateData,
        }));
        return previousData;
      },
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(apartmentDetailKey);
      },
      onError: (_err, _data, context) => {
        queryClient.setQueryData(apartmentDetailKey, context);
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: () => queryClient.invalidateQueries(apartmentDetailKey),
    }
  );

  function handleChangeStatus(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    const data = { [name]: checked ? 1 : 0 };
    updateObject.mutate(data);
  }

  function handleAmountSubmit(data: any) {
    if (!data?.allowed_repairs_max_price) {
      toast({
        status: 'error',
        title: strings.amount_is_required,
      });
      return;
    }
    updateObject.mutate(data);
  }
  return (
    <Stack direction="column" spacing="2rem">
      <Stack direction="row" fontWeight={500} spacing="4rem">
        <Box>
          <FormLabel>{strings.owner_allowed_repair}</FormLabel>
          <Switch
            colorScheme="green"
            name="owner_repairs_allowed"
            isChecked={Boolean(ownerRepairsAllowed)}
            onChange={handleChangeStatus}
            isDisabled={updateObject.isLoading || disableRepair}
          />
        </Box>
        <Box>
          <FormLabel>{strings.inform_owner}</FormLabel>
          <Switch
            colorScheme="green"
            name="inform_owner_repairs"
            isChecked={Boolean(informOwnerRepairs)}
            disabled={!ownerRepairsAllowed}
            onChange={handleChangeStatus}
            isDisabled={updateObject.isLoading || disableRepair}
          />
        </Box>
      </Stack>
      <Divider />
      <Grid templateColumns={['repeat(2,1fr)']}>
        <Stack direction="column" spacing="0.5rem">
          <Text as="p" fontWeight={500} color="heading">
            {
              strings.how_expensive_repairs_can_be_commissioned_without_consulting_with_the_owner
            }
          </Text>
          <Text as="p">{strings.in_euro}(&euro;)</Text>
        </Stack>
        <form onSubmit={handleSubmit(handleAmountSubmit)}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.amount}</FormLabel>
              <Input
                type="number"
                max="999999"
                {...register('allowed_repairs_max_price')}
                disabled={!ownerRepairsAllowed || disableRepair}
                placeholder="***********"
              />
            </FormControl>
            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                type="submit"
                isDisabled={!ownerRepairsAllowed || disableRepair}>
                {strings.save_now}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </Stack>
  );
};

export default RepairPanel;
