import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Textarea,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import { CrossSellingDetailInterface } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  crossSellingData?: CrossSellingDetailInterface;
}

const CrossSellingFrom: React.FC<Props> = (props) => {
  const { crossSellingData } = props;
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (!crossSellingData) return;
    setValue('service_name', crossSellingData.service_name);
    setValue('description_german', crossSellingData.description_german);
    setValue('description_english', crossSellingData.description_english);
    setValue('description_polish', crossSellingData.description_polish);
    setValue('description_danish', crossSellingData.description_danish);
    setValue('description_chinese', crossSellingData.description_chinese);
    setValue('link', crossSellingData.link);
  }, [crossSellingData, setValue]);

  return (
    <form>
      <Grid gap="4" templateColumns={['repeat(1,1fr)', 'repeat(2,1fr)']}>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.service_name}>
            <FormLabel>{strings.service_name}</FormLabel>
            <Input
              {...register('service_name', {
                required: strings.required_service_name,
              })}
              type="text"
              placeholder={strings.service_name}
            />
            {errors?.service_name?.message && (
              <FormErrorMessage>{errors.service_name.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem></GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.description_german}>
            <FormLabel>{strings.description_german}</FormLabel>
            <Textarea
              {...register('description_german', {
                required: strings.required,
              })}
              size="sm"
              placeholder={strings.description_german}
            />
            {errors?.description_german?.message && (
              <FormErrorMessage>
                {errors.description_german.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.description_english}>
            <FormLabel>{strings.description_english}</FormLabel>
            <Textarea
              {...register('description_english', {
                required: strings.required,
              })}
              size="sm"
              placeholder={strings.description_english}
            />
            {errors?.description_english?.message && (
              <FormErrorMessage>
                {errors.description_english.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.description_polish}>
            <FormLabel>{strings.description_polish}</FormLabel>
            <Textarea
              {...register('description_polish', {
                required: strings.required,
              })}
              size="sm"
              placeholder={strings.description_polish}
            />
            {errors?.description_polish?.message && (
              <FormErrorMessage>
                {errors.description_polish.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.description_danish}>
            <FormLabel>{strings.description_danish}</FormLabel>
            <Textarea
              {...register('description_danish', {
                required: strings.required,
              })}
              size="sm"
              placeholder={strings.description_danish}
            />
            {errors?.description_danish?.message && (
              <FormErrorMessage>
                {errors.description_danish.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.description_chinese}>
            <FormLabel>{strings.description_chinese}</FormLabel>
            <Textarea
              {...register('description_chinese', {
                required: strings.required,
              })}
              size="sm"
              placeholder={strings.description_chinese}
            />
            {errors?.description_chinese?.message && (
              <FormErrorMessage>
                {errors.description_chinese.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.link}>
            <FormLabel>{strings.link}</FormLabel>
            <Input
              {...register('link', {
                required: strings.required,
              })}
              type="text"
              placeholder={strings.link}
            />
            {errors?.link?.message && (
              <FormErrorMessage>{errors.link.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={!!errors?.image}>
            <FormLabel>{strings.image}</FormLabel>
            <ImageUploader
              fileKey="image"
              title={strings.drag_image_or_click_to_select_image}
              description={strings.upload_image}
              uploadedFiles={crossSellingData?.image}
            />
            {errors?.image?.message && (
              <FormErrorMessage>{errors.image.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
      </Grid>
    </form>
  );
};

export default CrossSellingFrom;
