import http from 'utils/http';
import Resource from './resource';

class FeedbackDashboardResource extends Resource {
  constructor() {
    super(`feedback/dashboard`);
  }

  dashboardObjectFeedbacks(id: number, query: any = {}) {
    return http({
      url: `feedback/dashboard/apartment/${id}/feedbacks`,
      method: 'GET',
      params: query,
    });
  }
  dashboardCustomerFeedbacks(id: number, query: any = {}) {
    return http({
      url: `feedback/dashboard/customer/${id}/feedbacks`,
      method: 'GET',
      params: query,
    });
  }
}

export { FeedbackDashboardResource as default };
