import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import KeyAPI from 'api/key';
import { DeviceOffline, InvalidKey, KeyScan } from 'assets/images';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useRef, useState } from 'react';
import { IoReload } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
interface Props {
  setScannedKeyId: (key: string | null) => void;
  onClose: () => void;
  databaseCheck?: boolean;
}

const GetNewKeyId: React.FC<Props> = (props) => {
  let { setScannedKeyId, onClose, databaseCheck } = props;
  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [rfid, setRfid] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [errorKey, setErrorKey] = useState<string>('');
  const inputRef: any = useRef(null);
  const keyAPI = new KeyAPI();
  const [KeyScanner, setKeyScanner] = useState<any>(KeyScan);
  const {
    data: keyDetails,
    isLoading,
    error,
    status,
  } = useQuery(
    ['get-key-details-from-key-id', rfid, refetch],
    () => keyAPI.get(rfid).then((res: any) => res.data.data),
    {
      refetchOnWindowFocus: false,
      enabled: !!rfid,
      retry: false,
    }
  );

  useEffect(() => {
    let handler = setTimeout(() => {
      if (inputValue) {
        if (databaseCheck) {
          setRefetch((t) => !t);
          setRfid(inputValue);
        } else {
          setScannedKeyId(inputValue);
          onClose();
        }
      }
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [databaseCheck, inputValue, onClose, setScannedKeyId]);

  useEffect(() => {
    //@ts-ignore
    if (status === 'error' && error?.response?.status === 404) {
      setScannedKeyId(inputValue);
      onClose();
    } else if (status === 'error') {
      setErrorKey('Cannot verify key');
      setKeyScanner(InvalidKey);
    }
  }, [error, inputValue, onClose, setScannedKeyId, status]);

  useEffect(() => {
    if (status === 'success') {
      setErrorKey('Key Already exists');
      setInputValue('');
      setKeyScanner(InvalidKey);
    }
  }, [status]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    function handleFocusOut(event: any) {
      setTimeout(() => {
        if (document.activeElement !== element) {
          setKeyScanner(DeviceOffline);
        }
      }, 500);
    }
    if (!inputRef.current) return;
    let element = inputRef.current;
    element.addEventListener('focusout', handleFocusOut);

    return () => {
      element.removeEventListener('focusout', handleFocusOut);
    };
  }, [inputRef]);

  function handleInputChange(event: any) {
    let { value } = event.target;
    setInputValue(value);
  }

  function handleRefocus() {
    setInputValue('');
    setErrorKey('');
    inputRef.current.focus();
    setKeyScanner(KeyScan);
  }
  function handleClose() {
    setScannedKeyId(null);
    onClose();
    history.goBack();
  }

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <Flex justifyContent="space-around" alignItems="center">
          <Box>
            {strings.scanner_title_message}
            {errorKey && <Text color="red.500">{errorKey}</Text>}
          </Box>
          <IconButton
            as="label"
            icon={<IoReload />}
            variant="link"
            aria-label={strings.rescan}
            color="green.300"
            fontSize="24px"
            onClick={handleRefocus}
          />
        </Flex>
      </AlertDialogHeader>
      <AlertDialogBody>
        <input
          ref={inputRef}
          style={{ height: '0px', width: '0px' }}
          type="text"
          autoFocus
          name="keyid"
          onChange={handleInputChange}
          value={inputValue}
        />
        <Image src={KeyScanner} w="100%" />
        {strings.scanner_message}
      </AlertDialogBody>
      <AlertDialogFooter gridGap="2">
        {status === 'success' && (
          <RouterLink
            to={routes.key.keyList.view.replace(
              ':id',
              keyDetails?.rfid?.toString()
            )}>
            <Button as="span" colorScheme="primary" variant="outline">
              {strings.gotoDetails}
            </Button>
          </RouterLink>
        )}
        <Button colorScheme="gray" variant="outline" onClick={handleClose}>
          {strings.cancel}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};

export default GetNewKeyId;
