import {
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { datePickerButtonStyles } from 'assets/css/commonStyles';
import { REACT_DATE_PICKER_FORMAT_YYYY_MM_DD } from 'constants/common';
import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { getDateAfter } from 'utils/DateFormat';

type Props = {
  placeholder: string;
  selected: Date | null;
  value: string;
  onChange: (date: Date) => void;
};

const DatePickerWithIncrementDecrement: React.FC<Props> = (props) => {
  const { placeholder, selected, value, onChange } = props;

  const dateIncrement = () => {
    const nextDate = getDateAfter(selected, {
      duration: 'days',
      quantity: 1,
    });
    onChange(nextDate);
  };

  const dateDecrement = () => {
    const nextDate = getDateAfter(selected, {
      duration: 'days',
      quantity: -1,
    });
    onChange(nextDate);
  };

  return (
    <Flex
      border="1px solid"
      borderColor="gray.200"
      boxShadow="input"
      borderRadius="sm"
      className="custom-datepicker-wrapper">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<BiCalendar />}
          color="gray.400"
        />
        <ReactDatePicker
          placeholderText={placeholder}
          dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
          selected={selected}
          value={value}
          customInput={<CreatedAtInput />}
          onChange={onChange}
        />
      </InputGroup>
      <ButtonGroup spacing={0}>
        <Button sx={datePickerButtonStyles} onClick={dateDecrement}>
          <BsChevronCompactLeft />
        </Button>
        <Button sx={datePickerButtonStyles} onClick={dateIncrement}>
          <BsChevronCompactRight />
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default DatePickerWithIncrementDecrement;

const CreatedAtInput = forwardRef((props, ref) => {
  return (
    <Input
      pl="10"
      border="none"
      boxShadow="none"
      _focus={{}}
      {...props}
      className="custom-datepicker-input"
    />
  );
});
CreatedAtInput.displayName = 'CreatedAtInput';
