import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ServiceResource from 'api/services';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CsvUpload from 'components/mdm/CsvUpload';
import ServiceSearch from 'components/mdm/ServiceSearch';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  SERVICES_CSV_UPLOAD_API,
  SERVICE_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  name: any;
  type: any;
  calculation: any;
  providers: any;
  invoice_recipient: any;
}

type LoadingType = 'filter' | 'reset' | '';

type QueryParamType = {
  page: number;
  limit: number;
};

const ServicesList: React.FC = () => {
  let serviceAPI = new ServiceResource();

  const [refetch, setRefetch] = useState<number>(1);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    name: null,
    type: null,
    calculation: null,
    providers: null,
    invoice_recipient: null,
  });

  const servicesListQuery = useQuery(
    [
      'listServices',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        name: filterParams.name,
        type: filterParams.type,
        calculation: filterParams.calculation,
        providers: filterParams.providers,
        invoice_recipient: filterParams.invoice_recipient,
      },
      refetch,
    ],
    async () => {
      const queryParam: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await getServiceList(
        filterParams.currentPage,
        filterParams.pageSize
      );
      return response?.data?.data;
    }
  );
  const getServiceList = async (page: number, limit: number) => {
    let { name, type, calculation, providers, invoice_recipient } =
      filterParams;
    let queryParams: any = { page, limit };
    if (name) queryParams.name = name;
    if (type) queryParams.type = type;
    if (calculation) queryParams.calculation = calculation;
    if (providers) queryParams.providers = providers;
    if (invoice_recipient) queryParams.invoice_recipient = invoice_recipient;

    return await serviceAPI.list(queryParams);
  };
  const handleAdvancedSearch = useCallback((data: any, loader: LoadingType) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      name: data.name,
      type: data.type,
      calculation: data.calculation,
      providers: data.providers,
      invoice_recipient: data.invoice_recipient,
    }));
  }, []);
  let { isLoading } = servicesListQuery;

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mdm} | {strings.all_services}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
            {strings.mdm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mdm.services.list}>
            {strings.all_services}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.services}
        </Heading>
        <CsvUpload
          url={SERVICES_CSV_UPLOAD_API}
          setRefetch={setRefetch}
          headerSchema={SERVICE_HEADER}
        />
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="16px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <ServiceSearch handleAdvancedSearch={handleAdvancedSearch} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.id}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.calculation}</Th>
                <Th>{strings.type}</Th>
                <Th>{strings.provider}</Th>
                <Th>{strings.invoice_recepient}</Th>
                <Th>{strings.category}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                servicesListQuery?.data?.data.map(
                  ({
                    id,
                    name,
                    calculation,
                    type,
                    providers,
                    invoice_recipient,
                    category,
                  }: any) => (
                    <Tr key={id}>
                      <Td>{id}</Td>
                      <Td>{name || '-'}</Td>
                      <Td>{calculation || '-'}</Td>
                      <Td>{type || '-'}</Td>
                      <Td>{providers || '-'}</Td>
                      <Td>{invoice_recipient || '-'}</Td>
                      <Td>{category || '-'}</Td>
                    </Tr>
                  )
                )}
              {isLoading && <TableSkeletonLoader rows={7} cols={7} />}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={servicesListQuery}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default ServicesList;
