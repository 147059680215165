import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import {
  apartment_search_url,
  customer_search_url,
  MDM_SEARCH_QUERY_LIMIT,
} from 'constants/common';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ReactSelect from 'react-select';
import { cleanData } from 'utils';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

const TYPES = ['DRAFT', 'CANCELED', 'DEFINITE', 'CONFIRMED', 'FREEZE', 'OFFER'];

interface Props {
  handleAdvancedSearch: any;
  isStatus?: boolean;
  handleResetSearch?: () => void;
  isLoading?: string | null;
}
const InitialValue = {
  apartment: '',
  customer: '',
  owner: '',
  date_from: '',
  date_to: '',
  reserved_at: '',
  changed_at: '',
  down_payment_until: '',
  status: '',
};

const BookingSearch: React.FC<Props> = ({ isStatus = true, ...props }) => {
  const { handleAdvancedSearch, isLoading } = props;
  /**
   * Initialize
   */
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [reserved_at, setReserved_at] = useState<Date | null>(null);
  const [changed_at, setChanged_at] = useState<Date | null>(null);
  const [down_payment_until, setDown_payment_until] = useState<Date | null>(
    null
  );
  const [formValue, setFormValue] = useState<any>(InitialValue);

  const {
    loading: customerListLoading,
    result: customerQuery,
    setQuery,
  } = useWordSearch(customer_search_url, {
    limit: MDM_SEARCH_QUERY_LIMIT,
    is_owner: false,
  });
  const {
    loading: houseOwnerLoading,
    result: houseOwnerQuery,
    setQuery: setHouseOwnerQuery,
  } = useWordSearch(customer_search_url, {
    limit: MDM_SEARCH_QUERY_LIMIT,
    is_owner: true,
  });
  const {
    loading: apartmentLoading,
    result: apartmentQuery,
    setQuery: setApartmentQuery,
  } = useWordSearch(apartment_search_url);

  const apartmentList = apartmentQuery?.data?.data;
  const customerList = customerQuery?.data?.data;
  const houseOwnerList = houseOwnerQuery?.data?.data;

  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.info.abbreviation,
    value: apartment.id,
  }));
  const customerOptions = customerList?.map((user: any) => ({
    label: user.last_name + ' ' + user.first_name,
    value: user.id,
  }));
  const houseOwnerOptions = houseOwnerList?.map((user: any) => ({
    label: user.last_name + ' ' + user.first_name,
    value: user.id,
  }));

  const bookingDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleReserveChange = (date: Date) => {
    setReserved_at(date);
  };
  const handleUpdatedChange = (date: Date) => {
    setChanged_at(date);
  };
  const handleDownPayementChange = (date: Date) => {
    setDown_payment_until(date);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleApartmentChange = (value: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      apartment: value,
    }));
  };
  const handleCustomerChange = (value: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      customer: value,
    }));
  };
  const handleOwnerChange = (value: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      owner: value,
    }));
  };
  useEffect(() => {
    const finalData: any = cleanData(formValue);
    if (startDate) finalData.date_from = startDate;
    if (endDate) finalData.date_to = endDate;
    if (reserved_at) finalData.reserved_at = DateFormatYMD(reserved_at);
    if (changed_at) finalData.changed_at = DateFormatYMD(changed_at);
    if (down_payment_until)
      finalData.down_payment_until = DateFormatYMD(down_payment_until);
    if (finalData.customer)
      finalData.customer = finalData.customer.label.split(' ').join(', ');
    if (finalData.owner)
      finalData.owner = finalData.owner.label.split(' ').join(', ');
    if (finalData.apartment) finalData.apartment = finalData.apartment.label;
    handleAdvancedSearch(finalData);
  }, [
    changed_at,
    down_payment_until,
    endDate,
    formValue,
    handleAdvancedSearch,
    reserved_at,
    startDate,
  ]);

  const handleReset = () => {
    setFormValue(InitialValue);
    setStartDate(null);
    setEndDate(null);
    setChanged_at(null);
    setReserved_at(null);
    setDown_payment_until(null);
    if (handleAdvancedSearch !== undefined) handleAdvancedSearch({});
  };

  const handleCustomerInputChange = (value: string) => {
    setQuery({
      limit: MDM_SEARCH_QUERY_LIMIT,
      is_owner: false,
      name: value || null,
    });
  };
  const handleOwnerInputChange = (value: string) => {
    setHouseOwnerQuery({
      limit: MDM_SEARCH_QUERY_LIMIT,
      is_owner: true,
      name: value || null,
    });
  };
  const handleApartmentInputChange = (value: string) => {
    setApartmentQuery({
      limit: MDM_SEARCH_QUERY_LIMIT,
      name: value || null,
    });
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="row" spacing="4">
            <Grid
              gap="4"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.object}</FormLabel>

                  <ReactSelect
                    name="object"
                    value={formValue.apartment}
                    placeholder={strings.select_apartment}
                    options={apartmentOptions}
                    styles={reactSelectStyles}
                    onInputChange={handleApartmentInputChange}
                    onChange={handleApartmentChange}
                    isLoading={apartmentLoading}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.booking_date}</FormLabel>
                  <InputGroup>
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={bookingDateChange}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      value={getFormattedRangeDate(startDate, endDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />

                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              {isStatus && (
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.customer}</FormLabel>
                    <ReactSelect
                      name="customer"
                      isLoading={customerListLoading}
                      value={formValue.customer}
                      placeholder={strings.select_customer}
                      options={customerOptions}
                      styles={reactSelectStyles}
                      onInputChange={handleCustomerInputChange}
                      onChange={handleCustomerChange}
                    />
                  </FormControl>
                </GridItem>
              )}
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.owner}</FormLabel>

                  <ReactSelect
                    name="owner"
                    value={formValue.owner}
                    placeholder={strings.select_house_owner}
                    options={houseOwnerOptions}
                    styles={reactSelectStyles}
                    onInputChange={handleOwnerInputChange}
                    onChange={handleOwnerChange}
                    isLoading={houseOwnerLoading}
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>{strings.received_at}</FormLabel>
                  <InputGroup>
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleReserveChange}
                      selected={reserved_at}
                      value={DateFormatYMD(reserved_at)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>{strings.changed_at}</FormLabel>
                  <InputGroup>
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleUpdatedChange}
                      selected={changed_at}
                      value={DateFormatYMD(changed_at)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.down_payment_until}</FormLabel>

                  <InputGroup>
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleDownPayementChange}
                      selected={down_payment_until}
                      value={DateFormatYMD(down_payment_until)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.status}</FormLabel>
                  <Select
                    name="status"
                    value={formValue.status}
                    placeholder={strings.select_type}
                    styles={reactSelectStyles}
                    onChange={handleInputChange}>
                    {TYPES.map((item: string) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack direction="row" spacing="4" pt="4">
            <Button
              colorScheme="primary"
              onClick={handleReset}
              isLoading={isLoading === 'reset'}
              variant="outline">
              {strings.reset_filter}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default BookingSearch;
