import { useCallback, useRef, useState } from 'react';

function useIntersectionObserver(
  loading: boolean = false,
  options?: IntersectionObserverInit
) {
  const [visible, setVisible] = useState<boolean>(false);
  const observerRef = useRef<any>();

  const observer = useCallback(
    (node) => {
      if (loading) {
        setVisible(false);
        return;
      }
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !loading) {
          setVisible(true);
        } else setVisible(false);
      }, options);
      if (node) observerRef.current.observe(node);
    },
    [loading, options]
  );

  return { visible, observer };
}

export default useIntersectionObserver;
