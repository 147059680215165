import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  schemeData: any;
  type: string;
}

const SchemeFormByBeachChair: React.FC<Props> = (props) => {
  const { schemeData, type } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    getValues,
    reset,
  } = useFormContext<any>();
  useEffect(() => {
    return () => {
      let vals = { ...getValues() };
      //remove property prom object
      delete vals.scheme_data;
      reset(vals);
    };
  }, [getValues, reset]);

  useEffect(() => {
    if (!schemeData) {
      for (let index = 0; index < 26; index++) {
        let dayTitle = '';
        if (index === 24) {
          dayTitle = 'ADDITIONAL DAY';
        } else if (index === 25) {
          dayTitle = 'ANNUAL PRICE';
        } else {
          let day = index + 1;
          dayTitle = `${day} DAY${day === 1 ? '' : 'S'}`;
        }
        setValue(`beach_chair_schemes.${index}.name`, dayTitle);
        setValue(`beach_chair_schemes.${index}.name`, dayTitle);
        setValue(`beach_chair_schemes.${index}.prices.summer`, '0');
      }
    } else if (schemeData && type === 'PriceSchemeBeachChair') {
      schemeData.forEach((res: any, index: any) => {
        let dayTitle = '';
        if (index === 24) {
          dayTitle = 'ADDITIONAL DAY';
        } else if (index === 25) {
          dayTitle = 'ANNUAL PRICE';
        } else {
          let day = index + 1;
          dayTitle = `${day} DAY${day === 1 ? '' : 'S'}`;
        }
        setValue(`beach_chair_schemes.${index}.name`, dayTitle);
        setValue(
          `beach_chair_schemes.${index}.prices.summer`,
          schemeData[index]['prices']['summer']
        );
        setValue(
          `beach_chair_schemes.${index}.prices.winter`,
          schemeData[index]['prices']['winter']
        );
      });
    }
  }, [schemeData, setValue, type]);

  const schemeByBeachChair = () => {
    let content = [];

    for (let index = 0; index < 26; index++) {
      let dayTitle = '';
      if (index === 24) {
        dayTitle = strings.additional_day;
      } else if (index === 25) {
        dayTitle = strings.annual_price;
      } else {
        let day = index + 1;
        dayTitle = `${day} ${strings.day}${day === 1 ? '' : 's'}`;
      }
      content.push(
        <Fragment key={index}>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.day}</FormLabel>
              <Text>{dayTitle}</Text>
              <Input
                type="hidden"
                defaultValue={dayTitle.toUpperCase()}
                {...register(`beach_chair_schemes.${index}.name`, {
                  required: strings.required,
                })}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={
                !!errors?.beach_chair_schemes?.[index]?.prices?.summer
              }>
              <FormLabel>{strings.price_a} (Cent)</FormLabel>
              <Input
                type="number"
                defaultValue={0}
                placeholder={strings.enter_value_in_cents}
                {...register(`beach_chair_schemes.${index}.prices.summer`, {
                  required: strings.required,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.beach_chair_schemes?.[index]?.prices?.summer &&
                  errors?.beach_chair_schemes?.[index]?.prices?.summer?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={
                !!errors?.beach_chair_schemes?.[index]?.prices?.winter
              }>
              <FormLabel>{strings.price_b} (Cent)</FormLabel>
              <Input
                type="number"
                defaultValue={0}
                placeholder={strings.enter_value_in_cents}
                {...register(`beach_chair_schemes.${index}.prices.winter`, {
                  required: strings.required,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.beach_chair_schemes?.[index]?.prices?.winter &&
                  errors?.beach_chair_schemes?.[index]?.prices?.winter?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Fragment>
      );
    }
    return content;
  };

  return (
    <>
      <Grid
        gap="4"
        templateColumns="repeat(3, 1fr)"
        borderWidth="1px"
        borderRadius="lg"
        p="4"
        flex="1"
        w="100%">
        {schemeByBeachChair()}
      </Grid>
    </>
  );
};

export default SchemeFormByBeachChair;
