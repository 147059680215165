import Resource from 'api/resource';
import axios from 'axios';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: any;

class NewsletterAPI extends Resource {
  constructor() {
    super('newsletter/newsletters');
  }

  /**
   * Upload to Bucket
   */
  uploadToBucket(data: any) {
    return http({
      url: `/newsletter/newsletters/upload`,
      method: 'post',
      data: data,
    });
  }
  destroyAttachment(attachmentId: number) {
    return http({
      url: `/newsletter/unlink/${attachmentId}`,
      method: 'delete',
    });
  }
}

export { NewsletterAPI as default };
