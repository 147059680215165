import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  InputGroup,
  InputRightElement,
  Select,
} from '@chakra-ui/react';
import CustomReactDatePicker from 'components/common/CustomReactDatePicker';
import { strings } from 'config/localization';
import {
  REACT_DATE_PICKER_FORMAT,
  RECURSIVE_INTERVAL,
  RECURSIVE_INTERVAL_MAP_TO_DURATION_AND_QUANTITY,
} from 'constants/common';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import { checkIsBeforeCertainDays, getDateAfter } from 'utils/DateFormat';

interface Props {
  repeatInterval?: string;
  repeatFrom?: Date;
  repeatUntil?: Date;
}

const RecursiveInfoForm: React.FC<Props> = (props) => {
  // props
  const { repeatInterval, repeatFrom, repeatUntil } = props;

  // Library Init
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext<any>();

  /**
   * Get min date for repeat until datepicker field
   * Min Date for repeat until depends upon the recursive interval field
   *
   * @returns date object
   */
  const getMinDateForRepeatUntil = () => {
    const repeatFromDate = watch('recursive_from');
    const recursiveInterval = watch('recursive_interval');
    if (!recursiveInterval) return;
    const dateMap = RECURSIVE_INTERVAL_MAP_TO_DURATION_AND_QUANTITY;
    const minDate = getDateAfter(repeatFromDate, {
      duration: dateMap[recursiveInterval].duration,
      quantity: dateMap[recursiveInterval].quantity,
    });
    return minDate;
  };

  /**
   * Get min duration in days between `Repeat Form` and `Repeat Until`
   * Min Duration is defined by recursive interval
   *
   * @returns min duration in days: number
   */
  const getRequiredRecursiveDuration = (): number => {
    const dateMap = RECURSIVE_INTERVAL_MAP_TO_DURATION_AND_QUANTITY;
    const recursiveInterval = watch('recursive_interval');
    if (!recursiveInterval) return 0;
    return dateMap[recursiveInterval].durationInDays;
  };

  return (
    <Box mt={6}>
      <Heading size="md" textTransform="capitalize">
        {strings.repeating_info}
      </Heading>
      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm" mt={4}>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.recursive_interval} isRequired>
              <FormLabel>{strings.interval}</FormLabel>
              <Select
                id="recursiveInterval"
                placeholder={strings.select_recursive_interval}
                {...register('recursive_interval', {
                  required: strings.required_interval,
                })}
                defaultValue={repeatInterval}
                tabIndex={13}>
                {RECURSIVE_INTERVAL.map(
                  (recursive_interval: any, index: number) => {
                    return (
                      <option value={recursive_interval} key={index}>
                        {strings.getString(
                          recursive_interval.toLowerCase().split('-').join('_')
                        )}
                      </option>
                    );
                  }
                )}
              </Select>
              <FormErrorMessage>
                {errors?.recursive_interval &&
                  errors?.recursive_interval?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.recursive_from} isRequired>
              <FormLabel>{strings.repeat_from}</FormLabel>
              <Controller
                control={control}
                name="recursive_from"
                rules={{
                  required: strings.required_repate_from,
                }}
                defaultValue={repeatFrom}
                render={({ field: { onChange, value } }) => (
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <CustomReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat={REACT_DATE_PICKER_FORMAT}
                      onChange={onChange}
                      minDate={new Date()}
                      selected={value}
                      tabIndex={14}
                    />
                  </InputGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.recursive_from && errors?.recursive_from?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.recursive_until} isRequired>
              <FormLabel>{strings.repeat_until}</FormLabel>
              <Controller
                control={control}
                name="recursive_until"
                rules={{
                  validate: (value) => {
                    if (!value) return strings.required_repeate_until;
                    if (
                      checkIsBeforeCertainDays(
                        watch('recursive_from'),
                        value,
                        getRequiredRecursiveDuration()
                      )
                    )
                      return strings.date_validation_msg;
                  },
                }}
                defaultValue={repeatUntil}
                render={({ field: { onChange, value } }) => (
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />

                    <CustomReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat={REACT_DATE_PICKER_FORMAT}
                      onChange={onChange}
                      selected={value}
                      minDate={getMinDateForRepeatUntil()}
                      tabIndex={15}
                    />
                  </InputGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.recursive_until && errors?.recursive_until?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default RecursiveInfoForm;
