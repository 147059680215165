import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex, Stack, Text } from '@chakra-ui/layout';
import {
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from '@chakra-ui/react';
import CrossSellingAPI from 'api/rms-cross-selling';
import { strings } from 'config/localization';
import { LANGUAGE_OPTIONS } from 'constants/common';
import {
  CrossSellingDetailInterface,
  CrossSellingEmailPayloadInterface,
  LanguageAbbreviationType,
} from 'constants/schema';
import React, { ChangeEvent, useState } from 'react';
import { useMutation } from 'react-query';

interface Props {
  crossSellingList: CrossSellingDetailInterface[];
  customerEmail: string;
  bookingId: number;
}

const CrossSellingArea: React.FC<Props> = (props) => {
  const { crossSellingList, customerEmail, bookingId } = props;

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const crossSellingAPI = new CrossSellingAPI();

  const [crossSellingId, setCrossSellingId] = useState<number[]>([]);
  const [selectedLanguageDescription, setSelectedLanguageDescription] =
    useState<LanguageAbbreviationType>('de');

  const sendEmailService = useMutation({
    mutationFn: (data: CrossSellingEmailPayloadInterface) =>
      crossSellingAPI.sendCrossSellingEmail(bookingId, data),
    onSuccess: () => {
      toast({
        status: 'success',
        title: strings.email_sent_successfully,
      });
      onClose();
    },
    onError: () => {
      toast({
        title: `${strings.failed_to_send_email}`,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const handleSendEmail = () => {
    const data: CrossSellingEmailPayloadInterface = {
      cross_selling_id: crossSellingId,
      language: selectedLanguageDescription,
    };
    sendEmailService.mutate(data);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (checked) {
      setCrossSellingId((prev) => [...prev, Number(value)]);
      return;
    }
    const newCrossSellingId = crossSellingId.filter(
      (item) => item !== Number(value)
    );
    setCrossSellingId(newCrossSellingId);
  };

  const handleSelectLangugaeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLanguageDescription(
      e.currentTarget.value as LanguageAbbreviationType
    );
  };

  return (
    <Stack direction="column" spacing="5">
      {crossSellingList.map((item) => {
        return (
          <Stack
            key={item.id}
            direction="row"
            justifyContent="space-between"
            py={4}
            px={1}
            _notLast={{
              borderBottom: '1px solid',
              borderColor: 'gray.300',
            }}>
            <Text color="paragrah"> {item.service_name}</Text>
            <Checkbox
              value={item.id}
              colorScheme="purple"
              onChange={handleCheckboxChange}
            />
          </Stack>
        );
      })}
      <Stack direction="row">
        <Text color="muted" fontSize="sm">
          {strings.saved_email}:
        </Text>
        <Text color="muted" fontWeight={500} fontSize="sm">
          {customerEmail}
        </Text>
      </Stack>
      <Flex justifyContent="flex-end">
        <Button
          variant="outline"
          colorScheme="primary"
          rounded="md"
          isDisabled={!crossSellingId.length}
          onClick={onOpen}>
          {strings.send_email}
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.send_email}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="column" spacing="4">
              <Text color="heading" fontSize="medium">
                {strings.select_language_description}
              </Text>
              <Select
                placeholder={strings.select_language}
                defaultValue={selectedLanguageDescription}
                onChange={handleSelectLangugaeChange}>
                {LANGUAGE_OPTIONS.map((item, index) => (
                  <option key={index} value={item.value}>
                    {strings[item.label]}
                  </option>
                ))}
              </Select>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="primary" mr={3} onClick={handleSendEmail}>
              {strings.send_email}
            </Button>
            <Button variant="outline" colorScheme="primary" onClick={onClose}>
              {strings.cancel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default CrossSellingArea;
