import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import CRMDashboardResource from 'api/crm-dashboard';
import {
  DashAccordionButtonStyle,
  DashHeadingStyle,
  ObjectDashboardColumnStyle,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { DashboardContactSchema } from 'constants/schema';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import ContactInfo from './ContactInfo';
import ContactNotes from './ContactNotes';

interface Props {
  resourceId: number;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setCustomerEmailAddress: React.Dispatch<React.SetStateAction<string>>;
}

const CustomerDash: React.FC<Props> = (props) => {
  const { resourceId, setTitle, setCustomerEmailAddress } = props;

  const [search, setSearch] = useState('');
  const keyword = useDebounce(search);

  const dashboardAPI = new CRMDashboardResource();

  const { data: customerInfo, isLoading } = useQuery<DashboardContactSchema>(
    [`dashboard-customer-info`, resourceId],
    () => {
      return dashboardAPI
        .dashboardCustomerContact(resourceId)
        .then((res) => res.data?.data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (!customerInfo) return;
    const { first_name, last_name, email } = customerInfo;
    setTitle(`${first_name} ${last_name}`);
    if (email) setCustomerEmailAddress(email);
  }, [setTitle, setCustomerEmailAddress, customerInfo]);

  return (
    <Stack sx={ObjectDashboardColumnStyle}>
      <Heading sx={DashHeadingStyle}>{strings.contact_details}</Heading>
      <FormControl>
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            children={<BiSearch />}
            color="gray.400"
          />
          <Input
            name="keyword"
            placeholder={strings.search}
            value={search}
            type="text"
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </InputGroup>
      </FormControl>
      {isLoading && <CenterSpinner />}
      {customerInfo && (
        <>
          <Accordion defaultIndex={[0]} allowToggle width="100%">
            <AccordionItem bg="gray.50">
              <AccordionButton sx={DashAccordionButtonStyle}>
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Text as="h4" fontWeight="medium" color="white">
                      {strings.contact_details}
                    </Text>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Stack gridGap="10px">
                  <ContactInfo customerInfo={customerInfo} />
                </Stack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem bg="gray.50">
              <AccordionButton sx={DashAccordionButtonStyle}>
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Text as="h4" fontWeight="medium" color="white">
                      {strings.notes}
                    </Text>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Stack gridGap="10px">
                  <ContactNotes customerInfo={customerInfo} />
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      )}
    </Stack>
  );
};

export default CustomerDash;
