import { Box, Center, Flex, Image, Spinner } from '@chakra-ui/react';
import { Merge } from 'assets/icons';
import DropdownSvg from 'components/common/DropdownSvg';
import { strings } from 'config/localization';
import { TICKET_CUSTOMER_SEARCH_API } from 'constants/common';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useWordSearch from 'hooks/useWordSearch';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  id: string;
  placeholder: string;
  onChange: (id: number, name: string, disabled: boolean) => void;
  value: any;
  parentQueries?: any;
  isDisabled?: boolean;
  tabIndex?: number;
}

interface Customer {
  id: number;
  name: string;
  is_intermixed: boolean;
}

interface CustomerQuery {
  page: number;
  limit: number;
}

export default function CustomerCustomSelect({
  id,
  placeholder,
  onChange,
  value,
  parentQueries,
  isDisabled = false,
  tabIndex,
}: Props) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const openModalRef = useRef<any>(null);
  const { visible, observer } = useIntersectionObserver();
  const focusRef = useRef<any>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [customerQueryParams, setCustomerQueryParams] = useState<CustomerQuery>(
    {
      page: 1,
      limit: 20,
      ...parentQueries,
    }
  );

  const location = useLocation();
  const isCreateEditPage =
    location.pathname.includes('edit') || location.pathname.includes('create');

  const {
    loading: customerListLoading,
    result: customerQuery,
    setQuery,
  } = useWordSearch(`${TICKET_CUSTOMER_SEARCH_API}`);

  const handleCustomerInputChange = useCallback((value: string) => {
    setCustomerQueryParams((prevState: CustomerQuery) => ({
      ...prevState,
      name: value ? value : null,
      page: 1,
    }));
    setCustomerOptions([]);
  }, []);

  useEffect(() => {
    if (!customerQuery.data) return;
    const customerListData = customerQuery?.data;
    const customerMeta = customerQuery?.meta;
    const currentPage = customerMeta?.current_page;
    const lastPage = customerMeta?.last_page;
    setHasMore(currentPage < lastPage);
    setCustomerOptions((prevState: any) => [...prevState, ...customerListData]);
  }, [customerQuery?.data, customerQuery?.meta]);

  useEffect(() => {
    setQuery(customerQueryParams);
  }, [customerQueryParams, setQuery]);

  useEffect(() => {
    if (openModal && visible && hasMore && !customerListLoading) {
      setCustomerQueryParams((prevState: CustomerQuery) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [openModal, customerListLoading, hasMore, visible]);

  useEffect(() => {
    function handleClick(e: any) {
      if (!e.target.classList.contains('disabled-ul')) {
        if (
          openModalRef.current !== null &&
          openModalRef.current.contains(e.target)
        ) {
          setOpenModal(!openModal);
          focusRef.current && focusRef.current.focus();
        } else {
          setOpenModal(false);
        }
      }
    }
    !isDisabled && document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [openModal, isDisabled]);

  useEffect(() => {
    const inputSearchHandler = (value: string) => {
      handleCustomerInputChange(value);
      if (searchInput !== '') setOpenModal(true);
    };
    inputSearchHandler(searchInput);
  }, [searchInput, handleCustomerInputChange]);

  const selectHandler = (id: number, name: string, is_intermixed: boolean) => {
    if (is_intermixed && isCreateEditPage) return;
    setSearchInput('');
    setOpenModal(false);
    onChange(id, name, is_intermixed);
  };

  return (
    <div
      className={`container-and-modal${
        isDisabled ? ' cursor-not-allowed' : ''
      }`}>
      <div className="custom-select-container" id={id}>
        <span
          aria-live="polite"
          aria-atomic="false"
          aria-relevant="additions text"
          className="outer-line-span"></span>
        <div className="all-content-container" ref={openModalRef}>
          <div className="text-placeholder-and-input-field-container">
            <div className="input-field-inner-container">
              <input
                id="customer_id"
                name="customer_id"
                value={searchInput}
                placeholder={value ? value : placeholder}
                className={`input-field${
                  isDisabled ? ' cursor-not-allowed' : ''
                }`}
                ref={focusRef}
                disabled={isDisabled}
                style={{ opacity: isDisabled ? 0.4 : 1 }}
                onChange={(e) => setSearchInput(e.target.value)}
                autoComplete="off"
                tabIndex={tabIndex}
              />
            </div>
          </div>
          <div className="seperator-arrow-outer-container">
            <span className="line-seperator"></span>
            <div className="arrow-inner-container" aria-hidden="true">
              <div style={{ opacity: isDisabled ? 0.4 : 1 }}>
                <DropdownSvg />
              </div>
            </div>
          </div>
        </div>

        {openModal && (
          <div className="modal-box">
            {customerOptions?.map((customer: Customer) => {
              return (
                <div key={customer.id}>
                  <ul
                    key={customer.id}
                    className={`list-item ${
                      customer.is_intermixed
                        ? 'list-item-disabled disabled-ul'
                        : ''
                    }`}
                    onClick={() =>
                      selectHandler(
                        customer.id,
                        customer.name,
                        customer.is_intermixed
                      )
                    }>
                    <Flex
                      className={customer.is_intermixed ? 'disabled-ul' : ''}>
                      {customer.name}
                      {customer.is_intermixed && (
                        <Box
                          w="5"
                          h="5"
                          ml="2"
                          color="orange.300"
                          className={
                            customer.is_intermixed ? 'disabled-ul' : ''
                          }>
                          <Image
                            src={Merge}
                            className={
                              customer.is_intermixed ? 'disabled-ul' : ''
                            }
                          />
                        </Box>
                      )}
                    </Flex>
                  </ul>
                </div>
              );
            })}
            <div ref={observer} style={{ height: '1px' }}></div>
            {customerListLoading && (
              <Center h="20px" color="grey">
                <Spinner size="sm" />
              </Center>
            )}
            {!customerListLoading && (
              <Center fontSize={12} color="grey">
                {customerOptions.length === 0
                  ? strings.no_options
                  : strings.showing_all_customers}
              </Center>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
