import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Grid,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { Article, User, Warehouse } from 'assets/icons';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import QRItemList from 'pages/dashboard/mms/QRCode/QRListItem';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';

const QRCode: React.FC = () => {
  const hasUserPermission: boolean = useIsPermitted([
    PermissionRequest['read:user'],
  ]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mms} | {strings.qr_code}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
            {strings.mms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mms.qrCode.list}>
            {strings.qr_code}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.qr_code}
        </Heading>
      </Flex>
      <Grid
        gap={['4', '4', '6', '6']}
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
          'repeat(6, 1fr)',
          'repeat(7, 1fr)',
        ]}>
        {hasUserPermission && (
          <QRItemList
            title={strings.user}
            image={User}
            bgColor="blue.400"
            link="/users/info/list"
          />
        )}
        <QRItemList
          title={strings.article}
          image={Article}
          bgColor="green.400"
          link="/mms/articles/list"
        />
        <QRItemList
          title={strings.warehouse}
          image={Warehouse}
          bgColor="primary.400"
          link="/mms/warehouse/list"
        />
      </Grid>
    </Stack>
  );
};

export default QRCode;
