import { Box, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import CRMDashboardResource from 'api/crm-dashboard';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import React, { SetStateAction, useEffect } from 'react';
import { BiPhoneCall } from 'react-icons/bi';
import { BsEnvelope } from 'react-icons/bs';
import { useQuery } from 'react-query';
interface Props {
  vOfficeOwnerId: number | null;
  setOwnerId: React.Dispatch<SetStateAction<number | null>>;
}

const InfoArea: React.FC<Props> = (props) => {
  const { vOfficeOwnerId, setOwnerId } = props;

  const dashboardAPI = new CRMDashboardResource();

  const {
    data: ownerInfo,
    isLoading,
    isError,
  } = useQuery(
    [`dashboard-customer-info`, vOfficeOwnerId],
    async () => {
      if (!vOfficeOwnerId) return;
      const response = await dashboardAPI.dashboardOwnerContact(vOfficeOwnerId);
      return response?.data?.data;
    },
    {
      enabled: !!vOfficeOwnerId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!ownerInfo) return;
    setOwnerId(ownerInfo.id);
  }, [ownerInfo, setOwnerId]);

  if (isLoading) {
    return <CenterSpinner />;
  }
  if (!ownerInfo || isError) {
    return <Text textAlign="center">{strings.no_data_found}</Text>;
  }

  return (
    <Stack
      gridGap="10px"
      width="100%"
      p="10px"
      border="1px solid #EBECF2"
      borderRadius="8px"
      justifyContent="space-between">
      <Flex gridGap="2">
        <div>{strings.name}: </div>
        <div>{ownerInfo.first_name + ' ' + ownerInfo.last_name}</div>
      </Flex>
      <Flex gridGap="2" justify="space-between">
        <Flex gridGap="2">
          <div>{strings.contact}: </div>
          <div>{ownerInfo.mobile}</div>
        </Flex>
        <Box>
          {ownerInfo?.phone && (
            <a href={`tel:${ownerInfo.phone}`}>
              <Icon aria-label={strings.phone} as={BiPhoneCall} h="5" w="5" />
            </a>
          )}
        </Box>
      </Flex>
      <Flex gridGap="2" justify="space-between">
        <Flex gridGap="2">
          <div>{strings.email}: </div>
          <div>{ownerInfo.email}</div>
        </Flex>
        <Box>
          {ownerInfo?.email && (
            <a href={`mailto:${ownerInfo.email}`}>
              <Icon aria-label={strings.mails} as={BsEnvelope} h="5" w="5" />
            </a>
          )}
        </Box>
      </Flex>
    </Stack>
  );
};

export default InfoArea;
