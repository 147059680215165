import { Avatar, Box, Flex, Stack, Text } from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { shallowEqual, useSelector } from 'react-redux';
import { TimeFormatHMA } from 'utils/DateFormat';
import ChatListItem from './ChatListItem';

interface Props {
  phoneRingingRoom: string;
  callInProgressRoom: string;
}

const ChatSearch: React.FC<Props> = (props) => {
  const { phoneRingingRoom, callInProgressRoom } = props;

  const { chatItemList } = useSelector(
    (state: any) => ({
      chatItemList: state?.data?.chat.chatItemList,
    }),
    shallowEqual
  );

  if (chatItemList.length < 1) {
    return (
      <Box
        bg="white"
        p="3"
        position="relative"
        rounded="sm"
        w="280px"
        maxWidth="280px"
        maxHeight="20">
        <Flex direction="row" alignItems="center">
          <Avatar showBorder />
          <Text fontSize="sm" color="gray.400" ml={3}>
            {strings.no_chat_assigned}
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="6" w="300px">
      <Box maxH="80vh" overflow="auto">
        <PerfectScrollbar>
          <Stack direction="column" spacing="4" rounded="md">
            {chatItemList?.map((data: any) => (
              <ChatListItem
                key={data._id}
                name={data.name}
                roomName={data.room_name}
                message={data.message}
                unreadChatCount={data?.employee_unread_chat}
                avatar={data.avatar}
                chatId={data._id}
                date={TimeFormatHMA(data.last_chat?.time)}
                phoneRingingRoom={phoneRingingRoom}
                callInProgressRoom={callInProgressRoom}
              />
            ))}
          </Stack>
        </PerfectScrollbar>
      </Box>
    </Stack>
  );
};

export default ChatSearch;
