import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  GridItem,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import {
  FEEDBACK_QUESTION_TYPE_CLOSED_ENDED,
  FEEDBACK_QUESTION_TYPE_OPEN,
  FEEDBACK_QUESTION_TYPE_RATING,
} from 'constants/common';
import * as React from 'react';

const borderStyled = {
  borderColor: 'gray.200',
  _lastChildren: {
    borderColor: 'transparent',
  },
};

interface Props {
  response: any;
  index: any;
}

const FeedbackQuestionType: React.FC<Props> = (props) => {
  const { response, index } = props;
  const type = response?.question_meta?.type;
  const question = response?.question_meta?.name;

  if (type === FEEDBACK_QUESTION_TYPE_RATING) {
    return (
      <>
        <div className="page-break" />
        <GridItem key={index}>
          <FormControl
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap">
            <Box w="45%">
              <FormLabel>{question}</FormLabel>
            </Box>
            <Box w="55%" textAlign="right">
              <Tooltip
                hasArrow
                label={strings.the_rating_is_out_of_10}
                placement="left"
                size="sm"
                zIndex={1}>
                <Tag
                  variant="outline"
                  colorScheme="teal"
                  borderRadius="full"
                  w="4"
                  h="4"
                  textAlign="center"
                  fontSize="14px"
                  size="md"
                  justifyContent="center">
                  {response?.response}
                </Tag>
              </Tooltip>
            </Box>
          </FormControl>
        </GridItem>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  if (type === FEEDBACK_QUESTION_TYPE_CLOSED_ENDED) {
    return (
      <>
        <div className="page-break" />
        <GridItem key={index}>
          <FormControl
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap">
            <Box display="flex" justifyContent="flex-start" w="45%">
              <FormLabel>{question}</FormLabel>
            </Box>
            <Box w="55%" textAlign="right">
              <Tooltip
                hasArrow
                label={strings.this_is_closed_ended_question}
                placement="left"
                size="sm"
                zIndex={1}>
                <Tag
                  size="md"
                  variant="outline"
                  colorScheme="teal"
                  rounded="md">
                  {response?.response === '1' ? strings.yes : strings.no}
                </Tag>
              </Tooltip>
            </Box>
          </FormControl>
        </GridItem>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  if (type === FEEDBACK_QUESTION_TYPE_OPEN) {
    return (
      <>
        <div className="page-break" />
        <GridItem key={index}>
          <FormControl
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap">
            <Box w="45%">
              <FormLabel>{question}</FormLabel>
            </Box>
            <Box w="55%" textAlign="right">
              <Tooltip
                hasArrow
                label={strings.this_is_open_ended_question}
                placement="left"
                size="sm"
                zIndex={1}>
                <Tag
                  size="md"
                  variant="outline"
                  colorScheme="gray"
                  rounded="sm"
                  padding={2}>
                  {response?.response}
                </Tag>
              </Tooltip>
            </Box>
          </FormControl>
        </GridItem>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  return <></>;
};

export default FeedbackQuestionType;
