import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { deteleIconStyles } from 'assets/css/commonStyles';
import Uploader from 'components/common/Uploader';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BiFile, BiTrash } from 'react-icons/bi';
import { capitalizeFirstLetter } from 'utils';
interface Props {
  data?: any;
  faqGroups: any;
}

const FaqForm: React.FC<Props> = (props) => {
  let { data, faqGroups } = props;

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<any>();

  const [file_contact, setFile_contact] = useState<string | null>(null);
  const [file_object, setFile_object] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setValue('title', data.title);
      setValue('alias', data.alias);
      setValue('status', data.status.toString());
      setValue('faq_group_id', data.faq_group.id);
      setValue('description', data.description);
      setValue('publish_to.web', Number(data.publish_to.web));
      setValue('publish_to.chat', Number(data.publish_to.chat));
      setValue('publish_to.internes', Number(data.publish_to.internes));
      setFile_object(data.file_object);
      setFile_contact(data.file_contact);
    }
  }, [data, setValue]);

  const handleDeleteContactFile = () => {
    setValue('file_contact', null);
    setFile_contact(null);
  };
  const handleDeleteObjectFile = () => {
    setValue('file_object', null);
    setFile_object(null);
  };

  let showApplyChanges =
    data?.file_object !== file_object || data?.file_contact !== file_contact;

  return (
    <>
      <Stack direction="column" spacing="4">
        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors.faq_group_id} isRequired>
              <FormLabel>{strings.group}</FormLabel>
              <Select
                {...register('faq_group_id')}
                placeholder={strings.select + ' ' + strings.group}
                rounded="sm">
                {faqGroups?.data?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {capitalizeFirstLetter(item.name)}
                  </option>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
          ]}
          w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors.title}>
              <FormLabel>{strings.title}</FormLabel>
              <Input
                {...register('title', {
                  required: strings.title_required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.title}
                defaultValue={data?.title}
              />
              <FormErrorMessage>
                {errors.title && errors.title?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.alias}>
              <FormLabel>{strings.alias}</FormLabel>
              <Input
                {...register('alias')}
                placeholder={strings.alias}
                defaultValue={data?.alias}
              />
              <FormErrorMessage>
                {errors.alias && errors.alias?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Veröffentlichen in</FormLabel>
              <Stack spacing={5} direction={['column', 'row']}>
                <Controller
                  name="publish_to.web"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                      isChecked={!!field.value}>
                      {strings.user}
                    </Checkbox>
                  )}
                />
                <Controller
                  name="publish_to.chat"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                      isChecked={!!field.value}>
                      {strings.chat}
                    </Checkbox>
                  )}
                />
                <Controller
                  name="publish_to.internes"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                      isChecked={!!field.value}>
                      {strings.internes_wissensdokument}
                    </Checkbox>
                  )}
                />
              </Stack>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.status} isRequired>
              <FormLabel>{strings.status}</FormLabel>
              <Controller
                name="status"
                rules={{
                  required: strings.required,
                }}
                defaultValue={data?.status}
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="status">
                    <Stack spacing={5} direction="row">
                      <Radio value="1">{strings.active}</Radio>
                      <Radio value="0">{strings.in_active}</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.status && errors?.status?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors?.description}>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                placeholder={strings.description}
              />
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.upload_files}>
              <FormLabel>
                {strings.file} {strings.contacts}
              </FormLabel>
              <Box w="260px">
                <Uploader
                  fileKey="file_contact"
                  uploadedFiles={data?.upload_files}
                  multiple={false}
                  acceptFileType=".pdf"
                />
              </Box>
              <Box mt="2" color="gray.400" w="260px">
                {file_contact && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    padding="6px 0 6px 0"
                    _hover={{ bg: 'gray.50' }}>
                    <a
                      href={data?.file_contact}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Icon as={BiFile} w="4" h="4" />
                      {data.file_contact_name
                        ? data.file_contact_name
                        : data.file_contact.split('/').pop()}
                    </a>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label={strings.delete_file}
                      fontSize="sm"
                      bg="red.300"
                      color="white">
                      <IconButton
                        icon={<BiTrash />}
                        variant="link"
                        aria-label={strings.delete_file}
                        sx={deteleIconStyles}
                        onClick={handleDeleteContactFile}
                      />
                    </Tooltip>
                  </Flex>
                )}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.upload_files}>
              <FormLabel>
                {strings.file} {strings.object}
              </FormLabel>
              <Box w="260px">
                <Uploader
                  fileKey="file_object"
                  uploadedFiles={data?.upload_files}
                  multiple={false}
                  acceptFileType=".pdf"
                />
              </Box>
              <Box mt="2" color="gray.400" w="260px">
                {file_object && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    padding="6px 0 6px 0"
                    _hover={{ bg: 'gray.50' }}>
                    <a
                      href={data?.file_object}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Icon as={BiFile} w="4" h="4" />
                      {data.file_object_name
                        ? data.file_object_name
                        : data.file_object.split('/').pop()}
                    </a>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label={strings.delete_file}
                      fontSize="sm"
                      bg="red.300"
                      color="white">
                      <IconButton
                        icon={<BiTrash />}
                        variant="link"
                        aria-label={strings.delete_file}
                        sx={deteleIconStyles}
                        onClick={handleDeleteObjectFile}
                      />
                    </Tooltip>
                  </Flex>
                )}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      {showApplyChanges && (
        <Text mt="3">* {strings.save_inorder_to_apply_changes}</Text>
      )}
    </>
  );
};

export default FaqForm;
