import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import BreadBookingResource from 'api/bread_booking';
import {
  infoStyles,
  labelStyles,
  selectColorScheme,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ReservationBooking } from 'constants/schemas/reservationBooking';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';
import { checkPermissions } from 'utils/listInfo';

interface Props {
  breadBookingData: ReservationBooking;
  title?: string;
  pageName?: string;
  bookingId?: string;
  search?: string;
}

const BreadBookingInfo: React.FC<Props> = (props) => {
  const { breadBookingData, pageName, bookingId, search } = props;
  let tillDate = breadBookingData?.reservation?.bookings?.tilldate;
  let toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let serviceReservationAPI = new BreadBookingResource();
  const deleteService = useMutation((id: any) =>
    serviceReservationAPI.cancelBreadBooking(id)
  );
  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const handleDelete = async () => {
    deleteService.mutate(bookingId, {
      onSuccess: () => {
        toast({
          title: `${strings.delete}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.bs.breadBooking.list + search);
      },
      onError: () => {
        toast({
          title: `${strings.failed}`,
          status: 'error',
          isClosable: true,
        });
        setIsLoading(false);
      },
    });
  };

  const isPast = (date: string) => {
    const today = new Date();
    const dateToCompare = new Date(date);
    return dateToCompare < today;
  };

  const isPermitted: boolean = checkPermissions(userPermissions, [
    PermissionRequest['manage:bread-service'],
  ]);

  let status: 'confirmed' | 'cancelled' | 'pending' = breadBookingData?.status;

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.booking_detail}
        </Heading>
        {isPermitted &&
          pageName !== 'breadDailyView' &&
          !isPast(tillDate) &&
          breadBookingData?.status?.toLowerCase() !== 'cancelled' && (
            <Stack direction="row" spacing="6">
              <Button
                size="sm"
                colorScheme="primary"
                onClick={() =>
                  history.push(
                    routes.bs.breadBooking.edit.replace(
                      ':id',
                      breadBookingData.id.toString()
                    ) + search
                  )
                }>
                {strings.edit_order}
              </Button>
              {breadBookingData?.status?.toLowerCase() === 'confirmed' && (
                <Button
                  size="sm"
                  colorScheme="primary"
                  isLoading={isLoading}
                  onClick={handleDelete}>
                  {strings.cancel_order}
                </Button>
              )}
            </Stack>
          )}
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_number}</FormLabel>
              <Box sx={infoStyles}>
                {breadBookingData?.reservation?.booking_number || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_date}</FormLabel>
              <Box sx={infoStyles}>{breadBookingData?.booking_date || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.property_name}</FormLabel>
              <Box sx={infoStyles}>
                {breadBookingData?.reservation?.apartment?.apartment_name ||
                  '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer_contact}</FormLabel>
              <Box sx={infoStyles}>
                {breadBookingData?.customer?.mobile || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer_name}</FormLabel>
              <Box sx={infoStyles}>
                {breadBookingData?.customer_name || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.status}</FormLabel>

              <Box sx={infoStyles}>
                <Text
                  color={selectColorScheme(status)}
                  textTransform="uppercase"
                  fontWeight="medium"
                  fontSize="sm">
                  {strings[breadBookingData?.status]}
                </Text>
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_period}</FormLabel>
              <Box sx={infoStyles}>{`${DateFormat(
                breadBookingData?.reservation?.bookings?.fromdate
              )} - ${DateFormat(
                breadBookingData?.reservation?.bookings?.tilldate
              )}`}</Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};
export default BreadBookingInfo;
