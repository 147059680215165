import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;
class SMSServiceGroupResource extends Resource {
  constructor() {
    super('rms/service-groups');
  }

  list(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: '/' + this.uri + '?include_dog_service=1',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  update(id: any, resource: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'post',
      data: resource,
    });
  }

  updateSerialNumber(oldSerialNumber: number, newSerialNumber: number) {
    return http({
      url: '/' + this.uri + '/update-serial-number',
      method: 'put',
      data: {
        old_serial_number: oldSerialNumber,
        new_serial_number: newSerialNumber,
      },
    });
  }
}

export { SMSServiceGroupResource as default };
