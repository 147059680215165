import { Flex, Icon } from '@chakra-ui/react';
import RMSDashObjectsResource from 'api/rms-dashboard';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { DashboardBookingListInterface } from 'constants/schema';
import React from 'react';
import { BiShowAlt } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

interface Props {
  type: 'upcoming' | 'past';
  keyword?: string;
  resourceId: number;
  dashboardType: 'customer' | 'object';
}

const BookingArea: React.FC<Props> = (props) => {
  const { type, keyword, resourceId, dashboardType } = props;

  const dashboardAPI = new RMSDashObjectsResource();

  const { data: bookingInfo, isLoading } = useQuery<
    DashboardBookingListInterface[]
  >(
    [
      `${type}-dashboard-booking-info`,
      type,
      keyword,
      resourceId,
      dashboardType,
    ],
    () => {
      let dashAPI =
        dashboardType === 'object'
          ? dashboardAPI.dashboardObjectReservations
          : dashboardAPI.dashboardCustomerReservations;
      return dashAPI(resourceId, {
        type,
        keyword,
      }).then((res) => res.data?.data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <TicketSkeletonLoader rows={3} />;
  } else if (!bookingInfo) return null;

  return (
    <>
      {bookingInfo.map((item) => (
        <Flex
          key={`${item.booking_date} ${item.id}`}
          width="100%"
          p="10px"
          border="1px solid #EBECF2"
          borderRadius="10px"
          alignItems="center"
          justifyContent="space-between">
          <div>
            <div>{item.booking_number}</div>
            <div>{item.booking_period}</div>
            <div>
              {dashboardType === 'customer'
                ? item.apartment.apartment_name
                : item.customer
                ? `${item.customer.forename} ${item.customer.surname}`
                : '-'}
            </div>
          </div>
          <div>
            <Link
              target="_blank"
              to={`${routes.bms.reservation.details.replace(
                ':id',
                item.id.toString()
              )}`}>
              <Icon
                as={BiShowAlt}
                title={strings.view}
                w="8"
                h="8"
                _hover={{ cursor: 'pointer' }}
              />
            </Link>
          </div>
        </Flex>
      ))}
    </>
  );
};

export default BookingArea;
