import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Flex, Heading, Stack } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/table';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ThirdPartyPartnerListItem from 'components/third_party_partner/ThirdPartyPartnerListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { ThirdPartyPartnerSchema } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  name: string;
  is_active: string;
}

const ThirdPartyPartnerList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    name: searchValues.name ?? '',
    is_active: searchValues.is_active ?? '',
  });

  const ThirdPartyPartnerAPI = new ThirdPartyPartnerResource();

  const thirdPartyPartnerListData: any = useQuery(
    [
      'third-party-partner-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        is_active: filterParams.is_active,
        name: filterParams.name,
      },
    ],
    () => {
      let { is_active, name } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (is_active) queryParams.is_active = is_active;
      if (name) queryParams.name = name;

      return ThirdPartyPartnerAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      name: '',
      is_active: '',
    }));
    history.push(routes.bms.booking.list);
  }

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.membership} | {strings.third_party_partner}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.membership}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.thirdPartyPartner.thirdPartyPartner.list}>
              {strings.third_party_partner}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.third_party_partner}
          </Heading>
          <RouterLink to={routes.thirdPartyPartner.thirdPartyPartner.add}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.add_new_third_party_partner}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="name"
                    value={filterParams.name}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.status}</FormLabel>
                <Select
                  name="is_active"
                  value={filterParams.is_active}
                  placeholder={`${strings.select} ${strings.status}`}
                  onChange={handleInputChange}>
                  <option value={1}>{strings.active}</option>
                  <option value={0}>{strings.in_active}</option>
                </Select>
              </FormControl>

              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.third_party_partner}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.is_active}</Th>
                  <Th>{strings.actions}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {thirdPartyPartnerListData?.data?.data?.map(
                  (
                    thirdPartyPartnerData: ThirdPartyPartnerSchema,
                    index: number
                  ) => (
                    <ThirdPartyPartnerListItem
                      key={thirdPartyPartnerData.id}
                      index={startingSN + index}
                      thirdPartyPartnerData={thirdPartyPartnerData}
                      search={search}
                    />
                  )
                )}
                {thirdPartyPartnerListData.isLoading && (
                  <TableSkeletonLoader rows={5} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {thirdPartyPartnerListData?.data && (
          <Pagination
            dataList={thirdPartyPartnerListData}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default ThirdPartyPartnerList;
