import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import BookingResource from 'api/bookings';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import BookingSearch from 'components/mdm/BookingSearch';
import CsvUpload from 'components/mdm/CsvUpload';
import { strings } from 'config/localization';
import {
  BOOKINGS_CSV_UPLOAD_API,
  BOOKING_HEADER,
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  apartment: string | null;
  customer: string | null;
  owner: string | null;
  date_from: Date | null;
  date_to: Date | null;
  reserved_at: Date | null;
  changed_at: Date | null;
  down_payment_until: Date | null;
  status: string | null;
}

type LoadingType = 'filter' | 'reset' | '';

type QueryParamType = {
  page: number;
  limit: number;
};

const BookingsList: React.FC = () => {
  let bookingAPI = new BookingResource();

  const [refetch, setRefetch] = useState<number>(1);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    apartment: null,
    customer: null,
    owner: null,
    date_from: null,
    date_to: null,
    reserved_at: null,
    changed_at: null,
    down_payment_until: null,
    status: null,
  });

  const bookingListQuery = useQuery(
    [
      'listBookings',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        object: filterParams.apartment,
        customer: filterParams.customer,
        owner: filterParams.owner,
        date_from: filterParams.date_from,
        date_to: filterParams.date_to,
        reserved_at: filterParams.reserved_at,
        changed_at: filterParams.changed_at,
        down_payment_until: filterParams.down_payment_until,
        status: filterParams.status,
      },
      refetch,
    ],
    async () => {
      const queryParam: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await getBookingList(
        filterParams.currentPage,
        filterParams.pageSize
      );
      return response?.data?.data;
    }
  );
  const getBookingList = async (page: number, limit: number) => {
    let {
      apartment,
      customer,
      owner,
      reserved_at,
      date_from,
      date_to,
      changed_at,
      down_payment_until,
      status,
    } = filterParams;

    let queryParams: any = {
      page,
      limit,
    };

    if (apartment) queryParams.object = apartment;
    if (customer) queryParams.customer = customer;
    if (owner) queryParams.owner = owner;
    if (date_from) queryParams.date_from = date_from;
    if (date_to) queryParams.date_to = date_to;
    if (reserved_at) queryParams.reserved_at = reserved_at;
    if (changed_at) queryParams.changed_at = changed_at;
    if (down_payment_until) queryParams.down_payment_until = down_payment_until;
    if (status) queryParams.status = status;

    return await bookingAPI.list(queryParams);
  };
  const handleAdvancedSearch = useCallback((data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      apartment: data.apartment,
      customer: data.customer,
      owner: data.owner,
      reserved_at: data.reserved_at,
      changed_at: data.changed_at,
      down_payment_until: data.down_payment_until,
      status: data.status,
      date_from: data.date_from,
      date_to: data.date_to,
    }));
  }, []);

  let { isLoading } = bookingListQuery;

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mdm} | {strings.all_bookings}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
            {strings.mdm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mdm.bookings.list}>
            {strings.all_bookings}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.bookings}
        </Heading>
        <CsvUpload
          url={BOOKINGS_CSV_UPLOAD_API}
          setRefetch={setRefetch}
          headerSchema={BOOKING_HEADER}
        />
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="16px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <BookingSearch handleAdvancedSearch={handleAdvancedSearch} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.id}</Th>
                <Th>{strings.number}</Th>
                <Th>{strings.object}</Th>
                <Th>{strings.from}</Th>
                <Th>{strings.until}</Th>
                <Th>{strings.customer}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.received_at}</Th>
                <Th>{strings.changed_at}</Th>
                <Th>{strings.total}</Th>
                <Th>{strings.open}</Th>
                <Th>{strings.marketing_channel}</Th>
                <Th>{strings.down_payment}</Th>
                <Th>{strings.down_payment_until}</Th>
                <Th>{strings.final_payment_until}</Th>
                <Th>{strings.ex_price}</Th>
                <Th>{strings.total_occupancy}</Th>
                <Th>{strings.adult}</Th>
                <Th>{strings.children}</Th>
                <Th>{strings.babies}</Th>
                <Th>{strings.age_children}</Th>
                <Th>{strings.country_customer}</Th>
                <Th>{strings.town_code_customer}</Th>
                <Th>{strings.owner}</Th>
                <Th>{strings.country_objectives}</Th>
                <Th>{strings.region}</Th>
                <Th>{strings.rent_statement_exit}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                bookingListQuery?.data?.data.map(
                  ({
                    id,
                    number,
                    object,
                    date_from,
                    date_until,
                    status,
                    reserved_at,
                    changed_at,
                    total_amount,
                    remaining_amount,
                    marketing_channel,
                    down_payment,
                    down_payment_until,
                    final_payment_until,
                    ex_price,
                    total_occupancy,
                    adults,
                    children,
                    babies,
                    age_children,
                    customer_country,
                    customer_zip,
                    customer,
                    owner,
                    object_country,
                    region,
                    rent_statement_exists,
                  }: any) => (
                    <Tr key={id}>
                      <Td>{id}</Td>
                      <Td>{number || '-'}</Td>
                      <Td>{object || '-'}</Td>
                      <Td>{DateFormat(date_from) || '-'}</Td>
                      <Td>{DateFormat(date_until) || '-'}</Td>
                      <Td>{customer || '-'}</Td>
                      <Td>{status || '-'}</Td>
                      <Td>{DateFormat(reserved_at) || '-'}</Td>
                      <Td>{DateFormat(changed_at) || '-'}</Td>
                      <Td>{total_amount || '-'}</Td>
                      <Td>{remaining_amount || '-'}</Td>
                      <Td>{marketing_channel || '-'}</Td>
                      <Td>{down_payment || '-'}</Td>
                      <Td>{DateFormat(down_payment_until) || '-'}</Td>
                      <Td>{DateFormat(final_payment_until) || '-'}</Td>
                      <Td>{ex_price || '-'}</Td>
                      <Td>{total_occupancy || '-'}</Td>
                      <Td>{adults || '-'}</Td>
                      <Td>{children || '-'}</Td>
                      <Td>{babies || '-'}</Td>
                      <Td>{age_children || '-'}</Td>
                      <Td>{customer_country || '-'}</Td>
                      <Td>{customer_zip || '-'}</Td>
                      <Td>{owner || '-'}</Td>
                      <Td>{object_country || '-'}</Td>
                      <Td>{region || '-'}</Td>
                      <Td>{`${rent_statement_exists}`}</Td>
                    </Tr>
                  )
                )}
              {isLoading && <TableSkeletonLoader rows={7} cols={28} />}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={bookingListQuery}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default BookingsList;
