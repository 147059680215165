import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { PublishOptionType, PublishValueType } from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import { FeedbackQuestionPublishOptions } from 'utils/index';

interface Props {
  data?: any;
}

const FeedbackQuestionForm: React.FC<Props> = (props) => {
  let { data } = props;
  const PublishSelectOptions = FeedbackQuestionPublishOptions();

  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext<any>();

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('type', data.type);
      setValue('categories', data.categories);
    }
  }, [data, setValue]);

  const handlePublishSelection = (categories: any) => {
    let selectedCategory: any = categories.map(
      (category: PublishOptionType) => category.value
    );
    setValue('categories', selectedCategory);
  };

  return (
    <form>
      <Stack direction="column" spacing="8">
        <Grid gap="4" templateColumns="repeat(1, 1fr)">
          <GridItem>
            <FormControl
              isInvalid={!!errors?.name}
              isRequired
              w={['100%', '50%']}>
              <FormLabel>{strings.title}</FormLabel>
              <Textarea
                {...register('name', {
                  required: strings.title_required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.title}
                defaultValue={data?.name}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl
              isInvalid={!!errors?.type}
              w={['100%', '50%']}
              isRequired>
              <FormLabel>{strings.type}</FormLabel>
              <Select
                {...register('type', {
                  required: strings.required_type,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                placeholder={strings.select_type}
                rounded="sm"
                isDisabled={data?.type}>
                <option value="open">{strings.open_ended}</option>
                <option value="closed">{strings.closed_ended}</option>
                <option value="rating">{strings.rating}</option>
              </Select>
              <FormErrorMessage>
                {errors?.type && errors?.type?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.categories} w={['100%', '50%']}>
              <FormLabel>{strings.publish}</FormLabel>
              <Controller
                control={control}
                name="categories"
                render={({ field: { ref } }) => (
                  <ReactSelect
                    ref={ref}
                    isMulti
                    placeholder={strings.select_publish}
                    defaultValue={data?.categories?.map(
                      (category: PublishValueType) => ({
                        value: category,
                        label: strings[category],
                      })
                    )}
                    options={PublishSelectOptions}
                    onChange={handlePublishSelection}
                    closeMenuOnSelect={false}
                    styles={reactSelectStyles}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.categories && errors?.categories?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default FeedbackQuestionForm;
