import { Flex, Icon } from '@chakra-ui/react';
import FeedbackDashboardResource from 'api/feedback-dashboard';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiShowAlt } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

interface Props {
  resourceId: number;
  keyword?: string;
  type: 'received' | 'closed';
  dashboardType: 'customer' | 'object';
}

const FeedbackArea: React.FC<Props> = (props) => {
  const { type, keyword, resourceId, dashboardType } = props;

  const dashboardAPI = new FeedbackDashboardResource();
  const { dashboardCustomerFeedbacks, dashboardObjectFeedbacks } = dashboardAPI;
  const { data: feedbackInfo, isLoading } = useQuery(
    [
      `${type}-dashboard-feedback-info`,
      type,
      keyword,
      resourceId,
      dashboardType,
    ],
    () => {
      let searchKey =
        dashboardType === 'object' ? 'customer_name' : 'apartment_name';
      let dashAPI =
        dashboardType === 'object'
          ? dashboardObjectFeedbacks
          : dashboardCustomerFeedbacks;
      return dashAPI(resourceId, {
        status: type,
        [searchKey]: keyword,
      }).then((res) => res.data?.data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  if (isLoading) {
    return <TicketSkeletonLoader rows={3} />;
  } else if (!feedbackInfo) return null;
  return feedbackInfo.map((item: any) => (
    <Flex
      key={item.reservation_id}
      width="100%"
      p="10px"
      border="1px solid #EBECF2"
      borderRadius="10px"
      alignItems="center"
      justifyContent="space-between">
      <div>
        <div>
          {dashboardType === 'object'
            ? item?.reservation?.customer_info?.forename +
              ' ' +
              item?.reservation?.customer_info?.surname
            : item.reservation?.apartment?.name || '-'}
        </div>
      </div>
      <div>
        <Link
          target="_blank"
          to={`${routes.feedback.feedbacks.details.replace(
            ':id',
            item.reservation_id
          )}`}>
          <Icon
            as={BiShowAlt}
            title={strings.view}
            w="8"
            h="8"
            _hover={{ cursor: 'pointer' }}
          />
        </Link>
      </div>
    </Flex>
  ));
};

export default FeedbackArea;
