export const objectListData = [
  {
    id: '1',
    name: '#object 1',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '2',
    name: '#object 2',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '3',
    name: '#object 3',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '5',
    name: '#object 5',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '1',
    name: '#object 1',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '5',
    name: '#object 5',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '6',
    name: '#object 1',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '7',
    name: '#object 5',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '8',
    name: '#object 1',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '9',
    name: '#object 5',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '101',
    name: '#object 1',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '105',
    name: '#object 5',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
  {
    id: '101',
    name: '#object 1',
    booking: '31',
    open: '1',
    inprogress: '3',
    done: '2',
    load: '35',
  },
];
