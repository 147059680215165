import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, Grid, GridItem, Heading, Stack } from '@chakra-ui/layout';
import { Button, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import ServiceReservationResource from 'api/service-reservation';
import {
  infoStyles,
  labelStyles,
  selectColorScheme,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { PdfBtn } from 'assets/icons';
import BookingServiceInfo from 'components/bms_booking/BookingServiceInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReservationBooking } from 'constants/schemas/reservationBooking';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import BookingDetailsPDF from './BookingDetailsPDF';

const BookingDetail: React.FC = () => {
  const { id: reservationID }: any = useParams();
  const BookingAPI = new ServiceReservationResource();
  const { search } = useLocation();
  const printRef: any = useRef();

  const {
    data: bookingDetailData,
    isLoading,
    isError,
  } = useQuery<ReservationBooking>([`booking-details-${reservationID}`], () =>
    BookingAPI.get(reservationID).then((res) => res.data.data)
  );

  const handlePrintBookingDetail = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { padding-left:2px,padding-right:2px }',
    documentTitle: `${bookingDetailData?.customer?.forename}_${bookingDetailData?.customer?.surname}_${bookingDetailData?.booking_date}`,
  });

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  } else if (isError || !bookingDetailData) {
    return <div>{strings.error}</div>;
  }

  let { customer, bookings, payment, reservation, status } = bookingDetailData;

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.bms} | {reservation?.booking_number ?? '-'}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list + search}>
            {strings.bms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list + search}>
            {strings.booking}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {reservation?.booking_number ?? '-'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.booking_detail}
        </Heading>
        <Tooltip label={strings.download_pdf} hasArrow>
          <Button
            size="sm"
            colorScheme="red"
            variant="outline"
            onClick={handlePrintBookingDetail}>
            <Image w="6" src={PdfBtn} alt="PDF" title="PDF" />
          </Button>
        </Tooltip>
      </Flex>

      <div style={{ display: 'none' }}>
        <BookingDetailsPDF
          bookingDetailData={bookingDetailData}
          ref={printRef}
        />
      </div>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem span={6}>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.bookings}:</FormLabel>
              <Box sx={infoStyles}>{reservation?.booking_number ?? '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem span={6}>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_period}:</FormLabel>
              <Box sx={infoStyles}>{reservation?.booking_period ?? '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem span={6}>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.units}:</FormLabel>
              <Box sx={infoStyles}>{reservation?.bookings?.unit ?? '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem span={6}>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer}:</FormLabel>
              <Box sx={infoStyles}>
                {bookingDetailData?.customer_name ?? '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem span={6}>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.mobile}:</FormLabel>
              <Box sx={infoStyles}>{customer?.mobile ?? '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem span={6}>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.status}:</FormLabel>
              <Box sx={infoStyles}>
                <Text
                  color={selectColorScheme(status)}
                  textTransform="uppercase"
                  fontWeight="medium"
                  fontSize="sm">
                  {strings[status] ?? '-'}
                </Text>
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      {bookings?.length > 0 && (
        <BookingServiceInfo
          bookings={bookings}
          payment={payment}
          status={status}
        />
      )}
    </Stack>
  );
};

export default BookingDetail;
