import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkPermissions } from 'utils/listInfo';

interface Props {
  path: string;
}
const TicketAddMenu: React.FC<Props> = ({ path }) => {
  const history = useHistory();
  const toast = useToast();
  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const handleTicketType = (ticketType: string) => {
    const isPermitted: boolean = checkPermissions(userPermissions, [
      PermissionRequest['create:ticket'],
    ]);
    if (!isPermitted) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    history.push({
      pathname: path,
      state: {
        ticketType,
      },
    });
  };
  return (
    <Menu>
      <MenuButton
        size="sm"
        as={Button}
        colorScheme="primary"
        rightIcon={<BiChevronDown />}>
        {strings.create_ticket}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleTicketType('recursiveTask')}>
          {strings.recursive_task}
        </MenuItem>
        <MenuItem onClick={() => handleTicketType('normal')}>
          {strings.normal_task}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default TicketAddMenu;
