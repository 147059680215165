import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import {
  apartment_search_url,
  customer_search_url,
  MDM_SEARCH_QUERY_LIMIT,
} from 'constants/common';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ReactSelect from 'react-select';
import { cleanData } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';

interface Props {
  handleAdvancedSearch: any;
  isStatus?: boolean;
  handleResetSearch?: () => void;
  isLoading?: string | null;
}
const InitialValue = {
  customer: '',
  apartment: '',
  due_date: '',
  invoice_date: '',
  cancelled_date: '',
  net_amount: '',
  total_amount: '',
  remainig_amount: '',
};

const InvoiceSearch: React.FC<Props> = ({ isStatus = true, ...props }) => {
  const { handleAdvancedSearch, isLoading } = props;
  /**
   * Initialize
   */
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(null);
  const [cancelledDate, setCancelledDate] = useState<Date | null>(null);
  const [formValue, setFormValue] = useState<any>(InitialValue);

  const {
    loading: customerListLoading,
    result: customerQuery,
    setQuery,
  } = useWordSearch(customer_search_url, {
    limit: MDM_SEARCH_QUERY_LIMIT,
    is_owner: false,
  });

  const {
    loading: apartmentLoading,
    result: apartmentQuery,
    setQuery: setApartmentQuery,
  } = useWordSearch(apartment_search_url);

  const apartmentList = apartmentQuery?.data?.data;
  const customerList = customerQuery?.data?.data;

  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.info.abbreviation,
    value: apartment.id,
  }));
  const customerOptions = customerList?.map((user: any) => ({
    label: user.last_name + ' ' + user.first_name,
    value: user.id,
  }));

  const handleDateChange = (date: Date) => {
    setDueDate(date);
  };
  const handleInvoiceDateChange = (date: Date) => {
    setInvoiceDate(date);
  };
  const handleCancelledChange = (date: Date) => {
    setCancelledDate(date);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleApartmentChange = (value: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      apartment: value,
    }));
  };
  const handleCustomerChange = (value: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      customer: value,
    }));
  };
  useEffect(() => {
    const finalData: any = cleanData(formValue);
    if (dueDate) finalData.due_date = DateFormatYMD(dueDate);
    if (invoiceDate) finalData.invoice_date = DateFormatYMD(invoiceDate);
    if (cancelledDate) finalData.cancelled_date = DateFormatYMD(cancelledDate);
    if (finalData.customer)
      finalData.customer = finalData.customer.label.split(' ').join(', ');
    if (finalData.apartment) finalData.apartment = finalData.apartment.label;
    handleAdvancedSearch(finalData, 'filter');
  }, [cancelledDate, dueDate, formValue, handleAdvancedSearch, invoiceDate]);

  const handleReset = () => {
    setFormValue(InitialValue);
    setDueDate(null);
    setInvoiceDate(null);
    setCancelledDate(null);
    if (handleAdvancedSearch !== undefined) handleAdvancedSearch({});
  };

  const handleCustomerInputChange = (value: string) => {
    setQuery({
      limit: MDM_SEARCH_QUERY_LIMIT,
      is_owner: false,
      name: value || null,
    });
  };
  const handleApartmentInputChange = (value: string) => {
    setApartmentQuery({
      limit: MDM_SEARCH_QUERY_LIMIT,
      name: value || null,
    });
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="row" spacing="4">
            <Grid
              gap="4"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.customer}</FormLabel>
                  <ReactSelect
                    name="customer"
                    value={formValue.customer}
                    placeholder={strings.select_customer}
                    options={customerOptions}
                    styles={reactSelectStyles}
                    onInputChange={handleCustomerInputChange}
                    onChange={handleCustomerChange}
                    isLoading={customerListLoading}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.invoice_date}</FormLabel>
                  <InputGroup>
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleInvoiceDateChange}
                      selected={invoiceDate}
                      value={DateFormatYMD(invoiceDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />

                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              {isStatus && (
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.object}</FormLabel>
                    <ReactSelect
                      name="apartment"
                      value={formValue.apartment}
                      placeholder={strings.select_apartment}
                      options={apartmentOptions}
                      styles={reactSelectStyles}
                      onInputChange={handleApartmentInputChange}
                      onChange={handleApartmentChange}
                      isLoading={apartmentLoading}
                    />
                  </FormControl>
                </GridItem>
              )}
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.net}</FormLabel>
                  <Input
                    name="net_amount"
                    value={formValue.net_amount}
                    type="text"
                    placeholder={strings.net}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.total}</FormLabel>
                  <Input
                    type="text"
                    name="total_amount"
                    value={formValue.total_amount}
                    placeholder={strings.total}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.open}</FormLabel>
                  <Input
                    type="text"
                    name="remainig_amount"
                    value={formValue.remainig_amount}
                    placeholder={strings.open}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>{strings.due_date}</FormLabel>
                  <InputGroup>
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleDateChange}
                      selected={dueDate}
                      value={DateFormatYMD(dueDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.cancelled_on}</FormLabel>

                  <InputGroup>
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleCancelledChange}
                      selected={cancelledDate}
                      value={DateFormatYMD(cancelledDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack direction="row" spacing="4" pt="4">
            <Button
              colorScheme="primary"
              onClick={handleReset}
              isLoading={isLoading === 'reset'}
              variant="outline">
              {strings.reset_filter}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default InvoiceSearch;
