import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import {
  MembershipLevelSchema,
  ThirdPartyPartnerSchema,
} from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import PartnerGoodiesCheckboxGroup from './PartnerGoodiesCheckboxGroup';

interface Props {
  data?: MembershipLevelSchema;
}

const MembershipLevelForm: React.FC<Props> = (props) => {
  let { data } = props;

  const thirdPartyPartnerAPI = new ThirdPartyPartnerResource();
  const toast = useToast();

  const thirdPartyPartnerListData: any = useQuery(
    ['third-party-partner-list-all'],
    () => {
      return thirdPartyPartnerAPI
        .getAll()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  );

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<MembershipLevelSchema>();
  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('description', data.description);
      setValue('brief_description', data.brief_description);
      setValue('is_active', data.is_active);
      setValue('booking_discount', data.booking_discount);
      setValue('final_cleaning_discount', data.final_cleaning_discount);
      setValue('laundry_discount', data.laundry_discount);
      setValue('website_condition_discount', data.website_condition_discount);
    }
  }, [data, setValue]);

  return (
    <form>
      <Stack direction="column" spacing="8">
        <Stack direction="row" spacing="8">
          <Grid gap="4" templateColumns={['repeat(1, 1fr)']} w="100%">
            <GridItem w="30%">
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>{strings.name_membership_level}</FormLabel>
                <Input
                  {...register('name', {
                    required: strings.required_membership_level,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  type="text"
                  placeholder={strings.name_membership_level}
                  defaultValue={data?.name}
                />
                <FormErrorMessage>
                  {errors.name && errors.name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{strings.brief_description}</FormLabel>
                <Textarea
                  {...register('brief_description')}
                  placeholder={strings.brief_description}
                  defaultValue={data?.brief_description}
                  minH={'50px'}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.description}</FormLabel>
                <Textarea
                  {...register('description')}
                  placeholder={strings.description}
                  defaultValue={data?.description}
                  minH={'100px'}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.active}</FormLabel>
                <Checkbox
                  {...register('is_active')}
                  defaultChecked={data?.is_active}
                />
              </FormControl>
            </GridItem>
            <GridItem w={'20%'}>
              <FormControl>
                <FormLabel>{strings.icon}</FormLabel>
                <ImageUploader
                  uploadedFiles={data?.icon}
                  accept="image/png, image/svg"
                  fileKey="icons"
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors.website_condition_discount}>
                <>
                  <FormLabel>{strings.website_condition_discount}</FormLabel>
                  <Input
                    type="number"
                    {...register('website_condition_discount')}
                    min={0}
                    defaultValue={data?.website_condition_discount}
                    maxWidth="100"
                  />
                </>
                <FormErrorMessage>
                  {errors.website_condition_discount &&
                    errors.website_condition_discount?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <Heading size="xs" mt="5" fontWeight="600">
              {strings.special_conditions_on_the_website}
            </Heading>
            <GridItem>
              <FormControl isInvalid={!!errors.final_cleaning_discount}>
                <HStack shouldWrapChildren direction="row">
                  <Input
                    type="number"
                    {...register('final_cleaning_discount')}
                    min={0}
                    defaultValue={data?.final_cleaning_discount}
                    maxWidth="100"
                  />

                  <FormLabel>{strings.final_cleaning_discount}</FormLabel>
                </HStack>
                <FormErrorMessage>
                  {errors.final_cleaning_discount &&
                    errors.final_cleaning_discount.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={!!errors.booking_discount}>
                <HStack shouldWrapChildren direction="row">
                  <Input
                    type="number"
                    {...register('booking_discount')}
                    min={0}
                    defaultValue={data?.booking_discount}
                    maxWidth="100"
                  />

                  <FormLabel>{strings.booking_discount}</FormLabel>
                </HStack>
                <FormErrorMessage>
                  {errors.booking_discount && errors.booking_discount.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={!!errors.laundry_discount}>
                <HStack shouldWrapChildren direction="row">
                  <Input
                    type="number"
                    {...register('laundry_discount')}
                    min={0}
                    defaultValue={data?.laundry_discount}
                    maxWidth="100"
                  />

                  <FormLabel>{strings.laundry_discount}</FormLabel>
                </HStack>
                <FormErrorMessage>
                  {errors.laundry_discount && errors.laundry_discount.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem mt="5">
              <Heading size="sm">
                {strings.extra_goodies} ({strings.third_party_partner}):
              </Heading>
              <Grid
                gap="4"
                templateColumns={['repeat(1, 1fr)', 'repeat(5, 1fr)']}
                mt="7">
                {thirdPartyPartnerListData?.data?.data?.map(
                  (
                    thirdPartyPartnerData: ThirdPartyPartnerSchema,
                    index: number
                  ) => (
                    <PartnerGoodiesCheckboxGroup
                      data={thirdPartyPartnerData}
                      key={index}
                      selectedValue={data?.partner_goodies}
                    />
                  )
                )}
              </Grid>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </form>
  );
};

export default MembershipLevelForm;
