import http from 'utils/http';
import Resource from './resource';

class ReservationResource extends Resource {
  constructor() {
    super('rms/reservations');
  }

  getCustomerBookingHistory(id: number, query: any) {
    return http({
      url: `/rms/customer-bookings/${id}`,
      method: 'GET',
      params: query,
    });
  }
}

export { ReservationResource as default };
