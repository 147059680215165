import PermissionRequest from 'constants/PermissionRequest';
import React from 'react';
import { connect } from 'react-redux';
import { checkSomePermissions } from 'utils/listInfo';

interface Props {
  hideFor: string;
  children?: any;
  renderNoAccess?: any;
  userPermissions: any;
}
const HideControl: React.FC<Props> = ({
  hideFor,
  children,
  renderNoAccess,
  userPermissions,
}) => {
  switch (hideFor) {
    case '':
      return renderNoAccess(children);

    case 'user-service':
      if (
        process.env.REACT_APP_USER_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:user'],
          PermissionRequest['manage:external-companies'],
          PermissionRequest['read:login-log'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'chat-service':
      if (
        process.env.REACT_APP_CHAT_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:chat'],
          PermissionRequest['read:chat-metrics'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'ticket-service':
      if (
        process.env.REACT_APP_TICKET_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:ticket'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'mdm-service':
      if (
        process.env.REACT_APP_MDM_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [PermissionRequest['read:mdm']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'crm-service':
      if (
        process.env.REACT_APP_CRM_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:crm'],
          PermissionRequest['merge:contacts'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'contact-center-service':
      if (
        process.env.REACT_APP_CONTACT_CENTER_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:contact-center'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'feedback-service':
      if (
        process.env.REACT_APP_FEEDBACK_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:feedback'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'mms-service':
      if (
        process.env.REACT_APP_MMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [PermissionRequest['read:mms']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'mms-service-object':
      if (process.env.REACT_APP_MMS_SERVICE_OBJECT === 'true') {
        return renderNoAccess(children);
      }
      break;
    case 'mms-service-stocktaking':
      if (process.env.REACT_APP_MMS_SERVICE_STOCKTAKING === 'true') {
        return renderNoAccess(children);
      }
      break;
    case 'mms-service-my-inventory':
      if (process.env.REACT_APP_MMS_SERVICE_MY_INVENTORY === 'true') {
        return renderNoAccess(children);
      }
      break;
    case 'mms-service-qr-code':
      if (process.env.REACT_APP_MMS_SERVICE_QR_CODE === 'true') {
        return renderNoAccess(children);
      }
      break;
    case 'oms-service':
      if (
        process.env.REACT_APP_OMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [PermissionRequest['read:oms']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'dms-service':
      if (
        process.env.REACT_APP_DMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:dms'],
          PermissionRequest['read:customer-dms'],
          PermissionRequest['read:owner-dms'],
          PermissionRequest['read:object-dms'],
          PermissionRequest['read:user-dms'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'sms-service':
      if (
        process.env.REACT_APP_SMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [PermissionRequest['read:sms']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'bms-service':
      if (
        process.env.REACT_APP_BMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:reservations'],
          PermissionRequest['read:bookings'],
          PermissionRequest['read:callbacks'],
          PermissionRequest['read:booking-terminals'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'bms-check-in-out':
      if (process.env.REACT_APP_CHECK_IN_OUT === 'true') {
        return renderNoAccess(children);
      }
      break;

    case 'bs-service':
      if (
        process.env.REACT_APP_BS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:bread-service'],
          PermissionRequest['read:bread-driving-order'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'pms-service':
      if (
        process.env.REACT_APP_PMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [PermissionRequest['read:pms']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'pns-service':
      if (
        process.env.REACT_APP_PNS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [PermissionRequest['read:pns']])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'newsletter-service':
      if (
        process.env.REACT_APP_NEWSLETTER_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:newsletter'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'faq-service':
      if (
        process.env.REACT_APP_FAQ_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:faq'],
          PermissionRequest['read:internal-faq'],
          PermissionRequest['read:faq-group'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'key-service':
      if (
        process.env.REACT_APP_KEY_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:keylog'],
          PermissionRequest['read:keylist'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'aerial-map-service':
      if (
        process.env.REACT_APP_AERIAL_MAP_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:aerial-map'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'dashboard':
      if (
        process.env.REACT_APP_DASHBOARD === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:dashboard'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'travelytics':
      if (
        process.env.REACT_APP_TRAVELYTICS === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:travelytics'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'object-dashboard':
      if (
        process.env.REACT_APP_OBJECT_DASHBOARD === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:object-dashboard'],
          PermissionRequest['manage:cross-selling'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'third-party-partner':
      if (
        process.env.REACT_APP_THIRD_PARTY_PARTNER === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['manage:third-party-partner'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
    case 'restaurant':
      if (
        process.env.REACT_APP_RESTAURANT === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['manage:restaurant'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
  }

  return renderNoAccess();
};

HideControl.defaultProps = {
  renderNoAccess: () => null,
};

export default connect((state: any) => ({
  userPermissions: state?.data?.auth?.permissions,
}))(HideControl);
