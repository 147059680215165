import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ContactResource from 'api/contacts';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CsvUpload from 'components/mdm/CsvUpload';
import { strings } from 'config/localization';
import {
  CONTACTS_CSV_UPLOAD_API,
  CONTACT_HEADER,
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

type LoadingType = 'filter' | 'reset' | '';

type QueryParamType = {
  page: number;
  limit: number;
};

const ContactData: React.FC = () => {
  let contactAPI = new ContactResource();

  const [refetch, setRefetch] = useState<number>(1);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const contactListQuery = useQuery(
    [
      'listContact',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
      refetch,
    ],
    async () => {
      const queryParam: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await contactAPI.list(queryParam);
      return response?.data?.data;
    }
  );

  let { isLoading } = contactListQuery;

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mdm} | {strings.all_contacts}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
            {strings.mdm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
            {strings.all_contacts}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.contacts}
        </Heading>
        <CsvUpload
          url={CONTACTS_CSV_UPLOAD_API}
          setRefetch={setRefetch}
          headerSchema={CONTACT_HEADER}
        />
      </Flex>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.id}</Th>
                <Th>{strings.first_name}</Th>
                <Th>{strings.last_name}</Th>
                <Th>{strings.street}</Th>
                <Th>{strings.post_code}</Th>
                <Th>{strings.town}</Th>
                <Th>{strings.business}</Th>
                <Th>{strings.house_no}</Th>
                <Th>{strings.country}</Th>
                <Th>{strings.language}</Th>
                <Th>{strings.letter_salutation}</Th>
                <Th>{strings.letter_closure}</Th>
                <Th>{strings.salutation}</Th>
                <Th>{strings.title}</Th>
                <Th>{strings.birthdate}</Th>
                <Th>{strings.house_owner}</Th>
                <Th>{strings.phone_number}</Th>
                <Th>{strings.business_phone_number}</Th>
                <Th>{strings.mobile_number}</Th>
                <Th>{strings.fax}</Th>
                <Th>{strings.email}</Th>
                <Th>{strings.im}</Th>
                <Th>{strings.website}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                contactListQuery?.data?.data?.map(
                  ({
                    id,
                    first_name,
                    last_name,
                    street,
                    zip_code,
                    location,
                    company,
                    house_no,
                    country,
                    language,
                    letter_opening,
                    letter_closing,
                    salutation,
                    title,
                    birth_date,
                    owner,
                    owner_phone,
                    business_phone,
                    phone,
                    fax,
                    email,
                    in_the,
                    homepage,
                  }: any) => (
                    <Tr key={id}>
                      <Td>{id}</Td>
                      <Td>{first_name || '-'}</Td>
                      <Td>{last_name || '-'}</Td>
                      <Td>{street || '-'}</Td>
                      <Td>{zip_code || '-'}</Td>
                      <Td>{location || '-'}</Td>
                      <Td>{company || '-'}</Td>
                      <Td>{house_no || '-'}</Td>
                      <Td>{country || '-'}</Td>
                      <Td>{language || '-'}</Td>
                      <Td>{letter_opening || '-'}</Td>
                      <Td>{letter_closing || '-'}</Td>
                      <Td>{salutation || '-'}</Td>
                      <Td>{title || '-'}</Td>
                      <Td>{DateFormat(birth_date) || '-'}</Td>
                      <Td>{`${owner}`}</Td>
                      <Td>{owner_phone || '-'}</Td>
                      <Td>{business_phone || '-'}</Td>
                      <Td>{phone || '-'}</Td>
                      <Td>{fax || '-'}</Td>
                      <Td>{email || '-'}</Td>
                      <Td>{in_the || '-'}</Td>
                      <Td>{homepage || '-'}</Td>
                    </Tr>
                  )
                )}
              {isLoading && <TableSkeletonLoader rows={7} cols={22} />}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={contactListQuery}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default ContactData;
