import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import CustomTabs from 'components/common/CustomTab';
import { strings } from 'config/localization';
import { LANGUAGE_OPTIONS } from 'constants/common';
import routes from 'constants/routes';
import { LanguageAbbreviationType, ServiceGroupSchema } from 'constants/schema';
import React from 'react';
import history from 'utils/history';
import { fallbackGermanValue, formatPrice } from 'utils/index';

interface Props {
  serviceGroupData: ServiceGroupSchema;
  search: string;
}

const ServiceGroupInfo: React.FC<Props> = (props) => {
  const { serviceGroupData, search } = props;

  const descriptions = new Map<LanguageAbbreviationType, string>();
  const titles = new Map<LanguageAbbreviationType, string>();

  /**
   * Get descriptions and titles according to the language type.
   */
  serviceGroupData.descriptions.forEach((item) => {
    descriptions.set(item.language, item.description);
  });
  serviceGroupData.titles.forEach((item) => {
    titles.set(item.language, item.title);
  });

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.service_group_details}
        </Heading>
        <Stack direction="row" spacing="6">
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              history.push(
                routes.sms.serviceGroups.edit.replace(
                  ':id',
                  serviceGroupData.id.toString()
                ) + search
              )
            }>
            {strings.edit_service_group}
          </Button>
        </Stack>
      </Flex>

      <CustomTabs
        display="flex"
        flexDirection="column"
        gridGap="4"
        sx={wrapperStyles}
        defaultIndex={0}>
        <CustomTabs.TabList>
          {LANGUAGE_OPTIONS.map((lang) => (
            <CustomTabs.Tab key={lang.value}>
              {strings[lang.value]}
            </CustomTabs.Tab>
          ))}
        </CustomTabs.TabList>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl>
                    <FormLabel sx={labelStyles}>
                      {strings.service_name} ({strings[lang.value]})
                    </FormLabel>
                    <Box sx={infoStyles}>
                      {titles.get(lang.value) ||
                        fallbackGermanValue(
                          lang.value,
                          serviceGroupData.name
                        ) ||
                        '-'}
                    </Box>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.frontend_color}</FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData.color
                  ? strings.getString(
                      serviceGroupData.color.toLowerCase().split(' ').join('_')
                    )
                  : '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.booking_fee_calculation}
              </FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData.booking_fee_type
                  ? strings.getString(
                      serviceGroupData.booking_fee_type
                        .toLowerCase()
                        .split(' ')
                        .join('_')
                    )
                  : '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.booking_fee} (&euro;)
              </FormLabel>
              <Box sx={infoStyles}>
                {formatPrice(serviceGroupData?.booking_fee)} &euro;
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.price} (&euro;)</FormLabel>
              <Box sx={infoStyles}>
                {formatPrice(serviceGroupData?.price_per_night)} &euro;
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.price_calculation}
              </FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData.service_per_night
                  ? strings.per_night
                  : strings.per_day}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl>
                    <FormLabel sx={labelStyles}>
                      {strings.description} ({strings[lang.value]})
                    </FormLabel>
                    <Box sx={infoStyles}>
                      {descriptions.get(lang.value) ||
                        fallbackGermanValue(
                          lang.value,
                          serviceGroupData.description
                        ) ||
                        '-'}
                    </Box>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
        </Grid>

        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.show_on_landing_page}
              </FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.show_on_page
                  ? strings.yes
                  : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.default_service}</FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.is_default ? strings.yes : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.early_deliverable}
              </FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.early_deliverable
                  ? strings.yes
                  : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.early_deliverable_on_start}
              </FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.early_deliverable_start
                  ? strings.yes
                  : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.pay_to_vendor}</FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.pay_to_vendor
                  ? strings.yes
                  : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.dog_service}</FormLabel>
              <Box sx={infoStyles}>
                {serviceGroupData?.is_dog_service
                  ? strings.yes
                  : strings.no || '-'}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.icon}</FormLabel>
              <Box sx={infoStyles}>
                <Image
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="1"
                  boxSize="200px"
                  objectFit="cover"
                  src={serviceGroupData?.icon}
                  alt={strings.icon}
                />
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.cover_image}</FormLabel>
              <Box sx={infoStyles}>
                <Image
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="1"
                  boxSize="200px"
                  objectFit="cover"
                  src={serviceGroupData?.cover}
                  alt={strings.icon}
                />
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </CustomTabs>
    </>
  );
};
export default ServiceGroupInfo;
