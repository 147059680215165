import {
  FormControl,
  FormLabel,
  IconButton,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import { toggleUserStatus } from 'api/chat';
import { strings } from 'config/localization';
import { Permission } from 'constants/interfaces';
import useChatUserInfo from 'hooks/query-hooks/useChatUserInfo';
import React, { useEffect, useRef } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { useQueryClient } from 'react-query';

interface Props {
  loggedInId: number;
  isInActiveCall: boolean;
  loggedInUserPermissions: any;
}

const PERMISSION_ACCESS_CHAT_ASSIGN = 'access-chat-assign';

const UserStatusSwitch: React.FC<Props> = (props) => {
  const { loggedInId, isInActiveCall, loggedInUserPermissions } = props;
  const { via_role: viaRole, direct } = loggedInUserPermissions;

  const queryClient = useQueryClient();

  const isInitialMount = useRef(true);
  const handleInActiveCallToggle = useRef(() => {});

  const { status } = useChatUserInfo();

  /**
   * Handle User status changes
   */
  const handleUserStatus = async () => {
    await toggleUserStatus(loggedInId);
    queryClient.invalidateQueries('status');
  };

  /**
   * Handle change on user's call activity
   * Toggle status if status was inactive initially and user makes the call
   * Toggle status if status was active intially and user ends the call
   *
   */
  handleInActiveCallToggle.current = () => {
    if (isInActiveCall && status) {
      handleUserStatus();
    } else if (!isInActiveCall && status !== undefined && !status) {
      handleUserStatus();
    }
  };

  /**
   * Set status to inactive when the user is in active call
   * Set status to active when the call is ended if the status was inactive previously
   *
   */
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleInActiveCallToggle.current();
    }
  }, [isInActiveCall]);

  const shouldEnableStatusSwitch =
    viaRole.some((p: Permission) => p.name === PERMISSION_ACCESS_CHAT_ASSIGN) ||
    direct.some((p: Permission) => p.name === PERMISSION_ACCESS_CHAT_ASSIGN);

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor="email-alerts" mb="0">
        {status ? strings.active : strings.in_active}
        <Tooltip
          hasArrow
          label={strings.chat_active_toggle_tooltip_message}
          fontWeight="normal"
          bg="white"
          placement="left"
          color="gray.500"
          p={4}>
          <IconButton
            icon={<BiInfoCircle />}
            variant="link"
            aria-label={strings.chat_active_info}
            color="gray.400"
            ml={1}
            minW="4"
          />
        </Tooltip>
      </FormLabel>
      <Switch
        colorScheme="green"
        onChange={handleUserStatus}
        isChecked={status}
        isDisabled={!shouldEnableStatusSwitch}
      />
    </FormControl>
  );
};

export default UserStatusSwitch;
