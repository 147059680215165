import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InventoryResource from 'api/inventories';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import MyInventoryListItem from 'components/inventory/MyInventoryListItem';
import MyInventorySearch from 'components/inventory/MyInventorySearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { InventorySchema, MyInventorySearchInput } from 'constants/schema';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  article: string;
}

type QueryParamType = {
  page: number;
  limit: number;
  article?: string;
};

const MyInventory: React.FC = () => {
  const inventoryAPI = new InventoryResource();

  const { id: userID } = useSelector((state: any) => state.data.auth.user);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    article: '',
  });

  const inventoryList = useQuery(
    [
      'myInventoryList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        article: filterParams.article,
      },
    ],
    async () => {
      const queryParam: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.article) queryParam.article = filterParams.article;
      const response = await inventoryAPI.fetchByUserId(userID, queryParam);
      return response?.data?.data;
    }
  );

  /**
   * @param data {InventorySearchInput} Filter inputs
   */
  const handleAdvancedSearch = useCallback((data: MyInventorySearchInput) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      article: data.article,
    }));
  }, []);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mms} | {strings.my_inventory}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
            {strings.mms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mms.myInventory.list}>
            {strings.all_my_inventories}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.my_inventory}
        </Heading>
      </Flex>

      <MyInventorySearch handleAdvancedSearch={handleAdvancedSearch} />

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{strings.inventory_name}</Th>
                <Th isNumeric>{strings.total_quantity}</Th>
                <Th>{strings.unit}</Th>
                <Th>{strings.transfer}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {inventoryList?.data?.data?.map(
                (inventory: InventorySchema, index: number) => (
                  <MyInventoryListItem
                    userID={userID}
                    inventory={inventory}
                    index={startingSN + index}
                    key={index}
                  />
                )
              )}
              {inventoryList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={inventoryList}
      />
    </Stack>
  );
};

export default MyInventory;
