import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ContactCenterUserListSchema } from 'constants/schema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  data: ContactCenterUserListSchema;
  index: number;
  search?: string;
}

const ContactCenterUserListItem: React.FC<Props> = (props) => {
  const { data, index } = props;
  const {
    total_incoming_call,
    total_outgoing_call,
    incoming_call_duration,
    outgoing_call_duration,
    average_call_duration,
    total_duration,
    name,
    id,
  } = data;

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.contactCenter.members.profile.replace(
            ':id',
            id.toString()
          )}>
          <Text
            fontWeight="medium"
            color="gray.500"
            _hover={{ color: 'gray.600' }}>
            {name || '-'}
          </Text>
        </RouterLink>
      </Td>
      <Td>{total_incoming_call ?? '-'}</Td>
      <Td>{incoming_call_duration ?? '-'}</Td>
      <Td>{total_outgoing_call ?? '-'}</Td>
      <Td>{outgoing_call_duration ?? '-'}</Td>
      <Td>{average_call_duration ?? '-'}</Td>
      <Td>{total_duration ?? '-'}</Td>
      <Td>
        <RouterLink
          to={routes.contactCenter.members.profile.replace(
            ':id',
            id.toString()
          )}>
          <Tooltip hasArrow label={strings.view_user}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_user}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default ContactCenterUserListItem;
