import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import SMSServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import ServiceGroupForm from 'components/service_group/ServiceGroupForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ServiceGroupFormSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const AddServiceGroup: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<ServiceGroupFormSchema>();
  const serviceGrouppAPI = new SMSServiceGroupResource();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const addServiceGroup = useMutation(
    (data: FormData) => serviceGrouppAPI.store(data),
    {
      onSuccess: () => {
        toast({
          title: strings.service_group_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('serviceGroupList');
        history.push(routes.sms.serviceGroups.list);
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const onSubmit = (data: ServiceGroupFormSchema) => {
    const formData = new FormData();
    formData.append('name', data.titles_de);
    formData.append('early_deliverable', data.early_deliverable);
    formData.append('is_default', data.is_default);
    formData.append('show_on_page', data.show_on_page);
    formData.append('early_deliverable_start', data.early_deliverable_start);
    formData.append('booking_fee', data.booking_fee.toString());
    formData.append('booking_fee_type', data.booking_fee_type);
    formData.append('pay_to_vendor', data.pay_to_vendor);
    formData.append('price_per_night', data.price_per_night.toString());
    formData.append('service_per_night', data.service_per_night.toString());
    formData.append('is_dog_service', data.is_dog_service);
    formData.append('color', data?.color);
    if (data.icon === undefined)
      methods.setError('icon', { message: strings.accept_only_png });
    if (data.cover === undefined)
      methods.setError('cover', { message: strings.accept_only_png });
    formData.append('icon', data?.icon);
    formData.append('cover', data?.cover);
    formData.append('descriptions[de]', data.descriptions_de);
    formData.append('descriptions[en]', data.descriptions_en);
    formData.append('descriptions[pl]', data.descriptions_pl);
    formData.append('descriptions[da]', data.descriptions_da);
    formData.append('descriptions[zh]', data.descriptions_zh);
    formData.append('titles[de]', data.titles_de);
    formData.append('titles[en]', data.titles_en);
    formData.append('titles[pl]', data.titles_pl);
    formData.append('titles[da]', data.titles_da);
    formData.append('titles[zh]', data.titles_zh);
    addServiceGroup.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.sms} | {strings.add_service_group}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.sms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.sms.serviceGroups.list}>
            {strings.service_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.sms.serviceGroups.add}>
            {strings.add_service_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.add_new_service_group}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {errorMessage.map((error) => (
                <Alert status="error" key={error}>
                  <AlertIcon />
                  {error}
                </Alert>
              ))}
              {addServiceGroup.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.service_group_creation_failed}
                </Alert>
              )}
              <ServiceGroupForm setErrorMessage={setErrorMessage} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addServiceGroup.isLoading}>
                  {strings.add_service_group}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddServiceGroup;
