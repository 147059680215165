import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import ImportResource from 'api/imports';
import { wrapperStyles } from 'assets/css/commonStyles';
import CSVUploader from 'components/common/CSVUploader';
import { strings } from 'config/localization';
import {
  BOOKINGS_CSV_UPLOAD_API,
  BOOKING_HEADER,
  CONTACTS_CSV_UPLOAD_API,
  CONTACT_HEADER,
  INVOICES_CSV_UPLOAD_API,
  INVOICE_HEADER,
  NEWSLETTERS_CSV_UPLOAD_API,
  NEWSLETTER_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

export const UPLOAD_FILE_TYPE =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsxx,.xlsx';

const UploadFiles: React.FC = () => {
  const methods = useForm();
  const importAPI = new ImportResource();

  let { data: contactQuery } = useQuery(
    ['import-type-contact'],
    () =>
      importAPI
        .uploadInfo({ import_type: 'contact' })
        .then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  let { data: invoiceQuery } = useQuery(
    ['import-type-invoice'],
    () =>
      importAPI
        .uploadInfo({ import_type: 'invoice' })
        .then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  let { data: bookingQuery } = useQuery(
    ['import-type-booking'],
    () =>
      importAPI
        .uploadInfo({ import_type: 'booking' })
        .then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  let { data: serviceQuery } = useQuery(
    ['import-type-service'],
    () =>
      importAPI
        .uploadInfo({ import_type: 'service' })
        .then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  let { data: apartmentQuery } = useQuery(
    ['import-type-apartment'],
    () =>
      importAPI
        .uploadInfo({ import_type: 'apartment' })
        .then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  let { data: newsletterQuery } = useQuery(
    ['import-type-newsletter'],
    () =>
      importAPI
        .uploadInfo({ import_type: 'newsletter_contact' })
        .then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mdm} | {strings.all_uploads}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
              {strings.mdm}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.mdm.uploads.list}>
              {strings.all_uploads}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.uploads}</Heading>
        </Flex>
        <FormProvider {...methods}>
          <Stack sx={wrapperStyles}>
            <SimpleGrid
              columns={[1, 1, 2, 2]}
              spacing="10"
              maxW="container"
              w="full">
              <FormControl>
                <FormLabel>{strings.contacts}</FormLabel>
                <CSVUploader
                  defaultFileName="Kontakte.xlsx"
                  uploadInfo={contactQuery}
                  acceptFileType={UPLOAD_FILE_TYPE}
                  url={CONTACTS_CSV_UPLOAD_API}
                  headerSchema={CONTACT_HEADER}
                />
              </FormControl>
              {/* <FormControl>
                <FormLabel>{strings.object}</FormLabel>
                <CSVUploader
                  defaultFileName="Objekt.xlsx"
                  uploadInfo={apartmentQuery}
                  acceptFileType={UPLOAD_FILE_TYPE}
                  url="/mdm/apartments/import-csv"
                  headerSchema={APARTMENT_HEADER}
                />
              </FormControl> */}
              <FormControl>
                <FormLabel>{strings.bookings}</FormLabel>
                <CSVUploader
                  defaultFileName="Belegungen.xlsx"
                  uploadInfo={bookingQuery}
                  acceptFileType={UPLOAD_FILE_TYPE}
                  url={BOOKINGS_CSV_UPLOAD_API}
                  headerSchema={BOOKING_HEADER}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{strings.invoices}</FormLabel>
                <CSVUploader
                  defaultFileName="Rechnungen.xlsx"
                  uploadInfo={invoiceQuery}
                  acceptFileType={UPLOAD_FILE_TYPE}
                  url={INVOICES_CSV_UPLOAD_API}
                  headerSchema={INVOICE_HEADER}
                />
              </FormControl>
              {/* <FormControl>
                <FormLabel>{strings.services}</FormLabel>
                <CSVUploader
                  defaultFileName="Services.csv"
                  uploadInfo={serviceQuery}
                  acceptFileType={UPLOAD_FILE_TYPE}
                  url={SERVICES_CSV_UPLOAD_API}
                  headerSchema={SERVICE_HEADER}
                />
              </FormControl> */}
              <FormControl>
                <FormLabel>{strings.newsletter}</FormLabel>
                <CSVUploader
                  defaultFileName="Newsletters.csv"
                  uploadInfo={newsletterQuery}
                  acceptFileType={UPLOAD_FILE_TYPE}
                  url={NEWSLETTERS_CSV_UPLOAD_API}
                  headerSchema={NEWSLETTER_HEADER}
                />
              </FormControl>
            </SimpleGrid>
          </Stack>
        </FormProvider>
      </Stack>
    </>
  );
};
export default UploadFiles;
