import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { formatPrice, formatPriceLabel } from 'utils';
import { enumerateDaysBetweenDates } from 'utils/DateFormat';

interface Props {
  bookings: any;
  payment: any;
  status: 'pending' | 'confirmed' | 'cancelled';
}

const BookingServicePDF: React.FC<Props> = (props) => {
  let { bookings, payment, status } = props;

  const [subTotal, setSubTotal] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [VAT19, setVAT19] = useState(0);
  const [VAT7, setVAT7] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalBookingFee, setTotalBookingFee] = useState(0);

  useEffect(() => {
    const booking_fee = bookings[0].booking_fee / bookings[0].timespan;

    let days = bookings
      .map((booki: any) =>
        enumerateDaysBetweenDates(booki.from_date, booki.till_date)
      )
      .flat();
    let uniqueDays = days.filter(function (item: any, pos: number) {
      return days.indexOf(item) === pos;
    });
    const totalDays = uniqueDays.length;

    let totalBookingFee = booking_fee * totalDays;
    setTotalBookingFee(totalBookingFee);

    // sum of price including all taxes.
    let sum = bookings.reduce(
      (acc: number, cur: { total_price: number }) => acc + cur.total_price,
      0
    );
    setTotalSum(sum);

    const finalTotal =
      bookings.reduce((acc: number, cur: any) => {
        return acc + (cur.total_price || 0);
      }, 0) + totalBookingFee;
    setGrandTotal(finalTotal);

    let amountWithoutTax = sum / 1.26;
    amountWithoutTax = ~~amountWithoutTax; //Bitwise not operator: round to nearest integer
    let vat19 = ~~(amountWithoutTax * 0.19);
    setVAT19(vat19);

    let vat7 = ~~(amountWithoutTax * 0.07);
    setVAT7(vat7);

    let finalSubTotal = sum - vat19 - vat7;
    setSubTotal(finalSubTotal);
  }, [bookings]);

  return (
    <TableContainer overflow="hidden" marginTop="0 !important">
      <Table size="sm" id="booking-pdf-table">
        <Thead>
          <Tr>
            <Th fontWeight="bold" color="blackAlpha.900">
              {strings.name}
            </Th>
            <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
              {strings.booking_period}
            </Th>
            <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
              {strings.price_pound}
            </Th>
            <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
              {strings.quantity}
            </Th>
            <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
              {strings.timespan}
            </Th>
            <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
              {strings.total_pound}
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {bookings?.map((bookings: any, index: number) => {
            return (
              <Tr key={index}>
                <Td
                  fontWeight="medium"
                  color="gray.800"
                  maxWidth="200px !important"
                  whiteSpace="initial">
                  {bookings?.service_name}
                  {bookings.description && (
                    <span className="booking-pdf-description">{` (${bookings.description})`}</span>
                  )}
                </Td>
                <Td isNumeric>{bookings?.booking_period || '-'}</Td>
                {bookings?.unit_price ? (
                  <Td isNumeric>{formatPrice(bookings?.unit_price)} &euro;</Td>
                ) : (
                  <Td isNumeric></Td>
                )}
                <Td isNumeric>{bookings?.quantity}</Td>
                <Td isNumeric>{bookings?.timespan}</Td>
                <Td isNumeric>{formatPrice(bookings?.total_price)} &euro;</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Table marginTop="5px">
        <Tfoot>
          <Tr className="booking-pdf-table-dashed-border">
            <Th
              borderColor="gray"
              width="90%"
              textAlign="right"
              borderStyle="dashed"
              fontWeight="bold"
              paddingBlock="8px"
              color="blackAlpha.900">
              {strings.total}
            </Th>
            <Th borderColor="gray" isNumeric borderStyle="dashed">
              {formatPrice(totalSum)} &euro;
            </Th>
          </Tr>
          <Tr>
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              borderColor="transparent"
              width="90%"
              paddingBlock="8px"
              textAlign="right">
              {strings.without_tax} {strings.subtotal}
            </Th>
            <Th borderColor="transparent" isNumeric>
              {formatPrice(subTotal)} &euro;
            </Th>
          </Tr>
          <Tr>
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              paddingBlock="8px"
              width="90%"
              textAlign="right"
              borderColor="transparent">
              {strings.vat19}
            </Th>
            <Th isNumeric borderColor="transparent">
              {formatPrice(VAT19)} &euro;
            </Th>
          </Tr>
          <Tr>
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              width="90%"
              paddingBlock="8px"
              textAlign="right"
              borderColor="transparent">
              {strings.vat7}
            </Th>
            <Th isNumeric borderColor="transparent">
              {formatPrice(VAT7)} &euro;
            </Th>
          </Tr>
          <Tr className="booking-pdf-table-dashed-border">
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              paddingBlock="8px"
              width="90%"
              textAlign="right"
              borderColor="gray"
              borderStyle="dashed">
              {strings.booking_fee_pound}
            </Th>
            <Th isNumeric borderColor="gray" borderStyle="dashed">
              {formatPrice(totalBookingFee)} &euro;
            </Th>
          </Tr>
          <Tr className="booking-pdf-table-solid-border">
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              paddingBlock="8px"
              width="90%"
              textAlign="right"
              borderColor="gray">
              {strings.grand_total} ({strings.euro_symbol})
            </Th>
            <Th isNumeric borderColor="gray">
              {formatPrice(grandTotal)} &euro;
            </Th>
          </Tr>

          <Tr>
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              width="90%"
              paddingBlock="8px"
              textAlign="right"
              borderColor="transparent">
              {strings.payment_method}
            </Th>
            <Th isNumeric borderColor="transparent">
              {payment?.payment_method ?? '-'}
            </Th>
          </Tr>
          <Tr>
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              width="90%"
              paddingBlock="8px"
              textAlign="right"
              borderColor="transparent">
              {strings.paid_amount}
            </Th>

            {formatPriceLabel(payment?.amount * 100) === 'NaN' ? (
              <Th isNumeric borderColor="transparent">
                -
              </Th>
            ) : (
              <Th isNumeric borderColor="transparent">
                {formatPrice(payment?.amount * 100)} &euro;
              </Th>
            )}
          </Tr>
          <Tr>
            <Th
              fontWeight="bold"
              color="blackAlpha.900"
              width="90%"
              paddingBlock="8px"
              textAlign="right"
              borderColor="transparent">
              {strings.status}
            </Th>
            <Th isNumeric borderColor="transparent">
              {strings[status] ?? '-'}
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default BookingServicePDF;
