import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import CustomTabs from 'components/common/CustomTab';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import {
  DESCRIPTION_MAPPER,
  LANGUAGE_OPTIONS,
  TITLE_MAPPER,
} from 'constants/common';
import { LanguageAbbreviationType, MeerSyltSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { fallbackGermanValue } from 'utils';
import { validURL } from 'utils/validate';

interface Props {
  data?: MeerSyltSchema;
  setErrorMessage: React.Dispatch<React.SetStateAction<string[]>>;
}

const TEXT_AREA_LENGTH = 1000;

const MeerSyltForm: React.FC<Props> = (props) => {
  const { data, setErrorMessage } = props;
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<any>();

  useEffect(() => {
    if (data) {
      setValue('title', data.title);
      setValue('text', data.text);
      setValue('link', data.link);
      setValue('is_active', !!data.is_active);
      setValue('is_load_on_iframe', !!data.is_load_on_iframe);
    }
  }, [data, setValue]);

  const titles = new Map<LanguageAbbreviationType, string>();
  const descriptions = new Map<LanguageAbbreviationType, string>();

  data?.titles.forEach((item) => {
    titles.set(item.language, item.title);
  });

  data?.descriptions.forEach((item) => {
    descriptions.set(item.language, item.description);
  });

  useEffect(() => {
    // Errors to show as alert because when the langauge tabs are changing,
    // Some input fields are not on screen and cannot be focused.
    const errs: string[] = [];

    if (errors.titles_de) {
      errs.push(`${strings.de}: ${errors.titles_de.message}`);
    }

    for (const [key, value] of Object.entries(DESCRIPTION_MAPPER)) {
      if (errors[value]) {
        const language = strings[key as LanguageAbbreviationType];
        errs.push(`${language}: ${errors[value]?.message}`);
      }
    }

    setErrorMessage(errs);
  }, [
    setErrorMessage,
    errors,
    errors.titles_de,
    errors.descriptions_de,
    errors.descriptions_en,
    errors.descriptions_zh,
    errors.descriptions_da,
    errors.descriptions_pl,
  ]);

  return (
    <form>
      <CustomTabs
        defaultIndex={0}
        display="flex"
        flexDirection="column"
        gridGap="4">
        <CustomTabs.TabList>
          {LANGUAGE_OPTIONS.map((lang) => (
            <CustomTabs.Tab key={lang.value}>
              {strings[lang.value]}
            </CustomTabs.Tab>
          ))}
        </CustomTabs.TabList>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1"
          w="100%">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl
                    isInvalid={!!errors?.[TITLE_MAPPER[lang.value]]}
                    isRequired={lang.value === 'de'}>
                    <FormLabel>
                      {strings.title} ({strings[lang.value]})
                    </FormLabel>
                    <Input
                      type="text"
                      defaultValue={
                        titles.get(lang.value) ||
                        fallbackGermanValue(lang.value, data?.title)
                      }
                      placeholder={strings.title}
                      {...register(TITLE_MAPPER[lang.value], {
                        required: lang.value === 'de' && strings.title_required,
                      })}
                    />

                    <FormErrorMessage>
                      {errors?.[TITLE_MAPPER[lang.value]] &&
                        errors?.[TITLE_MAPPER[lang.value]]?.message}
                    </FormErrorMessage>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>

          <GridItem>
            <FormControl isRequired isInvalid={!!errors.link}>
              <FormLabel>{strings.link}</FormLabel>
              <Input
                {...register('link', {
                  required: strings.link_is_required,
                  validate: (value) => {
                    if (value) return validURL(value) || strings.valid_link;
                  },
                })}
                type="text"
                placeholder={strings.link}
              />
              <FormErrorMessage>{errors.link?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.is_active}</FormLabel>
              <Switch
                {...register('is_active')}
                defaultChecked={data?.is_active}
                colorScheme="primary"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.is_load_on_iframe}</FormLabel>
              <Switch
                {...register('is_load_on_iframe')}
                defaultChecked={data?.is_load_on_iframe}
                colorScheme="primary"
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          templateColumns="repeat(1, 1fr)"
          flex="1"
          w="100%"
          overflow="auto">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl
                    isInvalid={!!errors?.[DESCRIPTION_MAPPER[lang.value]]}>
                    <FormLabel>
                      {strings.text} ({strings[lang.value]})
                    </FormLabel>
                    <Textarea
                      height="100px"
                      defaultValue={
                        descriptions.get(lang.value) ||
                        fallbackGermanValue(lang.value, data?.text)
                      }
                      placeholder={strings.description}
                      {...register(DESCRIPTION_MAPPER[lang.value], {
                        maxLength: {
                          value: TEXT_AREA_LENGTH,
                          message: strings.max_characters_exceeded,
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors?.[DESCRIPTION_MAPPER[lang.value]] &&
                        errors?.[DESCRIPTION_MAPPER[lang.value]]?.message}
                    </FormErrorMessage>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.image} isRequired>
              <FormLabel>{strings.image}</FormLabel>
              <ImageUploader
                required={true}
                uploadedFiles={data?.image}
                accept="image/png, image/svg, image/jpeg, image/jpg"
                fileKey="image"
              />
              <FormErrorMessage>{errors.image?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </CustomTabs>
    </form>
  );
};

export default MeerSyltForm;
