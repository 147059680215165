import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;

export default class ContactCenterResource extends Resource {
  constructor() {
    super('acc/calls');
  }

  get(id: any, query?: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'get',
      params: query,
    });
  }
}

export function getUserCallAnalysis(query?: any) {
  if (cancel) {
    cancel(); // cancel request
  }
  return http({
    url: `/acc/users/analysis`,
    method: 'get',
    params: query,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}
