import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import MembershipLevelResource from 'api/membership-level';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ThirdPartyPartnerSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const MembershipLevelDetails: React.FC = () => {
  const { id: membershipLevelId }: any = useParams();
  const { search } = useLocation();

  const membershipLevelAPI = new MembershipLevelResource();
  const thirdPartyPartnerAPI = new ThirdPartyPartnerResource();
  const toast = useToast();

  const { data: membershipLevelDetail, isLoading } = useQuery(
    ['membership-level-detail-page', membershipLevelId],
    () => {
      if (!membershipLevelId) return null; // { data: null };
      return membershipLevelAPI
        .get(membershipLevelId)
        .then((res) => res.data.data)
        .catch((err) => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  );

  const thirdPartyPartnerListData: any = useQuery(
    ['third-party-partner-list-all'],
    () => {
      return thirdPartyPartnerAPI
        .getAll()
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  );

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [active, setActive] = useState(membershipLevelDetail?.is_active);

  useEffect(() => {
    if (membershipLevelDetail?.partner_goodies) {
      const initialSelectedIds = membershipLevelDetail.partner_goodies.map(
        (item: any) => item.pivot.partner_goodies_id
      );
      setSelectedItems(initialSelectedIds);
    }
  }, [membershipLevelDetail?.partner_goodies]);

  useEffect(() => {
    setActive(membershipLevelDetail?.is_active);
  }, [membershipLevelDetail]);

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bms} | &nbsp;
            {strings.detail_member_level}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.thirdPartyPartner.membershipLevel.list + search}>
              {strings.membership}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.detail_member_level}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.membership_level} {membershipLevelDetail?.name}
          </Heading>
          <RouterLink
            to={routes.thirdPartyPartner.membershipLevel.edit.replace(
              ':id',
              membershipLevelId.toString()
            )}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.edit_member_level}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Grid gap="4" templateColumns={['repeat(1, 1fr)']} w="100%">
            <GridItem>
              <FormControl>
                <FormLabel>{strings.name_membership_level}</FormLabel>
                <Box>{membershipLevelDetail?.name}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.brief_description}</FormLabel>
                <Box>{membershipLevelDetail?.brief_description}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.description}</FormLabel>
                <Box>{membershipLevelDetail?.description}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.website_condition_discount}</FormLabel>
                <Input
                  min={0}
                  defaultValue={
                    membershipLevelDetail?.website_condition_discount
                  }
                  maxWidth="100"
                  disabled
                />
              </FormControl>
            </GridItem>
            <Heading size="xs" mt="5" fontWeight="600">
              {strings.special_conditions_on_the_website}
            </Heading>
            <GridItem>
              <FormControl>
                <HStack shouldWrapChildren direction="row">
                  <Input
                    min={0}
                    defaultValue={membershipLevelDetail?.booking_discount}
                    maxWidth="100"
                    disabled
                  />
                  <FormLabel>{strings.booking_discount}</FormLabel>
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <HStack shouldWrapChildren direction="row">
                  <Input
                    min={0}
                    defaultValue={
                      membershipLevelDetail?.final_cleaning_discount
                    }
                    maxWidth="100"
                    disabled
                  />
                  <FormLabel>{strings.final_cleaning_discount}</FormLabel>
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <HStack shouldWrapChildren direction="row">
                  <Input
                    min={0}
                    defaultValue={membershipLevelDetail?.laundry_discount}
                    maxWidth="100"
                    disabled
                  />
                  <FormLabel>{strings.laundry_discount}</FormLabel>
                </HStack>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl></FormControl>
              <FormLabel>{strings.active}</FormLabel>
              <Box>
                {' '}
                <Checkbox isChecked={active} disabled />
              </Box>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.icon}</FormLabel>
                <Box>
                  {' '}
                  <Image
                    src={membershipLevelDetail?.icon}
                    alt={membershipLevelDetail?.icon}
                    width="150"
                    height="150"
                  />
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>

        <Stack sx={wrapperStyles}>
          <Heading size="sm">
            {strings.extra_goodies} ({strings.third_party_partner}):
          </Heading>

          <Grid gap="4" templateColumns={['repeat(1, 1fr)', 'repeat(5, 1fr)']}>
            {thirdPartyPartnerListData?.data?.data?.map(
              (data: ThirdPartyPartnerSchema, index: number) => {
                return (
                  data.partner_goodies?.length > 0 && (
                    <VStack alignItems="left" key={index} marginTop="10px">
                      <Heading size="xs" fontWeight="600">
                        {data.name}
                      </Heading>
                      <Grid gap={4} templateColumns="1fr">
                        {data.partner_goodies?.map(
                          (item: any, index: number) => (
                            <Grid
                              key={index}
                              templateColumns="auto 1fr"
                              gap="3"
                              alignItems="center">
                              <Checkbox
                                isChecked={selectedItems?.includes(item.id)} // Compare with the pivot.partner_goodies_id
                                colorScheme="green"
                                disabled={true}
                              />
                              <span>{item.name}</span>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </VStack>
                  )
                );
              }
            )}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default MembershipLevelDetails;
