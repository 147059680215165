import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import {
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import { DMSDocumentFilterParams } from 'constants/schema';
import React, { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory } from 'react-router-dom';
import { changeURL } from 'utils';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

interface Props {
  filterParams: DMSDocumentFilterParams;
  setFilterParams: React.Dispatch<
    React.SetStateAction<DMSDocumentFilterParams>
  >;
}

const DMSDocumentSearch: React.FC<Props> = (props) => {
  const history = useHistory();
  const { filterParams, setFilterParams } = props;

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      created_at: null,
      available_from_date: null,
      available_to_date: null,
    }));
  };

  const handleUploadDateChange = (date: Date) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      created_at: date,
    }));
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      available_from_date: start,
      available_to_date: end,
    }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.created_at) data.created_at = Date.parse(data.created_at);
    if (data.available_from_date)
      data.available_from_date = Date.parse(data.available_from_date);
    if (data.available_to_date)
      data.available_to_date = Date.parse(data.available_to_date);

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  return (
    <Stack sx={wrapperStyles}>
      <form>
        <Stack direction="column" spacing="4">
          <Stack
            direction={['column', 'column', 'row']}
            spacing="4"
            align={['start', 'start', 'end']}>
            <Grid
              gap={['4', '4', '6']}
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
              ]}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.search}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<BiSearch />}
                      color="gray.400"
                    />
                    <Input
                      name="keyword"
                      type="text"
                      value={filterParams.keyword}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.upload_date}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                      name="created_at"
                      selected={filterParams.created_at}
                      value={DateFormatYMD(filterParams.created_at)}
                      customInput={
                        <CustomDateInput value={filterParams.created_at} />
                      }
                      onChange={handleUploadDateChange}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.availability}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleDateChange}
                      selected={filterParams.available_from_date}
                      startDate={filterParams.available_from_date}
                      endDate={filterParams.available_to_date}
                      selectsRange
                      value={getFormattedRangeDate(
                        filterParams.available_from_date,
                        filterParams.available_to_date
                      )}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
            </Grid>
            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={handleReset}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default DMSDocumentSearch;
