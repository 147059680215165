import 'react-perfect-scrollbar/dist/css/styles.css';

import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { getActiveUsersForChatTransfer, transferChat } from 'api/chat';

import { BiRightArrowCircle } from 'react-icons/bi';
import { EmployeeNotAvailable } from 'assets/images';
import { Stack } from '@chakra-ui/layout';
import { strings } from 'config/localization';
import { useQuery } from 'react-query';

interface Props {
  userIdFrom: number;
  roomName: string;
  shouldDisableTransferChat: boolean;
}

const TransferChatPopOver: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const { userIdFrom, roomName, shouldDisableTransferChat } = props;

  const fetchUserList = async () => {
    const response = await getActiveUsersForChatTransfer(userIdFrom);
    return response.data;
  };

  const userQuery = useQuery(`fetchUserList`, () => fetchUserList());

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      roomName: roomName,
      employeeFrom: userIdFrom,
      employeeTo: Number(e.target[0].value),
    };
    await transferChat(data);
    window.location.reload();
  };

  const users = userQuery?.data;

  const renderedTriggerButton = (
    <Button
      isDisabled={!!shouldDisableTransferChat}
      style={{
        background: 'white',
        width: 'var(--chakra-sizes-6)',
        height: 'var(--chakra-sizes-6)',
      }}>
      <Tooltip hasArrow label={strings.transfer_chat} fontSize="sm">
        <span>
          <Icon
            onClick={open}
            as={BiRightArrowCircle}
            w="7"
            h="7"
            mt={0.5}
            title={strings.transfer_chat}
            _hover={{ color: 'red.400' }}
          />
        </span>
      </Tooltip>
    </Button>
  );

  if (!!shouldDisableTransferChat) {
    return renderedTriggerButton;
  }

  return (
    <>
      <Popover
        id={`popover-${roomName}`}
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        closeOnBlur
        placement="right">
        <PopoverTrigger>{renderedTriggerButton}</PopoverTrigger>
        <Portal>
          <PopoverContent p={5}>
            <PopoverArrow />
            <PopoverCloseButton />
            {users?.length > 0 ? (
              <>
                <PopoverHeader>{strings.select_user}</PopoverHeader>
                <form onSubmit={handleSubmit}>
                  <Stack spacing={4}>
                    <Select
                      placeholder={strings.select_user}
                      name="employee_to"
                      required>
                      {userQuery?.data?.map((users: any) => {
                        if (users.user_id === userIdFrom && !users.status)
                          return null;
                        return (
                          <option key={users.user_id} value={users.user_id}>
                            {users.name}
                          </option>
                        );
                      })}
                    </Select>
                    <ButtonGroup d="flex" justifyContent="flex-start">
                      <Button colorScheme="green" type="submit">
                        {strings.transfer_chat}
                      </Button>
                      <Button variant="outline" onClick={close}>
                        {strings.cancel}
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </>
            ) : (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                px={8}>
                <Image src={EmployeeNotAvailable} w={14} mb={3} />
                <Text color="gray.500" fontSize="sm" textAlign="center">
                  {strings.chat_users_not_available_for_transferral}
                </Text>
              </Flex>
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default TransferChatPopOver;
