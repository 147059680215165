import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import SMSServiceResource from 'api/sms_services';
import SMSServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ServiceListItem from 'components/sms_service/ServiceListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  SERVICE_SCHEME_TYPE_OPTIONS,
} from 'constants/common';
import routes from 'constants/routes';
import { ServiceSchema } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  service_group_id: number | '';
  price_scheme_type: string;
  keyword: string;
}

const ServiceList: React.FC = () => {
  const queryClient = useQueryClient();
  const serviceAPI = new SMSServiceResource();
  const serviceGroupAPI = new SMSServiceGroupResource();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    service_group_id: searchValues.service_group_id ?? '',
    price_scheme_type: searchValues.price_scheme_type ?? '',
    keyword: searchValues.keyword ?? '',
  });

  /**
   * Fetch service groups
   */
  const serviceGroupList = useQuery('serviceGroupList', () =>
    serviceGroupAPI.list().then((res) => res.data.data)
  );

  const serviceList = useQuery(
    [
      'serviceList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        price_scheme_type: filterParams.price_scheme_type,
        service_group_id: filterParams.service_group_id,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.price_scheme_type)
        queryParams.price_scheme_type = filterParams.price_scheme_type;
      if (filterParams.service_group_id)
        queryParams.service_group_id = filterParams.service_group_id;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await serviceAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      price_scheme_type: '',
      service_group_id: '',
      keyword: '',
    }));
    history.push(routes.sms.services.list);
  };

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination?.index === source?.index) return;
    const sourceId = serviceList?.data?.data?.[source?.index].serial_number;
    const destinationId =
      serviceList?.data?.data?.[destination?.index].serial_number;

    await serviceAPI.updateSerialNumber(sourceId, destinationId);
    await queryClient.invalidateQueries('serviceList');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.sms} | {strings.services}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.sms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.sms.serviceGroups.list}>
              {strings.all_services}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.services}
          </Heading>
          <RouterLink to={routes.sms.services.add}>
            <Button colorScheme="primary" size="sm">
              {strings.add_service}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack direction="column" spacing="4">
              <Stack
                direction={['column', 'column', 'row']}
                spacing="4"
                align={['start', 'start', 'end']}>
                <Grid
                  gap={['4', '4', '6']}
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(3, 1fr)',
                  ]}
                  w="100%">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <Input
                          type="text"
                          name="keyword"
                          value={filterParams.keyword}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.service_group}</FormLabel>
                      <Select
                        name="service_group_id"
                        value={filterParams.service_group_id}
                        placeholder={strings.service_group}
                        rounded="sm"
                        onChange={handleInputChange}>
                        {serviceGroupList?.data?.map((serviceGroup: any) => {
                          return (
                            <option
                              key={serviceGroup.id}
                              value={serviceGroup.id}>
                              {serviceGroup.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.scheme_type}</FormLabel>
                      <Select
                        name="price_scheme_type"
                        value={filterParams.price_scheme_type}
                        placeholder={strings.scheme_type}
                        rounded="sm"
                        onChange={handleInputChange}>
                        {SERVICE_SCHEME_TYPE_OPTIONS.map((schemeType) => {
                          return (
                            <option
                              key={schemeType.value}
                              value={schemeType.value}>
                              {strings[schemeType.text]}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Stack direction="row" spacing="4">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleReset}>
                    {strings.reset_filter}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.service_name}</Th>
                  <Th>{strings.service_group}</Th>
                  <Th>{strings.scheme_type}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="ServiceListTable">
                  {(provided: any) => (
                    <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {!serviceList.isLoading &&
                        serviceList?.data?.data?.map(
                          (serviceData: ServiceSchema, index: number) => (
                            <Draggable
                              draggableId={serviceData.id.toString()}
                              key={serviceData.id}
                              index={index}>
                              {(provided: any) => (
                                <>
                                  <ServiceListItem
                                    serviceData={serviceData}
                                    key={serviceData.id}
                                    index={startingSN + index}
                                    draggableProvider={provided}
                                    search={search}
                                  />
                                  {provided.placeholder}
                                </>
                              )}
                            </Draggable>
                          )
                        )}

                      {serviceList.isLoading && (
                        <TableSkeletonLoader rows={filterParams.pageSize} />
                      )}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={serviceList}
        />
      </Stack>
    </>
  );
};

export default ServiceList;
