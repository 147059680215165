import { Center, Spinner } from '@chakra-ui/react';
import DropdownSvg from 'components/common/DropdownSvg';
import { strings } from 'config/localization';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useWordSearch from 'hooks/useWordSearch';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  id?: string;
  placeholder?: string;
  onChange: (option: Option) => void;
  value?: any;
  SEARCH_API: string;
  limit: number;
  parentQueries?: any;
  disable?: boolean;
}

interface Option {
  id: number;
  name: string;
  disabled?: boolean;
}

interface query {
  page: number;
  limit: number;
}

export default function ArticleCustomSelect({
  id,
  placeholder,
  onChange,
  value,
  limit,
  SEARCH_API,
  parentQueries,
  disable = false,
}: Props) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const openModalRef = useRef<any>(null);
  const { visible, observer } = useIntersectionObserver();
  const focusRef = useRef<any>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [options, setOptions] = useState<any>([]);
  const [queryParams, setQueryParams] = useState<query>({
    page: 1,
    limit: limit,
    ...parentQueries,
  });

  const {
    loading: loading,
    result: query,
    setQuery,
  } = useWordSearch(`${SEARCH_API}`);

  const handleInputChange = useCallback((value: string) => {
    setQueryParams((prevState: query) => ({
      ...prevState,
      name: value ? value : null, // queryparams for search should be "keyword"
      page: 1,
    }));
    setOptions([]);
  }, []);

  useEffect(() => {
    if (!query.data) return;
    const { data: listData, meta } = query?.data;
    const currentPage = meta?.current_page;
    const lastPage = meta?.last_page;

    setHasMore(currentPage < lastPage);

    const formattedOptions = listData?.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        unit: item.articleUnitType.name,
      };
    });

    setOptions((prevState: any) => [...prevState, ...formattedOptions]);
  }, [query?.data, query?.meta]);

  useEffect(() => {
    setQuery(queryParams);
  }, [queryParams, setQuery]);

  useEffect(() => {
    if (openModal && visible && hasMore && !loading) {
      setQueryParams((prevState: query) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [openModal, loading, hasMore, visible]);

  useEffect(() => {
    function handleClick(e: any) {
      if (!e.target.classList.contains('disabled-ul')) {
        if (
          openModalRef.current !== null &&
          openModalRef.current.contains(e.target)
        ) {
          setOpenModal(!openModal);
          focusRef.current && focusRef.current.focus();
        } else {
          setOpenModal(false);
        }
      }
    }
    // When disable flag is on, clicks are disabled
    !disable && document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [openModal, disable]);

  useEffect(() => {
    const inputSearchHandler = (value: string) => {
      handleInputChange(value);
      if (searchInput !== '') setOpenModal(true);
    };
    inputSearchHandler(searchInput);
  }, [searchInput, handleInputChange]);

  const selectHandler = (option: Option) => {
    if (option.disabled) return;
    setSearchInput('');
    setOpenModal(false);
    onChange(option);
  };

  return (
    <div
      className={`container-and-modal${disable ? ' cursor-not-allowed' : ''}`}>
      <div className="custom-select-container">
        <span
          aria-live="polite"
          aria-atomic="false"
          aria-relevant="additions text"
          className="outer-line-span"></span>
        <div className="all-content-container" ref={openModalRef}>
          <div className="text-placeholder-and-input-field-container">
            <div className="input-field-inner-container">
              <input
                id={id}
                value={searchInput}
                placeholder={value ? value.name : placeholder}
                className={`input-field${disable ? ' cursor-not-allowed' : ''}`}
                ref={focusRef}
                disabled={disable}
                style={{ opacity: disable ? 0.6 : 1 }}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          </div>
          <div className="seperator-arrow-outer-container">
            <span className="line-seperator"></span>
            <div className="arrow-inner-container" aria-hidden="true">
              <div style={{ opacity: disable ? 0.4 : 1 }}>
                <DropdownSvg />
              </div>
            </div>
          </div>
        </div>

        {openModal && (
          <div className="modal-box">
            {options?.map((option: Option) => {
              return (
                <div key={option.id}>
                  <ul
                    key={option.id}
                    className={`list-item ${
                      option.disabled ? 'list-item-disabled disabled-ul' : ''
                    }`}
                    onClick={() => selectHandler(option)}>
                    {option.name}
                  </ul>
                </div>
              );
            })}
            <div ref={observer} style={{ height: '1px' }}></div>
            {loading && (
              <Center h="20px" color="grey">
                <Spinner size="sm" />
              </Center>
            )}
            {!loading && (
              <Center fontSize={12} color="grey">
                {options.length === 0
                  ? strings.no_options
                  : strings.showing_all_options}
              </Center>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
