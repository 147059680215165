import { Box, Image, Text } from '@chakra-ui/react';

import { ChatNotAvailableImg } from 'assets/images';
import React from 'react';

interface ChatNotAvailableProps {
  message: string;
  imageWidth?: string;
}

const ChatNotAvailable: React.FC<ChatNotAvailableProps> = ({
  message,
  imageWidth = '50%',
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgColor="white"
      boxShadow="sm"
      rounded="sm"
      py={20}>
      <Image
        src={ChatNotAvailableImg}
        alt="chat-not-avilable"
        htmlWidth={imageWidth}
        opacity={0.6}
        mb={6}
      />
      <Text fontSize="lg" color="gray.400">
        {message}
      </Text>
    </Box>
  );
};

export default ChatNotAvailable;
