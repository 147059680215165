import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button } from '@chakra-ui/button';
import { Heading, Stack } from '@chakra-ui/layout';
import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/table';
import RMSCrossSellingResource from 'api/rms-cross-selling';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CrossSellingListItem from 'components/object-dashboard/cross-selling/CrossSellingListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  CrossSellingDetailInterface,
  DataWrapperSchema,
} from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

const CrossSellingList = () => {
  const crossSellingAPi = new RMSCrossSellingResource();

  const checkAddCrossSellingPermission = useCheckPermission(
    [PermissionRequest['manage:cross-selling']],
    routes.objectDashboard.crossSelling.add
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const crossSellingList = useQuery<
    DataWrapperSchema<CrossSellingDetailInterface[]>
  >({
    queryKey: ['cross-selling-list', filterParams],
    queryFn: async () => {
      const queryParams = {
        limit: filterParams.pageSize,
        page: filterParams.currentPage,
      };
      const response = await crossSellingAPi.list(queryParams);
      return response?.data;
    },
  });

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.dashboard} | {strings.all_cross_selling}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.objectDashboard.crossSelling.list}>
            {strings.cross_selling}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.all_cross_selling}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack direction="row" justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.cross_selling}
        </Heading>
        <Button
          size="sm"
          colorScheme="primary"
          type="button"
          onClick={checkAddCrossSellingPermission}>
          {strings.add_cross_selling}
        </Button>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.service}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!crossSellingList?.isLoading &&
                crossSellingList?.data?.data?.map((item, index: number) => (
                  <CrossSellingListItem
                    key={item.id}
                    index={++index}
                    crossSelling={item}
                  />
                ))}
              {crossSellingList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={3} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={crossSellingList}
      />
    </Stack>
  );
};

export default CrossSellingList;
