import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { CenterSpinner } from 'components/common/CenterSpinner';
import Billability from 'components/ticket/Billability';
import RecursiveInfo from 'components/ticket/RecursiveInfo';
import TicketAuthor from 'components/ticket/TicketAuthor';
import TicketComment from 'components/ticket/TicketComment';
import TicketInfo from 'components/ticket/TicketInfo';
import TicketLogInfo from 'components/ticket/TicketLogInfo';
import TransferItemForm from 'components/transfer/TransferItemForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { TicketInfoSchema } from 'constants/schemas/TicketSchema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import HideControl from 'services/HideControl';
import { truncateString } from 'utils';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';

const ViewTicket: React.FC = () => {
  const { search } = useLocation();

  function matchURL() {
    return matchPath(location.pathname, {
      path: routes.ticket.task.view,
      exact: true,
      strict: false,
    });
  }
  const isTicketBoardView = matchURL();
  const { id }: any = useParams();
  const ticketApi = new TicketResource();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const ticketQuery = useQuery<TicketInfoSchema>([`ticket${id}`], () =>
    ticketApi.get(id).then((res) => res.data.data)
  );

  if (ticketQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ticketQuery.isError) {
    history.push(routes.ticket.list.default);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.ticket} | #{id} -{truncateString(ticketQuery?.data?.title)}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={
                (isTicketBoardView
                  ? routes.ticket.task.board
                  : routes.ticket.list.default) + search
              }>
              {isTicketBoardView ? strings.ticket : strings.ticket_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              #{id} - {truncateString(ticketQuery?.data?.title)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {ticketQuery?.data && (
          <TicketInfo
            ticket={ticketQuery?.data}
            path={
              isTicketBoardView
                ? routes.ticket.task.edit
                : routes.ticket.list.edit
            }
          />
        )}

        {ticketQuery?.data?.recursive_interval && (
          <RecursiveInfo
            recursiveInterval={ticketQuery?.data?.recursive_interval}
            recursiveFrom={ticketQuery?.data?.recursive_from}
            recursiveUntil={ticketQuery?.data?.recursive_until}
          />
        )}

        {ticketQuery?.data?.is_billable && (
          <Billability ticket={ticketQuery?.data} updatedBy={loggedInUser.id} />
        )}
        <TicketComment ticketId={ticketQuery?.data?.id} />
        <HideControl
          hideFor="mms-service"
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <Accordion bg="white" borderColor="white" allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Flex justify="space-between">
                      <Heading size="md" textTransform="capitalize">
                        {strings.inventory}
                      </Heading>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel padding="0">
                <TransferItemForm
                  formName="ticket"
                  formID={ticketQuery?.data?.id}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </HideControl>

        <TicketLogInfo ticketId={ticketQuery?.data?.id} />

        <TicketAuthor
          createdBy={ticketQuery?.data?.created_by_user}
          createdAt={DateFormat(ticketQuery?.data?.createdAt)}
          lastChangedBy={ticketQuery?.data?.updated_by_user}
          lastChangedAt={DateFormat(ticketQuery?.data?.updatedAt)}
          source={ticketQuery?.data?.source}
        />
      </Stack>
    </>
  );
};

export default ViewTicket;
