import { ChakraProvider } from '@chakra-ui/react';
import 'assets/css/App.css';
import 'assets/css/theme.css';
import 'assets/fonts';
import 'assets/scss/App.scss';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import { strings } from 'config/localization';
import theme from 'config/theme';
import ThemeProvider from 'context/ThemeContext';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from 'router/Router';

const queryClient = new QueryClient();

const App = () => {
  let interfaceLang = strings.getInterfaceLanguage();
  if (
    interfaceLang !== 'en' &&
    interfaceLang !== 'pl' &&
    interfaceLang !== 'de'
  ) {
    interfaceLang = 'en';
  }
  let storedLang = localStorage.getItem('language') ?? interfaceLang;
  strings.setLanguage(storedLang);

  return (
    <ThemeProvider>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </ThemeProvider>
  );
};

export default App;
