import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import WarehouseResource from 'api/warehouses';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import WarehouseListItem from 'components/warehouse/WarehouseListItem';
import WarehouseSearch, {
  warehouseInitialSearchValues,
} from 'components/warehouse/WarehouseSearch';
import { strings } from 'config/localization';
import { INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { WarehouseSchema, WarehouseSearchSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

const Warehouse: React.FC = () => {
  // API Resource
  const warehouseAPI = new WarehouseResource();

  const { search } = useLocation();
  const history = useHistory();

  const [filterParams, setFilterParams] = useState<WarehouseSearchSchema>(
    warehouseInitialSearchValues
  );

  // Access Control
  const checkAddWarehousePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.warehouse.add
  );

  const warehouseList = useQuery(
    [
      'warehouseList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await warehouseAPI.list(queryParams);
      return response?.data?.data;
    }
  );

  const handleChangeFilters = useCallback(
    (data: any) => {
      setFilterParams((prevState) => ({
        ...prevState,
        currentPage: INITIAL_CURRENT_PAGE,
        keyword: data.keyword,
      }));
      const searchURL = changeURL(data);
      history.push(`?${searchURL}`);
    },
    [history]
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.all_warehouses}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.mms.warehouse.list}>
              {strings.all_warehouses}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.warehouses}
          </Heading>
          <Stack direction="row">
            <Button
              type="button"
              colorScheme="primary"
              size="sm"
              onClick={checkAddWarehousePermission}>
              {strings.add_warehouse}
            </Button>
          </Stack>
        </Flex>

        <WarehouseSearch handleAdvancedSearch={handleChangeFilters} />

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.warehouse}</Th>
                  <Th isNumeric>{strings.rooms}</Th>
                  <Th isNumeric>{strings.racks}</Th>
                  <Th isNumeric>{strings.shelves}</Th>
                  <Th>{strings.description}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {warehouseList?.data?.data?.map(
                  (warehouse: WarehouseSchema, index: number) => {
                    const totalRooms = warehouse.rooms.length;
                    const totalRacks = warehouse.rooms.reduce(
                      (total, currentRoom) => {
                        return total + currentRoom.racks.length;
                      },
                      0
                    );
                    const totalShelves = warehouse.rooms.reduce(
                      (grandTotal, currentRoom) => {
                        const totalRacksInARoom = currentRoom.racks.reduce(
                          (total, currentRack) => {
                            return total + currentRack.shelves.length;
                          },
                          0
                        );
                        return grandTotal + totalRacksInARoom;
                      },
                      0
                    );
                    return (
                      <WarehouseListItem
                        warehouseData={warehouse}
                        index={startingSN + index}
                        key={warehouse.id}
                        totalRooms={totalRooms}
                        totalRacks={totalRacks}
                        totalShelves={totalShelves}
                        search={search}
                      />
                    );
                  }
                )}
                {warehouseList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={warehouseList}
        />
      </Stack>
    </>
  );
};

export default Warehouse;
