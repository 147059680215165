import {
  FormControl,
  Grid,
  GridItem,
  Select,
  useToast,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { strings } from 'config/localization';
import { TicketInfoSchema } from 'constants/schema';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';

interface Props {
  ticketId: any;
  status: any;
}
const STATUSES = ['open', 'in-progress', 'done', 'closed'];
const StatusForm: React.FC<Props> = (props) => {
  const { ticketId, status } = props;
  const toast = useToast();
  const ticketAPI = new TicketResource();
  const methods = useForm<TicketInfoSchema>();
  const { loggedInUser, userPermissions } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const updateTicket = useMutation((data: any) =>
    ticketAPI.updateTicketStatus(ticketId, data.status, data.updated_by)
  );

  const handleStatus = async (status: string) => {
    let data = {
      status,
      updated_by: loggedInUser.id,
    };
    updateTicket.mutate(data, {
      onSuccess: () => {
        toast({
          title: strings.ticket_updated,
          status: 'success',
          isClosable: true,
        });
      },
    });
  };
  const TrailingDash = {
    fontWeight: 'normal',
    position: 'relative',
    paddingRight: '4',
    paddingLeft: '4',
    textTransform: 'capitalize',
    _after: {
      content: `""`,
      position: 'absolute',
      top: '1',
      right: '0',
      width: '1px',
      height: '25px',
      bg: 'gray.300',
    },
  };

  return (
    <>
      <form>
        <Grid
          gap="4"
          templateColumns="repeat(1, 1fr)"
          w="100%"
          sx={TrailingDash}>
          <GridItem>
            <FormControl w="100%">
              <Select
                size="sm"
                rounded="sm"
                id="status"
                defaultValue={status}
                bg="white"
                {...methods.register('status')}
                onChange={(e) => handleStatus(e.target.value)}>
                {STATUSES.map((status: any) => {
                  return (
                    <option key={status} value={status}>
                      {strings
                        .getString(status.split('-').join(''))
                        .toUpperCase()}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
      </form>
    </>
  );
};
export default StatusForm;
