import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Uploader from 'components/common/Uploader';
import { strings } from 'config/localization';
import { DMS_ACCEPT_FILE_TYPE, DMS_OWNER_SEARCH_API } from 'constants/common';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import ReactSelect from 'react-select';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

interface Props {
  ownerDocumentData?: any;
}

const OwnerDocumentForm: React.FC<Props> = (props) => {
  const { ownerDocumentData } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useFormContext<any>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!ownerDocumentData) return;
    setValue(
      'available_from_date',
      ownerDocumentData && ownerDocumentData?.available_from_date
    );
    setValue(
      'available_to_date',
      ownerDocumentData && ownerDocumentData?.available_to_date
    );
    if (ownerDocumentData?.available_from_date) {
      let startD = new Date(ownerDocumentData?.available_from_date);
      setStartDate(startD);
    }
    if (ownerDocumentData?.available_to_date) {
      let endD = new Date(ownerDocumentData?.available_to_date);
      setEndDate(endD);
    }
  }, [ownerDocumentData, setValue]);

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setValue('available_from_date', DateFormatYMD(start));
    setValue('available_to_date', DateFormatYMD(end));
  };

  const {
    loading: ownerListLoading,
    result: ownerQuery,
    setQuery,
  } = useWordSearch(`${DMS_OWNER_SEARCH_API}/?limit=50`);

  const ownerList = ownerQuery?.data;

  const ownerOptions = ownerList?.map((owner: any) => ({
    label: owner.full_name,
    value: owner.id,
  }));

  useEffect(() => {
    if (!ownerDocumentData) return;

    let { id, full_name } = ownerDocumentData.owner;
    let defaultValue = { value: id, label: full_name };

    setQuery({ keyword: full_name });
    setValue('owner_id', defaultValue);
  }, [ownerDocumentData, setQuery, setValue]);

  const handleOwnerChange = (value: string) => {
    if (!value) return;
    setQuery({ keyword: value });
  };

  return (
    <form>
      <Stack direction="column" spacing="4">
        {!ownerDocumentData && (
          <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
            <GridItem>
              <FormControl isInvalid={!!errors?.upload_files} isRequired={true}>
                <FormLabel>{strings.upload_files}</FormLabel>
                <Uploader
                  fileKey="upload_files"
                  uploadedFiles={ownerDocumentData?.upload_files}
                  acceptFileType={DMS_ACCEPT_FILE_TYPE}
                  maxFiles={1}
                  clearErrors={clearErrors}
                  multiple={false}
                />
                <FormErrorMessage>
                  {errors?.upload_files && errors?.upload_files?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        )}
        <Grid gap="4" templateColumns="repeat(2, 1fr)" flex="1" w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.document_name}</FormLabel>
              <Input
                type="text"
                defaultValue={ownerDocumentData?.name}
                placeholder={strings.document_name}
                {...register('name', {
                  required: strings.required_document_name,
                })}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.availability}</FormLabel>
              <Controller
                control={control}
                name="availability"
                render={({ field: { onChange, value } }) => (
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={(date: any) => handleDateChange(date)}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      selectsRange
                      value={getFormattedRangeDate(startDate, endDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                  </InputGroup>
                )}
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Grid gap="4" templateColumns="repeat(2, 1fr)" w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.owner_id} isRequired>
              <FormLabel>{strings.house_owner}</FormLabel>
              <Controller
                control={control}
                name="owner_id"
                rules={{
                  required: strings.required_owner,
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="owner_id"
                    placeholder={strings.select_house_owner}
                    options={ownerOptions}
                    value={field.value ? field.value : null}
                    styles={reactSelectStyles}
                    onInputChange={handleOwnerChange}
                    isLoading={ownerListLoading}
                    allowClear
                    isSearchable
                    required
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.owner_id && errors?.owner_id?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default OwnerDocumentForm;
