import {
  Box,
  Center,
  Icon,
  Image,
  Link,
  Popover,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { EXTERNAL_LINK_MENU } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import { ServiceListSchema, serviceRoute } from 'constants/schema';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { checkPermissions } from 'utils/listInfo';
import { FilterSubMenuLink } from './FilterSubMenuLink';

// styles
const navBoxStyles = {
  h: '45px',
  cursor: 'pointer',
};

const navLinkActiveStyles = {
  borderLeft: '4px',
  borderColor: 'rgba(255,255,255,0.6)',
  d: 'block',
  bg: 'primary.400',
};

export interface MenuLinkProps {
  menu: ServiceListSchema;
  shouldDisableToolTipOnMenus: boolean;
  shouldShowHoverSubMenus: boolean;
  handleClickCallback: (route: string, theme: string) => void;
}

const MenuLink: React.FC<MenuLinkProps> = (props) => {
  const {
    menu,
    shouldDisableToolTipOnMenus,
    shouldShowHoverSubMenus,
    handleClickCallback,
  } = props;

  const {
    theme,
    themeColor,
    rootLink,
    routes,
    tooltipLabel,
    icon,
    subMenusComp,
    serviceName,
  } = menu;

  const { userPermissions, unreadCount: unreadMessages } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
      unreadCount: state?.data?.chat?.unreadCount,
    }),
    shallowEqual
  );

  const getIcon = () => {
    if (typeof icon === 'string') {
      return <Image src={icon} w="5" h="5" color="white" />;
    }

    if (serviceName === 'chat') {
      return (
        <Box>
          <Icon as={icon} w="5" h="5" />
          {unreadMessages > 0 && (
            <Icon viewBox="0 0 200 200" color="red.500">
              <path
                fill="currentColor"
                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
              />
            </Icon>
          )}
        </Box>
      );
    }
    return <Icon as={icon} w="5" h="5" />;
  };

  const handleMenuIconClick = () => {
    let authorizedRoute = routes.find((currentRoute: serviceRoute) =>
      checkPermissions(userPermissions, [PermissionRequest[currentRoute[1]]])
    );

    if (authorizedRoute !== undefined) {
      if (EXTERNAL_LINK_MENU.includes(serviceName)) {
        return window.open(authorizedRoute[0], '_blank');
      }
      return handleClickCallback(authorizedRoute[0], theme);
    }
  };

  const bg = `${themeColor}.400`;
  const hoverBg = `${themeColor}.500`;

  return (
    <Popover placement="right" trigger="hover">
      <PopoverTrigger>
        <Link
          sx={
            location.pathname?.startsWith(rootLink)
              ? navLinkActiveStyles
              : undefined
          }
          transition="all 0.35s"
          display="block"
          bg={bg}
          _hover={{ bg: hoverBg }}
          onClick={handleMenuIconClick}>
          <Tooltip
            label={strings[tooltipLabel]}
            placement="left-end"
            hasArrow
            isDisabled={shouldDisableToolTipOnMenus}>
            <Center sx={navBoxStyles}>{getIcon()}</Center>
          </Tooltip>
        </Link>
      </PopoverTrigger>

      {/* Popover body element (sub-links) */}
      <FilterSubMenuLink
        subMenuType={subMenusComp}
        shouldShowHoverSubMenus={shouldShowHoverSubMenus}
        themeColor={themeColor}
      />
    </Popover>
  );
};

export default MenuLink;
