import { FormControl } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import {
  Button,
  Icon,
  InputGroup,
  InputRightElement,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiAddToQueue, BiCalendar, BiTrashAlt } from 'react-icons/bi';
import { formatPrice } from 'utils';
import {
  enumerateDaysBetweenDates,
  getFormattedRangeDate,
} from 'utils/DateFormat';

interface Props {
  service: any;
  selectedBooking: any;
  setExtraServices: any;
  includeDepartureDay: boolean;
  booking?: any;
  setError?: any;
}

const BreadBookingOrderFormAdd: React.FC<Props> = (props) => {
  let {
    service,
    selectedBooking,
    includeDepartureDay,
    setExtraServices,
    setError,
    booking,
  } = props;

  const unitPrice = service?.prices[0]?.unit_price;
  const minDate = moment(selectedBooking?.bookings?.fromdate)
    .add(1, 'days')
    .toDate();
  const maxDate = includeDepartureDay
    ? moment(selectedBooking?.bookings?.tilldate).toDate()
    : moment(selectedBooking?.bookings?.tilldate).subtract(1, 'days').toDate();
  let initialDays = enumerateDaysBetweenDates(minDate, maxDate);
  const initialDates = initialDays.map((day: string) => new Date(day));

  const [bookedDays, setBookedDays] = useState<any>(initialDates);
  const [additionalService, setAdditionalService] = useState<any>([]);
  useEffect(() => {
    const minDate = moment(selectedBooking?.bookings?.fromdate)
      .add(1, 'days')
      .toDate();
    const maxDate = includeDepartureDay
      ? moment(selectedBooking?.bookings?.tilldate).toDate()
      : moment(selectedBooking?.bookings?.tilldate)
          .subtract(1, 'days')
          .toDate();
    let resetService = [
      {
        id: `0${Date.now()}`,
        serviceId: service.id,
        name: service.name,
        startDate: minDate,
        endDate: maxDate,
        quantity: 0,
        days: enumerateDaysBetweenDates(minDate, maxDate),
        price: 0,
      },
    ];
    setAdditionalService(resetService);
  }, [includeDepartureDay, selectedBooking, service.id, service.name]);

  useEffect(() => {
    return () => {
      setBookedDays([]);
    };
  }, [service.id]);
  useEffect(() => {
    setExtraServices(additionalService, service.id);
  }, [additionalService, service.id, setExtraServices]);
  useEffect(() => {
    let error = '';
    function checkForInvalidDates(currentlyBookedDays: Date[]) {
      let isPast = false;
      let initDate = new Date();
      initDate.setDate(initDate.getDate() + 1);
      let tomorrow = initDate;
      for (let i = 0; i < currentlyBookedDays.length; i++) {
        let currDate: any = new Date(currentlyBookedDays[i]);
        currDate = currDate.setHours(12); // set time to 12:00
        // can only edit orders for tomorrow or later
        // must edit before 12 PM today for tomorrow's order.
        if (currDate <= tomorrow) {
          isPast = true;
          break;
        }
      }
      return isPast;
    }
    if (additionalService.length > 0) {
      let serviceOrderDays = additionalService
        .map((ele: any) => {
          return ele.days;
        })
        .flat();
      let DuplicateDates = serviceOrderDays.filter(
        (item: Date, index: number) => {
          return serviceOrderDays.indexOf(item) !== index;
        }
      );
      let hasInvalidDate = checkForInvalidDates(serviceOrderDays);
      if (hasInvalidDate) {
        error = strings.cannot_change_quantity_in_past;
      } else if (DuplicateDates.length > 0) {
        error = strings.multiple_bookings_in_same_date_not_allowed;
      }
    }

    if (setError) setError(error);
  }, [additionalService, setError]);

  const handleDateChange = (dates: [Date, Date], formID: any) => {
    const [start, end] = dates;

    let dayList = enumerateDaysBetweenDates(start, end);

    if (formID) {
      let LsingleService = additionalService.find(
        (item: any) => item.id === formID
      );
      let Linfo = [...additionalService];
      let Lindex = additionalService.findIndex(
        (item: any) => item.id === formID
      );
      Linfo[Lindex] = {
        ...LsingleService,
        startDate: start,
        endDate: end,
        days: dayList,
      };
      setAdditionalService(Linfo);

      let DAT = Linfo.map((day: any) => day.days).flat();
      let uniqueArray = DAT.filter(function (item: any, pos: number) {
        return DAT.indexOf(item) === pos;
      });
      let uniqueArray2 = uniqueArray.map((item: any) => new Date(item));
      setBookedDays(uniqueArray2);
    }
  };

  const onQuantityChange = (e: any, formId: any) => {
    let qty = Number(e.target.value);
    if (qty < 0) {
      return;
    }
    if (formId) {
      let LsingleService = additionalService.find(
        (item: any) => item.id === formId
      );
      let Linfo = [...additionalService];
      let Lindex = Linfo.findIndex((item: any) => item.id === formId);
      let Ldays = LsingleService?.days?.length;

      Linfo[Lindex] = {
        ...LsingleService,
        quantity: qty,
        price: qty * unitPrice * Ldays,
      };
      setAdditionalService(Linfo);
      return;
    }
  };
  const onAdditionalServicePlus = () => {
    let index = additionalService.length;
    let nwService = {
      id: `${index}${Date.now()}`,
      serviceId: service.id,
      name: service.name,
      booking_fee: 2,
      startDate: null,
      endDate: null,
      quantity: 0,
      days: [],
      price: 0,
    };
    setAdditionalService([...additionalService, nwService]);
  };
  const onAdditionalServiceMinus = (id: string) => {
    let index = additionalService.findIndex((item: any) => item.id === id);
    let newService = [...additionalService];
    newService.splice(index, 1);
    let alldays = newService
      .map((ele: any) => {
        return ele.days;
      })
      .flat();
    let uniqueDays = alldays.filter((item, index) => {
      return alldays.indexOf(item) === index;
    });
    let uniqueDays2 = uniqueDays.map((item: any) => new Date(item));

    setBookedDays(uniqueDays2);
    setAdditionalService(newService);
  };

  return (
    <>
      {additionalService.length > 0 &&
        additionalService.map((item: any, index: number) => (
          <Tr key={item.id}>
            <Td>{item.name}</Td>
            <Td>
              <FormControl>
                <InputGroup>
                  <InputRightElement
                    children={<BiCalendar />}
                    color="gray.400"
                  />
                  <ReactDatePicker
                    placeholderText={strings.select_date}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomDateInput />}
                    onChange={(data: any) => handleDateChange(data, item.id)}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    highlightDates={bookedDays}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectsRange
                    selected={null}
                    value={getFormattedRangeDate(item.startDate, item.endDate)}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
            </Td>
            <Td isNumeric>
              <FormControl>
                <Input
                  type="number"
                  min={0}
                  placeholder={strings.quantity}
                  onChange={(e) => onQuantityChange(e, item.id)}
                  value={item.quantity}
                />
              </FormControl>
            </Td>
            <Td isNumeric>
              <Text>{formatPrice(unitPrice)} &euro;</Text>
            </Td>
            <Td isNumeric>
              <Text>
                {formatPrice(item.quantity * unitPrice * item.days.length)}
                &nbsp;&euro;
              </Text>
            </Td>
            <Td>
              {index === 0 ? (
                <Button
                  type="button"
                  variant="link"
                  p={3}
                  color="gray.800"
                  justifyContent="flex-start"
                  onClick={() => onAdditionalServicePlus()}>
                  <Icon as={BiAddToQueue} w="4" h="4" mr={2} />
                  <Text>{strings.add_new_service}</Text>
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="link"
                  p={3}
                  color="gray.800"
                  justifyContent="flex-start"
                  onClick={() => onAdditionalServiceMinus(item.id)}>
                  <Icon as={BiTrashAlt} w="4" h="4" mr={2} />
                  <Text>{strings.delete}</Text>
                </Button>
              )}
            </Td>
          </Tr>
        ))}
    </>
  );
};

export default BreadBookingOrderFormAdd;
