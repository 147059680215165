import { Td, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  objectGroupDocumentData: any;
  index: number;
  search?: string;
}
const ObjectGroupDocumentListItem: React.FC<Props> = (props) => {
  const { objectGroupDocumentData, index, search } = props;

  return (
    <Tr key={objectGroupDocumentData.apartment_id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.dms.objectDocuments.objects.list.replace(
              ':id',
              objectGroupDocumentData?.apartment_id.toString()
            ) + search
          }>
          {objectGroupDocumentData?.apartment?.name}
        </RouterLink>
      </Td>
      <Td isNumeric>{objectGroupDocumentData?.total || 0}</Td>
    </Tr>
  );
};

export default ObjectGroupDocumentListItem;
