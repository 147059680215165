import http from 'utils/http';
import Resource from './resource';
class BreadDailyViewResource extends Resource {
  constructor() {
    super('rms/bakery-reservations');
  }

  export(query: any) {
    return http({
      url: '/' + this.uri + '/export',
      params: query,
      method: 'get',
      responseType: 'blob',
    });
  }
}

export { BreadDailyViewResource as default };
