import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import DatePickerWithIncrementDecrement from 'components/common/DatePickerWithIncrementDecrement';
import { strings } from 'config/localization';
import { CheckInOutFilterParams } from 'constants/schema';
import React from 'react';
import { BiFilter, BiSearch } from 'react-icons/bi';
import { DateFormatYMD } from 'utils/DateFormat';

type Props = {
  defaultAccordionIndex: 0 | -1;
  filterParams: CheckInOutFilterParams;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange: (date: Date) => void;
  handleReset: () => void;
  dateLabel: string;
};

const CheckInOutSearch: React.FC<Props> = (props) => {
  const {
    defaultAccordionIndex,
    filterParams,
    handleDateChange,
    handleInputChange,
    handleReset,
    dateLabel,
  } = props;

  return (
    <Accordion
      bg="white"
      borderColor="white"
      allowToggle
      defaultIndex={[defaultAccordionIndex]}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Flex justify="space-between">
                <Heading fontSize="16px" fontWeight="medium">
                  <Icon as={BiFilter} />
                  {strings.filter}
                </Heading>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="0">
          <Stack sx={wrapperStyles}>
            <form autoComplete="off">
              <Stack direction="column" spacing="4">
                <Stack
                  direction={['column', 'column', 'row']}
                  spacing="4"
                  align={['start', 'start', 'end']}>
                  <Grid
                    gap={['4', '4', '6']}
                    templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                    w="100%">
                    <GridItem>
                      <FormControl>
                        <FormLabel>{strings.search}</FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BiSearch />}
                            color="gray.400"
                          />
                          <Input
                            name="keyword"
                            placeholder={`${strings.booking_number} / ${strings.customer_name}`}
                            type="text"
                            value={filterParams.keyword}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>{dateLabel}</FormLabel>
                        <DatePickerWithIncrementDecrement
                          placeholder={strings.select_date}
                          selected={filterParams.date}
                          value={DateFormatYMD(filterParams.date)}
                          onChange={handleDateChange}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Stack direction="row" spacing="4">
                    <Button
                      colorScheme="primary"
                      variant="outline"
                      onClick={handleReset}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CheckInOutSearch;
