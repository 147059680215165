import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;

class CustomerDocumentResource extends Resource {
  constructor() {
    super('dms/customer-document');
  }

  getByCustomerDocument(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: 'dms/customer-document-group',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  getUserDocumentHistory(query: any) {
    return http({
      url: '/dms/crm-customer-document',
      method: 'GET',
      params: query,
    });
  }

  getCustomerList(query?: any) {
    return http({
      url: 'dms/customer',
      method: 'get',
      params: query,
    });
  }

  getCustomer(id: any) {
    return http({
      url: 'dms/customer/' + id,
      method: 'get',
    });
  }
}

export { CustomerDocumentResource as default };
