/* All validations should be defined here */

export function isExternal(path: string) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * Validate a valid URL
 * @param {String} textval
 * @return {Boolean}
 */
export function validURL(url: string) {
  const reg =
    /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/;
  return reg.test(url);
}

/**
 * Validate a full-lowercase string
 * @return {Boolean}
 * @param {String} str
 */
export function validLowerCase(str: string) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * Validate a full-uppercase string
 * @return {Boolean}
 * @param {String} str
 */
export function validUpperCase(str: string) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * Check if a string contains only alphabet
 * @param {String} str
 * @param {Boolean}
 */
export function validAlphabets(str: string) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * Validate an email address
 * @param {String} email
 * @return {Boolean}
 */
export function validEmail(email: string) {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * validation function for file upload.
 * @param {string} fileExtension,
 * @returns boolean,
 */

export const validateFile = (fileExtension: string): boolean => {
  const fileTypes: string = fileExtension?.split('/')?.[0];
  return fileTypes === 'image' ? true : false;
};

/**
 * validation for file size.Maximum allowed size is 5MB.
 * @param {number} fileSize in byte.
 * @return boolean
 *
 */
export const validateFileSize = (fileSize: number): boolean => {
  return fileSize <= 1000000 ? true : false;
};

/**
 * Password Validation
 * Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
 *
 * @param password string
 * @returns
 */
export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
};

/**
 * Number validation
 * validation to accept only digits i.e: from 0 to 9
 * @params text string
 * @returns
 */
export const validateNumber = (text: string | null) => {
  if (!text) return null;
  const regex = /^[0-9]*$/;
  return regex.test(text);
};
