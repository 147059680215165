import http from 'utils/http';
import Resource from './resource';

class ImportResource extends Resource {
  constructor() {
    super('mdm/imports');
  }

  uploadInfo(query: any) {
    return http({
      url: '/mdm/imports/import-type',
      method: 'GET',
      params: query,
    });
  }
}

export { ImportResource as default };
