import { Box, Flex, Icon, Stack } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { DashboardContactSchema } from 'constants/schema';
import React from 'react';
import { BiPhoneCall } from 'react-icons/bi';
import { BsEnvelope } from 'react-icons/bs';

interface Props {
  customerInfo: DashboardContactSchema;
}

const ContactInfo: React.FC<Props> = (props) => {
  const { customerInfo } = props;

  const {
    salutation,
    first_name,
    last_name,
    email,
    is_subscribed,
    street,
    house_no,
    zip_code,
    location,
    country,
    phone,
    mobile,
    company,
    contactDetail,
  } = customerInfo;
  return (
    <Stack width="100%" gridGap="2">
      <div>
        {strings.salutation}: <div>{salutation}</div>
      </div>
      <div>
        {strings.first_name}: <div>{first_name}</div>
      </div>
      <div>
        {strings.last_name}: <div>{last_name}</div>
      </div>
      <div>
        {strings.street}: <div>{street || '-'}</div>
      </div>
      <div>
        {strings.house_number}: <div>{house_no || '-'}</div>
      </div>
      <div>
        {strings.post_code}: <div>{zip_code || '-'}</div>
      </div>
      <div>
        {strings.town}: <div>{location || '-'}</div>
      </div>
      <div>
        {strings.country}: <div>{country || '-'}</div>
      </div>
      <div>
        {strings.contact}:
        {phone ? (
          <Flex gridGap="2" justify="space-between">
            <div>{phone}</div>
            <Box>
              <a href={`tel:${phone}`}>
                <Icon aria-label={strings.call} as={BiPhoneCall} h="5" w="5" />
              </a>
            </Box>
          </Flex>
        ) : (
          <div>-</div>
        )}
      </div>
      <div>
        {strings.mobile_number}:
        {mobile ? (
          <Flex gridGap="2" justify="space-between">
            <div>{mobile}</div>
            <Box>
              <a href={`tel:${mobile}`}>
                <Icon aria-label={strings.call} as={BiPhoneCall} h="5" w="5" />
              </a>
            </Box>
          </Flex>
        ) : (
          <div>-</div>
        )}
      </div>
      <div>
        {strings.email}:
        <Flex gridGap="2" justify="space-between">
          <div>{email}</div>
          <Box>
            <a href={`mailto:${email}`}>
              <Icon aria-label={strings.mails} as={BsEnvelope} h="5" w="5" />
            </a>
          </Box>
        </Flex>
      </div>
      <div>
        {strings.company}: <div>{company || '-'}</div>
      </div>
      <div>
        {strings.pets}: <div>{contactDetail.pet || '-'}</div>
      </div>
      <div>
        {strings.new_newsletter}:
        <div>{is_subscribed ? strings.subscribe : strings.unsubscribe}</div>
      </div>
    </Stack>
  );
};

export default ContactInfo;
