import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import WarehouseResource from 'api/warehouses';
import { QRScan } from 'assets/icons';
import QRScanDetailPDF from 'components/qr-scan/QRScanDetailPDF';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  qrDataSchema,
  RackItemSchema,
  RoomItemSchema,
  ShelfItemSchema,
  WarehouseSchema,
} from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useEffect, useRef, useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import AccessControl from 'services/AccessControl';
import { truncateString } from 'utils';

interface Props {
  warehouseData: WarehouseSchema;
  index: number;
  totalRooms: number;
  totalRacks: number;
  totalShelves: number;
  search: any;
}

const WarehouseListItem: React.FC<Props> = (props) => {
  // Props
  const { warehouseData, index, totalRooms, totalRacks, totalShelves, search } =
    props;

  // Library Init
  const queryClient = useQueryClient();

  // API Resource
  const warehouseAPI = new WarehouseResource();

  // Access Control
  const checkEditWarehousePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.warehouse.edit.replace(':id', warehouseData.id.toString())
  );

  // Local State
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');

  const printRef: any = useRef();
  const [qrSignature, setQrSignature] = useState<qrDataSchema[]>([]);
  const [isQRModelOpen, setIsQRModelOpen] = useState(false);

  const deleteWarehouse = useMutation(
    () => warehouseAPI.destroy(warehouseData.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('warehouseList');
        handleCloseModal();
      },
      onError: (error: any) => {
        const msg =
          error?.response?.data?.error?.code === 409
            ? strings.warehouse_holds_inventories
            : error?.response?.data?.error?.message;
        setDeleteErrorMsg(msg);
      },
    }
  );

  const handleConfirmDelete = () => {
    deleteWarehouse.mutate();
  };

  const handleDeleteWarehouse = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveQRScan = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '* { background-color: white !important } ',
    documentTitle: `${strings.warehouse} ${strings.qr_code}`,
  });

  useEffect(() => {
    if (isQRModelOpen) {
      saveQRScan();
      setIsQRModelOpen(false);
    }
  }, [isQRModelOpen, saveQRScan]);

  const getQrCodes = () => {
    let newQrSignature: qrDataSchema[] = [];
    const { id: warehouseId, name: warehouseName } = warehouseData;
    warehouseData?.rooms.forEach((room: RoomItemSchema) => {
      room.racks.forEach((rack: RackItemSchema) => {
        rack.shelves.forEach((shelf: ShelfItemSchema) => {
          const shelfQRName: string = `${warehouseName} / ${room.name} / ${rack.name} / ${shelf.name}`;
          const shelfQRIds = {
            shelf: shelf.id,
          };
          const shelfQRData = JSON.stringify(shelfQRIds);

          newQrSignature.push({ title: shelfQRName, value: shelfQRData });
        });
      });
    });

    setQrSignature(newQrSignature);
    setIsQRModelOpen(true);
  };

  return (
    <Tr key={warehouseData.id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.mms.warehouse.view.replace(
              ':id',
              warehouseData.id.toString()
            ) + search
          }>
          {warehouseData.name}
        </RouterLink>
      </Td>
      <Td isNumeric>{totalRooms}</Td>
      <Td isNumeric>{totalRacks}</Td>
      <Td isNumeric>{totalShelves}</Td>
      <Td>
        <Tooltip hasArrow label={warehouseData.description} fontSize="normal">
          {truncateString(warehouseData.description, 80)}
        </Tooltip>
      </Td>
      <Td>
        <RouterLink
          to={
            routes.mms.warehouse.view.replace(
              ':id',
              warehouseData.id.toString()
            ) + search
          }>
          <Tooltip
            hasArrow
            label={`${strings.view} ${strings.warehouse}`}
            fontSize="sm">
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={`${strings.view} ${strings.warehouse}`}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Link onClick={checkEditWarehousePermission}>
          <Tooltip
            hasArrow
            label={`${strings.edit} ${strings.warehouse}`}
            fontSize="sm">
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={`${strings.edit} ${strings.warehouse}`}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </Link>
        <Tooltip
          hasArrow
          label={`${strings.delete} ${strings.warehouse}`}
          fontSize="sm">
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={`${strings.delete} ${strings.warehouse}`}
            color="red.300"
            minW="6"
            onClick={handleDeleteWarehouse}
          />
        </Tooltip>
        <Modal isOpen={isModalOpen} isCentered onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_warehouse}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}

              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[
                    PermissionRequest['read:mms'],
                    PermissionRequest['manage:article'],
                  ]}
                  renderNoAccess={(data: any) => data}>
                  <Button
                    colorScheme="red"
                    type="button"
                    onClick={handleConfirmDelete}
                    isLoading={deleteWarehouse.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>
                <Button
                  variant="outline"
                  type="button"
                  onClick={handleCloseModal}
                  isDisabled={deleteWarehouse.isLoading}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Tooltip hasArrow label={strings.qr_code}>
          <Button variant="link" minW="6" onClick={getQrCodes}>
            <Image src={QRScan} alt="QR Scan Button" w="4" />
          </Button>
        </Tooltip>

        {isQRModelOpen && (
          <div style={{ display: 'none' }}>
            <QRScanDetailPDF qrSignature={qrSignature} ref={printRef} />
          </div>
        )}
      </Td>
    </Tr>
  );
};

export default WarehouseListItem;
