import TicketResource from 'api/ticket';
import { DraggableLocation } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
export const reorder = (
  list: any[],
  startIndex: number,
  endIndex: number
): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderTickets = (
  tickets: any,
  source: DraggableLocation,
  destination: DraggableLocation,
  userId: number,
  updateTotalTickets: (source: string, destination: string) => void
) => {
  const current: any = [...tickets[source.droppableId]];
  const next = [...tickets[destination.droppableId]];
  const target = current[source.index];
  const ticketApi = new TicketResource();
  const status =
    destination.droppableId === 'inProgress'
      ? 'in-progress'
      : destination.droppableId;
  // moving to same list
  if (source.droppableId === destination.droppableId) {
    // Update the time and updated_by
    ticketApi.updateTicketStatus(current[source.index]['id'], status, userId);
    const reordered = reorder(current, source.index, destination.index);
    return {
      ...tickets,
      [source.droppableId]: reordered,
    };
  }

  // moving to different list
  // Update status, update_by and time
  ticketApi.updateTicketStatus(current[source.index]['id'], status, userId);

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  updateTotalTickets(source.droppableId, destination.droppableId);

  return {
    ...tickets,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };
};
