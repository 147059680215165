import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { changePassword, getAuthUser, updateAuthUser } from 'api/auth';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ChangePassword from 'components/profile/ChangePassword';
import ProfileForm from 'components/profile/ProfileForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { PasswordSchema, UserSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as authService from 'services/auth';
import { unmaskPhone } from 'utils';

const EditProfile: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const methods = useForm<UserSchema>();
  const passwordMethods = useForm<PasswordSchema>();
  const [errMsg, setErrMsg] = useState<string>('');

  const userQuery = useQuery<UserSchema>(
    `user-auth`,
    () => getAuthUser().then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateUser = useMutation((data: any) => updateAuthUser(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.user_updated,
        description: res.data.email,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('userList');
      history.push(routes.dashboard);
    },
  });

  const updatePassword = useMutation((data: any) => changePassword(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.login_with_new_password,
        description: res.data.email,
        status: 'success',
        isClosable: true,
      });
      authService.logout();
      history.push(routes.auth.login);
    },
    onError: (error: any) => {
      if (error?.response?.data?.errors?.new_password)
        setErrMsg(error?.response?.data?.errors?.new_password[0]);
      if (error?.response?.data?.errors?.old_password)
        setErrMsg(strings.invalid_current_password);   
    },
  });

  const onUpdateUser = (data: any) => {
    data.contact_number = unmaskPhone(data.contact_number);
    updateUser.mutate(data);
  };

  const onUpdatePassword = (data: any) => {
    updatePassword.mutate(data);
  };

  if (userQuery.isLoading) return <CenterSpinner />;

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.profile.myProfile}>
            {strings.my_profile}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.profile.edit}>
            {strings.edit_profile}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.edit_profile}</Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onUpdateUser)}>
            <Stack direction="column" spacing="6">
              {updateUser.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.profle_update_failed}
                </Alert>
              )}
              {userQuery?.data && <ProfileForm userData={userQuery?.data} />}
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateUser.isLoading}>
                  {strings.save_now}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.change_password}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <FormProvider {...passwordMethods}>
          <form onSubmit={passwordMethods.handleSubmit(onUpdatePassword)}>
            <Stack direction="column" spacing="6">
              {updatePassword.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              {userQuery?.data && <ChangePassword />}
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updatePassword.isLoading}>
                  {strings.change_now}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditProfile;
