import { Button, ButtonGroup } from '@chakra-ui/button';
import { FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { GridItem, Heading, Stack } from '@chakra-ui/layout';
import { AlertDialog, AlertDialogOverlay } from '@chakra-ui/modal';
import {
  Alert,
  AlertIcon,
  FormControl,
  Grid,
  Icon,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import KeyAPI from 'api/key';
import KeyLogAPI from 'api/keyLogs';
import { wrapperStyles } from 'assets/css/commonStyles';
import GetKeyId from 'components/keys/GetKeyId';
import { strings } from 'config/localization';
import { KeyDetailSchema } from 'constants/schema';
import React, { ElementRef, useRef, useState } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQuery } from 'react-query';

interface Props {
  bookingNumber: string;
  customerName: string;
  customerId: number;
  reservation_id: number;
  apartment_id: number;
}

const CheckInOutKeyStatus: React.FC<Props> = (props) => {
  const {
    bookingNumber,
    customerName,
    customerId,
    reservation_id,
    apartment_id,
  } = props;

  const keyAPI = new KeyAPI();
  const keyLogAPI = new KeyLogAPI();

  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [scannedKeyId, setScannedKeyId] = useState<null | string>(null);
  const [message, setMessage] = useState<string | null>(null);
  const cancelRef = useRef<ElementRef<'div'>>(null);
  const [notes, setNotes] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>('');

  const { data: keyDetails } = useQuery<KeyDetailSchema>(
    ['get-key-details-from-key-id', scannedKeyId],
    () => keyAPI.get(scannedKeyId).then((res: any) => res.data.data),
    {
      refetchOnWindowFocus: false,
      enabled: !!scannedKeyId,
      onSuccess: (res) => {
        if (apartment_id !== res.apartment.id) {
          setErrorMessage(strings.invalid_object_key);
          setMessage(null);
          setScannedKeyId(null);
          return;
        }
        setMessage(strings.key_scanned_successfully);
        setErrorMessage(null);
      },
    }
  );

  const keyService = useMutation({
    mutationFn: () =>
      keyLogAPI.store({
        keyId: keyDetails?.id,
        reservation_id: reservation_id,
        customerId: customerId,
        notes,
      }),
    onSuccess: () => {
      toast({
        title: strings.key_updated_successfully,
        status: 'success',
        isClosable: true,
      });
      setMessage(null);
      setScannedKeyId(null);
      setNotes('');
    },
    onError: (error: any) => {
      if (error?.response?.status !== 400 && error?.response?.status !== 500) {
        toast({
          title: error?.response?.data?.message || strings.error,
          status: 'error',
          isClosable: true,
        });
      }
    },
  });

  const isInStock: boolean = keyDetails?.status === 'in stock';
  const newKeyStatus = isInStock ? strings.given_out : strings.in_stock;

  const handleCloseScan = () => {
    setScannedKeyId(null);
    onClose();
  };

  const removeScannedKey = () => {
    setScannedKeyId(null);
    setMessage(strings.key_removed_successfully);
  };

  const onSubmit = () => {
    keyService.mutate();
  };

  return (
    <Stack direction="column" spacing="4">
      <Stack direction="row" justifyContent="space-between">
        <Heading as="h3" size="md" textTransform="capitalize">
          {strings.key_new_status}
        </Heading>
        <Button size="sm" colorScheme="primary" type="button" onClick={onOpen}>
          <Icon as={BiBarcodeReader} mr={1} />
          {strings.scan_key}
        </Button>
      </Stack>
      <form>
        <Stack sx={wrapperStyles}>
          {message && (
            <Alert status="success" mb="4">
              <AlertIcon />
              {message}
            </Alert>
          )}
          {errorMessage && (
            <Alert status="error" mb="4">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          <Grid
            gap="4"
            templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl isDisabled>
                <FormLabel>{strings.new_status}</FormLabel>
                <Input
                  type="text"
                  name="keyword"
                  value={scannedKeyId ? newKeyStatus : ''}
                  opacity={0.8}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled>
                <FormLabel>{strings.booking_number}</FormLabel>
                <Input
                  type="text"
                  value={bookingNumber}
                  opacity={0.8}
                  disabled
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isDisabled>
                <FormLabel>{strings.customer}</FormLabel>
                <Input
                  type="text"
                  value={customerName}
                  opacity={0.8}
                  disabled
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={[1, 3]}>
              <FormControl isDisabled={!scannedKeyId}>
                <FormLabel>{strings.notes}</FormLabel>
                <Textarea
                  placeholder={strings.notes}
                  value={notes}
                  onChange={(e) => setNotes(e.currentTarget.value)}
                />
              </FormControl>
            </GridItem>
          </Grid>

          {scannedKeyId && message && (
            <ButtonGroup>
              <Button
                size="sm"
                colorScheme="primary"
                type="button"
                onClick={onSubmit}>
                <Icon as={BiBarcodeReader} mr={1} />
                {strings.confirm}
              </Button>
              <Button
                size="sm"
                colorScheme="primary"
                type="button"
                onClick={removeScannedKey}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      </form>
      {isOpen && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={handleCloseScan}
          isOpen={isOpen}
          isCentered>
          <AlertDialogOverlay />

          <GetKeyId
            setScannedKeyId={setScannedKeyId}
            onClose={onClose}
            databaseCheck={true}
          />
        </AlertDialog>
      )}
    </Stack>
  );
};

export default CheckInOutKeyStatus;
