import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';

const ObjectDetails: React.FC = () => {
  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.object_details}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.objects.list}>
              {strings.objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.object_details}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.object_details}
          </Heading>
          <h2>
            <small>{strings.total_quantity}</small>: 30
          </h2>
        </Flex>

        <Stack sx={wrapperStyles}>
          <Stack>
            <Grid
              ga
              p={['3', '6']}
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.object_name}</FormLabel>
                  <Text sx={infoStyles}>{strings.apartment}</Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.house_owner}</FormLabel>
                  <Text sx={infoStyles}>Jhon</Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.street}</FormLabel>
                  <Text sx={infoStyles}>windmühl</Text>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.house_number}</FormLabel>
                  <Text sx={infoStyles}>123</Text>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.object_image}</FormLabel>
              <Box>
                <Image
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="4"
                  boxSize="200px"
                  objectFit="cover"
                  alt={strings.object_image}
                />
              </Box>
            </FormControl>
          </Stack>
        </Stack>
        <Stack>{/* <ArticleListItem /> */}</Stack>
      </Stack>
    </>
  );
};

export default ObjectDetails;
