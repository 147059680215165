import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import EmailMarketingResource from 'api/emailMarketing';
import { wrapperStyles } from 'assets/css/commonStyles';
import MarketingEmailListItem from 'components/bms_marketing_email/MarketingEmailListItem';
import MarketingEmailSearch from 'components/bms_marketing_email/MarketingEmailSearch';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import {
  DataWrapperSchema,
  ReservationEmailStatus,
  ReservationListSchema,
} from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, cleanData, getStartingSerialNumber } from 'utils';
import {
  getDateRangeStartDateTime,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

export interface MarketingEmailFilterParams {
  currentPage: number;
  pageSize: number;
  customer: string;
  booking_number: string;
  email_status: ReservationEmailStatus | null;
  email_date: Date | null;
  time_period_from: Date | null;
  time_period_to: Date | null;
}

const MarketingEmails: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const emailMarketingAPI = new EmailMarketingResource();

  const [filterParams, setFilterParams] = useState<MarketingEmailFilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    customer: searchValues.customer ?? '',
    booking_number: searchValues.booking_number ?? '',
    email_status: searchValues.email_status ?? null,
    email_date: Number(searchValues.email_date)
      ? new Date(Number(searchValues.email_date))
      : null,
    time_period_from: Number(searchValues.time_period_from)
      ? new Date(Number(searchValues.time_period_from))
      : null,
    time_period_to: Number(searchValues.time_period_to)
      ? new Date(Number(searchValues.time_period_to))
      : null,
  });

  const reservationList = useQuery<DataWrapperSchema<ReservationListSchema[]>>({
    queryKey: ['market-email-list', filterParams],
    queryFn: async () => {
      const queryParams = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        email_status: filterParams.email_status,
        customer: filterParams.customer,
        booking_number: filterParams.booking_number,
        email_date: filterParams.email_date
          ? getDateRangeStartDateTime(filterParams.email_date)
          : null,
        time_period_from: filterParams.time_period_from
          ? getUTCDateRangeStartDateTime(filterParams.time_period_from)
          : null,
        time_period_to: filterParams.time_period_to
          ? getUTCDateRangeEndDateTime(filterParams.time_period_to)
          : null,
      };
      const cleanQueryData = cleanData(queryParams);

      const response = await emailMarketingAPI.list(cleanQueryData);
      return response?.data;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.email_date) data['email_date'] = Date.parse(data.email_date);
    if (data.time_period_from)
      data.time_period_from = Date.parse(data.time_period_from);
    if (data.time_period_to)
      data.time_period_to = Date.parse(data.time_period_to);
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.marketingEmail.list}>
            {strings.bms}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.marketing_email}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.marketing_email}
        </Heading>
      </Flex>

      <MarketingEmailSearch
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.booking_number}</Th>
                <Th>{strings.customer}</Th>
                <Th>{strings.object}</Th>
                <Th>{strings.time_period}</Th>
                <Th>{strings.email_date}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reservationList.data?.data.map((reservation, index: number) => (
                <MarketingEmailListItem
                  data={reservation}
                  key={reservation.id}
                  index={startingSN + index}
                />
              ))}
              {reservationList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        dataList={reservationList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default MarketingEmails;
