import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ArticleTypesResource from 'api/article-types';
import ArticleUnitsResource from 'api/article-units';
import ArticlesResource from 'api/articles';
import { wrapperStyles } from 'assets/css/commonStyles';
import ArticleListItem from 'components/article/ArticlesListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  name: string;
  articleUnitTypeId: string;
  articleCategoryId: string;
}

const Articles: React.FC = () => {
  const articlesAPI = new ArticlesResource();
  const articleUnitApi = new ArticleUnitsResource();
  const articleTypeApi = new ArticleTypesResource();
  const [isLoading, setIsLoading] = useState<'filter' | 'reset' | ''>('');

  const checkAddArticlePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.articles.add
  );

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const url_name = searchParams.get('name');
  const url_articleUnitTypeId = searchParams.get('articleUnitTypeId');
  const url_articleCategoryId = searchParams.get('articleCategoryId');

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    name: url_name ?? '',
    articleUnitTypeId: url_articleUnitTypeId ?? '',
    articleCategoryId: url_articleCategoryId ?? '',
  });

  const articleList = useQuery(
    [
      'articleList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        name: filterParams.name,
        articleUnitTypeId: filterParams.articleUnitTypeId,
        articleCategoryId: filterParams.articleCategoryId,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.name) queryParams.name = filterParams.name;
      if (filterParams.articleUnitTypeId)
        queryParams.article_unit_type_id = filterParams.articleUnitTypeId;
      if (filterParams.articleCategoryId)
        queryParams.article_category_id = filterParams.articleCategoryId;

      const response = await articlesAPI.list(queryParams);
      response && setIsLoading('');
      return response?.data?.data;
    }
  );

  /**
   * fetch article unit and types
   */
  const fetchArticleUnit = () => {
    return articleUnitApi.list().then((res) => res.data.data?.data);
  };
  const fetchArticleType = () => {
    return articleTypeApi.list().then((res) => res.data.data?.data);
  };

  const { data: articleUnitData } = useQuery('article-unit', fetchArticleUnit);
  const { data: articleTypesData } = useQuery(
    'article-types',
    fetchArticleType
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleReset = () => {
    if (
      !filterParams.name &&
      !filterParams.articleCategoryId &&
      !filterParams.articleUnitTypeId
    )
      return;
    setIsLoading('reset');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      name: '',
      articleUnitTypeId: '',
      articleCategoryId: '',
    }));
    history.push(routes.mms.articles.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.articles}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.mms.articles.list}>
              {strings.all_articles}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.articles}
          </Heading>
          <Button
            type="button"
            colorScheme="primary"
            size="sm"
            onClick={checkAddArticlePermission}>
            {strings.add_article}
          </Button>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack direction="column" spacing="4">
              <Stack
                direction={['column', 'column', 'row']}
                spacing="4"
                align={['start', 'start', 'end']}>
                <Grid
                  gap={['4', '4', '6']}
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(3, 1fr)',
                  ]}
                  w="100%">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <Input
                          type="text"
                          name="name"
                          value={filterParams.name}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.article_type}</FormLabel>
                      <Select
                        name="articleCategoryId"
                        value={filterParams.articleCategoryId}
                        placeholder={strings.article_type}
                        onChange={handleInputChange}>
                        {articleTypesData?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.article_unit}</FormLabel>
                      <Select
                        name="articleUnitTypeId"
                        value={filterParams.articleUnitTypeId}
                        placeholder={strings.article_unit}
                        onChange={handleInputChange}>
                        {articleUnitData?.map((item: any) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Stack direction="row" spacing="4">
                  <Button
                    colorScheme="primary"
                    onClick={handleReset}
                    variant="outline">
                    {strings.reset_filter}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.article_name}</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.unit}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {articleList?.data?.data?.map((article: any, index: number) => (
                  <ArticleListItem
                    data={article}
                    index={startingSN + index}
                    key={article.id}
                    search={search}
                  />
                ))}
                {articleList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={articleList}
        />
      </Stack>
    </>
  );
};

export default Articles;
