import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Flex, Heading, Stack } from '@chakra-ui/layout';
import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/table';
import RestaurantReservationResource from 'api/restaurant_reservation';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import RestaurantReservationListItem from 'components/restaurant_reservation/RestaurantReservationListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { RestaurantReservationListSchema } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  booking_number: string;
}

const RestaurantReservationList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    booking_number: searchValues.booking_number ?? '',
  });

  const restaurantReservationAPI = new RestaurantReservationResource();

  const restaurantReservationListData: any = useQuery(
    [
      'restaurantReservation-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        booking_number: filterParams.booking_number,
      },
    ],
    () => {
      let { keyword, booking_number } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.keyword = keyword;
      if (booking_number) queryParams.booking_number = booking_number;

      return restaurantReservationAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
    history.push(routes.restaurant.restaurantReservation.list);
  }

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.restaurant} | {strings.restaurant_reservation}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.restaurant}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.restaurant.restaurantReservation.list}>
            {strings.restaurant_reservation}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex>
        <Heading size="md" textTransform="capitalize">
          {strings.restaurant_reservation_list}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.search}</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BiSearch />}
                  color="gray.400"
                />
                <Input
                  type="text"
                  name="keyword"
                  value={filterParams.keyword}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.booking_number}</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BiSearch />}
                  color="gray.400"
                />
                <Input
                  type="text"
                  name="booking_number"
                  value={filterParams.booking_number}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>

            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={handleReset}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.first_name}</Th>
                <Th>{strings.last_name}</Th>
                <Th>{strings.email}</Th>
                <Th>{strings.phone_number}</Th>
                <Th>{strings.booking_date}</Th>
                <Th>{strings.booking_time}</Th>
                <Th>{strings.restaurant_name}</Th>
                <Th>{strings.booking_number}</Th>
                <Th>{strings.actions}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {restaurantReservationListData?.data?.data?.map(
                (
                  restaurantReservationData: RestaurantReservationListSchema,
                  index: number
                ) => (
                  <RestaurantReservationListItem
                    key={restaurantReservationData.id}
                    index={startingSN + index}
                    restaurantReservationData={restaurantReservationData}
                    search={search}
                  />
                )
              )}
              {restaurantReservationListData.isLoading && (
                <TableSkeletonLoader rows={10} cols={10} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      {restaurantReservationListData?.data && (
        <Pagination
          dataList={restaurantReservationListData}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      )}
    </Stack>
  );
};

export default RestaurantReservationList;
