import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ArticleTypesResource from 'api/article-types';
import { wrapperStyles } from 'assets/css/commonStyles';
import TypeForm from 'components/article-type/TypeForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ArticleTypeSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const defaultValues = {
  name: '',
  description: '',
};

const EditType: React.FC = () => {
  // params
  const { id }: any = useParams();

  // Library Init
  const methods = useForm<ArticleTypeSchema>({
    defaultValues: defaultValues,
  });
  const toast = useToast();

  // API Resource
  const articleTypesAPI = new ArticleTypesResource();

  // Local state
  const [errMsg, setErrMsg] = useState('');

  // Redux Selector
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const articleTypeQuery = useQuery<ArticleTypeSchema>(
    [`articleType${id}`, id],
    () => articleTypesAPI.get(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateArticleType = useMutation((data: any) =>
    articleTypesAPI.update(id, data)
  );

  if (articleTypeQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (articleTypeQuery.isError) {
    history.push(routes.mms.types.list);
  }

  const onSubmit = (data: ArticleTypeSchema) => {
    data.updated_by = loggedInUser.id;
    updateArticleType.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.type} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.mms.types.list);
      },
      onError: () =>
        setErrMsg(`${strings.type} ${strings.has_not_been_updated}`),
    });
  };

  const handleReset = () => {
    methods.reset();
    setErrMsg('');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.edit_type}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.types.list}>
              {strings.type}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {articleTypeQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.edit_type}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <Stack direction="column" spacing="4">
                <TypeForm data={articleTypeQuery?.data} />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    isLoading={updateArticleType.isLoading}
                    type="submit">
                    {strings.update}
                  </Button>
                  <Button variant="outline" onClick={handleReset}>
                    {strings.clear}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditType;
