import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { objectListData } from 'data/objectListData';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiShow, BiTrash } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link as RouterLink } from 'react-router-dom';

const Objects: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.objects}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.objects.list}>
              {strings.objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.objects}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form>
            <Stack direction="row" spacing="4" align="end">
              <FormControl>
                <FormLabel>{strings.object_name}</FormLabel>
                <Input type="text" placeholder={strings.object_name} />
              </FormControl>
              <Stack direction="row" spacing="4" pt="8">
                <Button colorScheme="primary" type="submit" variant="outline">
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack sx={wrapperStyles}>
          <Table>
            <Thead>
              <Tr>
                <Th rowspan={2}>#</Th>
                <Th rowspan={2}>{strings.object_name}</Th>
                <Th rowspan={2} isNumeric>
                  {strings.booking}
                </Th>

                <Th rowspan={2} isNumeric>
                  {strings.inventory_load}
                </Th>
                <Th rowspan={2}>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {objectListData.map(
                ({ id, name, booking, load }, index: number) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>
                      <RouterLink to={routes.mms.objects.details}>
                        {name}
                      </RouterLink>
                    </Td>
                    <Td isNumeric>{booking}</Td>

                    <Td isNumeric>{load}</Td>
                    <Td>
                      <RouterLink to={routes.mms.objects.details}>
                        <Tooltip hasArrow label="View Object">
                          <IconButton
                            icon={<BiShow />}
                            variant="link"
                            aria-label="View Object"
                            color="green.300"
                            minW="6"
                          />
                        </Tooltip>
                      </RouterLink>

                      <Tooltip hasArrow label="Delete Object">
                        <IconButton
                          icon={<BiTrash />}
                          variant="link"
                          aria-label="Delete Object"
                          color="red.300"
                          minW="6"
                          onClick={() => setIsModalOpen(true)}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </Stack>
      </Stack>
      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.delete_user}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this?</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="red">Delete</Button>
              <Button variant="outline">Cancel</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Objects;
