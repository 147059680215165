import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { ExcelFile } from 'assets/icons';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ExportAppointmentTodo from 'components/ticket/ExportAppointmentTodo';
import TicketAddMenu from 'components/ticket/TicketAddMenu';
import TicketListItem from 'components/ticket/TicketListItem';
import TicketSearch from 'components/ticket/TicketSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { AppointmentTodoExport } from 'constants/schema';
import * as FileSaver from 'file-saver';
import { useDebounce } from 'hooks/useDebounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiChevronDown, BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  cleanData,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import {
  DateFormat,
  DateFormatYMD,
  getDateRangeEndDateTime,
  getDateRangeStartDateTime,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

const FILEEXTENSION = '.xlsx';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  title: string;
  priority: string;
  status: string;
  assigned_user_id: string;
  assignee_name: string;
  apartment_id: string;
  customer_id: string;
  customer: string;
  external_company_id: string;
  company: string;
  source: string;
  created_at_start: Date | null;
  created_at_end: Date | null;
  due_date_start: Date | null;
  due_date_end: Date | null;
  appointment_date_from: Date | null;
  appointment_date_to: Date | null;
  type: string;
  order_by: string;
  order: any;
  responsible_person_id: string;
  responsible_person_name: string;
}

const TicketList: React.FC = () => {
  const ticketApi = new TicketResource();
  const history = useHistory();
  const { search } = useLocation();
  const toast = useToast();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [defaultIndex, setDefaultIndex] = useState<0 | -1>(
    defaultIndexIdentifier(searchValues)
  );

  const [appointmentTodoExport, setAppointmentTodoExport] =
    useState<AppointmentTodoExport>(null);

  const [showExportOption, setShowExportOption] = useState<boolean>(false);

  const created_at_start = Number(searchValues.created_at_start);
  const created_at_end = Number(searchValues.created_at_end);
  const due_date_start = Number(searchValues.due_date_start);
  const due_date_end = Number(searchValues.due_date_end);
  const appointment_date_from = Number(searchValues.appointment_date_from);
  const appointment_date_to = Number(searchValues.appointment_date_to);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    title: searchValues.title ?? '',
    priority: searchValues.priority ?? '',
    status: searchValues.status ?? '',
    assigned_user_id: searchValues.assigned_user_id ?? '',
    assignee_name: searchValues.assignee_name ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    customer_id: searchValues.customer_id ?? '',
    customer: searchValues.customer ?? '',
    external_company_id: searchValues.external_company_id ?? '',
    company: searchValues.company ?? '',
    source: searchValues.source ?? '',
    created_at_start: created_at_start ? new Date(created_at_start) : null,
    created_at_end: created_at_end ? new Date(created_at_end) : null,
    due_date_start: due_date_start ? new Date(due_date_start) : null,
    due_date_end: due_date_end ? new Date(due_date_end) : null,
    appointment_date_from: appointment_date_from
      ? new Date(appointment_date_from)
      : null,
    appointment_date_to: appointment_date_to
      ? new Date(appointment_date_to)
      : null,
    type: searchValues.type ?? '',
    order_by: searchValues.order_by ?? '',
    order: searchValues.order ?? '',
    responsible_person_id: searchValues.responsible_person_id ?? '',
    responsible_person_name: searchValues.responsible_person_name ?? '',
  });

  const name = useDebounce(filterParams.title);

  const ticketList = useQuery(
    [
      'ticketList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        priority: filterParams.priority,
        status: filterParams.status,
        assigned_user_id: filterParams.assigned_user_id,
        apartment_id: filterParams.apartment_id,
        customer_id: filterParams.customer_id,
        external_company_id: filterParams.external_company_id,
        source: filterParams.source,
        created_at_start: filterParams.created_at_start,
        created_at_end: filterParams.created_at_end,
        due_date_start: filterParams.due_date_start,
        due_date_end: filterParams.due_date_end,
        appointment_date_from: filterParams.appointment_date_from,
        appointment_date_to: filterParams.appointment_date_to,
        type: filterParams.type,
        order_by: filterParams.order_by,
        order: filterParams.order,
      },
      name,
    ],
    async () => {
      try {
        const response = await getTicketList(
          filterParams.currentPage,
          filterParams.pageSize
        );
        return response?.data;
      } catch (error) {
        throw new Error('Some error occured');
      }
    }
  );

  const getTicketList = async (page: number, limit: number) => {
    const {
      priority,
      status,
      assigned_user_id,
      apartment_id,
      customer_id,
      external_company_id,
      source,
      created_at_start,
      created_at_end,
      due_date_start,
      due_date_end,
      appointment_date_from,
      appointment_date_to,
      type,
      order_by,
      order,
      responsible_person_id,
    } = filterParams;

    const queryParams: any = {
      page,
      limit,
    };

    if (name) queryParams.title = name;
    if (priority) queryParams.priority = priority;
    if (status) queryParams.status = status;
    if (assigned_user_id) queryParams.assigned_user_id = assigned_user_id;
    if (apartment_id) queryParams.apartment_id = apartment_id;
    if (customer_id) queryParams.customer_id = customer_id;
    if (external_company_id)
      queryParams.external_company_id = external_company_id;
    if (source) queryParams.source = source;
    if (created_at_start)
      queryParams.created_at_start =
        getUTCDateRangeStartDateTime(created_at_start);
    if (created_at_end)
      queryParams.created_at_end = getUTCDateRangeEndDateTime(created_at_end);
    if (due_date_start)
      queryParams.due_date_start = getDateRangeStartDateTime(due_date_start);
    if (due_date_end)
      queryParams.due_date_end = getDateRangeEndDateTime(due_date_end);
    if (appointment_date_from)
      queryParams.appointment_date_from = getDateRangeStartDateTime(
        appointment_date_from
      );
    if (appointment_date_to)
      queryParams.appointment_date_to =
        getDateRangeEndDateTime(appointment_date_to);
    if (order_by) queryParams.order_by = order_by;
    if (order) queryParams.order = order;
    if (type) queryParams.type = type;
    if (responsible_person_id)
      queryParams.responsible_person = responsible_person_id;
    return await ticketApi.list(queryParams);
  };

  const handleAdvancedSearch = useCallback(
    (data: any) => {
      setFilterParams((prevState) => ({
        ...prevState,
        currentPage: INITIAL_CURRENT_PAGE,
        title: data.title,
        priority: data.priority,
        status: data.status,
        assigned_user_id: data.assigned_user_id,
        assignee_name: data.assignee_name,
        apartment_id: data.apartment_id,
        customer_id: data.customer_id,
        customer: data.customer,
        external_company_id: data.external_company_id,
        company: data.company,
        source: data.source,
        created_at_start: data.created_at_start,
        created_at_end: data.created_at_end,
        due_date_start: data.due_date_start,
        due_date_end: data.due_date_end,
        appointment_date_from: data.appointment_date_from,
        appointment_date_to: data.appointment_date_to,
        type: data.type,
        order_by: data.order_by,
        order: data.order,
        responsible_person_id: data.responsible_person_id,
        responsible_person_name: data.responsible_person_name,
      }));

      const urlData = { ...data };
      if (urlData.created_at_start) {
        urlData.created_at_start = Date.parse(urlData.created_at_start);
      }
      if (urlData.created_at_end) {
        urlData.created_at_end = Date.parse(urlData.created_at_end);
      }
      if (urlData.due_date_start) {
        urlData.due_date_start = Date.parse(urlData.due_date_start);
      }
      if (urlData.due_date_end) {
        urlData.due_date_end = Date.parse(urlData.due_date_end);
      }
      if (urlData.appointment_date_from) {
        urlData.appointment_date_from = Date.parse(
          urlData.appointment_date_from
        );
      }
      if (urlData.appointment_date_to) {
        urlData.appointment_date_to = Date.parse(urlData.appointment_date_to);
      }
      delete urlData.status;
      const searchURL = changeURL(urlData);
      history.push(`?${searchURL}`);
    },
    [history]
  );

  const exportTicket = useMutation(
    (params: any) => ticketApi.exportTicket(params),
    {
      onSuccess: (response) => {
        if (response.status === 204) {
          toast({
            title: strings.no_data_found,
            status: 'error',
            isClosable: true,
          });
          return;
        }

        const headers = response.headers;
        const blob = new Blob([response.data], {
          type: headers['content-type'],
        });
        FileSaver.saveAs(
          blob,
          `Ticketliste-${DateFormat(new Date())}` + FILEEXTENSION
        );
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleExport = () => {
    const params: any = cleanData(filterParams);

    const {
      created_at_start,
      created_at_end,
      due_date_start,
      due_date_end,
      appointment_date_from,
      appointment_date_to,
    } = params;

    if (created_at_start)
      params.created_at_start = getUTCDateRangeStartDateTime(created_at_start);
    if (created_at_end)
      params.created_at_end = getUTCDateRangeEndDateTime(created_at_end);
    if (due_date_start)
      params.due_date_start = getDateRangeStartDateTime(due_date_start);
    if (due_date_end)
      params.due_date_end = getDateRangeEndDateTime(due_date_end);
    if (appointment_date_from)
      params.appointment_date_from = getDateRangeStartDateTime(
        appointment_date_from
      );
    if (appointment_date_to)
      params.appointment_date_to = getDateRangeEndDateTime(appointment_date_to);

    delete params.currentPage;
    delete params.pageSize;
    exportTicket.mutate(params);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleAccordionToggle = () => {
    setDefaultIndex((prevState) => (prevState === 0 ? -1 : 0));
  };

  const handleExternalCompanyExportToggle = () => {
    setAppointmentTodoExport('externalCompany');
    setDefaultIndex(0);
  };

  const handleAssigneeExportToggle = () => {
    setAppointmentTodoExport('assignee');
    setDefaultIndex(0);
  };

  useEffect(() => {
    let showExport = false;
    if (appointmentTodoExport === 'externalCompany') {
      showExport =
        !!filterParams.external_company_id &&
        !!filterParams.appointment_date_from &&
        !!filterParams.appointment_date_to;
    } else if (appointmentTodoExport === 'assignee') {
      showExport =
        !!filterParams.assigned_user_id &&
        !!filterParams.appointment_date_from &&
        !!filterParams.appointment_date_to;
    }
    setShowExportOption(showExport);
  }, [
    appointmentTodoExport,
    filterParams.external_company_id,
    filterParams.assigned_user_id,
    filterParams.appointment_date_from,
    filterParams.appointment_date_to,
  ]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.ticket} | {strings.ticket_list}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.ticket.list.default}>
              {strings.ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.ticket.list.default}>
              {strings.all_tickets}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.ticket_list}</Heading>

          <Stack direction="row">
            <Menu>
              <Tooltip hasArrow label={strings.todo_button_tooltip}>
                <MenuButton
                  size="sm"
                  as={Button}
                  colorScheme="primary"
                  rightIcon={<BiChevronDown />}>
                  {strings.todo_list}
                </MenuButton>
              </Tooltip>
              <MenuList>
                <Tooltip
                  hasArrow
                  placement="left"
                  label={strings.assignee_button_tooltip}>
                  <MenuItem onClick={handleAssigneeExportToggle}>
                    {strings.assignee}
                  </MenuItem>
                </Tooltip>
                <Tooltip
                  hasArrow
                  placement="left"
                  label={strings.external_company_button_tooltip}>
                  <MenuItem onClick={handleExternalCompanyExportToggle}>
                    {strings.external_company}
                  </MenuItem>
                </Tooltip>
              </MenuList>
            </Menu>

            <TicketAddMenu path={routes.ticket.list.create} />

            <Tooltip label={strings.download_excel} hasArrow>
              <Button
                size="sm"
                colorScheme="green"
                variant="outline"
                isLoading={exportTicket.isLoading}
                onClick={handleExport}>
                <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
              </Button>
            </Tooltip>
          </Stack>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          index={[defaultIndex]}>
          <AccordionItem>
            <h2>
              <AccordionButton onClick={handleAccordionToggle}>
                <Box flex="1" textAlign="center">
                  <Flex justify="space-between">
                    <Heading fontSize="16px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <TicketSearch
                handleAdvancedSearch={handleAdvancedSearch}
                filter={filterParams}
                exportAppointmentTodo={appointmentTodoExport}
                handleResetSearch={() => setAppointmentTodoExport(null)}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {showExportOption && (
          <ExportAppointmentTodo
            exportAppointmentTodo={appointmentTodoExport}
            externalCompanyId={filterParams.external_company_id}
            externalCompanyName={filterParams.company}
            assigneeId={filterParams.assigned_user_id}
            assigneeName={filterParams.assignee_name}
            appointmentDateFrom={DateFormatYMD(
              filterParams.appointment_date_from
            )}
            appointmentDateTo={DateFormatYMD(filterParams.appointment_date_to)}
          />
        )}

        <Stack sx={wrapperStyles}>
          {ticketList.isError && (
            <Alert status="error">
              <AlertIcon />
              {strings.ticket_list_error}
            </Alert>
          )}
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.ticket_title}</Th>
                  <Th>{strings.priority}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.assignee}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.due_date}</Th>
                  <Th>{strings.created_at}</Th>
                  <Th>{strings.ticket_source}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {ticketList?.data?.data?.map((ticket: any, index: number) => (
                  <TicketListItem
                    ticketData={ticket}
                    index={startingSN + index}
                    key={ticket.id}
                    search={search}
                  />
                ))}
                {ticketList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={12} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={ticketList}
        />
      </Stack>
    </>
  );
};

export default TicketList;
