import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FeedbackQuestionResource from 'api/feedback_question';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import QuestionListItem from 'components/feedback/QuestionsListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { PublishOptionType, PublishValueType } from 'constants/schema';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  changeURL,
  FeedbackQuestionPublishOptions,
  getStartingSerialNumber,
} from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  type: string;
  categories: PublishValueType[];
  status: string;
}

const InitialFilterValue: FilterParams = {
  currentPage: INITIAL_CURRENT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  keyword: '',
  type: '',
  categories: [],
  status: '',
};

const QuestionsList: React.FC = () => {
  const feedbackQuestionAPI = new FeedbackQuestionResource();
  const queryClient = useQueryClient();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const categoriesRef = useRef<any>(null);
  const PublishSelectOptions = FeedbackQuestionPublishOptions();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const categories_url: PublishValueType[] =
    searchValues?.categories?.split(',') ?? [];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    type: searchValues.type ?? '',
    categories: categories_url,
    status: searchValues.status ?? '',
  });

  const keyword = useDebounce(filterParams.keyword);

  const defaultPublishValue = filterParams?.categories?.map(
    (item: PublishValueType) => ({
      value: item,
      label: strings[item],
    })
  );

  const queryList = useQuery(
    [
      `feedbackQuestionsList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        type: filterParams.type,
        categories: filterParams.categories,
        status: filterParams.status,
      },
      keyword,
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.keyword = keyword;
      if (filterParams.type) queryParams.type = filterParams.type;
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.categories.length)
        queryParams.categories = filterParams.categories;
      let response = await feedbackQuestionAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data: any = {
      ...filterParams,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setFilterParams(InitialFilterValue);
    categoriesRef.current.select.clearValue();
    history.push(routes.feedback.questions.list);
  };

  /**
   * Handle filter params submit
   */

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination?.index === source?.index) return;
    const sourceId = queryList?.data?.data?.[source?.index].serial;
    const destinationId = queryList?.data?.data?.[destination?.index].serial;

    await feedbackQuestionAPI.updateSerialNumber(sourceId, destinationId);
    await queryClient.invalidateQueries('feedbackQuestionsList');
  };

  const handlePublishChange = (categories: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      categories: categories.map((item: PublishOptionType) => item.value),
    }));
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.feedback} | {strings.all_questions}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
              {strings.feedback}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.feedback.questions.list}>
              {strings.all_questions}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md">{strings.questions}</Heading>
          <RouterLink to={routes.feedback.questions.add}>
            <Button colorScheme="primary" size="sm">
              {strings.new_question}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form>
            <Stack direction="column" spacing="4">
              <Stack direction="column" spacing="4" align="start">
                <Grid
                  gap={['4', '4', '6']}
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(4, 1fr)',
                  ]}
                  w="100%">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <Input
                          name="keyword"
                          value={filterParams.keyword}
                          type="text"
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.type}</FormLabel>
                      <Select
                        name="type"
                        value={filterParams.type}
                        onChange={handleInputChange}
                        placeholder={strings.select_type}
                        rounded="sm">
                        <option value="open">{strings.open_ended}</option>
                        <option value="closed">{strings.closed_ended}</option>
                        <option value="rating">{strings.rating}</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.publish}</FormLabel>
                      <ReactSelect
                        name="categories"
                        isMulti
                        closeMenuOnSelect={false}
                        ref={categoriesRef}
                        placeholder={strings.select_publish}
                        rounded="sm"
                        defaultValue={defaultPublishValue}
                        styles={reactSelectStyles}
                        options={PublishSelectOptions}
                        onChange={handlePublishChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.status}</FormLabel>
                      <Select
                        name="status"
                        placeholder={strings.select_status}
                        value={filterParams.status}
                        onChange={handleInputChange}
                        rounded="sm">
                        <option value="1">{strings.active}</option>
                        <option value="0">{strings.deactivated}</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                </Grid>

                <Button
                  colorScheme="primary"
                  type="button"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.title}</Th>
                  <Th>{strings.publish}</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="feedbackQuestionList">
                  {(provided: any) => (
                    <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {!queryList.isLoading &&
                        queryList?.data?.data?.map(
                          (feedbackQuestion: any, index: number) => (
                            <Draggable
                              draggableId={feedbackQuestion.id.toString()}
                              key={feedbackQuestion.id}
                              index={index}>
                              {(provided: any) => (
                                <>
                                  <QuestionListItem
                                    data={feedbackQuestion}
                                    key={feedbackQuestion.id}
                                    index={startingSN + index}
                                    draggableProvider={provided}
                                  />
                                  {provided.placeholder}
                                </>
                              )}
                            </Draggable>
                          )
                        )}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
              {queryList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
              )}
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default QuestionsList;
