import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import ArticleTypesResource from 'api/article-types';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ArticleTypeSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const TypeDetails: React.FC = () => {
  const { id }: any = useParams();
  const { search } = useLocation();

  // API Resource
  const articleTypeApi = new ArticleTypesResource();

  // Access Control
  const checkDeleteTypePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.types.edit.replace(':id', id.toString())
  );

  const articleTypeQuery = useQuery<ArticleTypeSchema>(
    [`articleType${id}`, id],
    () => articleTypeApi.get(id).then((res) => res.data.data)
  );

  if (articleTypeQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (articleTypeQuery.isError) {
    history.push(routes.mms.types.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {articleTypeQuery?.data?.name}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.types.list + search}>
              {strings.type}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {articleTypeQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.type_details}
          </Heading>
          <Button
            colorScheme="primary"
            size="sm"
            type="button"
            onClick={checkDeleteTypePermission}>
            {strings.edit}
          </Button>
        </Flex>

        <Stack sx={wrapperStyles}>
          <Stack direction="column" spacing="4">
            <Grid gap={['3', '6']} templateColumns="repeat(1, 1fr)" w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.type_name}</FormLabel>
                  <Text sx={infoStyles}>{articleTypeQuery?.data?.name}</Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.description}</FormLabel>
                  <Text sx={infoStyles} wordBreak="break-all">
                    {articleTypeQuery?.data?.description}
                  </Text>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default TypeDetails;
