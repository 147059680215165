import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ExternalCompanyResource from 'api/external_company';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CompanyForm from 'components/external_company/CompanyForm';
import { strings } from 'config/localization';
import { EMAIL_ALREADY_TAKEN_MESSAGE } from 'constants/common';
import routes from 'constants/routes';
import { ExternalCompanySchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { unmaskPhone } from 'utils';

const EditCompany: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<ExternalCompanySchema>();

  const externalCompanyAPI = new ExternalCompanyResource();
  const [errorMessage, setErrorMessage] = useState<ExternalCompanySchema>();

  const externalCompanyQuery = useQuery(
    [`user${id}-edit`, id],
    () => externalCompanyAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateExternalCompany = useMutation((data: any) =>
    externalCompanyAPI.update(id, data)
  );

  /*
   *set error message if Email is already taken.
   */
  const { setError } = methods;

  useEffect(() => {
    if (errorMessage?.email?.includes(EMAIL_ALREADY_TAKEN_MESSAGE)) {
      setError(
        'email',
        {
          type: 'manual',
          message: strings.the_email_has_already_been_taken,
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [errorMessage, setError]);

  const onSubmit = (data: any) => {
    data.phone = unmaskPhone(data.phone);
    if (!data.email) data.email = null;
    updateExternalCompany.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: strings.company_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('companyList');
        history.push(routes.users.externalCompanies.list);
      },
      onError: (error: any) => {
        setErrorMessage(error?.response?.data?.errors);
      },
    });
  };

  if (externalCompanyQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (externalCompanyQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.company_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.user} | {strings.edit_company}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.users.externalCompanies.list}>
            {strings.external_companies}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {externalCompanyQuery?.data?.data?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.edit_company}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateExternalCompany.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.company_udpate_failed}
                </Alert>
              )}
              <CompanyForm companyData={externalCompanyQuery.data.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateExternalCompany.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditCompany;
