import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InvoiceResource from 'api/invoices';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CsvUpload from 'components/mdm/CsvUpload';
import InvoiceSearch from 'components/mdm/InvoiceSearch';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  INVOICES_CSV_UPLOAD_API,
  INVOICE_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  customer: any;
  apartment: any;
  due_date: any;
  invoice_date: any;
  cancelled_date: any;
  net_amount: any;
  total_amount: any;
  remainig_amount: any;
}

type LoadingType = 'filter' | 'reset' | '';

type QueryParamType = {
  page: number;
  limit: number;
};

const InvoicesList: React.FC = () => {
  let invoiceAPI = new InvoiceResource();

  const [refetch, setRefetch] = useState<number>(1);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    customer: null,
    apartment: null,
    due_date: null,
    invoice_date: null,
    cancelled_date: null,
    net_amount: null,
    total_amount: null,
    remainig_amount: null,
  });

  const invoiceListQuery = useQuery(
    [
      'listInvoices',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer: filterParams.customer,
        object: filterParams.apartment,
        due_date: filterParams.due_date,
        invoice_date: filterParams.invoice_date,
        cancelled_date: filterParams.cancelled_date,
        net_amount: filterParams.net_amount,
        total_amount: filterParams.total_amount,
        remainig_amount: filterParams.remainig_amount,
      },
      refetch,
    ],
    async () => {
      const queryParam: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await getInvoice(
        filterParams.currentPage,
        filterParams.pageSize
      );
      return response?.data?.data;
    }
  );
  const getInvoice = async (page: number, limit: number) => {
    let {
      apartment,
      customer,
      due_date,
      invoice_date,
      cancelled_date,
      net_amount,
      total_amount,
      remainig_amount,
    } = filterParams;

    let queryParams: any = {
      page,
      limit,
    };

    if (apartment) queryParams.object = apartment;
    if (customer) queryParams.customer = customer;
    if (due_date) queryParams.due_date = due_date;
    if (invoice_date) queryParams.invoice_date = invoice_date;
    if (cancelled_date) queryParams.cancelled_date = cancelled_date;
    if (net_amount) queryParams.net_amount = net_amount;
    if (total_amount) queryParams.total_amount = total_amount;
    if (remainig_amount) queryParams.remainig_amount = remainig_amount;

    return await invoiceAPI.list(queryParams);
  };
  const handleAdvancedSearch = useCallback((data: any, loader: LoadingType) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      apartment: data.apartment,
      customer: data.customer,
      due_date: data.due_date,
      invoice_date: data.invoice_date,
      cancelled_date: data.cancelled_date,
      net_amount: data.net_amount,
      total_amount: data.total_amount,
      remainig_amount: data.remainig_amount,
    }));
  }, []);
  let { isLoading } = invoiceListQuery;

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mdm} | {strings.all_invoices}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
            {strings.mdm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mdm.invoices.list}>
            {strings.all_invoices}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.invoices}
        </Heading>
        <CsvUpload
          url={INVOICES_CSV_UPLOAD_API}
          setRefetch={setRefetch}
          headerSchema={INVOICE_HEADER}
        />
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="16px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <InvoiceSearch handleAdvancedSearch={handleAdvancedSearch} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.id}</Th>
                <Th>{strings.number}</Th>
                <Th>{strings.customer}</Th>
                <Th>{strings.invoice_date}</Th>
                <Th>{strings.object}</Th>
                <Th>{strings.net}</Th>
                <Th>{strings.total}</Th>
                <Th>{strings.open}</Th>
                <Th>{strings.due_date}</Th>
                <Th>{strings.cancelled_on}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                invoiceListQuery?.data?.data.map(
                  ({
                    id,
                    number,
                    customer,
                    invoice_date,
                    object,
                    net_amount,
                    total_amount,
                    remainig_amount,
                    due_date,
                    cancelled_date,
                  }: any) => (
                    <Tr key={id}>
                      <Td>{id}</Td>
                      <Td>{number || '-'}</Td>
                      <Td>{customer || '-'}</Td>
                      <Td>{DateFormat(invoice_date) || '-'}</Td>
                      <Td>{object || '-'}</Td>
                      <Td>{net_amount || '-'}</Td>
                      <Td>{total_amount || '-'}</Td>
                      <Td>{remainig_amount || '-'}</Td>
                      <Td>{DateFormat(due_date) || '-'}</Td>
                      <Td>{DateFormat(cancelled_date) || '-'}</Td>
                    </Tr>
                  )
                )}
              {isLoading && <TableSkeletonLoader rows={7} cols={10} />}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={invoiceListQuery}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default InvoicesList;
