import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import DocumentDashboardResource from 'api/dms-dashboard';
import {
  DashAccordionButtonStyle,
  DashHeadingStyle,
  ObjectDashboardColumnStyle,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { CustomerDocumentEmailPayloadInterface } from 'constants/schema';
import { useDebounce } from 'hooks/useDebounce';
import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useMutation } from 'react-query';
import DocumentArea from './DocumentArea';

interface Props {
  resourceId: number;
  customerEmail: string;
}

const DocumentDash: React.FC<Props> = (props) => {
  const { resourceId, customerEmail } = props;
  const toast = useToast();
  const [selectedDocs, setSelectedDocs] = useState<number[]>([]);
  const [search, setSearch] = useState('');
  const keyword = useDebounce(search);

  const dashboardAPI = new DocumentDashboardResource();

  const emailMutation = useMutation(
    (data: CustomerDocumentEmailPayloadInterface) =>
      dashboardAPI.dashboardEmailDocuments(data)
  );

  const sendEmail = () => {
    const data: CustomerDocumentEmailPayloadInterface = {
      email: customerEmail,
      customer_document_id: selectedDocs,
    };

    emailMutation.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.email_sent_successfully}`,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: `${strings.failed_to_send_email}`,
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  return (
    <Stack sx={ObjectDashboardColumnStyle}>
      <Heading sx={DashHeadingStyle}>{strings.documents}</Heading>
      <FormControl>
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            children={<BiSearch />}
            color="gray.400"
          />
          <Input
            name="keyword"
            placeholder={strings.search}
            value={search}
            type="text"
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </InputGroup>
      </FormControl>
      <Accordion defaultIndex={[0]} width="100%">
        <AccordionItem bg="gray.50">
          <AccordionButton sx={DashAccordionButtonStyle}>
            <Text as="h4" fontWeight="medium" color="white">
              {strings.documents}
            </Text>
          </AccordionButton>
          <AccordionPanel>
            <Stack gridGap="10px">
              <DocumentArea
                resourceId={resourceId}
                keyword={keyword}
                setSelectedDocs={setSelectedDocs}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {selectedDocs.length > 0 && (
        <Stack alignSelf="flex-start">
          <Text color="black">
            {strings.stored_mail}: <strong>{customerEmail}</strong>
          </Text>
          <Button
            colorScheme="primary"
            variant="outline"
            onClick={sendEmail}
            isLoading={emailMutation.isLoading}>
            {strings.send_email}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default DocumentDash;
