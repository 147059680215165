import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import BakeryBillingResource from 'api/bakery_billing';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { capitalizeFirstLetter, formatPrice } from 'utils';
import { enumerateDaysBetweenDatesYMD } from 'utils/DateFormat';

interface Props {
  breadBookingData: any;
}

const BreadBookingBillingServiceInfoEdit: React.FC<Props> = (props) => {
  const { breadBookingData } = props;
  let {
    bakery_reservation_billing_particular: billingDetails,
    reservation,
    reservation_id,
  } = breadBookingData;

  const toast = useToast();
  const history = useHistory();

  const [changedValues, setChangedValues] = useState<any>([]);
  const [errMsg, setErrMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const bakeryAPI = new BakeryBillingResource();
  const updateBakeryParticular = useMutation((data: any) =>
    bakeryAPI.updateBilling(data)
  );

  const handleChange = (
    id: string,
    val: string | null,
    service_id: number,
    order_date: string,
    defaultValue: number
  ) => {
    if (Number(val) === defaultValue) {
      return;
    }
    let newData = [...changedValues];
    let index = newData.findIndex((item: any) => item.id === id);
    if (index === -1) {
      newData.push({ id, reconciled_quantity: val, service_id, order_date });
    } else {
      newData[index].reconciled_quantity = val;
    }
    setChangedValues(newData);
  };

  const onSubmit = () => {
    let formData = {
      reservation_id: reservation_id,
      billing_particulars: changedValues,
    };
    updateBakeryParticular.mutate(formData, {
      onSuccess: () => {
        toast({
          title: `${strings.bread_booking_billing} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(
          routes.bs.breadBookingBilling.details.replace(
            ':id',
            reservation_id.toString()
          )
        );
      },
      onError: () => {
        setErrMsg(
          `${strings.bread_booking_billing} ${strings.has_not_been_updated}`
        );
        setIsLoading(false);
      },
    });
  };

  let bookingPeriodDates = enumerateDaysBetweenDatesYMD(
    reservation?.bookings?.fromdate,
    reservation?.bookings?.tilldate
  );
  let services = [
    ...new Set(billingDetails.map((item: any) => item.service_id)),
  ];

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.service}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles} spacing="4">
        {errMsg && (
          <Alert status="error">
            <AlertIcon />
            {errMsg}
          </Alert>
        )}
        <TableContainer>
          <Table>
            <PerfectScrollbar>
              <Thead>
                <Tr pos="relative">
                  <Th fontWeight="bold" color="blackAlpha.900" pos="sticky">
                    {strings.bread_type}/{strings.booking_period}
                  </Th>
                  {bookingPeriodDates.length !== 0 &&
                    bookingPeriodDates.map((date: string) => (
                      <Th
                        key={date}
                        fontWeight="bold"
                        color="blackAlpha.900"
                        isNumeric>
                        {`${date.split('-')[2]}.${date.split('-')[1]}`}
                      </Th>
                    ))}
                  <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
                    {strings.total_pound}
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {services.map((service_number: any, index: number) => {
                  let serviceData = billingDetails.filter(
                    (item: any) => item.service_id === service_number
                  );
                  let sum = serviceData.reduce((acc: number, cur: any) => {
                    let qty = cur?.reconciled_quantity ?? cur?.order_quantity;
                    qty = qty || 0;
                    return acc + cur.unit_price * qty;
                  }, 0);
                  return (
                    <Tr
                      key={service_number}
                      pos="relative"
                      _hover={{ bg: 'gray.50' }}>
                      <Td pos="sticky">
                        {capitalizeFirstLetter(serviceData[0]?.service?.name)}
                      </Td>

                      {bookingPeriodDates.length !== 0 &&
                        bookingPeriodDates.map((day: string) => {
                          let breadQuantity = serviceData.find(
                            (item: any) => item.order_date === day
                          );
                          let key = `${day}-${service_number}`;
                          let qty =
                            breadQuantity?.reconciled_quantity ??
                            breadQuantity?.order_quantity;
                          let defaultVal = qty ? qty.toString() : '0';
                          return (
                            <Td key={key} isNumeric>
                              <NumberInput
                                min={0}
                                defaultValue={defaultVal}
                                onChange={(val: string) =>
                                  handleChange(
                                    key,
                                    val,
                                    service_number,
                                    day,
                                    defaultVal
                                  )
                                }>
                                <NumberInputField
                                  textAlign="center"
                                  minW="70px"
                                  maxW="100px"
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </Td>
                          );
                        })}
                      <Td isNumeric>{formatPrice(sum)} &euro;</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </PerfectScrollbar>
          </Table>
        </TableContainer>
        <Stack direction="row" spacing="4">
          <Button
            colorScheme="primary"
            onClick={onSubmit}
            isLoading={isLoading}>
            {strings.save_now}
          </Button>
          <Button
            color="gray.800"
            variant="outline"
            onClick={() => history.goBack()}>
            {strings.cancel}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default BreadBookingBillingServiceInfoEdit;
