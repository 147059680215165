import { FeedbackAction, FeedbackActionTypes } from 'actions/data/feedback';

const INITIAL_STATE = {
  customer: {
    value: null,
    label: '',
    isDisabled: null,
  },
  apartment: {
    customer: {
      id: null,
      is_intermixed: null,
      name: '',
      owner: true,
    },
    label: '',
    value: null,
  },
};

export default function (
  feedbackState = INITIAL_STATE,
  action: FeedbackAction
) {
  switch (action.type) {
    case FeedbackActionTypes.SET_CUSTOMER_DATA:
      return {
        ...feedbackState,
        customer: action.data,
      };
    case FeedbackActionTypes.SET_APARTMENT_DATA:
      return {
        ...feedbackState,
        apartment: action.data,
      };

    default:
      return feedbackState;
  }
}
