import { Box, Stack, Text } from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';

interface Props {
  customerInfo: any;
}
const ContactNotes: React.FC<Props> = (props) => {
  const { customerInfo } = props;

  const { internal_notes, external_notes } = customerInfo;

  return (
    <Stack width="100%" justifyContent="space-between">
      <Stack padding="1rem">
        <Text color="black" fontWeight="500">
          {strings.notes_internal}
        </Text>
        <Box p="10px" border="1px solid #EBECF2" borderRadius="10px">
          {internal_notes || '-'}
        </Box>
      </Stack>
      <Stack padding="1rem">
        <Text color="black" fontWeight="500">
          {strings.notes_external}
        </Text>
        <Box p="10px" border="1px solid #EBECF2" borderRadius="10px">
          {external_notes || '-'}
        </Box>
      </Stack>
    </Stack>
  );
};

export default ContactNotes;
