// import ObjectDash from 'components/dashboard/object/ObjectDash';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Stack,
} from '@chakra-ui/react';
import ObjectDropdown from 'components/object-dashboard/dashboard/object/ObjectDropdown';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import history from 'utils/history';
import Customer from './Customer';
import Objects from './Objects';

const ListDashboard: React.FC = () => {
  const { dashboard } = useParams<{ dashboard: string }>();
  const [title, setTitle] = useState('');

  if (dashboard !== 'object' && dashboard !== 'customer') {
    history.push('/');
    return null;
  }

  return (
    <Stack direction="column" spacing={5}>
      <Helmet>
        <title>
          {strings.dashboard} | {title ?? ''}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>Dashboard</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>
            {strings[dashboard]} {title ? `"${title}"` : ''}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack direction="row" alignItems="center" gridGap="4">
        <Heading
          fontWeight="500"
          fontFamily="heading"
          fontSize="28px"
          lineHeight="42px"
          size="md"
          textTransform="capitalize">
          {title}
        </Heading>

        <ObjectDropdown />
      </Stack>

      <Stack
        direction="row"
        spacing="5"
        padding={[3, 4]}
        overflowX="auto"
        height="100%">
        {dashboard === 'object' ? (
          <Objects setTitle={setTitle} />
        ) : (
          <Customer setTitle={setTitle} />
        )}
      </Stack>
    </Stack>
  );
};

export default ListDashboard;
