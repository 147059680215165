import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { getUserCallAnalysis } from 'api/contact-center';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ContactCenterUserListItem from 'components/contact-center/ContactCenterUserListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { ContactCenterUserListSchema } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import {
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  start_date: Date | null;
  end_date: Date | null;
}

const ContactCenterUserList: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const start_date = Number(searchValues.start_date);
  const end_date = Number(searchValues.end_date);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    start_date: start_date ? new Date(start_date) : null,
    end_date: end_date ? new Date(end_date) : null,
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      start_date: start,
      end_date: end,
    }));
  };

  const userListQuery = useQuery(
    [
      `contactCenterUserList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer_name: filterParams.keyword,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
      },
    ],
    async () => {
      const { keyword, start_date, end_date } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.keyword = filterParams.keyword;
      if (start_date)
        queryParams.called_date_from = getUTCDateRangeStartDateTime(start_date);
      if (end_date)
        queryParams.called_date_to = getUTCDateRangeEndDateTime(end_date);
      let response = await getUserCallAnalysis(queryParams);
      return response?.data;
    }
  );

  const { data: userList, isLoading } = userListQuery;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    const { start_date, end_date } = data;
    if (start_date) data.start_date = Date.parse(start_date);
    if (end_date) data.end_date = Date.parse(end_date);
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      start_date: null,
      end_date: null,
    }));
    history.push(routes.contactCenter.members.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.contact_center} | {strings.user}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.contactCenter.members.list}>
            {strings.contact_center}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.contactCenter.members.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.user}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="column" spacing="4">
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <Grid
                gap={['4', '4']}
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.search}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.400"
                      />
                      <Input
                        type="text"
                        name="keyword"
                        placeholder={strings.search}
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.date}</FormLabel>
                    <InputGroup>
                      <InputRightElement
                        pointerEvents="none"
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                      <ReactDatePicker
                        placeholderText={strings.select_date}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomDateInput />}
                        onChange={handleDateChange}
                        selected={filterParams.start_date}
                        startDate={filterParams.start_date}
                        endDate={filterParams.end_date}
                        selectsRange
                        value={getFormattedRangeDate(
                          filterParams.start_date,
                          filterParams.end_date
                        )}
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
              </Grid>
              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  type="button"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.aircall_user_name}</Th>
                <Th>{strings.total_incoming_call}</Th>
                <Th>{strings.incoming_call_duration}</Th>
                <Th>{strings.total_outgoing_call}</Th>
                <Th>{strings.outgoing_call_duration}</Th>
                <Th>{strings.average}</Th>
                <Th>{strings.total_duration}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userList?.data?.map(
                (item: ContactCenterUserListSchema, index: number) => (
                  <ContactCenterUserListItem
                    data={item}
                    key={item.id}
                    index={startingSN + index}
                  />
                )
              )}
              {isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={9} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={userListQuery}
      />
    </Stack>
  );
};

export default ContactCenterUserList;
