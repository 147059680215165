import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import DrivingOrderResource from 'api/driving_order';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { ExcelFile } from 'assets/icons';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import DrivingOrderListItem from 'components/bms_driving_order/DrivingOrderListItem';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  DRIVING_ORDER_HEADER,
  ENCODING_CODEPAGE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import { DrivingOrderSchema } from 'constants/schema';
import * as FileSaver from 'file-saver';
import React, { useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { BiChevronLeft, BiChevronRight, BiCloudUpload } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import checkHeaders from 'utils/checkHeaders';
import { DateFormat } from 'utils/DateFormat';
import XLSX from 'xlsx';

const acceptType =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';
interface FilterParams {
  currentPage: number;
  pageSize: number;
}

const DrivingOrderList: React.FC = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const fileElem: any = useRef(null);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const drivingOrderAPI = new DrivingOrderResource();
  const [totalDrivingOrder, setTotalDrivingOrder] = useState<
    number | undefined
  >(undefined);

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalDrivingOrder,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: drivingOrderList, isLoading: isDrivingOrderListLoading } =
    useQuery(
      [
        'drivingOrderList',
        {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
        },
      ],
      async () => {
        const queryParams: any = {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
        };

        const response = await drivingOrderAPI.list(queryParams);
        setTotalDrivingOrder(response?.data?.meta?.total);
        return response?.data?.data;
      }
    );

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: nextPage,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const addDrivingOrder = useMutation(
    (data: any) => drivingOrderAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.import_driving_success,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('drivingOrderList');
      },
      onError: (error: any) => {
        toast({
          title: strings.import_driving_failed,
          status: 'error',
          isClosable: true,
        });
        queryClient.invalidateQueries('drivingOrderList');
      },
    }
  );

  const handleImport = async () => {
    let formData = new FormData();
    const file = fileElem.current.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = async function () {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, {
        type: 'binary',
        codepage: Number(ENCODING_CODEPAGE),
        cellDates: true,
      });
      const sheetNameList = workbook.SheetNames;

      // check if the uploaded filess contains required headers (columns)
      const isValid = checkHeaders(
        workbook.Sheets[sheetNameList[0]],
        DRIVING_ORDER_HEADER
      );
      if (!isValid) {
        toast({
          title: strings.cannot_upload_csv_in_worng_category,
          status: 'error',
          isClosable: true,
        });
      } else {
        if (file) formData.append('file', file);
        addDrivingOrder.mutate(formData);
      }
    };
    reader.readAsBinaryString(file);
  };

  const onDragEnd = async (result: any) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination?.index === source?.index) return;
    const sourceId = drivingOrderList[source?.index].serial_number;
    const destinationId = drivingOrderList[destination?.index].serial_number;

    await drivingOrderAPI.updateSerialNumber(sourceId, destinationId);
    await queryClient.invalidateQueries('drivingOrderList');
  };

  const handleDownload = async () => {
    let response: any;
    let today = new Date();
    const title: string = `Fahrstrecke_${DateFormat(today)}.xlsx`;

    response = await drivingOrderAPI.exportAll();

    if (response.data) {
      let headers = response.headers;
      let blob = new Blob([response.data], { type: headers['content-type'] });
      FileSaver.saveAs(blob, title);
    }
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bs} | {strings.all_driving_order}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bs}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.bs.drivingOrder.list}>
              {strings.all_driving_order}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.import_driving_order}
          </Heading>
          <Stack direction="row" spacing="4" align="end">
            <Tooltip label={strings.download_excel} hasArrow>
              <Button
                size="sm"
                colorScheme="green"
                variant="outline"
                onClick={() => handleDownload()}>
                <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
              </Button>
            </Tooltip>
            <input
              name="file"
              type="file"
              ref={fileElem}
              multiple
              accept={acceptType}
              onChange={handleImport}
              style={{ display: 'none' }}
            />

            <Button
              size="sm"
              onClick={() => {
                fileElem.current && fileElem.current.click();
              }}
              variant="solid"
              rounded="sm"
              colorScheme="primary"
              aria-label="Import"
              leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
              __hover={{ variant: 'solid', bg: 'primary', color: 'white' }}>
              {strings.import_file}
            </Button>
          </Stack>
        </Flex>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.address}</Th>
                </Tr>
              </Thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="DrivingOrderListTable">
                  {(provided: any) => (
                    <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {!isDrivingOrderListLoading &&
                        drivingOrderList?.map(
                          (
                            drivingOrderData: DrivingOrderSchema,
                            index: number
                          ) => (
                            <Draggable
                              draggableId={drivingOrderData.id.toString()}
                              key={drivingOrderData.id}
                              index={index}>
                              {(provided: any) => (
                                <>
                                  <DrivingOrderListItem
                                    drivingOrderData={drivingOrderData}
                                    key={drivingOrderData.id}
                                    index={startingSN + index}
                                    draggableProvider={provided}
                                  />
                                  {provided.placeholder}
                                </>
                              )}
                            </Draggable>
                          )
                        )}
                      {isDrivingOrderListLoading && (
                        <TableSkeletonLoader
                          rows={filterParams.pageSize}
                          cols={3}
                        />
                      )}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </Stack>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isDrivingOrderListLoading}
              totalCount={totalDrivingOrder}
              resultCount={drivingOrderList?.length}
              pageSize={filterParams.pageSize}
              currentPage={filterParams.currentPage}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {drivingOrderList?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.pageSize}
                setPageSize={(value) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    currentPage: INITIAL_CURRENT_PAGE,
                    pageSize: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isDrivingOrderListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.currentPage}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default DrivingOrderList;
