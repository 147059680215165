import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MembershipLevelResource from 'api/membership-level';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import MembershipLevelForm from 'components/third_party_partner/MembershipLevelForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

const AddMembershipLevel: React.FC = () => {
  const { id: membershipLevelId }: any = useParams();
  const { search } = useLocation();

  const methods = useForm<any>();
  const toast = useToast();
  const history = useHistory();
  const membershipLevelAPI = new MembershipLevelResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: membershipLevelDetail } = useQuery(
    ['membership-level-detail-page', membershipLevelId],
    () => {
      if (!membershipLevelId) return null; // { data: null };
      return membershipLevelAPI
        .get(membershipLevelId)
        .then((res) => res.data.data)
        .catch((err) => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  );
  const createMembershipLevel = useMutation(
    (data: any) => membershipLevelAPI.store(data),
    {
      onSuccess: (res: any) => {
        const id = res?.data?.data?.id;
        history.push(
          routes.thirdPartyPartner.membershipLevel.details.replace(
            ':id',
            id.toString()
          )
        );
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const updateMembershipLevel = useMutation(
    (data: any) => membershipLevelAPI.update(membershipLevelId, data),
    {
      onSuccess: () => {
        history.push(
          routes.thirdPartyPartner.membershipLevel.details.replace(
            ':id',
            membershipLevelId.toString()
          )
        );
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const onSubmit = async (formData: any) => {
    let API;
    const data = new FormData();

    if (formData.icons && formData.icons.size > 0) {
      data.append('icon', formData.icons);
    }
    data.append('name', formData.name);
    if (formData.description) data.append('description', formData.description);
    if (formData.brief_description)
      data.append('brief_description', formData.brief_description);

    data.append('is_active', formData.is_active ? '1' : '0');
    data.append('booking_discount', formData.booking_discount || 0);
    data.append(
      'final_cleaning_discount',
      formData.final_cleaning_discount || 0
    );
    data.append('laundry_discount', formData.laundry_discount || 0);
    data.append(
      'website_condition_discount',
      formData.website_condition_discount || 0
    );

    // Append each ID in partner_goodies_ids array individually
    if (
      formData.partner_goodies_ids &&
      formData.partner_goodies_ids.length > 0
    ) {
      if (Array.isArray(formData.partner_goodies_ids)) {
        formData.partner_goodies_ids.forEach((id: any) => {
          data.append('partner_goodies_ids[]', String(id));
        });
      } else {
        // If it's not an array, assume it's a single ID and append it
        data.append(
          'partner_goodies_ids[]',
          String(formData.partner_goodies_ids)
        );
      }
    }

    if (membershipLevelId) {
      API = updateMembershipLevel;
      data.append('_method', 'PUT');
    } else {
      API = createMembershipLevel;
    }
    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.membership_level} ${
            membershipLevelId
              ? strings.has_been_updated
              : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(
          `${strings.membership_level} ${
            membershipLevelId
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  if (membershipLevelDetail?.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bms} | &nbsp;
            {membershipLevelId
              ? strings.edit_member_level
              : strings.add_new_member_level}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.thirdPartyPartner.membershipLevel.list + search}>
              {strings.membership}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {membershipLevelId
                ? strings.edit_member_level
                : strings.add_new_member_level}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {membershipLevelId
              ? strings.edit_member_level
              : strings.add_new_member_level}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {errMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errMsg}
                  </Alert>
                )}
                <MembershipLevelForm data={membershipLevelDetail} />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {!membershipLevelId ? strings.add_now : strings.update}
                  </Button>
                  <Button
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddMembershipLevel;
