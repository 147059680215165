import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ExternalCompanyResource from 'api/external_company';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CompanyListItem from 'components/external_company/CompanyListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const ExternalCompanyList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const externalCompanyAPI = new ExternalCompanyResource();
  let { search } = useLocation();
  let history = useHistory();
  let searchParams = new URLSearchParams(search);
  let url_keyword = searchParams.get('keyword');

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: url_keyword ?? '',
  });

  const companyList = useQuery(
    [
      'companyList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      const response = await externalCompanyAPI.list(queryParams);
      response && setIsLoading(false);
      return response?.data;
    }
  );

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
    history.push(routes.users.externalCompanies.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  return (
    <>
      <Helmet>
        <title>
          {strings.user} | {strings.all_companies}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.user}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.all_companies}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.companies}
          </Heading>

          <RouterLink to={routes.users.externalCompanies.add}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.add_new_company}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="keyword"
                    value={filterParams.keyword}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>

              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.email}</Th>
                  <Th isNumeric>{strings.phone}</Th>

                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!companyList.isLoading &&
                  companyList?.data?.data?.map(
                    (companyData: any, index: number) => (
                      <CompanyListItem
                        companyData={companyData}
                        key={companyData.id}
                        index={startingSN + index}
                        search={search}
                      />
                    )
                  )}
                {companyList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={companyList}
        />
      </Stack>
    </>
  );
};

export default ExternalCompanyList;
