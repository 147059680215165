import {
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { PartnerGoodiesSchema } from 'constants/schema';
import React, { useState } from 'react';
import PartnerGoodiesListItem from './PartnerGoodiesListItem';
import PartnerGoodiesModal from './PartnerGoodiesModal';

interface Props {
  data?: PartnerGoodiesSchema[];
  thirdPartyPartnerId: string;
}
const PartnerGoodiesList = ({ data, thirdPartyPartnerId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setOpenCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <Stack sx={wrapperStyles}>
      <Flex justify="space-between">
        <Heading size="sm" textTransform="capitalize">
          {strings.extra_goodies}
        </Heading>

        <Button
          size="sm"
          colorScheme="primary"
          type="button"
          onClick={setOpenCloseModal}>
          {strings.add_extra_goodies}
        </Button>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>{strings.extra_goodies}</Th>
            <Th>{strings.description}</Th>
            <Th>{strings.status}</Th>
            <Th>{strings.actions}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((da: PartnerGoodiesSchema) => (
            <PartnerGoodiesListItem
              partnerGoodiesData={da}
              key={da.id}
              thirdPartyPartnerId={thirdPartyPartnerId}
            />
          ))}
        </Tbody>
        <PartnerGoodiesModal
          isOpen={isModalOpen}
          handleCancel={setOpenCloseModal}
          thirdPartyPartnerId={thirdPartyPartnerId}
        />
      </Table>
    </Stack>
  );
};

export default PartnerGoodiesList;
