import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { deteleIconStyles } from 'assets/css/commonStyles';
import { QRScan } from 'assets/icons';
import QRScanDetailPDF from 'components/qr-scan/QRScanDetailPDF';
import { strings } from 'config/localization';
import { QRCodeSVG } from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { useReactToPrint } from 'react-to-print';

interface Props {
  shelf: any;
  viewOnly: boolean;
  showQR: boolean;
  warehouseName?: string;
  warehouseId?: any;
  rack: any;
  room: any;
  handleDeleteEntity?: (
    entity: string,
    detail: {
      generatedRoomId: string;
      rackName?: string;
      shelfName?: string;
      id?: number; // roomId/rackId/shelfId based on entity (only on edit mode)
    }
  ) => void;
}

const WarehouseShelf: React.FC<Props> = (props) => {
  const {
    shelf,
    rack,
    viewOnly,
    warehouseName,
    warehouseId,
    showQR,
    room,
    handleDeleteEntity,
  } = props;

  const printRef: any = useRef();
  const [isQRModelOpen, setIsQRModelOpen] = useState(false);

  const shelfQRName: string = `${warehouseName} / ${room.name} / ${rack.name} / ${shelf.name}`;

  const shelfQRIds = {
    warehouse: warehouseId,
    room: room.id,
    rack: rack.id,
    shelf: shelf.id,
  };
  const shelfQRData = JSON.stringify(shelfQRIds);

  const qrSignature = [
    {
      value: shelfQRData,
      title: shelfQRName,
    },
  ];

  const handleDelete = () => {
    handleDeleteEntity !== undefined &&
      handleDeleteEntity(strings.delete_shelf, {
        generatedRoomId: room.generatedId,
        rackName: rack.name,
        shelfName: shelf.name,
        id: shelf.id,
      });
  };

  const saveQRScan = useReactToPrint({
    content: () => printRef.current,
    pageStyle:
      '* {background-color:white !important} @page { size:portrait !important} ',
    documentTitle: `${shelfQRName} QR Code`,
    onAfterPrint: () => setIsQRModelOpen(false),
  });

  return (
    <>
      <Flex
        w="100%"
        p={3}
        direction="row"
        justifyContent="space-between"
        rounded="sm"
        role="group"
        _hover={{ bg: 'gray.200' }}>
        <Text>
          {strings.shelf} {shelf.name}
        </Text>
        <Flex display="none" _groupHover={{ display: 'flex' }}>
          {showQR && (
            <Tooltip hasArrow label={strings.qr_code}>
              <Button
                variant="link"
                minW="6"
                onClick={() => {
                  setIsQRModelOpen(true);
                }}>
                <Image src={QRScan} alt="QR Scan Button" w="5" />
              </Button>
            </Tooltip>
          )}
          {!viewOnly && rack.shelves.length !== 1 && (
            <Tooltip
              hasArrow
              label={strings.delete_shelf}
              fontSize="sm"
              bg="red.300"
              color="white">
              <IconButton
                icon={<BiTrash />}
                variant="link"
                aria-label={strings.delete_shelf}
                sx={deteleIconStyles}
                onClick={handleDelete}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
      <Modal
        isOpen={isQRModelOpen}
        isCentered
        size="xl"
        onClose={() => setIsQRModelOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="18px">
              {strings.warehouse} {strings.qr_code}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction="column"
              alignItems="center"
              border="1px solid black"
              p={4}>
              <QRCodeSVG value={shelfQRData} height="80%" width="80%" />
              <Text color="grey.700" fontSize="24px">
                {shelfQRName}
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="primary"
                type="button"
                leftIcon={<AiOutlinePrinter />}
                onClick={saveQRScan}>
                {strings.print_qr}
              </Button>
              <Button
                variant="outline"
                colorScheme="primary"
                type="button"
                onClick={() => setIsQRModelOpen(false)}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div style={{ display: 'none' }}>
        <QRScanDetailPDF qrSignature={qrSignature} ref={printRef} />
      </div>
    </>
  );
};

export default WarehouseShelf;
