import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import CallbacksResource from 'api/callbacks';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { DateFormat } from 'utils/DateFormat';

const CallbackDetail: React.FC = () => {
  const { id: callbackID }: any = useParams();

  const { search } = useLocation();
  const history = useHistory();
  const toast = useToast();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');

  const CallbackAPI = new CallbacksResource();

  const {
    data: callbackDetails,
    isLoading,
    isError,
  } = useQuery([`callback-details-${callbackID}`], () =>
    CallbackAPI.get(callbackID).then((res) => {
      return res.data.data;
    })
  );

  const deleteCallback = useMutation((id: number) => CallbackAPI.destroy(id), {
    onSuccess: () => {
      setDeleteModalOpen(false);
      toast({
        position: 'bottom',
        title: strings.success,
        description: strings.callback_deleted_successfully,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      history.push(routes.bms.callback.list + search);
    },
    onError: (error: any) => {
      setDeleteErrorMsg(error?.response?.data?.error?.message);
    },
  });

  const handleDelete = () => {
    deleteCallback.mutate(callbackID);
  };

  const handleDeleteModal = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(false);
  };

  if (isLoading) {
    return <CenterSpinner />;
  } else if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        {strings.no_callback_details_found}
      </Alert>
    );
  }

  let callbackDate: string = DateFormat(callbackDetails?.callback_date) ?? '-';
  let callbackTimeFrom = callbackDetails?.callback_time?.substring(0, 5) ?? '-';
  let callbackTimeTo =
    callbackDetails?.callback_time_nxt?.substring(0, 5) ?? '-';

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.bms} | {callbackDetails?.first_name}&nbsp;
          {callbackDetails?.last_name}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.bms.callback.list}>
            {strings.bms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.bms.callback.list + search}>
            {strings.callbacks}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {callbackDetails?.first_name} {callbackDetails?.last_name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.callback_detail}
          </Heading>
          <Button colorScheme="red" size="sm" onClick={handleDeleteModal}>
            {strings.delete}
          </Button>
          <Modal
            isOpen={isDeleteModalOpen}
            isCentered
            onClose={handleCancelDelete}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {strings.delete} {strings.callback}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {deleteErrorMsg && (
                  <Alert status="error" mb={2}>
                    <AlertIcon />
                    {deleteErrorMsg}
                  </Alert>
                )}
                <Text color="heading">{strings.confirm_delete}</Text>
                <Text color="heading">{`${strings.name}: ${callbackDetails?.first_name} ${callbackDetails?.last_name}`}</Text>
                <Text color="heading">{`${strings.callback_date}: ${callbackDate}`}</Text>
                <Text color="heading">{`${strings.time}: ${callbackTimeFrom} / ${callbackTimeTo}`}</Text>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <AccessControl
                    allowedPermissions={[PermissionRequest['read:callbacks']]}
                    renderNoAccess={(data: any) => data}>
                    <Button
                      colorScheme="red"
                      onClick={handleDelete}
                      isLoading={deleteCallback.isLoading}>
                      {strings.delete}
                    </Button>
                  </AccessControl>
                  <Button
                    variant="outline"
                    onClick={handleCancelDelete}
                    isDisabled={deleteCallback.isLoading}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>

        <Stack sx={wrapperStyles}>
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles} fontWeight="bold">
                  {strings.salutation}:
                </FormLabel>
                <Box sx={infoStyles}>
                  {callbackDetails?.title === 1 && strings.mr}
                  {callbackDetails?.title === 2 && strings.mrs}
                  {callbackDetails?.title === 3 && strings.misc}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles} fontWeight="bold">
                  {strings.first_name}:
                </FormLabel>
                <Box sx={infoStyles}>{callbackDetails?.first_name ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles} fontWeight="bold">
                  {strings.last_name}:
                </FormLabel>
                <Box sx={infoStyles}>{callbackDetails?.last_name ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles} fontWeight="bold">
                  {strings.email}:
                </FormLabel>
                <Box sx={infoStyles}>{callbackDetails?.email ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles} fontWeight="bold">
                  {strings.phone_number}:
                </FormLabel>
                <Box sx={infoStyles}>
                  {callbackDetails?.phone_number ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles} fontWeight="bold">
                  {strings.callback_date}:
                </FormLabel>
                <Box sx={infoStyles}>{callbackDate}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles} fontWeight="bold">
                  {strings.time}:
                </FormLabel>
                <Box sx={infoStyles}>
                  {callbackTimeFrom}
                  {' / '}
                  {callbackTimeTo}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
          <FormControl>
            <FormLabel sx={labelStyles} fontWeight="bold">
              {strings.inquiry}:
            </FormLabel>
            <Box sx={infoStyles}>{callbackDetails?.inquiry ?? '-'}</Box>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CallbackDetail;
