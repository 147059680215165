import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PMSPaymentLinkAPI from 'api/pms_payment_link';
import { wrapperStyles } from 'assets/css/commonStyles';
import CreatePaymentLinkForm from 'components/pms/CreatePaymentLinkForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const CreatePaymentLink: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const pmsPaymentLinkAPI = new PMSPaymentLinkAPI();
  const methods = useForm<any>();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const [errorMessage, setErrorMessage] = useState('');

  const createPaymentLink = useMutation(
    (data: any) => pmsPaymentLinkAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.payment_link_send_successfully,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries(`paymentLinksList`);
        history.push(routes.pms.paymentLinks.list);
      },
      onError: (error: any) => {
        setErrorMessage(strings.payment_link_sending_failed);
      },
    }
  );
  const onSubmit = (data: any) => {
    const formData = { ...data };
    formData.reservation_id = data?.reservation_id?.id;
    createPaymentLink.mutate(formData);
  };

  return (
    <>
      <Stack direction="column" spacing="4" minHeight="90vh">
        <Helmet>
          <title>
            {strings.pms} | {strings.create_payment_links}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.pms.creditCard.list}>
              {strings.pms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.pms.paymentLinks.list}>
              {strings.payment_links}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.pms.paymentLinks.add}>
              {strings.create_payment_links}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.create_payment_links}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form}>
              <Stack direction="column" spacing="4">
                {createPaymentLink.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                    {!errorMessage && strings.payment_link_sending_failed}
                  </Alert>
                )}
                <CreatePaymentLinkForm />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="button"
                    onClick={methods.handleSubmit(onSubmit)}>
                    {strings.send_payment_link}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default CreatePaymentLink;
