import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { selectColorScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReservationBooking } from 'constants/schemas/reservationBooking';
import React from 'react';
import { BiEdit, BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';
interface Props {
  breadBookingData: ReservationBooking;
  index: number;
  search?: any;
}
const BreadBookingListItem: React.FC<Props> = (props) => {
  const { breadBookingData, index, search } = props;
  let status: 'confirmed' | 'cancelled' | 'pending' = breadBookingData?.status;

  let bookingServices = breadBookingData?.bookings?.map((booking: any) => {
    return booking.service_name;
  });
  const serviceName = () => {
    let name = '-';
    if (bookingServices.length > 0) {
      let nameList = bookingServices.join(', ');
      name = truncateString(nameList, 15) ?? '-';
    }
    return name;
  };

  return (
    <Tr key={breadBookingData.id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.bs.breadBooking.details.replace(
              ':id',
              breadBookingData.id.toString()
            ) + search
          }>
          {breadBookingData?.booking_date}
        </RouterLink>
      </Td>
      <Td>{breadBookingData?.reservation?.apartment?.apartment_name || '-'}</Td>
      <Td>{breadBookingData?.reservation?.booking_number || '-'}</Td>
      <Td title={`${bookingServices.join(', ')}`}>{serviceName()}</Td>
      <Td>
        <Text
          color={selectColorScheme(status)}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {strings[breadBookingData?.status] ?? '-'}
        </Text>
      </Td>
      <Td>
        <RouterLink
          to={
            routes.bs.breadBooking.details.replace(
              ':id',
              breadBookingData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_bread_booking}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_bread_booking}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={
            routes.bs.breadBooking.edit.replace(
              ':id',
              breadBookingData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.edit_bread_booking}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_bread_booking}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default BreadBookingListItem;
