import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import InternetStatus from 'components/alert/InternetStatus';
import ChatRegister from 'components/chat/ChatRegister';
import Aircall from 'components/contact-center/Aircall';
import Sidebar from 'components/layout/Sidebar';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useChatUserInfo from 'hooks/query-hooks/useChatUserInfo';
import useIsPermitted from 'hooks/usIsPermitted';
import React from 'react';
import { useLocation } from 'react-router-dom';
import PrivateRouter from 'router/PrivateRouter';

const fullScreenRoutes = [routes.secondScreen];

const Dashboard: React.FC = () => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1140px)');

  const { status } = useChatUserInfo();

  const hasUserChatPermission: boolean = useIsPermitted([
    PermissionRequest['show:chat-assign'],
  ]);

  const location = useLocation();
  if (fullScreenRoutes.includes(location.pathname)) {
    return <PrivateRouter />;
  }

  return (
    <Flex maxH="100vh" overflow="hidden" position="relative">
      <Aircall />
      {hasUserChatPermission && status && <ChatRegister />}
      <Sidebar />
      <Box overflow="auto" flex="1">
        <Box
          p={isLargerThan1280 ? 8 : 4}
          minH="100vh"
          className="private-router-wrapper">
          <PrivateRouter />
        </Box>
      </Box>
      <InternetStatus />
    </Flex>
  );
};

export default Dashboard;
