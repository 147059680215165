import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { ENCODING_CODEPAGE } from 'constants/common';
import useFileUpload from 'hooks/useFileUpload';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BiCloudUpload } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import checkHeaders from 'utils/checkHeaders';
import XLSX from 'xlsx';

const acceptType =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';
interface Props {
  url: string;
  setRefetch?: Function;
  headerSchema: string[];
}

const CsvUpload: React.FC<Props> = (props) => {
  let { url, setRefetch, headerSchema } = props;

  const { id: userID } = useSelector((state: any) => state.data.auth.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [file, setFile] = useState<any>(null);
  const uploadRef = useRef<any>(null);
  const { loading, result, uploadPercent, error, setFormdata, setUploadFile } =
    useFileUpload(url);

  useEffect(() => {
    if (!isOpen && uploadRef) {
      if (uploadRef.current) uploadRef.current.value = null;
    }
  }, [isOpen]);

  useEffect(() => {
    if (!result) return;
    if (result.status === 200) {
      onClose();
      if (setRefetch) setRefetch((prev: number) => prev + 1);
      toast({
        title: strings.file_uploaded_successfully,
        status: 'success',
        isClosable: true,
      });
    }
  }, [onClose, result, setRefetch, toast]);

  const handleChange = (e: any) => {
    let fs = e.target.files[0];
    if (!fs) return;
    const fileSize = fs.size / 1024 / 1024;
    if (fileSize > 15) {
      toast({
        title: strings.max_upload_size_15,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async function () {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, {
        type: 'binary',
        codepage: Number(ENCODING_CODEPAGE),
        cellDates: true,
      });
      const sheetNameList = workbook.SheetNames;

      // check if the uploaded filess contains required headers (columns)
      const isValid = checkHeaders(
        workbook.Sheets[sheetNameList[0]],
        headerSchema
      );
      if (!isValid) {
        toast({
          title: strings.cannot_upload_csv_in_worng_category,
          status: 'error',
          isClosable: true,
        });
      } else {
        const fullFileName: string[] = fs.name.split('.');
        const fileExtension = fullFileName.pop();
        const fileName = fullFileName.join('_');
        const file = new File(
          [fs],
          fileName + '_' + Date.now().toString() + '.' + fileExtension,
          {
            type: fs.type,
          }
        );
        setFile(file);
        setUploadFile(file);

        const formdata = new FormData();
        if (file) formdata.append('file', file);
        formdata.append('uploaded_by', `${userID}`);
        setFormdata(formdata);
        onOpen();
      }
    };
    reader.readAsBinaryString(fs);
  };
  const openUploadDialogue = () => {
    if (uploadRef) {
      uploadRef.current.click();
    }
  };
  const handleModalClose = () => {
    onClose();
  };

  return (
    <Fragment>
      <div>
        <Button
          colorScheme="primary"
          leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
          onClick={openUploadDialogue}
          size="sm">
          {strings.upload_csv}
        </Button>
        <form>
          <input
            type="file"
            name="uploadCSV"
            hidden
            ref={uploadRef}
            accept={acceptType}
            onChange={handleChange}
          />
        </form>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.upload_file}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify="space-between">
              <Text>{file && file.name}</Text>
              {loading && <a>{strings.cancel}</a>}
            </Flex>
            <Progress hasStripe value={uploadPercent} />
          </ModalBody>

          <ModalFooter justifyContent="center">
            {loading && uploadPercent !== 100 && (
              <Text>{strings.uploading} ....</Text>
            )}
            {loading && uploadPercent === 100 && (
              <Stack direction="row" align="center">
                <Spinner />
                <Text>{strings.processing_data} ....</Text>
              </Stack>
            )}
            {!loading && result?.status === 200 && <Text>{strings.done}</Text>}
            {!loading && error && (
              <Text>
                !Error:
                {error.status >= 500
                  ? strings.internal_server_error
                  : error.data.error?.message}
              </Text>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default CsvUpload;
