import { FormControl, Switch, Td, Tr } from '@chakra-ui/react';
import { toggleUserStatus } from 'api/chat';
import CustomTagTitle from 'components/CustomTagTitle';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

interface Props {
  data: any;
  startingSN: number;
}
const ChatMetricListItem: React.FC<Props> = (props) => {
  const { data, startingSN } = props;
  const {
    _id,
    status,
    name,
    in_progress_chat,
    number_of_chats,
    number_of_messages_received,
    number_of_messages_sent,
    total_call_duration,
    user_id,
  } = data;

  const [localStatus, setLocalStatus] = useState<boolean>(status);

  const handleUserStatus = async (userID: any) => {
    setLocalStatus((prev) => !prev);
    toggleUserStatus(userID)
      .then((res) => {
        setLocalStatus(res.data.status);
      })
      .catch((err) => {
        setLocalStatus(status);
      });
  };

  return (
    <>
      <Tr key={_id}>
        <Td>{startingSN}</Td>
        <Td>
          <Link to={routes.users.profile.replace(':id', user_id)}>
            <AccessControl
              allowedPermissions={[PermissionRequest['read:user']]}
              renderNoAccess={(data: any) => data}>
              <CustomTagTitle type="text" status={localStatus} name={name} />
            </AccessControl>
          </Link>
        </Td>
        <Td>
          <FormControl display="flex" alignItems="center">
            <Switch
              colorScheme="primary"
              isChecked={!!localStatus}
              onChange={() => handleUserStatus(user_id)}
            />
          </FormControl>
        </Td>
        <Td isNumeric>{in_progress_chat}</Td>
        <Td isNumeric>{number_of_chats}</Td>
        <Td isNumeric>{number_of_messages_received}</Td>
        <Td isNumeric>{number_of_messages_sent}</Td>
        <Td isNumeric>{total_call_duration ?? '-'}</Td>
      </Tr>
    </>
  );
};

export default ChatMetricListItem;
