import { Button } from '@chakra-ui/button';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import RMSObjectsResource from 'api/rms-objects';
import Uploader from 'components/common/Uploader';
import { strings } from 'config/localization';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';

type FormType = {
  objecktImages: any;
};

function ObjektAddImages() {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const objectAPI = new RMSObjectsResource();

  const params = useParams<{ id: string }>();
  const apartmentId = Number(params.id);

  const queryClient = useQueryClient();

  const methods = useForm<FormType>();

  const mutation = useMutation({
    mutationFn: (data: FormData) =>
      objectAPI.uploadObjectImages({
        apartmentId,
        data,
      }),
    onSuccess: () => {
      toast({
        title: strings.successfully_added_images,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries(`apartmentDetails-${apartmentId}`);
      onClose();
    },
    onError: () => {
      toast({
        title: strings.failed_to_upload_images,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const onSubmit: SubmitHandler<FormType> = (data) => {
    if (!data.objecktImages) return;
    const formData = new FormData();
    const images = data.objecktImages;
    for (let i = 0; i < images.length; i++) {
      formData.append('images[]', images[i]);
    }
    mutation.mutate(formData);
  };

  return (
    <>
      <Button bg="primary.400" colorScheme="primary" onClick={onOpen} size="sm">
        {strings.add_images}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.add_images}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Uploader
                  fileKey="objecktImages"
                  required={true}
                  width="100%"
                  maxFileSize={30}
                  title={strings.drag_icon_or_click_to_select_icon}
                  acceptFileType="image/*"
                />
                <Flex justifyContent="flex-end" my={4}>
                  <Button
                    colorScheme="primary"
                    mr={3}
                    type="submit"
                    disabled={mutation.isLoading}
                    isLoading={mutation.isLoading}>
                    {strings.upload_image}
                  </Button>
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={onClose}>
                    {strings.cancel}
                  </Button>
                </Flex>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ObjektAddImages;
