import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import * as stockActivitiesAPI from 'api/stock-activities';
import {
  activeStyles,
  normalStyles,
  separatorStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { ExcelFile } from 'assets/icons';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
  usePaginator,
} from 'chakra-paginator';
import PageLimitChanger from 'components/common/PageLimitChanger';
import ResultCountDisplay from 'components/common/ResultCountDisplay';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import TransferLogListItem from 'components/transfer/TransferLogListItem';
import TransferLogSearch from 'components/transfer/TransferLogSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { TransferLogQueryParams } from 'constants/schema';
import * as FileSaver from 'file-saver';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiChevronLeft, BiChevronRight, BiFilter } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import {
  DateFormat,
  DateTimeFormat,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';
import * as XLSX from 'xlsx';

const FILETYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILEEXTENSION = '.xlsx';

const TransferLogs: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string>('initialFetch');

  const [totalLogs, setTotalLogs] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<TransferLogQueryParams>({
    page: INITIAL_CURRENT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    article: '',
    source: null,
    destination: null,
    transferred_by: null,
    transferred_from: null,
    transfer_source: '',
    transferred_to: null,
  });

  const { pagesQuantity, setPageSize } = usePaginator({
    total: totalLogs,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: INITIAL_CURRENT_PAGE,
    },
  });

  const { data: logList, isLoading: isLogListLoading } = useQuery(
    [
      'transferLogList',
      {
        article: filterParams.article,
        page: filterParams.page,
        limit: filterParams.limit,
        source: filterParams.source,
        destination: filterParams.destination,
        transferred_by: filterParams.transferred_by,
        transferred_from: filterParams.transferred_from,
        transfer_source: filterParams.transfer_source,
        transferred_to: filterParams.transferred_to,
      },
    ],
    async () => {
      const response = await getTransferLogs(
        filterParams.page,
        filterParams.limit
      );
      response && setIsLoading('');
      setTotalLogs(response?.data?.data?.meta?.total);
      return response?.data?.data?.data;
    }
  );

  const getTransferLogs = async (page: number, limit: number) => {
    const {
      article,
      source,
      destination,
      transferred_by,
      transferred_from,
      transfer_source,
      transferred_to,
    } = filterParams;

    const queryParams: any = {
      page: page,
      limit: limit,
    };

    if (article) queryParams.article = article;
    if (source) queryParams.source = source;
    if (destination) queryParams.destination = destination;
    if (transferred_by) queryParams.transferred_by = transferred_by;
    if (transferred_from)
      queryParams.transferred_from =
        getUTCDateRangeStartDateTime(transferred_from);
    if (transferred_to)
      queryParams.transferred_to = getUTCDateRangeEndDateTime(transferred_to);
    if (transfer_source) queryParams.transfer_source = transfer_source;

    return await stockActivitiesAPI.getTransferLogs(queryParams);
  };

  const handlePageChange = (nextPage: number) => {
    setFilterParams((prevState) => ({
      ...prevState,
      page: nextPage,
    }));
  };

  const handleChangeFilters = useCallback((data: TransferLogQueryParams) => {
    setFilterParams((prevState) => ({
      ...prevState,
      page: INITIAL_CURRENT_PAGE,
      article: data.article,
      transferred_from: data.transferred_from,
      transferred_to: data.transferred_to,
      transferred_by: data.transferred_by,
      transfer_source: data.transfer_source,
      source: data.source,
      destination: data.destination,
    }));
  }, []);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(filterParams.page, filterParams.limit);
  }, [filterParams.page, filterParams.limit]);

  const displaySourceOrDestination = (endpoint: any) => {
    if (!endpoint) return '-';
    // Fresh / Consumed / Employee
    if (endpoint.name) {
      if (endpoint.name === 'Fresh') return strings.fresh;
      if (endpoint.name === 'Consumed') return strings.consumed;
      return endpoint.name;
    }
    // Warehouse
    return `${endpoint.warehouse?.name} / ${endpoint.room?.name} / ${endpoint.rack?.name} / ${endpoint.shelf?.name}`;
  };

  const handleExport = async () => {
    const logList = await getTransferLogs(1, Number(totalLogs));
    let logListArray = [];
    logListArray = await logList?.data?.data?.data.map(
      (logData: any, index: number) => {
        return {
          [strings.sn]: index + 1,
          [strings.article]: logData.article_name,
          [strings.source]: displaySourceOrDestination(logData.source),
          [strings.destination]: displaySourceOrDestination(
            logData.destination
          ),
          [strings.transfer_quantity]: logData.transferred_quantity,
          [strings.transfer_by]: logData.transferred_by?.name
            ? logData.transferred_by.name
            : '-',
          [strings.transfer_date]: DateTimeFormat(logData.transferred_date),
          [strings.transfer_source]: logData.action_type,
        };
      }
    );
    const ws = XLSX.utils.json_to_sheet(logListArray);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: FILETYPE });
    FileSaver.saveAs(data, `LBSLog-${DateFormat(new Date())}` + FILEEXTENSION);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.all_transfer_logs}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.all_transfer_logs}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.transfer_logs}</Heading>

          <Tooltip label={strings.download_excel} hasArrow>
            <Button
              size="sm"
              colorScheme="green"
              variant="outline"
              onClick={handleExport}>
              <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
            </Button>
          </Tooltip>
        </Flex>

        <Accordion bg="white" borderColor="white" allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="16px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <TransferLogSearch
                isLoading={isLoading}
                handleAdvancedSearch={handleChangeFilters}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>{strings.article}</Th>
                  <Th>{strings.source}</Th>
                  <Th>{strings.destination}</Th>
                  <Th isNumeric>{strings.transfer_quantity}</Th>
                  <Th>{strings.transfer_by}</Th>
                  <Th isNumeric>{strings.transfer_date}</Th>
                  <Th>{strings.transfer_source}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {logList?.map((log: any, index: number) => (
                  <TransferLogListItem
                    logData={log}
                    index={startingSN + index}
                    key={log.id}
                  />
                ))}
                {isLogListLoading && (
                  <TableSkeletonLoader rows={filterParams.limit} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          overflow="auto">
          <Text color="gray.400">
            <ResultCountDisplay
              isLoading={isLogListLoading}
              totalCount={totalLogs}
              resultCount={logList?.length}
              pageSize={filterParams.limit}
              currentPage={filterParams.page}
            />
          </Text>
          <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
            {logList?.length > 0 && (
              <PageLimitChanger
                pageSize={filterParams.limit}
                setPageSize={(value) => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    page: INITIAL_CURRENT_PAGE,
                    limit: value,
                  }));
                  setPageSize(value);
                }}
              />
            )}
            {!isLogListLoading && pagesQuantity > 1 && (
              <Paginator
                activeStyles={activeStyles}
                currentPage={filterParams.page}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}
                pagesQuantity={pagesQuantity}
                onPageChange={handlePageChange}
                innerLimit={2}
                outerLimit={2}>
                <Container
                  align="center"
                  justifyContent="flex-end"
                  w="full"
                  p={1}
                  pr={0}
                  ml={3}>
                  <Previous mr="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronLeft} fontSize="xl" />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next ml="0.3rem" sx={normalStyles} minWidth={0}>
                    <Icon as={BiChevronRight} fontSize="xl" />
                  </Next>
                </Container>
              </Paginator>
            )}
          </Flex>
        </Flex>
      </Stack>
    </>
  );
};

export default TransferLogs;
