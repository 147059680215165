import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { Flex, Heading, Stack } from '@chakra-ui/layout';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import RMSCrossSellingResource from 'api/rms-cross-selling';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CrossSellingFrom from 'components/object-dashboard/cross-selling/CrossSellingFrom';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  CrossSellingDetailInterface,
  DataWrapperWithSuccess,
} from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { buildFormData } from 'utils';

const EditCrossSelling = () => {
  const history = useHistory();
  const methods = useForm();
  const toast = useToast();
  const crossSellingAPi = new RMSCrossSellingResource();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const crossSellingDetail = useQuery<
    DataWrapperWithSuccess<CrossSellingDetailInterface>
  >({
    queryKey: ['cross-selling-detail', id],
    queryFn: async () => {
      const response = await crossSellingAPi.get(id);
      return response?.data;
    },
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const updateCrossSelling = useMutation({
    mutationFn: (data: any) => crossSellingAPi.updateCrossSelling(id, data),
    onSuccess: () => {
      toast({
        title: strings.successfully_edited_cross_selling,
        status: 'success',
        isClosable: true,
        duration: 3000,
      });
      queryClient.invalidateQueries({
        queryKey: ['cross-selling-list'],
      });
      history.push(routes.objectDashboard.crossSelling.list);
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
      });
    },
  });

  const onSubmit = (formData: any) => {
    formData['_method'] = 'PUT';
    updateCrossSelling.mutate(buildFormData(formData));
  };

  if (crossSellingDetail.isLoading) {
    return <CenterSpinner />;
  }

  if (crossSellingDetail.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.cross_selling_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.dashboard} | {strings.edit_cross_selling}
        </title>
      </Helmet>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.objectDashboard.crossSelling.list}>
            {strings.cross_selling}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>
            {crossSellingDetail?.data?.data?.service_name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md">{strings.edit_cross_selling}</Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="5">
              <CrossSellingFrom
                crossSellingData={crossSellingDetail?.data?.data}
              />
              <ButtonGroup>
                <Button
                  type="submit"
                  colorScheme="primary"
                  isLoading={updateCrossSelling.isLoading}>
                  {strings.update}
                </Button>
                <Button
                  variant="outline"
                  colorScheme="primary"
                  onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditCrossSelling;
