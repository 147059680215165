import { Box, Flex, Text } from '@chakra-ui/react';
import { qrDataSchema } from 'constants/schema';
import { QRCodeSVG } from 'qrcode.react';
import { default as React, forwardRef } from 'react';

interface Props {
  qrSignature: qrDataSchema[];
  ref: any;
  valueFromBackend?: boolean;
}
const QRScanDetailPDF = forwardRef((props: Props, ref: any) => {
  const { qrSignature, valueFromBackend = false } = props;

  return (
    <Box id="qr-scan-print" ref={ref}>
      {qrSignature?.map((item: qrDataSchema, index) => (
        <Flex
          className="qr-page-break"
          p={4}
          w="60%"
          m="auto"
          flexDirection="column"
          alignItems="center"
          border="1px solid black"
          key={index}>
          {valueFromBackend ? (
            <img id="qr-svg" alt={item.title} src={item.value} />
          ) : (
            <QRCodeSVG id="qr-svg" value={item.value} />
          )}
          <Text color="gray.700" fontSize="24px">
            {item.title}
          </Text>
        </Flex>
      ))}
    </Box>
  );
});

QRScanDetailPDF.displayName = 'QRScanCodePDF';
export default QRScanDetailPDF;
