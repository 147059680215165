import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Tag,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { setApartmentData, setCustomerData } from 'actions/data/feedback';
import FeedbackResource from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import { PdfBtn } from 'assets/icons';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FeedbackQuestionType from 'components/feedback/FeedbackQuestionType';
import { strings } from 'config/localization';
import { FEEDBACK_QUESTION_TYPE_CLOSED_ENDED } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { capitalizeFirstLetter } from 'utils';
import { DateFormat, utcToLocal } from 'utils/DateFormat';
import FeedbackDetailsPDF from './FeedbackDetailsPDF';

const FeedbackDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const toast = useToast();
  const history = useHistory();
  const { search } = useLocation();
  const printRef: any = useRef();
  const todayDate = new Date();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [isResending, setIsResending] = useState<boolean>(false);

  const feedbackResourceAPI = new FeedbackResource();

  const titleStyled = {
    color: 'gray.500',
    fontWeight: 'medium',
    mr: '5px',
  };

  const borderStyled = {
    borderColor: 'gray.200',
    _lastChildren: {
      borderColor: 'transparent',
    },
  };

  const feedbackData = useQuery(
    [`feedback${id}`],
    async () =>
      await feedbackResourceAPI.getFeedbackById(id).then((res) => res.data.data)
  );

  const {
    data: feedbackDetail,
    isLoading: feedbackLoading,
    error: feedbackError,
  } = feedbackData;

  const feedbackFlag = useMutation((data: any) =>
    feedbackResourceAPI.updateFeedbackStatus(id, data)
  );
  const formatedDate = DateFormat(feedbackDetail?.feedback_at);

  const handlePublishFeedback = () => {
    feedbackFlag.mutate(
      {
        publish: {
          feedback: 1,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${strings.feedback_has_been_published_successfully}`,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries(`feedback${id}`);
        },
        onError: () => {
          toast({
            title: `${strings.feedback_publish_error}`,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };

  const handlePublishOnObjectPage = () => {
    feedbackFlag.mutate(
      {
        publish: {
          feedback_apartment: 1,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${strings.published_feedback_on_object_page_successfully}`,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries(`feedback${id}`);
        },
        onError: () => {
          toast({
            title: `${strings.publish_feedback_on_object_page_error}`,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };
  const handleDoNotPublishFeedback = () => {
    feedbackFlag.mutate(
      {
        publish: {
          feedback: 0,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${strings.feedback_has_been_unpublished_successfully}`,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries(`feedback${id}`);
        },
        onError: () => {
          toast({
            title: `${strings.feedback_unpublish_error}`,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };
  const handleDoNotPublishOnObjectPage = () => {
    feedbackFlag.mutate(
      {
        publish: {
          feedback_apartment: 0,
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${strings.feedback_on_object_page_has_been_unpublished_successfully}`,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries(`feedback${id}`);
        },
        onError: () => {
          toast({
            title: `${strings.feedback_on_object_page_unpublish_error}`,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };
  const handleUseScoreCalculation = () => {
    feedbackFlag.mutate(
      {
        rating_calculation_flag: 1,
      },
      {
        onSuccess: () => {
          toast({
            title: `${strings.score_calculation_used}`,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries(`feedback${id}`);
        },
        onError: () => {
          toast({
            title: `${strings.score_calculation_use_error}`,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };
  const handleUnuseScoreCalculation = () => {
    feedbackFlag.mutate(
      {
        rating_calculation_flag: 0,
      },
      {
        onSuccess: () => {
          toast({
            title: `${strings.score_calculation_unused}`,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries(`feedback${id}`);
        },
        onError: () => {
          toast({
            title: `${strings.score_calculation_unuse_error}`,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };

  const { publish, rating_calculation_flag }: any = { ...feedbackDetail };
  const { feedback, feedback_apartment }: any = { ...publish };

  const togglePublishFeedbackHandler = () => {
    if (feedback === 1) {
      handleDoNotPublishFeedback();
    } else {
      handlePublishFeedback();
    }
  };

  const togglePublishOnObjectPageHandler = () => {
    if (feedback_apartment === 1) {
      handleDoNotPublishOnObjectPage();
    } else {
      handlePublishOnObjectPage();
    }
  };

  const toggleScoreCalculationHandler = () => {
    if (rating_calculation_flag === 1) {
      handleUnuseScoreCalculation();
    } else {
      handleUseScoreCalculation();
    }
  };

  const handleMarkAsDone = async (e: any) => {
    e.preventDefault();
    await feedbackFlag.mutate(
      {
        status: FEEDBACK_QUESTION_TYPE_CLOSED_ENDED,
      },
      {
        onSuccess: () => {
          toast({
            title: `${strings.feedback_has_been_marked_as_done_successfully}`,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries(`feedback${id}`);
        },
        onError: () => {
          toast({
            title: `${strings.feedback_mark_as_done_error}`,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };

  //redirect to ticket creation page
  const handleCreateTicket = (ticketType: string) => {
    const customerId = feedbackDetail?.customer_id;
    const customer_first_name =
      feedbackDetail?.reservation?.customer_info?.forename.trim();
    const customer_last_name =
      feedbackDetail?.reservation?.customer_info?.surname.trim();
    const customer_full_name = `${capitalizeFirstLetter(
      customer_first_name ?? ''
    )} ${capitalizeFirstLetter(customer_last_name ?? '')}`;

    const ownerId = feedbackDetail?.reservation?.apartment?.owner?.id;
    const owner_first_name =
      feedbackDetail?.reservation?.apartment?.owner?.first_name?.trim();
    const owner_last_name =
      feedbackDetail?.reservation?.apartment?.owner?.last_name.trim();
    const owner_name = `${capitalizeFirstLetter(
      owner_first_name ?? ''
    )} ${capitalizeFirstLetter(owner_last_name ?? '')}`;

    const apartmantId = feedbackDetail?.reservation?.apartment?.id;
    const apartmantName = feedbackDetail?.reservation?.apartment?.name;

    const customer = {
      value: customerId,
      label: customer_full_name,
      isDisabled: null,
    };
    const apartment = {
      customer: {
        id: ownerId,
        is_intermixed: null,
        name: owner_name,
        owner: true,
      },
      label: apartmantName,
      value: apartmantId,
    };
    dispatch(setCustomerData(customer));
    dispatch(setApartmentData(apartment));

    history.push({
      pathname: routes.ticket.task.create,
      state: {
        ticketType,
        redirectedFromFeedback: true,
      },
    });
  };

  const handleContactCustomer = () => {
    const customerId = feedbackDetail?.customer_id;
    if (customerId) {
      history.push({
        pathname: routes.crm.contact.details.replace(
          ':id',
          customerId.toString()
        ),
      });
    } else {
      const first_name =
        feedbackDetail?.reservation?.customer_info?.forename.trim();
      const last_name =
        feedbackDetail?.reservation?.customer_info?.surname.trim();
      history.push({
        pathname: routes.crm.contact.list,
        search: `?first_name=${first_name}&last_name=${last_name}`,
      });
    }
  };

  const handleContactHouseOwner = () => {
    const ownerId = feedbackDetail?.reservation?.apartment?.owner?.id;
    if (ownerId) {
      history.push({
        pathname: routes.crm.contact.details.replace(':id', ownerId.toString()),
      });
    } else {
      const owner = feedbackDetail?.reservation?.apartment?.owner;
      const first_name =
        feedbackDetail?.reservation?.apartment?.owner?.first_name?.trim();
      const last_name =
        feedbackDetail?.reservation?.apartment?.owner?.last_name.trim();
      let owner_name = `first_name=${first_name}&last_name=${last_name}`;

      history.push({
        pathname: routes.crm.contact.list,
        search: owner ? `?type=owner&${owner_name}` : `?type=owner`,
      });
    }
  };

  const handlePrintFeedbackDetail = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page{padding-top:10px }',
    documentTitle: `${feedbackDetail?.reservation?.booking_number}_${feedbackDetail?.reservation?.customer_info?.forename}_${feedbackDetail?.reservation?.customer_info?.surname}_${feedbackDetail?.reservation?.apartment?.name}_${formatedDate}`,
  });

  const resendPermission: boolean = useIsPermitted([
    PermissionRequest['resend:feedback'],
  ]);

  const resendFeedback = useMutation(
    (id: any) => feedbackResourceAPI.resendFeedbackMail(id),
    {
      onSuccess: () => {
        toast({
          title: `${strings.resent_mail_success}`,
          status: 'success',
          isClosable: true,
        });
        setIsResending(false);
      },
      onError: () => {
        toast({
          title: `${strings.resent_mail_error}`,
          status: 'error',
          isClosable: true,
        });
        setIsResending(false);
      },
    }
  );

  const handleResendFeedback = () => {
    setIsResending(true);
    resendFeedback.mutate(id);
  };

  if (feedbackLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.feedback} |
            {` ${feedbackDetail?.reservation?.customer_info?.forename} ${feedbackDetail?.reservation?.customer_info?.surname}`}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.feedback.feedbacks.list + search}>
              {strings.feedback}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {`${feedbackDetail?.reservation?.customer_info?.forename} ${feedbackDetail?.reservation?.customer_info?.surname}`}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.feedback_detail}
          </Heading>
          <Stack direction="row" justify="space-between">
            {resendPermission &&
              feedbackDetail?.status !==
                FEEDBACK_QUESTION_TYPE_CLOSED_ENDED && (
                <Button
                  size="sm"
                  colorScheme="primary"
                  type="button"
                  onClick={handleResendFeedback}
                  isLoading={isResending}>
                  {strings.resend_feedback}
                </Button>
              )}
            <Tooltip label={strings.download_pdf} hasArrow>
              <Button
                size="sm"
                colorScheme="red"
                variant="outline"
                onClick={handlePrintFeedbackDetail}>
                <Image w="6" src={PdfBtn} alt="PDF" title="PDF" />
              </Button>
            </Tooltip>
            <Button
              size="sm"
              colorScheme="primary"
              type="button"
              isDisabled={
                feedbackDetail?.status === FEEDBACK_QUESTION_TYPE_CLOSED_ENDED
                  ? true
                  : false
              }
              onClick={handleMarkAsDone}>
              {strings.mark_as_done}
            </Button>
          </Stack>
        </Flex>
        <div style={{ display: 'none' }}>
          <FeedbackDetailsPDF feedbackDetail={feedbackDetail} ref={printRef} />
        </div>
        <Box id="feedback-detail-print">
          <Stack
            direction="column"
            spacing="4"
            bg="white"
            p={['3', '6']}
            mb="3"
            shadow="box">
            <Text>
              <Box fontSize="lg" color="gray.600" fontWeight="bold">
                {`${feedbackDetail?.reservation?.customer_info?.forename} ${feedbackDetail?.reservation?.customer_info?.surname}`}
              </Box>
              <Flex py="1">
                <Text sx={titleStyled}>{strings.feedback_received}:</Text>
                <Text>{utcToLocal(feedbackDetail?.feedback_at)}</Text>
              </Flex>

              <Flex py="1">
                <Text sx={titleStyled}>{strings.booking_number}: </Text>
                <Text>{feedbackDetail?.reservation?.booking_number}</Text>
              </Flex>

              <Flex py="1">
                <Text sx={titleStyled}> {strings.apartmant}:</Text>
                <Text>{feedbackDetail?.reservation?.apartment?.name}</Text>
              </Flex>

              <Flex py="1">
                <Text sx={titleStyled}> {strings.time_period}: </Text>
                <Text>{`${DateFormat(
                  feedbackDetail?.reservation?.from_date
                )} - ${DateFormat(
                  feedbackDetail?.reservation?.to_date
                )}`}</Text>
              </Flex>
            </Text>
          </Stack>
          <Stack sx={wrapperStyles}>
            <Stack
              p={['3', '6']}
              display="flex"
              direction="row"
              justify="space-between">
              <Text
                fontWeight="medium"
                fontSize="md"
                color="gray.600"
                textTransform="capitalize">
                {strings.question}
              </Text>
              <Text
                fontWeight="medium"
                fontSize="md"
                textTransform="capitalize"
                color="gray.600">
                {strings.response}
              </Text>
            </Stack>
            <PerfectScrollbar>
              <Divider sx={borderStyled}></Divider>
              <Grid
                gap="2"
                templateColumns={['repeat(1, 1fr)']}
                p={['3', '6']}
                w="100%"
                alignItems="center"
                borderBottomColor="gray.900">
                <GridItem>
                  <FormControl
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap">
                    <Box w="45%">
                      <FormLabel>
                        {
                          strings.How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole
                        }
                      </FormLabel>
                    </Box>
                    <Box w="55%" textAlign="right">
                      <Tooltip
                        hasArrow
                        label={strings.the_rating_is_out_of_10}
                        placement="left"
                        size="sm"
                        zIndex={1}>
                        <Tag
                          variant="outline"
                          colorScheme="teal"
                          borderRadius="full"
                          w="4"
                          h="4"
                          textAlign="center"
                          fontSize="14px"
                          size="md"
                          justifyContent="center">
                          {feedbackDetail?.rating}
                        </Tag>
                      </Tooltip>
                    </Box>
                  </FormControl>
                </GridItem>
                <Divider sx={borderStyled}></Divider>
                {feedbackDetail?.feedback_response?.map(
                  (response: any, index: number) => (
                    <FeedbackQuestionType
                      response={response}
                      index={index}
                      key={index}
                    />
                  )
                )}
              </Grid>
            </PerfectScrollbar>
          </Stack>
        </Box>
        <Stack>
          <ButtonGroup>
            <Button
              colorScheme="primary"
              type="button"
              onClick={togglePublishFeedbackHandler}>
              {feedback === 1
                ? strings.do_not_publish_feedback
                : strings.publish_feedback}
            </Button>
            <Button
              colorScheme="primary"
              type="button"
              onClick={togglePublishOnObjectPageHandler}>
              {feedback_apartment === 1
                ? strings.do_not_publish_feedback_on_object_page
                : strings.publish_feedback_on_object_page}
            </Button>
            <Button
              colorScheme="primary"
              type="button"
              onClick={toggleScoreCalculationHandler}>
              {rating_calculation_flag === 1
                ? strings.do_not_use_for_score_calculation
                : strings.use_for_score_calculation}
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              colorScheme="primary"
              type="submit"
              onClick={() => handleCreateTicket('normal')}>
              {strings.create_ticket}
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              onClick={handleContactCustomer}>
              {strings.contact_customer}
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              onClick={handleContactHouseOwner}>
              {strings.contact_house_owner}
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>
    </>
  );
};

export default FeedbackDetails;
