import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import ReservationRequestResource from 'api/reservation_request';
import ReservationRequestCustomerInfo from 'components/bms_reservation_request/ReservationRequestCustomerInfo';
import ReservationRequestInfo from 'components/bms_reservation_request/ReservationRequestInfo';
import ReservationRequestServiceInfo from 'components/bms_reservation_request/ReservationRequestServiceInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReservationRequestSchema } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const ReservationRequestDetail: React.FC = () => {
  const { id }: any = useParams();
  const reservationRequestApi = new ReservationRequestResource();
  const { search } = useLocation();

  const reservationRequestQuery = useQuery<ReservationRequestSchema>(
    [`reservation-request-details-${id}`],
    () => reservationRequestApi.get(id).then((res) => res.data.data)
  );

  if (reservationRequestQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (reservationRequestQuery.isError) {
    history.push(routes.bms.reservationRequest.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bms} |&nbsp;
            {reservationRequestQuery?.data?.apartment?.apartment_name || '-'}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bms.reservationRequest.list + search}>
              {strings.reservation_requests}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {reservationRequestQuery?.data?.apartment?.apartment_name || '-'}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <ReservationRequestInfo
          bookingPeriod={reservationRequestQuery?.data?.booking_period || '-'}
          apartmentName={
            reservationRequestQuery?.data?.apartment?.apartment_name || '-'
          }
          reservationRequestData={reservationRequestQuery?.data?.bookings}
        />
        <ReservationRequestCustomerInfo
          reservationRequestData={reservationRequestQuery?.data?.customer}
        />
        <ReservationRequestServiceInfo
          reservationRequestData={
            reservationRequestQuery?.data?.service_bookings
          }
        />
      </Stack>
    </>
  );
};

export default ReservationRequestDetail;
