import {
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import RMSDashObjectsResource from 'api/rms-dashboard';
import {
  DashHeadingStyle,
  ObjectDashboardColumnStyle,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { ObjektDetailSchema } from 'constants/schemas/objektSchema';
import React, { useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import ObjectArea from './ObjectArea';

interface Props {
  resourceId: number;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setVOfficeOwnerId: React.Dispatch<React.SetStateAction<number | null>>;
}

const ObjectDash: React.FC<Props> = (props) => {
  const { resourceId, setTitle, setVOfficeOwnerId } = props;
  const dashboardAPI = new RMSDashObjectsResource();

  const { data: apartmentDetails, isLoading } = useQuery<ObjektDetailSchema>(
    [`dashboard-object-info`, resourceId],
    () => {
      return dashboardAPI
        .dashboardObjectInfo(resourceId)
        .then((res) => res.data?.data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!apartmentDetails) return;
    const {
      name: { de: apartmentName },
    } = apartmentDetails.info;
    setTitle(apartmentName);

    const { owner_info } = apartmentDetails;
    if (owner_info) setVOfficeOwnerId(owner_info.id);
  }, [setTitle, apartmentDetails, setVOfficeOwnerId]);

  return (
    <Stack sx={ObjectDashboardColumnStyle}>
      <Heading sx={DashHeadingStyle}>{strings.infos}</Heading>
      <FormControl>
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            children={<BiSearch />}
            color="gray.400"
          />
          <Input name="keyword" placeholder={strings.search} type="text" />
        </InputGroup>
      </FormControl>
      {isLoading && <CenterSpinner />}
      {apartmentDetails && <ObjectArea data={apartmentDetails} />}
    </Stack>
  );
};

export default ObjectDash;
