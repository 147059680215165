import { Box, Flex, Grid, GridItem, Image, Stack } from '@chakra-ui/react';
import {
  SecondScreenBackdropEffect,
  SecondScreenBackgroundStyle,
} from 'assets/css/commonStyles';
import FallBackImage from 'assets/images/banner2.jpg';
import { strings } from 'config/localization';
import { XXL_OBJECT_IMAGE_URL } from 'constants/common';
import React from 'react';
import { DateFormat } from 'utils/DateFormat';

const labelFontWeight = 400;
const valueFontWeight = 700;

const CheckInScreen = (props: { bookingInfo: any }) => {
  const { bookingInfo } = props;

  const imageId = bookingInfo.apartment?.image.id;
  const imageUrl = XXL_OBJECT_IMAGE_URL.replace(':imageID', imageId);

  const statusColor = !!bookingInfo.payment ? 'green.400' : 'orange.400';

  const noOfAdults = bookingInfo.booking.adults
    ? Number(bookingInfo.booking.adults)
    : 0;
  const noOfBabies = bookingInfo.booking.babys
    ? Number(bookingInfo.booking.babys)
    : 0;
  const noOfChildren = bookingInfo.booking.children
    ? Number(bookingInfo.booking.children)
    : 0;
  const totalPeople = noOfAdults + noOfBabies + noOfChildren;

  const serviceGroupList: Set<string> = new Set();
  bookingInfo?.service_bookings?.forEach((services: any) => {
    !!services.service_group_name &&
      serviceGroupList.add(services.service_group_name);
  });

  return (
    <Box sx={SecondScreenBackgroundStyle}>
      <Image
        src={imageUrl}
        fallbackSrc={FallBackImage}
        position="absolute"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
        w="100vw"
        h="100vh"
      />
      <Flex
        direction="column"
        sx={SecondScreenBackdropEffect}
        color="white"
        position="absolute">
        <Stack
          backgroundColor="darkbrown.500"
          paddingX={8}
          paddingY={5}
          maxW="90%"
          width="fit-content"
          boxShadow="box"
          borderBottomEndRadius="1.4vw"
          fontFamily="Overpass">
          <Box textTransform="capitalize">
            <span style={{ fontWeight: labelFontWeight, fontSize: '3.5vw' }}>
              {strings.welcome}&nbsp;
            </span>
            <span style={{ fontWeight: 900, fontSize: '3.7vw' }}>
              {bookingInfo.customer.forename}&nbsp;
              {bookingInfo.customer.surname}
            </span>
          </Box>
        </Stack>
        <Flex flex={1}>
          <Grid
            gridTemplateColumns="repeat(2,auto)"
            fontSize="3vw"
            flex={1}
            gap="0.6vw"
            padding={10}>
            <GridItem fontWeight={labelFontWeight}>
              {strings.apartmant}:
            </GridItem>
            <GridItem fontWeight={valueFontWeight}>
              {bookingInfo.apartment.fullname}
            </GridItem>
            <GridItem fontWeight={labelFontWeight}>
              {strings.time_period}:
            </GridItem>
            <GridItem fontWeight={valueFontWeight}>
              {DateFormat(bookingInfo.booking.fromdate)}&nbsp;-&nbsp;
              {DateFormat(bookingInfo.booking.tilldate)}
            </GridItem>
            <GridItem fontWeight={labelFontWeight}>{strings.people}:</GridItem>
            <GridItem fontWeight={valueFontWeight}>{totalPeople}</GridItem>
            <GridItem fontWeight={labelFontWeight}>{strings.status}:</GridItem>
            <GridItem fontWeight={valueFontWeight} color={statusColor}>
              {!!bookingInfo.payment ? strings.paid : strings.pending}
            </GridItem>
          </Grid>
          {!!serviceGroupList.size && (
            <Flex alignItems="flex-end">
              <Box
                backgroundColor="darkbrown.500"
                padding="1.8vw"
                boxShadow="box"
                borderTopStartRadius="1.4vw">
                <Flex direction="column">
                  <Box fontWeight={valueFontWeight} fontSize="3vw">
                    {strings.booked_service}:
                  </Box>
                  <Box fontSize="1.6vw" fontWeight={500}>
                    {Array.from(serviceGroupList).map((serviceGroup) => (
                      <Box key={serviceGroup}>{serviceGroup}</Box>
                    ))}
                  </Box>
                </Flex>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default CheckInScreen;
