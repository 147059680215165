import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  apartmentName: any;
}

const ObjectsInfo: React.FC = () => {
  const ticketApi = new TicketResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    apartmentName: '',
  });

  const [totalApartmentTicket, setTotalApartmentTicket] = useState<
    number | undefined
  >(undefined);

  const apartmentTicketList = useQuery(
    [
      'apartmentTicketList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        apartmentName: filterParams.apartmentName,
      },
    ],
    async () => {
      try {
        const queryParams: any = {
          page: filterParams.currentPage,
          limit: filterParams.pageSize,
        };
        if (filterParams.apartmentName)
          queryParams.apartmentName = filterParams.apartmentName;

        const response = await ticketApi.getApartmentWiseTicket(
          queryParams.page,
          queryParams.limit,
          queryParams.apartmentName
        );
        setTotalApartmentTicket(response?.data?.meta?.total);
        return response?.data;
      } catch (error) {
        throw new Error('Some error occured');
      }
    }
  );

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      apartmentName: '',
    }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.ticket} | {strings.all_objects}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.ticket.list.default}>
              {strings.ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.ticket.objectsInfo}>
              {strings.all_objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.objects}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="apartmentName"
                    value={filterParams.apartmentName}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>
              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th rowspan={2}>{strings.object_id}</Th>
                  <Th rowspan={2}>{strings.object_name}</Th>

                  <Th colSpan={3}>
                    <Center>{strings.tickets}</Center>
                  </Th>
                </Tr>

                <Tr>
                  <Th isNumeric>{strings.open}</Th>
                  <Th isNumeric>{strings.inprogress}</Th>
                  <Th isNumeric>{strings.done}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!apartmentTicketList.isLoading &&
                  apartmentTicketList?.data?.data?.map(
                    (apartmentTicket: any, index: number) => (
                      <Tr key={index}>
                        <Td>{apartmentTicket.apartment_id}</Td>
                        <Td>{apartmentTicket.name}</Td>
                        <Td isNumeric>{apartmentTicket.open}</Td>
                        <Td isNumeric>{apartmentTicket.in_progress}</Td>
                        <Td isNumeric>{apartmentTicket.done}</Td>
                      </Tr>
                    )
                  )}
                {apartmentTicketList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={apartmentTicketList}
        />
      </Stack>
      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.delete_user}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this?</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="red">Delete</Button>
              <Button variant="outline">Cancel</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ObjectsInfo;
