import http from 'utils/http';
import Resource from './resource';

class CRMDashboardResource extends Resource {
  constructor() {
    super(`crm/dashboard/contact`);
  }

  dashboardOwnerContact(id: number, query: any = {}) {
    return http({
      url: `crm/dashboard/contact/v-office/${id}`,
      method: 'GET',
      params: query,
    });
  }
  dashboardCustomerContact(id: number, query: any = {}) {
    return http({
      url: `crm/dashboard/contact/${id}`,
      method: 'GET',
      params: query,
    });
  }

  dashboardBookingList(params?: any) {
    return http({
      url: `crm/dashboard/contact-bookings`,
      method: 'GET',
      params,
    });
  }
}

export { CRMDashboardResource as default };
