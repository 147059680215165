import {
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  useEditableControls,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { BiEditAlt } from 'react-icons/bi';
import { strings } from 'config/localization';

export interface RoomNameEditProps {
  room: any;
  submitEditableInput: (value: string, id: string) => void;
  viewOnly: Boolean;
}

const RoomNameEdit: React.FC<RoomNameEditProps> = ({
  room,
  submitEditableInput,
  viewOnly,
}) => {
  const [inputValue, setInputValue] = useState(room.name);
  const defaultValue = room.name;
  const toast = useToast();

  const handleSubmit = (value: string) => {
    if (!value || value.trim() === '') {
      toast({
        title: 'Room name cannot be empty',
        status: 'error',
        isClosable: true,
      });
      setInputValue(defaultValue);
      return;
    }
    submitEditableInput(value, room.generatedId);
  };

  function EditableControls() {
    const { isEditing, getEditButtonProps } = useEditableControls();
    return !isEditing ? (
      <IconButton
        size="sm"
        aria-label={strings.edit_room}
        p="0"
        bg="transparent"
        icon={<BiEditAlt />}
        ml="2"
        fontSize="18"
        color="gray.500"
        {...getEditButtonProps()}
      />
    ) : null;
  }
  return (
    <Editable
      value={inputValue}
      onChange={(value) => setInputValue(value)}
      onSubmit={(value) => handleSubmit(value)}>
      <EditablePreview fontWeight="semibold" />
      <EditableInput paddingLeft="4" paddingRight="4" />
      {!viewOnly && <EditableControls />}
    </Editable>
  );
};

export default RoomNameEdit;
