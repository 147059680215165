import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Icon,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { BiPlus, BiTrash } from 'react-icons/bi';
import FileDetails from './FileDetails';

interface Props {
  title?: string;
  description?: string;
  fileKey?: string;
  multiple?: boolean;
  uploadedFiles?: any[];
  deleteAttachment?: any;
  acceptFileType?: string;
  clearFileInputToggle?: boolean; // clear input files (myFiles) on toggle of this prop
  maxFiles?: number;
  required?: boolean;
  clearErrors?: any;
  width?: string;
  maxFileSize?: number;
}
const Uploader: React.FC<Props> = (props) => {
  const {
    title = strings.upload_files,
    description = strings.formatString(
      strings.drag_drop_files_msg,
      props.maxFileSize ?? '5'
    ),
    fileKey = 'file',
    multiple = true,
    uploadedFiles = [],
    deleteAttachment,
    acceptFileType = 'image/*',
    clearFileInputToggle,
    maxFiles = 100,
    clearErrors = null,
    width = '260px',
    maxFileSize = 5,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  let uploadedDataFiles: any = uploadedFiles?.filter((item: any) => !!item);

  // if (uploadedFiles !== undefined) uploadedDataFiles = [...uploadedFiles];
  const [myUploadedFiles, setMyUploadedFiles] = useState<any>([
    ...uploadedDataFiles,
  ]);

  // // Whenever uploadedDataFiles changes, update myUploadedFiles

  const [myFiles, setMyFiles] = useState<any>([]);
  const [toRemoveFile, setToRemoveFile] = useState<any>();
  const { setValue } = useFormContext();
  const toast = useToast();
  const isFirstMount = useRef(true);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (multiple) {
        setMyFiles([...myFiles, ...acceptedFiles]);
      } else {
        setMyFiles(acceptedFiles);
      }

      if (clearErrors && acceptedFiles.length > 0) {
        clearErrors('upload_files');
      }

      if (fileRejections.length > 0) {
        let title = strings.max_upload_size_5;
        if (fileRejections[0]?.errors[0]?.code === 'too-many-files') {
          title = strings.only_one_file_is_allowed;
        }
        toast({
          title: title,
          status: 'error',
          isClosable: true,
        });
      }
    },
    [multiple, myFiles, toast, clearErrors]
  );

  React.useMemo(() => {
    if (multiple) {
      setValue(fileKey, myFiles);
    } else {
      setValue(fileKey, myFiles[0]);
    }
  }, [fileKey, multiple, myFiles, setValue]);

  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > maxFileSize) {
      return {
        code: 'file-size-too-large',
        message: `strings.file_size_is_too_large`,
      };
    }
    return null;
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: acceptFileType,
    validator: fileSizeValidator,
    multiple: multiple,
    onDrop,
    maxFiles: maxFiles,
  });

  const removeFile = (file: any) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file: any, index: number) => (
    <ListItem key={index} padding="8px 0 8px 0">
      <Flex justify="space-between">
        <FileDetails
          fileName={file.path.replace(/\.[^/.]+$/, '')}
          fileSize={file.size}
          mimeType={file.type}
        />
        <Box w="4" h="4" mr={1} alignSelf="baseline">
          <IconButton
            color="gray.300"
            _hover={{ cursor: 'pointer', color: 'red.300' }}
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            onClick={removeFile(file)}
          />
        </Box>
      </Flex>
    </ListItem>
  ));

  const handleModal = (file: any) => {
    setToRemoveFile(file);
    setIsModalOpen(true);
  };

  const uploadedFileList = myUploadedFiles.map((file: any, index: number) => (
    <ListItem key={index} padding="8px 0 8px 0">
      <Flex justify="space-between">
        <FileDetails
          fileName={file.file_name?.replace(/\.[^/.]+$/, '')}
          fileSize={file.file_size}
          mimeType={file.mime_type}
        />
        <Box w="4" h="4" mr={1} alignSelf="baseline">
          <IconButton
            color="gray.300"
            _hover={{ cursor: 'pointer', color: 'red.300' }}
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            onClick={() => handleModal(file)}
          />
        </Box>
      </Flex>
    </ListItem>
  ));

  const removeUploadedFile = () => {
    deleteAttachment(toRemoveFile.id);
    const newFiles = [...myUploadedFiles];
    newFiles.splice(myUploadedFiles.indexOf(toRemoveFile), 1);
    setMyUploadedFiles(newFiles);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      setMyFiles([]);
      setValue(fileKey, []);
    }
  }, [clearFileInputToggle, fileKey, setValue]);

  return (
    <>
      <Center
        {...getRootProps()}
        p={['3', '6']}
        border="2px"
        borderStyle="dashed"
        borderColor="gray.100"
        cursor="pointer"
        w={width}
        h="220px"
        bg="gray.50">
        <input {...getInputProps()} />
        <VStack>
          <Icon as={BiPlus} h="16" w="16" color="gray.300" />
          {isDragActive ? (
            <Text textAlign="center" color="gray.400">
              {title}
            </Text>
          ) : (
            <Text textAlign="center" color="gray.400">
              {description}
            </Text>
          )}
        </VStack>
      </Center>
      {myUploadedFiles.length > 0 && (
        <List py="2" spacing="2" w={width}>
          {uploadedFileList}
        </List>
      )}
      {files.length > 0 && (
        <List py="2" spacing="2" w={width}>
          {files}
        </List>
      )}

      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${strings.delete_file}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.confirm_delete}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="red" onClick={() => removeUploadedFile()}>
                {strings.delete}
              </Button>
              <Button variant="outline" onClick={() => setIsModalOpen(false)}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Uploader;
