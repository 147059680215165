import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  schemeData: any;
  schemeType: string;
  currentType: string;
}

const SchemeFormByDay: React.FC<Props> = (props) => {
  const { schemeData, schemeType, currentType } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    getValues,
    reset,
  } = useFormContext<any>();

  useEffect(() => {
    return () => {
      let vals = { ...getValues() };
      //remove property prom object
      delete vals.scheme_data;
      reset(vals);
    };
  }, [getValues, reset]);

  useEffect(() => {
    let setData = schemeType === currentType;
    for (let index: number = 0; index < 2; index++) {
      if (schemeData && schemeData.length > 0 && setData) {
        setValue(`scheme_data.${index}.name`, schemeData[index]['meta']['day']);
        setValue(`scheme_data.${index}.value`, schemeData[index]['price']);
      }
    }
  }, [schemeData, setValue, schemeType, currentType]);

  const schemeByDay = () => {
    let content = [];

    for (let index = 0; index < 2; index++) {
      content.push(
        <Fragment key={index}>
          <GridItem>
            <FormControl isInvalid={!!errors?.scheme_data?.[index]?.name}>
              <FormLabel>{strings.day}</FormLabel>
              <Select
                placeholder={strings.select}
                rounded="sm"
                {...register(`scheme_data.${index}.name`, {
                  required: strings.required,
                })}>
                <option value="SUN"> {strings.sun} </option>
                <option value="MON-FRI"> {strings.mon_fri} </option>
              </Select>
              <FormErrorMessage>
                {errors?.scheme_data?.[index]?.name &&
                  errors?.scheme_data?.[index]?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.scheme_data?.[index]?.value}>
              <FormLabel>{strings.price} (Cent)</FormLabel>
              <Input
                type="number"
                defaultValue={0}
                placeholder={strings.enter_value_in_cents}
                {...register(`scheme_data.${index}.value`, {
                  required: strings.required,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.scheme_data?.[index]?.value &&
                  errors?.scheme_data?.[index]?.value?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Fragment>
      );
    }
    return content;
  };

  return (
    <>
      <Grid
        gap="4"
        templateColumns="repeat(2, 1fr)"
        borderWidth="1px"
        borderRadius="lg"
        p="4"
        flex="1"
        w="100%">
        {schemeByDay()}
      </Grid>
    </>
  );
};

export default SchemeFormByDay;
