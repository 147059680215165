import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ArticleUnitsResource from 'api/article-units';
import { wrapperStyles } from 'assets/css/commonStyles';
import TypeForm from 'components/article-type/TypeForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ArticleUnitSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import history from 'utils/history';

const AddUnit: React.FC = () => {
  const methods = useForm<ArticleUnitSchema>();
  const articleUnitsAPI = new ArticleUnitsResource();
  const toast = useToast();
  const [errMsg, setErrMsg] = useState('');

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const createArticleUnit = useMutation((data: any) =>
    articleUnitsAPI.store(data)
  );

  const onSubmit = (data: ArticleUnitSchema) => {
    data.created_by = loggedInUser.id;
    createArticleUnit.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: `${strings.unit} ${strings.has_been_created}`,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.mms.units.list);
      },
      onError: () =>
        setErrMsg(`${strings.unit} ${strings.has_not_been_created}`),
    });
  };

  const handleReset = () => {
    methods.reset();
    setErrMsg('');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.add_unit}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.units.list}>
              {strings.unit}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.mms.units.add}>
              {strings.add_unit}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.add_unit}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <Stack direction="column" spacing="4">
                <TypeForm />

                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    isLoading={createArticleUnit.isLoading}
                    type="submit">
                    {strings.add_now}
                  </Button>
                  <Button variant="outline" onClick={handleReset}>
                    {strings.clear}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddUnit;
