import http from 'utils/http';
import Resource from './resource';
class ServiceResource extends Resource {
  constructor() {
    super('mdm/services');
  }

  update(id: any, resource: any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'post',
      data: resource,
    });
  }
}

export { ServiceResource as default };
