import { ObjektImageOrderPayload } from 'components/rms/AccordionComponents/PicturePanel';
import { ObjektPictureEditFormValue } from 'components/rms/AccordionComponents/PicturePanel/ObjektPictureEdit';
import { ResponsibilitiesIdType } from 'components/rms/ObjecktDetailsResponsibilities';
import http from 'utils/http';
import Resource from './resource';

type UpdateObjectImageDetail = {
  apartmentId: number;
  imageId: number;
  data: ObjektPictureEditFormValue;
};

type UploadObjectImages = {
  apartmentId: number;
  data: FormData;
};

type UpdateImageOrder = {
  apartmentId: number;
  data: ObjektImageOrderPayload;
};

class RMSObjectsResource extends Resource {
  constructor() {
    super(`rms/apartments`);
  }
  assignResponsibilities(apartmentId: number, data: ResponsibilitiesIdType) {
    return http({
      url: `${this.uri}/assigned-user/${apartmentId}`,
      method: 'PUT',
      data: data,
    });
  }

  updateObjectImageDetail({
    apartmentId,
    imageId,
    data,
  }: UpdateObjectImageDetail) {
    return http({
      url: `${this.uri}/${apartmentId}/images/${imageId}`,
      method: 'PATCH',
      data,
    });
  }

  uploadObjectImages({ apartmentId, data }: UploadObjectImages) {
    return http({
      url: `${this.uri}/${apartmentId}/images`,
      method: 'POST',
      data,
    });
  }

  updateImageOrder({ apartmentId, data }: UpdateImageOrder) {
    return http({
      url: `${this.uri}/${apartmentId}/update-images-order`,
      method: 'PUT',
      data,
    });
  }

  deleteObjectImages(imageId: number) {
    return http({
      url: `/rms/apartments-images/${imageId}`,
      method: 'DELETE',
    });
  }
}

export { RMSObjectsResource as default };
