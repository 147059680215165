import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';
import NewsletterAPI from 'api/newsletter';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import { InstagramCircle, PinterestCircle, VimeoCircle } from 'assets/icons';
import { BusinessSolutionLogo } from 'assets/images';
import CompressedUploader from 'components/common/CompressedUploader';
import { strings } from 'config/localization';
import {
  IMPRINT_URL,
  INSTAGRAM_URL,
  NEWSLETTER_ACCEPT_FILE_TYPE,
  PINTEREST_URL,
  PRIVACY_POLICY_URL,
  VIMEO_URL,
  WEBSITE_URL,
} from 'constants/common';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BiShow, BiTrash } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  capitalizeFirstLetter,
  GroupOfRecipientOptionLocalization,
  localizeGroupOfRecipient,
} from 'utils';

interface Props {
  data?: any;
  clearFileInputToggle?: boolean;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
}
interface ComposeMessageParams {
  recipient_group: string[];
  subject: string;
  message: string;
  status?: string;
  file: {
    fileName: string[];
    fileSize: number[];
  };
  recipient_count?: number;
}

const ComposeNewsletterForm: React.FC<Props> = (props) => {
  let { data, clearFileInputToggle = false, onSubmit, isLoading } = props;
  const tinymceRef = useRef<any>();
  const groupOfRecipientOptions = GroupOfRecipientOptionLocalization();

  const cancelConfirmRef = useRef<any>();
  const newsletterAPI = new NewsletterAPI();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const history = useHistory();

  const deleteAttachment = useMutation((attachmentId: number) =>
    newsletterAPI.destroyAttachment(attachmentId)
  );

  const handleDeleteAttachment = (attachmentId: number) => {
    deleteAttachment.mutate(attachmentId);
  };

  const {
    register,
    formState: { errors },
    setValue,
    control,
    getValues,
    setError,
    clearErrors,
  } = useFormContext<any>();

  const [composeNewsletterContent, setComposeNewsletterContent] =
    useState<ComposeMessageParams>({
      recipient_group: data?.recipient_group ?? [],
      subject: data?.subject ?? '',
      message: data?.message ?? '',
      file: {
        fileName: [],
        fileSize: [],
      },
      status: data?.status ?? '',
    });

  const addedFiles = useWatch({ name: 'file' });
  useEffect(() => {
    if (addedFiles?.length > 0) {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: addedFiles.map((item: any) => item.name),
          fileSize: addedFiles.map((item: any) => item.size),
        },
      }));
    } else {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: [],
          fileSize: [],
        },
      }));
    }
  }, [addedFiles]);

  useEffect(() => {
    const previouslyAddedFileName: string[] = data?.newsletterFiles?.map(
      (item: any) => item?.file_name
    );
    const previouslyAddedFileSize: number[] = data?.newsletterFiles?.map(
      (item: any) => item?.file_size
    );

    if (data?.newsletterFiles.length > 0) {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: previouslyAddedFileName,
          fileSize: previouslyAddedFileSize,
        },
      }));
    }
  }, [data?.newsletterFiles]);

  useEffect(() => {
    setValue('recipient_group', composeNewsletterContent.recipient_group);
    setValue('subject', composeNewsletterContent.subject);
    setValue('message', tinymceRef.current.currentContent);
    setValue('status', composeNewsletterContent.status);

    if (composeNewsletterContent.recipient_group.length > 0) {
      clearErrors('recipient_group');
    }
    if (composeNewsletterContent.subject) {
      clearErrors('subject');
    }
    if (tinymceRef.current.currentContent) {
      clearErrors('message');
    }
  }, [composeNewsletterContent, setValue, clearErrors]);

  const handleSelectOptionChange = (recipient: any) => {
    const recipient_group_array = recipient?.map(
      (element: any) => element.value
    );
    setComposeNewsletterContent((prevState) => ({
      ...prevState,
      recipient_group: recipient_group_array,
    }));
  };

  const handleEditorChange = (content: any, editor: any) => {
    setComposeNewsletterContent((prevState: any) => ({
      ...prevState,
      message: content,
    }));
  };

  const handleInputChange = (e: any) => {
    const subject = e.target.value;
    setComposeNewsletterContent((prevState: any) => ({
      ...prevState,
      subject,
    }));
  };

  const handleClearEditor = () => {
    tinymceRef.current.editor.setContent('');
    setComposeNewsletterContent((prevState: any) => ({
      ...prevState,
      file: {
        fileName: [],
        fileSize: [],
      },
    }));
  };

  const validateMandatoryFields = () => {
    if (!getValues('subject')) {
      setError('subject', { message: strings.subject_field_is_required });
    }
    if (!tinymceRef.current.currentContent) {
      setError('message', { message: strings.message_field_is_required });
    }
    if (getValues('recipient_group')?.length === 0) {
      setError('recipient_group', {
        message: strings.recipient_group_field_is_required,
      });
    }
  };

  const handleSaveAndSendButtonClick = () => {
    validateMandatoryFields();

    if (Object.keys(errors).length === 0) {
      onConfirmOpen();
    }
  };

  const handleConfirmClick = () => {
    const formValues = getValues();
    formValues.status = 'confirmed';
    onSubmit(formValues);
  };

  const handleDraftButtonClick = () => {
    validateMandatoryFields();

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formValues = getValues();
    formValues.status = 'pending';
    onSubmit(formValues);
  };

  return (
    <>
      <Stack sx={wrapperStyles} overflow="auto">
        <Grid gap="4">
          <GridItem>
            <FormControl
              display="flex"
              width="100%"
              isInvalid={!!errors?.recipient_group}
              isRequired>
              <FormLabel w={['10%']}>{strings.group_of_recipients}</FormLabel>
              <Stack w={['90%']}>
                <Controller
                  control={control}
                  name="recipient_group"
                  rules={{
                    required: strings.recipient_group_field_is_required,
                  }}
                  render={({ field: { ref } }) => (
                    <ReactSelect
                      ref={ref}
                      id="recipient_group"
                      placeholder={strings.select_recipient_group}
                      styles={reactSelectStyles}
                      value={composeNewsletterContent?.recipient_group?.map(
                        (item: any) => ({
                          value: item,
                          label: localizeGroupOfRecipient(
                            capitalizeFirstLetter(item)
                          ),
                        })
                      )}
                      options={groupOfRecipientOptions}
                      onChange={handleSelectOptionChange}
                      isMulti
                      closeMenuOnSelect={false}
                    />
                  )}
                />

                <FormErrorMessage>
                  {errors?.recipient_group && errors?.recipient_group?.message}
                </FormErrorMessage>
              </Stack>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              display="flex"
              width="100%"
              isInvalid={!!errors?.subject}
              isRequired>
              <FormLabel w={['10%']}>{strings.subject}</FormLabel>
              <Stack w={['90%']}>
                <Input
                  type="text"
                  placeholder={strings.subject}
                  {...register('subject', {
                    required: strings.subject_field_is_required,
                    validate: (value) => {
                      if (value?.trim() === '')
                        return strings.subject_field_is_required;
                    },
                  })}
                  value={composeNewsletterContent?.subject}
                  onChange={handleInputChange}
                />
                <FormErrorMessage>
                  {errors?.subject && errors?.subject?.message}
                </FormErrorMessage>
              </Stack>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              display="flex"
              width="100%"
              isInvalid={!!errors?.message}
              isRequired>
              <FormLabel w={['10%']}>{strings.message}</FormLabel>
              <Stack w={['90%']}>
                <Controller
                  control={control}
                  name="message"
                  rules={{
                    required: strings.message_field_is_required,
                  }}
                  render={() => {
                    return (
                      <Editor
                        tinymceScriptSrc={
                          process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                        }
                        plugins="wordcount"
                        init={{
                          icons: 'material',
                          plugins: 'code image link lists ',
                          toolbar:
                            'undo redo | fontsize | fontfamily | styles | bold italic underline forecolor backcolor | link image code | align | bullist numlist | preview ',
                          font_size_formats:
                            '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                          toolbar_location: 'bottom',
                          height: 500,
                          menubar: 'false',
                          textcolor_rows: '4',
                          branding: false,
                          link_default_target: '_blank',
                        }}
                        onEditorChange={handleEditorChange}
                        value={composeNewsletterContent?.message}
                        ref={tinymceRef}
                        id="message"
                      />
                    );
                  }}
                />

                <FormErrorMessage>
                  {errors?.message && errors?.message?.message}
                </FormErrorMessage>
              </Stack>
            </FormControl>
          </GridItem>
          <ButtonGroup
            variant="outline"
            spacing="2"
            justifyContent={'flex-end'}
            p="1"
            rounded="md">
            <Tooltip label={strings.preview} hasArrow>
              <IconButton
                size="sm"
                variant="outline"
                colorScheme="gray"
                aria-label="Send email"
                icon={<BiShow />}
                _hover={{
                  color: 'green',
                }}
                onClick={onOpen}
              />
            </Tooltip>
            <Tooltip label={strings.clear} hasArrow>
              <IconButton
                size="sm"
                variant="outline"
                colorScheme="gray"
                aria-label="Send email"
                icon={<BiTrash />}
                _hover={{
                  color: 'red',
                }}
                onClick={handleClearEditor}
              />
            </Tooltip>
          </ButtonGroup>
          <GridItem>
            <FormControl display="flex" width="100%">
              <FormLabel w={['10%']}>{strings.upload_files}</FormLabel>
              <Stack w={['90%']}>
                <CompressedUploader
                  uploadedFiles={data?.newsletterFiles}
                  deleteAttachment={(attachmentId: number) =>
                    handleDeleteAttachment(attachmentId)
                  }
                  clearFileInputToggle={clearFileInputToggle}
                  fileKey="file"
                  acceptFileType={NEWSLETTER_ACCEPT_FILE_TYPE}
                />
              </Stack>
            </FormControl>
          </GridItem>
        </Grid>
        <Stack>
          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelConfirmRef}
            onClose={onConfirmClose}
            isOpen={isConfirmOpen}
            isCentered>
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>{strings.send_newsletter} ?</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                {strings.attention_notification_will_be_send_continue} ?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelConfirmRef} onClick={onConfirmClose}>
                  {strings.no}
                </Button>
                <Button
                  ml={3}
                  colorScheme="red"
                  isLoading={isLoading}
                  onClick={handleConfirmClick}>
                  {strings.yes}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Stack>

        <Stack>
          <ButtonGroup>
            <Button
              colorScheme="primary"
              onClick={handleSaveAndSendButtonClick}>
              {strings.save_and_send}
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleDraftButtonClick}
              isLoading={
                composeNewsletterContent.status === 'pending'
                  ? isLoading
                  : false
              }>
              {strings.save_as_draft}
            </Button>
            <Button variant="outline" onClick={() => history.goBack()}>
              {strings.cancel}
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>

      {/* preview page Modal */}

      <Modal isOpen={isOpen} size="5xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.preview}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction="column"
              spacing="4"
              bg="white"
              p={['4', '6']}
              shadow="box"
              w="full">
              <Stack dir="column">
                <Flex>
                  {strings.subject}:&nbsp;
                  <Box fontWeight="bold">
                    {composeNewsletterContent.subject}
                  </Box>
                </Flex>
                <Flex>
                  {strings.group_of_recipients}:
                  {composeNewsletterContent.recipient_group.map(
                    (item: any, id: number) => (
                      <Flex pl="4" key={id}>
                        {id !== 0 && <Divider orientation="vertical" pl="3" />}
                        <Box
                          fontWeight="bold"
                          textTransform="capitalize"
                          alignSelf="center">
                          {localizeGroupOfRecipient(item)}
                        </Box>
                      </Flex>
                    )
                  )}
                </Flex>
              </Stack>
              <Stack
                direction="column"
                spacing="4"
                bg="gray.100"
                shadow="box"
                w="full">
                <Stack spacing="4" p="4">
                  <Center>
                    <Image
                      src={BusinessSolutionLogo}
                      width="340px"
                      objectFit="contain"
                    />
                  </Center>
                </Stack>
                <Divider borderColor="gray.300" />
                <Stack
                  dir="column"
                  spacing="4"
                  height="auto"
                  p="8"
                  marginTop="0 !important">
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                    }
                    disabled={true}
                    inline={true}
                    initialValue={composeNewsletterContent?.message}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing="4"
                bg="gray.200"
                color="gray.700"
                shadow="box"
                p={['0', '4']}
                marginTop="8px !important"
                w="full">
                <Center>
                  <Flex paddingInline="4">
                    <a
                      href={WEBSITE_URL}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Box fontWeight="bold" textTransform="capitalize">
                        {strings.website}
                      </Box>
                    </a>
                  </Flex>
                  <Text fontSize="20px" fontWeight="bold">
                    |
                  </Text>
                  <Flex paddingInline="4">
                    <a
                      href={PRIVACY_POLICY_URL}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Box fontWeight="bold" textTransform="capitalize">
                        {strings.privacy}
                      </Box>
                    </a>
                  </Flex>
                  <Text fontSize="20px" fontWeight="bold">
                    |
                  </Text>
                  <Flex paddingInline="4">
                    <a
                      href={IMPRINT_URL}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Box fontWeight="bold" textTransform="capitalize">
                        {strings.imprint}
                      </Box>
                    </a>
                  </Flex>
                </Center>
                <Stack
                  dir="column"
                  spacing="4"
                  height="auto"
                  p="4"
                  marginTop="0 !important">
                  <Grid
                    gap="2"
                    templateColumns={[
                      'repeat(1, 1fr)',
                      'repeat(1, 1fr)',
                      'repeat(2, 1fr)',
                      'repeat(2, 1fr)',
                      'repeat(4, 1fr)',
                    ]}
                    w="100%">
                    <GridItem span={6} mb={2}>
                      <Box>
                        <Text>Appartementvermittlung Familie</Text>
                        <Text>Clausen GmbH</Text>
                        <Text>Am Buttgraben 6a</Text>
                        <Text>25992 List</Text>
                      </Box>
                    </GridItem>
                    <GridItem span={6} mb={2}>
                      <Box>
                        <Text>Telefon: 04651 889 8570</Text>
                        <Text>Telefax: 04651 877 551</Text>
                        <Text>eMail: info@listinfo.de</Text>
                        <Text>Internet: www.list-info.de</Text>
                      </Box>
                    </GridItem>
                    <GridItem span={6} mb={2}>
                      <Box>
                        <Text>
                          Geschäftsführer/in: Cornelia Clausen, Jörn Clausen
                        </Text>
                        <Text>Amtsgericht Flensburg: HRB 10714 FL</Text>
                        <Text>Erfüllungsort: List</Text>
                        <Text>Ust.-IdNr: DE297105589</Text>
                      </Box>
                    </GridItem>
                    <GridItem span={6} mb={2}>
                      <Box>
                        <Text>Volskbank Schleswig</Text>
                        <Flex>
                          <Text>IBAN: DE72 2169 0020 0001 4526 81</Text>
                        </Flex>
                        <Text>BIC: GENODEF1SLW</Text>
                      </Box>
                    </GridItem>
                  </Grid>
                  <Center>
                    <Box textAlign="center">
                      <Text>
                        Sie erhalten diese E-Mail, weil sie den Newsletter der
                        Appartementvermittlung Familie Clausen abonniert haben.
                      </Text>
                      <Flex w="max-content" margin="auto">
                        <Text>Nicht mehr interessiert?</Text>&nbsp;
                        <Text color="blue" textDecoration="underline">
                          Hier abmelden.
                        </Text>
                      </Flex>
                    </Box>
                  </Center>
                </Stack>

                {/* <Stack dir="column"> //just in case it is need for future
                <Text color="gray.400">{strings.download_our_app_now}</Text>
                <Flex>
                  <Image src={GooglePlay} w="24" />
                  <Spacer />
                  <Image src={AppStore} w="24" ml="4" />
                </Flex>
              </Stack> */}
                <Center>
                  <Flex>
                    <Tooltip label="Instagram" hasArrow>
                      <a
                        href={INSTAGRAM_URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Box
                          bg="white"
                          shadow="box"
                          w={['14', '16']}
                          h={['14', '16']}
                          rounded="full"
                          marginInline="3"
                          p="3">
                          <Image src={InstagramCircle} />
                        </Box>
                      </a>
                    </Tooltip>
                    <Tooltip label="Vemo" hasArrow>
                      <a
                        href={VIMEO_URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Box
                          bg="white"
                          shadow="box"
                          w={['14', '16']}
                          h={['14', '16']}
                          rounded="full"
                          marginInline="3"
                          p="3">
                          <Image src={VimeoCircle} />
                        </Box>
                      </a>
                    </Tooltip>
                    <Tooltip label="Pinterest" hasArrow>
                      <a
                        href={PINTEREST_URL}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Box
                          bg="white"
                          shadow="box"
                          w={['14', '16']}
                          h={['14', '16']}
                          rounded="full"
                          marginInline="3"
                          p="3">
                          <Image src={PinterestCircle} />
                        </Box>
                      </a>
                    </Tooltip>
                  </Flex>
                </Center>
                <Center>
                  <Text textAlign="center">
                    &copy; 2022 Appartementvermittlung Familie Clausen GmbH
                  </Text>
                </Center>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ComposeNewsletterForm;
