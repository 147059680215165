import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Tr,
} from '@chakra-ui/react';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { BreadPriceBreakdownSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { formatPrice } from 'utils';

const tdHoverStyle = {
  _after: {
    content: `""`,
    position: 'absolute',
    left: ' 0',
    top: '-5000px',
    width: '1px',
    height: '100%',
    bg: 'gray.300',
    opacity: '0.6',
  },
};
const stepStyled = {
  color: 'gray.500',
  size: 'sm',
  rounded: 'full',
  w: '10',
  h: '10',
  marginRight: '10',
  _after: {
    content: `""`,
    position: 'absolute',
    left: ' 0',
    top: '-5000px',
    width: '1px',
    height: '100%',
    bg: 'gray.300',
    opacity: '0.6',
  },
};

interface Props {
  breadBookingData: any;
}

const PriceDifference: React.FC<Props> = (props) => {
  const { breadBookingData } = props;

  const [oldPrice, setOldPrice] = useState<BreadPriceBreakdownSchema>();
  const [newPrice, setNewPrice] = useState<BreadPriceBreakdownSchema>();

  useEffect(() => {
    function calculatePrice(taxableSum: number, totalBread: number, days: any) {
      const totalOrderedDays = [
        ...new Set(days.map((day: any) => day.order_date)),
      ].length;

      const bookingFee = totalOrderedDays * breadBookingData.booking_fee;
      let amountWithoutTax = taxableSum / 1.26;
      amountWithoutTax = ~~amountWithoutTax; //Bitwise not operator: round to nearest integer
      let vat19 = ~~(amountWithoutTax * 0.19);
      let vat7 = ~~(amountWithoutTax * 0.07);
      let subTotal = taxableSum - vat19 - vat7;

      const finalTotal = taxableSum + bookingFee;
      let oldPriceBreakdown = {
        taxableSum,
        bookingFee,
        vat19,
        vat7,
        totalBread,
        subTotal,
        finalTotal,
      };
      return oldPriceBreakdown;
    }

    function calculateOldPrice(oldData: any) {
      const taxableSum = oldData.reduce(
        (acc: number, cur: { unit_price: number; order_quantity: number }) =>
          acc + cur.unit_price * cur.order_quantity,
        0
      );
      const totalBread = oldData.reduce(
        (acc: number, cur: { order_quantity: number }) =>
          acc + cur.order_quantity,
        0
      );
      return calculatePrice(taxableSum, totalBread, oldData);
    }

    function calculateNewPrice(newData: any) {
      const taxableSum = newData.reduce(
        (
          acc: number,
          cur: {
            unit_price: number;
            order_quantity: number;
            reconciled_quantity: any;
          }
        ) => {
          let qty = cur?.reconciled_quantity ?? cur?.order_quantity;
          qty = qty || 0;
          return acc + cur.unit_price * qty;
        },
        0
      );
      const totalBread = newData.reduce(
        (
          acc: number,
          cur: { order_quantity: number; reconciled_quantity: number }
        ) => {
          let qty = cur?.reconciled_quantity ?? cur?.order_quantity;
          qty = qty || 0;
          return acc + qty;
        },
        0
      );
      let daysList: { order_date: string }[] = [];
      newData.forEach((item: any) => {
        let qty = item?.reconciled_quantity ?? item?.order_quantity;
        if (qty) {
          daysList.push({ order_date: item.order_date });
        }
      });
      return calculatePrice(taxableSum, totalBread, daysList);
    }

    let oldPrice = calculateOldPrice(
      breadBookingData.bakery_reservation_billing_particular
    );
    let newPrice = calculateNewPrice(
      breadBookingData.bakery_reservation_billing_particular
    );

    setOldPrice(oldPrice);
    setNewPrice(newPrice);
  }, [breadBookingData]);

  return (
    <>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        w="100%">
        <GridItem>
          <Flex justify="space-between">
            <Heading size="md" textTransform="capitalize">
              {strings.old_price}
            </Heading>
          </Flex>
          <Stack bg="gray.200" p={['3', '6']} shadow="box" rounded="sm" mt="4">
            <TableContainer>
              <Table>
                <Tbody>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="transparent">
                      {strings.total_bread}
                    </Th>
                    <Th isNumeric borderColor="transparent">
                      {oldPrice?.totalBread}
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      borderColor="gray"
                      colspan="5"
                      textAlign="right"
                      borderStyle="dashed">
                      {strings.total} (&euro;)
                    </Th>
                    <Th borderColor="gray" isNumeric borderStyle="dashed">
                      {formatPrice(oldPrice?.taxableSum)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      borderColor="transparent"
                      colspan="5"
                      textAlign="right">
                      {strings.without_tax} {strings.subtotal} (&euro;)
                    </Th>
                    <Th borderColor="transparent" isNumeric>
                      {formatPrice(oldPrice?.subTotal)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="transparent">
                      {strings.vat19} (&euro;)
                    </Th>
                    <Th isNumeric borderColor="transparent">
                      {formatPrice(oldPrice?.vat19)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="gray"
                      borderStyle="dashed">
                      {strings.vat7} (&euro;)
                    </Th>
                    <Th isNumeric borderColor="gray" borderStyle="dashed">
                      {formatPrice(oldPrice?.vat7)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="gray"
                      borderStyle="dashed">
                      {strings.booking_fee_pound}
                    </Th>
                    <Th isNumeric borderColor="gray" borderStyle="dashed">
                      {formatPrice(oldPrice?.bookingFee)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right">
                      {strings.grand_total} (&euro;)
                    </Th>
                    <Th isNumeric>
                      {formatPrice(oldPrice?.finalTotal)} &euro;
                    </Th>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </GridItem>
        <GridItem>
          <Flex justify="space-between">
            <Heading size="md" textTransform="capitalize">
              {strings.new_price}
            </Heading>
          </Flex>
          <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm" mt="4">
            <TableContainer>
              <Table>
                <Tbody>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="transparent">
                      {strings.total_bread}
                    </Th>
                    <Th isNumeric borderColor="transparent">
                      {newPrice?.totalBread}
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      borderColor="gray"
                      colspan="5"
                      textAlign="right"
                      borderStyle="dashed">
                      {strings.total} (&euro;)
                    </Th>
                    <Th borderColor="gray" isNumeric borderStyle="dashed">
                      {formatPrice(newPrice?.taxableSum)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      borderColor="transparent"
                      colspan="5"
                      textAlign="right">
                      {strings.without_tax} {strings.subtotal} (&euro;)
                    </Th>
                    <Th borderColor="transparent" isNumeric>
                      {formatPrice(newPrice?.subTotal)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="transparent">
                      {strings.vat19} (&euro;)
                    </Th>
                    <Th isNumeric borderColor="transparent">
                      {formatPrice(newPrice?.vat19)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="gray"
                      borderStyle="dashed">
                      {strings.vat7} (&euro;)
                    </Th>
                    <Th isNumeric borderColor="gray" borderStyle="dashed">
                      {formatPrice(newPrice?.vat7)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right"
                      borderColor="gray"
                      borderStyle="dashed">
                      {strings.booking_fee_pound}
                    </Th>
                    <Th isNumeric borderColor="gray" borderStyle="dashed">
                      {formatPrice(newPrice?.bookingFee)} &euro;
                    </Th>
                  </Tr>
                  <Tr>
                    <Th
                      fontWeight="bold"
                      color="blackAlpha.900"
                      colspan="5"
                      textAlign="right">
                      {strings.grand_total} (&euro;)
                    </Th>
                    <Th isNumeric>
                      {formatPrice(newPrice?.finalTotal)} &euro;
                    </Th>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </GridItem>
      </Grid>
      <Flex justify="space-between">
        <Heading size="md">{strings.price_difference}</Heading>
      </Flex>
      <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm" mt="4">
        {newPrice && oldPrice && (
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.bread_quantity_difference}
                </FormLabel>
                <Box sx={infoStyles}>
                  {newPrice?.totalBread - oldPrice?.totalBread}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.total_fee_difference} (&euro;)
                </FormLabel>
                <Box sx={infoStyles}>
                  {formatPrice(newPrice?.bookingFee - oldPrice?.bookingFee)}
                  &nbsp;&euro;
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.grand_total_difference} (&euro;)
                </FormLabel>
                <Box sx={infoStyles}>
                  {formatPrice(newPrice?.finalTotal - oldPrice?.finalTotal)}
                  &nbsp;&euro;
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        )}
      </Stack>
    </>
  );
};

export default PriceDifference;
