import { CrossSellingEmailPayloadInterface } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

class RMSCrossSellingResource extends Resource {
  constructor() {
    super('rms/cross-selling');
  }

  updateCrossSelling(id: string, data: any) {
    return http({
      url: `/${this.uri}/${id}`,
      method: 'POST',
      data,
    });
  }

  sendCrossSellingEmail(id: number, data: CrossSellingEmailPayloadInterface) {
    return http({
      url: `/${this.uri}/email/${id}`,
      method: 'POST',
      data,
    });
  }
}

export { RMSCrossSellingResource as default };
