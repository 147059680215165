import Resource from 'api/resource';
import http from 'utils/http';

class RolesResource extends Resource {
  constructor() {
    super('user/roles');
  }

  listUsers(id: any) {
    return http({
      url: '/' + this.uri + '/' + id + '/users',
      method: 'get',
    });
  }

  getPermissions(id: any) {
    return http({
      url: '/' + this.uri + '/' + id + '/permissions',
      method: 'get',
    });
  }
}

export { RolesResource as default };
