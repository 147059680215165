import { UpdateMarketingEmailDayPayload } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

class EmailMarketingResource extends Resource {
  constructor() {
    super('rms/reservations/marketing/email-list');
  }

  getMarketingEmailDaySetting() {
    return http({
      url: '/rms/setting',
      method: 'GET',
    });
  }

  updateMarketingEmailDaySetting(data: UpdateMarketingEmailDayPayload) {
    return http({
      url: '/rms/setting',
      method: 'PUT',
      data,
    });
  }

  resendMarketingEmail(id: number) {
    return http({
      url: `/rms/reservations/${id}/marketing/email/resend`,
      method: 'POST',
    });
  }
}

export { EmailMarketingResource as default };
