import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import BakeryBillingResource from 'api/bakery_billing';
import {
  infoStyles,
  labelStyles,
  selectColorScheme,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';

interface Props {
  breadBookingData: any;
  title?: string;
  pageName?: string;
  bookingId?: string;
  search?: string;
}

const BreadBookingBillingInfo: React.FC<Props> = (props) => {
  let { breadBookingData, search } = props;
  let { id, reservation_id, reservation } = breadBookingData;
  let status: 'confirmed' | 'cancelled' | 'pending' = breadBookingData?.status;

  let { booking_number, customer, apartment, bookings, booking_date } =
    reservation;
  let isStayPeriodOver = new Date(bookings.tilldate) < new Date();
  const toast = useToast();
  const queryClient = useQueryClient();
  const location = useLocation();
  const isEditPage = location.pathname.includes('edit');

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');

  const bakeryBillingAPI = new BakeryBillingResource();
  const closeBill = useMutation(
    (id: number) => bakeryBillingAPI.closeBill(id),
    {
      onSuccess: () => {
        setDeleteModalOpen(false);
        queryClient.invalidateQueries(`bakery-billing-details${id}`);
      },
      onError: (error: any) => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
        setDeleteErrorMsg(error?.response?.data?.error?.message);
      },
    }
  );
  const handleClose = () => {
    closeBill.mutate(reservation_id);
  };

  const openModal = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(true);
  };

  const onCancle = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(false);
  };
  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.booking_detail}
        </Heading>

        {status !== 'confirmed' && (
          <Stack direction="row" spacing="4">
            {isStayPeriodOver && (
              <Button
                size="sm"
                colorScheme="primary"
                variant="outline"
                onClick={openModal}>
                {strings.close_bill}
              </Button>
            )}

            {!isEditPage && (
              <Button
                size="sm"
                colorScheme="primary"
                onClick={() =>
                  history.push(
                    routes.bs.breadBookingBilling.edit.replace(
                      ':id',
                      reservation_id
                    ) + search
                  )
                }>
                {strings.edit_service}
              </Button>
            )}
          </Stack>
        )}
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_number}</FormLabel>
              <Box sx={infoStyles}>{booking_number ?? '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_date}</FormLabel>
              <Box sx={infoStyles}>{booking_date.split(' ')[0] ?? '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.property_name}</FormLabel>
              <Box sx={infoStyles}>{apartment?.info?.fullName ?? '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer_contact}</FormLabel>
              <Box sx={infoStyles}>-</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer_name}</FormLabel>
              <Box
                sx={
                  infoStyles
                }>{`${customer?.forename} ${customer?.surname}`}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.status}</FormLabel>
              <Box sx={infoStyles}>
                <Text
                  color={selectColorScheme(status)}
                  textTransform="uppercase"
                  fontWeight="medium"
                  fontSize="sm">
                  {strings[status] ?? '-'}
                </Text>
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_period}</FormLabel>
              <Box sx={infoStyles}>{`${DateFormat(
                bookings?.fromdate
              )} - ${DateFormat(bookings?.tilldate)}`}</Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      <Modal
        isOpen={isDeleteModalOpen}
        isCentered
        onClose={onCancle}
        size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {strings.are_you_sure_you_cannot_undo_this_action}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {deleteErrorMsg && (
              <Alert status="error" mb={2}>
                <AlertIcon />
                {deleteErrorMsg}
              </Alert>
            )}
            {strings.this_bill_will_be_closed_permanently}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <AccessControl
                allowedPermissions={[
                  PermissionRequest['read:bread-service'],
                  PermissionRequest['manage:bread-service'],
                ]}
                renderNoAccess={(data: any) => data}>
                <Button
                  colorScheme="primary"
                  onClick={handleClose}
                  isLoading={closeBill.isLoading}>
                  {strings.close_bill}
                </Button>
              </AccessControl>
              <Button
                variant="outline"
                onClick={onCancle}
                isDisabled={closeBill.isLoading}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default BreadBookingBillingInfo;
