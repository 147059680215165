import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { Merge, VOfficeLogo } from 'assets/icons';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ContactDetailInfo from 'components/crm/ContactDetailInfo';
import EventLogs from 'components/crm/EventLogs';
import LabelTags from 'components/crm/LabelTags';
import MergeHistoryDetails from 'components/crm/MergeHistoryDetails';
import { strings } from 'config/localization';
import { VOFFICE_CONTACT_DETAIL } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ContactSchema } from 'constants/schema';
import useIsPermitted from 'hooks/usIsPermitted';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BiEnvelopeOpen, BiLinkExternal, BiPhoneCall } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const ContactDetails: React.FC = () => {
  const { id: contactID } = useParams<{ id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const contactAPI = new CRMContactsResource();
  const { search } = useLocation();

  let { data: mergeDetail } = useQuery([`get-merge-detail-${contactID}`], () =>
    contactAPI
      .mergeDetails(contactID, { merge: 'direct' })
      .then((res) => res.data.data)
  );

  let { data: contactDetails, isLoading } = useQuery<ContactSchema>(
    [`get-contact-details-${contactID}`],
    () => contactAPI.get(contactID).then((res) => res.data.data)
  );

  const hasDashboardPermission = useIsPermitted([
    PermissionRequest['read:object-dashboard'],
  ]);

  if (isLoading) {
    return <CenterSpinner />;
  }

  if (!contactDetails) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.invalid_data}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.crm} | {contactDetails?.first_name ?? ' '}&nbsp;
          {contactDetails?.last_name ?? ' '}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
            {strings.crm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list + search}>
            {strings.contact_list}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {contactDetails?.first_name ?? '-'}&nbsp;
            {contactDetails?.last_name ?? '-'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack
        justifyContent="space-evenly"
        direction={['column', 'column', 'column', 'row']}
        spacing="5"
        overflow="auto">
        <Stack
          direction="column"
          spacing="8"
          flex="1"
          w={['100%', '100%', '100%', '60%']}>
          <Flex alignItems="center" justify="space-between">
            <Flex>
              <Heading size="md">{strings.contact_details}</Heading>
              <Stack ml="2" direction="row" justify="space-between">
                <LabelTags
                  label={
                    contactDetails.type === 'app'
                      ? 'web_app'
                      : contactDetails.type
                  }
                />
                {contactDetails?.contactDetail.is_merged && (
                  <Box w="6" h="6" ml="2" color="orange.300">
                    <Image src={Merge} />
                  </Box>
                )}
              </Stack>
            </Flex>
            <Flex gridGap="4" dir="row" justify="space-between">
              {hasDashboardPermission && (
                <Tooltip hasArrow placement="top" label={strings.dashboard}>
                  <RouterLink
                    target="_blank"
                    to={routes.objectDashboard.dashboard.customerBoard.replace(
                      ':id',
                      contactID
                    )}>
                    <Icon
                      aria-label={strings.dashboard}
                      as={BiLinkExternal}
                      h="6"
                      w="6"
                    />
                  </RouterLink>
                </Tooltip>
              )}
              {contactDetails?.email && (
                <a href={`mailto:${contactDetails?.email}`}>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={contactDetails?.email}>
                    <IconButton
                      aria-label={strings.mails}
                      icon={<BiEnvelopeOpen />}
                      variant="outline"
                      size="sm"
                      colorScheme="yellow"
                    />
                  </Tooltip>
                </a>
              )}
              {contactDetails?.phone && (
                <a href={`tel:${contactDetails?.phone}`}>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={contactDetails?.phone}>
                    <IconButton
                      aria-label={strings.call}
                      variant="outline"
                      icon={<BiPhoneCall />}
                      size="sm"
                      colorScheme="green"
                    />
                  </Tooltip>
                </a>
              )}
              {contactDetails?.v_office_id && (
                <a
                  href={VOFFICE_CONTACT_DETAIL + contactDetails.v_office_id}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Box pr="2" cursor="pointer">
                    <Tooltip
                      hasArrow
                      placement="top"
                      label={strings.voffice_contact_detail_page}>
                      <Image
                        src={VOfficeLogo}
                        alt="v-office contact"
                        width="10"
                        heihgt="10"
                      />
                    </Tooltip>
                  </Box>
                </a>
              )}
              <RouterLink
                to={
                  routes.crm.contact.edit.replace(':id', contactID.toString()) +
                  search
                }>
                <Button colorScheme="primary" size="sm">
                  {strings.edit_contact}
                </Button>
              </RouterLink>
            </Flex>
          </Flex>
          <Stack
            bg="white"
            p={['3', '6']}
            shadow="box"
            rounded="sm"
            overflow="auto">
            <ContactDetailInfo data={contactDetails} />

            {contactDetails?.contactDetail.is_merged && (
              <Box
                color="blue.300"
                _hover={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: 'blue.500',
                }}>
                <Box onClick={onOpen}>
                  <Flex>
                    <Box w="6" h="6" mr="2" color="orange.300">
                      <Image src={Merge} />
                    </Box>
                    {strings.merge_detail}
                  </Flex>
                </Box>
                <Modal isOpen={isOpen} onClose={onClose} size="6xl">
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>{strings.merge_detail}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody overflowX="auto">
                      <MergeHistoryDetails data={mergeDetail} />
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack
          direction="column"
          spacing="10"
          w={['100%', '100%', '100%', '40%']}
          overflow="auto">
          <Stack>
            <Heading size="md" textTransform="capitalize">
              {strings.event_history}
            </Heading>
          </Stack>
          {contactDetails && <EventLogs eventLogs={contactDetails} />}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ContactDetails;
