import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import RestaurantResource from 'api/restaurant';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import RestaurantForm from 'components/restaurant/RestaurantForm';
import { strings } from 'config/localization';
import { TOAST_DURATION, TOAST_STATUS } from 'constants/common';
import routes from 'constants/routes';
import { RestaurantFormDataObject, RestaurantSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const constructFormData = (formData: RestaurantFormDataObject): FormData => {
  const data = new FormData();

  if (formData.logo) {
    data.append('logo', formData.logo);
  }

  if (formData.cover) {
    data.append('cover', formData.cover);
  }

  if (formData.images && formData.images.length > 0) {
    formData.images.forEach((image: File, index: number) => {
      data.append(`images[${index}]`, image);
    });
  }

  data.append('name', formData.name);
  data.append('slug', formData.slug);
  data.append('zip_code', formData.zip_code);
  data.append('street', formData.street);
  data.append('house_number', formData.house_number);
  data.append('location', formData.location);
  data.append(
    'restaurant_reservation',
    formData.restaurant_reservation ? '1' : '0'
  );

  if (formData.menu) {
    data.append('menu', formData.menu);
  }

  if (formData.openingTimes) {
    const openingTimesJson = JSON.stringify(formData.openingTimes);
    data.append('opening_time', openingTimesJson);
  }

  if (formData.menu_file) {
    data.append('menu_file', formData.menu_file);
  }

  if (formData.website) {
    data.append('website', formData.website);
  }

  if (formData.description) {
    data.append('description', formData.description);
  }

  if (formData.short_description) {
    data.append('short_description', formData.short_description);
  }

  data.append('is_top_pick', formData.is_top_pick ? String(1) : String(0));

  return data;
};

const AddRestaurant: React.FC = () => {
  const { id: restaurantId } = useParams<{ id: string }>();

  const methods = useForm<RestaurantSchema>();
  const toast = useToast();
  let restaurantAPI = new RestaurantResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: restaurantDetail, isLoading: restaurantDetailDataLoading } =
    useQuery(['restaurant-details-page', restaurantId], () => {
      if (!restaurantId) return null; // { data: null };
      return restaurantAPI
        .get(restaurantId)
        .then((res) => res.data.data)
        .catch(() => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: TOAST_STATUS.ERROR,
            duration: TOAST_DURATION.LONG,
            isClosable: true,
          });
        });
    });

  const createRestaurant = useMutation(
    (data: any) => restaurantAPI.store(data),
    {
      onSuccess: (res: any) => {
        const id = res?.data?.data?.id;
        history.push(
          routes.restaurant.restaurant.details.replace(':id', id.toString())
        );
      },
    }
  );

  const updateRestaurant = useMutation(
    (data: any) => restaurantAPI.update(parseInt(restaurantId), data),
    {
      onSuccess: () => {
        history.push(
          routes.restaurant.restaurant.details.replace(
            ':id',
            restaurantId.toString()
          )
        );
      },
    }
  );

  const onSubmit = async (formData: any) => {
    let API;
    const data = constructFormData(formData);
    if (restaurantId) {
      API = updateRestaurant;
      data.append('_method', 'PATCH');
    } else {
      API = createRestaurant;
    }

    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.restaurant} ${
            restaurantId ? strings.has_been_updated : strings.has_been_created
          }`,
          status: TOAST_STATUS.SUCCESS,
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          type RestaurantKeys = keyof RestaurantSchema;
          const backendErrors = err.response.data.errors;
          const { setError } = methods;
          Object.keys(backendErrors).forEach((key) => {
            const formKey = key as RestaurantKeys;
            if (formKey in formData) {
              setError(formKey, { message: backendErrors[key][0] });
            }
          });
        }

        setErrMsg(
          `${strings.restaurant} ${
            restaurantId
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  if (restaurantDetailDataLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.restaurant} | &nbsp;
            {restaurantId
              ? strings.edit_restaurant
              : strings.add_new_restaurant}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.restaurant.restaurant.list}>
              {strings.restaurant}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {restaurantId
                ? restaurantDetail?.name
                : strings.add_new_restaurant}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {restaurantId
              ? strings.edit_restaurant
              : strings.add_new_restaurant}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {errMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errMsg}
                  </Alert>
                )}

                <RestaurantForm data={restaurantDetail} />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {!restaurantId ? strings.add_now : strings.update}
                  </Button>
                  <Button
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddRestaurant;
