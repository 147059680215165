import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import MeerSyltResource from 'api/sms_meer_sylt';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { MeerSyltSchema } from 'constants/schema';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { BiEdit, BiLinkExternal, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';

interface Props {
  meerSyltData: MeerSyltSchema;
  index: number;
  draggableProvider: DraggableProvided;
  search?: string;
}

interface UpdateMeerSyltType extends Partial<MeerSyltSchema> {
  _method: string;
}
const MeerSyltListItem: React.FC<Props> = (props) => {
  const { meerSyltData, index, draggableProvider, search } = props;
  const { is_active, is_load_on_iframe, title, text, link, id } = meerSyltData;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const checkEditPermission = useIsPermitted([PermissionRequest['read:sms']]);

  const [titleMsg, setTitleMsg] = useState({
    title: '',
    description: '',
  });
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({
    is_active: is_active,
    is_load_on_iframe: is_load_on_iframe,
  });

  const meerSyltAPI = new MeerSyltResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  useEffect(() => {
    if (meerSyltData) {
      setUpdatedValues({
        is_active: is_active || false,
        is_load_on_iframe: is_load_on_iframe || false,
      });
    }
  }, [meerSyltData, is_active, is_load_on_iframe]);

  const updateMeerSylt = useMutation(
    (data: UpdateMeerSyltType) =>
      meerSyltAPI.update(meerSyltData.id, data as MeerSyltSchema),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('meer-sylt-list');
        setUpdateModalOpen(false);
        toast({
          title: strings.updated,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const deleteMeerSylt = useMutation(
    () => meerSyltAPI.destroy(meerSyltData.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('meer-sylt-list');
        setDeleteModalOpen(false);
        toast({
          title: strings.meer_sylt_deleted_successfully,
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => {
        setDeleteErrorMsg(error?.response?.data?.message);
      },
    }
  );

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteMeerSylt.mutate();
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const message = isChecked
      ? strings.activate_message
      : strings.deactivate_message;

    setTitleMsg({
      title: message,
      description: message,
    });
    setUpdatedValues((prevState) => ({
      ...prevState,
      is_active: isChecked,
    }));

    setUpdateModalOpen(true);
  };

  const handleLoadOnIFrameStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setTitleMsg({
      title: strings.update,
      description: isChecked
        ? strings.load_within_iframe_message
        : strings.website_link_message,
    });
    setUpdatedValues((prevState) => ({
      ...prevState,
      is_load_on_iframe: isChecked,
    }));

    setUpdateModalOpen(true);
  };

  const onUpdateModalClose = () => {
    setUpdatedValues({
      is_active: is_active || false,
      is_load_on_iframe: is_load_on_iframe || false,
    });
    setUpdateModalOpen(false);
  };

  const onUpdate = () => {
    updateMeerSylt.mutate({ _method: 'PUT', ...updatedValues });
  };

  return (
    <Tr
      key={meerSyltData.id}
      ref={draggableProvider.innerRef}
      {...draggableProvider.draggableProps}
      {...draggableProvider.dragHandleProps}>
      <Td>{index}</Td>

      <Td>
        <RouterLink
          to={
            routes.sms.meerSylt.details.replace(':id', id.toString()) + search
          }>
          <Text
            fontWeight="medium"
            color="gray.500"
            _hover={{ color: 'gray.600' }}>
            {title}
          </Text>
        </RouterLink>
      </Td>
      <Td>{text ? truncateString(text, 50) : '-'}</Td>

      <Td>
        <a target="_blank" href={link} rel="noopener noreferrer">
          <Tooltip hasArrow label={strings.visit_link}>
            <IconButton
              icon={<BiLinkExternal />}
              variant="link"
              aria-label={strings.visit_link}
              minW="6"
            />
          </Tooltip>
        </a>
      </Td>
      <Td>
        <FormControl display="flex" alignItems="center">
          <Switch
            colorScheme="green"
            isDisabled={!checkEditPermission}
            onChange={handleActiveChange}
            isChecked={!!is_active}
          />
        </FormControl>
      </Td>
      <Td>
        <FormControl display="flex" alignItems="center">
          <Switch
            colorScheme="green"
            isDisabled={!checkEditPermission}
            onChange={handleLoadOnIFrameStatusChange}
            isChecked={!!is_load_on_iframe}
          />
        </FormControl>
      </Td>

      <Td>
        <RouterLink
          to={
            routes.sms.meerSylt.details.replace(':id', id.toString()) + search
          }>
          <Tooltip hasArrow label={strings.view_meer_sylt}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_meer_sylt}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={routes.sms.meerSylt.edit.replace(':id', id.toString()) + search}>
          <Tooltip hasArrow label={strings.edit_meer_sylt}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_meer_sylt}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_meer_sylt}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_meer_sylt}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_meer_sylt}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteMeerSylt.isLoading}>
                  {strings.delete}
                </Button>

                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isUpdateModalOpen}
          isCentered
          onClose={onUpdateModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <span style={{ textTransform: 'capitalize' }}>
                {titleMsg.title}
              </span>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {' '}
              {strings.formatString(
                strings.message_update_with_value,
                titleMsg.description
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  onClick={onUpdate}
                  isLoading={updateMeerSylt.isLoading}>
                  {strings.update}
                </Button>

                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={onUpdateModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default MeerSyltListItem;
