import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  Select,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BiCheck, BiMinusCircle, BiPlusCircle, BiX } from 'react-icons/bi';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link as RouterLink } from 'react-router-dom';
const CorrectInventory: React.FC = () => {
  const stepStyled = {
    bg: 'orange.100',
    my: '2',
    p: '2',
    fontSize: 'sm',
    rounded: 'sm',
    width: '100%',
    borderColor: 'transparent',
    _selected: {
      color: 'white',
      bg: 'orange.300',
    },
  };

  const headingStyled = {
    size: 'xs',
    fontSize: 'sm',
    color: 'gray.500',
    fontWeight: 'semibold',
  };
  const tabsStyled = {
    p: '0',
  };
  const borderLessStyled = {
    borderColor: 'transparent',
  };
  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mms} | {strings.correct_inventory}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
            {strings.mms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.mms.stocktaking.stocktakingMode}>
            {strings.stocktaking}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.mms.stocktaking.correctInventory}>
            {strings.correct_inventory}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.correct_inventory}
        </Heading>
        <Stack>
          <FormControl size="sm">
            <Select>
              <option value="option1">Warehouse</option>
              <option value="option2">Object</option>
              <option value="option3">Employee</option>
            </Select>
          </FormControl>
        </Stack>
      </Flex>
      <Stack
        bg="white"
        p="4"
        shadow="box"
        rounded="sm"
        w="100%"
        overflow="auto">
        <Stack dir="row" spacing="2">
          <Tabs colorScheme="gray" orientation="vertical">
            <TabList h="76vh" minW="180px" m="1" sx={borderLessStyled}>
              <Text sx={headingStyled}>{strings.warehouse}</Text>
              <PerfectScrollbar>
                <Tab sx={stepStyled}>Warehouse Main</Tab>
                <Tab sx={stepStyled}>Warehouse ABC</Tab>
                <Tab sx={stepStyled}>Warehouse XYZ</Tab>
                <Tab sx={stepStyled}>Warehouse 1</Tab>
                <Tab sx={stepStyled}>Warehouse 2</Tab>
                <Tab sx={stepStyled}>Warehouse Main</Tab>
                <Tab sx={stepStyled}>Warehouse ABC</Tab>
                <Tab sx={stepStyled}>Warehouse XYZ</Tab>
                <Tab sx={stepStyled}>Warehouse 1</Tab>
                <Tab sx={stepStyled}>Warehouse 2</Tab>
                <Tab sx={stepStyled}>Warehouse Main</Tab>
                <Tab sx={stepStyled}>Warehouse ABC</Tab>
                <Tab sx={stepStyled}>Warehouse XYZ</Tab>
                <Tab sx={stepStyled}>Warehouse 1</Tab>
                <Tab sx={stepStyled}>Warehouse 2</Tab>
              </PerfectScrollbar>
            </TabList>

            <TabPanels>
              <TabPanel sx={tabsStyled}>
                <Tabs colorScheme="gray" orientation="vertical">
                  <TabList h="76vh" minW="120px" m="1" sx={borderLessStyled}>
                    <Text sx={headingStyled}>{strings.room}</Text>
                    <PerfectScrollbar>
                      <Tab sx={stepStyled}>Room A1</Tab>
                      <Tab sx={stepStyled}>Room ABC</Tab>
                      <Tab sx={stepStyled}>Room XYZ</Tab>
                      <Tab sx={stepStyled}>Room 1</Tab>
                      <Tab sx={stepStyled}>Room 2</Tab>
                    </PerfectScrollbar>
                  </TabList>
                  <TabPanels>
                    <TabPanel sx={tabsStyled}>
                      <Tabs colorScheme="gray" orientation="vertical">
                        <TabList
                          h="76vh"
                          minW="50px"
                          m="1"
                          sx={borderLessStyled}>
                          <Text sx={headingStyled}>{strings.rack}</Text>
                          <PerfectScrollbar>
                            <Tab sx={stepStyled}>A</Tab>
                            <Tab sx={stepStyled}>B</Tab>
                            <Tab sx={stepStyled}>C</Tab>
                            <Tab sx={stepStyled}>D</Tab>
                            <Tab sx={stepStyled}>E</Tab>
                          </PerfectScrollbar>
                        </TabList>
                        <TabPanels>
                          <TabPanel sx={tabsStyled}>
                            <Tabs
                              colorScheme="gray"
                              orientation="vertical"
                              sx={tabsStyled}>
                              <TabList
                                h="76vh"
                                minW="50px"
                                m="1"
                                sx={borderLessStyled}>
                                <Text sx={headingStyled}>{strings.shelf}</Text>
                                <PerfectScrollbar>
                                  <Tab sx={stepStyled}>1</Tab>
                                  <Tab sx={stepStyled}>2</Tab>
                                  <Tab sx={stepStyled}>3</Tab>
                                  <Tab sx={stepStyled}>4</Tab>
                                  <Tab sx={stepStyled}>5</Tab>
                                  <Tab sx={stepStyled}>6</Tab>
                                  <Tab sx={stepStyled}>7</Tab>
                                  <Tab sx={stepStyled}>8</Tab>
                                  <Tab sx={stepStyled}>9</Tab>
                                  <Tab sx={stepStyled}>10</Tab>
                                </PerfectScrollbar>
                              </TabList>
                              <TabPanels>
                                <TabPanel h="76vh" p="2">
                                  <PerfectScrollbar>
                                    <TableContainer
                                      px="2"
                                      borderLeft="1px"
                                      borderColor="gray.300">
                                      <Table size="sm">
                                        <Thead>
                                          <Tr>
                                            <Th>#</Th>
                                            <Th>{strings.article_name}</Th>
                                            <Th isNumeric>
                                              {strings.quantity}
                                            </Th>
                                            <Th>{strings.unit}</Th>
                                            <Th>{strings.correct_inventory}</Th>
                                            <Th>{strings.actual_quantity}</Th>
                                            <Th isNumeric>{strings.delta}</Th>
                                            <Th>{strings.action}</Th>
                                          </Tr>
                                        </Thead>

                                        <Tbody>
                                          <Tr>
                                            <Td>1</Td>
                                            <Td>Coffee Machine</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>2</Td>
                                            <Td>Refregirator</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>3</Td>
                                            <Td>Bulb</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>4</Td>
                                            <Td>Flash Light</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>6</Td>
                                            <Td>Cabel</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Meter</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>7</Td>
                                            <Td>Tile</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>8</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>9</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td isNumeric>10</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>11</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>12</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>13</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>14</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>15</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>16</Td>
                                            <Td>#Article</Td>
                                            <Td isNumeric>10</Td>
                                            <Td>Psc</Td>
                                            <Td>
                                              <Stack direction="row">
                                                <IconButton
                                                  size="xs"
                                                  colorScheme="gray"
                                                  aria-label="Increment"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'blue.500',
                                                    colorScheme: 'blue',
                                                  }}
                                                  icon={<BiPlusCircle />}
                                                />
                                                <IconButton
                                                  size="xs"
                                                  variant="solid"
                                                  colorScheme="gray"
                                                  aria-label="Decrement"
                                                  rounded="full"
                                                  _hover={{
                                                    color: 'red.500',

                                                    colorScheme: 'red',
                                                  }}
                                                  icon={<BiMinusCircle />}
                                                />
                                              </Stack>
                                            </Td>
                                            <Td>
                                              <Input
                                                placeholder="Number"
                                                size="xs"
                                              />
                                            </Td>
                                            <Td isNumeric>
                                              <Text>2</Text>
                                            </Td>
                                            <Td>
                                              <Tooltip hasArrow label="Success">
                                                <IconButton
                                                  icon={<BiCheck />}
                                                  variant="link"
                                                  aria-label="Success"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                              <Tooltip hasArrow label="Delete">
                                                <IconButton
                                                  icon={<BiX />}
                                                  variant="link"
                                                  aria-label="Delete"
                                                  color="gray.300"
                                                  minW="6"
                                                />
                                              </Tooltip>
                                            </Td>
                                          </Tr>
                                        </Tbody>
                                      </Table>
                                    </TableContainer>
                                  </PerfectScrollbar>
                                </TabPanel>
                              </TabPanels>
                            </Tabs>
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default CorrectInventory;
