import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PNSResource from 'api/push_notification';
import { wrapperStyles } from 'assets/css/commonStyles';
import PushNotificationForm from 'components/pns/PushNotificationForm';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

const AddPushNotification: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<any>();
  const pnsAPI = new PNSResource();
  const [errorMessage, setErrorMessage] = useState('');

  const [isSendModalOpen, setIsSendModalOpen] = useState<boolean>(false);
  const [sendErrorMsg, setSendErrorMsg] = useState<boolean>(false);

  const handleSendModal = () => {
    setSendErrorMsg(false);
    setIsSendModalOpen(true);
  };

  const handleCancelSend = () => {
    setSendErrorMsg(false);
    setIsSendModalOpen(false);
  };

  const pnsService = useMutation((data: any) => pnsAPI.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.notification_created,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('pnsList');
      history.push(routes.pns.notification.list);
    },
    onError: (error: any, response: any) => {
      setErrorMessage(strings.notification_creation_failed);
    },
  });

  const onSubmit = (data: any) => {
    pnsService.mutate(data);
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.pns} | {strings.add_new_push_notification}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.pns}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.pns.notification.list}>
            {strings.push_notification}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.pns.notification.add}>
            {strings.add_new_push_notification}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.add_new_push_notification}
        </Heading>
      </Flex>

      <Box sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form ref={form}>
            <Stack direction="column" spacing="4">
              {pnsService.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                  {!errorMessage && strings.notification_creation_failed}
                </Alert>
              )}
              <PushNotificationForm setSendErrorMsg={setSendErrorMsg} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  onClick={handleSendModal}
                  type="button">
                  {strings.send}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
            <Modal
              isOpen={isSendModalOpen}
              isCentered
              onClose={handleCancelSend}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  {strings.send} {strings.push_notification}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {sendErrorMsg && (
                    <Alert status="error" mb={2}>
                      <AlertIcon />
                      {strings.invalid_data}
                    </Alert>
                  )}
                  {strings.pns_notification_confirm}
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <AccessControl
                      allowedPermissions={[
                        PermissionRequest['send:push-notificaton'],
                      ]}>
                      <Button
                        colorScheme="primary"
                        type="button"
                        onClick={methods.handleSubmit(onSubmit)}
                        isLoading={pnsService.isLoading}>
                        {strings.confirm}
                      </Button>
                    </AccessControl>
                    <Button
                      variant="outline"
                      onClick={handleCancelSend}
                      isDisabled={pnsService.isLoading}>
                      {strings.cancel}
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default AddPushNotification;
