import {
  Button,
  Image,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { ExcelFile } from 'assets/icons';
import { strings } from 'config/localization';
import { AppointmentTodoExport } from 'constants/schema';
import FileSaver from 'file-saver';
import React from 'react';
import { useMutation } from 'react-query';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  exportAppointmentTodo: AppointmentTodoExport;
  externalCompanyId: string;
  externalCompanyName: string;
  assigneeId: string;
  assigneeName: string;
  appointmentDateFrom: string;
  appointmentDateTo: string;
}

const ExportAppointmentTodo: React.FC<Props> = (props) => {
  const {
    exportAppointmentTodo,
    externalCompanyId,
    externalCompanyName,
    assigneeId,
    assigneeName,
    appointmentDateFrom,
    appointmentDateTo,
  } = props;

  const ticketAPI = new TicketResource();
  const toast = useToast();

  const isExportForAssignee = exportAppointmentTodo === 'assignee';
  const queryParams = {
    appointment_date_from: appointmentDateFrom,
    appointment_date_to: appointmentDateTo,
  };

  const formatedAppointmentDateFrom = DateFormat(appointmentDateFrom);
  const formatedAppointmentDateTo = DateFormat(appointmentDateTo);

  const exportAssigneeTodo = useMutation(
    () => ticketAPI.exportAssigneeTodo(assigneeId, queryParams),
    {
      onSuccess: (response) => {
        if (response.status === 204) {
          toast({
            title: strings.no_data_found,
            status: 'error',
            isClosable: true,
          });
          return;
        }

        const title = `${assigneeName}_${strings.todo_list.replaceAll(
          ' ',
          '_'
        )} (${formatedAppointmentDateFrom}-${formatedAppointmentDateTo}).xlsx`;
        const headers = response.headers;
        const blob = new Blob([response.data], {
          type: headers['content-type'],
        });
        FileSaver.saveAs(blob, title);
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const exportExternalCompanyTodo = useMutation(
    () => ticketAPI.exportExternalCompanyTodo(externalCompanyId, queryParams),
    {
      onSuccess: (response) => {
        if (response.status === 204) {
          toast({
            title: strings.no_data_found,
            status: 'error',
            isClosable: true,
          });
          return;
        }

        const title = `${externalCompanyName}_${strings.todo_list.replaceAll(
          ' ',
          '_'
        )} (${formatedAppointmentDateFrom}-${formatedAppointmentDateTo}).xlsx`;
        const headers = response.headers;
        const blob = new Blob([response.data], {
          type: headers['content-type'],
        });
        FileSaver.saveAs(blob, title);
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleExport = () => {
    if (isExportForAssignee) {
      exportAssigneeTodo.mutate();
    } else {
      exportExternalCompanyTodo.mutate();
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      shadow="box"
      padding="4"
      rounded="sm">
      <Stack direction="column">
        <Text fontWeight="bold" color="gray.500" lineHeight="3">
          {isExportForAssignee ? assigneeName : externalCompanyName}&nbsp;
          {strings.todo_list}
        </Text>
        <Text fontSize="sm" color="gray.400">
          ({formatedAppointmentDateFrom} - {formatedAppointmentDateTo})
        </Text>
      </Stack>
      <Tooltip label={strings.download_excel} hasArrow>
        <Button
          size="sm"
          colorScheme="green"
          variant="outline"
          onClick={handleExport}>
          <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default ExportAppointmentTodo;
