import { getUserDetails } from 'api/chat';
import PermissionRequest from 'constants/PermissionRequest';
import { ChatUserSchema } from 'constants/schema';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { checkPermissions } from 'utils/listInfo';

type ChatUserResponse = {
  isPermitted: boolean;
  status: boolean | undefined;
  isLoading: boolean;
  isError: boolean;
};

const fetchUserDetails = async (loggedInId: number) => {
  const response = await getUserDetails(loggedInId);
  return response.data;
};

/**
 * Get chat user info
 *
 */
export default function useChatUserInfo(): ChatUserResponse {
  const { loggedInUser, userPermissions } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  // Check permission of a user if he/she can be assigned a chat
  const isPermitted = checkPermissions(userPermissions, [
    PermissionRequest['show:chat-assign'],
  ]);

  const { data, isLoading, isError } = useQuery<ChatUserSchema>(
    'status',
    () => fetchUserDetails(loggedInUser?.id),
    { enabled: !!isPermitted }
  );

  return { isPermitted, status: data?.status, isLoading, isError };
}
