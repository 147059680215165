import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import FAQResource from 'api/faq';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { FAQSchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiCloudDownload, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  status: string;
  group_id: string;
}

const InternalFaqList: React.FC = () => {
  let faqAPI = new FAQResource();
  let faqGroupAPI = new FAQGroupResource();

  const history = useHistory();
  const { search } = useLocation();
  let defaultIndex = search ? 0 : -1;
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    status: searchValues.status ?? '',
    group_id: searchValues.group_id ?? '',
  });

  const queryList = useQuery(
    [
      `faq-list`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        status: filterParams.status,
        group_id: filterParams.group_id,
      },
    ],
    () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        type: 'internes',
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.group_id) queryParams.group_id = filterParams.group_id;
      return faqAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );

  const { data: faqsQuery } = queryList;

  const { data: faqGroupList } = useQuery(['faq-group-list'], () => {
    return faqGroupAPI.list().then((res) => res.data?.data);
  });

  const faqGroupOptions = useMemo(() => {
    return faqGroupList?.map((faqGroup: any) => {
      return {
        label: faqGroup.name,
        value: faqGroup.id,
      };
    });
  }, [faqGroupList]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      status: '',
      group_id: '',
      type: '',
    }));
    history.push(routes.faq.internalfaq.list);
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.faq} | {strings.all_internals}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.faq.internalfaq.list}>
            {strings.faq}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.faq.internalfaq.list}>
            {strings.all_internals}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md">{strings.internal_faq}</Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack direction="column" spacing="4">
            <Stack
              direction={['column', 'column', 'row']}
              spacing="4"
              align={['start', 'start', 'end']}>
              <Grid
                gap={['4', '4', '6']}
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(3, 1fr)',
                ]}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.search}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.400"
                      />
                      <Input
                        type="text"
                        name="keyword"
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.group}</FormLabel>
                    <Select
                      name="group_id"
                      value={filterParams.group_id}
                      placeholder={strings.select_group}
                      rounded="sm"
                      onChange={handleInputChange}>
                      {faqGroupOptions?.map((item: any) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.status}</FormLabel>
                    <Select
                      name="status"
                      value={filterParams.status}
                      placeholder={strings.select_status}
                      rounded="sm"
                      onChange={handleInputChange}>
                      <option value="1">ON</option>
                      <option value="0">OFF</option>
                    </Select>
                  </FormControl>
                </GridItem>
              </Grid>
            </Stack>

            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                type="button"
                variant="outline"
                onClick={() => handleReset()}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <Accordion
          bg="gray.100"
          borderColor="gray.200"
          allowToggle
          defaultIndex={[defaultIndex]}>
          {faqsQuery?.data?.map((item: FAQSchema) => (
            <AccordionItem key={item.id}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Heading size="xs">{item.title}</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="gray.50">
                <Stack dir="column">
                  <Text>{item.description}</Text>
                  <ButtonGroup>
                    {item.file_contact && (
                      <Button colorScheme="primary" type="submit">
                        <a
                          href={item.file_contact}
                          target="_blank"
                          rel="noopener noreferrer">
                          <Icon as={BiCloudDownload} mr="1" />
                          {item.file_contact_name
                            ? item.file_contact_name
                            : strings.download}
                          <Text color="white" ml="1" fontSize="sm">
                            ( {strings.contact} )
                          </Text>
                        </a>
                      </Button>
                    )}
                    {item.file_object && (
                      <Button colorScheme="primary" type="submit">
                        <a
                          href={item.file_object}
                          target="_blank"
                          rel="noopener noreferrer">
                          <Icon as={BiCloudDownload} mr="1" />
                          {item.file_object_name
                            ? item.file_object_name
                            : strings.download}
                          <Text ml="1" fontSize="sm" color="white">
                            ( {strings.object} )
                          </Text>
                        </a>
                      </Button>
                    )}
                  </ButtonGroup>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default InternalFaqList;
