import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import SMSServiceResource from 'api/sms_services';
import ServiceForm from 'components/sms_service/ServiceForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ServiceSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { buildFormData } from 'utils';

const AddService: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<ServiceSchema>();
  const serviceAPI = new SMSServiceResource();
  const [errorMessage, setErrorMessage] = useState('');

  const addService = useMutation((data: FormData) => serviceAPI.store(data));

  const onSubmit = (data: any) => {
    let formdata: any = { ...data };
    if (formdata.service_group_id)
      formdata.service_group_id = data.service_group_id.value;
    if (formdata.v_service_id) formdata.v_service_id = data.v_service_id.value;

    formdata.name = data.titles_de;
    formdata.titles = {
      de: data.titles_de,
      en: data.titles_en,
      da: data.titles_da,
      pl: data.titles_pl,
      zh: data.titles_zh,
    };
    delete formdata.titles_de;
    delete formdata.titles_en;
    delete formdata.titles_da;
    delete formdata.titles_pl;
    delete formdata.titles_zh;

    if (formdata.cover === undefined) {
      methods.setError('cover', { message: strings.accept_only_png });
    } else {
      addService.mutate(buildFormData(formdata), {
        onSuccess: () => {
          toast({
            title: strings.service_created,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries('serviceList');
          history.push(routes.sms.services.list);
        },
        onError: (error: any) => {
          if (
            error?.response?.data?.errors['scheme_data.0.name'] &&
            error?.response?.data?.errors['scheme_data.0.name'][0] ===
              'Duplicate Price Scheme'
          ) {
            setErrorMessage(strings.duplicate_price_scheme);
          } else {
            setErrorMessage(strings.service_creation_failed);
          }
        },
      });
    }
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.sms} | {strings.add_service}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.sms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.sms.services.list}>
            {strings.services}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.sms.serviceGroups.add}>
            {strings.add_service}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.add_service}
        </Heading>
      </Flex>

      <Stack>
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {errorMessage && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <ServiceForm setErrorMessage={setErrorMessage} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addService.isLoading}>
                  {strings.add_service}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddService;
