import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserDocumentResource from 'api/user_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import UserDocumentForm from 'components/user_document/UserDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const UploadUsersDocument: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const methods = useForm<any>();
  const userDocumentAPI = new UserDocumentResource();
  const [errorMessage, setErrorMessage] = useState('');

  const addUserDocument = useMutation(
    (data: any) => userDocumentAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.general_document_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userDocumentList');
        history.push(routes.dms.userDocuments.list);
      },
      onError: (error: any, response: any) => {
        if (error?.response?.data?.file) {
          setErrorMessage(error?.response?.data?.file[0]);
        } else {
          setErrorMessage(strings.general_document_creation_failed);
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    const formData: any = new FormData();

    formData.append('name', data.name);
    formData.append('user_id', data.user_id?.value);
    if (data.available_from_date)
      formData.append('available_from_date', data.available_from_date);
    if (data.available_to_date)
      formData.append('available_to_date', data.available_to_date);
    if (data.upload_files === undefined) {
      methods.setError('upload_files', { message: strings.required_upload });
      return;
    }
    formData.append('file', data.upload_files);
    addUserDocument.mutate(formData);
  };

  return (
    <Stack>
      <Helmet>
        <title>
          {strings.dms} | {strings.upload_user_document}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.userDocuments.list}>
              {strings.document_users}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.upload_user_document}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.upload_user_document}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack
                direction="column"
                spacing="10"
                justifyContent="space-between">
                {addUserDocument.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
                <UserDocumentForm />

                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={addUserDocument.isLoading}>
                    {strings.save_now}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UploadUsersDocument;
