import {
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import RolesResource from 'api/roles';
import UserListItem from 'components/user/UserListItem';
import { strings } from 'config/localization';
import { UserSchema } from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';

export interface RoleUsersProps {
  roleId: number;
}

const RoleUsers: React.FC<RoleUsersProps> = (props) => {
  const { roleId } = props;

  const rolesApi = new RolesResource();

  const { data: roleUserList } = useQuery([`roleUsers${roleId}`, roleId], () =>
    rolesApi.listUsers(roleId).then((res: any) => res.data.data)
  );

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.all_user_with_that_role}
        </Heading>
      </Flex>
      <Box bg="white" p="3 " shadow="box" rounded="sm">
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.email}</Th>
                <Th isNumeric>{strings.phone}</Th>
                <Th>{strings.role}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {roleUserList?.map((user: UserSchema, index: number) => (
                <UserListItem userData={user} key={user.id} index={index + 1} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default RoleUsers;
