import {
  Button,
  Image,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import EmailMarketingResource from 'api/emailMarketing';
import { ResentEmail } from 'assets/icons/index';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReservationListSchema } from 'constants/schema';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { utcToLocal } from 'utils/DateFormat';

interface Props {
  data: ReservationListSchema;
  index: number;
}

const statusColorScheme = {
  planned: 'orange.400',
  sent: 'green.400',
  resent: 'yellow.400',
} as const;

const MarketingEmailListItem: React.FC<Props> = (props) => {
  const { data, index } = props;
  const {
    id,
    customer,
    booking_number,
    booking_period,
    apartment,
    email_status,
    email_date,
  } = data;
  const toast = useToast();

  const queryClient = useQueryClient();

  const emailMarketingAPI = new EmailMarketingResource();

  const sendMarketingMail = useMutation({
    mutationFn: () => emailMarketingAPI.resendMarketingEmail(data.id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['market-email-list'],
      });
      toast({
        title: strings.email_sent_successfully,
        status: 'success',
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: strings.failed_to_send_email,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const handleMarketingMailclick = async () => {
    sendMarketingMail.mutate();
  };

  return (
    <Tr>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={routes.bms.reservation.details.replace(':id', id.toString())}>
          {booking_number ?? '-'}
        </RouterLink>
      </Td>
      <Td>{customer ? `${customer.forename} ${customer.surname}` : '-'}</Td>
      <Td>{apartment?.apartment_name ?? '-'}</Td>
      <Td>{booking_period ?? '-'}</Td>
      <Td>{email_date ? utcToLocal(email_date).replace(' ', ', ') : '-'}</Td>

      <Td>
        {email_status ? (
          <Text
            color={statusColorScheme[email_status]}
            textTransform="uppercase"
            fontWeight="medium"
            fontSize="sm">
            {strings[email_status]}
          </Text>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {(email_status === 'sent' || email_status === 'resent') && (
          <Tooltip hasArrow label={strings.resend_mail}>
            <Button variant="link" onClick={handleMarketingMailclick}>
              <Image src={ResentEmail} h="4" alt="ResentMail" />
            </Button>
          </Tooltip>
        )}
      </Td>
    </Tr>
  );
};

export default MarketingEmailListItem;
