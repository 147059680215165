import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import PermissionResources from 'api/permissions';
import RolesResource from 'api/roles';
import UsersResource from 'api/user';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { RoleSchema, UserSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import {
  capitalizeFirstLetter,
  LanguageOptionLocalization,
  maskPhone,
} from 'utils';
import { validEmail } from 'utils/validate';
import UserRightsManager from './UserRightsManager';

interface Props {
  userData?: UserSchema;
}
interface Permission {
  id: number;
  name: string;
  guard_name: string;
}
interface PermissionGroupRole {
  id: number;
  name: string;
  permissions: Permission[];
}

const UserForm: React.FC<Props> = (props) => {
  const { userData } = props;
  const { isOpen, onToggle } = useDisclosure();
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<UserSchema>();
  const [permissionViaRoleList, setPermissionViaRoleList] = useState<
    Permission[]
  >([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [roleId, setRoleId] = useState(
    userData?.role_id ? userData?.role_id : 0
  );
  const [oldRoleId, setOldRoleId] = useState(
    userData?.role_id ? userData?.role_id : 0
  );
  const rolesApi = new RolesResource();
  const usersApi = new UsersResource();
  const permissionGroupsApi = new PermissionResources();

  const isInitialMount = useRef(true);
  const LanguageStatusOptions = LanguageOptionLocalization();

  const { isLoading: isRolesLoading, data: rolesData } = useQuery(
    'user-roles-for-user-edit',
    () => rolesApi.list().then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const permissionGroupQuery = useQuery(
    'permission-groups-for-user-edit',
    () => permissionGroupsApi.list().then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!rolesData,
    }
  );

  useEffect(() => {
    const fetchUserPermissionRoles = async () => {
      if (userData?.id) {
        const response = await usersApi.permissions(userData?.id);
        const viaRole = response?.data?.data?.via_role;
        const directRole = response?.data?.data?.direct;
        const permissionLists: Permission[] = [...viaRole, ...directRole];
        setValueForPermission(permissionLists);
        setPermissionViaRoleList(viaRole);
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (permissionGroupQuery?.data?.length > 0 && userData?.id) {
      fetchUserPermissionRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionGroupQuery?.data, userData?.id]);

  const handleRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const roleId = Number(e.target.value);
    if (userData?.role_id && oldRoleId != roleId) {
      setRoleId(roleId);
      setModalOpen(true);
    } else getPermissions(roleId);
    clearErrors('role_id');
  };

  const handleForeignLanguageChange = (languages: any) => {
    const selectedLanguages = languages.map((lang: any) => lang.value);

    if (selectedLanguages.length === 0) {
      setValue('foreign_language', null);
      return;
    }
    setValue('foreign_language', selectedLanguages);
  };

  const getPermissions = async (roleId: number) => {
    if (roleId) {
      setRoleId(roleId);
      setOldRoleId(roleId);
      const response = await rolesApi.getPermissions(roleId);
      const permissionLists: Permission[] = response?.data?.data;
      setValueForPermission(permissionLists);
      setPermissionViaRoleList(permissionLists);
    }
  };

  const onModalClose = () => {
    setRoleId(oldRoleId);
    setModalOpen(false);
  };

  const handleConfirmRole = () => {
    getPermissions(roleId);
    setModalOpen(false);
  };

  const setValueForPermission = (permissionList: Permission[]) => {
    let permissionObj: any = {};
    const permissionGroupList = permissionGroupQuery.data;
    // Create a obj comprising of all the permissions with its value set to false initially
    permissionGroupList.forEach((role: PermissionGroupRole) => {
      role.permissions.forEach((per: Permission) => {
        permissionObj = { ...permissionObj, [per.id.toString()]: false };
      });
    });
    permissionList.forEach((res: any) => {
      permissionObj[res.id.toString()] = true; // the value of available permissions are set to true
    });
    setValue('permissions', permissionObj);
  };

  if (isRolesLoading || permissionGroupQuery.isLoading)
    return <CenterSpinner />;

  const isSetToEdit = !!userData?.id;

  return (
    <>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.first_name} isRequired>
              <FormLabel>{strings.first_name}</FormLabel>
              <Input
                {...register('first_name', {
                  required: strings.required_first_name,
                })}
                type="text"
                defaultValue={userData?.first_name}
                placeholder={strings.first_name}
              />
              <FormErrorMessage>
                {errors?.first_name && errors?.first_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.last_name} isRequired>
              <FormLabel>{strings.last_name}</FormLabel>
              <Input
                type="text"
                defaultValue={userData?.last_name}
                placeholder={strings.last_name}
                {...register('last_name', {
                  required: strings.required_last_name,
                })}
              />
              <FormErrorMessage>
                {errors?.last_name && errors?.last_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.email} isRequired>
              <FormLabel>{strings.email_address}</FormLabel>
              <Input
                defaultValue={userData?.email}
                placeholder={strings.email_placeholder}
                {...register('email', {
                  required: strings.required_email,
                  validate: (value) =>
                    validEmail(value) || strings.valid_email_address,
                })}
                isReadOnly={isSetToEdit}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.contact_number} isRequired>
              <FormLabel>{strings.cellphone}</FormLabel>
              <Input
                type="text"
                {...register('contact_number', {
                  required: strings.required_cellphone,
                  validate: (value) => {
                    if (!value) return true;
                    return !(value.length < 15) || strings.invalid_cellphone;
                  },
                })}
                defaultValue={
                  userData?.contact_number
                    ? maskPhone(userData.contact_number)
                    : ''
                }
                placeholder={strings.cellphone}
                onChange={(e) =>
                  (e.target.value = `${maskPhone(e.target.value)}`)
                }
              />
              <FormErrorMessage>
                {errors?.contact_number && errors?.contact_number?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.address} isRequired>
              <FormLabel>{strings.address}</FormLabel>
              <Input
                type="text"
                defaultValue={userData?.address}
                placeholder={strings.address}
                {...register('address', {
                  required: strings.required_address,
                })}
              />
              <FormErrorMessage>
                {errors?.address && errors?.address?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.foreign_language}</FormLabel>
              <ReactSelect
                name="foreign_language"
                placeholder={strings.select_language}
                closeMenuOnSelect={false}
                options={LanguageStatusOptions}
                defaultValue={userData?.foreign_language?.map((language) => ({
                  value: language,
                  label: strings[language],
                }))}
                onChange={handleForeignLanguageChange}
                styles={reactSelectStyles}
                isMulti
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.role_id} isRequired>
              <FormLabel>{strings.role}</FormLabel>
              <Select
                placeholder={userData?.role_id ? '' : strings.select_role}
                id="role"
                rounded="sm"
                value={roleId}
                {...register('role_id', {
                  required: strings.required_role,
                })}
                onChange={handleRole}>
                {rolesData?.map((role: RoleSchema) => (
                  <option key={role.id} value={role.id}>
                    {capitalizeFirstLetter(
                      strings.getString(role.name.split(' ').join('_'))
                    )}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors?.role_id && errors?.role_id?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.role}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.change_confirm}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button colorScheme="primary" onClick={handleConfirmRole}>
                  {strings.yes}
                </Button>
                <Button variant="outline" onClick={onModalClose}>
                  {strings.no}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
      <Box>
        <Button
          variant="link"
          onClick={onToggle}
          size="sm"
          pt="2"
          leftIcon={
            <Icon
              as={isOpen ? BiChevronUp : BiChevronDown}
              w="6"
              h="6"
              bg="primary.400"
              color="white"
              rounded="full"
            />
          }>
          {strings.manage_extra_rights}
        </Button>

        <Collapse in={isOpen} animateOpacity>
          <UserRightsManager
            permissionViaRoleList={permissionViaRoleList}
            permissionGroupQuery={permissionGroupQuery}
          />
        </Collapse>
      </Box>
    </>
  );
};

export default UserForm;
