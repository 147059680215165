import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  ScaleFade,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { resetPassword } from 'api/auth';
import { DE, EN, PL } from 'assets/icons';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { LanguageTypesSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  BiChevronDown,
  BiChevronLeft,
  BiEnvelope,
  BiKey,
} from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import history from 'utils/history';
import { validatePassword, validEmail } from 'utils/validate';

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token') || '';
  const email = params.get('email') || '';
  if (token === '' || email === '') history.push(routes.auth.login);
  const newPassword = useRef({});
  const [errMsg, setErrMsg] = useState<string>();
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm();
  const currentLanguage = strings.getLanguage();
  const [currentFlag, setCurrentFlag] = useState(() =>
    currentLanguage === 'pl' ? PL : currentLanguage === 'de' ? DE : EN
  );
  const resetPasswordMutation = useMutation((data) => resetPassword(data), {
    onSuccess: (res: any) => {
      toast({
        title: strings.login_with_new_password,
        status: 'success',
        isClosable: true,
      });
      history.push(routes.auth.login);
    },
    onError: (error: any) => {
      if (error?.response?.data?.errors?.email)
        setErrMsg(error?.response?.data?.errors?.email[0]);
      if (error?.response?.data?.errors?.token)
        setErrMsg(strings.invalid_token);
      if (error?.response?.data?.errors?.password)
        setErrMsg(error?.response?.data?.errors?.password[0]);
    },
  });

  newPassword.current = watch('password', '');

  const onSubmit = (data: any) => {
    data.token = token;
    if (data.email === email) resetPasswordMutation.mutate(data);
  };
  const handleLanguage = (Language: LanguageTypesSchema) => {
    setCurrentFlag(Language === 'en' ? EN : Language === 'de' ? DE : PL);

    strings.setLanguage(Language);
    localStorage.setItem('language', Language);
  };

  return (
    <Box bg="gray.50">
      <Stack pos="absolute" right="5%">
        <Menu>
          <MenuButton
            p={2}
            transition="all 0.2s"
            _hover={{ borderBottom: 'gray.300' }}
            _expanded={{ borderBottom: 'gray.400' }}>
            <Flex>
              <MenuItem icon={<Image src={currentFlag} w="6" h="6" />}>
                {currentLanguage?.toLocaleUpperCase()}
              </MenuItem>
              <Box pt="2">
                <BiChevronDown />
              </Box>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => handleLanguage('de')}
              icon={<Image src={DE} w="6" h="6" />}>
              DE
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('en')}
              icon={<Image src={EN} w="6" h="6" />}>
              EN
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('pl')}
              icon={<Image src={PL} w="6" h="6" />}>
              PL
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
      <Center h="100vh">
        <Stack direction="column" spacing="12">
          <Stack spacing="4">
            <Center>
              <Image src={Logo} w="16" />
            </Center>
            <Stack>
              <Text align="center" fontSize="lg" fontWeight="medium">
                {strings.listinfo_service_center}
              </Text>
            </Stack>
          </Stack>
          <Box bg="white" p="14" shadow="box" rounded="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="8">
                <Stack direction="column" spacing="4">
                  <Heading as="h1" size="xl">
                    {strings.reset_password}
                  </Heading>
                  <Text color="gray.500">
                    {strings.reset_password_subtitle}
                  </Text>
                  {resetPasswordMutation.isError && (
                    <ScaleFade in={resetPasswordMutation.isError}>
                      <Alert status="error">
                        <AlertIcon />
                        {errMsg}
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>
                {email && (
                  <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                    <FormLabel>{strings.email}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <Icon color="gray.200" w="4" h="4" as={BiEnvelope} />
                        }
                      />
                      <Input
                        id="email"
                        type="email"
                        defaultValue={email}
                        placeholder={strings.your_email_address}
                        {...register('email', {
                          required: strings.email_address,
                          validate: (value) =>
                            validEmail(value) || strings.valid_email_address,
                        })}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.email && errors.email?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}

                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.password}>
                  <FormLabel>{strings.password}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiKey} />
                      }
                    />
                    <Input
                      id="password"
                      type="password"
                      placeholder={strings.new_password}
                      {...register('password', {
                        required: strings.new_password,
                        validate: (value) =>
                          validatePassword(value) || strings.msg_valid_password,
                      })}
                    />
                  </InputGroup>
                  <FormHelperText>
                    {strings.password_helper_text}
                  </FormHelperText>
                  <FormErrorMessage>
                    {errors.password && errors.password?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.password_confirmation}>
                  <FormLabel>{strings.confirm_password}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiKey} />
                      }
                    />
                    <Input
                      id="password_confirmation"
                      type="password"
                      placeholder={strings.confirm_password}
                      {...register('password_confirmation', {
                        required: strings.confirm_password,
                        validate: (value) =>
                          value === newPassword.current ||
                          strings.not_match_new_confirm_password,
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password_confirmation &&
                      errors.password_confirmation?.message}
                  </FormErrorMessage>
                </FormControl>
                <VStack spacing="6">
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={resetPasswordMutation.isLoading}>
                    {strings.save_reset_password}
                  </Button>
                  <Link to={routes.auth.login}>
                    <Button
                      variant="link"
                      color="gray.400"
                      leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                      {strings.back_to_login}
                    </Button>
                  </Link>
                </VStack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default ResetPassword;
