import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { ArticleTypeSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data?: ArticleTypeSchema;
}

const TypeForm = (props: Props) => {
  // props
  const { data } = props;

  // Library Init
  const {
    register,
    formState: { errors },
    reset,
  } = useFormContext<ArticleTypeSchema>();

  // Initialize data (equivalent to setting default values)
  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        description: data.description,
      });
    }
  }, [data, reset]);

  return (
    <form>
      <Stack direction="row" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']}
          w={['100%', '50%']}>
          <GridItem>
            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel>{strings.type_name}</FormLabel>
              <Input
                type="text"
                placeholder={strings.type_name}
                {...register('name', {
                  required: strings.required_type_name,
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.description} isRequired>
              <FormLabel>{strings.descriptions}</FormLabel>
              <Textarea
                placeholder={strings.write_description}
                {...register('description', {
                  required: strings.required_descriptions,
                })}
              />
              <FormErrorMessage>
                {errors.description && errors.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default TypeForm;
