import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FAQResource from 'api/faq';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils';

interface Props {
  data: any;
  index: number;
  search?: any;
}

const FaqListItem: React.FC<Props> = (props) => {
  const { data, index, search } = props;
  let { id: faqID, status } = data;

  // Library Init
  const toast = useToast();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [localStatus, setLocalStatus] = useState<number>(status);
  const checkEditPermission = useIsPermitted([PermissionRequest['manage:faq']]);

  let faqAPI = new FAQResource();

  const updateStatus = useMutation((formdata: any) =>
    faqAPI.updateFAQ(faqID, formdata)
  );

  const deleteFAQAPI = useMutation((id: number) => faqAPI.destroy(id), {
    onSuccess: () => {
      setIsDeleteLoading(false);
      setDeleteModalOpen(false);
      toast({
        title: strings.success,
        description: strings.faq_deleted_successfully,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      queryClient.invalidateQueries('faq-list');
    },
    onError: (error: any) => {
      setDeleteErrorMsg(error?.response?.data?.error?.message);
    },
  });

  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  function submitStatusUpdate(formData: any) {
    updateStatus.mutate(formData, {
      onSuccess: () => {
        toast({
          title: strings.status_changed,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
        setLocalStatus(status);
      },
    });
  }

  function handleStatusChange(e: any) {
    let checked = e.target.checked ? 1 : 0;
    let stateObj = { status: checked, _method: 'PUT' };
    submitStatusUpdate(stateObj);
    setLocalStatus(checked);
  }
  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteLoading(false);
    setDeleteModalOpen(false);
  };

  function onDeleteConfirm() {
    setIsDeleteLoading(true);
    deleteFAQAPI.mutate(faqID);
  }

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>
        <Tooltip hasArrow label={data.description}>
          {capitalizeFirstLetter(data.title)}
        </Tooltip>
      </Td>
      <Td>{capitalizeFirstLetter(data.alias) ?? '-'}</Td>
      <Td>
        <FormControl display="flex" alignItems="center">
          <Switch
            colorScheme="primary"
            isDisabled={!checkEditPermission}
            isChecked={!!localStatus}
            onChange={handleStatusChange}
          />
        </FormControl>
      </Td>
      <Td>
        <RouterLink
          to={routes.faq.faqList.edit.replace(
            ':id',
            faqID.toString() + search
          )}>
          <Tooltip hasArrow label={strings.edit_faq}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_faq}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_faq}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_faq}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          closeOnOverlayClick={!isDeleteLoading}
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_faq}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {deleteErrorMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteErrorMsg}
                </Alert>
              )}
              {strings.confirm_delete}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  onClick={onDeleteConfirm}>
                  {strings.delete}
                </Button>

                <Button
                  variant="outline"
                  isDisabled={isDeleteLoading}
                  onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default FaqListItem;
