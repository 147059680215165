import { IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReservationRequestSchema } from 'constants/schema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  reservationRequestData: ReservationRequestSchema;
  index: number;
  search?: string;
}
const ReservationRequestListItem: React.FC<Props> = (props) => {
  const { reservationRequestData, index, search } = props;

  return (
    <Tr key={reservationRequestData.id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.bms.reservationRequest.details.replace(
              ':id',
              reservationRequestData.id.toString()
            ) + search
          }>
          {reservationRequestData?.apartment?.apartment_name || '-'}
        </RouterLink>
      </Td>
      <Td>{reservationRequestData?.booking_period}</Td>
      <Td>{reservationRequestData?.customer_name}</Td>
      <Td>{DateFormat(reservationRequestData.created_at)}</Td>
      <Td>
        <RouterLink
          to={
            routes.bms.reservationRequest.details.replace(
              ':id',
              reservationRequestData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_reservation_request_view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_reservation_request_view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default ReservationRequestListItem;
