import {
  AlertDialog,
  AlertDialogOverlay,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import KeyAPI from 'api/key';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import GetKeyId from 'components/keys/GetKeyId';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  KEY_APARTMENT_SEARCH_API,
  KEY_USER_SEARCH_API,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import useWordSearch from 'hooks/useWordSearch';
import useIsPermitted from 'hooks/usIsPermitted';
import KeyListItem from 'pages/dashboard/Key/KeyList/KeyListItem';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiBarcodeReader, BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { changeURL, getStartingSerialNumber } from 'utils';
import {
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  apartmentName: string;
  apartmentId: number | null;
  registeredDateFrom: Date | null;
  registeredDateTo: Date | null;
  registeredById: number | null;
  registeredByName: string;
  status: string;
}

const KeyLists: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [scannedKeyId, setScannedKeyId] = useState<null | string>(null);

  const keyAPI = new KeyAPI();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const registeredDateFrom = Number(searchValues.registeredDateFrom);
  const registeredDateTo = Number(searchValues.registeredDateTo);

  const apartmentValue =
    searchValues.apartmentId && searchValues.apartmentName
      ? {
          value: searchValues.apartmentId,
          label: searchValues.apartmentName,
        }
      : null;

  const registeredByValue =
    searchValues.registeredById && searchValues.registeredByName
      ? {
          value: searchValues.registeredById,
          label: searchValues.registeredByName,
        }
      : null;

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    apartmentName: searchValues.apartmentName ?? null,
    apartmentId: searchValues.apartmentId ?? null,
    registeredDateFrom: registeredDateFrom
      ? new Date(registeredDateFrom)
      : null,
    registeredDateTo: registeredDateTo ? new Date(registeredDateTo) : null,
    registeredById: searchValues.registeredById ?? null,
    registeredByName: searchValues.registeredByName ?? '',
    status: searchValues.status ?? '',
  });

  const [apartmentInputKey, setApartmentInputKey] = useState<string>('');
  const [userInputKey, setUserInputKey] = useState<string>('');

  const queryList: any = useQuery(
    [
      'keyList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        apartmentId: filterParams.apartmentId,
        registeredDateFrom: filterParams.registeredDateFrom,
        registeredDateTo: filterParams.registeredDateTo,
        registeredById: filterParams.registeredById,
        registeredByName: filterParams.registeredByName,
        status: filterParams.status,
      },
    ],
    async () => {
      const {
        status,
        registeredById,
        apartmentId,
        registeredDateFrom,
        registeredDateTo,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (status) queryParams.status = status;
      if (registeredDateFrom)
        queryParams.registeredDateFrom =
          getUTCDateRangeStartDateTime(registeredDateFrom);
      if (registeredDateTo)
        queryParams.registeredDateTo =
          getUTCDateRangeEndDateTime(registeredDateTo);
      if (registeredById) queryParams.registeredBy = registeredById;
      if (apartmentId) queryParams.apartmentId = apartmentId;

      const response = await keyAPI.list(queryParams);
      return response?.data;
    }
  );

  const { data: keyLists, isLoading: keyLoading } = queryList;

  const {
    loading: apartmentListLoading,
    result: apartmentQuery,
    setQuery: setApartmentQuery,
  } = useWordSearch(`${KEY_APARTMENT_SEARCH_API}?limit=500`);

  const apartmentList = apartmentQuery?.data;
  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
  }));

  const {
    loading: userListLoading,
    result: userQuery,
    setQuery: setUserQuery,
  } = useWordSearch(`${KEY_USER_SEARCH_API}?limit=500`);

  const userList = userQuery?.data;
  const userOptions = userList?.map((user: any) => ({
    label: user.fullName,
    value: user.id,
  }));

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      registeredDateFrom: start,
      registeredDateTo: end,
    }));
  };

  const handleStatusChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserChange = (value: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      registeredByName: value.label,
      registeredById: value.value,
    }));
  };

  const handleApartmentInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setApartmentInputKey(value);
      setApartmentQuery({ keyword: value || null });
    }
  };

  const handleUserInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setUserInputKey(value);
      setUserQuery({ keyword: value });
    }
  };

  const handleApartmentChange = (value: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      apartmentName: value.label,
      apartmentId: value.value,
    }));
  };

  const checkKeyLogsPermission = useIsPermitted([
    PermissionRequest['read:keylog'],
  ]);

  useEffect(() => {
    if (scannedKeyId) {
      history.push(routes.key.keyList.view.replace(':id', scannedKeyId));
    }
  }, [scannedKeyId, history]);

  const handleCloseScan = () => {
    //if rfid placed then only close the dialog box.
    setScannedKeyId(null);
    onClose();
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.registeredDateFrom)
      data.registeredDateFrom = Date.parse(data.registeredDateFrom);
    if (data.registeredDateTo)
      data.registeredDateTo = Date.parse(data.registeredDateTo);

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setFilterParams({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      apartmentName: '',
      apartmentId: null,
      registeredDateFrom: null,
      registeredDateTo: null,
      registeredById: null,
      registeredByName: '',
      status: '',
    });
    history.push(routes.key.keyList.list);
  };

  const checkKeyPermission = useCheckPermission(
    [PermissionRequest['read:keylist']],
    routes.key.keyList.add
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>KMS | {strings.key_list}</title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.key.keyList.list}>
              {strings.key_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between" align="end">
          <Heading size="md" textTransform="capitalize">
            {strings.all_key_list}
          </Heading>
          <Stack direction="row" spacing="4">
            {checkKeyLogsPermission && (
              <Button
                size="sm"
                colorScheme="primary"
                type="button"
                onClick={onOpen}>
                <Icon as={BiBarcodeReader} marginRight={1} />
                {strings.scan_key}
              </Button>
            )}
            <Button
              size="sm"
              colorScheme="primary"
              type="button"
              onClick={checkKeyPermission}>
              {strings.add_key}
            </Button>
          </Stack>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form>
            <Stack direction={['column']} spacing="4">
              <Grid
                gap="4"
                templateColumns={['repeat(1, 1fr)', 'repeat(4, 1fr)']}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.object}</FormLabel>
                    <ReactSelect
                      id="apartmentId"
                      name="apartmentId"
                      placeholder={strings.select_apartment}
                      options={apartmentOptions}
                      value={apartmentValue}
                      styles={reactSelectStyles}
                      onInputChange={handleApartmentInputChange}
                      inputValue={apartmentInputKey}
                      isLoading={apartmentListLoading}
                      onChange={handleApartmentChange}
                      allowClear
                      isSearchable
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.registered_date}</FormLabel>
                    <InputGroup>
                      <ReactDatePicker
                        placeholderText={strings.select_date}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomDateInput />}
                        onChange={handleDateChange}
                        selected={filterParams.registeredDateFrom}
                        startDate={filterParams.registeredDateFrom}
                        endDate={filterParams.registeredDateTo}
                        selectsRange
                        value={getFormattedRangeDate(
                          filterParams.registeredDateFrom,
                          filterParams.registeredDateTo
                        )}
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                      <InputRightElement
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.registered_by}</FormLabel>
                    <ReactSelect
                      id="registeredBy"
                      name="registeredBy"
                      placeholder={strings.registered_by}
                      options={userOptions}
                      value={registeredByValue}
                      styles={reactSelectStyles}
                      onInputChange={handleUserInputChange}
                      inputValue={userInputKey}
                      isLoading={userListLoading}
                      onChange={handleUserChange}
                      allowClear
                      isSearchable
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.status}</FormLabel>
                    <Select
                      name="status"
                      value={filterParams.status}
                      placeholder={strings.status}
                      onChange={handleStatusChange}
                      rounded="sm">
                      <option value="in stock">{strings.in_stock}</option>
                      <option value="given out">{strings.given_out}</option>
                      <option value="lost">{strings.lost}</option>
                    </Select>
                  </FormControl>
                </GridItem>
              </Grid>

              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  type="button"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.key_id}</Th>
                  <Th>{strings.registered_date}</Th>
                  <Th>{strings.registered_by}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!keyLoading &&
                  keyLists?.data?.map((keyItem: any, index: number) => (
                    <KeyListItem
                      keyItem={keyItem}
                      index={startingSN + index}
                      key={index}
                      search={search}
                    />
                  ))}
                {keyLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          dataList={queryList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>

      {checkKeyLogsPermission && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={handleCloseScan}
          isOpen={isOpen}
          isCentered>
          <AlertDialogOverlay />

          <GetKeyId
            setScannedKeyId={setScannedKeyId}
            onClose={onClose}
            databaseCheck={true}
          />
        </AlertDialog>
      )}
    </>
  );
};

export default KeyLists;
