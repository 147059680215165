import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import DebounceInput from 'components/common/DebounceInput';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import { MarketingEmailFilterParams } from 'pages/dashboard/bms/MarketingEmails/MarketingEmails';
import React, { SetStateAction } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { DateFormatYMD, getFormattedRangeDate } from 'utils/DateFormat';

interface Props {
  filterParams: MarketingEmailFilterParams;
  setFilterParams: React.Dispatch<SetStateAction<MarketingEmailFilterParams>>;
}

function MarketingEmailSearch(props: Props) {
  const { filterParams, setFilterParams } = props;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Date) => {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      email_date: date,
    }));
  };

  const handleDateRangeChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prev) => {
      return {
        ...prev,
        currentPage: INITIAL_CURRENT_PAGE,
        time_period_from: start,
        time_period_to: end,
      };
    });
  };

  const handleReset = () => {
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      customer: '',
      booking_number: '',
      email_status: null,
      email_date: null,
      time_period_from: null,
      time_period_to: null,
    }));
  };
  return (
    <Stack sx={wrapperStyles}>
      <form>
        <Stack direction="column" spacing="4">
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <Grid
              gap={['4', '4', '6']}
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.customer}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<BiSearch />}
                      color="gray.400"
                    />

                    <DebounceInput
                      name="customer"
                      setFilterParams={setFilterParams}
                      initialValue={filterParams.customer}
                      placeholder={strings.customer}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.booking_number}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<BiSearch />}
                      color="gray.400"
                    />

                    <DebounceInput
                      name="booking_number"
                      setFilterParams={setFilterParams}
                      initialValue={filterParams.booking_number}
                      placeholder={strings.booking_number}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.email_date}</FormLabel>

                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      customInput={
                        <CustomDateInput _placeholder={{ color: '#acacac' }} />
                      }
                      selected={filterParams.email_date}
                      value={DateFormatYMD(filterParams.email_date)}
                      onChange={handleDateChange}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.status}</FormLabel>
                  <Select
                    name="email_status"
                    value={filterParams.email_status ?? undefined}
                    placeholder={strings.select_status}
                    rounded="sm"
                    onChange={handleInputChange}>
                    <option value="resent">{strings.resent}</option>
                    <option value="planned">{strings.planned}</option>
                    <option value="sent">{strings.sent}</option>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.time_period}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <ReactDatePicker
                      placeholderText={strings.select_date}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleDateRangeChange}
                      selected={filterParams.time_period_from}
                      startDate={filterParams.time_period_from}
                      endDate={filterParams.time_period_to}
                      selectsRange
                      value={getFormattedRangeDate(
                        filterParams.time_period_from,
                        filterParams.time_period_to
                      )}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack direction="row" spacing="4">
            <Button
              colorScheme="primary"
              type="button"
              variant="outline"
              onClick={handleReset}>
              {strings.reset_filter}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default MarketingEmailSearch;
