import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import EmailMarketingResource from 'api/emailMarketing';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  DataWrapperWithSuccess,
  MarketingEmailDaySetting,
  MarketingSetting,
  UpdateMarketingEmailDayPayload,
} from 'constants/schema';
import React, { useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

const MarketingEmailSettings: React.FC = () => {
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;

  const queryClient = useQueryClient();

  const { register, handleSubmit } = useForm<MarketingSetting>();
  const toast = useToast();
  const emailMarketingAPI = new EmailMarketingResource();

  const {
    data: marketingEmailDaysSetting,
    isLoading,
    isError,
  } = useQuery<DataWrapperWithSuccess<MarketingEmailDaySetting>>({
    queryKey: ['marketing-email-day-setting'],
    queryFn: async () => {
      const response = await emailMarketingAPI.getMarketingEmailDaySetting();
      return response?.data;
    },
    refetchOnWindowFocus: false,
  });

  const updateMarketingMailsDays = useMutation({
    mutationFn: (data: UpdateMarketingEmailDayPayload) =>
      emailMarketingAPI.updateMarketingEmailDaySetting(data),
    onSuccess: () => {
      toast({
        title: strings.successfully_updated_marketing_email_days,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('marketing-email-day-setting');
    },
    onError: () => {
      toast({
        title: strings.error_boundary_heading_text,
        status: 'error',
        isClosable: true,
      });
    },
  });

  if (isLoading) {
    return <CenterSpinner />;
  }

  const numberOfMarketingDay =
    marketingEmailDaysSetting?.data.settings.email_notification_days;

  const onSubmit: SubmitHandler<MarketingSetting> = async (data) => {
    if (!data.email_notification_days) return;
    const payload: UpdateMarketingEmailDayPayload = {
      settings: data,
    };
    updateMarketingMailsDays.mutate(payload);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bms.marketingEmailSettings.list}>
              {strings.bms}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bms.marketingEmailSettings.list}>
              {strings.setting}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.setting}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form ref={form} onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction={['column', 'row']}
              justifyContent="space-between"
              alignItems="center">
              <Text>
                {
                  strings.how_many_days_before_arrival_should_the_marketing_mail_be_sent
                }
              </Text>
              <FormControl w="10%">
                <Input
                  type="number"
                  {...register('email_notification_days', {
                    required: strings.required,
                    valueAsNumber: true,
                  })}
                  autoFocus
                  defaultValue={numberOfMarketingDay}
                  rounded="sm"
                />
              </FormControl>
            </Stack>
            <Stack direction="row" mt="4" justifyContent="flex-end">
              <Button
                type="submit"
                colorScheme="primary"
                isDisabled={updateMarketingMailsDays.isLoading}
                isLoading={updateMarketingMailsDays.isLoading}>
                {strings.save_now}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
};

export default MarketingEmailSettings;
