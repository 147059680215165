import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FeedbackQuestionResource from 'api/feedback_question';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FeedbackQuestionForm from 'components/feedback/FeedbackQustionForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { FeedbackQuestionSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const EditQuestion: React.FC = () => {
  const { id: feedbackQuestionId }: any = useParams();
  const feedbackQuestionAPI = new FeedbackQuestionResource();

  const methods = useForm<FeedbackQuestionSchema>();
  const toast = useToast();
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { search } = useLocation();

  const feedbackQuestion = useQuery(
    ['feedbackQuestionEdit', feedbackQuestionId],
    () => {
      if (!feedbackQuestionId) return null;
      return feedbackQuestionAPI
        .get(feedbackQuestionId)
        .then((res) => res.data.data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateFeedbackQuestion = useMutation((data: any) =>
    feedbackQuestionAPI.update(feedbackQuestionId, data)
  );

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    updateFeedbackQuestion.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.feedback_question} ${
            feedbackQuestionId
              ? strings.has_been_updated
              : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.feedback.questions.list);
      },
      onError: () => {
        setErrMsg(
          `${strings.feedback_question} ${
            feedbackQuestionId
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.feedback} |&nbsp;
            {feedbackQuestionId ? strings.edit_question : strings.new_question}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
              {strings.feedback}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.feedback.questions.list + search}>
              {strings.questions}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {feedbackQuestionId
                ? strings.edit_question
                : strings.new_question}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {feedbackQuestionId ? strings.edit_question : strings.new_question}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              {feedbackQuestion.isLoading ? (
                <CenterSpinner />
              ) : (
                <FeedbackQuestionForm data={feedbackQuestion?.data} />
              )}
              <ButtonGroup pt="2">
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {!feedbackQuestionId ? strings.save_now : strings.update}
                </Button>
                <RouterLink to={routes.feedback.questions.list}>
                  <Button variant="outline" isDisabled={isLoading}>
                    {strings.cancel}
                  </Button>
                </RouterLink>
              </ButtonGroup>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditQuestion;
