import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { PUSH_NOTIFICATION_OPTIONS } from 'constants/common';
import { PNSOptions } from 'constants/schema';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

const MAX_CHAR_LIMIT = 255;

interface Props {
  setSendErrorMsg: (error: boolean) => void;
}

const PushNotificationForm: React.FC<any> = (props: Props) => {
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext<any>();

  const { setSendErrorMsg } = props;

  const [characterCount, setCharacterCount] = useState(0);

  function handleMessageChange(e: any) {
    let { value } = e.target;
    let charCount = value.length;
    setCharacterCount(charCount);
    if (value.trim().length !== 0) {
      clearErrors('message');
    }
  }

  if (Object.keys(errors).length !== 0) {
    setSendErrorMsg(true);
  }

  return (
    <form>
      <Stack direction="column" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1"
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.channel} isRequired>
              <FormLabel>{strings.channel}</FormLabel>
              <Select
                {...register('channel', {
                  required: strings.required,
                })}
                name="channel"
                placeholder={strings.channel}
                rounded="sm">
                {PUSH_NOTIFICATION_OPTIONS?.map((item: PNSOptions) => {
                  return (
                    <option key={item} value={item}>
                      {strings[item]}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors?.channel && errors?.channel?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.title} isRequired>
              <FormLabel>{strings.title}</FormLabel>
              <Input
                type="text"
                placeholder={strings.title}
                {...register('title', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.title && errors?.title?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.imageUrl}>
              <FormLabel>{strings.image_link}</FormLabel>
              <Input
                type="url"
                placeholder={strings.image_link}
                {...register('imageUrl')}
              />
              <FormErrorMessage>
                {errors?.imageUrl && errors?.imageUrl?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.launchUrl}>
              <FormLabel>{strings.link}</FormLabel>
              <Input
                type="url"
                placeholder={strings.link}
                {...register('launchUrl')}
              />
              <FormErrorMessage>
                {errors?.launchUrl && errors?.launchUrl?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1" w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.message} isRequired>
              <FormLabel>{strings.text}</FormLabel>
              <Textarea
                placeholder={strings.text}
                {...register('message', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                  maxLength: {
                    value: MAX_CHAR_LIMIT,
                    message: strings.max_characters_exceeded,
                  },
                })}
                onChange={handleMessageChange}
              />
              <Flex justifyContent="space-between">
                <FormErrorMessage
                  justifyContent="flex-start"
                  className="blink_me">
                  {errors?.message && errors?.message?.message}
                </FormErrorMessage>
                <Text
                  justifyContent="flex-end"
                  textAlign="right"
                  color={
                    characterCount > MAX_CHAR_LIMIT ? 'red.500' : 'gray.400'
                  }>
                  {characterCount}/{MAX_CHAR_LIMIT}
                </Text>
              </Flex>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default PushNotificationForm;
