import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/layout';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import CrossSellingResource from 'api/rms-cross-selling';
import RMSDashObjectsResource from 'api/rms-dashboard';
import {
  DashAccordionButtonStyle,
  DashHeadingStyle,
  ObjectDashboardColumnStyle,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import {
  CrossSellingDetailInterface,
  DashboardBookingListInterface,
  DataWrapperSchema,
} from 'constants/schema';
import React, { ChangeEvent, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import CrossSellingArea from './CrossSellingArea';

interface Props {
  resourceId: number;
  customerEmail: string;
}

const CrossSellingDash: React.FC<Props> = (props) => {
  const { resourceId, customerEmail } = props;
  const [search, setSearch] = useState('');
  const crossSellingAPI = new CrossSellingResource();
  const dashboardAPI = new RMSDashObjectsResource();

  const { data: bookingList, isLoading: boolingListIsLoading } = useQuery<
    DataWrapperSchema<DashboardBookingListInterface[]>
  >({
    queryKey: ['dashboard-get-latest-user-booking-list'],
    queryFn: async () => {
      const response = await dashboardAPI.dashboardCustomerReservations(
        resourceId,
        {
          type: 'upcoming',
        }
      );
      return response?.data;
    },
    refetchOnWindowFocus: false,
  });

  const { data: crossSellingList, isLoading: crossSellingIsLoading } = useQuery<
    DataWrapperSchema<CrossSellingDetailInterface[]>
  >({
    queryKey: ['dashboard-cross-selling-list'],
    queryFn: async () => {
      const response = await crossSellingAPI.list();
      return response?.data;
    },
    refetchOnWindowFocus: false,
  });

  const isLoading = boolingListIsLoading || crossSellingIsLoading;

  return (
    <Stack direction="column" sx={ObjectDashboardColumnStyle}>
      <Heading sx={DashHeadingStyle}>{strings.cross_selling}</Heading>
      <FormControl>
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            children={<BiSearch />}
            color="gray.400"
          />
          <Input
            name="keyword"
            placeholder={strings.search}
            value={search}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
          />
        </InputGroup>
      </FormControl>
      {isLoading && <CenterSpinner />}
      {crossSellingList?.data?.length && bookingList?.data?.length ? (
        <Accordion defaultIndex={[0]} allowToggle width="100%">
          {bookingList.data.map((item: any) => (
            <AccordionItem
              key={item.id}
              bg="gray.50"
              title={item.booking_number}>
              <AccordionButton sx={DashAccordionButtonStyle}>
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Text as="h4" fontWeight="medium" color="white">
                      {item.booking_number}
                    </Text>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <CrossSellingArea
                  crossSellingList={crossSellingList.data}
                  customerEmail={customerEmail}
                  bookingId={item.id}
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <Text textAlign="center">{strings.no_data_found}</Text>
      )}
    </Stack>
  );
};

export default CrossSellingDash;
