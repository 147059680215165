import { FormControl, Text } from '@chakra-ui/react';
import { TransferItemSchema } from 'constants/schema';
import React from 'react';
import { Control, useWatch } from 'react-hook-form';

function QuantityUnitDisplay({
  control,
}: {
  control: Control<TransferItemSchema>;
}) {
  const articleId = useWatch({
    control,
    name: 'article_id',
    defaultValue: null,
  });
  return (
    <FormControl>
      <Text>{articleId?.unit}</Text>
    </FormControl>
  );
}

export default QuantityUnitDisplay;
