import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiMailSend, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat, utcToLocal } from 'utils/DateFormat';

interface Props {
  data: any;
  index: number;
}

const MailsListItem: React.FC<Props> = (props) => {
  const { data, index } = props;
  let { reservation_id, reservation, last_email_sent_at } = data;
  const { from_date, to_date } = reservation;
  const status:
    | 'pending'
    | 'confirmed'
    | 'sent'
    | 'received'
    | 'closed'
    | 'no_email'
    | 'resent' = data?.status;

  const selectColorScheme = (status: any) => {
    const statusName = status.toLowerCase();
    const statusColorScheme: { [key: string]: string } = {
      pending: 'orange.400',
      confirmed: 'blue.300',
      sent: 'green.400',
      resent: 'green.400',
      received: 'yellow.300',
      closed: 'red.300',
      no_email: 'gray.300',
    };
    return statusColorScheme[statusName];
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const feedbackResourceAPI = new FeedbackResource();
  const toast = useToast();

  const from_date_DD_YY_MM = DateFormat(from_date);
  const to_date_DD_YY_MM = DateFormat(to_date);

  const sendFeedbackMail = useMutation((reservation_id: number) =>
    feedbackResourceAPI.sendFeedbackMail(reservation_id)
  );

  const handleFeedbackMailClick = async (data: any) => {
    sendFeedbackMail.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.feedback_mail_sent_sucessfully} `,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: `${strings.feedback_mail_not_sent} `,
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  const deleteFeedbackMailAPI = useMutation(
    (id: number) => feedbackResourceAPI.deleteFeedbackMail(id),
    {
      onSuccess: () => {
        setIsDeleteLoading(false);
        setDeleteModalOpen(false);
        toast({
          title: strings.success,
          description: strings.feedback_mail_deleted_successfully,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries('feedbackMailsList');
      },
      onError: () => {
        setIsDeleteLoading(false);
        setDeleteModalOpen(false);
        toast({
          title: strings.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteLoading(false);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    setIsDeleteLoading(true);
    deleteFeedbackMailAPI.mutate(reservation_id);
  };

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>
        {`${reservation.customer_info.forename} ${reservation.customer_info.surname} `}
      </Td>
      <Td>{reservation?.booking_number}</Td>
      <Td>{reservation?.apartment?.name}</Td>
      <Td isNumeric>{`${from_date_DD_YY_MM} - ${to_date_DD_YY_MM} `}</Td>
      <Td isNumeric>
        {last_email_sent_at
          ? utcToLocal(last_email_sent_at).replace(' ', ', ')
          : '-'}
      </Td>
      <Td>
        <Text
          color={selectColorScheme(status) ?? 'blackAlpha.700'}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {strings[status] || '-'}
        </Text>
      </Td>
      <Td>
        {(status === 'pending' || status === 'sent') && (
          <RouterLink to={routes.feedback.mails.list}>
            <Tooltip hasArrow label={strings.resend_mail}>
              <IconButton
                icon={<BiMailSend />}
                variant="link"
                aria-label={strings.resend_mail}
                color="blue.300"
                size="lg"
                minW="6"
                onClick={() => handleFeedbackMailClick(reservation_id)}
              />
            </Tooltip>
          </RouterLink>
        )}
        {status === 'pending' && (
          <Tooltip hasArrow label={strings.delete_mail}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_mail}
              color="red.300"
              minW="6"
              onClick={onDeletePress}
            />
          </Tooltip>
        )}
      </Td>
      <Modal
        isOpen={isDeleteModalOpen}
        isCentered
        closeOnOverlayClick={!isDeleteLoading}
        onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.delete_mail}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.confirm_delete}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                isLoading={isDeleteLoading}
                onClick={onDeleteConfirm}>
                {strings.delete}
              </Button>
              <Button
                variant="outline"
                isDisabled={isDeleteLoading}
                onClick={onDeleteModalClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr>
  );
};

export default MailsListItem;
