import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CustomerDocumentResource from 'api/customer_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CustomerDocumentListItem from 'components/customer_document/CustomerDocumentListItem';
import DMSDocumentSearch from 'components/dms/DMSDocumentSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import {
  DataWrapperSchema,
  DMSCustomerObjectDocumentSchema,
  DMSDocumentFilterParams,
} from 'constants/schema';
import React, { useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCloudUpload } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';

const CustomerList: React.FC = () => {
  const { id }: any = useParams();
  let customerDocumentAPI = new CustomerDocumentResource();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const createdAt = Number(searchValues.created_at);
  const availableFromDate = Number(searchValues.available_from_date);
  const availableToDate = Number(searchValues.available_to_date);

  const [filterParams, setFilterParams] = useState<DMSDocumentFilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    created_at: createdAt ? new Date(createdAt) : null,
    available_from_date: availableFromDate ? new Date(availableFromDate) : null,
    available_to_date: availableToDate ? new Date(availableToDate) : null,
  });

  const customerDocumentList = useQuery<
    DataWrapperSchema<DMSCustomerObjectDocumentSchema[]>
  >(
    [
      'customerDocumentList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        created_at: filterParams.created_at,
        available_from_date: filterParams.available_from_date,
        available_to_date: filterParams.available_to_date,
      },
    ],
    async () => {
      const { keyword, created_at, available_from_date, available_to_date } =
        filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer_id: id,
      };
      if (keyword) queryParams.keyword = keyword;
      if (created_at) queryParams.created_at = DateFormatYMD(created_at);
      if (available_from_date)
        queryParams.available_from_date = DateFormatYMD(available_from_date);
      if (available_to_date)
        queryParams.available_to_date = DateFormatYMD(available_to_date);

      const response = await customerDocumentAPI.list(queryParams);
      return response?.data;
    }
  );

  const customerDocumentQuery = useQuery([`customerDocument${id}`], () =>
    customerDocumentAPI
      .getCustomer(id)
      .then((res) => res.data.data)
      .catch((e) => {
        if (e.response.status == 404)
          return { full_name: strings.deleted_customer };
      })
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.dms} | {customerDocumentQuery?.data?.full_name ?? ' '}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.customerDocuments.list + search}>
              {strings.document_customers}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>
              {customerDocumentQuery?.data?.full_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.customer_documents}
          </Heading>
          <RouterLink
            to={routes.dms.customerDocuments.customer.upload.replace(
              ':id',
              id.toString()
            )}>
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiCloudUpload} w="6" h="6" />}
              size="sm">
              {strings.upload_document}
            </Button>
          </RouterLink>
        </Flex>
        <Stack>
          <DMSDocumentSearch
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.id}</Th>
                  <Th>{strings.document_name}</Th>
                  <Th>{strings.upload_date}</Th>
                  <Th>{strings.from}</Th>
                  <Th>{strings.until}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!customerDocumentList.isLoading &&
                  customerDocumentList?.data?.data?.map(
                    (customerDocumentData, index: number) => (
                      <CustomerDocumentListItem
                        key={customerDocumentData.id}
                        index={startingSN + index}
                        customerDocumentData={customerDocumentData}
                      />
                    )
                  )}
                {customerDocumentList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={customerDocumentList}
        />
      </Stack>
    </>
  );
};

export default CustomerList;
