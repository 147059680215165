import { Button, ButtonGroup, IconButton } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Link, Stack, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Td, Tr } from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import RMSCrossSellingResource from 'api/rms-cross-selling';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { CrossSellingDetailInterface } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  crossSelling: CrossSellingDetailInterface;
  index: number;
}

const CrossSellingListItem: React.FC<Props> = (props) => {
  const { crossSelling, index } = props;
  const crossSellingAPI = new RMSCrossSellingResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const deleteService = useMutation({
    mutationFn: () => crossSellingAPI.destroy(crossSelling.id),
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries('cross-selling-list');
      toast({
        status: 'success',
        title: strings.successfully_deleted_cross_selling,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
      });
    },
  });

  const checkEditCrossSellingPermission = useCheckPermission(
    [PermissionRequest['manage:cross-selling']],
    routes.objectDashboard.crossSelling.edit.replace(
      ':id',
      crossSelling.id.toString()
    )
  );

  return (
    <>
      <Tr>
        <Td>{index}</Td>
        <Td>{crossSelling.service_name}</Td>
        <Td>
          <Stack direction="row" alignItems="baseline">
            <Link onClick={checkEditCrossSellingPermission}>
              <Tooltip hasArrow label={strings.edit}>
                <IconButton
                  icon={<BiEdit />}
                  variant="link"
                  aria-label={strings.edit}
                  color="blue.300"
                  minW="6"
                />
              </Tooltip>
            </Link>
            <Tooltip hasArrow label={strings.delete}>
              <IconButton
                icon={<BiTrash />}
                variant="link"
                aria-label={strings.delete}
                color="red.300"
                minW="6"
                onClick={onOpen}
              />
            </Tooltip>
          </Stack>
        </Td>
      </Tr>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text as="p" color="paragraph" fontSize="18px">
              {strings.delete_cross_selling}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.confirm_delete}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="solid"
                colorScheme="primary"
                isLoading={deleteService.isLoading}
                onClick={() => deleteService.mutate()}>
                {strings.delete}
              </Button>
              <Button variant="outline" colorScheme="outline">
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CrossSellingListItem;
