import {
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Textarea,
} from '@chakra-ui/react';
import CustomSelect from 'components/common/CustomSelect';
import { strings } from 'config/localization';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import 'react-perfect-scrollbar/dist/css/styles.css';

const BOOKING_SEARCH_API = `/keyscan/reservation`;
const CUSTOMER_SEARCH_API = `/keyscan/customer`;
const EXTERNAL_COMPANY_SEARCH_API = `/keyscan/external-company`;

interface Props {
  isInStock: boolean;
}

const KeyNewStatusForm: React.FC<Props> = (props) => {
  const { isInStock } = props;

  const { register, control, setValue } = useFormContext<any>();

  const onBookingChange = (e: any) => {
    setValue('reservationId', e);
  };

  const onCustomerChange = (e: any) => {
    setValue('customerId', e);
  };

  const onExternalCompanyChange = (e: any) => {
    setValue('externalCompanyId', e);
  };

  const newKeyStatus = isInStock ? strings.given_out : strings.in_stock;
  const disabled: boolean = !isInStock;

  return (
    <Grid
      gap="4"
      templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
      w="100%">
      <GridItem>
        <FormControl isDisabled>
          <FormLabel>{strings.new_status}</FormLabel>
          <Input
            type="text"
            name="keyword"
            value={`${newKeyStatus}`}
            style={{ opacity: 0.8 }}
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isDisabled={disabled}>
          <FormLabel>{strings.external_company}</FormLabel>
          <Controller
            control={control}
            name="externalCompanyId"
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder={strings.external_company}
                  value={field?.value}
                  onChange={onExternalCompanyChange}
                  SEARCH_API={EXTERNAL_COMPANY_SEARCH_API}
                  limit={30}
                  replaceNameInOption="name"
                  disable={disabled}
                />
              );
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isDisabled={disabled}>
          <FormLabel>{strings.booking_number}</FormLabel>
          <Controller
            control={control}
            name="reservationId"
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder={strings.booking_number}
                  value={field?.value}
                  onChange={onBookingChange}
                  SEARCH_API={BOOKING_SEARCH_API}
                  limit={30}
                  replaceNameInOption="bookingNumber"
                  disable={disabled}
                />
              );
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl isDisabled={disabled}>
          <FormLabel>{strings.customer}</FormLabel>
          <Controller
            control={control}
            name="customerId"
            render={({ field }) => {
              return (
                <CustomSelect
                  placeholder={strings.customer}
                  value={field?.value}
                  onChange={onCustomerChange}
                  SEARCH_API={CUSTOMER_SEARCH_API}
                  limit={30}
                  replaceNameInOption="fullName"
                  disable={disabled}
                />
              );
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={[1, 2]}>
        <FormControl>
          <FormLabel>{strings.notes}</FormLabel>
          <Textarea placeholder={strings.notes} {...register('notes')} />
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default KeyNewStatusForm;
