import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CRMDashboardResource from 'api/crm-dashboard';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ContactSearch from 'components/crm/ContactSearch';
import DashboardCustomerListItem from 'components/object-dashboard/dashboard/customer/DashboardCustomerListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import {
  DashboardCustomerContactList,
  DataWrapperSchema,
} from 'constants/schema';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, cleanData, getStartingSerialNumber } from 'utils';
import {
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  first_name: string | null;
  last_name: string | null;
  location: string | null;
  street: string | null;
  type: string | null;
  source: string | null;
  updated_at_from: Date | null;
  updated_at_to: Date | null;
  is_merged: string | null;
  email: string | null;
  phone: string | null;
  mobile: string | null;
  newsletter_subscribed: string | null;
}

const DashboardCustomerList: React.FC = () => {
  const crmDashboardAPI = new CRMDashboardResource();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const updated_at_from = Number(searchValues.updated_at_from);
  const updated_at_to = Number(searchValues.updated_at_to);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    first_name: searchValues.first_name ?? null,
    last_name: searchValues.last_name ?? null,
    location: searchValues.location ?? null,
    street: searchValues.street ?? null,
    type: searchValues.type ?? null,
    source: searchValues.source ?? null,
    updated_at_from: updated_at_from ? new Date(updated_at_from) : null,
    updated_at_to: updated_at_to ? new Date(updated_at_to) : null,
    is_merged: searchValues.is_merged ?? null,
    email: searchValues.email ?? null,
    phone: searchValues.phone ?? null,
    mobile: searchValues.mobile ?? null,
    newsletter_subscribed: searchValues.newsletter_subscribed ?? null,
  });

  const contactList = useQuery<
    DataWrapperSchema<DashboardCustomerContactList[]>
  >(
    [
      'dashboard-customer-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        first_name: filterParams.first_name,
        last_name: filterParams.last_name,
        location: filterParams.location,
        street: filterParams.street,
        type: filterParams.type,
        source: filterParams.source,
        updated_at_from: filterParams.updated_at_from,
        updated_at_to: filterParams.updated_at_to,
        is_merged: filterParams.is_merged,
        email: filterParams.email,
        phone: filterParams.phone,
        mobile: filterParams.mobile,
        newsletter_subscribed: filterParams.newsletter_subscribed,
      },
    ],
    async () => {
      const queryParams = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        first_name: filterParams.first_name,
        last_name: filterParams.last_name,
        location: filterParams.location,
        street: filterParams.street,
        type: filterParams.type,
        source: filterParams.source,
        updated_at_from: filterParams.updated_at_from
          ? getUTCDateRangeStartDateTime(filterParams.updated_at_from)
          : null,
        updated_at_to: filterParams.updated_at_to
          ? getUTCDateRangeEndDateTime(filterParams.updated_at_to)
          : null,
        is_merged: filterParams.is_merged,
        email: filterParams.email,
        phone: filterParams.phone,
        newsletter_subscribed: filterParams.newsletter_subscribed,
        mobile: filterParams.mobile,
      };
      const cleanQueryParams = cleanData(queryParams);
      const response = await crmDashboardAPI.dashboardBookingList(
        cleanQueryParams
      );
      return response?.data.data;
    }
  );

  const handleAdvancedSearch = useCallback((data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      first_name: data.first_name,
      last_name: data.last_name,
      location: data.location,
      street: data.street,
      type: data.type,
      source: data.source,
      updated_at_from: data.updated_at_from,
      updated_at_to: data.updated_at_to,
      is_merged: data.is_merged,
      email: data.email,
      phone: data.phone,
      mobile: data.mobile,
      newsletter_subscribed: data.newsletter_subscribed,
    }));
  }, []);

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.updated_at_from) {
      data.updated_at_from = Date.parse(data.updated_at_from);
    }
    if (data.updated_at_to) {
      data.updated_at_to = Date.parse(data.updated_at_to);
    }
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.object_dashboard} | {strings.all_contacts}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.objectDashboard.dashboard.contactList}>
            {strings.object_dashboard}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={routes.objectDashboard.dashboard.contactList}>
            {strings.all_contacts}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.contacts}
        </Heading>
      </Flex>
      <Accordion bg="white" borderColor="white" allowToggle defaultIndex={[0]}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="16px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0" className="focusable-accordian-panel">
            <ContactSearch
              filterParams={filterParams}
              handleAdvancedSearch={handleAdvancedSearch}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.booking}</Th>
                <Th>{strings.address}</Th>
                <Th>{strings.email}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contactList.data?.data.map((contactData, index) => (
                <DashboardCustomerListItem
                  key={contactData.id}
                  index={startingSN + index}
                  contactData={contactData}
                />
              ))}
              {contactList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={contactList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default DashboardCustomerList;
