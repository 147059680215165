import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CustomerDocumentResource from 'api/customer_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import GeneralDocumentForm from 'components/general_document/GeneralDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const UploadCustomer: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const methods = useForm<any>();
  const customerDocumentAPI = new CustomerDocumentResource();
  const [errorMessage, setErrorMessage] = useState('');

  const addCustomerDocument = useMutation(
    (data: any) => customerDocumentAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.general_document_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('customerDocumentList');
        history.goBack();
      },
      onError: (error: any, response: any) => {
        if (error?.response?.data?.file) {
          setErrorMessage(error?.response?.data?.file[0]);
        } else {
          setErrorMessage(strings.general_document_creation_failed);
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    const formData: any = new FormData();

    formData.append('name', data.name);
    formData.append('customer_id', id);
    if (data.available_from_date)
      formData.append('available_from_date', data.available_from_date);
    if (data.available_to_date)
      formData.append('available_to_date', data.available_to_date);
    if (data.upload_files === undefined) {
      methods.setError('upload_files', { message: strings.required_upload });
      return;
    }
    formData.append('file', data.upload_files);
    addCustomerDocument.mutate(formData);
  };

  const customerDocumentQuery = useQuery([`customerDocument${id}`], () =>
    customerDocumentAPI.getCustomer(id).then((res) => res.data.data)
  );

  if (customerDocumentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (customerDocumentQuery.isError) {
    history.push(routes.dms.customerDocuments.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.dms} | {strings.upload_document_customer}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.customerDocuments.list}>
              {strings.general_documents}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.customerDocuments.customer.list.replace(
                ':id',
                customerDocumentQuery?.data?.id?.toString()
              )}>
              {customerDocumentQuery?.data?.full_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.customerDocuments.customer.upload}>
              {strings.upload_document_customer}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.upload_document_customer}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack
                direction="column"
                spacing="10"
                justifyContent="space-between">
                {addCustomerDocument.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
                <GeneralDocumentForm />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={addCustomerDocument.isLoading}>
                    {strings.save_now}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default UploadCustomer;
