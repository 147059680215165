import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InventoryResource from 'api/inventories';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import InventoryListItem from 'components/inventory/InventoryListItem';
import InventorySearch from 'components/inventory/InventorySearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { InventorySchema, InventorySearchInput } from 'constants/schema';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  article: string;
  currentStock: string;
}

type LoadingType = 'filter' | 'reset' | '';

type QueryParamType = {
  page: number;
  limit: number;
  article?: string;
  currentStock?: string;
};

const Inventory: React.FC = () => {
  const inventoryAPI = new InventoryResource();

  const [isLoading, setIsLoading] = useState<LoadingType>('');
  const history = useHistory();
  const { search } = useLocation();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    article: '',
    currentStock: '',
  });

  const inventoryList = useQuery(
    [
      'inventoryList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        article: filterParams.article,
        currentStock: filterParams.currentStock,
      },
    ],
    async () => {
      const queryParam: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.article) queryParam.article = filterParams.article;
      if (filterParams.currentStock)
        queryParam.currentStock = filterParams.currentStock;

      const response = await inventoryAPI.list(queryParam);
      response && setIsLoading('');
      return response?.data?.data;
    }
  );

  /**
   * Single handler function for `apply filter` and `reset`
   * loader = 'filter' -> apply filter
   * loader = 'reset' -> reset filter (data contains the default values)
   *
   * @param data {InventorySearchInput} Filter inputs
   * @param loader {LoadingType} 'filter' or 'reset'
   */

  const handleAdvancedSearch = useCallback(
    (data: any, loader: LoadingType) => {
      setIsLoading(loader);
      setFilterParams((prevState) => ({
        ...prevState,
        currentPage: INITIAL_CURRENT_PAGE,
        article: data.article,
        currentStock: data.currentStock,
      }));
      const searchURL = changeURL(data);
      history.push(`?${searchURL}`);
    },
    [history]
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mms} | {strings.all_inventory}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
            {strings.mms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
            {strings.all_inventory}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.inventories}
        </Heading>
        <RouterLink to={routes.mms.transferItems}>
          <Button colorScheme="primary" size="sm">
            {strings.transfer_item}
          </Button>
        </RouterLink>
      </Flex>

      <InventorySearch
        isLoading={isLoading}
        handleAdvancedSearch={handleAdvancedSearch}
      />

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{strings.inventory_name}</Th>
                <Th isNumeric>{strings.total_quantity}</Th>
                <Th>{strings.unit}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {inventoryList?.data?.data?.map(
                (inventory: InventorySchema, index: number) => (
                  <InventoryListItem
                    inventory={inventory}
                    index={startingSN + index}
                    key={index}
                    search={search}
                  />
                )
              )}
              {inventoryList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={inventoryList}
      />
    </Stack>
  );
};

export default Inventory;
