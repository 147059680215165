import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import PartnerGoodiesList from 'components/third_party_partner/PartnerGoodiesList';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const ThirdPartyPartnerDetails: React.FC = () => {
  const { id: thirdPartyPartnerId }: any = useParams();
  const { search } = useLocation();

  let thirdPartyPartnerAPI = new ThirdPartyPartnerResource();
  const toast = useToast();

  const { data: thirdPartyPartnerDetail, isLoading } = useQuery(
    ['third-party-partner-details-page', thirdPartyPartnerId],
    () => {
      if (!thirdPartyPartnerId) return null; // { data: null };
      return thirdPartyPartnerAPI
        .get(thirdPartyPartnerId)
        .then((res) => res.data.data)
        .catch(() => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  );

  const [active, setActive] = useState(!!thirdPartyPartnerDetail?.is_active);

  useEffect(() => {
    if (thirdPartyPartnerDetail?.is_active) {
      setActive(thirdPartyPartnerDetail?.is_active);
    }
  }, [thirdPartyPartnerDetail?.is_active]);

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.membership} | &nbsp;
            {strings.detail_third_party_partner}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
              {strings.membership}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.thirdPartyPartner.thirdPartyPartner.list + search}>
              {strings.third_party_partner}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.detail_third_party_partner}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.third_party_partner} {thirdPartyPartnerDetail?.name}
          </Heading>
          <RouterLink
            to={routes.thirdPartyPartner.thirdPartyPartner.edit.replace(
              ':id',
              thirdPartyPartnerId.toString()
            )}>
            <Button size="sm" colorScheme="primary" type="button">
              {strings.edit_third_party_partner}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Grid gap="4" templateColumns={['repeat(1, 1fr)']} w="100%">
            <GridItem>
              <FormControl>
                <FormLabel>{strings.name_third_party_partner}</FormLabel>
                <Box>{thirdPartyPartnerDetail?.name}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.brief_description}</FormLabel>
                <Box>{thirdPartyPartnerDetail?.brief_description}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.description}</FormLabel>
                <Box>{thirdPartyPartnerDetail?.description}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl></FormControl>
              <FormLabel>{strings.active}</FormLabel>
              <Box>
                {' '}
                <Checkbox isChecked={active} disabled />
              </Box>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{strings.icon}</FormLabel>
                <Box>
                  {' '}
                  <Image
                    src={thirdPartyPartnerDetail?.icon}
                    alt={thirdPartyPartnerDetail?.icon}
                    width="150"
                    height="150"
                  />
                </Box>
              </FormControl>
            </GridItem>
          </Grid>

          <PartnerGoodiesList
            data={thirdPartyPartnerDetail?.partner_goodies}
            thirdPartyPartnerId={thirdPartyPartnerId}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ThirdPartyPartnerDetails;
