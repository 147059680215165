import {
  Avatar,
  Flex,
  Heading,
  Icon,
  Stack,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { setCurrentChatUser } from 'actions/data/chat';
import { getChatUserDetail } from 'api/chat';
import { strings } from 'config/localization';
import React, { useEffect } from 'react';
import { BiPhoneCall } from 'react-icons/bi';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

interface Props {
  name: string;
  roomName: string;
  date: string;
  message: string;
  unreadChatCount?: number;
  avatar: string;
  chatId: String;
  phoneRingingRoom: string;
  callInProgressRoom: string;
}

const ChatListItem: React.FC<Props> = (props) => {
  const {
    name,
    roomName,
    date,
    message,
    avatar,
    chatId,
    phoneRingingRoom,
    callInProgressRoom,
    unreadChatCount = 0,
  } = props;
  const dispatch = useDispatch();

  const { currentChatUserDetails } = useSelector(
    (state: any) => ({
      currentChatUserDetails: state?.data?.chat.currentChatUser,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (unreadChatCount > 0) {
    }
  }, [unreadChatCount]);

  const handleCurrentChatUser = async () => {
    const response = await getChatUserDetail(chatId);
    dispatch(setCurrentChatUser(response.data.data));
  };

  // Indicate that a given room is making a call or is in an active call
  const isRoomInCall =
    phoneRingingRoom === roomName || callInProgressRoom === roomName;

  return (
    <Stack
      _hover={{ cursor: 'pointer' }}
      position="relative"
      rounded="sm"
      p="1"
      onClick={() => handleCurrentChatUser()}>
      <Stack
        direction={['column', 'column']}
        spacing="2"
        p={['1', '2', '4', '4']}
        shadow="box"
        bg="white"
        sx={
          currentChatUserDetails._id === chatId
            ? { boxShadow: '0px 3px 0px 0px #8ef2d3' }
            : { boxShadow: 'none' }
        }>
        <Stack
          direction={['column', 'column', 'row', 'row']}
          spcing="2"
          width="100%">
          <Stack width={['25%', '10%']}>
            <Avatar showBorder src={avatar} shadow="md" size={'sm'} />
          </Stack>
          <Flex
            justify="space-between"
            width={['75%', '90%']}
            pl={['2', '1']}
            direction={['column', 'column', 'row', 'row']}>
            <Heading
              width="75%"
              size="xs"
              fontWeight="medium"
              alignSelf="center"
              wordBreak="break-all">
              {name}
            </Heading>
            <Text
              width="25%"
              fontSize="xs"
              fontWeight="normal"
              color="gray.300"
              wordBreak="normal"
              textAlign="right">
              {date}
            </Text>
          </Flex>
        </Stack>
        <Stack>
          <Flex justify="space-between">
            <Text fontSize="xs" color="gray.400">
              {message}
            </Text>
            {isRoomInCall && (
              <Tooltip
                hasArrow
                label={strings.call_in_progress}
                textAlign="right">
                <span>
                  <Icon as={BiPhoneCall} w="4" h="4" color="green.400" />
                </span>
              </Tooltip>
            )}
          </Flex>
        </Stack>
      </Stack>
      {unreadChatCount > 0 && (
        <Tag
          bg="red.400"
          rounded="full"
          color="white"
          fontSize="10px"
          position="absolute"
          right="3"
          bottom="3"
          size="sm">
          {unreadChatCount}
        </Tag>
      )}
    </Stack>
  );
};

export default ChatListItem;
