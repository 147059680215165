import io from 'socket.io-client';
import { getAccessToken } from './auth';
const CONNECTION_PORT: any = process.env.REACT_APP_SOCKET_CONNECTION_PORT;
const AIRCALL_SOCKET: any = process.env.REACT_APP_AIRCALL_SOCKET_URL;

export const socket = io(CONNECTION_PORT, {
  transports: ['websocket'],
  upgrade: false,
  autoConnect: false,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
});

const token = getAccessToken();
export const airCallSocket = io(AIRCALL_SOCKET, {
  transports: ['websocket'],
  upgrade: false,
  auth: {
    Authorization: token ? `Bearer ${token}` : null,
  },
});
