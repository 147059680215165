const pl = {
  /*login Credential*/
  login: 'Zaloguj',
  log_in: 'Zaloguj sie',
  password: 'hasło',
  forgot_your_password: 'Zapomniałeś hasła',
  email: 'Email',
  your_email_address: 'Twój adres email',
  remember_me: 'Zapamiętaj mnie',
  forgot_password: 'Zapomniałeś hasła',
  forgot_password1: 'Zapomniałeś hasła?',
  back_to_login: 'Powrót do logowania',
  password_reset: 'Zresetuj hasło',
  login_message: 'Wprowadź swój adres email i hasło, aby się zalogować.',
  forgot_message:
    'Wprowadź swój adres email, aby otrzymać link do zmiany hasła.',
  listinfo_service_center: 'Listinfo Service Center',
  appartmentvermittlung_familie_clausen:
    'Appartmentvermittlung Familie Clausen',
  password_info:
    'Hasło musi zawierać co najmniej 1 cyfrę, 1 znak specjalny, 1 wielką literę i 5 innych znaków.',
  invalid_login_msg: 'Nieprawidłowy adres email lub hasło.',
  change_language: 'Zmień język',
  invalid_token: 'Ten token resetowania hasła jest nieważny.',
  two_factor_authentication: 'Two Factor Authentication',
  enter_the_pin_from_google_authentication:
    'Wprowadź pin z Google Authentication',
  authenticate: 'Uwierzytelnianie',
  one_time_password: 'Hasło jednorazowe',
  FA_status: 'Status 2FA',
  disable_2FA: 'Wyłącz 2FA',
  enable_2FA: 'Włącz 2FA',
  fa_message:
    'Zeskanuj ten kod kreskowy za pomocą aplikacji Google Aunthenticator App i wprowadź te kod pin, aby włączyć 2FA',
  fa_enable_status_tooltip: 'Wygeneruj tajny klucz, aby włączyć 2FA',
  fa_disable_status_tooltip: 'Wpisz swoje hasło, aby wyłączyć 2FA',
  authenticator_code: 'Authenticator Code',
  FA_successfully: '2FA is Enable Successfully',
  FA_enable: '2FA is Currently Enabled for your account',
  fa_Disable: '2FA jest teraz Wyłączone',
  authenticator_code_error: 'Błąd kodu uwierzytelniającego',
  required_authenticator_code: 'Wymagany kod uwierzytelniający',
  fa_disable_title:
    'Jeśli chcesz wyłączyć uwierzytelnianie dwuczynnikowe. Proszę potwierdzić swoje hasło i kliknąć przycisk Disable 2FA.',
  disable_2FA_confimation_message:
    'Ønsker du virkelig at deaktivere 2FA for denne bruger?',

  /*button*/
  save_now: 'Zapisz teraz',
  filter: 'Filtruj',
  back: 'Z powrotem',
  apply_filter: 'Zastosuj filtr',
  reset_filter: 'Resetuj filtr',
  more_filter: 'Więcej filtrów',
  cancel: 'Anuluj',
  clear: 'Wyczyść',
  reply: 'Odpowiedz',
  comment: 'Komentarz',
  save_and_create_new_ticket: 'Zapisz i utwórz nowe zgłoszenie',
  confirm_and_close: 'Potwierdź i zamknij',
  confirm_and_transfer_another_items: 'Potwierdź i przenieś inne elementy',
  edit_article: 'Edytuj artykuł',
  confirm: 'Potwierdź',
  euro: 'Euro',
  euro_symbol: '€',
  profile: 'profil',
  my_profile: 'Mój profil',
  edit_profile: 'Edytuj profil',
  profile_updated: 'Profil zaktualizowany',
  profle_update_failed: 'Aktualizacja profilu nie powiodła się',
  password_updated: 'Hasło zaktualizowane',
  not_match_new_confirm_password: 'Nowe hasło i potwierdzenie hasła nie pasują',
  incorrect_password: 'Nieprawidłowe hasło',
  setting: 'Ustawienia',
  settings: 'Ustawienia',
  logout: 'Wyloguj',
  loggedOut: 'Wyloguj',
  change_password: 'Zmień hasło',
  change_now: 'Zmień teraz',
  Current_password: 'Aktualne hasło',
  new_password: 'Nowe hasło',
  confirm_password: 'Potwierdź hasło',
  invalid_current_password: 'Nieprawidłowe aktualne hasło',
  less_than_zero: 'Wartość mniejsza niż zero.',
  email_not_found: 'Nie znaleziono adresu e-mail.',
  email_required: 'Wymagany jest adres e-mail',
  upcoming: 'Nadchodzące',
  past: 'Przeszłe',
  send_email: 'Wyślij e-mail',
  stored_mail: 'Przechowywana poczta',
  email_sent_successfully: 'E-mail został wysłany pomyślnie',
  failed_to_send_email: 'Błąd podczas wysyłania e-maila',
  appointment: 'Powołanie',
  appointment_date_time: 'Data i godzina spotkania',
  enter_appointment_date_time: 'Wprowadź datę i godzinę spotkania',

  /*Module*/
  user_management: 'Zarządzanie użytkownikami',
  chat: 'Czat',
  ticket: 'Zgłoszenie',
  crm: 'CRM',
  contact_center: 'Centrum Kontaktu',
  feedback: 'Opinie',
  mms: 'MMS',
  dashboard: 'Dashboard',
  access_dashboard: 'Dostęp do Dashboard',
  travelytics: 'Travelytics',
  access_travelytics: 'Dostęp do Travelytics',
  /*Splash Screen*/
  choose_subsystem: 'Wybierz system',
  /*User Management*/
  user_management_system: 'System zarządzania użytkownikami',
  user: 'Użytkownik',
  user_list: 'Lista użytkowników',
  user_rights: 'Prawa użytkownika',
  user_roles: 'Role użytkownika',
  user_name: 'Nazwa użytkownika',
  user_profile: 'Profil użytkownika',
  role: 'Rola',
  add_additional_role: 'Dodaj dodatkową rolę',
  add_additional_rights: 'Dodaj dodatkowe prawa',
  additional_rights: 'Dodatkowe prawa',
  manage_extra_rights: 'Zarządzaj dodatkowymi prawami',
  right_from_role: 'Prawa z roli',
  add_role: 'Dodaj rolę',
  role_setup: 'Ustawienia roli',
  system: 'System',
  role_name: 'Nazwa roli',
  privillage: 'Prawa',
  add_user: 'Dodaj użytkownika',
  all_users: 'Wszyscy użytkownicy',
  all_user_with_that_role: 'Wszyscy użytkownicy z tą rolą',
  add_new_user: 'Dodaj nowego użytkownika',
  add_new_role: 'Dodaj nową rolę',
  view_user: 'Zobacz użytkownika',
  edit_user: 'Edytuj użytkownika',
  delete_user: 'Usuń użytkownika',
  save_user: 'Zapisz użytkownika',
  user_deatils: 'Szczegóły użytkownika',
  upload_image: 'Załaduj obraz',
  assigned_tickets: 'Przypisane zgłoszenia',
  action: 'Akcja',
  first_name: 'Imię',
  last_name: 'Nazwisko',
  email_address: 'Adres email',
  valid_email_address: 'Wprowadź prawidłowy adres email',
  contact_info: 'Informacje kontaktowe',
  address: 'Adres',
  view: 'Widok',
  add: 'Dodaj',
  edit: 'Edytuj',
  delete: 'Usuń',
  search: 'Szukaj',
  new_user: 'Nowy użytkownik',
  has_been_assigned: 'Został przypisany na ciebie',
  has_been_transferred: 'Został przeniesiony na ciebie',
  login_with_new_password: 'zaloguj się przy użyciu nowego hasła',
  msg_valid_password:
    'Hasło musi zawierać co najmniej 1 cyfrę, 1 znak specjalny, 1 wielką literę i 5 innych znaków.',
  msg_password_reset_link:
    'Link do resetowania hasła został wysłany na adres email.',
  select_role: 'Wybierz rolę',
  search_username: 'Szukaj nazwy użytkownika',
  rights: 'Prawa',
  reset_password: 'Resetuj hasło',
  reset_password_subtitle:
    'Wprowadź pola, których używasz do resetowania konta.',
  save_reset_password: 'Save Reset Password',
  create_password: 'Utwórz hasło',
  create_password_subtitle:
    'Please enter the fields to create a password for your account.',
  save_password: 'Zapisz hasło',
  password_helper_text:
    'Hasło musi zawierać co najmniej 1 cyfrę, 1 znak specjalny, 1 wielką literę i 5 innych znaków.',
  resend_verification_email: 'Wyślij ponownie email weryfikacyjny',
  verification_email_sent: 'Email weryfikacyjny został wysłany na adres email.',
  email_verification_success:
    'Email weryfikacyjny został wysłany na adres email.',
  email_verification_failed: 'Email weryfikacyjny nie został wysłany.',
  resend_verify_email_error: 'Nie można wysłać emaila weryfikacyjnego.',
  resend_verify_email_success:
    'Email weryfikacyjny został wysłany na adres email.',
  resend_verify_email_success_to:
    'Email weryfikacyjny został wysłany na adres email.',
  email_already_verified: 'Email jest już zweryfikowany.',
  login_to_continue: 'Zaloguj się, aby kontynuować',
  create_password_to_proceed: 'Utwórz hasło, aby kontynuować',
  change_confirm: 'Czy na pewno chcesz to zmienić?',
  cellphone: 'Telefon komórkowy',
  invalid_cellphone: 'Nieprawidłowy numer telefonu komórkowego',
  email_placeholder: 'twójemail@example.com',
  admin: 'Administrator',
  chat_supporter: 'Osoba wspierająca czat',
  ticket_supporter: 'Osoba wspierająca zakup biletów',
  mms_employee: 'MMS Pracownik',
  verified: 'Zweryfikowany',
  not_yet_verified: 'Jeszcze nie zweryfikowany',
  external_companies: 'Firmy zewnętrzne',
  external_company: 'Firma zewnętrzna',
  company_name: 'Nazwa firmy',
  add_new_company: 'Dodaj nową firmę',
  company_created: 'Firma została utworzona.',
  company_name_is_required: 'Nazwa firmy jest wymagana.',
  company_email_is_required: 'Firmowy adres e-mail jest wymagany.',
  please_enter_a_valid_phone_number: 'Proszę wpisać poprawny numer telefonu.',
  enter_a_country_code_at_the_start: 'Wpisz kod kraju na początku.',
  company_creation_failed: 'Utworzenie firmy nie powiodło się.',
  the_email_has_already_been_taken: 'Adres e-mail jest już zajęty.',
  edit_company: 'Edytuj firmę',
  company_updated: 'Firma zaktualizowana pomyślnie.',
  company_udpate_failed: 'Aktualizacja firmy nie powiodła się.',
  delete_company: 'Usuń firmę',
  company_deleted: 'Firma została usunięta.',
  company_delete_error: 'Firma nie może zostać usunięta.',
  company_not_found: 'Nie znaleziono firmy.',
  company_profile: 'profil firmy',
  all_companies: 'Wszystkie firmy',
  company_list: 'Lista firm',
  companies: 'Firmy',
  view_company: 'Zobacz firmę',
  all_roles: 'Wszystkie role',
  user_login_log: 'Dzienniki logowania użytkowników',
  all_login_logs: 'Wszystkie dzienniki logowania',
  access_user_log: 'Dostęp do dzienników logowania',
  foreign_language: 'Język obcy',
  select_language: 'Wybierz język',

  /*Chat System*/
  chat_system: 'System Chatowy',
  closed_chat: 'Zamknięty',
  all_closed_chats: 'Wszystkie zamknięte czaty',
  closed_chat_details: 'Szczegóły zamkniętego chatu',
  archived_chat: 'Zarchiwizowany',
  all_archived_chats: 'Wszystkie zarchiwizowane czaty',
  archived_chat_details: 'Szczegóły zarchiwizowanego chatu',
  chat_metrics: 'Metryki chatu',
  all_chat_metrics: 'Wszystkie metryki czatów',
  sn: '#',
  id: 'ID',
  customer_name: 'Nazwa klienta',
  employee_name: 'Nazwa pracownika',
  closed_at: 'Zamknięty',
  last_message: 'Ostatnia wiadomość',
  actions: 'Akcje',
  no_of_chat: 'Liczba chatów',
  no_of_active_chat: 'Liczba aktywnych chatów',
  no_of_message: 'Liczba wiadomości',
  no_messages_received: 'Brak wiadomości',
  no_messages_send: 'Brak wiadomości',
  time_spent_in_call: 'Czas spędzony w rozmowie',
  role_descripition: 'Opis roli',
  name: 'Nazwa',
  assignee: 'Przypisany',
  description: 'Opis',
  transfer_chat: 'Przenieś chat',
  select_user: 'Wybierz użytkownika',
  transfer_closed_chat: 'Przenieś zamknięty chat',
  transfer_archived_chat: 'Przenieś zarchiwizowany chat',
  transfer_modal_closed_message: 'Przenieś zamknięty chat',
  transfer_modal_archived_message: 'Przenieś zarchiwizowany chat',
  yes: 'Tak',
  no: 'Nie',
  call: 'Rozmowa',
  miss_called: 'Brak rozmowy',
  active: 'Aktywny',
  in_active: 'Nieaktywny',
  select_apartment: 'Wybierz apartament',
  select_assignee: 'Wybierz przypisanego',
  select_customer: 'Wybierz klienta',
  select_cost_recepient: 'Wybierz odbiorcę kosztów',
  select_house_owner: 'Wybierz właściciela domu',
  select_priority: 'Wybierz priorytet',
  select_source: 'Wybierz źródło',
  write_description: 'Wpisz opis',
  call_ended: 'Rozmowa zakończona',
  message_not_sent: 'Wiadomość nie została wysłana',
  join_room_error: 'Nie można dołączyć do pokoju',
  make_call_error: 'Nie można wykonać rozmowy',
  cancel_call_error: 'Nie można anulować rozmowy',
  chat_not_found: 'Nie znaleziono chatu',
  chat_not_selected: 'Nie wybrano chatu',
  chat_active_toggle_tooltip_message:
    'Nie można zmienić statusu chatu, ponieważ jest aktywny',
  chat_users_not_available_for_transferral:
    'Nie można przenieść chatu, ponieważ nie ma użytkowników',
  call_in_progress: 'Rozmowa w trakcie',
  click_to_cancel: 'Kliknij, aby anulować',
  call_cancelled: 'Rozmowa anulowana',
  make_call: 'Wykonaj rozmowę',
  chat_active_info: 'Chat jest aktywny',
  no_chat_assigned: 'Nie przypisano chatu',
  is_typing: 'Pisze...',

  /*Ticket Board*/
  ticket_system: 'Ticket System',
  all_tickets: 'Wszystkie bilety',
  ticket_board: 'Ticket Board',
  add_task: 'Dodaj zadanie',
  open: 'Otwarte',
  inprogress: 'W trakcie',
  done: 'Zrobione',
  closed: 'Zamknięte',
  recursive_task: 'Powtarzające się zadania',
  normal_task: 'Zadania normalne',
  create_ticket: 'Utwórz ticket',
  ticket_details: 'Szczegóły ticketu',
  title: 'Tytuł',
  ticket_title: 'Tytuł ticketu',
  object: 'Obiekt',
  all_objects: 'Wszystkie obiekty',
  customer: 'Klient',
  cost_recipient: 'Odbiorca kosztów',
  house_owner: 'Właściciel domu',
  priority_label: 'Priorytet',
  priority: 'Priorytet',
  upload_files: 'Prześlij pliki',
  descriptions: 'Opis',
  save_create_new_ticket: 'Zapisz i utwórz nowy ticket',
  author: 'Autor',
  created_by: 'Utworzony przez',
  created_at: 'Utworzony',
  last_changed_by: 'Ostatnio zmieniony przez',
  last_changed_at: 'Ostatnio zmieniony',
  status: 'Status',
  ticket_log: 'Dziennik ticketu',
  ticket_list: 'Lista ticketów',
  repeat_task: 'Powtarzaj zadanie',
  recursive_interval: 'Powtarzaj co',
  recursive_untill: 'Powtarzaj do',
  recursive_start: 'Powtarzaj od',
  apartment: 'Apartament',
  ticket_created: 'Ticket utworzony',
  has_been_created: 'Został utworzony',
  has_not_been_created: 'Nie został utworzony',
  has_been_merged: 'Został połączony',
  has_not_been_merged: 'Nie został połączony',
  has_been_updated: 'Został zaktualizowany',
  has_not_been_updated: 'Nie został zaktualizowany',
  has_been_deleted: 'Został usunięty',
  has_not_been_deleted: 'Nie został usunięty',
  ticket_error: 'Błąd ticketu',
  expand: 'Rozwiń',
  ticket_list_error: 'Błąd listy ticketów',
  select_recursive_interval: 'Wybierz powtarzający się interwał',
  title_required: 'Tytuł jest wymagany',
  repeating_info: 'Powtarzające się zadania',
  interval: 'Interwał',
  repeat_from: 'Powtarzaj od',
  repeat_until: 'Powtarzaj do',
  validate_recursive_date: 'Wybierz datę powtarzania',
  validate_recursive_until: 'Proszę wprowadzić datę późniejszą niż Powtórz od',
  comment_here: 'Komentarz...',
  comment_required: 'Komentarz jest wymagany',
  id_not_found: 'Nie znaleziono id',
  edit_ticket: 'Edytuj ticket',
  ticket_updated: 'Ticket zaktualizowany',
  change_status: 'Zmień status',
  status_updated: 'Status zaktualizowany',
  select_status: 'Wybierz status',
  comment_error: 'Błąd komentarza',
  view_more: 'Zobacz więcej',
  view_less: 'Zobacz mniej',
  billability: 'Billability',
  billable: 'Billable',
  spent_time: 'Spędzony czas',
  correct_spent_time: 'Prawidłowo spędzony czas',
  spent_time_message: 'Spędzony czas został skorygowany ręcznie.',
  task: 'Zadanie',
  low: 'Niski',
  medium: 'Średni',
  high: 'Wysoki',
  urgent: 'Pilny',
  normal: 'Normalny',
  repeating: 'Powtarzające się',
  select_type: 'Wybierz typ',
  ticket_detail: 'Ticket Detail',
  edit_task: 'Edit  Task',
  created_ticket: 'created ticket',
  updated_ticket: 'updated ticket',
  changed_status: 'changed status',
  assigned_ticket: 'assigned ticket',
  commented: 'commented',
  order_by: 'Zamów przez',
  createdat: 'Utworzono w',
  order: 'Zamówienie',
  asc: 'Wstępna',
  desc: 'Zjeżdżający',
  select_sort: 'wybrać rodzaj',
  select_order: 'Wybierz Kolejność',
  ticket_name: 'Tytuł ',
  select_external_company: 'Wybierz firmę zewnętrzną',
  showing_all_customers: 'Pokaż wszystkich klientów',
  showing_all_options: 'Wyświetlanie wszystkich opcji',
  full_screen: 'Pełny ekran',
  extenal_company_ticket: 'Bilet dla firmy zewnętrznej',
  external_company_button_tooltip:
    'Aby wygenerować listę rzeczy do zrobienia w firmie zewnętrznej, wybierz firmę zewnętrzną i datę spotkania w filtrze.',
  external_company_filter_message:
    'Najpierw wybierz firmę zewnętrzną, a następnie datę spotkania.',
  todo_list: 'Lista rzeczy do zrobienia',
  assignee_button_tooltip:
    'Aby wygenerować listę rzeczy do zrobienia przez cesjonariusza,wybierz cesjonariusza i datę spotkania w filtrze.',
  todo_button_tooltip:
    'Aby wygenerować listę rzeczy do zrobienia przez cesjonariusza i firmę zewnętrzną.',

  /*CRM*/
  customer_relation_management: 'Customer Reletion Management',
  contact_list: 'Lista kontaktów',
  all_contacts: 'Wszystkie kontakty',
  merge_list: 'Scal listę kontaktów',
  add_contact: 'Dodaj kontakt',
  label: 'Etykieta',
  source: 'Źródło',
  destination: 'Cel',
  added: 'Dodano',
  last_activities: 'Ostatnie aktywności',
  last_ticket: 'Ostatnie tickety',
  street: 'Ulica',
  house_no: 'Nr domu',
  post_code: 'Kod pocztowy',
  town: 'Miasto',
  country: 'Kraj',
  phone: 'Telefon',
  mobile: 'Telefon komórkowy',
  company: 'Firma',
  fellow_travelers: 'Przyjaciele',
  pets: 'Zwierzęta',
  equipment_features: 'Cechy sprzętu',
  services: 'Usługi',
  service: 'Usługa',
  notes_external: 'Notatki zewnętrzne',
  notes_internal: 'Notatki wewnętrzne',
  add_now: 'Dodaj teraz',
  added_on: 'Dodano',
  edit_contact: 'Edytuj kontakt',
  event_history: 'Historia zdarzeń',
  channel: 'Kanał',
  date_time: 'Data/czas',
  contact_details: 'Szczegóły kontaktu',
  merge: 'Połącz',
  unmerge: 'Rozłącz',
  updated: 'Zaktualizowano',
  city: 'Miasto',
  select_channel: 'Wybierz kanał',
  merge_contact: 'Połącz kontakty',
  merge_contact_sidebar: 'Połącz kontakty',
  select_contact: 'Wybierz kontakt',
  logs: 'Logs',
  merged_date: 'Data połączenia',
  updated_date: 'Data aktualizacji',
  uploaded_by: 'Załadowany przez',
  file: 'Plik',
  image: 'Obraz',
  images: 'Obrazy',
  progress_status: 'Status postępu',
  upload_file: 'Załaduj plik',
  continue: 'Kontynuuj',
  view_contact: 'Zobacz kontakt',
  delete_contact: 'Usuń kontakt',
  merge_detail: 'Szczegóły połączenia',
  merge_preview: 'Podgląd połączenia',
  merge_contact_is_permanent:
    'Połączenie kontaktów jest trwałe. Nie będzie można usunąć kontaktów z połączonej listy.',
  janitor: 'Woźny',
  support: 'Wsparcie',
  deleted_customer: 'Usunięty klient',

  /*Contact Center*/
  call_for_user: 'Zaproszenie dla użytkowników',
  total_calls: 'Łączna liczba połączeń',
  aircall_user_name: 'Nazwa Użytkownika',
  incoming_call: 'Połączenie przychodzące',
  outgoing_call: 'Wychodzące połączenie',
  total_incoming_call: 'Całkowita liczba połączeń przychodzących',
  incoming_call_duration: 'Czas trwania połączenia przychodzącego',
  total_outgoing_call: 'Całkowita liczba połączeń wychodzących',
  outgoing_call_duration: 'Czas trwania połączenia wychodzącego',
  average: 'Przeciętny',
  total_duration: 'Całkowity czas trwania',
  internal_phone_number: 'Wewnętrzny numer telefonu',
  _customer_is_calling: 'Dzwoni klient {0}.',

  /*Feedback*/
  feedback_system: 'System opinii',
  access_feedback: 'Zarządzaj opiniami i pocztą',
  access_feedback_questions: 'Zarządzaj pytaniami i ustawieniami',
  access_feedback_resend: 'Dostęp Wyślij ponownie informację zwrotną',
  all_feedbacks: 'Wszystkie opinie',
  feedback_lists: 'Feedback-Listen',
  mails: 'Mails',
  all_mails: 'Wszystkie maile',
  mail_lists: 'Mail Lists',
  questions: 'Fragen',
  all_questions: 'Wszystkie pytania',
  question_lists: 'Frage-Listen',
  date: 'Date',
  closed_ended: 'Zamknięty Zakończony',
  open_ended: 'Otwarty',
  rating: 'Ocena',
  time_period: 'Okres czasu',
  feedback_detail: 'Informacja zwrotna Szczegóły',
  mark_as_done: 'Oznacz jako gotowe',
  publish_feedback: 'Opublikuj informacje zwrotne',
  publish_feedback_on_object_page:
    'Opublikuj informacje zwrotne na stronie obiektu',
  do_not_publish_feedback: 'Nie należy publikować informacji zwrotnych',
  do_not_publish_feedback_on_object_page:
    'Nie publikuj informacji zwrotnych na stronie obiektu',
  use_for_score_calculation: 'Wykorzystanie do obliczania wyniku',
  do_not_use_for_score_calculation: 'Nie należy używać do obliczania wyniku',
  contact_customer: 'Contact Customer',
  contact_house_owner: 'Contact House Owner',
  resend_mail: 'Resend Mail',
  new_question: 'New Question',
  edit_question: 'Edit Question',
  add_question: 'Add Question',
  delete_question: 'Delete Question',
  edit_feedback: 'Edytuj opinię',
  view_feedback: 'View Feedback',
  feedback_question: 'Pytanie zwrotne',
  feedback_question_deleted_successfully:
    'Pytanie w ramach opinii zostało pomyślnie usunięte',
  sent: 'Wysłano',
  received: 'Otrzymane',
  feedback_mail_sent_sucessfully: 'Wiadomość zwrotna wysłana pomyślnie',
  feedback_mail_not_sent: 'Wiadomość zwrotna nie została wysłana',
  how_many_days_after_departure_should_the_feedback_mail_be_sent:
    'Ile dni po wyjeździe należy wysłać wiadomość zwrotną?',
  select_days: 'Wybierz dni',
  days: 'Dni',
  feedback_received: 'Otrzymuj opinię',
  question: 'Pytanie',
  response: 'Odpowiedź',
  the_rating_is_out_of_10: 'The rating is out of 10',
  this_is_closed_ended_question: 'Ocena jest poza 10',
  this_is_open_ended_question: 'To jest pytanie otwarte',
  feedback_has_been_published_successfully:
    'Opinia została pomyślnie opublikowana',
  feedback_publish_error: 'Błąd publikacji opinii',
  published_feedback_on_object_page_successfully:
    'Pomyślnie opublikowano opinię na stronie obiektu',
  publish_feedback_on_object_page_error:
    'Opublikowana opinia na temat błędu strony obiektu',
  feedback_has_been_unpublished_successfully:
    'Opinia została pomyślnie wycofana',
  feedback_unpublish_error: 'Błąd cofnięcia publikacji opinii',
  feedback_has_been_marked_as_done_successfully:
    'Opinia została oznaczona jako pomyślnie wykonana',
  feedback_mark_as_done_error: 'Błąd oznaczania opinii jako wykonanej',
  How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole:
    'Jak oceniasz swoje wakacje w List on Sylt i usługi świadczone przez rodzinę Clausen jako całość?',
  publish_status: 'Status publikacji',
  published_on_website: 'Opublikowany na stronie internetowej',
  published_on_object_detail_page:
    'Opublikowany na stronie szczegółowej obiektu',
  score_calculation: 'Obliczanie wyników',
  feedback_on_object_page_has_been_unpublished_successfully:
    'Opinie na temat strony obiektu zostały pomyślnie usunięte',
  feedback_on_object_page_unpublish_error:
    'Informacje zwrotne na temat błędu nieopublikowania strony obiektu',
  score_calculation_used: 'Zastosowany sposób obliczania wyniku',
  score_calculation_use_error: 'Obliczanie wyniku Błąd użycia',
  score_calculation_unused: 'Obliczanie wyniku Nieużywane',
  score_calculation_unuse_error: 'Obliczanie wyniku Błąd niewykorzystania',
  object_publish: 'Obiekt opublikowany',
  website_publish: 'Strona opublikowana',
  not_publish: 'Nie opublikowano',
  invalid_data: 'Podane dane są nieprawidłowe',
  booking_invalid: 'Numer rezerwacji nie jest prawidłowy',
  service_invalid: 'Usługa nie jest poprawna',
  customer_invalid: 'Nazwa klienta jest prawidłowa',
  score_total: 'Wynik całkowity',
  answer_rate: 'Szybkość odpowiedzi',
  public_score_calculation: 'Publiczna kalkulacja wyników',
  real_score: 'Wynik rzeczywisty',
  public_score: 'Wynik publiczny',
  detail_reviews_general: 'Szczegółowe recenzje ogólne',
  detail_review_object_page: 'Strona szczegółowa przeglądu',
  reviews_general: 'Recenzje ogólne',
  reviews_object_page: 'Strona obiektu Reviews',
  score_general: 'Bewertungen Gesamt',
  score_objects: 'Bewertungen nach Objekten',
  no_mail: 'Nie E-mail',
  no_email: 'Nie E-mail',
  analysis: 'Analiza',
  average_rating: 'Średnia ocena.',
  percentage_of_closed_ended: 'Procentowy udział Tak / Nie.',
  percentage_of_open_ended:
    'Odsetek klientów piszących opinie / nie piszących opinii.',
  feedback_analysis: 'Analiza sprzężenia zwrotnego',
  delete_mail: 'Usuń pocztę',
  feedback_mail_deleted_successfully: 'Poczta zwrotna usunięta pomyślnie',
  feedback_analysis_detail: 'Szczegóły_analizy_opinii',
  feedback_response_detail: 'Szczegóły_odpowiedzi_zwrotnej',
  resend_feedback: 'Ponowne przesłanie informacji zwrotnej',
  resent: 'Resent',
  feedback_deleted_successfully: 'Informacja zwrotna usunięta pomyślnie',
  delete_feedback: 'Usuń informację zwrotną',
  publish: 'Publikuj',
  select_publish: 'Wybierz publikację',
  publish_is_required: 'Publikacja jest wymagana.',
  start_page: 'Strona startowa',

  /*Inventory*/
  inventory: 'Inwentaryzacja',
  inventories: 'Zapasy',
  all_inventory: 'Wszystkie zapasy',
  inventory_name: 'Nazwa inwentaryzacji',
  used_item: 'Używane przedmioty',
  quantity: 'Ilość',
  total_quantity: 'Całkowita ilość',
  from: 'Od',
  to: 'Do',
  add_item: 'Dodaj przedmiot',
  deselect_all: 'Odznacz wszystko',

  /*MMS*/
  merchandise_management_system: 'System Zarządzania Towarami',
  transfer_item: 'Transfer przedmiotu',
  all_transfer_item: 'Wszystkie pozycje do przeniesienia',
  all_transfer_logs: 'Wszystkie dzienniki transferowe',
  select_articles: 'Wybierz artykuły',
  search_article: 'Wyszukaj artykuł',
  stock_type: ' Typ magazynowania',
  units: 'Jednostki',
  unit: 'Jednostka',
  all_units: 'Wszystkie jednostki',
  unit_details: 'Szczegóły jednostki',
  select_source_warehouse: 'Wybierz magazyn źródłowy',
  rooms: 'Pokoje',
  room: 'Pokój',
  delete_room: 'Usuń pokój',
  add_room: 'Dodaj pokój',
  edit_room: 'Edytuj pokój',
  room_name: 'Nazwa pokoju',
  enter_room_name: 'Wprowadź nazwę pokoju',
  racks: 'Ramy',
  add_rack: 'Dodaj ramę',
  rack: 'Rama',
  delete_rack: 'Usuń ramę',
  shelf: 'Półka',
  add_shelf: 'Dodaj półkę',
  delete_shelf: 'Usuń półkę',
  shelves: 'Półki',
  select_destination_warehouse: 'Wybierz magazyn docelowy',
  warehouse: 'Magazyn',
  warehouse_name: 'Nazwa magazynu',
  warehouses: 'Magazyny',
  all_warehouses: 'Wszystkie magazyny',
  add_warehouse: 'Dodaj magazyn',
  edit_warehouse: 'Edytuj magazyn',
  delete_warehouse: 'Usuń magazyn',
  warehouse_details: 'Szczegóły magazynu',
  warehouse_information: 'Informacje o magazynie',
  warehouse_not_found: 'Magazyn nie znaleziony',
  shelf_delete_error: 'Nie można usunąć półki',
  rack_delete_error: 'Nie można usunąć ramy',
  room_delete_error: 'Nie można usunąć pokoju',
  articles: 'Artykuły',
  article: 'Artykuł',
  all_articles: 'Wszystkie artykuły',
  add_article: 'Dodaj artykuł',
  articles_names: 'Nazwy artykułów',
  article_name: 'Nazwa artykułu',
  upload_article_picture: 'Załaduj zdjęcie artykułu',
  article_image: 'Zdjęcie artykułu',
  type: 'Typ',
  types: 'Typy',
  all_types: 'Wszystkie rodzaje',
  type_details: 'Szczegóły typu',
  buying_price: 'Cena zakupu',
  selling_price: 'Cena sprzedaży',
  seller: 'Sprzedawca',
  seller_home_page_shop: '  Strona domowa sklepu sprzedawcy',
  minimum_stock: 'Minimalna ilość',
  maximum_stock: 'Maksymalna ilość',
  out_of_stock: 'Brak na stanie',
  minimum_stock_notification: 'Powiadomienie o minimalnej ilości',
  ean_number: 'Numer EAN',
  notes: 'Notatki',
  article_details: 'Szczegóły artykułu',
  objects: 'Obiekty',
  object_id: 'ID obiektu',
  object_name: 'Nazwa obiektu',
  booking: 'Rezerwacja',
  tickets: 'Bilety',
  inventory_load: 'Załaduj inwentaryzację',
  object_details: 'Szczegóły obiektu',
  ticket_open_inprogress_done: 'Otwarte/W trakcie/Zrobione',
  abbreviation: 'Skrót',
  street_number: 'Numer ulicy',
  is_active: '  Aktywny',
  type_name: 'Nazwa typu',
  add_type: 'Dodaj typ',
  unit_name: 'Nazwa jednostki',
  add_unit: 'Dodaj jednostkę',
  edit_unit: 'Edytuj jednostkę',
  transfer_log: ' Log transferu',
  transfer_logs: 'Dzienniki transferowe',
  my_transfer_log: 'Mój log transferu',
  my_transfer_logs: 'Moje dzienniki',
  all_my_logs: 'Wszystkie dzienniki',
  ticket_source: '  Źródło biletu',
  done_by: '  Wykonane przez',
  required: 'Wymagane',
  user_created: ' Użytkownik utworzył',
  user_creation_failed: 'Utworzenie użytkownika nie powiodło się',
  user_udpate_failed: 'Aktualizacja użytkownika nie powiodła się',
  password_update_failed: 'Aktualizacja hasła nie powiodła się',
  user_not_found: 'Użytkownik nie znaleziony',
  update: 'Aktualizuj',
  user_updated: 'Użytkownik zaktualizowany',
  user_deleted: 'Użytkownik usunięty',
  roles_not_found: 'Nie znaleziono roli',
  confirm_delete: 'Czy na pewno chcesz to usunąć?',
  edit_type: 'Edytuj typ',
  select: 'Wybierz',
  min_stock_notification_tooltip: 'Powiadomienie o minimalnej ilości',
  valid_data: 'Dane ważne',
  valid_only_number: 'Tylko liczby',
  min_stock_vaidation_msg: 'Minimalna ilość musi być większa od 0',
  max_stock_vaidation_msg: 'Maksymalna ilość musi być większa od minimalnej',
  phone_call: 'Telefon',
  collapse_sidebar: 'Zwiń pasek boczny',
  expand_sidebar: 'Rozwiń pasek boczny',
  article_type: 'Typ artykułu',
  article_unit: 'Jednostka artykułu',
  max_upload_size_5: 'Maksymalny rozmiar pliku 5MB',
  max_upload_size_10: 'Maksymalny rozmiar pliku 10MB',
  max_upload_size_15: 'Maksymalny rozmiar pliku 15MB',
  max_characters_exceeded: 'Przekroczony maksymalny limit znaków',
  selected_files: 'Wybrane pliki',
  drag_drop_files_msg:
    'Przeciągnij i upuść niektóre pliki tutaj lub kliknij, aby wybrać pliki. Prześlij pliki do {0} MB.',
  files: 'Pliki',
  delete_file: 'Usuń plik',
  only_1_file_is_allowed: 'Only 1 file is allowed.',
  access_user: 'Dostęp użytkownika',
  manage_role: 'Zarządzaj rolami',
  manage_user: 'Zarządzaj użytkownikami',
  access_external_companies: 'Dostęp do firm zewnętrznych',
  manage_external_companies: 'Edytuj firmy zewnętrzne',
  access_chat: 'Dostęp do czatu',
  access_closed_chat: 'Dostęp do zamkniętego czatu',
  access_archived_chat: 'Dostęp do archiwizowanego czatu',
  access_chat_metrics: 'Dostęp do wskaźników czatu',
  access_chat_assign: 'Dostęp do przypisania czatu',
  access_chat_mediasoup: 'Dostęp do mediasyou',
  access_ticket: 'Dostęp do biletów',
  access_mms: 'Dostęp do MMS',
  can_hold_item: 'Może przechowywać przedmiot',
  user_delete_error: 'Nie można usunąć użytkownika.',
  manage_article: 'Zarządzaj artykułami',
  stocktaking_mode: 'Tryb zbierania',
  stocktaking: '  Zbieranie',
  upload_new: ' Załaduj nowe',
  remove: 'Usuń',
  uploading: 'Przesyłanie',
  invalid_profile_upload: ' Nieprawidłowy plik profilu',
  file_must_be_an_image_file: '   Plik musi być plikiem graficznym',
  clear_profile_pic: 'Wyczyść zdjęcie profilowe',
  are_you_sure_you_cannot_undo_this_action:
    'Czy na pewno? Później nie można cofnąć tej akcji.',
  upload_label: 'Załaduj',
  remove_label: ' Usuń',
  invalid_file_size: 'Nieprawidłowy rozmiar pliku',
  file_size_info: 'Maksymalny rozmiar pliku 5MB',
  uploading_profile: '  Przesyłanie profilu',
  no_permission_msg: ' Nie masz uprawnień do wykonania tej operacji',
  transfer_date: '  Data transferu',
  transfer_source: '  Źródło transferu',
  transfer_by: '  Transferowany przez',
  transfer_quantity: '  Ilość transferu',
  category: ' Kategoria',
  select_category: '  Wybierz kategorię',
  house_number: ' Numer domu',
  object_image: ' Obraz obiektu',
  house: 'Domy',
  apartmant: 'Apartamenty',
  flat: 'Mieszkania',
  new: 'Nowy',
  fresh: 'Żywy',
  consumed: 'Zużyty',
  employee: 'Pracownik',
  transfer_item_success: 'Przedmiot transferowany pomyślnie',
  transfer_item_failed: 'Transferowanie przedmiotu nie powiodło się',
  none: 'Brak',
  reconciled: 'Rozrachunkowany',
  web_ui: 'Interfejs webowy',
  app: 'Aplikacja',
  web_app: 'Web/Aplikacja',
  select_article_and_quantity_first: ' Wybierz artykuł i ilość',
  min_stock_reached: 'Minimalna ilość osiągnięta',
  min_stock_reached_description:
    'Ten artykuł osiągnął minimalny zapas, który został ustawiony na',
  max_stock_reached: 'Maksymalna ilość osiągnięta',
  max_stock_reached_description:
    'Ten artykuł osiągnął maksymalna zapas, który został ustawiony na',
  stocktaking_mode_message: 'Tryb zbierania',
  my_inventory: 'Moje zapasy',
  all_my_inventories: 'Wszystkie zapasy',
  transfer: 'Przelew',
  max_min_stock: 'Maksymalna/Minimalna ilość',
  item: 'Przedmiot',
  inventory_detail: 'szczegóły dotyczące inwentarza',
  qr_code: 'Kod QR',
  warehouse_qr_code_confirm:
    'Chcesz wydrukować {noOfShelves} kod QR {warehouseName}?',

  //object-dahboard and cross-selling

  access_object_dashboard: 'Dostęp Dashboard',
  manage_cross_selling: 'Zarządzanie cross selling',
  enter_object_name_here: 'Wprowadź tutaj nazwę obiektu',

  // common
  lang: 'pl',
  result: 'Wynik',
  no_results_found: 'Nie znaleziono wyników',
  no_records_found: 'Nie znaleziono klucza',
  error: 'Błąd',
  no_options: 'Brak opcji',
  showing: 'Pokazuję',
  manually: 'Ręcznie',
  general: 'Ogólne',
  gotoDetails: 'Przejdź do szczegółów',
  out_of: 'Z',
  loading: 'Ładowanie...',
  show_rows: 'Pokaż wiersze',
  error_boundary_heading_text: 'Przepraszam, coś poszło nie tak.',
  error_boundary_paragraph_text:
    'Nasz zespół został powiadomiony i naprawimy to tak szybko, jak to możliwe.',
  reload_page: 'Odśwież stronę',
  take_me_home: 'Przenieś mnie na stronę główną',
  manual: 'Ręcznie',
  ['v-office']: 'V-Office',
  guest: 'Gość',
  owner: 'Właściciel',
  lead: ' Naczelny',
  supplier: 'Dostawca',
  test: 'Test',
  other: 'Inne',
  daily: 'Dziennie',
  weekly: ' Tygodniowo',
  bi_weekly: 'Co tydzień',
  monthly: ' Co miesiąc',
  semi_anually: 'Co pół roku',
  anually: 'Co rok',
  previous: 'Poprzedni',
  next: 'Następny',
  date_validation_msg: 'Proszę wprowadź poprawną datę',
  freeze_inventory: 'Zamrożenie zapasów',
  create_inventory_list: 'Utwórz listę zapasów',
  correct_inventory: 'Popraw zapasy',
  create_delta_list: 'Utwórz listę delta',
  unfreeze_inventory_end: 'Zwolnij zamrożenie zapasów',
  download_pdf: 'Pobierz PDF',
  download_excel: 'Pobierz Excel',
  download__delta_list_pdf: 'Pobierz PDF listy delta',
  unfreeze_inventory: 'Zwolnij zamrożenie zapasów',
  no_transferring: 'Brak transferu',
  download_as: '(Pobierz jako PDF i zapisz, a następnie wydrukuj.)',
  transfer_items: '(Przenieś pozycje z lub do korekty inwentaryzacji.)',
  what_change: '(Jakie zmiany zostały wprowadzone podczas Inwentaryzacji.)',
  step_1: 'Krok 1',
  step_2: 'Krok 2',
  step_3: 'Krok 3',
  step_4: 'Krok 4',
  step_5: 'Krok 5',
  actual_quantity: 'Aktualna ilość',
  delta: 'Delta',
  no_data_found: 'Brak danych',
  successful: 'Udany',

  /*MDM*/
  master_data_magnagement: '  Zarządzanie danymi',
  mdm: 'MDM',
  contacts: '   Kontakty',
  contact: 'Kontakt',
  upload_csv: 'Prześlij plik CSV',
  business: 'Biznes',
  language: 'Język',
  letter_salutation: 'Powitanie',
  letter_closure: 'Zamknięcie',
  salutation: 'Powitanie',
  birthdate: 'Data urodzenia',
  phone_number: ' Numer telefonu',
  business_phone_number: 'Numer telefonu biznesowego',
  mobile_number: 'Numer telefonu komórkowego',
  fax: 'Fax',
  im: '-im-',
  website: 'website',
  both: 'Oba',
  self: 'ja',

  region: 'Region',
  plant: 'Fabryka',
  categories: 'Kategorie',
  online: 'Online',
  deactivated: 'Dezaktywowany',
  sleeping_facilities: 'Ośrodki niepracy',
  longitude: 'Longitude',
  latitude: 'Latitude',
  bedrooms: 'Łóżka',
  bookings: 'Rezerwacje',
  booking_date: 'Data rezerwacji',
  invoices: 'Faktury',
  invoice: '  Faktura',
  number: 'Numer',
  until: 'Do',
  received_at: 'Otrzymane',
  changed_at: 'Zmienione',
  total: 'Suma',
  grand_total: 'Suma całkowita',
  marketing_channel: 'Kanał marketingowy',
  down_payment: 'Płatność zaliczkową',
  down_payment_until: 'Płatność zaliczkowa do',
  final_payment_until: 'Płatność końcowa do',
  ex_price: 'Cena brutto',
  total_occupancy: 'Całkowita zajętość',
  adult: 'Dorosły',
  children: 'Dzieci',
  babies: 'Dzieci',
  age_children: 'Wiek dzieci',
  country_customer: 'Kraj klienta',
  town_code_customer: 'Kod pocztowy klienta',
  country_objectives: 'Kraj celu',
  rent_statement_exit: '  Wyjście z wyrażenia wynajmu',
  invoice_date: 'Data faktury',
  net: 'Netto',
  due_date: 'Termin płatności',
  cancelled_on: 'Anulowano',
  calculation: 'Obliczenia',
  select_calculation: 'Wybierz obliczenia',
  provider: 'Dostawca',
  select_provider: 'Wybierz dostawcę',
  invoice_recepient: 'Odbiorca faktury',
  select_invoice_recepient: 'Wybierz odbiorcę faktury',
  warehouse_holds_inventories: 'Magazyny zapasów',
  uploads: 'Prześlij',
  all_uploads: 'Wszystkie Wysyłanie',
  all_bookings: 'Wszystkie rezerwacje',
  all_invoices: 'Wszystkie faktury',
  all_services: 'Wszystkie usługi',
  all_logs: 'Wszystkie dzienniki',
  drag_drop_csv_files_msg:
    ' Przeciągnij i upuść tutaj tylko pliki .csv lub kliknij, aby wybrać pliki .csv. Prześlij pliki do 10 MB',
  drag_drop_csv_15MB_files_msg:
    'Przeciągnij i upuść tutaj tylko pliki .csv lub kliknij, aby wybrać pliki .csv. Prześlij pliki do 10 MB',
  processing_data: 'Przetwarzanie danych',
  failed: 'Niepowodzenie',
  access_mdm: 'Dostęp do MDM',
  mdm_supporter: 'MDM wspierający',
  file_uploaded_successfully: 'Plik został przesłany pomyślnie',
  uploaded_on: 'Przesłano',
  by: 'przez',
  delete_article_unit_error:
    'Nie można usunąć typu jednostki artykułu. Zawiera wiele artykułów.',
  delete_type_error:
    'Nie można usunąć kategorii artykułów. Zawiera wiele artykułów.',
  transferred: 'Przeniesiono',
  internal_server_error: 'Błąd wewnętrzny serwera',
  cannot_upload_csv_in_worng_category:
    'Nie można prześcić pliku CSV w nieprawidłowej kategorii',
  upload_date: 'Data przesłania',
  crm_employee: 'Pracownik CRM',
  access_crm: 'Dostęp do CRM',
  merge_contacts: 'Połącz kontakty',
  delete_contacts: 'Usuń kontakty',

  access_oms: 'Dostęp do OMS',
  access_bms: 'Dostęp do BMS',
  access_sms: 'Dostęp do SMS',
  access_bread_service: 'Dostęp do serwisu dobrze',
  location: 'Lokalizacja',
  letter_completion: 'Letter Completion',
  homepage: 'strona główna',

  imports: 'Importy',
  all_imports: 'Wszystkie importy',
  import_type: 'Typ importu',
  last_id: 'Ostatnie Id',
  total_records_imported: 'Całkowita liczba zaimportowanych rekordów',
  imported_at: 'Importowane na',
  availabilities: 'Dostępność',

  /* OMS */
  object_management_system: 'System Zarządzania Obiektami',
  oms: 'OMS',
  object_list: 'Lista obiektów',
  status_changed: 'Status zmieniony',
  payement_active: 'Płatność aktywna',
  website_active: 'Strona internetowa aktywna',
  video_active: 'Wideo aktywne',
  seven_for_six: '7 za 6',
  this_discount_will_be_applied_for_season_c:
    'Zniżka ta będzie obowiązywać tylko w sezonie C',
  voffice_data_sync: 'Synchronizacja danych VOffice',
  features: 'Funkcje',
  picture: 'Obraz',
  text: 'Tekst',
  cannot_get_apartment_details: 'Nie można uzyskać szczegółów apartamentu',

  /**SMS Service */
  service_management_system: 'System Zarządzania Usługami',
  sms: 'SMS',
  add_service: 'Dodaj usługę',
  scheme_type: 'Typ schematu',
  service_created: 'Usługa utworzona',
  service_creation_failed: 'Nie udało się utworzyć usługi',
  service_udpate_failed: 'Nie udało się zaktualizować usługi',
  add_new_service: 'Dodaj nową usługę',
  edit_service: 'Edytuj usługę',
  service_updated: 'Usługa zaktualizowana',
  service_not_found: 'Usługa nie znaleziona',
  service_details: 'Szczegóły usługi',
  product_code: 'Kod produktu',
  v_office_service: 'Usługa VOffice',
  price_scheme_type: 'Typ schematu ceny',
  by_quantity: 'Na ilość',
  by_duration: 'Na czas',
  by_day: 'Na dzień',
  newspaper: 'Gazeta',
  one_time_price: 'Cena jednorazowa',
  by_request: 'Na żądanie',
  beach_chair: 'Krzesło na plaży',
  unit_price: 'Cena jednostkowa',
  price_a: 'Price A',
  price_b: 'Price B',
  view_service: 'Zobacz usługę',
  delete_service: ' Usuń usługę',
  service_deleted: 'Usługa usunięta',
  day: 'Dzień',
  price: 'Cena',
  duration: 'Czas trwania',
  priceschemebynone: 'Brak',
  priceschemebyday: 'Schemat ceny na dzień',
  priceschemeonetime: 'Schemat ceny jednorazowy',
  priceschemebyquantity: 'Schemat ceny na ilość',
  priceschemebyduration: 'Schemat ceny na czas',
  priceschemebeachchair: 'Schemat ceny na krzesło na plaży',
  priceschemebyrequest: 'Schemat ceny na żądanie',
  per_week: 'Na tydzień',
  sun: 'Niedziela',
  mon_fri: 'Poniedziałek - Piątek',
  additional_day: 'Dodatkowy dzień',
  annual_price: 'Cena roczna',
  duplicate_price_scheme: 'Powiel schemat ceny',

  /**SMS Service Group */
  service_group: 'Grupa usług',
  service_group_list: 'Lista grup usług',
  all_service_groups: 'Wszystkie grupy usług',
  add_service_group: 'Dodaj grupę usług',
  booking_type: 'Typ rezerwacji',
  booking_fee_calculation: 'Obliczanie opłat za rezerwację',
  booking_fee: 'Opłata rezerwacji',
  service_group_created: 'Grupa usług utworzona',
  service_group_creation_failed: 'Nie udało się utworzyć grupę usług',
  service_group_udpate_failed: 'Nie udało się zaktualizować grupę usług',
  add_new_service_group: 'Dodaj nową grupę usług',
  service_name: 'Nazwa usługi',
  frontend_color: 'Kolor frontend',
  price_per_night: 'Cena za noc',
  show_on_landing_page: 'Pokaż na stronie głównej',
  default_service: 'Domyślna usługa',
  early_deliverable: 'Dostawa wcześniejsza',
  early_deliverable_on_start: 'Dostawa wcześniejsza na początku',
  pay_to_vendor: '  Płatność dostawcy',
  dog_service: 'Usługa zwierząt',
  icon: 'Ikona',
  cover_image: 'Obraz zakładki',
  drag_icon_or_click_to_select_icon:
    'Przeciągnij ikonę lub kliknij, aby wybrać ikonę',
  drag_image_or_click_to_select_image:
    'Przeciągnij obraz lub kliknij, aby wybrać obraz',
  edit_service_group: 'Edytuj grupę usług',
  service_group_updated: 'Grupa usług zaktualizowana',
  service_group_not_found: 'Grupa usług nie znaleziona',
  service_group_details: 'Szczegóły grup usług',
  one_time: 'Jednorazowo',
  per_day: 'Na dzień',
  per_night: 'Na noc',
  view_service_group: 'Zobacz grupę usług',
  delete_service_group: 'Usuń grupę usług',
  service_group_deleted: 'Grupa usług usunięta',
  accept_only_png: 'Akceptuj tylko pliki PNG',
  enter_booking_fee_in_cents: 'Wprowadź opłatę za rezerwację w centach',
  enter_price_per_night_in_cents: 'wprowadź cenę w centach',
  enter_value_in_cents: 'Wprowadź wartość w centach',
  enter_value_in_euro: 'Wprowadź wartość w euro',
  light: 'Piekło',
  dark: 'Dunkel',
  price_calculation: 'Kalkulacja ceny',

  /**DMS Service */
  document_management_system: 'System zarządzania dokumentami',
  dms: 'DMS',
  general_documents: 'Dokumenty ogólne',
  document_objects: 'Obiekty dokumentów',
  document_customers: 'Odbiorcy dokumentów',
  document_owners: 'Właściciele dokumentów',
  document_users: 'Dokument użytkownika',
  upload_document: 'Prześlij dokument',
  all_documents: 'Wszystkie dokumenty',
  all_object_documents: 'Wszystkie dokumenty obiektów',
  all_user_documents: 'Wszystkie dokumenty użytkownika',
  all_customers: 'Wszyscy Klienci',
  all_owners: 'Wszyscy Właściciele',
  documents: 'dokumenty',
  document_name: 'Nazwa dokumentu',
  owner_name: 'Nazwa właściciela',
  availability: 'Dostępność',
  edit_general_document: 'Edytuj dokument ogólny',
  edit_document_object: 'Edytuj Prześlij dokument do obiektu',
  edit_document_owner: 'Edytuj Prześlij dokument do właściciela',
  edit_document_customer: 'Edytuj Prześlij dokument do klienta',
  upload_general_document: 'Prześlij dokument ogólny',
  upload_document_object: 'Prześlij dokument do obiektu',
  upload_document_owner: 'Prześlij dokument do właściciela',
  upload_document_customer: 'Prześlij dokument do klienta',
  to_objects: 'do Obiektów',
  no_of_documents: 'Liczba dokumentów',
  select_object: 'Wybierz obiekt',
  general_document_deleted: 'Dokument został usunięty.',
  delete_general_document: 'Usuń dokument',
  general_document_not_found: 'Nie znaleziono dokumentu',
  general_document_udpate_failed: 'Aktualizacja dokumentu nie powiodła się.',
  general_document_created: 'Dokument został utworzony',
  general_document_creation_failed: 'Tworzenie dokumentu nie powiodło się.',
  general_document_updated: 'Dokument został pomyślnie zaktualizowany.',
  users_document: 'Dokument użytkownika',
  upload_user_document: 'Prześlij dokument użytkownika',
  edit_user_document: 'Edytuj dokument użytkownika',
  customer_documents: 'Dokumenty klienta',
  owner_documents: 'Dokumenty właściciela',
  object_documents: 'Dokumenty dotyczące obiektów',
  import_file: 'import pliku',
  the_file_field_is_required: 'Pole pliku jest wymagane.',

  /**BMS Service */
  booking_management_system: 'System zarządzania rezerwacjami',
  bms: 'BMS',
  subtotal: ' Podstawa',
  vat19: 'VAT 19%',
  vat7: 'VAT 7%',
  payment_method: 'Metoda płatności',
  bank_transfer: 'Przelew bankowy',
  paid_amount: 'Kwota zapłacona',
  without_tax: 'Bez podatku',
  including: 'Włączając',
  callback: 'Wywołanie zwrotne',
  callbacks: 'Wywołania zwrotne',
  all_callbacks: 'Wszystkie wywołania zwrotne',
  callback_date: 'Data wywołania zwrotnego',
  callback_detail: 'Szczegóły wywołania zwrotnego',
  callback_deleted_successfully: 'Odwołanie zwrotne usunięte pomyślnie',
  no_callback_details_found: 'Nie znaleziono szczegółów wywołania zwrotnego',
  inquiry: 'Zapytanie ofertowe',
  mr: 'Pan',
  mrs: 'Pani',
  misc: 'Różne',
  check_in_out: 'Zameldowanie/Wymeldowanie',
  check_in: 'Zameldowanie',
  check_out: 'Wymeldowanie',
  check_in_warning_message:
    'W następnym kroku szczegóły rezerwacji są wyświetlane gościowi do sprawdzenia. Później można jeszcze dokonać korekt lub uzupełnień.',
  did_you_choose_right_guest: 'Czy wybrałeś właściwego gościa?',
  display_booking_details_confirmation_question:
    'Czy należy wyświetlić szczegóły rezerwacji następującego gościa?',
  mirror_booking_details: 'Szczegóły rezerwacji Mirror',
  check_out_confirmation_question: 'Chcesz sprawdzić gościa {0}?',
  check_in_confirmation_question: 'Czy chcesz teraz zameldować gościa {0}?',
  check_in_confirmation_message:
    'Prosimy o upewnienie się, że Gość zapoznał się z informacjami dotyczącymi rezerwacji.',
  check_booking_details: 'Sprawdź szczegóły rezerwacji',
  marketing_email: 'E-Mail-Marketing',
  marketing_email_settings: 'Ustawienia e-mail marketingu',
  how_many_days_before_arrival_should_the_marketing_mail_be_sent:
    'Ile dni przed przyjazdem gościa należy wysłać wiadomości marketingowe?',

  /**BS Service */
  bread_system: 'System pieczywa',
  bs: 'BS',
  all_billings: 'Wszystkie rozliczenia',
  all_daily_view: 'Wszystkie widoki dzienne',
  all_driving_order: 'Wszystkie zamówienia na jazdę',

  /**PMS Service */
  payment_management_system: 'System Zarządzania Płatnościami',
  pms: 'PMS',
  credit_card: 'Płatność kartą kredytową',
  all_credit_cards: 'Płatność kartą kredytową',
  paypal: 'Płatność Paypal',
  invoice_payment: 'Faktura Płatność',
  all_paypals: 'Płatność Paypal',
  credit_card_no: 'Numer Karty Kredytowej',
  amount: 'Kwota ',
  paypal_address: 'Adres Paypala',
  send: 'Wyślij',
  access_pms: 'Dostęp PMS',
  payment_links: 'Linki do płatności',
  send_on: 'Przekazać dalej',
  first_sent_on: 'Pierwszy wysłany na',
  last_sent_on: 'Ostatnio wysłany w dniu',
  paid_on: 'Zapłacones',
  all_payment_links: 'Wszystkie linki do płatności',
  create_payment_links: 'Utwórz link do płatności',
  send_payment_link: 'Wyślij link do płatności',
  payment_link_sending_failed: 'Nie udało się wysłać linku do płatności.',
  payment_link_send_successfully: 'Link do płatności został pomyślnie wysłany',
  identifier: 'Identyfikator',
  amount_is_required: 'Kwota jest wymagana',
  required_booking_number: 'Numer rezerwacji jest wymagany.',
  required_customer_name: 'Nazwa klienta jest wymagana.',
  paid: 'Płatne',
  resent_mail_success: 'E-mail wysłany ponownie z powodzeniem',
  resent_mail_error: 'Ponowne wysłanie wiadomości e-mail nie powiodło się',
  confirm_resend_email: 'Czy na pewno chcesz ponownie wysłać wiadomość?',
  service_reservation: 'Rezerwacja usług',
  apartment_reservation: 'Rezerwacja obiektu',
  ferry_booking: 'Rezerwacja promu',
  payment_link: 'Link do płatności',
  /**PNS Service */
  push_notification_system: 'System powiadomień Push',
  pns: 'PNS',
  notifications: 'Powiadomienia',
  all_notifications: 'Wszystkie Powiadomienia',
  push_notification: 'Powiadomienia Push',
  add_new_push_notification: 'Nowe Powiadomienia Push',
  link: 'Link',
  total_read: 'Ogółem/czytelnia',
  add_push_notification: 'Dodaj powiadomienie Push',
  notification_created: 'Powiadomienie Push zostało utworzone',
  notification_creation_failed: 'Tworzenie powiadomień Push nie powiodło się.',
  access_pns: 'Dostęp PNS',
  manage_pns: 'Wyślij powiadomienie push',
  image_link: 'Łącze obrazu',
  pns_notification_confirm:
    'Uwaga: Zostanie wysłane powiadomienie, kontynuować?',

  /**FAQ Service */
  frequently_ask_question: 'Często zadawane pytania',
  faq: 'FAQs',
  all_faq: 'Wszystkie FAQs',
  internal_faq: 'Wewnętrzne FAQs',
  all_internals: 'Wszystkie Intenale',
  success: 'Sukces',
  group: 'Grupa',
  all_groups: 'Wszystkie grupy',
  add_faq: 'Dodaj FAQ',
  edit_faq: 'Edytuj FAQ',
  delete_faq: 'Usuń FAQ',
  select_group: 'Wybierz grupę',
  alias: 'Alias',
  web: 'Web',
  internes_wissensdokument: 'Wewnętrzne wiedza',
  dateien_hochladen: 'Wgraj pliki',
  pdf_upload: 'Wgraj plik PDF',
  faq_deleted_successfully: 'FAQ usunięta',

  faq_group: 'FAQ grupę',
  add_group: 'Dodaj grupę',
  edit_group: 'Edytuj grupę',
  delete_group: 'Usuń grupę',
  faq_group_deleted_successfully: 'FAQ grupa usunięta',

  reservations: 'Rezerwacje',
  reservation_requests: 'Zgłoszenia rezerwacji',
  all_reservations: 'Wszystkie Rezerwacje',
  all_requests: 'Wszystkie zlecenia',
  ferry_bookings: 'Rezerwacje na łodzi',
  bread_bookings: 'Rezerwacje na śniadanie',
  bread_daily_view: 'Widok dzienny śniadania',
  bread_driving_order: 'Zamówienie jazdy śniadania',
  save_inorder_to_apply_changes: 'Zapisz w kolejności, aby zastosować zmiany',

  /** Bread Boking */
  bread_booking: 'Rezerwacja śniadania',
  add_bread_booking: 'Dodaj rezerwację śniadania',
  update_bread_booking: 'Aktualizuj rezerwację śniadania',
  pending: 'Oczekujące',
  confirmed: 'Potwierdzone',
  cancelled: '  Anulowane',
  booking_number: 'Numer rezerwacji',
  add_new_bread_booking: '  Dodaj nową rezerwację śniadania',
  edit_bread_booking: 'Edytuj rezerwację śniadania',
  bread_booking_updated: 'Rezerwacja śniadania zaktualizowana',
  bread_booking_not_found: 'Rezerwacja śniadania nie znaleziona',
  cannot_change_quantity_in_past: 'Nie można zmienić ilości w przeszłości',
  multiple_bookings_in_same_date_not_allowed:
    'Nie można zarezerwować wielu śniadaniach w tym samym dniu',
  bread_booking_details: 'Szczegóły rezerwacji śniadania',
  bread_booking_deleted: 'Rezerwacja śniadania usunięta',
  view_bread_booking: 'Zobacz rezerwację śniadania',
  delete_bread_booking: 'Usuń rezerwację śniadania',
  edit_order: 'Edytuj zamówienie',
  cancel_order: 'Anuluj zamówienie',
  property_name: 'Nazwa właściciela',
  customer_contact: ' Kontakt klienta',
  booking_period: 'Okres rezerwacji',
  timespan: 'Czas trwania',
  bread_booking_created: 'Rezerwacja śniadania utworzona',
  bread_booking_creation_failed: 'Nie udało się utworzyć rezerwacji śniadania',
  bread_booking_udpate_failed:
    'Nie udało się zaktualizować rezerwacji śniadania',
  include_departure_day: 'Uwzględnij dzień wyjazdu',
  price_pound: 'Cena zł (€)',
  booking_fee_pound: 'Opłata za rezerwację (€)',
  total_pound: 'Suma (€)',
  must_be_date_after_today_or_equal_to_today:
    'musi być datą późniejszą lub równą dzisiejszej.',

  /**Driving Order */
  driving_order: 'Zamówienie jazdy',
  import_driving_order: 'Importuj zamówienie jazdy',
  import_driving_success: 'Zamówienie jazdy zaimportowane pomyślnie',
  import_driving_failed: 'Nie udało się zaimportować zamówienia jazdy',

  /** Bread Daily View */
  bread_booking_billing: 'Chleb Rezerwacja Rozliczenie',
  bread_booking_billing_detils: 'Bread Booking Szczegóły rozliczeń',
  bread_booking_billing_edit: 'Chleb Rezerwacja Rozliczenie Edycja',
  bread_type: 'Rodzaj chleba',
  old_price: 'Stara Cena',
  new_price: 'Nowa cena',
  price_difference: 'Różnica w cenie',
  total_bread: 'Chleb ogółem',
  total_fee: 'Opłata całkowita',
  total_price_difference: 'Różnica w cenie ogółem',
  bread_quantity_difference: 'Różnica w ilości chleba',
  total_fee_difference: 'Difference in total charges',
  grand_total_difference: 'Różnica ogółem',
  update_bill: 'Aktualizuj rozliczenie',
  close_bill: 'Zamknij Bill',
  this_bill_will_be_closed_permanently:
    'To rozliczenie zostanie zamknięte na stałe',

  /** Bread Daily View */
  property: 'Właściciel',
  guest_number: 'Liczba gości',
  brotchen: 'Chleb',
  bread_daily_view_details: 'Szczegóły widoku dziennego śniadania',
  view_bread_daily_view: 'Zobacz widok dziennego śniadania',
  download: 'Pobierz',
  download_image: 'Download Image',
  download_driving_order: 'Pobierz zamówienie jazdy',

  /** Reservation Request */
  reservation_request_details: 'Szczegóły zgłoszenia rezerwacji',
  booking_detail: 'Szczegóły rezerwacji',
  reservation_detail: 'Szczegóły rezerwacji',
  adults: 'Dorosłych',
  customer_detail: 'Szczegóły klienta',
  sur_name: 'Nazwisko',
  reservation_details: 'Szczegóły rezerwacji',
  service_line: 'Linia usług',
  view_reservation_request_view: 'Zobacz zgłoszenie rezerwacji',
  dog: 'Pies',
  dog_breed: 'Rasa Pies',

  /** Ferry Booking */
  view_ferry_booking_view: 'Zobacz rezerwację na łodzi',
  ferry_booking_details: 'Szczegóły rezerwacji na łodzi',
  booking_amount: 'Kwota rezerwacji',
  trip_date: 'Data podróży',
  time: 'Czas',
  trip_voyage: 'Podróż',
  departure_harbor: ' Port wylotu',
  destination_harbor: ' Port docelowy',
  trip_return_date: ' data powrotu z wycieczki',
  vehicle_type: 'Typ pojazdu',
  licence_plate: 'Nr rejestracyjny',
  length: 'Długość',
  customer_email: ' E-mail klienta',
  trip_detail: 'Szczegóły podróży',
  vehicle_detail: 'Szczegóły pojazdu',
  cm: 'cm',
  ferry_booking_cancel_success: 'Rezerwacja na łodzi została anulowana',
  ferry_booking_cancel_failed: 'Nie udało się anulować rezerwacji na łodzi',

  data_synced: 'Data synced.',
  last_v_office_data_sync: 'Last v-office data sync',
  access_reservations: 'Rezerwacje',
  access_bookings: 'Rezerwacje',
  manage_bread_service: 'Edycja serwisu bułek’',
  access_bread_driving_order: 'Trasa do obsługi bułek',
  access_callbacks: 'Wywołania zwrotne',
  booking_terminals: 'Terminale rezerwacyjne',

  access_faq: 'Dostęp FAQ',
  manage_faq: 'Zarządzaj FAQ',
  access_faq_internal: 'Dostęp Wewnętrzne FAQ',
  access_faq_group: 'Grupa FAQ (Access FAQ)',
  manage_faq_group: 'Zarządzaj grupą FAQ',

  access_dms_general: 'Dostęp do dokumentu ogólnego',
  manage_dms_general: 'Zarządzaj dokumentami ogólnymi',
  access_dms_object: 'Obiekt Access Document',
  manage_dms_object: 'Zarządzaj obiektem dokumentu',
  access_dms_customer: 'Dostęp do dokumentu Klient',
  manage_dms_customer: 'Zarządzaj klientem dokumentu',
  access_dms_owner: 'Dostęp Właściciel dokumentu',
  manage_dms_owner: 'Zarządzaj właścicielem dokumentu',
  access_dms_user: 'Dostęp do dokumentu użytkownika',
  manage_dms_user: 'Zarządzaj dokumentem użytkownika',
  select_date: 'Wybierz Datę',
  select_date_time: 'Wybierz Datę/Czas',

  subscribe: 'Subskrybuj',
  unsubscribe: 'Wypisz się',
  /*Internet Status*/
  wifi: 'Połączenie z Internetem zostało przywrócone.',
  nowifi: 'Jesteś obecnie offline.',
  refresh: 'odświeżenie',

  /*Contact Center*/
  access_contact_center: 'Dostęp Contact Center',

  /*Newsletter*/
  newsletter: 'Newsletter',
  newsletter_contact: 'Newsletter',
  newsletters: 'Biuletyny informacyjne',
  draft: 'Draft',
  drafts: 'Drafts',
  group_of_recipients: 'Grupa odbiorców',
  all_newsletters: 'Wszystkie newslettery',
  new_newsletter: 'Nowy biuletyn',
  subject: 'Temat',
  all_draft: 'Wszystkie projekty',
  message: 'Message',
  attach_file: 'Attach File',
  attachments: 'Załączniki:',
  preview: 'Preview',
  save_as_draft: 'Zapisz jako szkic',
  compose_message: 'Compose Message',
  preview_message: 'Preview Newsletter',
  download_our_app_now: 'Pobierz naszą aplikację teraz ',
  contact_with_us: 'Skontaktuj się z nami',
  about_us: 'O nas',
  customer_care: 'Obsługa klienta',
  contact_us: 'Skontaktuj się z nami',
  privacy_policy: 'Polityka prywatności',
  select_recipient_group: 'Wybierz odbiorcę',
  edit_newsletter: 'Edytuj biuletyn',
  delete_newsletter: 'Usuń biuletyn',
  newsletter_deleted: 'Usunięto biuletyn.',
  newsletter_delete_error: 'Usunięcie biuletynu nie powiodło się.',
  newsletter_sent_sucessfully: 'Newsletter został wysłany pomyślnie.',
  newsletter_saved_to_draft_sucessfully:
    'Biuletyn został pomyślnie zapisany w wersji roboczej.',
  newsletter_updated_sucessfully: 'Newsletter został pomyślnie zaktualizowany.',
  hello: 'Witam',
  to_send: 'Do wysłania',
  save_and_send: 'Zapisz i wyślij',
  recipient_group_field_is_required: 'Pole grupy odbiorców jest wymagane.',
  subject_field_is_required: 'Pole tematu jest wymagane.',
  message_field_is_required: 'Pole wiadomości jest wymagane.',
  send_newsletter: 'Wyślij biuletyn',
  attention_notification_will_be_send_continue:
    'Uwaga! Powiadomienie zostanie wysłane dalej',
  privacy: 'Prywatność',
  imprint: 'Odcisk',
  text_search: 'Wyszukiwanie tekstu',

  /*required*/
  required_first_name: 'Wymagane jest podanie imienia',
  required_last_name: 'Wymagane jest podanie nazwiska',
  required_email: 'Wymagany jest adres e-mail',
  required_cellphone: 'Wymagany jest telefon komórkowy',
  required_role: 'Rola jest wymagana',
  required_address: 'Adres jest wymagany',
  required_repate_from: 'Wymagane jest powtórzenie od',
  required_repeate_until: 'Wymagane jest powtarzanie jednostki',
  required_interval: 'Wymagany jest powtarzalny interwał',
  required_label: 'Wymagana jest etykieta',
  required_type: 'Wymagany jest typ',
  required_article: 'Artykuł jest wymagany',
  required_destination: 'Wymagane jest podanie miejsca przeznaczenia',
  required_source: 'Źródło jest wymagane',
  required_quantity: 'Wymagana jest ilość',
  required_article_name: 'Wymagana jest nazwa artykułu',
  required_unit: 'Wymagana jest jednostka',
  required_type_name: 'Wymagana jest nazwa typu',
  required_unit_name: 'Wymagana jest nazwa jednostki',
  required_descriptions: 'Wymagane jest podanie opisu',
  required_document_name: 'Wymagana jest nazwa dokumentu',
  required_Object: 'Wymagany jest obiekt',
  required_upload: 'Wymagane jest przesłanie pliku',
  required_service_name: 'Wymagana jest nazwa usługi',
  required_voffice_service: 'Wymagana jest usługa VOffice',
  required_service_group: 'Wymagana jest grupa usług',
  required_cover_image: 'Wymagane jest zdjęcie okładki',
  required_booking_type: 'Wymagany jest typ opłaty za rezerwację.',
  required_booking_fee: 'Wymagana jest opłata rezerwacyjna.',
  required_price_per_night: 'Wymagana jest cena za noc.',
  required_icon: 'Wymagane jest przesłanie ikony',
  required_group_name: 'Wymagana jest nazwa grupy',
  required_status: 'Wymagany jest status',
  required_group_recipients: 'Wymagana jest grupa odbiorców',
  required_subject: 'Wymagany jest temat',
  required_message: 'Wymagana jest wiadomość',
  required_room: 'Wymagany jest pokój.',
  required_rack: 'Wymagany jest stojak.',
  required_shelf: 'Wymagana jest półka.',
  file_size_is_too_large: 'Rozmiar pliku jest zbyt duży.',
  required_warehouse_name: 'Wymagana jest nazwa magazynu.',
  required_customer: 'Klient jest wymagany',
  required_owner: 'Właściciel jest wymagany.',
  required_user: 'Użytkownik jest wymagany.',
  required_front_color: 'Wymagany jest kolor frontu',
  only_one_file_is_allowed: 'Dozwolony jest tylko 1 plik',
  please_fill_in_all_mandatory_fields:
    'Proszę wypełnić wszystkie obowiązkowe pola.',

  // Message
  email_taken: 'Wiadomość e-mail została już odebrana',
  access_newsletter: 'Dostęp do biuletynu i wersji roboczych',
  manage_newsletter: 'zarządzać newsletterami',

  /*Key*/
  key: 'Klucz',
  keyscan: 'Kluczowy skan',
  access_keyscan_keylist: 'Lista kluczy dostępu',
  access_keyscan_keylog: 'Dziennik kluczy dostępu',
  key_management_system: 'System zarządzania kluczami',
  key_list: 'Lista kluczy',
  key_logs: 'Dzienniki kluczy',
  key_management: 'Zarządzanie kluczami',
  all_key_logs: 'Wszystkie dzienniki kluczy',
  search_customer: 'Wyszukaj klienta / użytkownika',
  key_id: 'Id klucza',
  scan_key: 'Skanuj klucz',
  key_handling: 'Obsługa kluczy',
  key_information: 'Informacje o kluczu',
  key_current_status: 'Aktualny status klucza',
  new_status: 'Nowy status',
  rfid_message: 'RFID Scan Successfully',
  rescan_key: 'Przeskanuj klucz ponownie',
  key_new_status: 'Nowy status klucza',
  place_rfid: 'Umieść klucz na czytniku RFID. ',
  all_key_list: 'Lista wszystkich kluczy',
  add_key: 'Dodaj klucz',
  registered_date: 'Data zarejestrowania',
  registered_by: 'Registered By',
  edit_key: 'Klucz do edycji',
  delete_key: 'Klucz usunięcia',
  view_key: 'Klucz widoku',
  given_out: 'Rozdany',
  in_stock: 'W magazynie',
  lost: 'Zagubiony',
  key_history: 'Historia klucza',
  key_created_successfully: 'Klucz utworzony pomyślnie',
  key_created_error: 'Błąd tworzenia klucza',
  key_updated_successfully: 'Klucz Zaktualizowano pomyślnie',
  key_updated_error: 'Błąd aktualizacji klucza',
  key_deleted_successfully: 'Klucz usunięty pomyślnie',
  key_deleted_error: 'Błąd usunięcia klucza',
  rescan: 'Rescan',
  rfid_taken_error: 'Klucz RFID już zajęty',
  scanner_title_message: 'Proszę umieścić klucz na skanerze RFID',
  scanner_message: 'Klucz umieszczony poprawnie?',
  key_no_registered: 'Klucz nie został jeszcze zarejestrowany.',
  print_qr: 'Wydrukuj kod QR',
  key_removed_successfully: 'Klucz został pomyślnie usunięty',
  key_scanned_successfully: 'Klucz zeskanowany pomyślnie',
  invalid_object_key: 'Nieprawidłowy klucz obiektu',

  /*Aerial Map */
  aerial_map: 'Mapa lotnicza',
  access_aerial_map: 'Dostęp do mapy lotniczej',
  three_sixty_degree: '360°',
  standard: 'Standard',
  map: 'Mapa',

  /*Language*/
  description_german: 'Opis Niemiec',
  description_english: 'Opis Język angielski',
  description_polish: 'Opis Polski',
  description_danish: 'Opis Danii',
  description_chinese: 'Opis Chiński',
  de: 'Niemiecki',
  en: 'Angielski',
  pl: 'Polski',
  da: 'Duński',
  zh: 'Chiński',
  no_of_print: 'Liczba druków',
  article_inactive: 'Artykuł nie jest aktywny',
  second_screen_welcome_text: 'Zapraszamy do Listy na Sylcie',
  open_second_screen: 'Otwórz drugi ekran',
  show_welcome_screen: 'Pokaż ekran powitalny',
  welcome: 'Witamy',
  booked_service: 'Usługi zarezerwowane',
  people: 'Ludzie',
  three_sixty_active: '360° Aktywny',
  can_only_be_enabled_apartment_with_VR:
    'Można włączyć tylko dla obiektu z trasą VR.',
  can_only_be_enabled_object_with_video_url:
    'Może być włączone tylko dla obiektu z adresem video.',
  other_see_description: 'Inne (Patrz Opis)',
  app_vermittl_fam_clausen: 'App.Vermittl.Fam.Clausen',
  select_time: 'Wybierz czas',
  close_all_active_chats: 'Zamknij wszystkie aktywne czaty',
  confirm_close_all_active_chats:
    'Czy na pewno chcesz zamknąć wszystkie aktywne czaty?',
  successfully_reset_all_active_chats:
    'Udało się zresetować wszystkie aktywne czaty.',
  responsibility: 'Odpowiedzialność',
  person_in_charge: 'Osoba odpowiedzialna',
  substitute_person_in_charge: 'Zastępca odpowiedzialny',
  successfully_updated_responsible_person:
    'Pomyślnie zaktualizowana osoba odpowiedzialna.',
  responsible_person_cannot_be_same:
    'Osoba odpowiedzialna i zastępca osoby odpowiedzialnej nie mogą być identyczne',
  select_responsible_person: 'Wybór osoby odpowiedzialnej',
  voffice_contact_detail_page: 'Strona szczegółów kontaktu VOffice',
  repairs: 'Naprawy',
  owner_allowed_repair: 'Właściciel zezwolił na naprawy?',
  inform_owner: 'Poinformować właściciela?',
  how_expensive_repairs_can_be_commissioned_without_consulting_with_the_owner:
    'Jak kosztowne naprawy można zlecić bez konsultacji z właścicielem?',
  add_note: 'Dodaj uwagę',
  write_note_here: 'Zapisz notatki tutaj',
  characters: 'znaki',
  maximum_200_character: 'Maksymalnie 200 znaków',
  latest_update: 'Najnowsza aktualizacja',
  updated_by: 'Zaktualizowane przez',
  successfully_added_note: 'Pomyślnie dodano notatkę',
  successfully_deleted_note: 'Pomyślnie usunięto notatkę',
  successfully_edited_note: 'Pomyślnie edytowano notatkę',
  edit_note: 'Uwaga do edycji',
  are_you_sure: 'Czy na pewno? ',
  do_you_really_want_to_delete_this_note_this_cannot_be_undone:
    'Czy naprawdę chcesz usunąć tę notatkę? Nie można tego cofnąć',
  in_euro: 'W Euro',
  maximum_notes_range_reached: 'Osiągnięty maksymalny zakres notatek ',
  object_dashboard: 'Object Dashboard',
  cross_selling: 'Sprzedaż krzyżowa',
  all_cross_selling: 'Cała sprzedaż krzyżowa',
  add_cross_selling: 'Dodaj nowy Sprzedaż krzyżowa',
  edit_cross_selling: 'Edycja sprzedaży krzyżowej',
  delete_cross_selling: 'Usuń sprzedaż krzyżową',
  cross_selling_not_found: 'Cross Selling nie został znaleziony.',
  successfully_added_cross_selling: 'Pomyślne dodanie sprzedaży krzyżowej',
  successfully_deleted_cross_selling: 'Pomyślnie usunięto sprzedaż krzyżową.',
  successfully_edited_cross_selling: 'Udana edycja sprzedaży krzyżowej',
  select_language_description: 'Wybierz opis języka',
  saved_email: 'Zapisana wiadomość e-mail',
  infos: 'Infos',
  other_objects: 'Inne przedmioty',
  successfully_updated_marketing_email_days:
    'Pomyślnie zaktualizowano marketingowe dni e-mail.',
  email_date: 'Data e-mail',
  planned: 'Planowany',
  classification: 'Klasyfikacja',
  object_classification: 'Klasyfikacja obiektów',
  select_tier: 'Wybierz poziom',
  tier: 'Poziom',
  failed_to_update_object_tier: 'Nie udało się zaktualizować warstwy obiektów.',
  successfully_updated_object_tier:
    'Pomyślnie zaktualizowano warstwę obiektów.',
  contract_signing_required: 'Wymagane podpisanie umowy',
  contract_signing_message:
    'Wygląda na to, że umowa nie została jeszcze podpisana. Przejdź do podpisania umowy, aby kontynuować',
  signed: 'Podpisano',
  contract_signed_successfully: 'Umowa podpisana pomyślnie',
  contract_signed_failed: 'Podpisana umowa nie powiodła się',
  //Membership Level
  membership: 'członkostwo',
  membership_level: 'poziom członkostwa',
  membership_list: 'Lista członków',
  list: 'Lista',
  number_of_booking_in_level: ' Liczba rezerwacji na poziomie',
  update_number_of_booking_in_level: '{0}  liczba rezerwacji na poziomie',

  published: 'Opublikowany',
  internal: 'Internal',
  add_new_member_level: 'Dodaj nowy poziom członkowski',
  name_membership_level: 'Nazwa Poziom członkostwa',
  brief_description: 'Krótki opis',
  edit_member_level: 'Edytuj poziom członkowski',
  required_membership_level: 'Wymagany poziom członkostwa',
  detail_member_level: 'Szczegóły poziomu członkostwa',
  booking_discount: '% zniżki na: Booking Fee',
  final_cleaning_discount: '% zniżki na: Sprzątanie końcowe',
  laundry_discount: '% zniżki na: Opłata za pranie',
  website_condition_discount:
    'Zniżka na rezerwację (w %) noclegu (bez dodatkowych usług) 10 Specjalne warunki na stronie internetowej',
  membership_delete: 'Poziom członkostwa usunięty',
  membership_delete_error: 'Błąd podczas usuwania poziomu członkostwa',
  message_update_membership_value: 'Czy na pewno chcesz {0}?',
  special_conditions_on_the_website:
    'Specjalne warunki na stronie internetowej:',
  un_publish: 'nie publikować',
  mark_as_internal: 'mark as internal',
  remove_from_internal: 'usuń z wewnętrznego',

  //Third Party Partners
  access_third_party_partner: 'Access Third Party Partner',
  manage_third_party_partner: 'Zarządzaj partnerem zewnętrznym',
  third_party_partner: 'Partner zewnętrzny',
  add_new_third_party_partner: 'Dodaj nowego partnera zewnętrznego',
  edit_third_party_partner: 'Edytuj partnera zewnętrznego',
  name_third_party_partner: 'Nazwa Partnera będącego osobą trzecią',
  required_third_party_partner: 'Wymagany partner zewnętrzny',
  detail_third_party_partner: 'Szczegółowy partner strony trzeciej',
  third_party_partner_delete: 'Partner strony trzeciej usunięty',
  third_party_partner_error: 'Błąd podczas usuwania partnera strony trzeciej',

  //Extra Goodies
  extra_goodies: 'Dodatkowe gadżety',
  name_extra_goodies: 'Nazwa dodatkowych gadżetów',
  required_extra_goodies: 'Wymagane dodatkowe gadżety',
  extra_goodies_delete: 'Dodatkowe gadżety usunięte',
  extra_goodies_error: 'Błąd podczas usuwania dodatkowych gadżetów',
  add_extra_goodies: 'Nowy dodatkowy gadżet',
  extra_goodies_delete_msg:
    'Czy naprawdę chcesz trwale usunąć gadżet {0} na stałe',

  message_active_inactive: 'Czy na pewno chcesz ustawić status na {0}?',

  //Restaurant Management
  restaurant: 'Restauracja',
  restaurant_list: 'Lista restauracji',
  reservation_count: 'Reservation Count',
  add_new_restaurant: 'Add New Restaurant',
  edit_restaurant: 'Edytuj restaurację',
  is_top_pick: 'Top Pick',
  required_restaurant_name: 'wymagana nazwa restauracji',
  short_description: 'krótki opis',
  logo: 'logo',
  zip_code: 'Kod pocztowy ',
  restaurant_delete: 'Restauracja usunięta pomyślnie',
  restaurant_error: 'Błąd podczas usuwania restauracji',
  restaurant_detail: 'Szczegóły restauracji',
  menu: 'Menu',
  opening_time: 'Czas otwarcia',
  timing: 'Timing',
  view_menu: 'Wyświetl menu',
  view_website: 'Wyświetl stronę internetową',
  required_street: 'Ulica jest wymagana',
  required_house_number: 'Numer domu jest wymagany',
  required_zip_code: 'Kod pocztowy jest wymagany',
  required_location: 'Lokalizacja jest wymagana',

  message_add_remove_from_top_pick:
    'Czy na pewno chcesz wybrać {0} restaurację z pierwszej listy?',

  restaurant_reservation: 'Rezerwacja restauracji',
  restaurant_reservation_list: 'Restaurant Reservation List',
  booking_time: 'Czas rezerwacji',
  restaurant_name: 'Nazwa restauracji',
  restaurant_reservation_detail: 'Szczegóły rezerwacji restauracji',
  no_of_people: 'Liczba osób',
  alternative_booking_date: 'Alternatywna data rezerwacji',
  alternative_booking_time: 'Alternatywny czas rezerwacji',
  additional_request: 'Dodatkowe żądanie',

  sylt_go: 'Sylt Go',

  //Meer Sylt
  meer_sylt: 'Meer Sylt Płytki',
  add_meer_sylt: 'Dodaj Meer Sylt Płytki',
  is_load_on_iframe: 'załadowany w ramce iframe',
  edit_meer_sylt: 'Edytuj Meer Sylt Płytki',
  delete_meer_sylt: 'Usunąć Meer Sylt Płytki',
  view_meer_sylt: 'Zobacz Meer Sylt Płytki',
  meer_sylt_deleted_successfully: 'Meer Sylt Płytki usunięta pomyślnie',
  meer_sylt_details: 'Szczegóły Meer Sylt Płytki',
  visit_link: 'Odwiedź łącze',
  valid_link: 'Prosimy o podanie prawidłowego linku.',
  message_update_with_value: 'Czy na pewno {0}?',
  activate_message: ' chcę aktywować meer sylt płytki ',
  deactivate_message: 'chce dezaktywować meer sylt płytki',
  load_within_iframe_message: 'chcę pokazać to w ramce iframe',
  website_link_message: 'nie chcę wyświetlać tego w ramce iframe',
  link_is_required: 'wymagany jest link',
  meer_sylt_tiles: 'Meer Sylt Płytki',
  meer_sylt_updated_successfully: 'Pomyślna aktualizacja płytek Meer Sylt',
  meer_sylt_created_successfully: 'Płytki Meer Sylt utworzone pomyślnie',

  failed_to_update: 'Nie udało się zaktualizować szczegółów obrazu.',
  successfully_updated_image_details:
    'Pomyślnie zaktualizowano szczegóły obrazu',
  update_image_detail: 'Zaktualizowano szczegóły obrazu',
  fileName: 'Filename',
  altTag: 'Alt tag',
  imageCaption: 'Podpis obrazu',
  filename_already_exists: 'Nazwa pliku już istnieje.',

  add_images: 'Dodaj obrazy',
  successfully_added_images: 'Pomyślnie przesłano obrazy.',
  failed_to_upload_images: 'Nie udało się przesłać obrazów.',
  successfully_deleted_image: 'Pomyślnie usunięto obraz',
  delete_image: 'Usuń obraz',
  manage_restaurant: 'Zarządzanie restauracją',
  slug: 'Ślimak',
  possible: 'Możliwe',
};

export default pl;
