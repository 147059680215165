import {
  EdelTier,
  LuxusTier,
  PureEdelTier,
  PureLuxusTier,
  RealTier,
} from 'assets/images';
import { strings } from 'config/localization';
import {
  ImportTypesArray,
  MDM_LOGS_IMPORT_TYPES_ARRAY,
  PNSOptionsArray,
  PRICE_CALCULATION_OPTION,
} from 'constants/schema';
import { bookingStatusArray } from './schemas/reservationBooking';

// API urls
export const TICKET_CUSTOMER_SEARCH_API = `/ticket/customers`;
export const OMS_OWNER_SEARCH_API = `/rms/owner`;
export const DMS_APARTMENT_SEARCH_API = `/dms/apartment`;
export const DMS_OWNER_SEARCH_API = `/dms/owner`;
export const DMS_CUSTOMER_SEARCH_API = `/dms/customer`;
export const DMS_USER_SEARCH_API = `/dms/user`;
export const EXTERNAL_COMPANY_SEARCH_API = `/ticket/external-company`;
export const ARTICLE_SEARCH_API = '/mms/articles';
export const SERVICE_GROUP_SEARCH_API = `rms/service-groups`;
export const V_SERVICE_SEARCH_API = `rms/v-services`;
export const KEY_APARTMENT_SEARCH_API = `keyscan/apartment`;
export const KEY_USER_SEARCH_API = `keyscan/user`;

// CSV Upload APIs
export const SERVICES_CSV_UPLOAD_API = `/mdm/services/import-csv`;
export const BOOKINGS_CSV_UPLOAD_API = `/mdm/bookings/import-csv`;
export const CONTACTS_CSV_UPLOAD_API = `/mdm/contacts/import-csv`;
export const INVOICES_CSV_UPLOAD_API = `mdm/invoices/import-csv`;
export const NEWSLETTERS_CSV_UPLOAD_API = `/mdm/contacts/subscriber/import-csv`;
export const OBJECTS_CSV_UPLOAD_API = '/mdm/apartments/import-csv';

//MDM
export const customer_search_url = `/mdm/contacts`;
export const apartment_search_url = `/mdm/apartments`;

// Auth
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expires_in';
export const EMAIL_ALREADY_TAKEN_MESSAGE = strings.email_taken;

// Pagination
export const DEFAULT_PAGE_SIZE = 100;
export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [50, 100, 200, 500];
export const MDM_SEARCH_QUERY_LIMIT = 50;

// Timers
export const DEFAULT_REFETCH_TIME = 90000; // 90 second ticket board
export const AIRCALL_MODAL_CLOSEABLE_TIME = 2000; // modal is closeable only after 2 second

// Accepted Files
export const TICKET_ACCEPT_FILE_TYPE =
  'image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';
export const DMS_ACCEPT_FILE_TYPE = 'application/pdf';
export const NEWSLETTER_ACCEPT_FILE_TYPE =
  'image/*,.doc,.docx,application/msword,.pdf';

// Date Picker
export const REACT_DATE_PICKER_FORMAT = 'yyyy-MM-dd';
export const REACT_DATE_PICKER_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const REACT_DATE_PICKER_FORMAT_YYYY_MM_DD_TIME = 'yyyy-MM-dd   h:mm aa';
export const LISTINFO_SERVICE_CENTER_TITLE = 'Listinfo Service Center';

export const ROLE_ADMIN = 'admin';

// Recursive interval for ticket
export const RECURSIVE_INTERVAL = [
  'Daily',
  'Weekly',
  'Bi-Weekly',
  'Monthly',
  'Semi-Anually',
  'Anually',
];

// XLSX CODEPAGE for character encoding
export const ENCODING_CODEPAGE = 65001;

//CSV FILE HEADERS
export const BOOKING_HEADER = [
  'Id',
  'Nummer',
  'Objekt',
  'Von',
  'Bis',
  'Kunde',
  'Status',
  'Reserviert am',
  'Geändert am',
  'Gesamt',
  'Offen',
  'Vertriebskanal',
  'Anzahlung',
  'Anzahlung bis',
  'Restzahlung bis',
  'EK-Preis',
  'Gesamt Belegung',
  'Erw.',
  'Kinder',
  'Babys',
  'Alter d. Kinder',
  'Land Kunde',
  'PLZ Kunde',
  'Eigentümer',
  'Lang Objekt',
  'Region',
  'Mietabrechnung existiert',
];

export const INVOICE_HEADER = [
  'Id',
  'Nummer',
  'Kunde',
  'Rechnungsdatum',
  'Objekt',
  'Netto',
  'Gesamt',
  'Offen',
  'Fälligkeitsdatum',
  'Storniert am',
];

export const CONTACT_HEADER = [
  'Id',
  'Nummer',
  'Nachname',
  'Vorname',
  'Straße',
  'Plz',
  'Ort',
  'Firma',
  'Hausnr.',
  'Land',
  'Sprache',
  'Briefanrede',
  'Briefabschluss',
  'Anrede',
  'Titel',
  'Geburtsdatum',
  'Eigentümer',
  'Telefon',
  'Telefon geschäftlich',
  'Mobil',
  'Fax',
  'Email',
  '-im-',
  'Homepage',
];

export const APARTMENT_HEADER = [
  'Id',
  'Kürzel',
  'Anlage',
  'Eigentümer',
  'Kategorie',
  'Region',
  'Live',
  'Online',
  'Deaktiviert',
  'Schlafmöglichkeiten',
  'Längengrad',
  'Breitengrad',
  'Typ',
  'Schlafzimmer',
  'Straße',
  'Hausnr.',
  'Plz',
  'Ort',
  'Land',
];

export const SERVICE_HEADER = [
  'Id',
  'Name',
  'Berechnung',
  'Typ',
  'Anbieter',
  'Rechnungsempfänger',
  'category',
];

export const NEWSLETTER_HEADER = [
  'Id',
  'Nummer',
  'Nachname',
  'Vorname',
  'Straße',
  'Plz',
  'Ort',
  'Firma',
  'Hausnr.',
  'Land',
  'Sprache',
  'Briefanrede',
  'Briefabschluss',
  'Anrede',
  'Titel',
  'Geburtsdatum',
  'Eigentümer',
  'Telefon',
  'Telefon geschäftlich',
  'Mobil',
  'Fax',
  'Email',
  '-im-',
  'Homepage',
];

type MomentDurationTypes = 'days' | 'weeks' | 'months' | 'years';
export const RECURSIVE_INTERVAL_MAP_TO_DURATION_AND_QUANTITY: {
  [name: string]: {
    duration: MomentDurationTypes;
    quantity: number;
    durationInDays: number;
  };
} = {
  Daily: {
    duration: 'days',
    quantity: 1,
    durationInDays: 1,
  },
  Weekly: {
    duration: 'weeks',
    quantity: 1,
    durationInDays: 7,
  },
  'Bi-Weekly': {
    duration: 'weeks',
    quantity: 2,
    durationInDays: 15,
  },
  Monthly: {
    duration: 'months',
    quantity: 1,
    durationInDays: 30,
  },
  'Semi-Anually': {
    duration: 'years',
    quantity: 1 / 2,
    durationInDays: 183,
  },
  Anually: {
    duration: 'years',
    quantity: 1,
    durationInDays: 365,
  },
};

// Themes
export const THEME_USER = 'theme-default';
export const THEME_CHAT = 'theme-green';
export const THEME_TICKET = 'theme-purple';
export const THEME_CRM = 'theme-red';
export const THEME_CONTACT_CENTER = 'theme-cyan';
export const THEME_FEEDBACK = 'theme-teal';
export const THEME_MMS = 'theme-orange';
export const THEME_MDM = 'theme-malibu';
export const THEME_DMS = 'theme-blue2';
export const THEME_OMS = 'theme-purple2';
export const THEME_SMS = 'theme-brown';
export const THEME_BMS = 'theme-darkbrown';
export const THEME_PMS = 'theme-blue3';
export const THEME_PNS = 'theme-pink2';
export const THEME_FAQ = 'theme-green2';
export const THEME_DASHBOARD = 'theme-pink';
export const THEME_BS = 'theme-burlywood';
export const THEME_NEWS = 'theme-yellow';
export const THEME_KEY = 'theme-sunrise';
export const THEME_AERIAL_MAP = 'theme-greygreen';
export const THEME_OBJECT_DASHBOARD = 'theme-purple3';
export const THEME_THIRD_PARTY_PARTNER = 'theme-partner';
export const THEME_RESTAURANT = 'theme-pink3';

// Transfer Item source and destination types
export const FRESH = 'Fresh';
export const SHELF = 'Shelf';
export const APARTMENT = 'Apartment';
export const USER = 'User';
export const CONSUMED = 'Consumed';

export const STOCK_ACTIVITIES_ENTITIES = {
  STOCK_ACTIVITY_ACTIONS: 'stock_activity_actions', // id: 1 -> Fresh, id: 2 -> Consumed
  WAREHOUSE_SHELVES: 'warehouse_shelves',
  APARTMENTS: 'apartments',
  USERS: 'users',
};

export const STOCK_ACTIVITIES_TRANSFER_SOURCE = {
  TICKET: 'Ticket',
  RECONCILED: 'Reconciled',
  WEB_UI: 'Web-UI',
  APP: 'App',
};

export const BOOKING_FEE_TYPE_OPTIONS = [
  {
    value: 'One Time',
    text: strings.one_time,
  },
  {
    value: 'Per Day',
    text: strings.per_day,
  },
];

export const PRICE_CALCULATION_OPTIONS: PRICE_CALCULATION_OPTION[] = [
  {
    value: 0,
    text: 'per_day',
  },
  {
    value: 1,
    text: 'per_night',
  },
];

export const SERVICE_SCHEME_TYPE_OPTIONS = [
  {
    value: 'PriceSchemeByNone',
    text: 'none',
  },
  {
    value: 'PriceSchemeByQuantity',
    text: 'by_quantity',
  },
  {
    value: 'PriceSchemeByDuration',
    text: 'by_duration',
  },
  {
    value: 'PriceSchemeByDay',
    text: 'by_day',
  },
  {
    value: 'PriceSchemeNewspaper',
    text: 'newspaper',
  },
  {
    value: 'PriceSchemeOneTime',
    text: 'one_time',
  },
  {
    value: 'PriceSchemeBeachChair',
    text: 'beach_chair',
  },
  {
    value: 'PriceSchemeByRequest',
    text: 'by_request',
  },
] as const;

export const SERVICE_DURATION_OPTIONS = [
  {
    value: 'PER_DAY',
    text: strings.per_day,
  },
  {
    value: 'PER_WEEK',
    text: strings.per_week,
  },
];

export const SERVICE_DAY_OPTIONS = [
  {
    value: 'SUN',
    text: strings.sun,
  },
  {
    value: 'MON-FRI',
    text: strings.mon_fri,
  },
];

export const apartmentFeatures: any = {
  küche: {
    kitchenType: 'Küchentyp',
    espressomachine: 'Nespresso Maschine',
    dishwasher: 'Geschirrspüler',
    stove: 'Herd',
    cookingUtensils: 'Kochutensilien',
    electricKettle: 'Wasserkocher',
    oven: 'Ofen',
    ovenConnection: 'Ofen Verbindung',
    fridge: 'Kühlschrank',
    freezer: 'Gefrierschrank',
    toaster: 'Toaster',
    espressomachineType: 'Espresso Maschinentyp',
    dishes: 'Besteck',
    freezerType: 'Gefrierertyp',
    coffeemaker: 'Kaffeemaschine',
    trashcan: 'Mülleimer',
    warmwater: 'Warmes Wasser',
  },
  bad: {
    bathrooms: 'Bäder',
    guestwc: 'Gäste WC',
  },
  schlafzimmer: {
    bedrooms: 'Schlafzimmer',
  },
  entertainment: {
    tv: 'TV',
    tvConnection: 'Fernsehverbindung',
    dvd: 'DVD',
    cd: 'CD',
  },
  aktivitäten: {
    shopping: 'Shopping',
    family: 'Familie',
    day_spa: 'Spa',
    golf: 'Golf',
    loc_ocean: 'Meerblick',
    mountainbiking: 'Mountainbiking',
    wellnessActivity: 'Wellness',
    beach: 'Strand',
    nudeBeach: 'FKK',
    cycling: 'Fahrrad',
    countrysideView: 'Ländliche Gegend',
    birdWatching: 'Vögel beobachten',
    swimming: 'Schwimmen',
    hiking: 'Wandern',
    water_sport: 'Wassersport',
    'nordic-walking': 'Nordic Walking',
    windSurfing: 'Wind Surfing',
    minigolf: 'Mini-Golf',
    gardenView: 'Garten',
    sideboard: 'Komode',
    sauna: 'Sauna',
    wellness: 'Wellness',
  },
};

export const BREAD_BOOKING_STATUS_OPTIONS: bookingStatusArray = [
  'confirmed',
  'pending',
  'cancelled',
];

export const IMPORT_TYPES: ImportTypesArray = [
  'availabilities',
  'units',
  'services',
];

export const PMS_STATUS_OPTIONS = [
  {
    value: 'pending',
    text: 'pending',
  },
  {
    value: 'confirmed',
    text: 'confirmed',
  },
] as const;

export const PMS_PAYMENT_LINK_STATUS_OPTIONS = [
  {
    value: 'pending',
    text: 'pending',
  },
  {
    value: 'confirmed',
    text: 'paid',
  },
] as const;

export const PAYMENT_OPTIONS = [
  {
    value: 'visa',
    text: 'Visa',
  },
  {
    value: 'master',
    text: 'Master',
  },
  {
    value: 'amex',
    text: 'Amex',
  },
  {
    value: 'unionpay',
    text: 'Unionpay',
  },
];

export const PMS_PAYMENT_METHOD_OPTIONS = ['credit_card', 'paypal'] as const;

//CSV FILE HEADERS FOR BMS DRIVING ORDER
export const BMS_DRIVING_ORDER_HEADER = [
  'Id',
  'Kürzel',
  'Anlage',
  'Eigentümer',
  'Kategorie',
  'Aktiv',
  'Online',
  'Schlafmöglichkeiten',
  'Längengrad',
  'Breitengrad',
  'Typ',
  'Schlafzimmer',
  'Straße',
  'Hausnr.',
  'Plz',
  'Ort',
];

export const DRIVING_ORDER_HEADER = ['Id', 'Kürzel'];

export const IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/x-icon',
  'image/ief',
  'image/svg+xml',
];

export const imageCompressionOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const RATING_OPTIONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

export const FEEDBACK_QUESTION_TYPE_CLOSED_ENDED = 'closed';
export const FEEDBACK_QUESTION_TYPE_RATING = 'rating';
export const FEEDBACK_QUESTION_TYPE_OPEN = 'open';
export const FEEDBACK_STATUS_TYPE: ['pending', 'sent', 'received', 'closed'] = [
  'pending',
  'sent',
  'received',
  'closed',
];

export const PUSH_NOTIFICATION_OPTIONS: PNSOptionsArray = ['web', 'app'];
//Recipient options in Newsletter subsystem

export const MDM_LOGS_IMPORT_TYPES_OPTIONS: MDM_LOGS_IMPORT_TYPES_ARRAY = [
  'apartment',
  'contact',
  'booking',
  'invoice',
  'service',
  'newsletter_contact',
];

export const VIMEO_URL = 'https://vimeo.com/user133227979';
export const INSTAGRAM_URL = 'https://www.instagram.com/listinfo.de';
export const PINTEREST_URL = 'https://www.pinterest.de/ListInfoClausen';
export const WEBSITE_URL = 'https://listinfo.de';
export const PRIVACY_POLICY_URL = 'https://listinfo.de/datenschutz';
export const IMPRINT_URL = 'https://listinfo.de/impressum';

export const NEWSLETTER_STATUS_CONFIRMED = 'confirmed';

export const defaultCreatePaymentLinkMessage =
  'Ihre Zahlung gehört zur Buchung BN ______ und dem von Ihnen gebuchten Feriendomizil ____________ in dem Zeitraum vom __.__.____ bis __.__.____. Für Rückfragen stehen wir Ihnen gerne auch telefonisch unter 04651 8898570 zur Verfügung.';

export const AERIAL_MAP_URL = 'https://aerialmap.listinfo.de';
export const DASHBOARD_URL = 'https://matomo.listinfo.de';
export const TRAVELYTICS_URL =
  'https://lookerstudio.google.com/u/0/reporting/1e912921-0eb3-40ab-b9b5-d96c4052e2f5';
export const V_OFFICE_URL = 'https://dyn.v-office.com';
export const XXL_OBJECT_IMAGE_URL =
  `${V_OFFICE_URL}/image/xxl/:imageID` as const;

export const message2FA =
  '2FA authentication enabled, Please enter the verification code.';
export const invalid2FA = 'Invalid Verification Code, Please try again.';

export const INBOUND = 'inbound';
export const OUTBOUND = 'outbound';

export const MMSSearchFormDefaultValues = {
  article: '',
  transferred_by: '',
  transfer_source: '',
  source_entity_type: '',
  source_entity_id: '',
  destination_entity_type: '',
  destination_entity_id: '',
  transferred_from: null,
  transferred_to: null,
};

export const apartmentWithVRTour = [
  100794, 100769, 138457, 100784, 100887, 100906, 127799, 100797, 100891,
  100858, 100763, 100843, 100827, 138445, 100841, 100792, 100819, 161690,
  100939, 100770, 100781, 100782, 100805, 100807, 100808, 110314, 125222,
  180165, 100866, 100940, 100928, 100790, 183388, 181807, 164044, 100903,
  100767, 100937, 100920, 110244, 100901, 100798, 100745, 100870, 100793,
  179769, 100774, 100795, 109008, 190085, 100917,
];

export const costRecipientMap = new Map([
  ['Guest', 'guest'],
  ['Owner', 'owner'],
  ['Company', 'app_vermittl_fam_clausen'],
  ['Other', 'other_see_description'],
]);

export const SECOND_SCREEN_CHANNEL = 'secondScreenBroadCast';
export const MIRROR_SECOND_SCREEN = 'mirrorSecondScreen';
export const SECOND_SCREEN_STATUS = 'secondScreenStatus';
export const IS_SECOND_SCREEN_OPEN = 'isSecondScreenOpen';
export const BOOKING_DETAILS = 'bookingDetails';

export const EXTERNAL_LINK_MENU = ['aerialMap', 'travelytics', 'dashboard'];

export const RESPONSIBLE_PERSON_API = `/rms/users`;

export const VOFFICE_CONTACT_DETAIL = 'https://app.v-office.com/#/contact?id=';

export const LANGUAGE_OPTIONS = [
  {
    label: 'description_german',
    value: 'de',
  },
  {
    label: 'description_english',
    value: 'en',
  },
  {
    label: 'description_polish',
    value: 'pl',
  },
  {
    label: 'description_danish',
    value: 'da',
  },
  {
    label: 'description_chinese',
    value: 'zh',
  },
] as const;

export const DESCRIPTION_MAPPER = {
  de: 'descriptions_de',
  en: 'descriptions_en',
  pl: 'descriptions_pl',
  da: 'descriptions_da',
  zh: 'descriptions_zh',
} as const;

export const TITLE_MAPPER = {
  de: 'titles_de',
  en: 'titles_en',
  pl: 'titles_pl',
  da: 'titles_da',
  zh: 'titles_zh',
} as const;

export const ObjektTierIcons = {
  real: RealTier,
  luxus: LuxusTier,
  edel: EdelTier,
  pure_edel: PureEdelTier,
  pure_luxus: PureLuxusTier,
} as const;

export const TIER_LIST = [
  {
    name: '1: Real',
    value: 'real',
  },
  {
    name: '2: Edel',
    value: 'edel',
  },
  {
    name: '3: Luxus',
    value: 'luxus',
  },
  {
    name: '4: Pure Edel',
    value: 'pure_edel',
  },
  {
    name: '5: Pure Luxus',
    value: 'pure_luxus',
  },
] as const;

// Common toast durations in milliseconds
export const TOAST_DURATION = {
  DEFAULT: 5000,
  LONG: 9000,
  SHORT: 3000,
};

// Common toast statuses
export enum TOAST_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
