import Resource from 'api/resource';
import http from 'utils/http';

class CRMEventLogResource extends Resource {
  constructor() {
    super('crm/contact-events');
  }

  getEventList(id: number, params: any) {
    return http({
      method: 'GET',
      url: `${this.uri}/events/${id}`,
      params,
    });
  }
}

export { CRMEventLogResource as default };
