import Resource from 'api/resource';
import http from 'utils/http';
class ArticlestResource extends Resource {
  constructor() {
    super('mms/articles');
  }

  /**
   * Upload to Bucket
   */
  uploadToBucket(data: any) {
    return http({
      url: `/mms/articles/attachment`,
      method: 'post',
      data: data,
    });
  }

  /**
   * Get articles Qr signature
   */
  getQrSignature(id: number, article_print_number: number) {
    return http({
      url: `/mms/articles/qrcode/print/${id}/${article_print_number}`,
      method: 'get',
    });
  }

  /**
   * Delete Attachment
   */
  destroyAttachment(attachmentId: number) {
    return http({
      url: `/mms/articles/attachment/${attachmentId}`,
      method: 'delete',
    });
  }
}

export { ArticlestResource as default };
