import { Td, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { PNSOptions } from 'constants/schema';
import React from 'react';
import { truncateString } from 'utils';
import { utcToLocal } from 'utils/DateFormat';

interface notificationData {
  id: number;
  createdAt: string;
  channel: PNSOptions;
  title: string;
  message: string;
  launchUrl: string;
  successCount: number;
  respondCount: number;
}
interface Props {
  notificationData: notificationData;
  index: number;
}
const NotificationListItem: React.FC<Props> = (props) => {
  const { notificationData, index } = props;

  return (
    <Tr key={notificationData.id}>
      <Td>{index}</Td>
      <Td>{utcToLocal(notificationData?.createdAt).replace(' ', ', ')}</Td>
      <Td>{strings[notificationData?.channel] ?? '-'}</Td>
      <Td title={notificationData?.title}>
        {truncateString(notificationData?.title, 25) ?? '-'}
      </Td>
      <Td title={notificationData?.message}>
        {truncateString(notificationData?.message, 25)}
      </Td>
      <Td title={notificationData?.launchUrl}>
        {truncateString(notificationData?.launchUrl, 20) ?? '-'}
      </Td>
      <Td isNumeric>
        {notificationData?.successCount ?? '-'}
        &nbsp;/&nbsp;
        {notificationData?.respondCount ?? '-'}
      </Td>
    </Tr>
  );
};

export default NotificationListItem;
