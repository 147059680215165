import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Icon,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { ENCODING_CODEPAGE } from 'constants/common';
import useFileUpload from 'hooks/useFileUpload';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { BiFile, BiInfoCircle, BiTrash } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import checkHeaders from 'utils/checkHeaders';
import { DateFormat } from 'utils/DateFormat';
import XLSX from 'xlsx';
import FileDetails from './FileDetails';

interface Props {
  title?: string;
  url: string;
  defaultFileName?: string;
  description?: string;
  uploadInfo: any;
  deleteAttachment?: any;
  acceptFileType?: string;
  clearFileInputToggle?: boolean; // clear input files (myFiles) on toggle of this prop
  headerSchema: string[];
}

const CSVUploader: React.FC<Props> = (props) => {
  const {
    url,
    defaultFileName,
    title = strings.upload_files,
    description = strings.drag_drop_csv_15MB_files_msg,
    uploadInfo,
    deleteAttachment,
    acceptFileType = 'csv/*',
    clearFileInputToggle,
    headerSchema,
  } = props;

  const { id: userID } = useSelector((state: any) => state.data.auth.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let uploadedDataFiles: any = [];
  const [myUploadedFiles, setMyUploadedFiles] = useState<any>([
    ...uploadedDataFiles,
  ]);
  const [myFiles, setMyFiles] = useState<any>([]);
  const [toRemoveFile, setToRemoveFile] = useState<any>();
  const { setValue } = useFormContext();
  const toast = useToast();
  const isFirstMount = useRef(true);
  const { loading, result, uploadPercent, error, setFormdata, setUploadFile } =
    useFileUpload(url);

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      setMyFiles(acceptedFiles);

      if (fileRejections.length > 0) {
        toast({
          title: strings.max_upload_size_15,
          status: 'error',
          isClosable: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = async function () {
        const fileData = reader.result;
        const workbook = XLSX.read(fileData, {
          type: 'binary',
          codepage: Number(ENCODING_CODEPAGE),
          cellDates: true,
        });
        const sheetNameList = workbook.SheetNames;

        // check if the uploaded filess contains required headers (columns)
        const isValid = checkHeaders(
          workbook.Sheets[sheetNameList[0]],
          headerSchema
        );
        if (!isValid) {
          toast({
            title: strings.cannot_upload_csv_in_worng_category,
            status: 'error',
            isClosable: true,
          });
          return;
        } else {
          const fs = acceptedFiles[0];
          const fullFileName: string[] = fs.name.split('.');
          const fileExtension = fullFileName.pop();
          const fileName = fullFileName.join('_');
          const file = new File(
            [fs],
            fileName + '_' + Date.now().toString() + '.' + fileExtension,
            {
              type: fs.type,
            }
          );

          const formdata = new FormData();
          if (file) formdata.append('file', file);
          formdata.append('uploaded_by', `${userID}`);
          setUploadFile(file);
          setFormdata(formdata);
        }
      };
      reader.readAsBinaryString(acceptedFiles[0]);
    },
    [headerSchema, setFormdata, setUploadFile, toast, userID]
  );
  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 15) {
      return {
        code: 'file-size-too-large',
        message: strings.max_upload_size_15,
      };
    }
    return null;
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: acceptFileType,
    validator: fileSizeValidator,
    onDrop,
  });

  const removeFile = (file: any) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const cancelStyled = {
    color: 'gray.300',
    _hover: {
      color: 'black',
      cursor: 'pointer',
    },
  };

  const files = myFiles.map((file: any, index: number) => (
    <ListItem key={index}>
      <Flex justify="space-between">
        <FileDetails
          fileName={file.path.replace(/\.[^/.]+$/, '')}
          fileSize={file.size}
          mimeType={file.type}
        />
        <Box w="4" h="4" mr={1} alignSelf="baseline">
          <IconButton
            color="gray.300"
            _hover={{ cursor: 'pointer', color: 'red.300' }}
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            onClick={removeFile(file)}
          />
        </Box>
      </Flex>
    </ListItem>
  ));

  const handleModal = (file: any) => {
    setToRemoveFile(file);
    setIsModalOpen(true);
  };

  const uploadedFileList = myUploadedFiles.map((file: any, index: number) => (
    <ListItem key={index}>
      <Flex justify="space-between">
        <FileDetails
          fileName={file.file_name.replace(/\.[^/.]+$/, '')}
          fileSize={file.file_size}
          mimeType={file.mime_type}
        />
        <Box w="4" h="4" mr={1} alignSelf="baseline">
          <IconButton
            color="gray.300"
            _hover={{ cursor: 'pointer', color: 'red.300' }}
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            onClick={() => handleModal(file)}
          />
        </Box>
      </Flex>
    </ListItem>
  ));

  const removeUploadedFile = () => {
    deleteAttachment(toRemoveFile.id);
    const newFiles = [...myUploadedFiles];
    newFiles.splice(myUploadedFiles.indexOf(toRemoveFile), 1);
    setMyUploadedFiles(newFiles);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      setMyFiles([]);
      setValue('files', []);
    }
  }, [clearFileInputToggle, setValue]);

  useEffect(() => {
    if (!result) return;
    if (result.status === 200) {
      toast({
        title: strings.file_uploaded_successfully,
        status: 'success',
        isClosable: true,
      });
    }
  }, [result, toast]);

  return (
    <>
      <Center
        {...getRootProps()}
        p={['3', '6']}
        border="2px"
        borderStyle="dashed"
        borderColor="gray.100"
        cursor="pointer"
        w="100%"
        h="140px"
        bg="gray.50">
        <input {...getInputProps()} />
        <VStack>
          <Icon as={BiFile} h="16" w="16" color="gray.300" />
          {isDragActive ? (
            <Text textAlign="center" color="gray.400">
              {title}
            </Text>
          ) : (
            <Text textAlign="center" color="gray.400">
              {description}
            </Text>
          )}
        </VStack>
      </Center>
      {myUploadedFiles.length > 0 && (
        <List py="2" spacing="2">
          {uploadedFileList}
        </List>
      )}
      {myFiles.length > 0 && myFiles[0] !== undefined && (
        <List py="2" spacing="2">
          <ListItem>
            <Flex justify="space-between">
              <FileDetails
                fileName={myFiles[0].path.replace(/\.[^/.]+$/, '')}
                fileSize={myFiles[0].size}
                mimeType={myFiles[0].type}
              />
            </Flex>
          </ListItem>
        </List>
      )}

      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${strings.delete_file}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.confirm_delete}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="red" onClick={() => removeUploadedFile()}>
                {strings.delete}
              </Button>
              <Button variant="outline" onClick={() => setIsModalOpen(false)}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Stack direction="column" spacing="2">
        <Text color="gray.300" pt="2">
          <Icon as={BiInfoCircle} pr="1" />
          {strings.uploaded_on} {DateFormat(uploadInfo?.created_at)}&nbsp;
          {strings.by} {uploadInfo?.user?.name}
        </Text>
        <Flex justify="space-between" pt="2">
          <Text>{defaultFileName}</Text>
          {loading && <Text sx={cancelStyled}>{strings.cancel}</Text>}
        </Flex>
        <Progress hasStripe value={uploadPercent} />
        {loading && uploadPercent !== 100 && (
          <Text>{strings.uploading} ....</Text>
        )}
        {loading && uploadPercent === 100 && (
          <Stack direction="row" align="center">
            <Spinner />
            <Text>{strings.processing_data} ....</Text>
          </Stack>
        )}
        {!loading && result?.status === 200 && (
          <Text
            color="green.300"
            textTransform="uppercase"
            fontWeight="medium"
            fontSize="sm">
            {strings.done}
          </Text>
        )}
        {!loading && error && (
          <Text>
            !Error:
            {error.status >= 500
              ? strings.internal_server_error
              : error.data.error?.message}
          </Text>
        )}
      </Stack>
    </>
  );
};

export default CSVUploader;
