import { Td, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  ownerGroupDocumentData: any;
  index: number;
  search?: any;
}
const OwnerGroupDocumentListItem: React.FC<Props> = (props) => {
  const { ownerGroupDocumentData, index, search } = props;

  return (
    <Tr key={ownerGroupDocumentData.customer_id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.dms.ownerDocuments.owner.list.replace(
              ':id',
              ownerGroupDocumentData?.owner_id.toString()
            ) + search
          }>
          {ownerGroupDocumentData?.owner?.full_name}
        </RouterLink>
      </Td>
      <Td isNumeric>{ownerGroupDocumentData?.total || 0}</Td>
    </Tr>
  );
};

export default OwnerGroupDocumentListItem;
