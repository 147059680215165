import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { selectColorScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { FerryBookingSchema } from 'constants/schema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
interface Props {
  ferryBookingData: FerryBookingSchema;
  index: number;
  search: any;
}
const FerryBookingListItem: React.FC<Props> = (props) => {
  const { ferryBookingData, index, search } = props;
  let status: 'confirmed' | 'cancelled' | 'pending' = ferryBookingData?.status;

  return (
    <Tr key={ferryBookingData.id}>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.bms.ferryBooking.details.replace(
              ':id',
              ferryBookingData.id.toString()
            ) + search
          }>
          {ferryBookingData?.client_booking_number || '-'}
        </RouterLink>
      </Td>
      <Td>{ferryBookingData?.booking_date}</Td>
      <Td>{ferryBookingData?.customer_name}</Td>
      <Td>
        <Text
          color={selectColorScheme(status)}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {status}
        </Text>
      </Td>
      <Td>
        <RouterLink
          to={
            routes.bms.ferryBooking.details.replace(
              ':id',
              ferryBookingData.id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_ferry_booking_view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_ferry_booking_view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default FerryBookingListItem;
