import axios from 'axios';
import http from 'utils/http';

export function login(data: any) {
  return http({
    url: '/user/login',
    method: 'post',
    data: { ...data, role_group: 'service_center' },
  });
}

export function getAuthUser() {
  return http({
    url: '/user/auth/user',
    method: 'get',
  });
}

export function updateAuthUser(data: any) {
  return http({
    url: '/user/auth/user',
    method: 'put',
    data: data,
  });
}

export function getAuthPermission() {
  return http({
    url: '/user/auth/user-permissions',
    method: 'get',
  });
}

export function logout() {
  return http({
    url: '/user/logout',
    method: 'post',
  });
}

const CancelToken = axios.CancelToken;
let cancel: any;

export function refreshUser(refreshToken: string, userID: string) {
  if (cancel) {
    cancel(); // cancel request
  }

  return http({
    url: '/user/refresh-token',
    method: 'post',
    data: { refresh_token: refreshToken, user_id: userID },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}

export function forgotPassword(data: any) {
  return http({
    url: '/user/forgot-password',
    method: 'post',
    data: data,
  });
}

export function changePassword(data: any) {
  return http({
    url: '/user/change-password',
    method: 'post',
    data: data,
  });
}

export function resetPassword(data: any) {
  return http({
    url: '/user/reset-password',
    method: 'post',
    data: data,
  });
}

export function setNewPassword(data: any) {
  return http({
    url: '/user/set-new-password',
    method: 'post',
    data: data,
  });
}

export function verifyEmail(data: any) {
  return http({
    url: '/user/verify-email',
    method: 'post',
    data: data,
  });
}

export function resendVerificationEmail(data: any) {
  return http({
    url: '/user/resend-verify-email',
    method: 'post',
    data: data,
  });
}
