import Resource from 'api/resource';
import http from 'utils/http';

class MMSResource extends Resource {
  constructor() {
    super('mms/users');
  };


};

export { MMSResource as default };
