import {
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { formatPriceWithLabel } from 'utils';
interface Props {
  reservationRequestData: any;
}

const ReservationRequestServiceInfo: React.FC<Props> = (props) => {
  const { reservationRequestData: serviceLines } = props;

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.service_line}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.name}</Th>
                <Th>{strings.booking_period}</Th>
                <Th>{strings.price} (&euro;)</Th>
                <Th>{strings.quantity}</Th>
                <Th>{strings.timespan}</Th>
                <Th>{strings.total} (&euro;)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {serviceLines &&
                serviceLines.length > 0 &&
                serviceLines?.map((serviceData: any) => (
                  <Tr key={serviceData.id}>
                    <Td>{serviceData?.service_name}</Td>
                    <Td>{serviceData?.booking_period}</Td>
                    <Td>
                      {formatPriceWithLabel(serviceData?.unit_price || 0)}
                    </Td>
                    <Td>{serviceData?.quantity}</Td>
                    <Td>{serviceData?.timespan}</Td>
                    <Td>
                      {formatPriceWithLabel(serviceData?.total_price || 0)}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};
export default ReservationRequestServiceInfo;
