import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ArticlesResource from 'api/articles';
import { QRScan } from 'assets/icons';
import CustomTagTitle from 'components/CustomTagTitle';
import QRScanDetailPDF from 'components/qr-scan/QRScanDetailPDF';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { qrDataSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useRef, useState } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import AccessControl from 'services/AccessControl';

interface Props {
  data: any;
  index: number;
  search?: any;
}

const ArticleListItem: React.FC<Props> = (props) => {
  // props
  const { data, index, search } = props;
  const printRef: any = useRef();
  // Library Init
  const queryClient = useQueryClient();

  // API Resource
  const articlesAPI = new ArticlesResource();

  const toast = useToast();
  const status = data?.status;
  // Local State
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isQRModelOpen, setIsQRModelOpen] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');
  const [numberOfPrint, setNumberOfPrint] = useState<number>(1);
  const [qrSignature, setQrSignature] = useState<qrDataSchema[]>([]);

  // Access Control
  const checkEditArticlePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.articles.edit.replace(':id', data.id?.toString())
  );

  const isDeletePermitted: boolean = useIsPermitted([
    PermissionRequest['manage:article'],
  ]);

  // Handler Functions
  const deleteArticle = useMutation((id: number) => articlesAPI.destroy(id), {
    onSuccess: () => {
      setDeleteModalOpen(false);
      queryClient.invalidateQueries('articleList');
    },
    onError: (error: any) => {
      setDeleteErrorMsg(error?.response?.data?.error?.message);
    },
  });

  const handleDelete = () => {
    deleteArticle.mutate(data.id);
  };

  const handleDeleteModal = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteErrorMsg('');
    setDeleteModalOpen(false);
  };

  const handleCloseQrModal = () => {
    setNumberOfPrint(1);
    setIsQRModelOpen(false);
  };

  const getQrSignature = useMutation(
    () => articlesAPI.getQrSignature(data.id, numberOfPrint),
    {
      onSuccess: (res) => {
        let newArray: any = [];
        res?.data?.qrCodeRes.forEach((item: any) =>
          newArray.push({ title: data.name ?? '-', value: item.qrRes })
        );
        setQrSignature(newArray);
        saveQRScan();
      },

      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const generateQrCode = () => {
    getQrSignature.mutate();
  };

  const handleAfterPrint = () => {
    setIsQRModelOpen(false);
    setQrSignature([]);
  };

  const saveQRScan = useReactToPrint({
    content: () => printRef.current,
    pageStyle:
      '* {background-color:white !important} @page { size:portrait !important} ',
    documentTitle: ` QR Code`,
    onAfterPrint: handleAfterPrint,
  });

  return (
    <Tr key={index}>
      <Td> {index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.mms.articles.details.replace(':id', data.id?.toString()) +
            search
          }>
          <CustomTagTitle
            type="text"
            status={data.status}
            name={data.name || '-'}
          />
        </RouterLink>
      </Td>
      <Td>{data.articleCategory?.name || '-'}</Td>
      <Td>{data.articleUnitType?.name || '-'}</Td>

      <Td>
        <Stack direction="row" alignItems="baseline">
          <RouterLink
            to={
              routes.mms.articles.details.replace(':id', data.id?.toString()) +
              search
            }>
            <Tooltip hasArrow label={strings.view}>
              <IconButton
                icon={<BiShow />}
                variant="link"
                aria-label={strings.view}
                color="green.300"
                minW="6"
              />
            </Tooltip>
          </RouterLink>
          {!!status && (
            <>
              <Link onClick={checkEditArticlePermission}>
                <Tooltip hasArrow label={strings.edit}>
                  <IconButton
                    icon={<BiEdit />}
                    variant="link"
                    aria-label={strings.edit}
                    color="blue.300"
                    minW="6"
                  />
                </Tooltip>
              </Link>
              <Tooltip hasArrow label={strings.delete}>
                <IconButton
                  icon={<BiTrash />}
                  variant="link"
                  aria-label={strings.delete}
                  color="red.300"
                  minW="6"
                  isDisabled={!isDeletePermitted}
                  onClick={handleDeleteModal}
                />
              </Tooltip>

              <Tooltip hasArrow label={strings.qr_code}>
                <Image
                  src={QRScan}
                  alt="Get QrSCan"
                  onClick={() => setIsQRModelOpen(true)}
                  w="4"
                  _hover={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </>
          )}
          <Modal
            isOpen={isDeleteModalOpen}
            isCentered
            onClose={handleCancelDelete}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {strings.delete} {strings.article}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {deleteErrorMsg && (
                  <Alert status="error" mb={2}>
                    <AlertIcon />
                    {deleteErrorMsg}
                  </Alert>
                )}
                {strings.confirm_delete}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <AccessControl
                    allowedPermissions={[
                      PermissionRequest['read:mms'],
                      PermissionRequest['manage:article'],
                    ]}
                    renderNoAccess={(data: any) => data}>
                    <Button
                      colorScheme="red"
                      onClick={handleDelete}
                      isLoading={deleteArticle.isLoading}>
                      {strings.delete}
                    </Button>
                  </AccessControl>
                  <Button
                    variant="outline"
                    onClick={handleCancelDelete}
                    isDisabled={deleteArticle.isLoading}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={isQRModelOpen}
            isCentered
            size="xl"
            onClose={handleCloseQrModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Text as="p" color="paragraph" fontSize="18px">
                  {data.name} {strings.qr_code}
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody color="heading">
                <FormControl mt="16px">
                  <FormLabel fontSize={16}>{strings.no_of_print}:</FormLabel>
                  <NumberInput
                    value={numberOfPrint}
                    inputMode="numeric"
                    min={1}
                    step={1}
                    defaultValue={1}
                    onChange={(e: any) => setNumberOfPrint(e)}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    leftIcon={<AiOutlinePrinter />}
                    minW="8"
                    variant="solid"
                    onClick={generateQrCode}>
                    {strings.print_qr}
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="primary"
                    onClick={handleCloseQrModal}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
        <div style={{ display: 'none' }}>
          <QRScanDetailPDF
            qrSignature={qrSignature}
            ref={printRef}
            valueFromBackend={true}
          />
        </div>
      </Td>
    </Tr>
  );
};

export default ArticleListItem;
