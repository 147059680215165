import { FormControl, FormLabel } from '@chakra-ui/form-control';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormErrorMessage,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DE, EN, PL } from 'assets/icons';
import { Logo } from 'assets/images';
import GoogleAuthentication from 'components/auth/GoogleAuthentication';
import { withAuthState } from 'components/hoc/auth';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { LanguageTypesSchema } from 'constants/schema';
import React, { CSSProperties, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiChevronDown, BiEnvelope, BiLock } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { validEmail } from 'utils/validate';

interface LoginFormInputs {
  email: string;
  password: string;
  verifyCode?: string;
}

const hideFormStyles: CSSProperties = {
  visibility: 'hidden',
  height: '0px',
  padding: '0px',
  opacity: 0,
};

interface Props {
  login: (email: string, pw: string, verifyCode?: string) => any;
}

const Login: React.FC<Props> = (props) => {
  const { login } = props;

  const currentLanguage = strings.getLanguage();
  const [currentFlag, setCurrentFlag] = useState(() =>
    currentLanguage === 'pl' ? PL : currentLanguage === 'de' ? DE : EN
  );

  const methods = useForm<LoginFormInputs>();
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = methods;
  const [isError, setIsError] = useState(false);
  const [twoFA, setTwoFA] = useState<boolean>(false);

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      if (!data?.verifyCode) {
        delete data?.verifyCode;
      }
      const res = await login(data.email, data.password, data.verifyCode);

      if (res === 'enable_2fa') {
        setIsError(false);
        setTwoFA(true);
      }
    } catch (e) {
      setIsError(true);
    }
  };

  const handleLanguage = (Language: LanguageTypesSchema) => {
    setCurrentFlag(Language === 'en' ? EN : Language === 'de' ? DE : PL);

    strings.setLanguage(Language);
    localStorage.setItem('language', Language);
  };

  return (
    <Box bg="gray.50">
      <Stack pos="absolute" right="5%">
        <Menu>
          <MenuButton
            p={2}
            transition="all 0.2s"
            _hover={{ borderBottom: 'gray.300' }}
            _expanded={{ borderBottom: 'gray.400' }}>
            <Flex>
              <MenuItem icon={<Image src={currentFlag} w="6" h="6" />}>
                {currentLanguage?.toLocaleUpperCase()}
              </MenuItem>
              <Box pt="2">
                <BiChevronDown />
              </Box>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => handleLanguage('de')}
              icon={<Image src={DE} w="6" h="6" />}>
              DE
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('en')}
              icon={<Image src={EN} w="6" h="6" />}>
              EN
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('pl')}
              icon={<Image src={PL} w="6" h="6" />}>
              PL
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
      <Center h="100vh">
        <Stack direction="column" spacing="12">
          <Stack spacing="4">
            <Center>
              <Image src={Logo} w="16" />
            </Center>
            <Stack>
              <Text align="center" fontSize="lg" fontWeight="medium">
                {strings.listinfo_service_center}
              </Text>
            </Stack>
          </Stack>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                bg="white"
                p="14"
                shadow="box"
                rounded="sm"
                style={twoFA ? hideFormStyles : {}}>
                <Stack direction="column" spacing="8">
                  <Stack direction="column" spacing="4">
                    <Heading as="h1" size="xl">
                      {strings.log_in}
                    </Heading>
                    <Text color="gray.500">{strings.login_message}</Text>
                    {isError && (
                      <ScaleFade in={isError}>
                        <Alert status="error">
                          <AlertIcon />
                          {strings.invalid_login_msg}
                        </Alert>
                      </ScaleFade>
                    )}
                  </Stack>
                  <Stack direction="column" spacing="5">
                    <FormControl
                      colorScheme="primary"
                      isInvalid={!!errors.email}>
                      <FormLabel>{strings.email}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          children={
                            <Icon
                              color="gray.200"
                              w="4"
                              h="4"
                              as={BiEnvelope}
                            />
                          }
                        />
                        <Input
                          id="email"
                          type="email"
                          placeholder={strings.your_email_address}
                          {...register('email', {
                            required: 'Email address is required',
                            validate: (value) =>
                              validEmail(value) || strings.valid_email_address,
                          })}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors.email && errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      colorScheme="primary"
                      isInvalid={!!errors.email}>
                      <FormLabel>{strings.password}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          children={
                            <Icon color="gray.200" w="4" h="4" as={BiLock} />
                          }
                        />
                        <Input
                          id="password"
                          type="password"
                          colorScheme="green"
                          placeholder={strings.password}
                          {...register('password', {
                            required: 'Password is required',
                          })}
                        />
                      </InputGroup>

                      <FormErrorMessage>
                        {errors.password && errors.password?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <Flex justify="space-between">
                        <Checkbox color="gray.500">
                          {strings.remember_me}
                        </Checkbox>

                        <Button variant="link" colorScheme="primary">
                          <Link to={routes.auth.forgotPassword}>
                            {strings.forgot_password}
                          </Link>
                        </Button>
                      </Flex>
                    </FormControl>
                    <Button
                      w="full"
                      type="submit"
                      size="lg"
                      colorScheme="primary"
                      isLoading={formState.isSubmitting}>
                      Login
                    </Button>
                  </Stack>
                </Stack>
              </Box>

              {twoFA && (
                <GoogleAuthentication
                  setTwoFA={setTwoFA}
                  isError={isError}
                  setIsError={setIsError}
                />
              )}
            </form>
          </FormProvider>
        </Stack>
      </Center>
    </Box>
  );
};

export default withAuthState(Login);
