import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { labelStyles } from 'assets/css/commonStyles';
import {
  Dusche,
  Einrichtung,
  Jobs,
  Kompass,
  MeineBuchung,
  MP3Player,
  Qm,
  Restaurant,
  Spuelmaschiene,
  Umgebung,
} from 'assets/icons';
import { apartmentFeatures } from 'constants/common';
import React from 'react';

interface Props {
  objektInfo: any;
  isDashboard?: boolean;
}

const FeaturesPanel: React.FC<Props> = (props) => {
  const { objektInfo, isDashboard = false } = props;
  const featureColumns = ['repeat(1, 1fr)', 'repeat(2, 1fr)'];
  if (!isDashboard) featureColumns.push('repeat(3,1fr)');

  const featureStyle = {
    gridTemplateColumns: isDashboard
      ? ['repeat(1, 1fr)', 'repeat(2, 1fr)']
      : ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3,1fr)'],
    gridGap: '4',
    w: isDashboard ? '100%' : '85%',
  };
  return (
    <Stack gridGap={['2', '2']}>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Spuelmaschiene} />
          </Box>
          <Text sx={labelStyles}>Küche</Text>
        </Flex>
        <Grid sx={featureStyle}>
          {Object.keys(apartmentFeatures.küche).map((feature: string) => (
            <GridItem key={feature}>
              <Flex justify="space-between" align="center">
                {objektInfo[feature] ? (
                  <Text>{apartmentFeatures.küche[feature]}</Text>
                ) : (
                  <Text as="s">{apartmentFeatures.küche[feature]}</Text>
                )}
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Dusche} />
          </Box>
          <Text sx={labelStyles}>Bad</Text>
        </Flex>
        <Grid sx={featureStyle}>
          {Object.keys(apartmentFeatures.bad).map((feature: string) => (
            <GridItem key={feature}>
              <Flex justify="space-between" align="center">
                {objektInfo[feature] ? (
                  <Text>{apartmentFeatures.bad[feature]}</Text>
                ) : (
                  <Text as="s">{apartmentFeatures.bad[feature]}</Text>
                )}
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Einrichtung} />
          </Box>
          <Text sx={labelStyles}>Schlafzimmer</Text>
        </Flex>
        <Grid sx={featureStyle}>
          {Object.keys(apartmentFeatures.schlafzimmer).map(
            (feature: string) => (
              <GridItem key={feature}>
                <Flex justify="space-between" align="center">
                  {objektInfo[feature] ? (
                    <Text>{apartmentFeatures.schlafzimmer[feature]}</Text>
                  ) : (
                    <Text as="s">
                      {apartmentFeatures.schlafzimmer[feature]}
                    </Text>
                  )}
                </Flex>
              </GridItem>
            )
          )}
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={MP3Player} />
          </Box>
          <Text sx={labelStyles}>Entertainment</Text>
        </Flex>
        <Grid sx={featureStyle}>
          {Object.keys(apartmentFeatures.entertainment).map(
            (feature: string) => (
              <GridItem key={feature}>
                <Flex justify="space-between" align="center">
                  {objektInfo[feature] ? (
                    <Text>{apartmentFeatures.entertainment[feature]}</Text>
                  ) : (
                    <Text as="s">
                      {apartmentFeatures.entertainment[feature]}
                    </Text>
                  )}
                </Flex>
              </GridItem>
            )
          )}
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Umgebung} />
          </Box>
          <Text sx={labelStyles}>Aktivitäten</Text>
        </Flex>
        <Grid sx={featureStyle}>
          {Object.keys(apartmentFeatures.aktivitäten).map((feature: string) => (
            <GridItem key={feature}>
              <Flex justify="space-between" align="center">
                {objektInfo[feature] ? (
                  <Text>{apartmentFeatures.aktivitäten[feature]}</Text>
                ) : (
                  <Text as="s">{apartmentFeatures.aktivitäten[feature]}</Text>
                )}
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Restaurant} />
          </Box>
          <Text sx={labelStyles}>Mahlzeiten</Text>
        </Flex>
        <Grid sx={featureStyle}>
          <GridItem>
            <Flex justify="space-between" align="center">
              <Text>Selbstverpflegung</Text>
            </Flex>
          </GridItem>
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Qm} />
          </Box>
          <Text sx={labelStyles}>Wohnflache</Text>
        </Flex>
        <Grid sx={featureStyle}>
          <GridItem>
            <Flex justify="space-between" align="center">
              <Text>
                {objektInfo?.squareMeters} m<sup>2</sup>
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Kompass} />
          </Box>
          <Text sx={labelStyles}>Ortstyp</Text>
        </Flex>
        <Grid sx={featureStyle}>
          <GridItem>
            <Flex justify="space-between" align="center">
              <Text>{objektInfo?.loc_beach ? 'am Strand' : 'im Ort'}</Text>
            </Flex>
          </GridItem>
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={MeineBuchung} />
          </Box>
          <Text sx={labelStyles}>Urlaubsmotto</Text>
        </Flex>
        <Grid sx={featureStyle}>
          <GridItem>
            <Flex justify="space-between" align="center">
              <Text>
                {objektInfo?.childrenWelcome
                  ? 'Familienfreundliche Unterkünft'
                  : 'Luxusdomizile'}
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Stack>
      <Stack direction={isDashboard ? 'column' : 'row'} spacing="4" p="2">
        <Flex mr="3" w={isDashboard ? '100%' : '15%'}>
          <Box w="6" h="6" mr="2">
            <Image src={Jobs} />
          </Box>
          <Text sx={labelStyles}>Allgemeines</Text>
        </Flex>
        <Grid sx={featureStyle}>
          <GridItem>
            <Flex justify="space-between" align="center">
              <Text>{objektInfo?.wifi ? 'WLAN' : 'kein WLAN'}</Text>
            </Flex>
          </GridItem>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default FeaturesPanel;
