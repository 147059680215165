import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FerryBookingResource from 'api/ferry_booking';
import FerryBookingInfo from 'components/bms_ferry_booking/FerryBookingInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { FerryBookingSchema } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const FerryBookingDetail: React.FC = () => {
  const toast = useToast();
  const { id }: any = useParams();
  const ferryBookingApi = new FerryBookingResource();
  const { search } = useLocation();

  const ferryBookingQuery = useQuery<FerryBookingSchema>(
    [`ferry-booking${id}`],
    () => ferryBookingApi.get(id).then((res) => res.data.data)
  );

  const cancelFerryBooking = useMutation(
    (data: any) => {
      return ferryBookingApi.cancel(
        ferryBookingQuery?.data?.client_booking_number
      );
    },
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.ferry_booking_cancel_success,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.bms.ferryBooking.list);
      },
      onError: (error: any, response: any) => {
        toast({
          title: strings.ferry_booking_cancel_failed,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  if (ferryBookingQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ferryBookingQuery.isError) {
    history.push(routes.bms.ferryBooking.list);
  }

  const handleCancel = () => {
    cancelFerryBooking.mutate(id);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bms} |&nbsp;
            {ferryBookingQuery?.data?.client_booking_number || '-'}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bms.ferryBooking.list + search}>
              {strings.ferry_bookings}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {ferryBookingQuery?.data?.client_booking_number || 0}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {/* <Flex justify="space-between">
          {ferryBookingQuery?.data?.status.toLocaleLowerCase() ===
            'confirmed' && (
            <Stack direction="row" spacing="4" align="end">
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={handleCancel}>
                {strings.cancel}
              </Button>
            </Stack>
          )}
        </Flex> */}

        <FerryBookingInfo ferryBookingData={ferryBookingQuery?.data} />
      </Stack>
    </>
  );
};

export default FerryBookingDetail;
