import { Icon } from '@chakra-ui/react';
import { INBOUND } from 'constants/common';
import { callDirection } from 'constants/schema';
import React from 'react';
import { VscCallIncoming, VscCallOutgoing } from 'react-icons/vsc';

export const getCallDirectionIcon = (type: callDirection) => {
  return (
    <Icon
      as={type === INBOUND ? VscCallIncoming : VscCallOutgoing}
      color={type === INBOUND ? 'green.600' : 'blue.400'}
      fontSize={18}
    />
  );
};
