import { Input, InputProps } from '@chakra-ui/input';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface DebounceInputProps extends InputProps {
  delay?: number;
  name: string;
  placeholder?: string;
  initialValue?: string;
  setFilterParams: Dispatch<SetStateAction<any>>;
}

const DebounceInput: React.FC<DebounceInputProps> = (props) => {
  const {
    name,
    sx,
    placeholder,
    delay = 600,
    initialValue = '',
    setFilterParams,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilterParams((prev: any) => ({
        ...prev,
        [name]: inputValue,
      }));
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [inputValue, delay, name, setFilterParams]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Input
      pl="35px"
      type="text"
      name={name}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleInputChange}
      {...rest}
    />
  );
};

export default DebounceInput;
