import Icon from '@chakra-ui/icon';
import { Flex, Text } from '@chakra-ui/layout';
import RMSDashObjectsResource from 'api/rms-dashboard';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ObjektDetailSchema } from 'constants/schemas/objektSchema';
import React from 'react';
import { BiShowAlt } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

interface Props {
  vOfficeOwnerId: number | null;
}

const OtherObjectsArea: React.FC<Props> = (props) => {
  const { vOfficeOwnerId } = props;
  const { id } = useParams<{ id: string }>();
  const apartmentId = Number(id);
  const dashboardObjectsAPI = new RMSDashObjectsResource();

  const {
    data: ownerObjects,
    isLoading,
    isError,
  } = useQuery<ObjektDetailSchema[]>({
    queryKey: ['dashbaord-owner-other-object', vOfficeOwnerId],
    queryFn: async () => {
      if (!vOfficeOwnerId) return;
      const response = await dashboardObjectsAPI.dashboardOwnerObjects(
        vOfficeOwnerId
      );
      //Need to be removed in future changes (Objects excluding apartmentId)
      return response?.data?.data?.filter(
        (item: ObjektDetailSchema) => item.id !== apartmentId
      );
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <TableSkeletonLoader rows={3} />;
  }

  if (isError || !ownerObjects || !ownerObjects?.length) {
    return <Text>{strings.no_data_found}</Text>;
  }

  return (
    <>
      {ownerObjects.map((item) => (
        <Flex
          border="1px"
          borderColor="#EBECF2"
          p="10px"
          rounded="lg"
          alignItems="center"
          justifyContent="space-between"
          key={item.id}>
          <Flex flexDir="column">
            <Text>
              {strings.name}: {item.info.fullName}
            </Text>
            <Text>
              {strings.status}:
              {item.status === 1 ? strings.active : strings.in_active}
            </Text>
          </Flex>
          <Link
            to={routes.oms.objects.details.replace(':id', item.id.toString())}>
            <Icon
              as={BiShowAlt}
              title={strings.view}
              w="8"
              h="8"
              _hover={{ cursor: 'pointer' }}
            />
          </Link>
        </Flex>
      ))}
    </>
  );
};

export default OtherObjectsArea;
