import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { KEY_APARTMENT_SEARCH_API } from 'constants/common';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import ReactSelect from 'react-select';
import { DateFormat } from 'utils/DateFormat';

interface KeyDetails {
  rfid: string;
  apartment: { id: number; name: string };
  description: string;
  status: string;
  createdAt: string;
}

interface Props {
  keyDetails: KeyDetails;
}

const EditKeyForm = (props: Props) => {
  const { keyDetails } = props;

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();

  const { loading: apartmentListLoading, result: apartmentQuery } =
    useWordSearch(`${KEY_APARTMENT_SEARCH_API}?limit=500`);

  const apartmentList = apartmentQuery?.data;
  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
  }));

  useEffect(() => {
    if (!keyDetails) return;
    const defaultValue = apartmentOptions?.find(
      (item: any) => item.value === keyDetails?.apartment.id
    );
    if (defaultValue?.value) setValue('apartmentId', defaultValue);
  }, [apartmentOptions, keyDetails, setValue]);

  useEffect(() => {
    if (!keyDetails) return;
    setValue('description', keyDetails.description);
    setValue('status', keyDetails.status);
  }, [keyDetails, setValue]);

  return (
    <form>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl isDisabled isRequired>
            <FormLabel>{strings.key_id}</FormLabel>
            <Input type="text" value={keyDetails?.rfid} />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isDisabled isRequired>
            <FormLabel>{strings.registered_date}</FormLabel>
            <InputGroup>
              <InputRightElement
                pointerEvents="none"
                children={<BiCalendar />}
                color="gray.400"
              />
              <Input type="text" value={DateFormat(keyDetails?.createdAt)} />
            </InputGroup>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.apartmentId} isRequired>
            <FormLabel>{strings.object}</FormLabel>
            <Controller
              control={control}
              name="apartmentId"
              rules={{
                required: strings.required_Object,
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="apartmentId"
                  placeholder={strings.select_apartment}
                  options={apartmentOptions}
                  styles={reactSelectStyles}
                  isLoading={apartmentListLoading}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.apartmentId &&
                (errors.apartmentId as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.status} isRequired>
            <FormLabel>{strings.status}</FormLabel>
            <Select
              id="status"
              placeholder={strings.select_status}
              rounded="sm"
              {...register('status', {
                required: strings.required_status,
              })}>
              <option value="in stock" disabled>
                {strings.in_stock}
              </option>
              <option value="given out" disabled>
                {strings.given_out}
              </option>
              <option value="lost">{strings.lost}</option>
            </Select>
            <FormErrorMessage>
              {errors?.status && errors?.status?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={[1, 2]}>
          <FormControl isInvalid={!!errors?.description} isRequired>
            <FormLabel>{strings.descriptions}</FormLabel>
            <Textarea
              id="description"
              placeholder={strings.descriptions}
              {...register('description', {
                required: strings.required_descriptions,
                validate: (value) => {
                  if (value?.trim() === '')
                    return strings.required_descriptions;
                },
              })}
            />
            <FormErrorMessage>
              {errors?.description && errors?.description?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </form>
  );
};

export default EditKeyForm;
