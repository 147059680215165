import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  DMSDocumentSchema,
  LanguageAbbreviationType,
  LanguageOptions,
  MenuType,
  PublishOptionType,
} from 'constants/schema';
import history from 'utils/history';
import { DateFormat } from './DateFormat';

/**
 * Get query object from URL
 *
 * @param {string} url
 */
export function getQueryObject(url: string) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    // @ts-ignore
    obj[name] = val;
    return rs;
  });

  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str: string) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) {
      s++;
    } else if (code > 0x7ff && code <= 0xffff) {
      s += 2;
    }
    if (code >= 0xdc00 && code <= 0xdfff) {
      i--;
    }
  }
  return s;
}

/**
 * Remove invalid (not equal true) elements from array
 *
 * @param {Array} actual
 */
export function cleanArray(actual: any) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }

  return newArray;
}

/**
 * Parse params from URL and return an object
 *
 * @param {string} url
 */
export function param2Obj(url: string) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  );
}

/**
 * @param {string} val
 */
export function html2Text(val: string) {
  const div = document.createElement('div');
  div.innerHTML = val;

  return div.textContent || div.innerText;
}

/**
 * Merges two  objects, giving the last one precedence
 *
 * @param {Object} target
 * @param {Object} source
 */
export function objectMerge(target: any, source: any) {
  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });

  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element: HTMLElement, className: string) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length);
  }

  element.className = classString;
}

export function getTime(type: string) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Object[]} arr
 * @returns {Object[]}
 */
export function uniqueArr(arr: any) {
  return Array.from(new Set(arr));
}

/**
 * Check if an element has a class
 *
 * @param {HTMLElement} elm
 * @param {String} cls
 */
export function hasClass(ele: HTMLElement, cls: string) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 *
 * @param {HTMLElement} elm
 * @param {String} cls
 */
export function addClass(ele: HTMLElement, cls: string) {
  if (!hasClass(ele, cls)) {
    ele.className += ' ' + cls;
  }
}

/**
 * Remove class from element
 *
 * @param {HTMLElement} elm
 * @param {String} cls
 */
export function removeClass(ele: HTMLElement, cls: string) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

/**
 * Trunacte string and add ellipsis
 * @param str
 * @returns
 */
export function truncateString(str: any, count: number = 20) {
  if (str) return str.slice(0, count) + (str.length > count ? '...' : '');
}

export function capitalizeFirstLetter(str: string) {
  if (str) return str && str[0].toUpperCase() + str.slice(1);
}

export const cleanData = (formData: any) => {
  let cleanObj = {};
  Object.keys(formData).forEach((val: any) => {
    const newVal = formData[val];
    cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
  });
  return cleanObj;
};

/**
 * changeURL is a function for changing the url of the current page with the given parameters
 * used while navigating between pages
 * @param {any} formdata
 * @returns {string} urlstring
 */
export const changeURL = (data: any) => {
  const url_data: any = { ...data };

  const searchKeys: any = [];
  Object.entries(url_data).forEach(([key, value]) => {
    if (
      url_data[key] !== '' &&
      url_data[key] !== undefined &&
      url_data[key] !== null &&
      url_data[key].length !== 0
    )
      searchKeys.push(`${key}=${value}`) ?? '';
  });
  let searchURL = searchKeys.join('&');
  return searchURL;
};

/**
 * Unmask phone number before calling API endpoint
 * Input -> Output: (+49 123 123456) -> 49123123456
 *
 * @param {string} value
 * @returns {string}
 */
export const unmaskPhone = (value: string) => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '');
};

/**
 * Mask phone number to display in proper format
 * Input -> Output: 49123123456 -> (+49 123 123456)
 *
 * @param {string} phone
 * @returns {string}
 */
export const maskPhone = (phone: string) => {
  if (!phone) return phone;
  const x: any = phone.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,12})/);
  phone = !x[2] ? x[1] : '(+' + x[1] + ') ' + x[2] + (x[3] ? ' ' + x[3] : '');
  return phone ? phone : '';
};

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 *
 * @param {string} inputValue
 * @returns {string} resultValue(formated time)
 */
export const maskSpentTime = (inputValue: string): string => {
  const value = inputValue && inputValue?.split(':').join('');

  const hour = value && value?.substr(0, 2);
  const minute = value && value?.substr(2, 2);
  const second = value && value?.substr(4, 2);

  let resultValue: string = '';

  if (hour) {
    resultValue = hour;
  }
  if (minute) {
    resultValue += ':' + minute;
  }
  if (second) {
    resultValue += ':' + second;
  }
  return resultValue;
};

/**
 * Generate a unique id
 * Helpful for identifying elements of an array when id is not sent by server
 *
 * @returns a unique id (For eg: _a3hxejbt1)
 */
export const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

/**
 * Get next letter in the English Alphabet
 * Input a -> Output b, Input A -> Output B
 *
 * @param s
 * @returns
 */
export const getNextLetter = (s: string) => {
  return s.replace(/([a-zA-Z])[^a-zA-Z]*$/, function (a: string) {
    let c = a.charCodeAt(0);
    switch (c) {
      case 90:
        return 'A';
      case 122:
        return 'a';
      default:
        return String.fromCharCode(++c);
    }
  });
};

/**
 * Get starting serial number (S.N.) based on current page and page size
 * The starting S.N. is dynamic with page
 *
 * page = 1, limit = 5 ->  staring SN = 1
 * page = 2, limit = 5 ->  staring SN = 6
 *
 * @param currentPage
 * @param pageSize
 * @returns
 */
export const getStartingSerialNumber = (
  currentPage: number,
  pageSize: number
) => {
  return currentPage * pageSize - (pageSize - 1);
};

/**
 * Format currency in euros (GER)
 *
 * 123456.12 -> 123.456,12
 *
 * @param value {number} currency value
 * @returns
 */
export const formatCurrency = (value: number) => {
  if (!value) return;
  return value.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });
};

export const buildFormData = (
  model: any,
  form?: FormData,
  namespace = ''
): FormData => {
  let formData = form || new FormData();
  let formKey;

  for (let propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date)
      formData.append(formKey, model[propertyName].toISOString());
    else if (model[propertyName] instanceof File)
      formData.append(formKey, model[propertyName]);
    else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any, index: any) => {
        const tempFormKey = `${formKey}[${index}]`;
        buildFormData(element, formData, tempFormKey);
      });
    } else if (
      typeof model[propertyName] === 'object' &&
      !(model[propertyName] instanceof File)
    )
      buildFormData(model[propertyName], formData, formKey);
    else formData.append(formKey, model[propertyName].toString());
  }
  return formData;
};

export const formatPrice = (value: number | undefined) => {
  if (value === null || value === undefined) return null;
  let euroCurrency;
  euroCurrency = (value / 100).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
  });
  return euroCurrency;
};

export const formatPriceLabel = (value: number) => {
  let euroCurrency;
  euroCurrency = Number(value).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
  });
  return euroCurrency;
};

export const formatPriceWithLabel = (value: number) => {
  let euroCurrency;
  euroCurrency =
    (value / 100).toLocaleString('de-DE', {
      minimumFractionDigits: 2,
    }) + ' €';
  return euroCurrency;
};

export const GroupOfRecipientOptionLocalization = () => {
  const groupOfRecipientOptions = [
    { value: 'owner', label: strings.owner },
    { value: 'guest', label: strings.guest },
    { value: 'lead', label: strings.lead },
    { value: 'supplier', label: strings.supplier },
    { value: 'app', label: strings.app },
    { value: 'test', label: strings.test },
  ];
  return groupOfRecipientOptions;
};

export const localizeGroupOfRecipient = (group: any) => {
  const groupName = group.toLowerCase();
  const recipientGroupLocalize: { [key: string]: string } = {
    owner: strings.owner,
    guest: strings.guest,
    lead: strings.lead,
    supplier: strings.supplier,
    app: strings.app,
    test: strings.test,
  };
  return recipientGroupLocalize[groupName];
};

export const KeyOptionLocalization = () => {
  const keyOptions = [
    { value: 'in stock', label: strings.in_stock },
    { value: 'given out', label: strings.given_out },
    { value: 'lost', label: strings.lost },
  ];
  return keyOptions;
};

// identifies whether filter is on or off in startup
export const defaultIndexIdentifier = (searchValues: any): 0 | -1 => {
  const filterValues = { ...searchValues };
  delete filterValues?.currentPage;
  delete filterValues?.pageSize;
  delete filterValues?.page;
  delete filterValues?.limit;
  const defaultIndex = Object.keys(filterValues).length > 0 ? 0 : -1;
  return defaultIndex;
};

// feedback rating format
export const formatRating = (value: number) => {
  if (value === null || value === undefined || isNaN(value)) return null;
  if (value === 10) return '10';
  const formattedRating = Number(value).toLocaleString('de-DE', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  return formattedRating;
};

export const LanguageOptionLocalization = () => {
  const LanguageOptions: LanguageOptions[] = [
    { value: 'en', label: strings.en },
    { value: 'pl', label: strings.pl },
    { value: 'da', label: strings.da },
    { value: 'zh', label: strings.zh },
  ];
  return LanguageOptions;
};

// Redirect to respective booking page from pms
export const identifyService = (payable_id: number, payable_type: string) => {
  if (payable_type === 'service_reservation') {
    history.push(
      routes.bms.booking.details.replace(':id', payable_id.toString())
    );
  } else if (payable_type === 'apartment_reservation') {
    history.push(
      routes.bms.reservation.details.replace(':id', payable_id.toString())
    );
  } else if (payable_type === 'ferry_booking') {
    history.push(
      routes.bms.ferryBooking.details.replace(':id', payable_id.toString())
    );
  }
};

export const FeedbackQuestionPublishOptions = () => {
  const options: PublishOptionType[] = [
    { value: 'start_page', label: strings.start_page },
    { value: 'object', label: strings.object },
    { value: 'service', label: strings.service },
  ];
  return options;
};

/**
 * Checks if the customer is deleted or not
 * @param {number} customerId
 * @param {any} customerDetail
 * @param {string} nameKey
 * @returns {{boolean, string}} { isCustomerDeleted, customerName }
 */
export const getCustomerOrFail = (
  customerId: number,
  customerDetail: Record<string, string> | null,
  nameKey = 'name'
) => {
  const isCustomerDeleted = !!customerId && !customerDetail;
  const customerName = isCustomerDeleted
    ? `(${strings.deleted_customer})`
    : (customerDetail?.[nameKey] as string);

  return { isCustomerDeleted, customerName };
};

export const getFileExtension = (fileName: string) => {
  const lastIndexOfDot = fileName.lastIndexOf('.');
  return fileName.substring(lastIndexOfDot);
};

export const formatFileNameDMS = (file: DMSDocumentSchema) => {
  return (
    file.name + '_' + DateFormat(new Date()) + getFileExtension(file.file_name)
  );
};

export const fallbackGermanValue = (
  lang: LanguageAbbreviationType,
  value: string | undefined
) => {
  return lang === 'de' ? value : undefined;
};

const SUPPORTED_EXTENSIONS: string[] = ['pdf', 'jpg', 'jpeg', 'png', 'gif'];

export function determineContentType(text: string): MenuType {
  const fileExtension = text?.split('.').pop()?.toLowerCase();
  if (SUPPORTED_EXTENSIONS.includes(fileExtension || '')) {
    return MenuType.FILE;
  }
  return MenuType.LINK;
}
