import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { ServiceSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  schemeData: any;
  schemeType: string;
  currentType: string;
}

const SchemeFormByQty: React.FC<Props> = (props) => {
  const { schemeData, schemeType, currentType } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    getValues,
  } = useFormContext<ServiceSchema>();

  useEffect(() => {
    if (!schemeData || schemeType !== currentType) return;
    setValue('unit_price', schemeData?.unit_price ? schemeData?.unit_price : 0);
  }, [schemeData, setValue, schemeType, currentType]);

  const schemeByQuantity = () => {
    return (
      <>
        <Grid
          gap="4"
          templateColumns="repeat(1, 1fr)"
          flex="1"
          borderWidth="1px"
          borderRadius="lg"
          p="4"
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.unit_price}>
              <FormLabel>{strings.unit_price} (Cent)</FormLabel>
              <Input
                type="number"
                defaultValue={0}
                placeholder={strings.enter_value_in_cents}
                {...register('unit_price', {
                  required: strings.required,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.unit_price && errors?.unit_price?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </>
    );
  };

  return schemeByQuantity();
};

export default SchemeFormByQty;
