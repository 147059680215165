import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PMSPaypalResource from 'api/pms_paypal';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PaypalListItem from 'components/pms/PMSPaypalListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  PMS_STATUS_OPTIONS,
} from 'constants/common';
import routes from 'constants/routes';
import { DataWrapperSchema, PaymentSchema } from 'constants/schema';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
  date_time: string;
  paypal_address: string;
  status: string;
  amount: string;
}

const PaypalList: React.FC = () => {
  const pmsPaypalAPI = new PMSPaypalResource();
  const [documentCreatedAt, setDocumentCreatedAt] = useState<any>();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    date_time: searchValues.date_time ?? '',
    paypal_address: searchValues.paypal_address ?? '',
    status: searchValues.status ?? '',
    amount: searchValues.amount ?? '',
  });

  const pmsPaypalList = useQuery<DataWrapperSchema<PaymentSchema[]>>(
    [
      'pmsPaypalList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        date_time: filterParams.date_time,
        paypal_address: filterParams.paypal_address,
        status: filterParams.status,
        amount: filterParams.amount,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.date_time)
        queryParams.date_time = filterParams.date_time;
      if (filterParams.paypal_address)
        queryParams.email = filterParams.paypal_address;
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.amount) queryParams.amount = filterParams.amount;

      const response = await pmsPaypalAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
      date_time: documentCreatedAt
        ? moment(documentCreatedAt).format('YYYY-MM-DD')
        : prevState.date_time,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  useEffect(() => {
    if (documentCreatedAt) {
      setFilterParams((prevState) => ({
        ...prevState,
        date_time: moment(documentCreatedAt).format('YYYY-MM-DD'),
      }));
    }
  }, [documentCreatedAt]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleReset = () => {
    setDocumentCreatedAt('');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      date_time: '',
      paypal_address: '',
      status: '',
      amount: '',
    }));
    history.push(routes.pms.paypal.list);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.pms} | {strings.all_paypals}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.pms.paypal.list}>
              {strings.pms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.pms.paypal.list}>
              {strings.all_paypals}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.paypal}
          </Heading>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          defaultIndex={[defaultIndex]}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="16px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack>
                <Stack sx={wrapperStyles}>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Stack direction="row" spacing="4" align="end">
                      <Grid
                        gap="4"
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(4, 1fr)',
                        ]}
                        flex="1">
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.search}</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={<BiSearch />}
                                color="gray.400"
                              />
                              <Input
                                name="keyword"
                                value={filterParams.keyword}
                                type="text"
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.date_time}</FormLabel>
                            <InputGroup>
                              <InputRightElement
                                children={<BiCalendar />}
                                color="gray.400"
                              />
                              <ReactDatePicker
                                placeholderText={strings.date_time}
                                id="date_time"
                                dateFormat="yyyy-MM-dd"
                                name="date_time"
                                value={filterParams.date_time}
                                selected={documentCreatedAt}
                                customInput={
                                  <CustomDateInput value={documentCreatedAt} />
                                }
                                onChange={(date: Date) =>
                                  setDocumentCreatedAt(date)
                                }
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.paypal_address}</FormLabel>
                            <Input
                              name="paypal_address"
                              value={filterParams.paypal_address}
                              type="email"
                              placeholder={strings.paypal_address}
                              onChange={handleInputChange}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.status}</FormLabel>
                            <Select
                              name="status"
                              value={filterParams.status}
                              placeholder={strings.status}
                              rounded="sm"
                              onChange={handleInputChange}>
                              {PMS_STATUS_OPTIONS?.map((status) => {
                                return (
                                  <option
                                    key={status.value}
                                    value={status.value}>
                                    {strings[status.text]}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>
                              {strings.amount}({strings.euro_symbol})
                            </FormLabel>
                            <Input
                              name="amount"
                              value={filterParams.amount}
                              type="text"
                              placeholder={strings.amount}
                              onChange={handleInputChange}
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </Stack>
                    <Stack direction="row" spacing="4" pt="4">
                      <Button
                        colorScheme="primary"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.customer_name}</Th>
                  <Th isNumeric>{strings.date_time}</Th>
                  <Th>{strings.paypal_address}</Th>
                  <Th>{strings.status}</Th>
                  <Th isNumeric>
                    {strings.amount}({strings.euro_symbol})
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {!pmsPaypalList.isLoading &&
                  pmsPaypalList?.data?.data?.map((pmsPaypalData, index) => (
                    <PaypalListItem
                      key={pmsPaypalData.id}
                      index={startingSN + index}
                      pmsPaypalData={pmsPaypalData}
                    />
                  ))}

                {pmsPaypalList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={pmsPaypalList}
        />
      </Stack>
    </>
  );
};

export default PaypalList;
