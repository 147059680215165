import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import BookingTerminalResource from 'api/booking_terminal';
import { CheckInOut } from 'assets/icons';
import { strings } from 'config/localization';
import { MIRROR_SECOND_SCREEN, SECOND_SCREEN_CHANNEL } from 'constants/common';
import routes from 'constants/routes';
import { CheckInOutListSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { FiCheckSquare } from 'react-icons/fi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

interface Props {
  isCheckIn: boolean;
  index: number;
  data: CheckInOutListSchema;
}

const CheckInOutListItem: React.FC<Props> = (props) => {
  const { isCheckIn, index, data } = props;
  const {
    apartment_name,
    booking_number,
    fromdate,
    tilldate,
    customer_name,
    reservation_id,
    check_in_at,
    check_out_at,
  } = data;

  const toast = useToast();
  const router = useHistory();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const BookingTerminalAPI = new BookingTerminalResource();
  const broadCastAPI = new BroadcastChannel(SECOND_SCREEN_CHANNEL);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const checkInOutLabel = isCheckIn ? strings.check_in : strings.check_out;

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    broadCastAPI.postMessage({
      name: MIRROR_SECOND_SCREEN,
      value: false,
    });
  };

  const handleMirrorDetails = () => {
    broadCastAPI.postMessage({
      name: MIRROR_SECOND_SCREEN,
      value: true,
    });
    router.push(`/bms/check-in-out/details/${reservation_id}` + search);
  };

  const checkOutMutation = useMutation(
    () => BookingTerminalAPI.updateCheckOut(reservation_id.toString()),
    {
      onSuccess: () => {
        toast({
          title: `${strings.check_out} ${strings.successful}.`,
          status: 'success',
          isClosable: true,
        });
        setIsModalOpen(false);
        queryClient.invalidateQueries(`checkOutList`);
      },
      onError: () => {
        toast({
          title: `${strings.check_out} ${strings.failed}.`,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleCheckOutConfirm = () => {
    checkOutMutation.mutate();
  };

  const isCheckedInAlready = isCheckIn && !!check_in_at;
  const isCheckedOutAlready = !isCheckIn && !!check_out_at;

  return (
    <Tr>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={routes.bms.checkInOut.details.replace(
            ':id',
            reservation_id.toString() + search
          )}>
          {booking_number}
        </RouterLink>
      </Td>
      <Td>
        <Stack direction="row" spacing={2}>
          <Box>{customer_name}</Box>
          {(isCheckedInAlready || isCheckedOutAlready) && (
            <Icon as={FiCheckSquare} w="4" h="4" color="green.400" />
          )}
        </Stack>
      </Td>
      <Td>{apartment_name}</Td>
      <Td>{fromdate}</Td>
      <Td>{tilldate}</Td>
      <Td>
        {!isCheckedOutAlready ? (
          <Tooltip hasArrow label={checkInOutLabel}>
            <IconButton
              icon={<FaRegCalendarCheck />}
              variant="link"
              aria-label={checkInOutLabel}
              color="blue.400"
              minW="6"
              onClick={handleModalOpen}
            />
          </Tooltip>
        ) : (
          '-'
        )}
        <Modal isOpen={isModalOpen} isCentered onClose={handleModalClose}>
          <ModalOverlay />
          {isCheckIn && (
            <ModalContent maxW="40rem">
              <ModalHeader>{strings.did_you_choose_right_guest}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack spacing={3}>
                  <Alert status="error" mb={2} fontSize={12}>
                    <AlertIcon />
                    {strings.check_in_warning_message}
                  </Alert>
                  <Box>
                    {strings.display_booking_details_confirmation_question}
                  </Box>
                  <Stack direction="row" fontWeight={500} spacing={10}>
                    <Box>
                      {strings.booking_number}: {booking_number}
                    </Box>
                    <Box>
                      {strings.customer}: {customer_name}
                    </Box>
                  </Stack>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup w="100%">
                  <Button
                    w="50%"
                    colorScheme="primary"
                    onClick={handleMirrorDetails}>
                    {strings.mirror_booking_details}
                  </Button>
                  <Button w="50%" variant="outline" onClick={handleModalClose}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          )}
          {!isCheckIn && (
            <ModalContent textAlign="center">
              <ModalHeader>
                {strings.guest} {strings.check_out}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box fontSize="xl">
                  {strings.formatString(
                    strings.check_out_confirmation_question,
                    <span
                      style={{
                        fontWeight: 600,
                      }}>
                      {customer_name}
                    </span>
                  )}
                </Box>
              </ModalBody>
              <ModalFooter marginInline="auto">
                <Button
                  colorScheme="primary"
                  isLoading={checkOutMutation.isLoading}
                  onClick={handleCheckOutConfirm}>
                  <Image src={CheckInOut} w={6} mr={1} />
                  {strings.check_out}
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </Modal>
      </Td>
    </Tr>
  );
};

export default CheckInOutListItem;
