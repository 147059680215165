import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import {
  reactSelectTicketContactStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import CustomReactDatePicker from 'components/common/CustomReactDatePicker';
import CustomSelect from 'components/common/CustomSelect';
import CustomerCustomSelect from 'components/ticket/CustomerCustomSelect';
import { strings } from 'config/localization';
import {
  EXTERNAL_COMPANY_SEARCH_API,
  RESPONSIBLE_PERSON_API,
} from 'constants/common';
import routes from 'constants/routes';
import {
  AppointmentTodoExport,
  ExternalCompanySchema,
  ResponsibleType,
} from 'constants/schema';
import useWordSearch from 'hooks/useWordSearch';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import { cleanData } from 'utils';
import { getFormattedRangeDate } from 'utils/DateFormat';

const PRIORITIES = ['low', 'medium', 'high', 'urgent'];
const SOURCES = ['Manually', 'Chat', 'Feedback'];
const STATUSES = ['open', 'in-progress', 'done', 'closed'];
const TYPES = ['normal', 'repeating', 'billable'];
const ORDER_BY = ['due_date', 'priority', 'createdAt'];
const ORDER = ['asc', 'desc'];

interface Props {
  handleAdvancedSearch: any;
  isStatus?: boolean;
  filter: any;
  handleResetSearch?: () => void;
  exportAppointmentTodo?: AppointmentTodoExport;
}

const customerQueryParams = {
  is_owner: false,
};

const TicketSearch: React.FC<Props> = ({ isStatus = true, ...props }) => {
  const {
    handleAdvancedSearch,
    filter,
    exportAppointmentTodo,
    handleResetSearch,
  } = props;

  const history = useHistory();

  const isExportAppointmentEnabled = !!exportAppointmentTodo;
  const isExportAppointmentForAssignee = exportAppointmentTodo === 'assignee';
  const isExportAppointmentForExternalCompany =
    exportAppointmentTodo === 'externalCompany';

  const InitialValue = {
    title: '',
    status: '',
    priority: '',
    assigned_user_id: '',
    assigned_name: '',
    apartment_id: '',
    customer_id: '',
    customer: '',
    external_company_id: '',
    company: '',
    source: '',
    type: '',
    order_by: '',
    order: '',
    appointment: '',
    responsible_person_id: '',
    responsible_person_name: '',
  };

  const [startDate, setStartDate] = useState<Date | null>(
    filter.created_at_start
  );
  const [endDate, setEndDate] = useState<Date | null>(filter.created_at_end);
  const [dueDateStart, setDueDateStart] = useState<Date | null>(
    filter.due_date_start
  );
  const [dueDateEnd, setDueDateEnd] = useState<Date | null>(
    filter.due_date_end
  );
  const [appointmentDateStart, setAppointmentDateStart] = useState<Date | null>(
    filter.appointment_date_from
  );
  const [appointmentDateEnd, setAppointmentDateEnd] = useState<Date | null>(
    filter.appointment_date_to
  );
  const [companyInputKey, setCompanyInputKey] = useState<string>(
    filter.company
  );

  const [formValue, setFormValue] = useState<any>(filter);

  let responsiblePerson: ResponsibleType | null =
    filter?.responsible_person_id && filter?.responsible_person_name
      ? {
          id: filter.responsible_person_id,
          name: filter.responsible_person_name,
        }
      : null;

  let firstRender = useRef(true);

  const ticketAPI = new TicketResource();

  const {
    loading: companyListLoading,
    result: companyQuery,
    setQuery: setCompanyQuery,
  } = useWordSearch(`${EXTERNAL_COMPANY_SEARCH_API}`);

  const apartmentList = useQuery([`apartmentList`], () =>
    ticketAPI.getApartmentList().then((res) => res.data)
  );

  const userList = useQuery('userList', () =>
    ticketAPI.getUserList().then((res) => res.data.data)
  );

  const companyList = companyQuery?.data;
  const companyOptions = companyList?.map((company: ExternalCompanySchema) => ({
    label: company.name,
    value: company.id,
  }));

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const finalData: any = cleanData(formValue);
    if (startDate) {
      finalData.created_at_start = startDate;
    }
    if (endDate) finalData.created_at_end = endDate;
    if (dueDateStart) finalData.due_date_start = dueDateStart;
    if (dueDateEnd) finalData.due_date_end = dueDateEnd;
    if (appointmentDateStart)
      finalData.appointment_date_from = appointmentDateStart;
    if (appointmentDateEnd) finalData.appointment_date_to = appointmentDateEnd;
    if (finalData.customer_id) finalData.customer_id = finalData.customer_id;
    if (finalData.external_company_id)
      finalData.external_company_id = finalData.external_company_id.value;
    handleAdvancedSearch(finalData);
  }, [
    startDate,
    endDate,
    formValue,
    handleAdvancedSearch,
    dueDateStart,
    dueDateEnd,
    appointmentDateStart,
    appointmentDateEnd,
  ]);

  const handleReset = () => {
    setFormValue(InitialValue);
    setStartDate(null);
    setEndDate(null);
    setDueDateStart(null);
    setDueDateEnd(null);
    setAppointmentDateStart(null);
    setAppointmentDateEnd(null);
    setCompanyInputKey('');
    responsiblePerson = null;
    let selectRoute = '';
    if (history.location.pathname.includes(routes.ticket.task.board))
      selectRoute = routes.ticket.task.board;
    if (history.location.pathname.includes(routes.ticket.list.default))
      selectRoute = routes.ticket.list.default;

    if (handleAdvancedSearch !== undefined) handleAdvancedSearch({});
    history.push(selectRoute);

    handleResetSearch && handleResetSearch();
  };

  const handleCompanyInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setCompanyInputKey(value);
      setCompanyQuery({ keyword: value });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCustomerChange = useCallback((value: number, name: string) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      customer_id: value,
      customer: name,
    }));
  }, []);

  const handleExternalCompanyChange = (value: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      external_company_id: value,
      company: value.label,
    }));
  };

  const handleAssigneeChange = (e: any) => {
    const { value } = e.target;
    const assigneeName = userList?.data?.find(
      (user: any) => user.id === Number(value)
    );
    setFormValue((prevState: any) => ({
      ...prevState,
      assigned_user_id: value,
      assignee_name: assigneeName?.name,
    }));
  };

  const handleCreatedDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleDueDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setDueDateStart(start);
    setDueDateEnd(end);
  };

  const handleAppointmentDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setAppointmentDateStart(start);
    setAppointmentDateEnd(end);
  };

  const onResponsibilitiesChange = (e: ResponsibleType) => {
    responsiblePerson = e;
    setFormValue((prevState: any) => ({
      ...prevState,
      responsible_person_id: e.id,
      responsible_person_name: e.name,
    }));
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="row" spacing="4">
            <Grid
              gap="4"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1">
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.ticket_name}</FormLabel>
                  <Input
                    id="title"
                    type="text"
                    name="title"
                    value={formValue.title}
                    onChange={handleInputChange}
                    placeholder={strings.ticket_name}
                    isDisabled={isExportAppointmentEnabled}
                    tabIndex={1}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.appointment}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <CustomReactDatePicker
                      placeholderText={strings.select_date}
                      selected={appointmentDateStart}
                      startDate={appointmentDateStart}
                      endDate={appointmentDateEnd}
                      value={getFormattedRangeDate(
                        appointmentDateStart,
                        appointmentDateEnd
                      )}
                      onChange={handleAppointmentDateChange}
                      tabIndex={5}
                      selectRange={true}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentForAssignee}>
                  <FormLabel>{strings.external_company}</FormLabel>
                  <ReactSelect
                    id="external_company_id"
                    name="external_company_id"
                    placeholder={strings.select_external_company}
                    options={companyOptions}
                    value={
                      formValue.external_company_id
                        ? formValue.external_company_id
                        : null
                    }
                    styles={reactSelectTicketContactStyles}
                    onInputChange={handleCompanyInputChange}
                    inputValue={companyInputKey}
                    isLoading={companyListLoading}
                    isDisabled={isExportAppointmentForAssignee}
                    onChange={handleExternalCompanyChange}
                    allowClear
                    isSearchable
                    tabIndex="8"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.customer}</FormLabel>
                  <CustomerCustomSelect
                    id="customer_id"
                    placeholder={strings.select_customer}
                    value={formValue.customer ? formValue.customer : null}
                    onChange={handleCustomerChange}
                    parentQueries={customerQueryParams}
                    isDisabled={isExportAppointmentEnabled}
                    tabIndex={12}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.apartmant}</FormLabel>
                  <Select
                    placeholder={strings.select_apartment}
                    id="apartment_id"
                    name="apartment_id"
                    rounded="sm"
                    value={formValue.apartment_id}
                    isDisabled={isExportAppointmentEnabled}
                    tabIndex={2}
                    onChange={handleInputChange}>
                    {apartmentList?.data?.data?.map((apartment: any) => {
                      return (
                        <option key={apartment.id} value={apartment.id}>
                          {apartment.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.due_date}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <CustomReactDatePicker
                      placeholderText={strings.select_date}
                      selected={dueDateStart}
                      startDate={dueDateStart}
                      endDate={dueDateEnd}
                      value={getFormattedRangeDate(dueDateStart, dueDateEnd)}
                      onChange={handleDueDateChange}
                      tabIndex={6}
                      selectRange={true}
                      isDisabled={isExportAppointmentEnabled}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentForExternalCompany}>
                  <FormLabel>{strings.assignee}</FormLabel>
                  <Select
                    placeholder={strings.select_assignee}
                    id="assigned_user_id"
                    name="assigned_user_id"
                    value={formValue.assigned_user_id}
                    rounded="sm"
                    isDisabled={isExportAppointmentForExternalCompany}
                    onChange={handleAssigneeChange}
                    tabIndex={9}>
                    {userList?.data?.map((user: any) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.source}</FormLabel>
                  <Select
                    id="source"
                    rounded="sm"
                    name="source"
                    value={formValue.source}
                    placeholder={strings.select_source}
                    isDisabled={isExportAppointmentEnabled}
                    onChange={handleInputChange}
                    tabIndex={13}>
                    {SOURCES.map((source: any) => {
                      return (
                        <option key={source} value={source}>
                          {strings.getString(source.toLowerCase())}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.priority}</FormLabel>
                  <Select
                    id="priority"
                    name="priority"
                    rounded="sm"
                    placeholder={strings.select_priority}
                    value={formValue.priority}
                    isDisabled={isExportAppointmentEnabled}
                    tabIndex={3}
                    onChange={handleInputChange}>
                    {PRIORITIES.map((priority: any) => {
                      return (
                        <option key={priority} value={priority}>
                          {strings
                            .getString(priority.split('-').join(''))
                            .toUpperCase()}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.created_at}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiCalendar />}
                      color="gray.400"
                    />
                    <CustomReactDatePicker
                      placeholderText={strings.select_date}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={handleCreatedDateChange}
                      tabIndex={7}
                      selectRange={true}
                      value={getFormattedRangeDate(startDate, endDate)}
                      isDisabled={isExportAppointmentEnabled}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.order_by}</FormLabel>
                  <Flex>
                    <Select
                      id="order_by"
                      rounded="sm"
                      name="order_by"
                      placeholder={strings.select_sort}
                      value={formValue.order_by}
                      isDisabled={isExportAppointmentEnabled}
                      onChange={handleInputChange}
                      tabIndex={10}>
                      {ORDER_BY.map((orderBy: any) => {
                        return (
                          <option key={orderBy} value={orderBy}>
                            {strings.getString(orderBy.toLowerCase())}
                          </option>
                        );
                      })}
                    </Select>
                    <Select
                      id="order"
                      rounded="sm"
                      name="order"
                      placeholder={strings.order}
                      value={formValue.order}
                      isDisabled={isExportAppointmentEnabled}
                      onChange={handleInputChange}
                      tabIndex={11}>
                      {ORDER.map((order: any) => {
                        return (
                          <option key={order} value={order}>
                            {strings.getString(order.toLowerCase())}
                          </option>
                        );
                      })}
                    </Select>
                  </Flex>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.type}</FormLabel>
                  <Select
                    id="type"
                    rounded="sm"
                    name="type"
                    value={formValue.type}
                    placeholder={strings.select_type}
                    isDisabled={isExportAppointmentEnabled}
                    onChange={handleInputChange}
                    tabIndex={14}>
                    {TYPES.map((type: any) => {
                      return (
                        <option key={type} value={type}>
                          {strings.getString(type.toLowerCase())}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              {isStatus && (
                <GridItem>
                  <FormControl isDisabled={isExportAppointmentEnabled}>
                    <FormLabel>{strings.status}</FormLabel>
                    <Select
                      id="status"
                      name="status"
                      rounded="sm"
                      value={formValue.status}
                      placeholder={strings.select_status}
                      isDisabled={isExportAppointmentEnabled}
                      onChange={handleInputChange}
                      tabIndex={4}>
                      {STATUSES.map((status: any) => {
                        return (
                          <option key={status} value={status}>
                            {strings
                              .getString(status.split('-').join(''))
                              .toUpperCase()}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              )}
              <GridItem>
                <FormControl isDisabled={isExportAppointmentEnabled}>
                  <FormLabel>{strings.person_in_charge}</FormLabel>
                  <CustomSelect
                    placeholder={strings.select_responsible_person}
                    value={responsiblePerson}
                    onChange={onResponsibilitiesChange}
                    SEARCH_API={RESPONSIBLE_PERSON_API}
                    limit={30}
                    replaceNameInOption="name"
                    disable={isExportAppointmentEnabled}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack direction="row" spacing="4" pt="4">
            <Button
              colorScheme="primary"
              onClick={handleReset}
              variant="outline">
              {strings.reset_filter}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default TicketSearch;
