import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { formatPriceWithLabel } from 'utils';
import FerryBookingTripItemInfo from './FerryBookingTripItemInfo';
interface Props {
  ferryBookingData: any;
}

const FerryBookingInfo: React.FC<Props> = (props) => {
  const { ferryBookingData } = props;

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.booking_detail}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_number}</FormLabel>
              <Box sx={infoStyles}>
                {ferryBookingData?.client_booking_number || 0}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>
                {strings.booking_amount} (&euro;)
              </FormLabel>
              <Box sx={infoStyles}>
                {formatPriceWithLabel(ferryBookingData?.amount || 0)}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>

      {/* Trip Detail */}
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.trip_detail}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <FerryBookingTripItemInfo
            tripDetail={ferryBookingData?.trip_details[0]}
          />
        </Grid>

        <Divider />

        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <FerryBookingTripItemInfo
            tripDetail={ferryBookingData?.trip_details[1]}
          />
        </Grid>
      </Stack>

      {/* Vehicle Detail */}
      {ferryBookingData?.vehicles && ferryBookingData?.vehicles.length > 0 && (
        <>
          <Flex justify="space-between">
            <Heading size="md" textTransform="capitalize">
              {strings.vehicle_detail}
            </Heading>
          </Flex>

          <Stack sx={wrapperStyles}>
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.vehicle_type}</FormLabel>
                  <Box sx={infoStyles}>
                    {ferryBookingData?.vehicles[0]?.type || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.licence_plate}
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {ferryBookingData?.vehicles[0]?.licence_plate || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.length}</FormLabel>
                  <Box sx={infoStyles}>
                    {ferryBookingData?.vehicles[0]?.length_cm | 0} {strings.cm}
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </>
      )}

      {/* Customer Detail */}
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.customer_detail}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer_name}</FormLabel>
              <Box sx={infoStyles}>
                {ferryBookingData?.customer_info?.first_name +
                  ' ' +
                  ferryBookingData?.customer_info?.last_name || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer_contact}</FormLabel>
              <Box sx={infoStyles}>
                {ferryBookingData?.customer_info?.telephone || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.customer_email}</FormLabel>
              <Box sx={infoStyles}>
                {ferryBookingData?.customer_info?.email || '-'}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};
export default FerryBookingInfo;
