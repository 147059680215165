import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { deteleIconStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { BiAddToQueue, BiCabinet, BiTrash } from 'react-icons/bi';
import RoomNameEdit from './RoomNameEdit';
import WarehouseShelf from './WarehouseShelf';

export interface RoomsAccordionProps {
  roomsInput: any;
  viewOnly: boolean;
  showQR: boolean;
  warehouseName?: string;
  warehouseId?: any;
  handleDeleteEntity?: (
    entity: string,
    detail: {
      generatedRoomId: string;
      rackName?: string;
      shelfName?: string;
      id?: number; // roomId/rackId/shelfId based on entity (only on edit mode)
    }
  ) => void;
  handleAddShelf?: (roomId: string, rackName: string) => void;
  handleAddRack?: (roomId: string) => void;
  handleAddRoom?: () => void;
  handleRoomNameChange?: (value: string, generatedRoomId: string) => void;
}

const RoomsAccordion: React.FC<RoomsAccordionProps> = (props) => {
  const {
    roomsInput,
    viewOnly,
    showQR,
    warehouseName,
    warehouseId,
    handleDeleteEntity,
    handleAddShelf,
    handleAddRack,
    handleAddRoom,
    handleRoomNameChange,
  } = props;

  const submitEditableInput = (value: string, generatedRoomId: string) => {
    if (handleRoomNameChange !== undefined)
      handleRoomNameChange(value, generatedRoomId);
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={3}>
        <Text fontWeight="medium">{strings.rooms}</Text>
        {!viewOnly && (
          <ButtonGroup>
            <Button
              colorScheme="primary"
              type="button"
              isFullWidth={false}
              onClick={() => handleAddRoom !== undefined && handleAddRoom()}>
              {strings.add_room}
            </Button>
          </ButtonGroup>
        )}
      </Flex>
      <Accordion
        allowToggle
        allowMultiple
        boxShadow="xs"
        defaultIndex={[0]}
        overflow="auto">
        {roomsInput.map((room: any, roomIndex: number) => (
          <AccordionItem key={roomIndex}>
            <Stack direction="row" bg="gray.50">
              <Flex width="15rem" alignItems="center" ml={4} py={4}>
                {!viewOnly ? (
                  <FormControl>
                    <RoomNameEdit
                      room={room}
                      submitEditableInput={submitEditableInput}
                      viewOnly={viewOnly}
                    />
                  </FormControl>
                ) : (
                  <Text fontWeight="semibold">{room.name}</Text>
                )}
              </Flex>
              <AccordionButton _hover={{ bg: 'gray.50' }}>
                <Flex
                  width="100%"
                  justifyContent="flex-end"
                  alignItems="center">
                  {!viewOnly && roomsInput.length !== 1 && (
                    <Tooltip
                      hasArrow
                      label={strings.delete_room}
                      fontSize="sm"
                      bg="red.300"
                      color="white">
                      <IconButton
                        icon={<BiTrash />}
                        variant="link"
                        aria-label={strings.delete_room}
                        sx={deteleIconStyles}
                        onClick={() =>
                          handleDeleteEntity !== undefined &&
                          handleDeleteEntity(strings.delete_room, {
                            generatedRoomId: room.generatedId,
                            id: room.id,
                          })
                        }
                      />
                    </Tooltip>
                  )}
                </Flex>
                <AccordionIcon />
              </AccordionButton>
            </Stack>
            <AccordionPanel pt={6} pb={4}>
              <Text color="gray.600" fontWeight="semibold">
                {strings.racks}
              </Text>
              {room.racks.map((rack: any, rackIndex: number) => (
                <Box key={rackIndex} mt={6}>
                  <Flex alignItems="center">
                    <Text>
                      {strings.rack} {rack.name}
                    </Text>
                    {!viewOnly && room.racks.length !== 1 && (
                      <Tooltip
                        hasArrow
                        label={strings.delete_rack}
                        fontSize="sm"
                        bg="red.300"
                        color="white">
                        <IconButton
                          icon={<BiTrash />}
                          variant="link"
                          aria-label={strings.delete_rack}
                          sx={deteleIconStyles}
                          ml={6}
                          onClick={() =>
                            handleDeleteEntity !== undefined &&
                            handleDeleteEntity(strings.delete_rack, {
                              generatedRoomId: room.generatedId,
                              rackName: rack.name,
                              id: rack.id,
                            })
                          }
                        />
                      </Tooltip>
                    )}
                  </Flex>
                  <Grid
                    templateColumns={[
                      'repeat(2, 1fr)',
                      'repeat(2, 1fr)',
                      'repeat(4, 1fr)',
                    ]}
                    columnGap={[8, 20]}
                    rowGap={5}
                    p={3}
                    mt={3}
                    boxShadow="xs"
                    rounded="sm"
                    overflow="auto">
                    {rack.shelves.map((shelf: any, shelfIndex: number) => (
                      <WarehouseShelf
                        key={shelfIndex}
                        warehouseId={warehouseId}
                        warehouseName={warehouseName}
                        shelf={shelf}
                        viewOnly={viewOnly}
                        showQR={showQR}
                        rack={rack}
                        room={room}
                        handleDeleteEntity={handleDeleteEntity}
                      />
                    ))}
                    {!viewOnly && (
                      <Button
                        type="button"
                        variant="link"
                        p={3}
                        color="gray.800"
                        justifyContent="flex-start"
                        onClick={() =>
                          handleAddShelf !== undefined &&
                          handleAddShelf(room.generatedId, rack.name)
                        }>
                        <Icon as={BiAddToQueue} w="4" h="4" mr={2} />
                        <Text fontSize="sm">{strings.add_shelf}</Text>
                      </Button>
                    )}
                  </Grid>
                </Box>
              ))}

              {!viewOnly && (
                <Button
                  type="button"
                  variant="link"
                  mt={4}
                  p={3}
                  color="gray.800"
                  justifyContent="flex-start"
                  onClick={() =>
                    handleAddRack !== undefined &&
                    handleAddRack(room.generatedId)
                  }>
                  <Icon as={BiCabinet} w="4" h="4" mr={2} />
                  <Text fontSize="sm">{strings.add_rack}</Text>
                </Button>
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default RoomsAccordion;
