import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { strings } from 'config/localization';
import React, { useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface Props {
  onClose: any;
}

const Enable2FA: React.FC<Props> = (props) => {
  const { onClose } = props;

  const queryClient = useQueryClient();
  const userAPI = new UserResource();
  const toast = useToast();

  const [error, setError] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Handle User status changes
   */
  const { isLoading, data } = useQuery(
    'generateQR',
    () => userAPI.generateQRCode(),
    {
      onSuccess: (res: any) => {
        return res?.data;
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const enable2FA = useMutation((data: any) => userAPI.enable2FA(data), {
    onSuccess: (res: any) => {
      if (res?.data?.success) {
        onClose();
        toast({
          title: strings.FA_successfully,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('user-auth');
      } else {
        setError(strings.authenticator_code_error);
      }
    },
    onError: () => {
      setError(strings.invalid_data);
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    const authCode = inputRef.current?.value;

    if (!authCode) {
      setError(strings.required_authenticator_code);
      inputRef.current?.focus();
      return;
    }
    const data = {
      'verify-code': authCode,
    };
    enable2FA.mutate(data);
  };

  return (
    <ModalContent>
      {!isLoading && (
        <form onSubmit={onSubmit}>
          <ModalHeader fontSize="1rem" w="95%">
            {strings.fa_message}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack dir="column" justifyContent="space-between" spacing="4">
              <Center>
                <Stack
                  maxWidth="200px"
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="md">
                  <Image width="100%" src={data?.data?.qr_code} alt="QR" />
                </Stack>
              </Center>
              <Stack>
                <Grid
                  gap="4"
                  templateColumns={['repeat(1, 1fr)']}
                  flex="1"
                  w="100%">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.authenticator_code}</FormLabel>
                      <Input
                        type="number"
                        placeholder={strings.authenticator_code}
                        ref={inputRef}
                        autoFocus
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Stack>
              {error && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {error}
                </Alert>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="primary" type="submit">
                {strings.enable_2FA}
              </Button>
              <Button variant="outline" onClick={onClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      )}
    </ModalContent>
  );
};

export default Enable2FA;
