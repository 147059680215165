import {
  AlertDialog,
  AlertDialogOverlay,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import KeyLogAPI from 'api/keyLogs';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import GetKeyId from 'components/keys/GetKeyId';
import KeysListIterm from 'components/keys/KeysListIterm';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  KEY_APARTMENT_SEARCH_API,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useWordSearch from 'hooks/useWordSearch';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiBarcodeReader, BiCalendar, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  capitalizeFirstLetter,
  changeURL,
  getStartingSerialNumber,
  KeyOptionLocalization,
} from 'utils';
import {
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  apartmentName: string;
  apartmentId: string | null;
  status: string[];
  transfer_date_from: Date | null;
  transfer_date_to: Date | null;
}

const KeyLogs: React.FC = () => {
  const keyLogAPI = new KeyLogAPI();
  const statusRef = useRef<any>(null);
  const cancelRef = useRef(null);
  const history = useHistory();
  const KeyStatusOptions = KeyOptionLocalization();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkKeyLogsPermission = useIsPermitted([
    PermissionRequest['read:keylog'],
  ]);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const [scannedKeyId, setScannedKeyId] = useState<null | string>(null);
  const searchValues: any = {};

  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const transferDateFrom = Number(searchValues.transfer_date_from);
  const transferDateTo = Number(searchValues.transfer_date_to);

  const apartmentValue =
    searchValues.apartmentId && searchValues.apartmentName
      ? {
          value: searchValues.apartmentId,
          label: searchValues.apartmentName,
        }
      : null;

  const status_url: string[] = searchValues?.status?.split(',') ?? [];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    apartmentName: searchValues.apartmentName ?? null,
    apartmentId: searchValues.apartmentId ?? '',
    status: status_url ?? [],
    transfer_date_from: transferDateFrom ? new Date(transferDateFrom) : null,
    transfer_date_to: transferDateTo ? new Date(transferDateTo) : null,
  });
  const [apartmentInputKey, setApartmentInputKey] = useState<string>('');

  const status_value = filterParams?.status?.map((item: any) => {
    const newLabel = capitalizeFirstLetter(item);
    return {
      value: item,
      label: newLabel,
    };
  });

  const queryList = useQuery(
    [
      'keyLogsQuery',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        apartmentId: filterParams.apartmentId,
        transferDateFrom: filterParams.transfer_date_from,
        transferDateTo: filterParams.transfer_date_to,
        status: filterParams.status,
      },
    ],
    async () => {
      const {
        keyword,
        apartmentId,
        transfer_date_from,
        transfer_date_to,
        status,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.keyword = keyword;
      if (apartmentId) queryParams.apartmentId = apartmentId;
      if (status) queryParams.status = status;
      if (transfer_date_from)
        queryParams.transferDateFrom =
          getUTCDateRangeStartDateTime(transfer_date_from);
      if (transfer_date_to)
        queryParams.transferDateTo =
          getUTCDateRangeEndDateTime(transfer_date_to);
      const response = await keyLogAPI.list(queryParams);
      return response?.data;
    }
  );

  const { data: keyLogsQuery, isLoading: keyLogsLoading } = queryList;

  const {
    loading: apartmentListLoading,
    result: apartmentQuery,
    setQuery: setApartmentQuery,
  } = useWordSearch(`${KEY_APARTMENT_SEARCH_API}`);

  const apartmentList = apartmentQuery?.data;
  const apartmentOptions = apartmentList?.map((apartment: any) => ({
    label: apartment.name,
    value: apartment.id,
  }));

  useEffect(() => {
    if (scannedKeyId) {
      history.push(routes.key.KeyLogs.view.replace(':id', `${scannedKeyId}`));
    }
  }, [history, scannedKeyId]);

  const handleScan = () => {
    //scan logic here
    onOpen();
  };

  const handleCloseScan = () => {
    //if rfid placed then only close the dialog box.
    setScannedKeyId(null);
    onClose();
  };

  const handleApartmentInputChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setApartmentInputKey(value);
      setApartmentQuery({ keyword: value || null });
    }
  };

  const handleApartmentChange = (value: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      apartmentName: value.label,
      apartmentId: value.value,
    }));
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      transfer_date_from: start,
      transfer_date_to: end,
    }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleStatusChange = (status: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      status: status.map((item: any) => item.value),
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.transfer_date_from)
      data.transfer_date_from = Date.parse(data.transfer_date_from);
    if (data.transfer_date_to)
      data.transfer_date_to = Date.parse(data.transfer_date_to);

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      apartmentId: '',
      status: [],
      transfer_date_from: null,
      transfer_date_to: null,
    }));
    statusRef.current.select.clearValue();
    history.push(routes.key.KeyLogs.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Helmet>
        <title>KMS | {strings.all_key_logs}</title>
      </Helmet>
      {checkKeyLogsPermission && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={handleCloseScan}
          isOpen={isOpen}
          isCentered>
          <AlertDialogOverlay />

          <GetKeyId
            setScannedKeyId={setScannedKeyId}
            onClose={onClose}
            databaseCheck={true}
          />
        </AlertDialog>
      )}
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_management}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.key.KeyLogs.list}>
              {strings.key_logs}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.all_key_logs}
          </Heading>

          {checkKeyLogsPermission && (
            <Button
              size="sm"
              onClick={handleScan}
              variant="solid"
              rounded="sm"
              colorScheme="primary"
              aria-label="key_scan"
              leftIcon={<Icon as={BiBarcodeReader} w="4" h="4" />}
              __hover={{ variant: 'solid', bg: 'primary', color: 'white' }}>
              {strings.scan_key}
            </Button>
          )}
        </Flex>

        <Stack sx={wrapperStyles}>
          <form>
            <Stack direction={['column']} spacing="4">
              <Grid
                gap="4"
                templateColumns={['repeat(1, 1fr)', 'repeat(4, 1fr)']}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.search_customer}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.400"
                      />
                      <Input
                        type="text"
                        name="keyword"
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.object}</FormLabel>
                    <ReactSelect
                      id="apartmentId"
                      name="apartmentId"
                      placeholder={strings.select_apartment}
                      options={apartmentOptions}
                      value={apartmentValue}
                      styles={reactSelectStyles}
                      onInputChange={handleApartmentInputChange}
                      inputValue={apartmentInputKey}
                      isLoading={apartmentListLoading}
                      onChange={handleApartmentChange}
                      allowClear
                      isSearchable
                    />
                  </FormControl>
                </GridItem>

                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.status}</FormLabel>
                    <ReactSelect
                      name="status"
                      placeholder={strings.status}
                      closeMenuOnSelect={false}
                      options={KeyStatusOptions}
                      ref={statusRef}
                      isMulti
                      defaultValue={status_value}
                      onChange={handleStatusChange}
                      styles={reactSelectStyles}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.date}</FormLabel>
                    <InputGroup>
                      <ReactDatePicker
                        placeholderText={strings.select_date}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomDateInput />}
                        onChange={handleDateChange}
                        selected={filterParams.transfer_date_from}
                        startDate={filterParams.transfer_date_from}
                        endDate={filterParams.transfer_date_to}
                        selectsRange
                        value={getFormattedRangeDate(
                          filterParams.transfer_date_from,
                          filterParams.transfer_date_to
                        )}
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                      <InputRightElement
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
              </Grid>

              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.external_company}</Th>
                  <Th>{strings.date_time}</Th>
                  <Th>{strings.user}</Th>
                  <Th>{strings.key_id}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {keyLogsQuery?.data.map((keyLogItem: any, index: number) => (
                  <KeysListIterm
                    keyLogItem={keyLogItem}
                    key={keyLogItem.id}
                    index={startingSN + index}
                    search={search}
                  />
                ))}
                {keyLogsLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default KeyLogs;
