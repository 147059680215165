import {
  Box,
  Flex,
  Heading,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import React, { forwardRef, useEffect, useState } from 'react';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  apartmentScoreData: any;
  ref: any;
  filterParams: any;
}

const selectColorSchemeId = (value: number) => {
  if (value < 5) {
    return 'tag-background-color-red';
  } else if (value >= 5 && value < 8) {
    return 'tag-background-color-orange';
  } else {
    return 'tag-background-color-green';
  }
};

const FeedbackResponseDetailsPDF = forwardRef((props: Props, ref: any) => {
  const { apartmentScoreData, filterParams } = props;
  const [showFilterParams, setShowFilterParams] = useState<boolean>(false);

  useEffect(() => {
    const filterParamsFlag =
      filterParams?.keyword ||
      filterParams?.feedback_at_from ||
      filterParams?.feedback_at_to;
    setShowFilterParams(!!filterParamsFlag);
  }, [
    filterParams?.feedback_at_from,
    filterParams?.feedback_at_to,
    filterParams?.keyword,
  ]);

  return (
    <Box ref={ref} id="feedback-print">
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <Image src={Logo} width="80px" alt="ListInfo Logo" />
          <Flex direction="column" ml="16px">
            <Heading size="lg" mb="8px" textTransform="capitalize">
              Listinfo Service Center
            </Heading>
            <Heading size="sm">Appartementvermittlung Familie Clausen</Heading>
          </Flex>
        </Flex>
        <Heading
          size="md"
          textTransform="capitalize"
          marginTop="10px"
          marginRight="10px">
          {strings.feedback_detail}
        </Heading>
      </Flex>
      <Box mt="8px">
        {showFilterParams && (
          <Box id="feedback-filter-block" mb="8px">
            <Box>
              <Text as="span" fontWeight={900} fontSize="18px">
                {strings.filter}
              </Text>
            </Box>

            {filterParams?.keyword && (
              <Box>
                <Text
                  as="span"
                  fontWeight={700}
                  className="filter-keyword-text">
                  {strings.text_search}:
                </Text>
                &ensp;{filterParams?.keyword}
              </Box>
            )}
            {filterParams?.feedback_at_from && (
              <Box>
                <Text
                  as="span"
                  fontWeight={700}
                  className="filter-keyword-text">
                  {strings.date}:
                </Text>
                &ensp;{DateFormat(filterParams?.feedback_at_from)}&nbsp;-&nbsp;
                {DateFormat(filterParams?.feedback_at_to)}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <TableContainer overflowY="auto">
        <Table
          size="sm"
          style={{
            whiteSpace: 'normal',
          }}>
          <Thead
            style={{
              borderBottom: '1px solid #000',
            }}>
            <Tr>
              <Th color="blackAlpha.900">{strings.sn}</Th>
              <Th color="blackAlpha.900" name="apartment">
                {strings.objects}
              </Th>
              <Th color="blackAlpha.900" name="score_total">
                {strings.score_total}
              </Th>
              <Th color="blackAlpha.900">{strings.reviews_general}</Th>
              <Th color="blackAlpha.900">{strings.reviews_object_page}</Th>
              <Th color="blackAlpha.900">{strings.public_score_calculation}</Th>
              <Th color="blackAlpha.900">{strings.real_score}</Th>
              <Th color="blackAlpha.900">{strings.public_score}</Th>
            </Tr>
          </Thead>
          <Tbody id="feedback-pdf-table-body">
            {apartmentScoreData?.data?.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{item.apartment}</Td>
                <Td>{item.total_feedback_received}</Td>
                <Td>{item.total_review}</Td>
                <Td>{item.total_apartment_review}</Td>
                <Td>{item.total_feedback_received_rating}</Td>
                <Td>
                  <span
                    className="rating-tag"
                    id={selectColorSchemeId(Number(item.total_score))}>
                    {Number(item.total_score).toFixed(1)}
                  </span>
                </Td>
                <Td>
                  <span
                    className="rating-tag"
                    id={selectColorSchemeId(Number(item.published_score))}>
                    {Number(item.published_score).toFixed(1)}
                  </span>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
});

FeedbackResponseDetailsPDF.displayName = 'FeedbackDetailsPDF';

export default FeedbackResponseDetailsPDF;
