import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import GeneralDocumentResource from 'api/general_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import GeneralDocumentForm from 'components/general_document/GeneralDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { GeneralDocumentSchema } from 'constants/schema';
import { default as React } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditGeneral: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<GeneralDocumentSchema>();

  const generalDocumentAPI = new GeneralDocumentResource();

  const generalDocumentQuery = useQuery(
    [`general-document${id}-edit`, id],
    () => generalDocumentAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateGeneralDocument = useMutation((data: any) =>
    generalDocumentAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    data._method = 'PUT';
    updateGeneralDocument.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: strings.service_group_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('generalDocumentList');
        history.push(routes.dms.generalList.list);
      },
    });
  };

  if (generalDocumentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (generalDocumentQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.general_document_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack>
      <Helmet>
        <title>
          {strings.dms} | {strings.edit_general_document}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.general_documents}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>
              {generalDocumentQuery?.data?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.edit_general_document}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {updateGeneralDocument.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.general_document_udpate_failed}
                  </Alert>
                )}
                <GeneralDocumentForm
                  generalDocumentData={generalDocumentQuery.data.data}
                />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={updateGeneralDocument.isLoading}>
                    {strings.save_now}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EditGeneral;
