import { Icon, Td, Tooltip, Tr } from '@chakra-ui/react';
import Feedback from 'api/feedback';
import { strings } from 'config/localization';
import * as FileSaver from 'file-saver';
import React from 'react';
import { BiCloudDownload } from 'react-icons/bi';
import { formatRating } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';

interface FeedbackItem {
  id: number;
  title: string;
  type: 'rating' | 'closed' | 'open';
  total_count: number;
  result: number;
}
interface Props {
  index: number;
  data: FeedbackItem;
  apartmentId?: string;
  apartmentName?: string;
  dateFrom?: any;
  dateTo?: any;
}

const FeedbackAnalysisItem: React.FC<Props> = (props) => {
  const { index, data, apartmentId, apartmentName, dateFrom, dateTo } = props;
  const { id, title, type, total_count, result } = data;

  const feedbackAPI = new Feedback();

  let resultValue: string = '-';
  let tooltip: string = strings.result;
  let questionType: 'rating' | 'open_ended' | 'closed_ended' = 'rating';

  if (type === 'rating') {
    /**
     * Questions to rate from 1 to 10
     */
    resultValue = formatRating(result) ?? '-';
    tooltip = strings.average_rating;
    questionType = 'rating';
  } else if (type === 'closed') {
    /**
     * Yes / No Questions
     */
    const yesPercentage = Math.round((result / total_count) * 100);
    const noPercentage = 100 - yesPercentage;

    resultValue = `${yesPercentage} / ${noPercentage}`;
    tooltip = strings.percentage_of_closed_ended;
    questionType = 'closed_ended';
  } else if (type === 'open') {
    /**
     * Open Textfield Questions
     */
    const answeredPercentage = Math.round((result / total_count) * 100);
    const unansweredPercentage = 100 - answeredPercentage;

    resultValue = `${answeredPercentage} / ${unansweredPercentage}`;
    tooltip = strings.percentage_of_open_ended;
    questionType = 'open_ended';
  }

  const downloadFileHandler = async () => {
    const queryParams = {
      question_id: id,
      apartment_id: apartmentId ? apartmentId : null,
      feedback_at_from: DateFormatYMD(dateFrom),
      feedback_at_to: DateFormatYMD(dateTo),
    };

    const today = new Date();
    const title: string = `Feedack_für_Einzelfrage_${id}${
      apartmentId ? `_Objekt_${apartmentName}` : ''
    }${dateFrom ? `(${DateFormat(dateFrom)}` : ''}${
      dateTo ? `-${DateFormat(dateTo)}` : ''
    }${dateFrom ? ')' : ''}_${DateFormat(today)}.xlsx`;

    const response = await feedbackAPI.downloadOpenEnded(queryParams);

    if (response.data) {
      let headers = response.headers;
      let blob = new Blob([response.data], { type: headers['content-type'] });
      FileSaver.saveAs(blob, title);
    }
  };

  return (
    <Tr>
      <Td>{index}</Td>
      <Td whiteSpace="pre-wrap">{title}</Td>
      <Td>{strings[questionType]}</Td>
      <Tooltip hasArrow label={tooltip}>
        <Td textAlign="center">{resultValue}</Td>
      </Tooltip>
      <Td textAlign="center" className="hide-in-print">
        {type === 'open' ? (
          <Tooltip label={strings.download} hasArrow>
            <Icon as={BiCloudDownload} onClick={downloadFileHandler} />
          </Tooltip>
        ) : (
          '-'
        )}
      </Td>
    </Tr>
  );
};

export default FeedbackAnalysisItem;
