const de = {
  /*login Credential*/
  login: 'Anmeldung',
  log_in: 'Einloggen',
  password: 'Passwort',
  forgot_your_password: 'Passwort vergessen',
  email: 'E-Mail',
  your_email_address: 'Ihre E-Mail-Adresse',
  remember_me: 'Login speichern',
  forgot_password: 'Passwort vergessen?',
  forgot_password1: 'Passwort vergessen',
  back_to_login: 'Zurück zur Anmeldung',
  password_reset: 'Passwort zurücksetzen',
  login_message:
    'Geben Sie Ihre Anmeldeinformationen ein, um sich im Listinfo Service Center anzumelden',
  forgot_message:
    'Bitte geben Sie die E-Mail für ihr Konto ein, um sich anzumelden.',
  listinfo_service_center: 'Listinfo Service Center',
  appartmentvermittlung_familie_clausen:
    'Appartmentvermittlung Familie Clausen',
  password_info:
    'Das Passwort muss mindestens 1 Zahl, 1 Sonderzeichen, 1 Großbuchstaben und 5 weitere Zeichen enthalten',
  validate_recursive_until:
    'Bitte geben Sie ein Datum ein, das später liegt als "Wiederholen ab"',
  change_language: 'Ändern der Sprache',
  invalid_token: 'Mit diesem Link wurde bereits ein neues Passwort festgelegt.',
  two_factor_authentication: 'Zwei-Faktor-Authentifizierung',
  enter_the_pin_from_google_authentication:
    'Geben Sie jetzt die PIN aus der Goole Authenticator App ein',
  authenticate: 'Authentifizieren',
  one_time_password: 'Einmaliges Passwort',
  FA_status: '2FA-Status',
  disable_2FA: '2FA ausschalten',
  enable_2FA: '2FA einschalten',
  fa_message:
    'Scannen Sie diesen Barcode mit Ihrer Google Authenticator App und geben Sie danach den Code aus der Google Authenticator App hier ein.',
  fa_enable_status_tooltip:
    'Erzeugen eines geheimen Schlüssels zur Aktivierung von 2FA',
  fa_disable_status_tooltip:
    'Geben Sie Ihr Passwort ein, um 2FA zu deaktivieren',
  authenticator_code: 'Authenticator Code',
  FA_successfully: '2FA ist erfolgreich aktiviert',
  FA_enable: '2FA ist zurzeit für Ihr Konto aktiviert',
  fa_Disable: '2FA ist jetzt deaktiviert',
  authenticator_code_error: 'Authenticator Code Fehler',
  required_authenticator_code: 'Erforderlicher Authenticator Code',
  fa_disable_title:
    'Wenn sie die 2-Faktor-Authentifizierung deaktivieren möchten, geben Sie bitte Ihr Passwort für das Listinfo Service Center ein, und bestätigen Sie.',
  disable_2FA_confimation_message:
    '2-Faktor-Authentifizierung für diesen Benutzer wirklich ausschalten?',

  /*button*/
  save_now: 'Speichern',
  back: 'Zurück',
  filter: 'Filter',
  apply_filter: 'Suchen',
  reset_filter: 'Filter zurücksetzen',
  more_filter: 'Mehr Filter',
  cancel: 'Abbrechen',
  clear: 'Freimachen',
  reply: 'Antworten',
  comment: 'Kommentar',
  save_and_create_new_ticket: 'Speichern und neues Ticket erstellen',
  confirm_and_close: 'Bestätigen und schließen',
  confirm_and_transfer_another_items:
    'Bestätigen und weiteren Artikel transferieren',
  edit_article: 'Artikel bearbeiten',
  profile: 'Profil',
  my_profile: 'Mein Profil',
  edit_profile: 'Profil bearbeiten',
  profile_updated: 'Profil aktualisiert',
  password_updated: 'Passwort aktualisiert',
  profle_update_failed: 'Profil-Aktualisierung fehlgeschlagen',
  not_match_new_confirm_password: 'Die Passwörter stimmen nicht überein',
  incorrect_password: 'Das Passwort ist nicht korrekt',
  setting: 'Einstellungen',
  settings: 'Einstellungen',
  loggedOut: 'Ausloggen',
  logout: 'Abmeldung',
  change_password: 'Passwort ändern',
  change_now: 'Jetzt ändern',
  Current_password: 'Jetziges Passwort',
  new_password: 'Neues Passwort',
  confirm_password: 'Passwort bestätigen',
  invalid_login_msg: 'E-Mail und Passwort stimmen nicht überein',
  invalid_current_password: 'Das Passwort ist falsch',
  user_delete_error:
    'Der Nutzer kann nicht gelöscht werden, da er noch Artikel hält',
  euro: 'Euro',
  euro_symbol: '€',
  less_than_zero: 'Wert kleiner als Null.',
  email_not_found: 'E-Mail Adresse nicht gefunden.',
  email_required: 'E-Mail Adresse ist erforderlich',
  upcoming: 'Bevorstehend',
  past: 'Vergangene',
  send_email: 'E-Mail senden',
  stored_mail: 'Gespeicherte Mail',
  email_sent_successfully: 'E-Mail erfolgreich gesendet',
  failed_to_send_email: 'Fehler beim Senden der E-Mail',

  /*Module*/
  user_management: 'Benutzerverwaltung',
  chat: 'Chat',
  ticket: 'Ticket',
  crm: 'CRM',
  contact_center: 'Contact Center',
  feedback: 'Feedback',
  mms: 'WaWi',
  dashboard: 'Dashboard',
  access_dashboard: 'Dashboard Zugriff',
  travelytics: 'Travelytics',
  access_travelytics: 'Travelytics Zugriff',
  /*Splash Screen*/
  choose_subsystem: 'Wählen Sie ein Subsystem',
  /*User Management*/
  user_management_system: 'Benutzerverwaltung',
  user: 'Nutzer',
  user_list: 'Nutzerliste',
  user_rights: 'Nutzerrechte',
  user_roles: 'Nutzerregeln',
  user_name: 'Nutzername',
  user_profile: 'Nutzerprofil',
  role: 'Rolle',
  add_additional_role: 'Zusätzliche Rolle hinzufügen',
  add_additional_rights: 'Zusätzliche Rechte hinzufügen',
  additional_rights: 'Zusätzliche Rechte',
  manage_extra_rights: 'Extra-Rechte verwalten',
  right_from_role: 'Rechte aus der Rolle',
  add_role: 'Rolle hinzufügen',
  role_setup: 'Rollen-Setup',
  system: 'System',
  all_users: 'Alle Nutzer',
  all_user_with_that_role: 'Alle Nutzer mit dieser Rolle',
  add_new_role: 'Add New Role',
  add_new_user: 'Neuen Nutzer hinzufügen',
  role_name: 'Rollenname',
  privillage: 'Privileg',
  view_user: 'Nutzer ansehen',
  add_user: 'Nutzer hinzufügen',
  edit_user: 'Nutzer bearbeiten',
  save_user: 'Nutzer speichern',
  user_deatils: 'Nutzerdetails',
  delete_user: 'Nutzer löschen',
  upload_image: 'Bild hochladen',
  assigned_tickets: 'Zugewiesene Tickets',
  action: 'Aktion',
  first_name: 'Vorname',
  last_name: 'Nachname',
  email_address: 'E-Mail-Addresse',
  valid_email_address: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
  contact_info: 'Kontaktinformationen',
  address: 'Adresse',
  search: 'Suche',
  new_user: 'Neuer Nutzer',
  has_been_assigned: 'ist Ihnen zugewiesen worden',
  has_been_transferred: 'wurde an Sie übertragen',
  login_with_new_password: 'Bitte mit neuem Passwort anmelden',
  msg_valid_password: 'Bitte geben Sie ein gültiges Passwort ein',
  msg_password_reset_link:
    'Wir haben Ihnen den Link zum Zurücksetzen des Passworts per E-Mail zugesandt',
  select_role: 'Rolle auswählen',
  search_username: 'Nutzer suchen',
  rights: 'Rechte',
  reset_password: 'Passwort zurücksetzen',
  reset_password_subtitle:
    'Bitte geben Sie die benötigten Felder an, um ihr Passwort zurückzusetzen',
  save_reset_password: 'Passwort zurücksetzen',
  create_password: 'Passwort festlegen',
  create_password_subtitle:
    'Bitte füllen Sie die Felder aus, um ein Passwort für Ihren Account zu erstellen',
  save_password: 'Passwort speichern',
  password_helper_text:
    'Mindestens 8 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen',
  resend_verification_email: 'Verifizierungs-E-Mail erneut senden',
  verification_email_sent: 'Verifizierungs-E-Mail gesendet',
  email_verification_success: 'E-Mail erfolgreich verifiziert',
  email_verification_failed: 'E-Mail-Verifizierung fehlgeschlagen.',
  resend_verify_email_error:
    'Fehler beim erneuten Senden der E-Mail-Bestätigung',
  resend_verify_email_success:
    'Die Verifizierungs-E-Mail wurde erfolgreich versandt. Prüfen Sie Ihren Posteingang.',
  resend_verify_email_success_to:
    'Die Verifizierungs-E-Mail wurde erfolgreich versandt an',
  email_already_verified: 'Die E-Mail ist bereits verifiziert.',
  login_to_continue: 'Bitte loggen Sie sich ein, um fortzufahren.',
  create_password_to_proceed: 'Erstellen Sie ein Passwort, um fortzufahren',
  change_confirm: 'Möchten Sie dies wirklich ändern?',
  cellphone: 'Mobiltelefon',
  invalid_cellphone: 'Ungültige Handynummer',
  email_placeholder: 'ihremail@beispiel.com',
  admin: 'Admin',
  chat_supporter: 'Chat-Mitarbeiter',
  ticket_supporter: 'Ticket-Mitarbeiter',
  mms_employee: 'WaWi-Mitarbeiter',
  verified: 'Bestätigt',
  not_yet_verified: 'Noch nicht bestätigt',
  external_companies: 'Externe Unternehmen',
  external_company: 'Externes Unternehmen',
  company_name: 'Name der Firma',
  add_new_company: 'Neues Unternehmen hinzufügen',
  company_name_is_required: 'Firmenname ist erforderlich.',
  company_email_is_required: 'Firmen-E-Mail ist erforderlich.',
  please_enter_a_valid_phone_number:
    'Bitte geben Sie eine gültige Telefonnummer ein.',
  enter_a_country_code_at_the_start:
    'Geben Sie zu Beginn einen Ländercode ein.',
  company_creation_failed: 'Unternehmenserstellung fehlgeschlagen.',
  the_email_has_already_been_taken: 'Diese E-Mail Adresse ist schon vergeben.',
  company_created: 'Unternehmen wurde erstellt.',
  edit_company: 'Unternehmen bearbeiten',
  company_updated: 'Firma erfolgreich aktualisiert.',
  company_udpate_failed: 'Unternehmensaktualisierung fehlgeschlagen.',
  delete_company: 'Unternehmen löschen',
  company_deleted: 'Firma wurde gelöscht.',
  company_delete_error: 'Unternehmen kann nicht gelöscht werden.',
  company_not_found: 'Firma nicht gefunden.',
  company_profile: 'Unternehmensprofil',
  all_companies: 'Alle Unternehmen',
  company_list: 'Firmenliste',
  companies: 'Firmen',
  view_company: 'Unternehmen anzeigen',
  all_roles: 'Alle Rollen',
  user_login_log: 'Benutzer-Login-Protokolle',
  all_login_logs: 'Alle Anmeldeprotokolle',
  access_user_log: 'Zugang zu Login-Protokollen',
  foreign_language: 'Fremdsprache',
  select_language: 'Sprache auswählen',

  /*Chat System*/
  chat_system: 'Chat System',
  closed_chat: 'Geschlossener Chat.',
  all_closed_chats: 'Alle geschlossenen Chats',
  closed_chat_details: 'Geschlossener Chat Details',
  archived_chat: 'Archivierter Chat',
  all_archived_chats: 'Alle archivierten Chats',
  archived_chat_details: 'Archivierter Chat Details',
  chat_metrics: 'Chat Statistik',
  all_chat_metrics: 'Alle Chat-Metriken',
  sn: '#',
  id: 'ID',
  customer_name: 'Kundenname',
  employee_name: 'Nutzername',
  closed_at: 'Geschlossen am',
  last_message: 'Letzte Nachricht',
  no_of_message: 'Anzahl der Nachrichten',
  actions: 'Aktion',
  no_of_chat: 'Anzahl der Chats',
  no_of_active_chat: 'Anzahl der aktiven Chats',
  no_messages_received: 'Anzahl erhaltener Nachrichten',
  no_messages_send: 'Anzhal gesendeter Nachrichten',
  time_spent_in_call: 'Zeit in Anruf',
  view: 'Ansicht',
  add: 'Hinzufügen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  role_descripition: 'Rollenbeschreibung',
  name: 'Name',
  assignee: 'Interne Stelle',
  description: 'Beschreibung',
  apartment: 'Ferienwohnung',
  transfer_chat: 'Chat übertragen',
  select_user: 'Nutzer auswählen',
  transfer_closed_chat: 'Chat schließen?',
  transfer_archived_chat: 'Archivierten Chat übertragen',
  transfer_modal_closed_message: 'Chat wird geschlossen.',
  transfer_modal_archived_message: 'Wirklich archivieren?',
  yes: 'Ja',
  no: 'Nein',
  call: 'Anruf',
  miss_called: 'Teilnehmer hat den Anruf verpasst',
  call_ended: 'Anruf beendet',
  message_not_sent: 'Nachricht konnte nicht gesendet werden',
  join_room_error: 'Raum beitreten nicht möglich',
  make_call_error: 'Anruf starten nicht möglich',
  is_typing: 'schreibt...',
  /*Ticket Board*/
  ticket_system: 'Ticket System',
  all_tickets: 'Alle Tickets',
  ticket_board: 'Ticket-Board',
  add_task: 'Ticket hinzufügen',
  open: 'Offen',
  inprogress: 'In Bearbeitung',
  done: 'Erledigt',
  closed: 'Geschlossen',
  recursive_task: 'Wiederholendes Ticket',
  normal_task: 'Normales Ticket',
  create_ticket: 'Ticket erstellen',
  ticket_details: 'Ticketdetails',
  title: 'Titel',
  ticket_title: 'Ticketname',
  object: 'Objekt',
  all_objects: 'Alle Objekte',
  customer: 'Gast',
  repeat_task: 'Ticket Wiederholen',
  cost_recipient: 'Kostenempfänger',
  house_owner: 'Eigentümer',
  priority_label: 'Priorität',
  priority: 'Priorität',
  upload_files: 'Dateien hochladen',
  descriptions: 'Beschreibung',
  save_create_new_ticket: 'Speichern und neues Ticket erstellen',
  cancel_call_error: 'Anruf kann aktuell nicht beendet werden',
  author: 'Autor',
  created_by: 'Erstellt von',
  created_at: 'Erstellt am',
  last_changed_by: 'Letzte Änderung von ',
  last_changed_at: 'Letzte Änderung am ',
  status: 'Status',
  ticket_log: 'Ticketprotokoll',
  ticket_list: 'Ticketliste',
  recursive_interval: 'Wiederhol-Intervall',
  recursive_untill: 'Wiederholen bis',
  recursive_start: 'Wiederholen ab',
  ticket_created: 'Ticket wurde erstellt',
  has_been_created: 'wurde erstellt',
  has_not_been_created: 'wurde nicht erstellt',
  has_been_merged: 'zusammenführen',
  has_not_been_merged: 'wurde nicht merged',
  has_been_updated: 'wurde aktualisiert',
  has_not_been_updated: 'wurde nicht aktualisiert',
  has_been_deleted: 'has been deleted',
  has_not_been_deleted: 'has not been deleted',
  ticket_error: 'Ticket konnte nicht erstellt werden',
  active: 'Aktiv',
  in_active: 'Inaktiv',
  select_apartment: 'Ferienwohnung auswählen',
  select_assignee: 'Beauftragten auswählen',
  select_customer: 'Kunden auswählen',
  select_cost_recepient: 'Kostenempfänger auswählen',
  select_house_owner: 'Eigentümer auswählen',
  select_priority: 'Priorität auswählen',
  select_source: 'Quelle auswählen',
  write_description: 'Beschreibung',
  expand: 'Ausklappen',
  ticket_list_error:
    'Es ist ein Fehler bei der Bearbeitung Ihrer Anfrage aufgetreten',
  select_recursive_interval: 'Wiederhol-Intervall auswählen',
  title_required: 'Titel ist erforderlich',
  repeating_info: 'Wiederhol-Info',
  interval: 'Intervall',
  repeat_from: 'Wiederholen ab',
  repeat_until: 'Wiederholen bis',
  validate_recursive_date: 'Bitte geben Sie ein gültiges Wiederhol-Datum an',
  comment_here: 'Hier kommentieren',
  comment_required: 'Kommentar ist erforderlich',
  id_not_found: 'ID nicht gefunden',
  edit_ticket: 'Ticket bearbeiten',
  ticket_updated: 'Ticket erfolgreich aktualisiert',
  change_status: 'änderte den Ticket-Status',
  status_updated: 'Status erfolgreich aktualisiert',
  select_status: 'Status auswählen',
  comment_error: 'Kommentar-Fehler',
  view_more: 'Mehr anzeigen',
  view_less: 'Weniger anzeigen',
  billability: 'Abrechenbarkeit',
  billable: 'Abrechenbar',
  spent_time: 'Verbrachte Zeit',
  correct_spent_time: 'Verbrachte Zeit korrigieren',
  spent_time_message: 'Die verbrachte Zeit wurde manuell korrigiert',
  chat_not_found: 'Ihr Feed enthält keinen Chat',
  chat_not_selected: 'Kein Chat ausgewählt',
  chat_active_toggle_tooltip_message:
    'Neue Chats können Ihnen zugewiesen oder an Sie übertragen werden, wenn Sie aktiv sind. Während Sie ein Gespräch führen, wird der Schalter automatisch deaktiviert',
  chat_users_not_available_for_transferral:
    'Im Moment ist kein anderer Benutzer verfügbar',
  call_in_progress: 'Anruf in Arbeit',
  click_to_cancel: 'Zum Abbrechen erneut klicken',
  call_cancelled: 'Anruf abgebrochen',
  make_call: 'Jemanden anrufen',
  chat_active_info: 'Chat Aktivität Info',
  no_chat_assigned: 'Zurzeit ist Ihnen kein Chat zugewiesen',
  task: 'Ticket',
  low: 'Niedrig',
  medium: 'Mittel',
  high: 'Hoch',
  urgent: 'Kritisch',
  normal: 'Normal',
  repeating: 'Wiederholend',
  select_type: 'Art auswählen',
  ticket_detail: 'Ticket Detail',
  edit_task: 'Ticket bearbeiten',
  created_ticket: 'erstellte das Ticket',
  updated_ticket: 'änderte das Ticket',
  changed_status: 'änderte den Ticket-Status',
  assigned_ticket: 'zugeordnetes Ticket',
  commented: 'kommentierte',
  order_by: 'Sortieren nach',
  createdat: 'Erstellt am',
  order: 'Auf/Absteigend',
  asc: 'Aufsteigend',
  desc: 'Absteigend',
  select_sort: 'Sortierung wählen',
  select_order: 'Ordnung auswählen',
  ticket_name: 'Auftrag ',
  select_external_company: 'Wählen Sie Externes Unternehmen aus',
  showing_all_customers: 'Alle Kunden anzeigen',
  showing_all_options: 'Alle Optionen anzeigen',
  appointment: 'Termin',
  appointment_date_time: 'Datum und Uhrzeit des Termins',
  enter_appointment_date_time: 'Datum und Uhrzeit des Termins eingeben',
  full_screen: 'Vollbild',
  extenal_company_ticket: 'Handwerker ToDo',
  external_company_button_tooltip:
    'Um eine Aufgabenliste für eine externe Firma zu erstellen, wählen Sie die externe Firma und das Datum des Termins im Filter aus.',
  external_company_filter_message:
    'Wählen Sie zuerst die externe Firma und dann das Termindatum.',
  todo_list: 'ToDo-Liste',
  assignee_button_tooltip:
    'Um eine ToDo-Liste für den Empfänger zu erstellen, wählen Sie den Empfänger und das Termindatum im Filter aus.',
  todo_button_tooltip:
    'Um eine ToDo-Liste für den Empfänger und das externe Unternehmen zu erstellen.',

  /*CRM*/
  customer_relation_management: 'Kundenverwaltung',
  contact_list: 'Kontaktliste',
  all_contacts: 'Alle Kontakte',
  merge_list: 'Liste zusammenführen',
  add_contact: 'Kontakt hinzufügen',
  label: 'Label',
  source: 'Quelle',
  destination: 'Ziel',
  added: 'Hinzugefügt',
  last_activities: 'Letzte Aktivität',
  last_ticket: 'Letztes Ticket',
  street: 'Straße',
  house_no: 'Hausnummer',
  post_code: 'Postleitzahl',
  town: 'Ort',
  country: 'Land',
  phone: 'Telefonnummer',
  mobile: 'Handynummer',
  company: 'Firma',
  fellow_travelers: 'Mitreisende',
  pets: 'Haustiere',
  equipment_features: 'Ausstattungsmerkmale',
  services: 'Services',
  service: 'Service',
  notes_external: 'Notizen extern',
  notes_internal: 'Notizen intern',
  add_now: 'Hinzufügen',
  added_on: 'Hinzugefügt am',
  edit_contact: 'Kontakt bearbeiten',
  event_history: 'Event-Log',
  channel: 'Kanal',
  date_time: 'Datum und Uhrzeit',
  contact_details: 'Kontaktdetails',
  merge: 'Zusammengeführt',
  unmerge: 'Nicht zusammengeführt',
  updated: 'Aktualisiert',
  city: 'Stadt',
  select_channel: 'Select Channel',
  merge_contact: 'Kontakte zusammenführen',
  merge_contact_sidebar: 'Zusammenführen',
  select_contact: 'Select Contact',
  logs: 'Logs',
  merged_date: 'Zusammengeführtes Datum',
  updated_date: 'Neues Datum',
  uploaded_by: 'hochgeladen von',
  file: 'Datei',
  image: 'Bild',
  images: 'Bilder',
  progress_status: 'Fortschrittsstatus',
  upload_file: 'Hochgeladen Datei',
  continue: 'Weiter',
  view_contact: 'Kontakt Details',
  delete_contact: 'Kontakt löschen',
  merge_detail: ' Kontakt Zusammenführung Detail',
  merge_preview: 'Zusammenführung Vorschau',
  merge_contact_is_permanent:
    'Das Zusammenführen von Kontakten ist ein Vorgang, der nicht rückgängig gemacht werden kann.',
  janitor: 'Hausmeister',
  support: 'Unterstützung',
  deleted_customer: 'Gelöschter Kunde',

  /*Contact Center*/
  call_for_user: 'Aufruf für Benutzer',
  total_calls: 'Anrufe',
  aircall_user_name: 'Nutzer',
  incoming_call: 'Eingehender Anruf',
  outgoing_call: 'Ausgehenden Anruf',
  total_incoming_call: 'Eingehnde',
  incoming_call_duration: 'Eingehende Dauer',
  total_outgoing_call: 'Ausgehende',
  outgoing_call_duration: 'Ausgehende Dauer',
  average: 'Durchschnitt',
  total_duration: 'Dauer gesamt',
  internal_phone_number: 'Interne Telefonnummer',
  _customer_is_calling: 'Der Kunde {0} ruft an.',

  /*Feedback*/
  feedback_system: 'Feedback System',
  access_feedback: 'Feedback und Mails managen',
  access_feedback_questions: 'Fragen und Einstellungen managen',
  access_feedback_resend: 'Zugang Feedback erneut senden',
  all_feedbacks: 'Alle Feedbacks',
  feedback_lists: 'Feedback Lists',
  mails: 'Mails',
  all_mails: 'Alle Mails',
  mail_lists: 'Mail Liste',
  questions: 'Fragen',
  all_questions: 'Alle Fragen',
  question_lists: 'Fragen Liste',
  date: 'Datum',
  closed_ended: 'Ja/Nein-Frage',
  open_ended: 'Text-Frage',
  rating: 'Bewertungs-Frage',
  time_period: 'Zeitraum',
  feedback_detail: 'Feedback Detail',
  mark_as_done: 'Als erledigt markieren',
  publish_feedback: ' Feedback veröffentlichen',
  publish_feedback_on_object_page: 'Feedback auf Objekt-Seite veröffentlichen',
  do_not_publish_feedback: 'Feedback nicht veröffentlichen',
  do_not_publish_feedback_on_object_page:
    'Kein Feedback auf der Objektseite veröffentlichen',
  use_for_score_calculation: 'Für Score-Berechnung verwenden',
  do_not_use_for_score_calculation: 'Nicht für die Score-Berechnung verwenden',
  contact_customer: 'Kunden kontaktieren',
  contact_house_owner: 'Eigentümer kontaktieren',
  resend_mail: 'Mail erneut senden',
  new_question: 'Neue Frage',
  edit_question: ' Frage bearbeiten',
  add_question: 'Frage hinzufügen',
  delete_question: 'Frage löschen',
  edit_feedback: ' Feedback bearbeiten',
  view_feedback: 'Rückmeldung anzeigen',
  feedback_question: 'Feedback-Frage',
  feedback_question_deleted_successfully: 'Feedback-Frage erfolgreich gelöscht',
  sent: 'Geschickt',
  received: 'Empfangen',
  feedback_mail_sent_sucessfully: 'Feedbackmail erfolgreich versendet',
  feedback_mail_not_sent: 'Feedback-E-Mail wurde nicht gesendet',
  how_many_days_after_departure_should_the_feedback_mail_be_sent:
    ' Wie viele Tage nach der Abreise soll die Feedback-Mail versendet werden?',
  select_days: 'Tage auswählen',
  days: 'Tage',
  feedback_received: 'Feedback erhalten',
  question: 'Frage',
  response: 'Antwort',
  the_rating_is_out_of_10: 'Die Bewertung ist von 10',
  this_is_closed_ended_question: 'Dies ist eine Ja/Nein-Frage',
  this_is_open_ended_question: 'Dies ist eine offene Frage',
  feedback_has_been_published_successfully:
    'Feedback wurde erfolgreich veröffentlicht',
  feedback_publish_error: 'Feedback-Veröffentlichungsfehler',
  published_feedback_on_object_page_successfully:
    'Feedback auf Objektseite erfolgreich veröffentlicht',
  publish_feedback_on_object_page_error:
    'Veröffentlichtes Feedback zum Objektseitenfehler',
  feedback_has_been_unpublished_successfully:
    'Die Veröffentlichung des Feedbacks wurde erfolgreich aufgehoben',
  feedback_unpublish_error:
    'Fehler beim Aufheben der Veröffentlichung des Feedbacks',
  feedback_has_been_marked_as_done_successfully:
    'Feedback wurde als erfolgreich abgeschlossen markiert',
  feedback_mark_as_done_error: 'Feedback als erledigt markieren Fehler',
  How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole:
    'Wie würden Sie Ihren Urlaub in List auf Sylt und die Services der Appartementvermittlung Familie Clausen insgesamt bewerten?',
  publish_status: 'Status veröffentlichen',
  published_on_website: 'Auf der Website veröffentlicht',
  published_on_object_detail_page: 'Veröffentlicht auf der Objekt-Detailseite',
  score_calculation: 'Punkteberechnung',
  feedback_on_object_page_has_been_unpublished_successfully:
    'Feedback zur Objektseite wurde erfolgreich unveröffentlicht',
  feedback_on_object_page_unpublish_error:
    'Feedback zum Fehler beim Unveröffentlichen von Objektseiten',
  score_calculation_used: 'Verwendete Punkteberechnung',
  score_calculation_use_error: 'Punkteberechnung Fehler verwenden',
  score_calculation_unused: 'Punkteberechnung Unbenutzt',
  score_calculation_unuse_error: 'Punkteberechnung Nichtverwendung Fehler',
  object_publish: 'Objekt veröffentlicht',
  website_publish: 'Website veröffentlicht',
  not_publish: 'Nicht veröffentlicht',
  score_total: 'Bewertungen',
  answer_rate: 'Antwortrate',
  public_score_calculation: 'Score-Berechnung',
  real_score: 'Gesamt-Score',
  public_score: 'Score öffentlich',
  detail_reviews_general: 'Bewertungen allgemein',
  detail_review_object_page: 'Bewertungen Objekt-Seite',
  reviews_general: 'Bewertungen allgemein',
  reviews_object_page: 'Bewertungen Objektseite',
  score_general: 'Bewertungen Gesamt',
  score_objects: 'Bewertungen nach Objekten',
  no_mail: 'Keine E-Mail vorhanden',
  no_email: 'Keine E-Mail vorhanden',
  analysis: 'Analyse',
  average_rating: 'Durchschnittliche Bewertung.',
  percentage_of_closed_ended: 'Prozentsatz von Ja / Nein.',
  percentage_of_open_ended: 'Prozentsatz beantwortet / nicht beantwortet.',
  feedback_analysis: 'Feedback-Analyse',
  delete_mail: 'Mail löschen',
  feedback_mail_deleted_successfully: 'Erfolgreich gelöschte Feedback-Mail',
  feedback_analysis_detail: 'Details_der_Feedback_Analyse',
  feedback_response_detail: 'Feedback_Antwortdetails',
  feedback_deleted_successfully: 'Feedback erfolgreich gelöscht',
  delete_feedback: 'Feedback löschen',
  resend_feedback: 'Feedback erneut senden',
  resent: 'Erneut geschickt',
  publish: 'Veröffentlichen',
  select_publish: 'Veröffentlichen auswählen',
  publish_is_required: 'Veröffentlichen ist erforderlich.',
  start_page: 'Startseite',

  /*Inventory*/
  inventory: 'Inventar',
  inventories: 'Inventar',
  all_inventory: 'Alle Inventare',
  inventory_name: 'Name des Inventars',
  used_item: 'Benutztes Inventar',
  quantity: 'Menge',
  total_quantity: 'Gesamtmenge',
  from: 'Von',
  to: 'Zu',
  add_item: 'Artikel hinzufügen',
  deselect_all: 'Alle abwählen',

  /*MMS*/
  merchandise_management_system: 'Warenwirtschaftssystem',
  transfer_item: 'Inventar übertragen',
  all_transfer_item: 'Inventar übertragen',
  all_transfer_logs: 'Übertragungsprotokoll',
  select_articles: 'Inventar auswählen',
  search_article: 'Inventar suchen',
  stock_type: 'Stock Type',
  units: 'Einheiten',
  unit: 'Einheit',
  all_units: 'Alle Einheiten',
  unit_details: 'Einheit Details',
  select_source_warehouse: 'Quell-Lager auswählen',
  rooms: 'Räume',
  room: 'Raum',
  room_name: 'Raumname',
  add_room: 'Raum hinzufügen',
  edit_room: 'Raum bearbeiten',
  enter_room_name: 'Raumnamen eingeben',
  delete_room: 'Raum löschen',
  racks: 'Regale',
  rack: 'Regal',
  add_rack: 'Regal hinzufügen',
  delete_rack: 'Regal löschen',
  shelf: 'Regalboden',
  add_shelf: 'Regalboden hinzufügen',
  delete_shelf: 'Regalboden löschen',
  shelves: 'Regalböden',
  select_destination_warehouse: 'Wählen Sie das Ziel-Lager aus',
  warehouse: 'Lager',
  warehouses: 'Lager',
  all_warehouses: 'Alle Lagerhäuser',
  warehouse_name: 'Lagername',
  add_warehouse: 'Lager hinzufügen',
  edit_warehouse: 'Lager bearbeiten',
  delete_warehouse: 'Lager löschen',
  warehouse_details: 'Lagerdetails',
  warehouse_information: 'Lager Informationen',
  warehouse_not_found: 'Lager nicht gefunden',
  shelf_delete_error: 'Beim Löschen des Regalbodens ist ein Fehler aufgetreten',
  rack_delete_error: 'Beim Löschen des Regals ist ein Fehler aufgetreten',
  room_delete_error: 'Beim Löschen des Lagers ist ein Fehler aufgetreten',
  articles: 'Artikel',
  article: 'Artikel',
  all_articles: 'Alle Artikel',
  add_article: 'Artikel hinzufügen',
  articles_names: 'Artikelnamen',
  type: 'Art',
  types: 'Arten',
  type_details: 'Geben Sie Details ein',
  buying_price: 'Einkaufspreis (EK)',
  selling_price: 'Verkaufspreis (VK)',
  seller: 'Verkäufer',
  seller_home_page_shop: 'Verkäufer Webseite',
  minimum_stock: 'Mindestbestand',
  maximum_stock: 'Höchstbestand',
  out_of_stock: 'Nicht auf Lager',
  minimum_stock_notification: 'Mindestbestandsbenachrichtigung',
  ean_number: 'EAN-Nummer',
  notes: 'Anmerkungen',
  article_details: 'Artikeldetails',
  objects: 'Objekt',
  object_id: 'Objekt ID',
  object_name: 'Objektname',
  booking: 'Buchung',
  tickets: 'Tickets',
  inventory_load: 'Inventarlast',
  object_details: 'Objektdetails',
  ticket_open_inprogress_done: 'Tickets (Offen / In Bearbeitung / Erledigt)',
  abbreviation: 'Abkürzung',
  street_number: 'Hausnummer',
  is_active: 'Ist aktiv',
  type_name: 'Artname',
  add_type: 'Art hinzufügen',
  all_types: 'Alle Arten',
  unit_name: 'Einheitenname',
  add_unit: 'Einheit hinzufügen',
  edit_unit: 'Einheit bearbeiten',
  transfer_log: 'Übertragungsprotokoll',
  transfer_logs: 'Übertragungsprotokoll',
  my_transfer_log: 'Mein Übertragungsprotokoll',
  my_transfer_logs: 'Mein Übertragungsprotokoll',
  all_my_logs: 'Mein Übertragungsprotokoll',
  ticket_source: 'Ticketquelle',
  done_by: 'Erledigt durch',
  required: 'Erforderlich',
  user_created: 'Benutzer wurde erstellt',
  user_creation_failed: 'Benutzererstellung fehlgeschlagen',
  user_udpate_failed: 'Benutzeraktualisierung fehlgeschlagen',
  password_update_failed: 'Passwort-Aktualisierung fehlgeschlagen',
  user_not_found: 'Benutzer nicht gefunden',
  update: 'Aktualisieren',
  user_updated: 'Benutzer erfolgreich aktualisiert',
  user_deleted: 'Der Benutzer wurde gelöscht',
  roles_not_found: 'Rollen nicht gefunden',
  article_image: 'Artikelbild',
  confirm_delete: 'Möchten Sie das wirklich löschen?',
  edit_type: 'Art bearbeiten',
  article_name: 'Artikelname',
  upload_article_picture: 'Artikelbild hochladen',
  select: 'Auswahl',
  min_stock_notification_tooltip:
    'Sie werden benachrichtigt, wenn der Mindestbestand erreicht ist',
  min_stock_vaidation_msg: 'Der Wert sollte unter dem Höchstbestand liegen',
  max_stock_vaidation_msg: 'Der Wert sollte größer als der Mindestbestand sein',
  valid_data: 'Bitte geben Sie gültige Daten ein',
  valid_only_number: 'Bitte geben Sie nur Zahlen ein.',
  phone_call: 'Telefonanruf',
  collapse_sidebar: 'Seitenleiste einklappen',
  article_type: 'Artikel Art',
  article_unit: 'Artikel Einheit',
  expand_sidebar: 'Seitenleiste ausklappen',
  max_upload_size_5: 'Maximale Dateigröße: 5MB',
  max_upload_size_10: 'Maximale Dateigröße: 10MB',
  max_upload_size_15: 'Maximale Dateigröße: 15MB',
  max_characters_exceeded: 'Maximale Buchstabenanzahl überschritten',
  selected_files: 'Ausgewählte Dateien',
  drag_drop_files_msg:
    'Ziehen Sie einige Dateien per Drag & Drop hierher, oder klicken Sie, um Dateien auszuwählen. Dateien bis zu {0}MB hochladen.s',
  files: 'Dateien',
  delete_file: 'Datei löschen',
  only_1_file_is_allowed: 'Nur 1 Datei ist erlaubt.',
  access_user: 'User Management Zugriff',
  manage_role: 'Rollen verwalten',
  manage_user: 'Nutzer verwalten',
  access_external_companies: 'Greifen Sie auf externe Unternehmen zu',
  manage_external_companies: 'Externe Unternehmen bearbeiten',
  access_chat: 'Chat Zugriff',
  access_closed_chat: 'Geschlossene Chats Zugriff',
  access_archived_chat: 'Archiviertte Chats Zugriff',
  access_chat_metrics: 'Chat Statistik Zugriff',
  access_chat_assign: 'Chat Zuweisung',
  access_chat_mediasoup: 'Chat Video',
  access_ticket: 'Ticket Zugriff',
  access_mms: 'WaWi Zugriff',
  can_hold_item: 'Kann Inventar halten',
  manage_article: 'Lager, Art und Einheit managen',
  stocktaking_mode: 'Inventurmodus',
  stocktaking: 'Inventur',
  uploading: 'Hochladen',
  upload_new: 'Neu hochladen.',
  remove: 'Entfernen',
  invalid_profile_upload: 'Ungültiger Profilbild-Upload',
  file_must_be_an_image_file: 'Die Datei muss ein Bildtyp sein',
  clear_profile_pic: 'Profilbild löschen',
  are_you_sure_you_cannot_undo_this_action:
    'Sind Sie sicher? Diese Aktion kann nicht rückgängig gemacht werden',
  upload_label: 'Profilbild hochladen',
  remove_label: 'Profilbild entfernen',
  invalid_file_size: 'Ungültige Dateigröße',
  file_size_info: 'Die Dateigröße muss weniger als 5 MB betragen',
  uploading_profile: 'Profilbild hochladen',
  no_permission_msg:
    'Sie haben keine Zugangsberechtigung. Bitte kontaktieren Sie einen Administrator',
  new: 'Neu',
  fresh: 'Neu',
  consumed: 'Verbraucht',
  employee: 'Benutzer',
  apartmant: 'Objekt',
  confirm: 'Bestätigen',
  transfer_date: 'Übertragungsdatum',
  transfer_source: 'Übertragungsinstanz',
  transfer_by: 'Übertragen von',
  transfer_quantity: 'Übertragungsmenge',
  category: 'Art',
  select_category: 'Kategorie wählen',
  house_number: 'Hausnummer',
  object_image: 'Objektbild',
  house: 'Haus',
  flat: 'Wohnung',
  transfer_item_success: 'Übertragung erfolgreich',
  transfer_item_failed: 'Übertragung fehlgeschlagen',
  none: 'Keine',
  reconciled: 'Inventur',
  web_ui: 'Web',
  app: 'App',
  web_app: 'Web/App',
  select_article_and_quantity_first:
    'Bitte wählen Sie zunächst Artikel und Menge aus',
  min_stock_reached: 'Mindestbestand erreicht!',
  min_stock_reached_description:
    'Dieser Artikel hat den Mindestbestand erreicht, der festgelegt wurde auf ',
  max_stock_reached: 'Höchstbestand überschritten',
  max_stock_reached_description:
    'Dieser Artikel hat den Höchststand überschritten, der festgelegt wurde auf ',
  stocktaking_mode_message: ' Really enter Stocktaking mode.',
  my_inventory: 'Mein Inventar',
  all_my_inventories: 'Mein Inventar',
  transfer: 'Übertragen',
  item: 'Inventar',
  max_min_stock: 'Höchst-/Mindestbestand',
  inventory_detail: 'Inventar Detail',
  qr_code: 'QR Code',
  warehouse_qr_code_confirm:
    'Möchten Sie {noOfShelves} QR-Codes von {warehouseName} drucken?',

  //object-dahboard and cross-selling

  access_object_dashboard: 'Dashboard Zugriff',
  manage_cross_selling: 'Cross Selling verwalten',
  enter_object_name_here: 'Hier Objektnamen eingeben',

  // common
  lang: 'de',
  result: 'Ergebnis',
  no_results_found: 'Keine Ergebnisse gefunden',
  no_records_found: 'Schlüssel nicht gefunden',
  no_options: 'Keine Optionen',
  error: 'Fehler',
  showing: 'Zeige',
  manually: 'Manuell',
  general: 'Allgemein',
  gotoDetails: 'Detail-Seite',
  out_of: 'von',
  loading: 'Lade',
  show_rows: 'Anzahl Reihen',
  error_boundary_heading_text: 'Entschuldiung, etwas ist schiefgelaufen',
  error_boundary_paragraph_text:
    'Unser Team wurde benachrichtigt und wir werden den Fehler so schnell wie möglich beheben',
  reload_page: 'Seite neu laden',
  take_me_home: 'Zur Startseite',
  manual: 'Manuell',
  ['v-office']: 'V-Office',
  guest: 'Gast',
  owner: 'Eigentümer',
  lead: 'Lead',
  supplier: 'Lieferant',
  test: 'Prüfen',
  other: 'Andere',
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  bi_weekly: 'Zweiwöchentlich',
  monthly: 'Monatlich',
  semi_anually: 'Halbjährlich',
  anually: 'Jährlich',
  previous: 'Vorherige',
  next: 'Nächste',
  date_validation_msg: 'Bitte geben Sie ein gültiges Datum an',
  freeze_inventory: 'Inventar einfrieren',
  create_inventory_list: 'Bestandsliste erstellen',
  correct_inventory: 'Korrektes Inventar.',
  create_delta_list: 'Delta-Liste erstellen',
  unfreeze_inventory_end: 'Inventar- und Endbestandsvorrat',
  download_pdf: 'PDF Herunterladen',
  download_excel: 'Excel Herunterladen',
  download__delta_list_pdf: 'DREDA LIST-PDF herunterladen',
  unfreeze_inventory: 'Inventar',
  no_transferring: '(Keine Übertragung von Gegenständen möglich.)',
  download_as:
    '(Laden Sie als PDF herunter und speichern Sie, dann drucken Sie sie an.)',
  transfer_items:
    '(Übertragen Sie die Elemente von oder auf eine Bestandskorrektur.)',
  what_change: '(Welche Änderungen wurden während der Bestellungen getroffen.)',
  step_1: 'Schritt 1',
  step_2: 'Schritt 2',
  step_3: 'Schritt 3',
  step_4: 'Schritt 4',
  step_5: 'Schritt 5',
  actual_quantity: 'Actual Menge.',
  delta: 'delta',
  no_data_found: 'Keine Daten gefunden',
  successful: 'Erfolgreich',

  /*MDM*/
  master_data_magnagement: 'Stammdatenverwaltung',
  mdm: 'Stammdatentool',
  contacts: 'Kontakte',
  contact: 'Kontakt',
  upload_csv: 'CSV Hochladen',
  business: 'Firma',
  language: 'Sprache',
  letter_salutation: 'Brief Anrede',
  letter_closure: 'Brief Abschluss',
  salutation: 'Anrede',
  birthdate: 'Geburtsdatum',
  phone_number: 'Telefonnummer',
  business_phone_number: 'Geschäfts-Telefonnummer',
  mobile_number: 'Mobilnummer',
  fax: 'Fax',
  im: '-im-',
  website: 'Webseite',
  both: 'beide',
  self: 'selbst',
  region: 'Region',
  plant: 'Plant',
  categories: 'Kategorie',
  online: 'Online',
  deactivated: 'Deaktiviert',
  sleeping_facilities: 'Schlafmöglichkeiten',
  longitude: 'Längengrad',
  latitude: 'Breitengrad',
  bedrooms: 'Schlafzimmer',
  bookings: 'Buchungen',
  booking_date: 'Buchungsdatum',
  invoices: 'Rechnungen',
  invoice: 'Rechnungen',
  number: 'Nummer',
  until: 'Bis',
  received_at: 'Erhalten am',
  changed_at: 'Geändert am',
  total: 'Gesamt',
  grand_total: 'Gesamtbetrag',
  marketing_channel: 'Marketing Channel',
  down_payment: 'Anzahlung',
  down_payment_until: 'Anzahlung bis',
  final_payment_until: 'Abschlusszahlung bis',
  ex_price: 'Ex-Preis',
  total_occupancy: 'Gesamtbelegung',
  adult: 'Erwachsene',
  children: 'Kinder',
  babies: 'Babys',
  age_children: 'Alter der Kinder',
  country_customer: 'Land Kunde',
  town_code_customer: 'PLZ Kunde',
  country_objectives: 'Country Objectives',
  rent_statement_exit: 'Mietnachweis existiert',
  invoice_date: 'Rechnungsdatum',
  net: 'Netto',
  due_date: 'Fälligkeitsdatum',
  cancelled_on: 'Storniert am',
  calculation: 'Berechnung',
  provider: 'Anbieter',
  invoice_recepient: 'Rechnungsempfänger',
  warehouse_holds_inventories: 'Das Lager enthält noch Inventar',
  uploads: 'Uploads',
  all_uploads: 'Alle Uploads',
  all_bookings: 'Alle Buchungen',
  all_invoices: 'Alle Rechnungen',
  all_services: 'Alle Services',
  all_logs: 'Alle Logs',
  drag_drop_csv_files_msg: 'Laden Sie hier .csv Dateien hoch (Maximal 10 MB)',
  drag_drop_csv_15MB_files_msg:
    'Laden Sie hier .csv Dateien hoch (Maximal 15 MB)',
  processing_data: 'Daten verarbeiten',
  failed: 'Gescheitert',
  select_calculation: 'Berechnung auswählen',
  select_provider: 'Provider auswählen',
  select_invoice_recepient: 'Invoice Recepient auswählen',
  access_mdm: 'Stammdatentool Zugriff',
  mdm_supporter: 'Stammdatentool Mitarbeiter',
  file_uploaded_successfully: 'Datei erfolgreich hochgeladen',
  uploaded_on: 'Zuletzt aktualisiert am',
  by: 'von',
  delete_article_unit_error:
    'Die Einheit kann nicht gelöscht werden, da sie noch Artikeln zugeordnet ist',
  delete_type_error:
    'Die Art kann nicht gelöscht werden, da sie noch Artikeln zugeordnet ist',
  transferred: 'übertrug',
  internal_server_error: 'Interner Serverfehler',
  cannot_upload_csv_in_worng_category:
    'csv Datei kann nicht in falsche Kategorie hochgeladen werden',
  upload_date: 'Upload Datum',
  crm_employee: 'CRM-Mitarbeiter',
  access_crm: 'CRM Zugriff',
  merge_contacts: 'Kontakte zusammenführen',
  delete_contacts: 'Kontakte löschen',

  access_oms: 'Zugriff Objektmanagement',
  access_bms: 'Zugriff Buchungsmanagement',
  access_bread_service: 'Brötchenservice',
  access_sms: 'Zugriff Servicemanagement',
  location: 'Ort',
  letter_completion: 'Briefabschluss',
  homepage: 'Homepage',

  imports: 'Einfuhren',
  all_imports: 'Alle Einfuhren',
  import_type: 'Art der Einfuhr',
  last_id: 'Letzte Id',
  total_records_imported: 'Importierte Datensätze insgesamt',
  imported_at: 'Eingeführt am',
  availabilities: 'Verfügbarkeiten',

  /* OMS */
  object_management_system: 'Objektverwaltung',
  oms: 'OMS',
  object_list: 'Objektliste',
  status_changed: 'Erfolgreich bearbeitet',
  payement_active: 'Zahlung aktiv',
  website_active: 'Website aktiv',
  video_active: 'Video aktiv',
  seven_for_six: '7 für 6',
  this_discount_will_be_applied_for_season_c:
    'Dieser Rabatt wird nur während der Saison C gewährt.',
  voffice_data_sync: 'V-Office Datensynchronisierung',
  features: 'Features',
  picture: 'Bild',
  text: 'Text',
  cannot_get_apartment_details: 'Kann nicht die Objekt Details abrufen',

  /**SMS Service */
  service_management_system: 'Service Management System',
  sms: 'SMS',
  add_service: 'Service erstellen',
  scheme_type: 'Schematyp',
  service_created: 'Service wurde erstellt',
  service_creation_failed: 'Service konnte nicht erstellt werden',
  service_udpate_failed: 'Service konnte nicht bearbeitet werden',
  add_new_service: 'Service erstellen',
  edit_service: 'Service bearbeiten',
  service_updated: 'Service erfolgreich bearbeitet',
  service_not_found: 'Service nicht gefunden',
  service_details: 'Service Details',
  product_code: 'Produktcode',
  v_office_service: 'VOffice Service',
  price_scheme_type: 'Preistyp',
  by_quantity: 'Nach Menge',
  by_duration: 'Nach Dauer',
  by_day: 'Pro Tag',
  newspaper: 'Zeitung',
  one_time_price: 'Einmaliger Preis',
  by_request: 'Auf Anfrage',
  beach_chair: 'Strandkorb',
  unit_price: 'Einheit Preis',
  price_a: 'Preis A',
  price_b: 'Preis B',
  view_service: 'Service Details',
  delete_service: 'Service löschen',
  service_deleted: 'Service wurde gelöscht',
  day: 'Tag',
  price: 'Preis',
  duration: 'Dauer',
  priceschemebynone: 'Keine',
  priceschemebyday: 'Pro Tag',
  priceschemeonetime: 'Pauschal',
  priceschemebyquantity: 'Nach Menge',
  priceschemebyduration: 'Nach Dauer',
  priceschemebeachchair: 'Strandkorb',
  priceschemebyrequest: 'Auf Anfrage',
  sun: 'So.',
  mon_fri: 'Mo.-Fr.',
  additional_day: 'Zusätzlicher Tag',
  annual_price: 'Jahrespreis',
  duplicate_price_scheme: 'Duplicate Price Scheme',

  /**Service Group */
  service_group: 'Service Gruppe',
  all_service_groups: 'Alle Servicegruppen',
  service_group_list: 'Service Gruppe Liste',
  add_service_group: 'Service Gruppe erstellen',
  booking_type: 'Buchungstyp',
  booking_fee_calculation: 'Buchungsgebühr-Berechnung',
  booking_fee: 'Buchungsgebühr',
  service_group_created: 'Service Gruppe wurde erstellt',
  service_group_creation_failed: 'Service Gruppe konnte nicht erstellt werden',
  service_group_udpate_failed: 'Service Gruppe konnte nicht bearbeitet werden',
  add_new_service_group: 'Servie Gruppe erstellen',
  service_name: 'Service Name',
  frontend_color: 'Frontend Farbe',
  price_per_night: 'Preis pro Nacht',
  show_on_landing_page: 'Auf Startseite zeigen?',
  default_service: 'In Buchungsstrecke zeigen?',
  early_deliverable: 'Frühzeitige Lieferung',
  early_deliverable_on_start: 'Frühzeitige Lieferung bei Beginn',
  pay_to_vendor: 'An den Verkäufer zahlen',
  dog_service: 'Hunde Service',
  icon: 'Icon',
  cover_image: 'Titelbild',
  drag_icon_or_click_to_select_icon: 'Hier klicken, um Icon hochzulagen',
  drag_image_or_click_to_select_image: 'Hier klicken, um Titelbild hochzuladen',
  edit_service_group: 'Service Gruppe bearbeiten',
  service_group_updated: 'Servie Gruppe erfolgreich bearbeitet',
  service_group_not_found: 'Service Gruppe nicht gefunden',
  service_group_details: 'Service Gruppe Details',
  one_time: 'Pauschal',
  per_day: 'Pro Tag',
  per_night: 'Pro Nacht',
  per_week: 'Pro Woche',
  view_service_group: 'Service Gruppe Details',
  delete_service_group: 'Service Gruppe löschen',
  service_group_deleted: 'Service Gruppe wurde gelöscht',
  accept_only_png: 'Bitte .png Datei verwenden',
  enter_booking_fee_in_cents: 'Buchungsgebühr in Cents eingeben',
  enter_price_per_night_in_cents: 'Preis in Cent eingeben',
  enter_value_in_cents: 'Wert in Cents eingeben',
  enter_value_in_euro: 'Wert in Euro eingeben',
  light: 'Hell',
  dark: 'Dunkel',
  price_calculation: 'Preiskalkulation',

  /**DMS Service */
  document_management_system: 'Dokumenten Management System',
  dms: 'DMS',
  general_documents: 'Allgemeine Dokumente',
  document_objects: 'Objekt-Dokumente',
  document_customers: 'Kunden-Dokumente',
  document_owners: 'Eigentümer-Dokumente',
  document_users: 'Nutzer-Dokumente',
  upload_document: 'Dokument hochladen',
  all_documents: 'Alle Allgemeine Dokumente',
  all_customers: 'Alle Kunden-Dokumente',
  all_owners: 'Alle Eigentümer-Dokumente',
  all_object_documents: 'Alle Objekt-Dokumente',
  all_user_documents: 'Alle Nutzer-Dokumente',
  documents: 'Dokumente',
  document_name: 'Dokument Name',
  owner_name: 'Eigentümer',
  availability: 'Verfügbarkeit',
  edit_general_document: 'Dokument bearbeiten',
  edit_document_object: 'Objekt-Dokument bearbeiten',
  edit_document_owner: 'Eigentümer-Dokument bearbeiten',
  edit_document_customer: 'Kunden-Dokument bearbeiten',
  upload_general_document: 'Allgemeines Dokument hochladen',
  upload_document_object: 'Objekt-Dokument hochladen',
  upload_document_owner: 'Eigentümer-Dokument hochladen',
  upload_document_customer: 'Kunden-Dokument hochladen',
  to_objects: 'Objekt',
  no_of_documents: 'Anzahl Dokumente',
  select_object: 'Objekt auswählen',
  general_document_deleted: 'Dokument wurde gelöscht',
  delete_general_document: 'Dokument löschen',
  general_document_not_found: 'Dokument nicht gefunden',
  general_document_udpate_failed: 'Dokument bearbeiten fehlgeschlagen',
  general_document_created: 'Dokument wurde erstellt',
  general_document_creation_failed: 'Dokument konnte nicht erstellt werden',
  general_document_updated: 'Document updated successfully',
  customer_documents: 'Kunden-Dokumente',
  owner_documents: 'Eigentümer-Dokumente',
  object_documents: 'Objekt-Dokumente',
  users_document: 'Nutzer-Dokumente',
  upload_user_document: 'Nutzer-Dokument hochladen',
  edit_user_document: 'Nutzer-Dokument bearbeiten',
  import_file: 'Datei importieren',
  the_file_field_is_required: 'Das Dateifeld ist erforderlich',

  /**BMS Service */
  booking_management_system: 'Buchung Management System',
  bms: 'BMS',
  subtotal: 'Zwischensumme',
  vat19: '19% MwSt. auf den Lieferservice',
  vat7: '7% MwST',
  payment_method: 'Zahlungsmethode',
  bank_transfer: 'Banküberweisung',
  paid_amount: 'Bezahlter Betrag',
  without_tax: 'Ohne MwSt.',
  including: 'Inklusive',
  callback: 'Rückruf',
  callbacks: 'Rückrufe',
  all_callbacks: 'Alle Rückrufe',
  callback_date: 'Rückrufdatum',
  callback_detail: 'Rückrufdetails',
  callback_deleted_successfully: 'Rückruf wurde erfolgreich gelöscht',
  no_callback_details_found: 'Keine Rückrufdetails gefunden',
  inquiry: 'Anfrage',
  mr: 'Herr',
  mrs: 'Frau',
  misc: 'Divers',
  check_in_out: 'Einchecken/Auschecken',
  check_in: 'Einchecken',
  check_out: 'Auschecken',
  check_in_warning_message:
    'Im nächsten Schritt werden dem Gast die Buchungsdetails zur Kontrolle angezeigt. Anpassungen oder Ergänzungen können auch nachträglich noch vorgenommen werden.',
  did_you_choose_right_guest: 'Haben Sie den richtigen Gast ausgewählt?',
  display_booking_details_confirmation_question:
    'Sollen die Buchungsdaten des folgenden Gastes angezeigt werden?',
  mirror_booking_details: 'Spiegel Buchungsdetails',
  check_out_confirmation_question:
    'Möchten Sie Check-Out für den Gast {0} jetzt durchführen?',
  check_in_confirmation_question: 'Möchten Sie jetzt als Gast {0} einchecken?',
  check_in_confirmation_message:
    'Bitte vergewissern Sie sich, dass der Gast die Buchungsinformationen gelesen hat.',
  check_booking_details: 'Buchungsdetails prüfen',
  marketing_email: 'E-Mail-Marketing',
  marketing_email_settings: 'E-Mail Marketing Einstellungen',
  how_many_days_before_arrival_should_the_marketing_mail_be_sent:
    'Wie viele Tage vor der Ankunft des Gastes sollte die Werbesendung verschickt werden?',
  /**BS Service */
  bread_system: 'Brötchenbuchungen',
  bs: 'BS',
  all_billings: 'Alle Abrechnungen',
  all_daily_view: 'Alle Tagesansichten',
  all_driving_order: 'Fahrstrecke',

  /**PMS Service */
  payment_management_system: 'Zahlungs-Verwaltungs-System',
  pms: 'PMS',
  credit_card: 'Kreditkarten-Zahlungen',
  all_credit_cards: 'Kreditkarten-Zahlungen',
  paypal: 'PayPal-Zahlungen',
  invoice_payment: 'Rechnung-Zahlung',
  all_paypals: 'PayPal-Zahlungen',
  credit_card_no: 'Kreditkartennummer',
  amount: 'Betrag',
  paypal_address: 'PayPal Adresse',
  send: 'Senden',
  access_pms: 'ZVS Zugriff',
  payment_links: 'Zahlungslinks',
  send_on: 'Weiterleitung',
  first_sent_on: 'Erstmals gesendet am',
  last_sent_on: 'Zuletzt gesendet am',
  paid_on: 'Bezahlt am',
  all_payment_links: 'Alle Zahlungslinks',
  create_payment_links: 'Zahlungslink erstellen',
  send_payment_link: 'Zahlungslink senden',
  payment_link_sending_failed: 'Zahlungslink konnte nicht gesendet werden.',
  payment_link_send_successfully: 'Zahlungslink erfolgreich gesendet.',
  identifier: 'Kennung',
  amount_is_required: 'Betrag ist erforderlich',
  required_booking_number: 'Buchungsnummer ist erforderlich.',
  required_customer_name: 'Kundenname ist erforderlich.',
  paid: 'Bezahlt',
  resent_mail_success: 'E-Mail erneut gesendet erfolgreich',
  resent_mail_error: 'E-Mail erneut gesendet fehlgeschlagen',
  confirm_resend_email:
    'Sind Sie sicher, dass Sie die E-Mail erneut senden möchten?',
  service_reservation: 'Service-Buchung',
  apartment_reservation: 'Objekt-Buchung',
  ferry_booking: 'Fährbuchung',
  payment_link: 'Zahlungs-Link',
  /**PNS Service */
  push_notification_system: 'Push-Benachrichtigungen',
  all_notifications: 'Alle Benachrichtigungen',
  pns: 'PNS',
  notifications: 'Benachrichtigungen',
  push_notification: 'Push-Benachrichtigung',
  add_new_push_notification: 'Neue Push-Benachrichtigung',
  link: 'Link',
  total_read: 'Gesamt/Gelesen',
  add_push_notification: 'Push-Benachrichtigung hinzufügen',
  notification_created: 'Push-Benachrichtigung gesendet',
  notification_creation_failed:
    'Push-Benachrichtigung konnte nicht gesendet werden',
  access_pns: 'Push Zugriff',
  manage_pns: 'Push-Benachrichtigungen senden',
  image_link: 'Bild-Link',
  pns_notification_confirm:
    'Achtung: Benachrichtigung wird gesendet, Fortfahren?',

  /**FAQ Service */
  frequently_ask_question: 'Häufig gestellte Fragen',
  faq: 'FAQs',
  all_faq: 'Alle FAQs',
  internal_faq: 'Interne FAQs',
  all_internals: 'Alle Interne FAQs',
  success: 'Erfolg',
  group: 'Gruppe',
  all_groups: 'Alle Gruppen',
  add_faq: 'FAQ Hinzufügen',
  edit_faq: 'FAQ Bearbeiten',
  delete_faq: 'FAQ Löschen',
  select_group: 'Gruppe auswählen',
  alias: 'Alias',
  web: 'Web',
  internes_wissensdokument: 'Internes_Wissensdokument',
  dateien_hochladen: 'Dateien_Hochladen',
  pdf_upload: 'PDF Upload',
  faq_deleted_successfully: 'FAQ wurde erfolgreich gelöscht',

  faq_group: 'FAQ Gruppe',
  add_group: 'Gruppe Hinzufügen',
  edit_group: 'Gruppe Bearbeiten',
  delete_group: 'Gruppe Löschen',
  faq_group_deleted_successfully: 'FAQ Gruppe wurde erfolgreich gelöscht',

  reservations: 'Reservierungen',
  all_reservations: 'Alle Reservierungen',
  all_requests: 'Alle Anfragen',
  reservation_requests: 'Reservierungsanfrage',
  ferry_bookings: 'Fährbuchungen',
  bread_bookings: 'Brötchen Buchungen',
  bread_daily_view: 'Brötchen Tagesansicht',
  bread_driving_order: 'Brötchen Fahrstrecke',
  save_inorder_to_apply_changes: 'Änderungen speichern, um sie zu übernehmen',

  /** Bread Boking */
  bread_booking: 'Brötchen Buchungen',
  add_bread_booking: 'Neue Brötchenbestellung',
  update_bread_booking: 'Brötchenbuchung aktualisieren',
  pending: 'Ausstehend',
  confirmed: 'Bestätigt',
  cancelled: 'Storniert',
  booking_number: 'Buchungsnummer',
  add_new_bread_booking: 'Speichern',
  edit_bread_booking: 'Brötchenbestellung bearbeiten',
  bread_booking_updated: 'Brötchenbestellung erfolgreich geändert',
  cannot_change_quantity_in_past:
    'Bestellmengen in der Vergangenheit können nicht geändert werden',
  multiple_bookings_in_same_date_not_allowed:
    'Mehrere Buchungen für den gleichen Zeitraum sind nicht möglich',
  bread_booking_not_found: 'Brötchenbestellung nicht gefunden',
  bread_booking_details: 'Detail',
  bread_booking_deleted: 'Brötchenbestellung wurde gelöscht',
  view_bread_booking: 'Brötchenbestellung Detail',
  delete_bread_booking: 'Brötchenbestellung löschen',
  edit_order: 'Bestellung bearbeiten',
  cancel_order: 'Bestellung stornieren',
  property_name: 'Objektname',
  customer_contact: 'Kunden Telefonnummer',
  booking_period: 'Buchungszeitraum',
  timespan: 'Buchungszeitraum',
  bread_booking_created: 'Brötchenbestellung wurde erstellt',
  bread_booking_creation_failed:
    'Brötchenbestellung konnte nicht erstellt werden',
  bread_booking_udpate_failed:
    'Brötchenbestellung konnte nicht bearbeitet werden',
  include_departure_day: 'Lieferung am Abreisetag',
  price_pound: 'Preis (€)',
  booking_fee_pound: 'Buchungsgebühr (€)',
  total_pound: 'Gesamt (€)',
  must_be_date_after_today_or_equal_to_today:
    'muss ein Datum nach dem today oder gleich dem today sein.',
  invalid_data: 'Die angegebenen Daten sind ungültig',
  booking_invalid: 'Die Buchungsnummer ist nicht korrekt',
  service_invalid: 'Leistung ist nicht korrekt',
  customer_invalid: 'Kundenname ist richtig',

  /**Driving Order */
  driving_order: 'Fahrstrecke',
  import_driving_order: 'Fahrstrecke',
  import_driving_success: 'Fahrstrecke erfolgreich importiert',
  import_driving_failed: 'Fahrstrecke konnte nicht importiert werden',

  /** Bread Daily View */
  property: 'Objekt',
  guest_number: 'Gastnummer',
  brotchen: 'Brötchen',
  bread_daily_view_details: 'Brötchenbestellung Details',
  view_bread_daily_view: 'Brötchen Tagesansicht',
  download: 'Herunterladen',
  download_image: 'Download Image',
  download_driving_order: 'Fahrstrecke herunterladen',

  /** Bread Daily View */
  bread_booking_billing: 'Brötchen Abrechnung',
  bread_booking_billing_detils: 'Brotbuchung Abrechnungsdetails',
  bread_booking_billing_edit: 'Brot buchen Abrechnung bearbeiten',
  bread_type: 'Brot Art',
  old_price: 'Alter Preis',
  new_price: 'Neuer Preis',
  price_difference: 'Preisunterschied',
  total_bread: 'Gesamt Brot',
  total_fee: 'Gesamtgebühr',
  total_price_difference: 'Gesamtpreisdifferenz',
  bread_quantity_difference: 'Gesamtdifferenz bei Brot',
  total_fee_difference: 'Gebührenunterschied insgesamt',
  grand_total_difference: 'Gesamtdifferenz',
  update_bill: 'Rechnung aktualisieren',
  close_bill: 'Rechnung abschließen',
  this_bill_will_be_closed_permanently:
    'Diese Rechnung wird dauerhaft geschlossen. Sobald sie geschlossen ist, können Sie keine Änderungen mehr daran vornehmen.',

  /** Reservation Request */
  reservation_request_details: 'Reservierungsanfrage Detail',
  booking_detail: 'Buchung Detail',
  reservation_detail: 'Reservierung Detail',
  adults: 'Erwachsene',
  customer_detail: 'Kunden Detail',
  sur_name: 'Nachname',
  reservation_details: 'Reservierungsdetails',
  service_line: 'Services',
  view_reservation_request_view: 'Reservierungsanfrage Detailansicht',
  dog: 'Hund',
  dog_breed: 'Hunderasse',

  /** Ferry Booking */
  view_ferry_booking_view: 'Fährbuchung Detailansicht',
  ferry_booking_details: 'Fährbuchung Detail',
  booking_amount: 'Buchungsbetrag',
  trip_date: 'Datum der Reise',
  time: 'Uhrzeit',
  trip_voyage: 'Reise-Reise',
  departure_harbor: 'Abfahrtshafen',
  destination_harbor: 'Zielhafen',
  trip_return_date: 'Rückreisedatum',
  vehicle_type: 'Fahrzeugtyp',
  licence_plate: 'Kennzeichen',
  length: 'Länge',
  customer_email: 'Kunden-E-Mail',
  trip_detail: 'Reisedetail',
  vehicle_detail: 'Fahrzeugtyp',
  cm: 'cm',
  ferry_booking_cancel_success: 'Fährbuchung wurde storniert.',
  ferry_booking_cancel_failed: 'Fährbuchung storniert fehlgeschlagen.',

  data_synced: 'Daten synchronisiert',
  last_v_office_data_sync: 'Letzte Synchronisierung mit VOffice',
  access_reservations: 'Reservierungen',
  access_bookings: 'Buchungen',
  manage_bread_service: 'Brötchenservice bearbeiten',
  access_bread_driving_order: 'Brötchenservice Fahrstrecke',
  access_callbacks: 'Rückruf-Liste',
  booking_terminals: 'Buchungs-Terminals',

  access_faq: 'FAQ Zugriff',
  manage_faq: 'FAQ bearbeiten',
  access_faq_internal: 'Internes FAQ Zugriff',
  access_faq_group: 'FAQ-Gruppen Zugriff',
  manage_faq_group: 'FAQ-Gruppen bearbeiten',

  access_dms_general: 'Allgemeine Dokumente Zugriff',
  manage_dms_general: 'Allgemeine Dokumente bearbeiten',
  access_dms_object: 'Objekt-Dokumente Zugriff',
  manage_dms_object: 'Objekt-Dokumente bearbeiten',
  access_dms_customer: 'Kunden-Dokumente Zugriff',
  manage_dms_customer: 'Kunden-Dokumente bearbeiten',
  access_dms_owner: 'Eigentümer-Dokumente Zugriff',
  manage_dms_owner: 'Eigentümer-Dokumente bearbeiten',
  access_dms_user: 'Benutzer-Dokumente Zugriff',
  manage_dms_user: 'Benutzer-Dokumente bearbeiten',
  select_date: 'Datum auswählen',
  select_date_time: 'Datum/Uhrzeit auswählen',

  subscribe: 'Abonniert',
  unsubscribe: 'Nicht abonniert',
  /*Internet Status*/
  wifi: 'Ihre Internetverbindung wurde wiederhergestellt.',
  nowifi: 'Sie sind derzeit offline.',
  refresh: 'Aktualisieren',

  /*Contact Center*/
  access_contact_center: 'Contact Center Zugriff',

  /*Newsletter*/
  newsletter: 'Newsletter',
  newsletter_contact: 'Newsletter',
  newsletters: 'Newsletters',
  draft: 'Entwurf',
  drafts: 'Entwürfe',
  group_of_recipients: 'Gruppe von Empfängern',
  all_newsletters: 'Alle Newsletters',
  new_newsletter: 'Neuer Newsletter',
  subject: 'Betreff',
  all_draft: 'Alle Entwürfe',
  message: 'Nachricht',
  attach_file: 'Datei anhängen',
  attachments: 'Anhänge:',
  preview: 'Vorschau',
  save_as_draft: 'Als Entwurf speichern',
  compose_message: 'Nachricht verfassen',
  preview_message: 'Vorschau Newsletter',
  download_our_app_now: 'Laden Sie unsere App jetzt herunter',
  contact_with_us: 'Kontakt mit uns',
  about_us: 'Über uns',
  customer_care: 'Kundenbetreuung',
  contact_us: 'Kontaktieren Sie uns',
  privacy_policy: 'Datenschutzbestimmungen',
  select_recipient_group: 'Empfänger auswählen',
  edit_newsletter: 'Newsletter bearbeiten',
  delete_newsletter: 'Newsletter löschen',
  newsletter_deleted: 'Newsletter gelöscht.',
  newsletter_delete_error: 'Newsletter-Löschung fehlgeschlagen.',
  newsletter_sent_sucessfully: 'Newsletter erfolgreich gesendet.',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter erfolgreich als Entwurf gespeichert.',
  newsletter_updated_sucessfully: 'Newsletter erfolgreich aktualisiert.',
  hello: 'Hallo',
  to_send: 'Zum Senden',
  save_and_send: 'Speichern und Senden',
  recipient_group_field_is_required:
    'Das Feld Empfängergruppe ist erforderlich.',
  subject_field_is_required: 'Betrefffeld ist erforderlich.',
  message_field_is_required: 'Nachrichtenfeld ist erforderlich.',
  send_newsletter: 'Newsletter versenden',
  attention_notification_will_be_send_continue:
    'Achtung: Newsletter wird gesendet, Fortfahren',
  privacy: 'Datenschutz',
  imprint: 'Impressum',
  text_search: 'Textsuche',

  /*required*/
  /*required*/
  required_first_name: 'Vorname ist erforderlich',
  required_last_name: 'Nachname ist erforderlich',
  required_email: 'E-Mail ist erforderlich',
  required_cellphone: 'Mobiltelefon ist erforderlich',
  required_role: 'Rolle ist erforderlich',
  required_address: 'Adresse ist erforderlich',
  required_repate_from: 'Wiederholung von ist erforderlich',
  required_repeate_until: 'Wiederholung der Einheit ist erforderlich',
  required_interval: 'Rekursives Intervall ist erforderlich',
  required_label: 'Bezeichnung ist erforderlich',
  required_type: 'Typ ist erforderlich',
  required_article: 'Artikel ist erforderlich',
  required_destination: 'Ziel ist erforderlich',
  required_source: 'Quelle ist erforderlich',
  required_quantity: 'Menge ist erforderlich',
  required_article_name: 'Artikelname ist erforderlich',
  required_unit: 'Einheit ist erforderlich',
  required_type_name: 'Typname ist erforderlich',
  required_unit_name: 'Einheitsname ist erforderlich',
  required_descriptions: 'Beschreibungen sind erforderlich',
  required_document_name: 'Dokumentname ist erforderlich',
  required_Object: 'Objekt ist erforderlich',
  required_upload: 'Datei hochladen ist erforderlich',
  required_service_name: 'Dienstname ist erforderlich',
  required_voffice_service: 'VOffice Dienst ist erforderlich',
  required_service_group: 'Dienstgruppe ist erforderlich',
  required_cover_image: 'Titelbild ist erforderlich',
  required_booking_type: 'Buchungsgebührentyp ist erforderlich.',
  required_booking_fee: 'Buchungsgebühr ist erforderlich.',
  required_price_per_night: 'Preis pro Nacht ist erforderlich.',
  required_icon: 'Upload-Symbol ist erforderlich',
  required_group_name: 'Gruppenname ist erforderlich',
  required_status: 'Status ist erforderlich',
  required_group_recipients: 'Gruppe von Empfängern ist erforderlich',
  required_subject: 'Betreff ist erforderlich',
  required_message: 'Nachricht ist erforderlich',
  required_room: 'Raum ist erforderlich.',
  required_rack: 'Regal ist erforderlich.',
  required_shelf: 'Regal ist erforderlich.',
  required_warehouse_name: 'Der Name des Lagers ist erforderlich.',
  file_size_is_too_large: 'Die Dateigröße ist zu groß.',
  required_customer: 'Kunde ist erforderlich',
  required_owner: 'Der Eigentümer ist erforderlich.',
  required_user: 'Benutzer ist erforderlich.',
  required_front_color: 'Frontend-Farbe ist erforderlich.',
  only_one_file_is_allowed: 'Nur 1 Datei ist erlaubt',
  please_fill_in_all_mandatory_fields:
    'Alle Pflichtfelder müssen ausgefüllt werden.',

  // *Message*/
  email_taken: 'Die E-Mail wurde bereits angenommen.',
  access_newsletter: 'Newsletter und Entwürfe Zugriff',
  manage_newsletter: 'Newsletter managen',

  /*Key*/
  key: 'Schlüssel',
  keyscan: 'Schlüsselscan',
  access_keyscan_keylist: 'Zugriff Schlüsselverwaltung',
  access_keyscan_keylog: 'Schlüsselausgabe Zugriff',
  key_management_system: 'Schlüsselverwaltungssystem',
  key_list: 'Schlüsselliste',
  key_logs: 'Schlüsselprotokolle',
  key_management: 'Schlüsselverwaltung',
  all_key_logs: 'Alle Schlüsselprotokolle',
  search_customer: 'Kunde/Benutzer suchen',
  key_id: 'Schlüssel-Id',
  scan_key: 'Schlüssel scannen',
  key_handling: 'Schlüssel-Behandlung',
  key_information: 'Schlüsselinformationen',
  key_current_status: 'Aktueller Status des Schlüssels',
  new_status: 'Neuer Status',
  rfid_message: 'RFID-Scan erfolgreich',
  rescan_key: 'Schlüssel erneut scannen',
  key_new_status: 'Schlüssel Neuer Status',
  place_rfid: 'Schlüssel auf RFID-Lesegerät legen. ',
  all_key_list: 'Alle Schlüssel-Liste',
  add_key: 'Schlüssel hinzufügen',
  registered_date: 'Registriertes Datum',
  registered_by: 'Registriert von',
  edit_key: 'Schlüssel bearbeiten',
  delete_key: 'Schlüssel löschen',
  view_key: 'Ansichtsschlüssel',
  given_out: 'Ausgegeben',
  in_stock: 'Auf Lager',
  lost: 'Verloren',
  key_history: 'Schlüsselverlauf',
  key_created_successfully: 'Schlüssel erfolgreich erstellt',
  key_created_error: 'Fehler bei der Schlüsselerstellung',
  key_updated_successfully: 'Schlüssel Erfolgreich aktualisiert',
  key_updated_error: 'Fehler bei der Schlüsselaktualisierung',
  key_deleted_successfully: 'Erfolgreich gelöschte Taste',
  key_deleted_error: 'Schlüssel-Löschfehler',
  rescan: 'Rescan',
  rfid_taken_error: 'RFID-Schlüssel bereits vergeben',
  scanner_title_message: 'Bitte den Schlüssel auf den RFID-Scanner legen.',
  scanner_message: 'Schlüssel richtig aufgelegt?',
  key_no_registered: 'Der Schlüssel ist noch nicht registriert worden.',
  print_qr: 'QR Code drucken',
  key_removed_successfully: 'Schlüssel erfolgreich entfernt',
  key_scanned_successfully: 'Schlüssel erfolgreich gescannt',
  invalid_object_key: 'Ungültiger Objektschlüssel',

  /*Aerial Map */
  aerial_map: '360° Karte',
  three_sixty_degree: '360°',
  standard: 'Standard',
  access_aerial_map: '360° Karte Zugriff',
  map: 'Karte',

  /*Language*/
  description_german: 'Beschreibung Deutsch',
  description_english: 'Beschreibung Englisch',
  description_polish: 'Beschreibung Polnisch',
  description_danish: 'Beschreibung Dänisch',
  description_chinese: 'Beschreibung Chinesisch',
  de: 'Deutsch',
  en: 'Englisch',
  pl: 'Polnisch',
  da: 'Dänisch',
  zh: 'Chinesisch',
  no_of_print: 'Anzahl der Drucke',
  article_inactive: 'Artikel ist nicht aktiv',
  second_screen_welcome_text: 'Willkommen In List auf Sylt',
  open_second_screen: 'Zweiten Bildschirm öffnen',
  show_welcome_screen: 'Willkommensbildschirm anzeigen',
  welcome: 'Willkommen',
  booked_service: 'Gebuchte Services',
  people: 'Personen',
  three_sixty_active: '360° Aktiv',
  can_only_be_enabled_apartment_with_VR:
    'Kann nur für Objekte mit VR-Tour aktiviert werden.',
  can_only_be_enabled_object_with_video_url:
    'Kann nur für Objekte mit video url aktiviert werden.',
  other_see_description: 'Sonstiges (siehe Beschreibung)',
  app_vermittl_fam_clausen: 'App.Vermittl.Fam.Clausen',
  select_time: 'Zeit auswählen',
  close_all_active_chats: 'Alle aktiven Chats schließen',
  confirm_close_all_active_chats:
    'Sind Sie sicher, dass Sie alle aktiven Chats schließen wollen?',
  successfully_reset_all_active_chats:
    'Alle aktiven Chats wurden erfolgreich zurückgesetzt.',
  responsibility: 'Verantwortlichkeit',
  person_in_charge: 'Verantwortliche*r',
  substitute_person_in_charge: 'Stellvertretender Verantwortlicher',
  successfully_updated_responsible_person:
    'Erfolgreich aktualisierte verantwortliche Person.',
  responsible_person_cannot_be_same:
    'Verantwortliche Person und stellvertretende verantwortliche Person können nicht identisch sein',
  select_responsible_person: 'Verantwortliche Person auswählen',
  voffice_contact_detail_page: 'VOffice Kontakt Detailseite',
  repairs: 'Reparaturen',
  owner_allowed_repair: 'Erlaubt der Eigentümer Reparaturen?',
  inform_owner: 'Eigentümer informieren?',
  how_expensive_repairs_can_be_commissioned_without_consulting_with_the_owner:
    'Wie teuer können Reparaturen ohne Rücksprache mit dem Eigentümer in Auftrag gegeben werden?',

  add_note: 'Notiz hinzufügen',
  write_note_here: 'Schreiben Sie hier Notizen',
  characters: 'Zeichen',
  maximum_200_character: 'Maximal 200 Zeichen',
  latest_update: 'Letzte Aktualisierung',
  updated_by: 'Aktualisiert von',
  successfully_added_note: 'Erfolgreich eine Notiz hinzugefügt',
  successfully_deleted_note: 'Erfolgreich eine Notiz gelöscht',
  successfully_edited_note: 'Erfolgreich eine Notiz bearbeitet',
  edit_note: 'Anmerkung bearbeiten',
  are_you_sure: 'Sind Sie sicher? ',
  do_you_really_want_to_delete_this_note_this_cannot_be_undone:
    'Wollen Sie diese Notiz wirklich löschen? Dies kann nicht rückgängig gemacht werden',
  in_euro: 'In Euro',
  maximum_notes_range_reached: 'Maximaler Notenbereich erreicht ',
  object_dashboard: 'Verantwortung',
  cross_selling: 'Cross Selling',
  all_cross_selling: 'Alle Cross Selling',
  add_cross_selling: 'Neues Cross Selling hinzufügen',
  edit_cross_selling: 'Cross Selling bearbeiten',
  delete_cross_selling: 'Cross Selling löschen',
  cross_selling_not_found: 'Cross Selling nicht gefunden.',
  successfully_added_cross_selling:
    'Erfolgreiche Einführung eines Cross Selling',
  successfully_deleted_cross_selling: 'Erfolgreich gestrichenes Cross Selling.',
  successfully_edited_cross_selling:
    'Erfolgreich ein Cross Selling-Projekt bearbeitet',
  select_language_description: 'Bitte wählen Sie eine Sprachbeschreibung',
  saved_email: 'Gespeicherte E-Mail',
  infos: 'Infos',
  other_objects: 'Andere Objekte',
  successfully_updated_marketing_email_days:
    'Erfolgreich aktualisierte Marketing-E-Mail-Tage.',
  email_date: 'E-Mail Datum',
  planned: 'Geplant',
  classification: 'Klassifizierung',
  object_classification: 'Objektklassifizierung',
  select_tier: 'Stufe auswählen',
  tier: 'Stufe',
  failed_to_update_object_tier:
    'Aktualisierung der Objektebene fehlgeschlagen.',
  successfully_updated_object_tier: 'Erfolgreich aktualisierte Objektebene.',
  contract_signing_required: 'Vertragsunterzeichnung erforderlich',
  contract_signing_message:
    'Es scheint, dass der Vertrag noch nicht unterzeichnet wurde. Bitte unterschreiben Sie den Vertrag, um fortzufahren',
  signed: 'Unterzeichnet',
  contract_signed_successfully: 'Erfolgreich unterzeichneter Vertrag ',
  contract_signed_failed: 'Unterzeichneter Vertrag Gescheitert',
  //Membership Level
  membership_level: 'Memberstufe',
  membership: 'Membership',
  membership_list: 'Membership Liste',
  list: 'Liste',
  number_of_booking_in_level: 'Anzahl Buchungen in Stufe',
  update_number_of_booking_in_level: '{0} Anzahl Buchungen in Stufe',
  published: 'Veröffentlicht',
  internal: 'Intern',
  add_new_member_level: 'Neue Mitgliedsstufe hinzufügen',
  name_membership_level: 'Name Memberstufe',
  brief_description: 'Kurzbeschreibung',
  edit_member_level: 'Memberstufe bearbeiten',
  required_membership_level: 'Erforderliche Memberstufe',
  detail_member_level: 'Details zur Memberstufe',
  booking_discount: '% Rabatt auf: Endreinigung',
  final_cleaning_discount: '% Rabatt auf: Endreinigung',
  laundry_discount: '% Rabatt auf: Wäschegebühr',
  website_condition_discount:
    'Buchungsrabatt (in %) auf Übernachtungssumme (ohne Extra-Leistungen)',
  membership_delete: 'Memberstufe gelöscht',
  membership_delete_error: 'Fehler beim Löschen der Mitgliedschaft Level',
  message_update_membership_value: 'Sind Sie sicher, dass Sie das wollen {0}?',
  special_conditions_on_the_website: 'Sonderkonditionen auf der Webseite:',
  un_publish: 'nicht veröffentlichen',
  mark_as_internal: 'als intern markieren',
  remove_from_internal: 'von intern entfernen',

  //Third Party Partners
  access_third_party_partner: 'Access Third Party Partner',
  manage_third_party_partner: 'Zarządzaj partnerem zewnętrznym',
  third_party_partner: 'Drittpartner',
  add_new_third_party_partner: 'Neuen Drittpartner hinzufügen',
  edit_third_party_partner: 'Drittpartei bearbeiten',
  name_third_party_partner: 'Name Drittpartner',
  required_third_party_partner: 'Erforderlicher Drittparteipartner',
  detail_third_party_partner: 'Drittpartner Detailseite',
  third_party_partner_delete: 'Third Party Partner gelöscht',
  third_party_partner_error: 'Fehler beim Löschen von Third Party Partner',

  //Extra Goodies
  extra_goodies: 'Extra-Goodies',
  name_extra_goodies: 'Name Extra-Goodie',
  required_extra_goodies: 'Erforderliche zusätzliche Goodies',
  extra_goodies_delete: 'Extra Goodies gelöscht',
  extra_goodies_error: 'Fehler beim Löschen von Extra Goodies',
  add_extra_goodies: 'Neues Extra-Goodie',
  extra_goodies_delete_msg:
    'Möchten Sie das Goodie {0} wirklich dauerhaft löschen',

  message_active_inactive:
    'Sind Sie sicher, dass Sie den Status auf {0} setzen wollen?',

  //Restaurant Management
  restaurant: 'Restaurant',
  restaurant_list: 'Restaurant Liste',
  reservation_count: 'Anzahl der Reservierungen',
  add_new_restaurant: 'Neues Restaurant hinzufügen',
  edit_restaurant: 'Restaurant bearbeiten',
  is_top_pick: 'Top-Pick',
  required_restaurant_name: 'Restaurantname ist erforderlich',
  short_description: 'Kurzbeschreibung',
  logo: 'Logo',
  zip_code: 'Postleitzahl',
  restaurant_delete: 'Restaurant erfolgreich gelöscht',
  restaurant_error: 'Fehler beim Löschen des Restaurants',
  restaurant_detail: 'Restaurant details',
  menu: 'Menü',
  opening_time: 'Öffnungszeiten',
  timing: 'Zeitpläne',
  view_menu: 'Menü ansehen',
  view_website: 'Website ansehen',
  required_street: 'Straße ist erforderlich',
  required_house_number: 'Hausnummer ist erforderlich',
  required_zip_code: 'Postleitzahl ist erforderlich',
  required_location: 'Ort ist erforderlich',

  message_add_remove_from_top_pick:
    'Sind Sie sicher, dass Sie {0} das Restaurant von oben auswählen wollen?',

  restaurant_reservation: 'Restaurant-Reservierung',
  restaurant_reservation_list: 'Restaurant-Reservierungsliste',
  booking_time: 'Buchungszeit',
  restaurant_name: 'Restaurant Name',
  restaurant_reservation_detail: 'Restaurant Reservierungsdetails',
  no_of_people: 'Anzahl der Personen',
  alternative_booking_date: 'Alternatives Buchungsdatum',
  alternative_booking_time: 'Alternative Buchungszeit',
  additional_request: 'Zusätzliche Anfrage',

  sylt_go: 'Sylt Go',

  //Meer Sylt
  meer_sylt: 'Meer Sylt Kacheln',
  add_meer_sylt: 'Meer Sylt Kacheln erstellen',
  is_load_on_iframe: ' innerhalb eines iframe geladen',
  edit_meer_sylt: 'Bearbeiten Meer Sylt Kacheln',
  delete_meer_sylt: 'Meer Sylt Kacheln löschen',
  view_meer_sylt: 'Ansicht Meer Sylt Kacheln',
  meer_sylt_deleted_successfully: 'Meer Sylt Kacheln Erfolgreich Gelöscht',
  meer_sylt_details: 'Meer Sylt Kacheln Details',
  visit_link: 'Link besuchen',
  valid_link: 'Bitte geben Sie einen gültigen Link an.',
  message_update_with_value: 'Sind Sie sicher, dass Sie {0}?',
  activate_message: ' wollen meer sylt kacheln aktivieren',
  deactivate_message: 'möchte meer sylt kacheln deaktivieren',
  load_within_iframe_message: 'möchte dies innerhalb des iframe anzeigen',
  website_link_message: 'möchte dies nicht innerhalb des iframe anzeigen',
  link_is_required: 'Link ist erforderlich',
  meer_sylt_tiles: 'Meer Sylt Kacheln',
  meer_sylt_updated_successfully: 'Meer Sylt Kacheln Erfolgreich Aktualisiert',
  meer_sylt_created_successfully: 'Meer Sylt Kacheln Erfolgreich Erstellt',

  failed_to_update: 'Bilddetails konnten nicht aktualisiert werden.',
  successfully_updated_image_details: 'Bilddetail erfolgreich aktualisiert.',
  update_image_detail: 'Bilddetails aktualisieren',
  fileName: 'Dateiname',
  altTag: 'Alt-Tag',
  imageCaption: 'Bildunterschrift',
  filename_already_exists: 'Dateiname existiert bereits.',

  add_images: 'Bilder hinzufügen',
  successfully_added_images: 'Erfolgreich hochgeladene Bilder.',
  failed_to_upload_images: 'Bilder konnten nicht hochgeladen werden.',
  successfully_deleted_image: 'Erfolgreich ein Bild gelöscht',
  delete_image: 'Bild löschen',
  manage_restaurant: 'Restaurant leiten',
  slug: 'Schnecken',
  possible: 'Möglich',
};

export default de;
