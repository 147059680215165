import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import InventoryResource from 'api/inventories';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  ArticleSchema,
  InventoryUserSchema,
  InventoryWarehouseSchema,
} from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const InventoryDetails: React.FC = () => {
  const { id }: { id: string } = useParams();
  const inventoryAPI = new InventoryResource();
  const { search } = useLocation();

  const { isLoading, data } = useQuery(
    [`inventory-detail-${id}`, id],
    () => inventoryAPI.get(id).then((res) => res.data.data),
    { cacheTime: 0, refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return <CenterSpinner />;
  }

  const {
    warehouses,
    users,
    article,
  }: {
    warehouses: [InventoryWarehouseSchema];
    users: [InventoryUserSchema];
    article: ArticleSchema;
  } = data;

  const totalQuantityForWarehouse = warehouses?.reduce((total, current) => {
    return total + +current.available_quantity;
  }, 0);

  const totalQuantityForUser = users?.reduce((total, current) => {
    return total + +current.available_quantity;
  }, 0);

  const totalQuantity = totalQuantityForWarehouse + totalQuantityForUser;
  const isMinStockReached =
    totalQuantity <= parseInt(`${article.min_stock_limit}`, 10);
  const isMaxStockReached =
    totalQuantity >= parseInt(`${article.max_stock_limit}`, 10);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {article.name}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.mms.inventory.list + search}>
              {strings.inventory}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {article.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.inventory_detail}
          </Heading>
          <Flex align="items-center">
            {isMinStockReached && totalQuantity !== 0 && (
              <Tooltip
                hasArrow
                label={`${strings.min_stock_reached_description} ${article.min_stock_limit}.`}>
                <Box as="span" mt="1">
                  <CustomTagTitle
                    bg="purple.100"
                    rounded="full"
                    color="purple.400"
                    fontSize="10px"
                    size="sm"
                    name={strings.min_stock_reached}
                  />
                </Box>
              </Tooltip>
            )}
            {isMaxStockReached && totalQuantity !== 0 && (
              <Tooltip
                hasArrow
                label={`${strings.max_stock_reached_description} ${article.max_stock_limit}.`}>
                <Box as="span" mt="1">
                  <CustomTagTitle
                    bg="gray.100"
                    rounded="full"
                    color="gray.400"
                    fontSize="10px"
                    size="sm"
                    name={strings.max_stock_reached}
                  />
                </Box>
              </Tooltip>
            )}
            {totalQuantity === 0 && (
              <Tooltip hasArrow>
                <Box as="span" mt="1">
                  <CustomTagTitle
                    bg="red.100"
                    rounded="full"
                    color="red.400"
                    fontSize="10px"
                    size="sm"
                    name={strings.out_of_stock}
                  />
                </Box>
              </Tooltip>
            )}
            <Flex ml="2">
              {strings.total_quantity}:&nbsp;
              <Text fontWeight="bold">{totalQuantity}</Text>
            </Flex>
          </Flex>
        </Flex>

        <Stack sx={wrapperStyles} maxH="65vh" overflow="auto">
          <PerfectScrollbar>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    {strings.warehouse}/{strings.employee}/{strings.apartmant}
                  </Th>
                  <Th>{strings.rooms}</Th>
                  <Th>{strings.racks}</Th>
                  <Th>{strings.shelves}</Th>
                  <Th isNumeric>{strings.quantity}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {warehouses?.map(
                  (item: InventoryWarehouseSchema, index: number) => (
                    <Tr key={index}>
                      <Td>{item.warehouse}</Td>
                      <Td>{item.room}</Td>
                      <Td>{item.rack}</Td>
                      <Td>{item.shelf}</Td>
                      <Td isNumeric>{item.available_quantity}</Td>
                    </Tr>
                  )
                )}
              </Tbody>
              <Tbody borderTop="2px solid" borderColor="gray.200">
                {users?.map((item: InventoryUserSchema, index: number) => (
                  <Tr key={index}>
                    <Td>{item.user}</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td>-</Td>
                    <Td isNumeric>{item.available_quantity}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </PerfectScrollbar>
        </Stack>
      </Stack>
    </>
  );
};

export default InventoryDetails;
