import {
  Box,
  Flex,
  IconButton,
  Image,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { Merge } from 'assets/icons';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { DashboardCustomerContactList } from 'constants/schema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  contactData: DashboardCustomerContactList;
  index: number;
}

const DashboardCustomerListItem: React.FC<Props> = (props) => {
  const { contactData, index } = props;
  const {
    id,
    first_name,
    last_name,
    contactDetail,
    email,
    house_no = '',
    street = '',
    zip_code = '',
    location = '',
    contactEvent,
  } = contactData;

  const address = `${street ? street : ''}${house_no ? ', ' + house_no : ''}${
    zip_code ? ', ' + zip_code : ''
  }${location ? ', ' + location : ''}`;

  return (
    <Tr>
      <Td>{index}</Td>
      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={routes.objectDashboard.dashboard.customerBoard.replace(
            ':id',
            id.toString()
          )}>
          <Flex>
            {first_name + ' ' + last_name}
            {contactDetail?.is_merged && (
              <Box w="6" h="6" ml="2" color="orange.300">
                <Image src={Merge} />
              </Box>
            )}
          </Flex>
        </RouterLink>
      </Td>
      <Td>
        {contactEvent.length > 0 ? (
          <Flex alignItem="center">
            {contactEvent.map((item, index) => (
              <Text key={index}>{item?.event_description.booking_number}</Text>
            ))}
          </Flex>
        ) : (
          '-'
        )}
      </Td>
      <Td>{address || '-'}</Td>
      <Td>{email || '-'}</Td>
      <Td>
        <RouterLink
          target="_blank"
          to={routes.crm.contact.details.replace(':id', id.toString())}>
          <Tooltip hasArrow label={strings.contact_details}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.contact_details}
              minW="6"
              color="green.300"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default DashboardCustomerListItem;
