import { Box, FormControl, FormLabel, Grid, GridItem } from '@chakra-ui/react';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { InfoSchema } from 'constants/schemas/objektSchema';
import React from 'react';

interface Props {
  objektInfo: InfoSchema;
  ownerInfo: any;
  isDashboard?: boolean;
}

const GeneralPanel: React.FC<Props> = (props) => {
  const { objektInfo, ownerInfo, isDashboard } = props;
  const featureColumns = ['repeat(1, 1fr)', 'repeat(2, 1fr)'];
  if (!isDashboard) featureColumns.push('repeat(4,1fr)');

  const {
    name: { de: apartmentName },
    address,
    type,
  } = objektInfo;

  return (
    <Grid gap="4" templateColumns={featureColumns} w="100%">
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.name}</FormLabel>
          <Box sx={infoStyles}>{apartmentName}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.type}</FormLabel>
          <Box sx={infoStyles}>{type ?? '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>Anlage</FormLabel>
          <Box sx={infoStyles}>{'-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.country}</FormLabel>
          <Box sx={infoStyles}>{address.country ?? '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.city}</FormLabel>
          <Box sx={infoStyles}>{address.city ?? '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.street}</FormLabel>
          <Box sx={infoStyles}>{address.street ?? '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.house_no}</FormLabel>
          <Box sx={infoStyles}>{address.housenumber ?? '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.post_code}</FormLabel>
          <Box sx={infoStyles}>{address.postalcode ?? '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.house_owner}</FormLabel>
          <Box sx={infoStyles}>{ownerInfo?.name ?? '-'}</Box>
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default GeneralPanel;
