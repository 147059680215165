import {
  Button,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { subMenuDataType } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import HideControl from 'services/HideControl';
import { checkPermissions } from 'utils/listInfo';

const linkStyles = {
  rounded: 'md',
  p: '2',
  textTransform: 'capitalize',
  _hover: {
    bg: 'rgba(255,255,255, 0.1)',
  },
};

const activeLinkStyles = {
  ...linkStyles,
  p: '2',
  bg: 'rgba(255,255,255, 0.2)',
};

function getRouteStyle(path: string) {
  let match = matchPath(location.pathname, {
    path: path,
  });
  return match?.isExact ? activeLinkStyles : linkStyles;
}

type DisplaySubLinksType = {
  subMenuData: subMenuDataType[];
};

const SubMenuListItem: React.FC<{
  item: subMenuDataType;
}> = ({ item }) => {
  const checkPermission = useCheckPermission(
    [PermissionRequest[item.permission]],
    item.link
  );

  return (
    <ListItem>
      <Link
        sx={getRouteStyle(item.base || item.link)}
        onClick={() => {
          checkPermission();
        }}>
        {strings[item.text]}
      </Link>
    </ListItem>
  );
};

const DisplaySubLinks: React.FC<DisplaySubLinksType> = (props) => {
  const { subMenuData, children } = props;

  const { userPermissions } = useSelector(
    (state: any) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  return (
    <>
      {subMenuData.map((item, index) => {
        const isPermitted = checkPermissions(userPermissions, [
          PermissionRequest[item.permission],
        ]);

        return (
          isPermitted && (
            <HideControl
              key={index}
              hideFor={item?.hideFor || ''}
              renderNoAccess={(data: any) => {
                return data ? data : '';
              }}>
              <SubMenuListItem item={item} />
            </HideControl>
          )
        );
      })}
      {children}
    </>
  );
};

export const UserSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.users.base,
      link: routes.users.list,
      text: 'user_list',
      permission: 'read:user',
    },
    {
      base: routes.users.roles.base,
      link: routes.users.roles.list,
      text: 'user_roles',
      permission: 'read:user',
    },
    {
      base: routes.users.externalCompanies.base,
      link: routes.users.externalCompanies.list,
      text: 'external_companies',
      permission: 'manage:external-companies',
    },
    {
      base: routes.users.loginLog.base,
      link: routes.users.loginLog.list,
      text: 'user_login_log',
      permission: 'read:login-log',
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const ChatSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      link: routes.chat.default,
      text: 'chat',
      permission: 'read:chat',
    },
    {
      base: routes.chat.closed.base,
      link: routes.chat.closed.list,
      text: 'closed_chat',
      permission: 'read:chat',
    },
    {
      base: routes.chat.archived.base,
      link: routes.chat.archived.list,
      text: 'archived_chat',
      permission: 'read:chat',
    },
    {
      link: routes.chat.metrics,
      text: 'chat_metrics',
      permission: 'read:chat-metrics',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const TicketSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.ticket.task.base,
      link: routes.ticket.task.board,
      text: 'ticket_board',
      permission: 'read:ticket',
    },
    {
      base: routes.ticket.list.base,
      link: routes.ticket.list.default,
      text: 'ticket_list',
      permission: 'read:ticket',
    },
    {
      link: routes.ticket.objectsInfo,
      text: 'objects',
      permission: 'read:ticket',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const CrmSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.crm.contact.base,
      link: routes.crm.contact.list,
      text: 'contacts',
      permission: 'read:crm',
    },
    {
      base: routes.crm.merge.base,
      link: routes.crm.merge.list,
      text: 'merge_contact_sidebar',
      permission: 'merge:contacts',
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

//not done yet
export const MmsSubMenuList: React.FC = () => {
  const location = useLocation();

  const checkStockTakingPermission = useCheckPermission(
    [PermissionRequest['manage:stocktaking']],
    routes.mms.stocktaking.stocktakingMode,
    () => onClose()
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSwitchToStocktaking = () => {
    if (location.pathname === routes.mms.stocktaking.stocktakingMode) return;
    onOpen();
  };

  const subMenuData: subMenuDataType[] = [
    {
      link: routes.mms.transferItems,
      text: 'transfer_item',
      permission: 'read:mms',
    },
    {
      base: routes.mms.inventory.base,
      link: routes.mms.inventory.list,
      text: 'inventories',
      permission: 'read:mms',
    },
    {
      base: routes.mms.articles.base,
      link: routes.mms.articles.list,
      text: 'articles',
      permission: 'read:mms',
    },
    {
      base: routes.mms.objects.base,
      link: routes.mms.objects.list,
      text: 'objects',
      permission: 'read:mms',
      hideFor: 'mms-service-object',
    },
    {
      base: routes.mms.warehouse.base,
      link: routes.mms.warehouse.list,
      text: 'warehouses',
      permission: 'read:mms',
    },
    {
      base: routes.mms.types.base,
      link: routes.mms.types.list,
      text: 'types',
      permission: 'read:mms',
    },
    {
      base: routes.mms.units.base,
      link: routes.mms.units.list,
      text: 'units',
      permission: 'read:mms',
    },
    {
      link: routes.mms.transferLogs,
      text: 'transfer_logs',
      permission: 'read:mms',
    },
    {
      base: routes.mms.myInventory.base,
      link: routes.mms.myInventory.list,
      text: 'my_inventory',
      permission: 'read:mms',
      hideFor: 'mms-service-my-inventory',
    },
    {
      base: routes.mms.myLogs.base,
      link: routes.mms.myLogs.list,
      text: 'my_transfer_logs',
      permission: 'read:mms',
      hideFor: 'mms-service-my-inventory',
    },
    {
      base: routes.mms.qrCode.base,
      link: routes.mms.qrCode.list,
      text: 'qr_code',
      permission: 'read:mms',
      hideFor: 'mms-service-qr-code',
    },
  ];

  return (
    <>
      <DisplaySubLinks subMenuData={subMenuData}>
        <HideControl
          hideFor="mms-service-stocktaking"
          renderNoAccess={(data: any) => {
            return data ? data : '';
          }}>
          <ListItem>
            <Link
              sx={getRouteStyle(routes.mms.stocktaking.stocktakingMode)}
              onClick={handleSwitchToStocktaking}>
              {strings.stocktaking}
            </Link>
          </ListItem>
        </HideControl>
      </DisplaySubLinks>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.stocktaking}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.stocktaking_mode_message}</ModalBody>

          <ModalFooter>
            <Button
              type="button"
              colorScheme="primary"
              mr={3}
              onClick={() => {
                checkStockTakingPermission();
              }}>
              {strings.confirm}
            </Button>

            <Button variant="outline" onClick={onClose}>
              {strings.cancel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const MdmSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      link: routes.mdm.uploads.list,
      text: 'uploads',
      permission: 'read:mdm',
    },
    {
      link: routes.mdm.contacts.list,
      text: 'contacts',
      permission: 'read:mdm',
    },
    {
      link: routes.mdm.bookings.list,
      text: 'bookings',
      permission: 'read:mdm',
    },
    {
      link: routes.mdm.invoices.list,
      text: 'invoices',
      permission: 'read:mdm',
    },
    {
      link: routes.mdm.newsletter.list,
      text: 'newsletter',
      permission: 'read:mdm',
    },
    {
      link: routes.mdm.imports.list,
      text: 'imports',
      permission: 'read:mdm',
    },
    {
      link: routes.mdm.logs.list,
      text: 'logs',
      permission: 'read:mdm',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const OmsSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.oms.objects.base,
      link: routes.oms.objects.list,
      text: 'objects',
      permission: 'read:oms',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const SMSSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.sms.services.base,
      link: routes.sms.services.list,
      text: 'services',
      permission: 'read:sms',
    },
    {
      base: routes.sms.serviceGroups.base,
      link: routes.sms.serviceGroups.list,
      text: 'service_group',
      permission: 'read:sms',
    },
    {
      base: routes.sms.meerSylt.base,
      link: routes.sms.meerSylt.list,
      text: 'meer_sylt',
      permission: 'read:sms',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const BMSSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.bms.reservation.base,
      link: routes.bms.reservation.list,
      text: 'reservations',
      permission: 'read:reservations',
    },
    {
      base: routes.bms.reservationRequest.base,
      link: routes.bms.reservationRequest.list,
      text: 'reservation_requests',
      permission: 'read:reservations',
    },
    {
      base: routes.bms.booking.base,
      link: routes.bms.booking.list,
      text: 'bookings',
      permission: 'read:bookings',
    },
    {
      base: routes.bms.ferryBooking.base,
      link: routes.bms.ferryBooking.list,
      text: 'ferry_bookings',
      permission: 'read:bookings',
    },
    {
      base: routes.bms.callback.base,
      link: routes.bms.callback.list,
      text: 'callbacks',
      permission: 'read:callbacks',
    },
    {
      base: routes.bms.checkInOut.base,
      link: routes.bms.checkInOut.list,
      text: 'check_in_out',
      hideFor: 'bms-check-in-out',
      permission: 'read:booking-terminals',
    },
    {
      base: routes.bms.marketingEmail.base,
      link: routes.bms.marketingEmail.list,
      text: 'marketing_email',
      permission: 'read:bookings',
    },
    {
      base: routes.bms.marketingEmailSettings.base,
      link: routes.bms.marketingEmailSettings.list,
      text: 'marketing_email_settings',
      permission: 'read:bookings',
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const BSSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.bs.breadBooking.base,
      link: routes.bs.breadBooking.list,
      text: 'bread_bookings',
      permission: 'read:bread-service',
    },
    {
      base: routes.bs.breadBookingBilling.base,
      link: routes.bs.breadBookingBilling.list,
      text: 'bread_booking_billing',
      permission: 'read:bread-service',
    },
    {
      base: routes.bs.breadDailyView.base,
      link: routes.bs.breadDailyView.list,
      text: 'bread_daily_view',
      permission: 'read:bread-service',
    },
    {
      base: routes.bs.drivingOrder.base,
      link: routes.bs.drivingOrder.list,
      text: 'bread_driving_order',
      permission: 'read:bread-driving-order',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const FAQSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.faq.faqList.base,
      link: routes.faq.faqList.list,
      text: 'faq',
      permission: 'read:faq',
    },
    {
      base: routes.faq.internalfaq.base,
      link: routes.faq.internalfaq.list,
      text: 'internal_faq',
      permission: 'read:internal-faq',
    },
    {
      base: routes.faq.faqGroup.base,
      link: routes.faq.faqGroup.list,
      text: 'group',
      permission: 'read:faq-group',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const DMSSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.dms.generalList.base,
      link: routes.dms.generalList.list,
      text: 'general_documents',
      permission: 'read:dms',
    },
    {
      base: routes.dms.objectDocuments.base,
      link: routes.dms.objectDocuments.list,
      text: 'document_objects',
      permission: 'read:object-dms',
    },
    {
      base: routes.dms.customerDocuments.base,
      link: routes.dms.customerDocuments.list,
      text: 'document_customers',
      permission: 'read:customer-dms',
    },
    {
      base: routes.dms.ownerDocuments.base,
      link: routes.dms.ownerDocuments.list,
      text: 'document_owners',
      permission: 'read:owner-dms',
    },
    {
      base: routes.dms.userDocuments.base,
      link: routes.dms.userDocuments.list,
      text: 'users_document',
      permission: 'read:user-dms',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const PMSSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      link: routes.pms.creditCard.list,
      text: 'credit_card',
      permission: 'read:pms',
    },
    {
      link: routes.pms.paypal.list,
      text: 'paypal',
      permission: 'read:pms',
    },
    {
      link: routes.pms.invoice.list,
      text: 'invoice_payment',
      permission: 'read:pms',
    },
    {
      base: routes.pms.paymentLinks.base,
      link: routes.pms.paymentLinks.list,
      text: 'payment_links',
      permission: 'read:pms',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const PNSSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.pns.notification.base,
      link: routes.pns.notification.list,
      text: 'notifications',
      permission: 'read:pns',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const ContactCenterSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.contactCenter.members.base,
      link: routes.contactCenter.members.list,
      text: 'user',
      permission: 'read:contact-center',
    },
    {
      link: routes.contactCenter.totalCalls.list,
      text: 'total_calls',
      permission: 'read:contact-center',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const FeedbackSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.feedback.feedbacks.base,
      link: routes.feedback.feedbacks.list,
      text: 'feedback',
      permission: 'read:feedback',
    },
    {
      link: routes.feedback.dashboard,
      text: 'dashboard',
      permission: 'read:feedback',
    },
    {
      link: routes.feedback.analysis,
      text: 'analysis',
      permission: 'read:feedback',
    },
    {
      link: routes.feedback.mails.list,
      text: 'mails',
      permission: 'read:feedback',
    },
    {
      base: routes.feedback.questions.base,
      link: routes.feedback.questions.list,
      text: 'questions',
      permission: 'manage:feedback',
    },
    {
      link: routes.feedback.setting.list,
      text: 'setting',
      permission: 'manage:feedback',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const NewsletterSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.newsletter.newsletters.base,
      link: routes.newsletter.newsletters.list,
      text: 'newsletters',
      permission: 'read:newsletter',
    },
    {
      link: routes.newsletter.draft.list,
      text: 'drafts',
      permission: 'read:newsletter',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const KeySubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.key.KeyLogs.base,
      link: routes.key.KeyLogs.list,
      text: 'key_logs',
      permission: 'read:keylog',
    },
    {
      base: routes.key.keyList.base,
      link: routes.key.keyList.list,
      text: 'key_list',
      permission: 'read:keylist',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const ObjectDashboardSubMenu: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.objectDashboard.dashboard.contactList,
      link: routes.objectDashboard.dashboard.contactList,
      text: 'dashboard',
      permission: 'read:object-dashboard',
    },
    {
      base: routes.objectDashboard.crossSelling.base,
      link: routes.objectDashboard.crossSelling.list,
      text: 'cross_selling',
      permission: 'manage:cross-selling',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const ThirdPartyPartnerSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.thirdPartyPartner.membershipLevel.base,
      link: routes.thirdPartyPartner.membershipLevel.list,
      text: 'membership_level',
      permission: 'manage:third-party-partner',
    },
    {
      base: routes.thirdPartyPartner.thirdPartyPartner.base,
      link: routes.thirdPartyPartner.thirdPartyPartner.list,
      text: 'third_party_partner',
      permission: 'manage:third-party-partner',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const RestaurantSubMenuList: React.FC = () => {
  const subMenuData: subMenuDataType[] = [
    {
      base: routes.restaurant.restaurant.base,
      link: routes.restaurant.restaurant.list,
      text: 'restaurant',
      permission: 'manage:restaurant',
    },
    {
      base: routes.restaurant.restaurantReservation.base,
      link: routes.restaurant.restaurantReservation.list,
      text: 'restaurant_reservation',
      permission: 'manage:restaurant',
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};
