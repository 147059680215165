import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ServiceGroupResource from 'api/sms_service_groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ServiceGroupForm from 'components/service_group/ServiceGroupForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import {
  DataWrapperWithSuccess,
  ServiceGroupFormSchema,
  ServiceGroupSchema,
} from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

const EditServiceGroup: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const methods = useForm<ServiceGroupFormSchema>();

  const serviceGroupAPI = new ServiceGroupResource();

  const serviceGroupQuery = useQuery<
    DataWrapperWithSuccess<ServiceGroupSchema>
  >(
    [`service-group${id}-edit`, id],
    () => serviceGroupAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateServiceGroup = useMutation((data: FormData) =>
    serviceGroupAPI.update(id, data)
  );

  const onSubmit = (data: ServiceGroupFormSchema) => {
    const formData = new FormData();
    formData.append('name', data.titles_de);
    formData.append('service_group_id', '1');
    formData.append('early_deliverable', data.early_deliverable);
    formData.append('is_default', data.is_default);
    formData.append('show_on_page', data.show_on_page);
    formData.append('early_deliverable_start', data.early_deliverable_start);
    formData.append('booking_fee', data.booking_fee.toString());
    formData.append('booking_fee_type', data.booking_fee_type);
    formData.append('pay_to_vendor', data.pay_to_vendor);
    formData.append('price_per_night', data.price_per_night.toString());
    formData.append('service_per_night', data.service_per_night.toString());
    formData.append('is_dog_service', data.is_dog_service);
    formData.append('color', data?.color);
    if (data?.icon) formData.append('icon', data?.icon);
    if (data?.cover) formData.append('cover', data?.cover);
    formData.append('descriptions[de]', data.descriptions_de);
    formData.append('descriptions[en]', data.descriptions_en);
    formData.append('descriptions[pl]', data.descriptions_pl);
    formData.append('descriptions[da]', data.descriptions_da);
    formData.append('descriptions[zh]', data.descriptions_zh);
    formData.append('titles[de]', data.titles_de);
    formData.append('titles[en]', data.titles_en);
    formData.append('titles[pl]', data.titles_pl);
    formData.append('titles[da]', data.titles_da);
    formData.append('titles[zh]', data.titles_zh);
    formData.append('_method', 'PUT');

    updateServiceGroup.mutate(formData, {
      onSuccess: () => {
        toast({
          title: strings.service_group_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('serviceGroupList');
        history.push(
          routes.sms.serviceGroups.details.replace(':id', id.toString()) +
            search
        );
      },
    });
  };

  if (serviceGroupQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (serviceGroupQuery.isError || !serviceGroupQuery.data) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.service_group_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.sms} | {strings.edit_service_group}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.sms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.sms.serviceGroups.list + search}>
            {strings.service_group}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {serviceGroupQuery?.data?.data?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.edit_service_group}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {errorMessage?.map((error) => (
                <Alert status="error" key={error}>
                  <AlertIcon />
                  {error}
                </Alert>
              ))}
              {updateServiceGroup.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.service_group_udpate_failed}
                </Alert>
              )}
              <ServiceGroupForm
                serviceGroupData={serviceGroupQuery.data.data}
                setErrorMessage={setErrorMessage}
              />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateServiceGroup.isLoading}>
                  {strings.update}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default EditServiceGroup;
