import {
  Box,
  Flex,
  List,
  ListItem,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { PermissionGroupSchema, PermissionItemSchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Permission {
  id: number;
  name: string;
  guard_name: string;
}
interface Props {
  permissionViaRoleList: Permission[];
  permissionGroupQuery: any;
}

const UserRightsManager: React.FC<Props> = (props) => {
  const { permissionViaRoleList, permissionGroupQuery } = props;
  const { register } = useFormContext();

  const tabStyle = {
    justifyContent: 'flex-start',
    px: '4',
    py: '3',
    fontWeight: '500',
    fontSize: 'sm',
    position: 'relative',
    textTransform: 'capitalize',
  };

  if (permissionGroupQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <Box border="1px" borderColor="gray.100" rounded="sm" mt="4">
      <Tabs orientation="vertical" variant="unstyled">
        <TabList w="xs" borderRight="1px" borderColor="gray.100">
          {permissionGroupQuery?.data?.map(
            (permissionGroup: PermissionGroupSchema) => (
              <Tab
                key={permissionGroup.id}
                sx={tabStyle}
                _selected={{ color: 'white', bg: 'blue.400' }}>
                {strings.getString(permissionGroup.name.split('-').join('_'))}
              </Tab>
            )
          )}
        </TabList>
        <TabPanels>
          {permissionGroupQuery?.data?.map(
            (permissionGroup: PermissionGroupSchema) => (
              <TabPanel key={permissionGroup.id}>
                <List spacing="6" color="gray.500">
                  {permissionGroup.permissions.map(
                    (permissionItem: PermissionItemSchema) => {
                      const checkStatus = permissionViaRoleList.some(
                        (permission: Permission) =>
                          permission.id === +permissionItem.id
                      );
                      const permissionItemId = permissionItem.id.toString();
                      return (
                        <ListItem key={permissionItem.id}>
                          <Flex alignItems="center">
                            {checkStatus ? (
                              <>
                                {/* Actual checkbox component is hidden showing a dummy disabled checkbox 
                              as the checkbox element doesn't have a readonly property */}
                                <input
                                  type="checkbox"
                                  id={`permissions-${permissionItemId}`}
                                  checked
                                  disabled
                                />
                                <input
                                  type="hidden"
                                  id={`permissions-${permissionItemId}`}
                                  {...register(
                                    `permissions.${permissionItemId}`
                                  )}
                                />
                              </>
                            ) : (
                              <input
                                type="checkbox"
                                id={`permissions-${permissionItemId}`}
                                {...register(`permissions.${permissionItemId}`)}
                              />
                            )}
                            <label
                              htmlFor={`permissions-${permissionItemId}`}
                              style={{
                                textTransform: 'capitalize',
                                fontSize: '14px',
                                marginLeft: '0.5rem',
                              }}>
                              {strings.getString(
                                permissionItem.name.split('-').join('_')
                              )}
                            </label>
                          </Flex>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </TabPanel>
            )
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default UserRightsManager;
