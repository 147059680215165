import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Heading, Stack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import RMSObjectsResource from 'api/rms-objects';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomSelect from 'components/common/CustomSelect';
import { strings } from 'config/localization';
import { ROLE_ADMIN } from 'constants/common';
import { ResponsibleType } from 'constants/schema';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';

interface FormType {
  person_in_charge: ResponsibleType | null;
  substitute_person_in_charge: ResponsibleType | null;
}

interface Props extends FormType {
  apartmentId: number;
  apartmentDetailKey: string;
}
export type ResponsibilitiesIdType = Partial<Record<keyof FormType, number>>;

const PERSON_IN_CHARGE = `/rms/users?responsibility=person_in_charge`;
const SUBSTITUTE_PERSON_IN_CHARGE = `/rms/users?responsibility=substitute_person_in_charge`;

const ObjecktDetailsResponsibilities: React.FC<Props> = (props) => {
  const {
    apartmentId,
    person_in_charge,
    substitute_person_in_charge,
    apartmentDetailKey,
  } = props;

  const queryClient = useQueryClient();
  const apartmentAPI = new RMSObjectsResource();
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'bottom',
  });

  const { control, setValue, handleSubmit, watch } = useForm<FormType>({
    defaultValues: {
      person_in_charge,
      substitute_person_in_charge,
    },
  });

  const watchResponsibilities = watch([
    'person_in_charge',
    'substitute_person_in_charge',
  ]);

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const isAdminUser: boolean = loggedInUser.role === ROLE_ADMIN;
  const isEmptyField: boolean =
    watchResponsibilities[0] === null && watchResponsibilities[1] === null;

  const onPersonInChargeChange = (e: ResponsibleType) => {
    setValue('person_in_charge', e);
  };
  const onSubstitutePersonInChargeChange = (e: ResponsibleType) => {
    setValue('substitute_person_in_charge', e);
  };

  const updateResponsible = useMutation(
    (data: ResponsibilitiesIdType) =>
      apartmentAPI.assignResponsibilities(apartmentId, data),
    {
      onSuccess: () => {
        toast({
          title: strings.successfully_updated_responsible_person,
          status: 'success',
        });
        queryClient.invalidateQueries(apartmentDetailKey);
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
        });
      },
    }
  );

  const getIds = (data: Partial<FormType>) => {
    const ids: ResponsibilitiesIdType = {};

    if (data.person_in_charge && data.person_in_charge.id !== null) {
      ids.person_in_charge = data.person_in_charge.id;
    }
    if (
      data.substitute_person_in_charge &&
      data.substitute_person_in_charge.id !== null
    ) {
      ids.substitute_person_in_charge = data.substitute_person_in_charge.id;
    }
    return ids;
  };

  const onSubmit: SubmitHandler<FormType> = (data) => {
    const ids: ResponsibilitiesIdType = getIds(data);
    if (ids.person_in_charge === ids.substitute_person_in_charge) {
      toast({
        status: 'error',
        title: strings.responsible_person_cannot_be_same,
      });
      return;
    }
    updateResponsible.mutate(ids);
  };

  return (
    <>
      <Heading size="sm">{strings.responsibility}</Heading>
      <Stack sx={wrapperStyles}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.person_in_charge}</FormLabel>
              <Controller
                name="person_in_charge"
                control={control}
                render={({ field }) => {
                  return (
                    <CustomSelect
                      placeholder={strings.person_in_charge}
                      value={field.value}
                      onChange={onPersonInChargeChange}
                      SEARCH_API={PERSON_IN_CHARGE}
                      limit={30}
                      replaceNameInOption="name"
                      disable={!isAdminUser}
                    />
                  );
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{strings.substitute_person_in_charge}</FormLabel>
              <Controller
                name="substitute_person_in_charge"
                control={control}
                render={({ field }) => {
                  return (
                    <CustomSelect
                      placeholder={strings.substitute_person_in_charge}
                      value={field.value}
                      onChange={onSubstitutePersonInChargeChange}
                      SEARCH_API={SUBSTITUTE_PERSON_IN_CHARGE}
                      limit={30}
                      replaceNameInOption="name"
                      disable={!isAdminUser}
                    />
                  );
                }}
              />
            </FormControl>

            {isAdminUser && (
              <Stack direction="row" spacing="4">
                <Button
                  bg="primary.400"
                  type="submit"
                  colorScheme="primary"
                  isLoading={updateResponsible.isLoading}
                  isDisabled={isEmptyField}>
                  {strings.save_now}
                </Button>
              </Stack>
            )}
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ObjecktDetailsResponsibilities;
