import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import ImportResource from 'api/imports';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  MDM_LOGS_IMPORT_TYPES_OPTIONS,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import { MDM_LOGS_IMPORT_TYPES } from 'constants/schema';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiCloudDownload } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  import_type: string | null;
  uploaded_date: string | null;
}

type QueryParamType = {
  page: number;
  limit: number;
};
type LogSchema = {
  id: number;
  import_method: string;
  file_name: string;
  file_url: string;
  import_type: string;
  status: string;
  updated_at: Date;
  user: {
    name: string;
  };
};

const MDMLogs: React.FC = () => {
  const [uploadDate, setUploadDate] = useState<Date | null>(null);
  const [category, setCategory] = useState<any>(null);

  let importAPI = new ImportResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    import_type: null,
    uploaded_date: null,
  });

  const logListQuery = useQuery(
    [
      'listLogs',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        uploaded_date: filterParams.uploaded_date,
        import_type: filterParams.import_type,
      },
    ],
    async () => {
      const queryParam: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await getLogs(
        filterParams.currentPage,
        filterParams.pageSize
      );
      return response?.data?.data;
    }
  );

  const handleDateChange = (date: Date) => {
    setUploadDate(date);
  };
  const handleCatChange = (e: any) => {
    setCategory(e.target.value);
  };
  const getLogs = async (page: number, limit: number) => {
    let { uploaded_date, import_type } = filterParams;
    let queryParam: any = {
      page,
      limit,
    };
    if (uploaded_date) queryParam.uploaded_date = uploaded_date;
    if (import_type) queryParam.import_type = import_type;
    return await importAPI.list(queryParam);
  };

  const getStatus = (status: string) => {
    let color: any;
    let text = '';
    if (status) {
      color = 'green.300';
      text = strings.done;
    } else {
      color = 'red.300';
      text = strings.failed;
    }
    return (
      <Text
        color={color}
        textTransform="uppercase"
        fontWeight="medium"
        fontSize="sm">
        {text}
      </Text>
    );
  };

  useEffect(() => {
    const handleInputChange = () => {
      setFilterParams((prevState) => ({
        ...prevState,
        import_type: category,
        uploaded_date: uploadDate
          ? moment(uploadDate).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
          : prevState.uploaded_date,
      }));
    };
    handleInputChange();
  }, [category, uploadDate]);

  function handleReset() {
    setUploadDate(null);
    setCategory('');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      uploaded_date: null,
      import_type: null,
    }));
  }
  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  function getString(import_type: string) {
    switch (import_type) {
      case 'service':
        return strings.service;
      case 'apartment':
        return strings.apartmant;
      case 'booking':
        return strings.booking;
      case 'invoice':
        return strings.invoice;
      case 'contact':
        return strings.contact;
      case 'newsletter_contact':
        return strings.newsletter;

      default:
        return import_type;
    }
  }
  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mdm} | {strings.all_logs}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
            {strings.mdm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mdm.logs.list}>
            {strings.all_logs}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.logs}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack direction="column" spacing="4">
            <Stack
              direction={['column', 'column', 'row']}
              spacing="4"
              align={['start', 'start', 'end']}>
              <Grid
                gap={['4', '4', '6']}
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.category}</FormLabel>
                    <Select
                      placeholder={strings.select_category}
                      rounded="sm"
                      id="import_type"
                      value={category}
                      onChange={handleCatChange}>
                      {MDM_LOGS_IMPORT_TYPES_OPTIONS.map(
                        (item: MDM_LOGS_IMPORT_TYPES) => (
                          <option value={item} key={item}>
                            {strings[item]}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.upload_date}</FormLabel>
                    <InputGroup>
                      <ReactDatePicker
                        dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                        placeholderText={strings.select_date}
                        customInput={<CustomDateInput />}
                        onChange={handleDateChange}
                        selected={uploadDate}
                        value={DateFormatYMD(uploadDate)}
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                      <InputRightElement
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
              </Grid>
              <Stack direction="row" spacing="4" pt={['2']}>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.category}</Th>
                <Th>{strings.uploaded_by}</Th>
                <Th>{strings.upload_date}</Th>
                <Th>{strings.file}</Th>
                <Th>{strings.progress_status}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!logListQuery.isLoading &&
                logListQuery?.data?.data.map(
                  (
                    {
                      id,
                      import_method,
                      file_name,
                      file_url,
                      import_type,
                      status,
                      updated_at,
                      user,
                    }: LogSchema,
                    index: number
                  ) => (
                    <Tr key={id}>
                      <Td>{startingSN + index}</Td>
                      <Td>{getString(import_type)}</Td>
                      <Td>{user.name ?? '-'}</Td>
                      <Td>{DateFormat(updated_at)}</Td>
                      <Td>{file_name}</Td>
                      <Td>{getStatus(status)}</Td>
                      <Td>
                        <Tooltip label={strings.download} hasArrow>
                          <a
                            href={file_url}
                            target="_blank"
                            rel="noopener noreferrer">
                            <Icon as={BiCloudDownload} />
                          </a>
                        </Tooltip>
                      </Td>
                    </Tr>
                  )
                )}
              {logListQuery.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={logListQuery}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default MDMLogs;
