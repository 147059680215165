import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import RMSImportResource from 'api/rms_imports';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  IMPORT_TYPES,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import { ImportTypes } from 'constants/schema';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import { DateFormatYMD, utcToLocal } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  import_type: string | null;
  updated_at: string | null;
}

type QueryParamType = {
  page: number;
  limit: number;
  import_type: string | null;
  updated_at: string | null;
};

type ImportSchema = {
  id: number;
  import_type: ImportTypes;
  last_id: number;
  total_records_imported: number;
  imported_at: string;
  created_at: string;
};

const ImportsList: React.FC = () => {
  const [updateDate, setUpdateDate] = useState<Date | null>(null);
  const [importType, setImportType] = useState<any>(null);

  const importAPI = new RMSImportResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    import_type: null,
    updated_at: null,
  });

  const importList = useQuery(
    [
      'importList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        import_type: filterParams.import_type,
        updated_at: filterParams.updated_at,
      },
    ],
    async () => {
      const queryParams: QueryParamType = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        import_type: filterParams.import_type,
        updated_at: filterParams.updated_at,
      };
      const response = await importAPI.list(queryParams);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const handleImportSelect = (e: any) => {
    setImportType(e.target.value);
  };
  const handleDateChange = (date: Date) => {
    setUpdateDate(date);
  };

  useEffect(() => {
    const handleInputChange = () => {
      setFilterParams((prevState) => ({
        ...prevState,
        import_type: importType,
        updated_at: updateDate
          ? moment(updateDate).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
          : prevState.updated_at,
      }));
    };
    handleInputChange();
  }, [importType, updateDate]);

  function handleReset() {
    setUpdateDate(null);
    setImportType('');
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      import_type: null,
      updated_at: null,
    }));
  }

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.mdm} | {strings.all_imports}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.mdm.contacts.list}>
            {strings.mdm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.mdm.imports.list}>
            {strings.all_imports}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.imports}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.import_type}</FormLabel>
              <Select
                placeholder={strings.import_type}
                rounded="sm"
                id="import_type"
                value={importType}
                onChange={handleImportSelect}>
                {IMPORT_TYPES.map((item: ImportTypes) => (
                  <option value={item} key={item}>
                    {strings[item]}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.updated_date}</FormLabel>
              <InputGroup>
                <ReactDatePicker
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                  placeholderText={strings.select_date}
                  customInput={<CustomDateInput />}
                  onChange={handleDateChange}
                  selected={updateDate}
                  value={DateFormatYMD(updateDate)}
                  shouldCloseOnSelect={false}
                  autoComplete="off"
                />
                <InputRightElement
                  children={<BiCalendar color="green.500" />}
                />
              </InputGroup>
            </FormControl>
            <Stack direction="row" spacing="4" pt={['2', '8']}>
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={handleReset}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.import_type}</Th>
                <Th>{strings.last_id}</Th>
                <Th>{strings.imported_at}</Th>
                <Th>{strings.total_records_imported}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!importList.isLoading &&
                importList?.data?.data.map(
                  (
                    {
                      id,
                      import_type,
                      last_id,
                      total_records_imported,
                      created_at,
                    }: ImportSchema,
                    index: number
                  ) => {
                    return (
                      <Tr key={id}>
                        <Td>{startingSN + index}</Td>
                        <Td>{strings[import_type] ?? '-'}</Td>
                        <Td>{last_id ?? '-'}</Td>
                        <Td>
                          {utcToLocal(created_at).replace(' ', ', ') ?? '-'}
                        </Td>
                        <Td>{total_records_imported ?? '-'}</Td>
                      </Tr>
                    );
                  }
                )}
              {importList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={importList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default ImportsList;
