import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import { TICKET_TYPE } from 'constants/schema';
import React from 'react';

export const getTag = (priority: TICKET_TYPE) => {
  let bg = '';
  let color = '';
  switch (priority) {
    case 'low':
      bg = 'green.50';
      color = 'green.400';
      break;
    case 'medium':
      bg = 'yellow.50';
      color = 'yellow.400';
      break;
    case 'high':
      bg = 'orange.50';
      color = 'orange.400';
      break;
    case 'urgent':
      bg = 'red.50';
      color = 'red.400';
      break;
    default:
      break;
  }
  return (
    <CustomTagTitle
      bg={bg}
      rounded="full"
      color={color}
      fontSize="10px"
      size="sm"
      name={strings[priority]}
    />
  );
};
