import { Icon } from '@chakra-ui/react';
import { strings } from 'config/localization';
import useNetworkStatus from 'hooks/useNetworkStatus';
import React, { useEffect, useState } from 'react';
import { BiWifi, BiWifiOff, BiXCircle } from 'react-icons/bi';

const InternetStatus: React.FC<any> = () => {
  const statusWrapper = {
    position: 'absolute',
    bottom: '0',
    zIndex: '9999',
  };
  const nowifi = {
    background: '#383838',
    color: '#fdfdfd',
    fontSize: '12px',
    padding: '20px',
    borderRadius: '5px',
    display: 'inline-block',
    maxWidth: '340px',
  };
  const closeButton = {
    color: '#cccccc',
    marginLeft: '10px',
    _hover: {
      color: '#0445C5',
      cursor: 'pointer',
    },
  };
  const wifiIcon = {
    marginRight: '5px',
    fontSize: '16px',
  };
  const wifi = {
    background: '#383838',
    color: '#fdfdfd',
    fontSize: '12px',
    padding: '20px',
    borderRadius: '5px',
    display: 'inline-block',
    maxWidth: '400px',
  };
  const refresh = {
    color: '#6591E4',
    margin: '0 5px',
    _hover: {
      textDecoration: 'underline',
      color: '#03E79EB',
      cursor: 'pointer',
    },
  };

  const [isOnline, connectionChange] = useNetworkStatus();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (!connectionChange && isOnline) return;

    setShowPopup(true);
    if (isOnline) {
      let timer = setTimeout(() => {
        setShowPopup(false);
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [connectionChange, isOnline]);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      {!isOnline && showPopup && (
        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          <div style={nowifi}>
            <Icon color="#bcbcbc" style={wifiIcon} as={BiWifiOff} />
            <span>{strings.nowifi}</span>
            <span style={refresh}>
              <button
                onClick={() => {
                  location.reload();
                }}>
                {strings.refresh}
              </button>
            </span>
            <Icon
              style={closeButton}
              as={BiXCircle}
              onClick={handlePopupClose}
            />
          </div>
        </div>
      )}
      {isOnline && showPopup && (
        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          <div style={wifi}>
            <Icon color="#02AB32" style={wifiIcon} as={BiWifi} />
            <span>{strings.wifi}</span>
            <Icon
              style={closeButton}
              as={BiXCircle}
              onClick={handlePopupClose}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InternetStatus;
