import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { infoText } from 'assets/css/commonStyles';
import CustomTabs from 'components/common/CustomTab';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import {
  BOOKING_FEE_TYPE_OPTIONS,
  DESCRIPTION_MAPPER,
  LANGUAGE_OPTIONS,
  PRICE_CALCULATION_OPTIONS,
  TITLE_MAPPER,
} from 'constants/common';
import {
  LanguageAbbreviationType,
  ServiceGroupFormSchema,
  ServiceGroupSchema,
} from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { fallbackGermanValue } from 'utils';

const COLOR_OPTIONS = [
  { value: 'light', label: 'light' },
  { value: 'dark', label: 'dark' },
] as const;

const TEXT_AREA_LENGTH = 1000;

interface Props {
  serviceGroupData?: ServiceGroupSchema;
  setErrorMessage: React.Dispatch<React.SetStateAction<string[]>>;
}

const ServiceGroupForm: React.FC<Props> = (props) => {
  const { serviceGroupData, setErrorMessage } = props;

  const descriptions = new Map<LanguageAbbreviationType, string>();
  const titles = new Map<LanguageAbbreviationType, string>();

  /**
   * Get descriptions and titles according to the language type.
   */
  serviceGroupData?.descriptions.forEach((item) => {
    descriptions.set(item.language, item.description);
  });
  serviceGroupData?.titles.forEach((item) => {
    titles.set(item.language, item.title);
  });

  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<ServiceGroupFormSchema>();

  useEffect(() => {
    if (!serviceGroupData) return;
    setValue('show_on_page', serviceGroupData?.show_on_page ? '1' : '0');
    setValue('is_default', serviceGroupData?.is_default ? '1' : '0');
    setValue(
      'early_deliverable',
      serviceGroupData?.early_deliverable ? '1' : '0'
    );
    setValue(
      'early_deliverable_start',
      serviceGroupData?.early_deliverable_start ? '1' : '0'
    );
    setValue('pay_to_vendor', serviceGroupData?.pay_to_vendor ? '1' : '0');
    setValue('is_dog_service', serviceGroupData?.is_dog_service ? '1' : '0');
  }, [serviceGroupData, setValue]);

  useEffect(() => {
    // Errors to show as alert because when the langauge tabs are changing,
    // Some input fields are not on screen and cannot be focused.
    const errs: string[] = [];

    if (errors.titles_de) {
      errs.push(`${strings.de}: ${errors.titles_de.message}`);
    }

    for (const [key, value] of Object.entries(DESCRIPTION_MAPPER)) {
      if (errors[value]) {
        const language = strings[key as LanguageAbbreviationType];
        errs.push(`${language}: ${errors[value]?.message}`);
      }
    }

    setErrorMessage(errs);
  }, [
    setErrorMessage,
    errors,
    errors.titles_de,
    errors.descriptions_de,
    errors.descriptions_en,
    errors.descriptions_zh,
    errors.descriptions_da,
    errors.descriptions_pl,
  ]);

  return (
    <form>
      <CustomTabs
        display="flex"
        flexDirection="column"
        gridGap="4"
        defaultIndex={0}>
        <CustomTabs.TabList>
          {LANGUAGE_OPTIONS.map((lang) => (
            <CustomTabs.Tab key={lang.value}>
              {strings[lang.value]}
            </CustomTabs.Tab>
          ))}
        </CustomTabs.TabList>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1"
          w="100%"
          overflow="auto">
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl
                    isInvalid={!!errors?.[TITLE_MAPPER[lang.value]]}
                    isRequired={lang.value === 'de'}>
                    <FormLabel>
                      {strings.service_name} ({strings[lang.value]})
                    </FormLabel>
                    <Input
                      type="text"
                      defaultValue={
                        titles.get(lang.value) ||
                        fallbackGermanValue(lang.value, serviceGroupData?.name)
                      }
                      placeholder={strings.service_name}
                      {...register(TITLE_MAPPER[lang.value], {
                        required: lang.value === 'de' && strings.title_required,
                      })}
                    />

                    <FormErrorMessage>
                      {errors?.[TITLE_MAPPER[lang.value]] &&
                        errors?.[TITLE_MAPPER[lang.value]]?.message}
                    </FormErrorMessage>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.color} isRequired>
              <FormLabel>{strings.frontend_color}</FormLabel>
              <Select
                placeholder={strings.select}
                rounded="sm"
                defaultValue={serviceGroupData?.color}
                {...register('color', {
                  required: strings.required_front_color,
                })}>
                {COLOR_OPTIONS.map((type) => {
                  return (
                    <option key={type.value} value={type.value}>
                      {strings[type.label]}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormErrorMessage>
              {errors?.color && errors?.color?.message}
            </FormErrorMessage>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.booking_fee_type} isRequired>
              <FormLabel>{strings.booking_fee_calculation}</FormLabel>
              <Select
                placeholder={strings.select}
                rounded="sm"
                defaultValue={serviceGroupData?.booking_fee_type}
                {...register('booking_fee_type', {
                  required: strings.required_booking_fee,
                })}>
                {BOOKING_FEE_TYPE_OPTIONS?.map((type: any) => {
                  return (
                    <option key={type.value} value={type.value}>
                      {strings.getString(
                        type.text.toLowerCase().split(' ').join('_')
                      )}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors?.booking_fee_type && errors?.booking_fee_type?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.service_per_night} isRequired>
              <FormLabel>{strings.price_calculation}</FormLabel>
              <Select
                rounded="sm"
                defaultValue={serviceGroupData?.service_per_night}
                {...register('service_per_night', {
                  required: strings.required,
                })}>
                {PRICE_CALCULATION_OPTIONS?.map((type) => {
                  return (
                    <option key={type.value} value={type.value}>
                      {strings[type.text]}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors?.service_per_night &&
                  errors?.service_per_night?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.booking_fee} isRequired>
              <FormLabel display="flex">
                {strings.booking_fee} <Text sx={infoText}>(Cent)</Text>
              </FormLabel>
              <Input
                type="number"
                defaultValue={serviceGroupData?.booking_fee}
                placeholder={strings.enter_booking_fee_in_cents}
                {...register('booking_fee', {
                  required: strings.required_booking_type,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.booking_fee && errors?.booking_fee?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.price_per_night} isRequired>
              <FormLabel display="flex">
                {strings.price} <Text sx={infoText}>(Cent)</Text>
              </FormLabel>
              <Input
                type="number"
                defaultValue={serviceGroupData?.price_per_night}
                placeholder={strings.enter_price_per_night_in_cents}
                {...register('price_per_night', {
                  required: strings.required_price_per_night,
                  min: {
                    value: 0,
                    message: strings.less_than_zero,
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.price_per_night && errors?.price_per_night?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <CustomTabs.TabPanels>
              {LANGUAGE_OPTIONS.map((lang) => (
                <CustomTabs.TabPanel key={lang.value}>
                  <FormControl
                    isInvalid={!!errors?.[DESCRIPTION_MAPPER[lang.value]]}
                    isRequired={lang.value === 'de'}>
                    <FormLabel>
                      {strings.description} ({strings[lang.value]})
                    </FormLabel>
                    <Textarea
                      height="100px"
                      defaultValue={
                        descriptions.get(lang.value) ||
                        fallbackGermanValue(
                          lang.value,
                          serviceGroupData?.description
                        )
                      }
                      placeholder={strings.description}
                      {...register(DESCRIPTION_MAPPER[lang.value], {
                        required:
                          lang.value === 'de' && strings.required_descriptions,
                        maxLength: {
                          value: TEXT_AREA_LENGTH,
                          message: strings.max_characters_exceeded,
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors?.[DESCRIPTION_MAPPER[lang.value]] &&
                        errors?.[DESCRIPTION_MAPPER[lang.value]]?.message}
                    </FormErrorMessage>
                  </FormControl>
                </CustomTabs.TabPanel>
              ))}
            </CustomTabs.TabPanels>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          templateColumns="repeat(2, 1fr)"
          flex="1"
          w="100%"
          overflow="auto">
          <GridItem>
            <FormControl isInvalid={!!errors?.show_on_page} isRequired>
              <FormLabel>{strings.show_on_landing_page}</FormLabel>
              <Controller
                name="show_on_page"
                rules={{
                  required: strings.required,
                }}
                defaultValue="1"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="show_on_page">
                    <Stack spacing={5} direction="row">
                      <Radio value="1">{strings.yes}</Radio>
                      <Radio value="0">{strings.no}</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.show_on_page && errors?.show_on_page?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.is_default} isRequired>
              <FormLabel>{strings.default_service}</FormLabel>
              <Controller
                name="is_default"
                rules={{
                  required: strings.required,
                }}
                defaultValue="1"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="is_default">
                    <Stack spacing={5} direction="row">
                      <Radio value="1">{strings.yes}</Radio>
                      <Radio value="0">{strings.no}</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.is_default && errors?.is_default?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.early_deliverable} isRequired>
              <FormLabel>{strings.early_deliverable}</FormLabel>
              <Controller
                name="early_deliverable"
                rules={{
                  required: strings.required,
                }}
                defaultValue="1"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="early_deliverable">
                    <Stack spacing={5} direction="row">
                      <Radio value="1">{strings.yes}</Radio>
                      <Radio value="0">{strings.no}</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.early_deliverable &&
                  errors?.early_deliverable?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              isInvalid={!!errors?.early_deliverable_start}
              isRequired>
              <FormLabel>{strings.early_deliverable_on_start}</FormLabel>
              <Controller
                name="early_deliverable_start"
                rules={{
                  required: strings.required,
                }}
                defaultValue="1"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="early_deliverable_start">
                    <Stack spacing={5} direction="row">
                      <Radio value="1">{strings.yes}</Radio>
                      <Radio value="0">{strings.no}</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.early_deliverable_start &&
                  errors?.early_deliverable_start?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.pay_to_vendor} isRequired>
              <FormLabel>{strings.pay_to_vendor}</FormLabel>
              <Controller
                name="pay_to_vendor"
                rules={{
                  required: strings.required,
                }}
                defaultValue="1"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="pay_to_vendor">
                    <Stack spacing={5} direction="row">
                      <Radio value="1">{strings.yes}</Radio>
                      <Radio value="0">{strings.no}</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors?.pay_to_vendor && errors?.pay_to_vendor?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.is_dog_service} isRequired>
              <FormLabel>{strings.dog_service}</FormLabel>
              <Controller
                name="is_dog_service"
                rules={{
                  required: strings.required,
                }}
                defaultValue="1"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="is_dog_service">
                    <Stack spacing={5} direction="row">
                      <Radio value="1">{strings.yes}</Radio>
                      <Radio value="0">{strings.no}</Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors.is_dog_service && errors.is_dog_service?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.icon} isRequired>
              <FormLabel>{strings.icon}</FormLabel>
              <ImageUploader
                fileKey="icon"
                required={true}
                accept={['image/png', 'image/svg+xml']}
                uploadedFiles={serviceGroupData?.icon}
                description={strings.upload_image + ' ( .png / .svg )'}
                title={strings.drag_icon_or_click_to_select_icon}
              />
              <FormErrorMessage>
                {errors.icon && errors.icon?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.cover} isRequired>
              <FormLabel>{strings.cover_image}</FormLabel>
              <ImageUploader
                fileKey="cover"
                accept="image/*"
                uploadedFiles={serviceGroupData?.cover}
                title={strings.drag_image_or_click_to_select_image}
              />
              <FormErrorMessage>
                {errors.cover && errors.cover?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </CustomTabs>
    </form>
  );
};

export default ServiceGroupForm;
