import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PNSResource from 'api/push_notification';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import NotificationListItem from 'components/pns/NotificationListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  PUSH_NOTIFICATION_OPTIONS,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { PNSOptions } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  date_time: string;
  channel: string;
  title: string;
}

const PushNotificationList: React.FC = () => {
  const pnsAPI = new PNSResource();
  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const date_time = searchParams.get('date_time') ?? '';
  const channel = searchParams.get('channel') ?? '';
  const title = searchParams.get('title') ?? '';

  const [documentCreatedAt, setDocumentCreatedAt] = useState<any>();

  const checkAddNotificationPermission = useCheckPermission(
    [PermissionRequest['read:pns'], PermissionRequest['send:push-notificaton']],
    routes.pns.notification.add
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    date_time: date_time ?? '',
    channel: channel ?? '',
    title: title ?? '',
  });

  const notificationList: any = useQuery(
    [
      'notificationList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        date_time: filterParams.date_time,
        channel: filterParams.channel,
        title: filterParams.title,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.date_time)
        queryParams.created_at = filterParams.date_time;
      if (filterParams.channel) queryParams.channel = filterParams.channel;
      if (filterParams.title) queryParams.keyword = filterParams.title;
      const response = await pnsAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
      date_time: documentCreatedAt
        ? moment(documentCreatedAt).format(REACT_DATE_PICKER_FORMAT_YYYY_MM_DD)
        : prevState.date_time,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  useEffect(() => {
    if (documentCreatedAt) {
      setFilterParams((prevState) => ({
        ...prevState,
        date_time: moment(documentCreatedAt).format(
          REACT_DATE_PICKER_FORMAT_YYYY_MM_DD
        ),
      }));
    }
  }, [documentCreatedAt]);

  const handleDateChange = (date: Date) => {
    setDocumentCreatedAt(date);
  };

  const handleReset = () => {
    setDocumentCreatedAt(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      date_time: '',
      channel: '',
      title: '',
    }));
    history.push(routes.pns.notification.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.pns} | {strings.all_notifications}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.pns}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.pns.notification.list}>
              {strings.all_notifications}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.push_notification}
          </Heading>
          <RouterLink to={routes.pns.notification.add}>
            <AccessControl
              allowedPermissions={[PermissionRequest['send:push-notificaton']]}
              renderNoAccess={(data: any) => data}>
              <Button colorScheme="primary" size="sm">
                {strings.add_new_push_notification}
              </Button>
            </AccessControl>
          </RouterLink>
        </Flex>
        <Stack
          direction="column"
          spacing="4"
          bg="white"
          p={['3', '6']}
          shadow="box"
          w="full">
          <form>
            <Stack direction="column" spacing="4">
              <Stack
                direction={['column', 'column', 'row']}
                spacing="4"
                align={['start', 'start', 'end']}>
                <Grid
                  gap={['4', '4', '6']}
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(3, 1fr)',
                  ]}
                  w="100%">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <Input
                          type="text"
                          name="title"
                          value={filterParams.title}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.date_time}</FormLabel>
                      <InputGroup>
                        <InputRightElement
                          children={<BiCalendar />}
                          color="gray.400"
                        />
                        <ReactDatePicker
                          name="date_time"
                          placeholderText={strings.select_date}
                          value={filterParams.date_time}
                          dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                          id="date_time"
                          selected={documentCreatedAt}
                          customInput={
                            <CustomDateInput value={documentCreatedAt} />
                          }
                          onChange={handleDateChange}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.channel}</FormLabel>
                      <Select
                        name="channel"
                        value={filterParams.channel}
                        placeholder={strings.channel}
                        rounded="sm"
                        onChange={handleInputChange}>
                        {PUSH_NOTIFICATION_OPTIONS?.map((item: PNSOptions) => {
                          return (
                            <option key={item} value={item}>
                              {strings[item]}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Stack direction="row" spacing="4">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleReset}>
                    {strings.reset_filter}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.date_time}</Th>
                  <Th>{strings.channel}</Th>
                  <Th>{strings.title}</Th>
                  <Th>{strings.text}</Th>
                  <Th>{strings.link}</Th>
                  <Th isNumeric>{strings.total_read}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!notificationList.isLoading &&
                  notificationList?.data?.data?.map(
                    (notificationData: any, index: number) => (
                      <NotificationListItem
                        notificationData={notificationData}
                        key={notificationData.id}
                        index={startingSN + index}
                      />
                    )
                  )}
                {notificationList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          dataList={notificationList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default PushNotificationList;
