import routes from 'constants/routes';
import { ForgotPassword, Login } from 'pages/auth';
import ResetPassword from 'pages/auth/ResetPassword';
import SetPassword from 'pages/auth/SetPassword';
import VerifyEmail from 'pages/auth/VerifyEmail';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getAccessToken } from 'services/auth';

interface IProps {
  isLoggedIn?: boolean;
  [key: string]: any;
}

const PublicRoute: React.FC<IProps> = () => {
  let isLoggedIn = getAccessToken();

  return !isLoggedIn ? (
    <Switch>
      <Route path={routes.auth.login} component={Login} />
      <Route path={routes.auth.forgotPassword} component={ForgotPassword} />
      <Route path={routes.auth.resetPassword} component={ResetPassword} />
      <Route path={routes.auth.setPassword} component={SetPassword} />
      <Route path={routes.auth.verifyEmail} component={VerifyEmail} />
    </Switch>
  ) : (
    <Redirect to={routes.splash} />
  );
};

export default PublicRoute;
