import Resource from 'api/resource';
import axios from 'axios';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: any;

class BookingTerminalResource extends Resource {
  constructor() {
    super('rms/booking-terminal');
  }

  getCheckInList(query?: any) {
    if (cancel) {
      cancel();
    }
    return http({
      url: '/rms/booking-terminal/check-in/list',
      method: 'GET',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  getCheckOutList(query?: any) {
    if (cancel) {
      cancel();
    }
    return http({
      url: '/rms/booking-terminal/check-out/list',
      method: 'GET',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  updateCheckIn(id: string) {
    return http({
      url: `/rms/booking-terminal/${id}/check-in`,
      method: 'PUT',
    });
  }

  updateCheckOut(id: string) {
    return http({
      url: `/rms/booking-terminal/${id}/check-out`,
      method: 'PUT',
    });
  }
}

export { BookingTerminalResource as default };
