import Resource from 'api/resource';
import http from 'utils/http';

class FeedbackQuestionResource extends Resource {
  constructor() {
    super('feedback/question');
  }
  updateSerialNumber(oldSerialNumber: number, newSerialNumber: number) {
    return http({
      url: '/' + this.uri + '/update-serial',
      method: 'put',
      data: {
        old_serial: oldSerialNumber,
        new_serial: newSerialNumber,
      },
    });
  }
}

export { FeedbackQuestionResource as default };
