import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ObjectDocumentResource from 'api/object_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import ObjectDocumentForm from 'components/object_document/ObjectDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const UploadObjectsDocument: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const methods = useForm<any>();
  const objectDocumentAPI = new ObjectDocumentResource();
  const [errorMessage, setErrorMessage] = useState('');

  const addObjectDocument = useMutation(
    (data: any) => objectDocumentAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: strings.general_document_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('objectDocumentList');
        history.push(routes.dms.objectDocuments.list);
      },
      onError: (error: any, response: any) => {
        if (error?.response?.data?.file) {
          setErrorMessage(error?.response?.data?.file[0]);
        } else {
          setErrorMessage(strings.general_document_creation_failed);
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    const formData: any = new FormData();

    formData.append('name', data.name);
    formData.append('apartment_id', data.apartment_id?.value);
    if (data.available_from_date)
      formData.append('available_from_date', data.available_from_date);
    if (data.available_to_date)
      formData.append('available_to_date', data.available_to_date);
    if (data.upload_files === undefined) {
      methods.setError('upload_files', { message: strings.required_upload });
      return;
    }
    formData.append('file', data.upload_files);
    addObjectDocument.mutate(formData);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.dms} | {strings.upload_document_object}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.objectDocuments.list}>
              {strings.document_objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.objectDocuments.upload}>
              {strings.upload_document_object}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.upload_document_object}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack
                direction="column"
                spacing="10"
                justifyContent="space-between">
                {addObjectDocument.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
                <ObjectDocumentForm />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={addObjectDocument.isLoading}>
                    {strings.save_now}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default UploadObjectsDocument;
