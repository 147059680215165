import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { DashAccordionButtonStyle } from 'assets/css/commonStyles';
import FeaturesPanel from 'components/rms/AccordionComponents/FeaturesPanel';
import GeneralPanel from 'components/rms/AccordionComponents/GeneralPanel';
import NotesPanel from 'components/rms/AccordionComponents/NotesPanel';
import RepairPanel from 'components/rms/AccordionComponents/RepairPanel';
import { strings } from 'config/localization';
import { V_OFFICE_URL } from 'constants/common';
import React from 'react';
import ObjectDocumentArea from './ObjectDocumentArea';

interface ObjektDetailsInfoProps {
  data: any;
}
const imageBaseURL = `${V_OFFICE_URL}/image/s/`;

const ObjectArea: React.FC<ObjektDetailsInfoProps> = (props) => {
  const { data: apartmentDetails } = props;

  const {
    id,
    info,
    owner_info,
    inform_owner_repairs,
    owner_repairs_allowed,
    allowed_repairs_max_price,
    notes,
  } = apartmentDetails;

  const { description, headline, images } = info;

  return (
    <Accordion defaultIndex={[0]} allowToggle width="100%">
      <AccordionItem bg="gray.50">
        <AccordionButton sx={DashAccordionButtonStyle}>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text color="white" as="h4" fontWeight="medium">
                {strings.notes}
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <NotesPanel gridTemplateColumns="repeat(1,1fr)" notes={notes} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50">
        <AccordionButton sx={DashAccordionButtonStyle}>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text color="white" as="h4" fontWeight="medium">
                {strings.repairs}
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <RepairPanel
            disableRepair={true}
            informOwnerRepairs={inform_owner_repairs}
            ownerRepairsAllowed={owner_repairs_allowed}
            allowedRepairsMaxPrice={allowed_repairs_max_price}
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50">
        <AccordionButton sx={DashAccordionButtonStyle}>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text color="white" as="h4" fontWeight="medium">
                {strings.documents}
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <ObjectDocumentArea objectId={id} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50">
        <AccordionButton sx={DashAccordionButtonStyle}>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text color="white" as="h4" fontWeight="medium">
                {strings.general} (vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          <GeneralPanel
            isDashboard={true}
            ownerInfo={owner_info}
            objektInfo={info}
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50">
        <AccordionButton sx={DashAccordionButtonStyle}>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text color="white" as="h4" fontWeight="medium">
                {strings.features}(vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="2" overflow="auto">
          <FeaturesPanel objektInfo={info} isDashboard={true} />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50">
        <AccordionButton sx={DashAccordionButtonStyle}>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text color="white" as="h4" fontWeight="medium">
                {strings.text} (vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="2">
          <Heading as="h3" size="sm" color="gray.800" mt="2">
            {headline?.de}
          </Heading>
          <Text as="p" mt="2" mb="2">
            {description?.de}
          </Text>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem bg="gray.50">
        <AccordionButton sx={DashAccordionButtonStyle}>
          <Box flex="1" textAlign="left">
            <Flex justify="space-between">
              <Text color="white" as="h4" fontWeight="medium">
                {strings.picture} (vOffice)
              </Text>
            </Flex>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="2">
          <Grid
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            gridGap="4">
            {images !== undefined &&
              images.map((picture: any, index: number) => (
                <Image
                  src={`${imageBaseURL}${picture.id}`}
                  key={picture.id}
                  alt={picture.name}
                />
              ))}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ObjectArea;
