import Resource from 'api/resource';

class PMSCreditCardResource extends Resource {
  constructor() {
    super(
      'rms/payments?payment_method_group[]=STRIPE&payment_method_group[]=VR_PAYMENT'
    );
  }
}

export { PMSCreditCardResource as default };
