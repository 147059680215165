import Resource from 'api/resource';
import http from 'utils/http';

class FAQGroupResource extends Resource {
  constructor() {
    super('faq/groups');
  }
  updateFAQGroup(id:any,data:any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'POST',
      data: data,
    });
  };
}

export { FAQGroupResource as default };
