import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import BreadBookingResource from 'api/bread_booking';
import BreadBookingInfo from 'components/bms_bread_booking/BreadBookingInfo';
import BreadBookingServiceInfo from 'components/bms_bread_booking/BreadBookingServiceInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReservationBooking } from 'constants/schemas/reservationBooking';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const BreadBookingDetail: React.FC = () => {
  const { id }: any = useParams();
  const breadBookingApi = new BreadBookingResource();
  const { search } = useLocation();

  const breadBookingQuery = useQuery<ReservationBooking>(
    [`bread-booking${id}`],
    () => breadBookingApi.getBreadBooking(id).then((res) => res.data.data)
  );

  if (breadBookingQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (breadBookingQuery.isError) {
    history.push(routes.bs.breadBooking.list + search);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bs} | {breadBookingQuery.data?.reservation?.booking_number}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bs}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bs.breadBooking.list + search}>
              {strings.bread_booking}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {breadBookingQuery.data?.reservation?.booking_number || '-'}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {breadBookingQuery.data && (
          <>
            <BreadBookingInfo
              breadBookingData={breadBookingQuery.data}
              bookingId={id}
              search={search}
            />
            {breadBookingQuery.data?.bookings.length > 0 && (
              <BreadBookingServiceInfo
                breadBookingData={breadBookingQuery?.data?.bookings}
                paymentData={breadBookingQuery?.data?.payment}
                status={breadBookingQuery?.data?.status}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default BreadBookingDetail;
