import BookingDash from 'components/object-dashboard/dashboard/booking/BookingDash';
import CrossSellingDash from 'components/object-dashboard/dashboard/cross-selling/CrossSellingDash';
import CustomerDash from 'components/object-dashboard/dashboard/customer/CustomerDash';
import DocumentDash from 'components/object-dashboard/dashboard/document/DocumentDash';
import FeedbackDash from 'components/object-dashboard/dashboard/feedback/FeedbackDash';
import TicketDash from 'components/object-dashboard/dashboard/ticket/TicketDash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}
const Customer: React.FC<Props> = (props) => {
  const { setTitle } = props;
  const { id } = useParams<{ id: string; dashboard: string }>();
  let resourceId = Number(id);

  const [customerEmail, setCustomerEmail] = useState('-');
  return (
    <>
      <CrossSellingDash resourceId={resourceId} customerEmail={customerEmail} />
      <CustomerDash
        resourceId={resourceId}
        setTitle={setTitle}
        setCustomerEmailAddress={setCustomerEmail}
      />
      <BookingDash dashboardType="customer" resourceId={resourceId} />
      <DocumentDash resourceId={resourceId} customerEmail={customerEmail} />
      <TicketDash dashboardType="customer" resourceId={resourceId} />
      <FeedbackDash dashboardType="customer" resourceId={resourceId} />
    </>
  );
};

export default Customer;
