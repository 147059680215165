import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, Grid, GridItem, Heading, Stack, Text } from '@chakra-ui/layout';
import ReservationResources from 'api/reservation';
import {
  infoStyles,
  labelStyles,
  selectColorScheme,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const ReservationDetail: React.FC = () => {
  const { id: reservationID }: any = useParams();
  const ReservationAPI = new ReservationResources();
  const { search } = useLocation();

  const { data: reservationDetailsData, isLoading } = useQuery(
    `reservation-details-${reservationID}`,
    () => ReservationAPI.get(reservationID).then((res) => res.data.data)
  );
  const formatPrice = (value: number) => {
    let euroCurrency;
    euroCurrency = (value / 100).toLocaleString('de-DE', {
      minimumFractionDigits: 2,
    });
    return euroCurrency;
  };

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  } else if (!reservationDetailsData) {
    return <div>{strings.error}</div>;
  }

  let { customer, bookings, service_bookings, apartment, payment } =
    reservationDetailsData;
  let status: 'confirmed' | 'cancelled' | 'pending' =
    reservationDetailsData.status;
  let { payment_method } = payment ?? {};

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.bms} | {reservationDetailsData.booking_number ?? '-'}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.bms.reservation.list + search}>
            {strings.bms}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.bms.reservation.list + search}>
            {strings.reservations}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink textTransform="capitalize">
            {reservationDetailsData.booking_number ?? '-'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Stack direction="column" spacing="4">
        <Heading size="md" textTransform="capitalize">
          {strings.reservation_detail}
        </Heading>

        <Stack sx={wrapperStyles}>
          <Grid
            gap="2"
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
            ]}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.bookings}:</FormLabel>
                <Box sx={infoStyles}>
                  {reservationDetailsData?.booking_number ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.booking_period}:
                </FormLabel>
                <Box sx={infoStyles}>
                  {reservationDetailsData?.booking_period ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.units}:</FormLabel>
                <Box sx={infoStyles}>{apartment?.apartment_name ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.status}:</FormLabel>
                <Box sx={infoStyles}>
                  {status ? (
                    <Text
                      color={selectColorScheme(status)}
                      textTransform="uppercase"
                      fontWeight="medium"
                      fontSize="sm">
                      {strings[status]}
                    </Text>
                  ) : (
                    '-'
                  )}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.payment_method}:
                </FormLabel>
                <Box sx={infoStyles}>
                  {payment_method === 'INVOICE'
                    ? strings.bank_transfer
                    : payment_method ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.adult}:</FormLabel>
                <Box sx={infoStyles}>{bookings?.adults ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.children}:</FormLabel>
                <Box sx={infoStyles}>{bookings?.children ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.babies}:</FormLabel>
                <Box sx={infoStyles}>{bookings?.babys ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.dog}</FormLabel>
                <Box sx={infoStyles}>{bookings?.hund || 0}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.dog_breed}</FormLabel>
                <Box sx={infoStyles}>{bookings?.dog_breed || '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
          <Grid gap="2" templateColumns={['repeat(1, 1fr)']} w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.message}</FormLabel>
                <Box sx={infoStyles}>{bookings?.notes || '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
      <Stack direction="column" spacing="4">
        <Heading as="h3" size="md" textTransform="capitalize">
          {strings.customer_detail}
        </Heading>
        <Stack sx={wrapperStyles}>
          <Grid
            gap="2"
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
            ]}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.first_name}:</FormLabel>
                <Box sx={infoStyles}>{customer?.forename ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.last_name}:</FormLabel>
                <Box sx={infoStyles}>{customer?.surname ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.email}:</FormLabel>
                <Box sx={infoStyles}>{customer?.email ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.mobile}:</FormLabel>
                <Box sx={infoStyles}>{customer?.mobile ?? '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.country}:</FormLabel>
                <Box sx={infoStyles}>
                  {customer?.mainAddress?.country ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.street}:</FormLabel>
                <Box sx={infoStyles}>
                  {customer?.mainAddress?.street ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.house_number}:</FormLabel>
                <Box sx={infoStyles}>
                  {customer?.mainAddress?.house_number ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.post_code}:</FormLabel>
                <Box sx={infoStyles}>
                  {customer?.mainAddress?.postalcode ?? '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.city}:</FormLabel>

                <Box sx={infoStyles}>{customer?.mainAddress?.city ?? '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>

      {service_bookings?.length > 0 && (
        <>
          <Stack direction="column" spacing="4">
            <Heading as="h3" size="md" textTransform="capitalize">
              {strings.service_line}
            </Heading>
            <Stack sx={wrapperStyles}>
              <Grid
                gap="4"
                templateColumns={[
                  'repeat(2, 1fr)',
                  'repeat(3, 1fr)',
                  'repeat(5, 1fr)',
                ]}
                w="100%">
                <GridItem>
                  <Text color="blackAlpha.900">{strings.service}</Text>
                </GridItem>
                <GridItem>
                  <Text color="blackAlpha.900">{strings.price_pound}</Text>
                </GridItem>
                <GridItem>
                  <Text color="blackAlpha.900">{strings.quantity}</Text>
                </GridItem>
                <GridItem>
                  <Text color="blackAlpha.900">{strings.timespan}</Text>
                </GridItem>
                <GridItem>
                  <Text color="blackAlpha.900">{strings.total_pound}</Text>
                </GridItem>
                {service_bookings.map((service_booking: any) => (
                  <Fragment key={service_booking.id}>
                    <GridItem>
                      <Box sx={infoStyles}>{service_booking.service_name}</Box>
                    </GridItem>
                    <GridItem>
                      <Box sx={infoStyles}>
                        {formatPrice(service_booking.unit_price)} &euro;
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box sx={infoStyles}>{service_booking.quantity}</Box>
                    </GridItem>
                    <GridItem>
                      <Box sx={infoStyles}>{service_booking.timespan}</Box>
                    </GridItem>
                    <GridItem>
                      <Box sx={infoStyles}>
                        {formatPrice(service_booking.total_price)} &euro;
                      </Box>
                    </GridItem>
                  </Fragment>
                ))}
              </Grid>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default ReservationDetail;
