import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import BreadDailyViewResource from 'api/bread_booking';
import BreadBookingInfo from 'components/bms_bread_booking/BreadBookingInfo';
import BreadBookingServiceInfo from 'components/bms_bread_booking/BreadBookingServiceInfo';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReservationBooking } from 'constants/schemas/reservationBooking';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const BreadDailyViewDetail: React.FC = () => {
  const { id }: any = useParams();
  let { search } = useLocation();

  const breadDailyViewApi = new BreadDailyViewResource();
  const breadDailyViewQuery = useQuery<ReservationBooking>(
    [`bread-daily-view${id}`],
    () => breadDailyViewApi.get(id).then((res) => res.data.data)
  );

  if (breadDailyViewQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (breadDailyViewQuery.isError) {
    history.push(routes.bs.breadDailyView.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bs} |
            {breadDailyViewQuery?.data?.reservation?.booking_number || '-'}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bs}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bs.breadDailyView.list + search}>
              {strings.bread_daily_view}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {breadDailyViewQuery?.data?.reservation?.booking_number || '-'}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {breadDailyViewQuery?.data && (
          <>
            <BreadBookingInfo
              breadBookingData={breadDailyViewQuery.data}
              title={strings.bread_daily_view_details}
              pageName={'breadDailyView'}
            />
            {breadDailyViewQuery.data?.bookings.length > 0 && (
              <BreadBookingServiceInfo
                breadBookingData={breadDailyViewQuery?.data?.bookings}
                paymentData={breadDailyViewQuery?.data?.payment}
                status={breadDailyViewQuery?.data?.status}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default BreadDailyViewDetail;
