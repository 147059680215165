import {
  Box,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import {
  AIRCALL_MODAL_CLOSEABLE_TIME,
  LISTINFO_SERVICE_CENTER_TITLE,
} from 'constants/common';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { FiPhoneCall } from 'react-icons/fi';
import { shallowEqual, useSelector } from 'react-redux';
import { airCallSocket } from 'services/socket';

type CustomerDataSchema = {
  id: number;
  firstName: string;
  lastName: string;
};

type AircallDataSchema = {
  context: CustomerDataSchema;
  userId?: number;
};

const Aircall: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalClosable, setIsModalCloseable] = useState(false);
  const [customer, setCustomer] = useState<CustomerDataSchema | null>(null);

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state.data.auth.user,
    }),
    shallowEqual
  );

  /**
   * When call rings
   */
  useEffect(() => {
    airCallSocket.on('onCallRingingOnAgent', (message: AircallDataSchema) => {
      setCustomer(message.context);
      setIsModalOpen(true);
      document.title = `${strings.incoming_call}...`;

      /**
       * Cannot close modal for few seconds as user might be clicking
       * and the modal mistakely closes without user getting to read
       */
      setTimeout(() => {
        setIsModalCloseable(true);
      }, AIRCALL_MODAL_CLOSEABLE_TIME);
    });

    return () => {
      airCallSocket.off('onCallRingingOnAgent');
    };
  }, []);

  /**
   * When call is answered
   */
  useEffect(() => {
    airCallSocket.on('onCallAnswered', (message: AircallDataSchema) => {
      // If modal is not open, it means I am not being called. No need to close modal.
      if (!isModalOpen) return;

      const { context, userId } = message;
      const didCustomerWhoIsCallingMeAnswered = customer?.id === context.id;
      const someoneElseAnswered = userId !== loggedInUser.id;

      if (didCustomerWhoIsCallingMeAnswered && someoneElseAnswered) {
        setIsModalOpen(false);
        setIsModalCloseable(false);
        setCustomer(null);
        document.title = LISTINFO_SERVICE_CENTER_TITLE;
      }
    });

    return () => {
      airCallSocket.off('onCallAnswered');
    };
  }, [customer?.id, isModalOpen, loggedInUser.id]);

  /**
   * When call is hunged up
   */
  useEffect(() => {
    airCallSocket.on('onCallHungUp', (message: AircallDataSchema) => {
      // If modal is not open, it means I am not being called. No need to close modal.
      if (!isModalOpen) return;

      const { context } = message;
      const didCustomerWhoIsCallingMeHungup = customer?.id === context.id;

      if (didCustomerWhoIsCallingMeHungup) {
        setIsModalOpen(false);
        setIsModalCloseable(false);
        setCustomer(null);
        document.title = LISTINFO_SERVICE_CENTER_TITLE;
      }
    });

    return () => {
      airCallSocket.off('onCallHungUp');
    };
  }, [customer?.id, isModalOpen]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsModalCloseable(false);
    setCustomer(null);
    document.title = LISTINFO_SERVICE_CENTER_TITLE;
  };

  const handleDelayedCloseModal = () => {
    if (isModalClosable) handleCloseModal();
  };

  return (
    <Modal isOpen={isModalOpen} isCentered onClose={handleDelayedCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack direction="row" alignItems="center" spacing={2}>
            <FiPhoneCall />
            <Box>{strings.incoming_call}...</Box>
          </Stack>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pt={0} pb={4}>
          <Box>
            {strings.formatString(
              strings._customer_is_calling,
              `${customer?.firstName} ${customer?.lastName}`
            )}
          </Box>
          <span onClick={handleCloseModal}>
            <Link
              variant="link"
              href={routes.objectDashboard.dashboard.customerBoard.replace(
                ':id',
                customer?.id.toString() ?? ''
              )}
              color="red.400"
              isExternal>
              {strings.gotoDetails}
            </Link>
          </span>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Aircall;
