import axios from 'axios';
import http from 'utils/http';
import Resource from './resource';

const CancelToken = axios.CancelToken;
let cancel: any;

class OwnerDocumentResource extends Resource {
  constructor() {
    super('dms/owner-document');
  }

  getByOwnerDocument(query?: any) {
    if (cancel) {
      cancel();
    }
    return http({
      url: 'dms/owner-document-group',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  getOwnerList(query?: any) {
    return http({
      url: 'dms/owner',
      method: 'get',
      params: query,
    });
  }

  getOwner(id: any) {
    return http({
      url: 'dms/owner/' + id,
      method: 'get',
    });
  }
}

export { OwnerDocumentResource as default };
