import Resource from 'api/resource';
import axios from 'axios';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: any;

class FeedbackResource extends Resource {
  constructor() {
    super('feedback');
  }

  getFeedbackAnalysis(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: '/' + this.uri + '/analysis',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }
  getGeneralScore() {
    return http({
      url: '/' + this.uri + '/score',
      method: 'get',
    });
  }
  apartmentScore(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: '/' + this.uri + '/apartment-score',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }
  feedbackMailList(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: '/' + this.uri + '/mail',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }
  getApartmentList(query?: any) {
    return http({
      url: '/' + this.uri + '/apartment',
      method: 'get',
      params: query,
    });
  }

  sendFeedbackMail(reservationId: number) {
    return http({
      url: `${this.uri}/mail/${reservationId}`,
      method: 'put',
    });
  }
  getFeedbackDaysSetting() {
    return http({
      url: '/' + this.uri + '/setting',
      method: 'get',
    });
  }
  setFeedbackDaysSetting(data: any) {
    return http({
      url: '/' + this.uri + '/setting',
      method: 'put',
      data,
    });
  }
  getFeedbackById(id: any) {
    return http({
      url: '/' + this.uri + '/response/' + id,
      method: 'get',
    });
  }
  getFeedbackList(query?: any) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: '/' + this.uri + '/response',
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  updateFeedbackStatus(id: any, data: any) {
    return http({
      url: '/' + this.uri + '/response/' + id,
      method: 'put',
      data,
    });
  }
  deleteFeedbackMail(id: number) {
    return http({
      url: `${this.uri}/mail/${id}`,
      method: 'delete',
    });
  }
  resendFeedbackMail(id: any) {
    return http({
      url: `${this.uri}/resend/${id}`,
      method: 'put',
    });
  }
  downloadOpenEnded(query: any) {
    return http({
      url: `${this.uri}/responses/question`,
      method: 'get',
      params: query,
      responseType: 'blob',
    });
  }
}

export { FeedbackResource as default };
