import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { closeAllActiveChats, getChatMetrics } from 'api/chat';
import { wrapperStyles } from 'assets/css/commonStyles';
import ChatMetricListItem from 'components/chat/ChatMetricListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useIsPermitted from 'hooks/usIsPermitted';
import React, { useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const ChatMetrics: React.FC = () => {
  const searchFormRef = useRef<HTMLFormElement>(null);

  const history = useHistory();
  const { search } = useLocation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchParams = new URLSearchParams(search);
  const url_keyword = searchParams.get('keyword');
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: url_keyword ?? '',
  });
  const chatMetricsList = useQuery(
    [
      'chatMetricsList',
      {
        page: filterParams.currentPage,
        name: filterParams.keyword,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.name = filterParams.keyword;
      const response = await getChatMetrics(queryParams);
      return response?.data;
    }
  );

  const handleReset = async () => {
    searchFormRef.current?.reset();
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
    history.push(routes.chat.metrics);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data: any = {
      ...filterParams,
      [name]: value,
    };
    delete data?.currentPage;
    delete data?.pageSize;
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const hasCloseActiveChatPermission = useIsPermitted([
    PermissionRequest['read:chat-metrics'],
  ]);

  const closeAllChats = useMutation(() => closeAllActiveChats(), {
    onSuccess: () => {
      toast({
        status: 'success',
        title: strings.successfully_reset_all_active_chats,
      });
      onClose();
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.error_boundary_heading_text,
      });
    },
  });

  const handleCloseAllActiveChats = () => {
    closeAllChats.mutate();
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.chat} | {strings.all_chat_metrics}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.chat.default}>
            {strings.chat}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.all_chat_metrics}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.chat_metrics}
        </Heading>
        {hasCloseActiveChatPermission && (
          <>
            <Button
              size="sm"
              colorScheme="primary"
              type="button"
              onClick={onOpen}>
              {strings.close_all_active_chats}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{strings.close_all_active_chats}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{strings.confirm_close_all_active_chats}</ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    isLoading={closeAllChats.isLoading}
                    onClick={handleCloseAllActiveChats}>
                    {strings.confirm}
                  </Button>
                  <Button variant="outline" onClick={onClose}>
                    {strings.cancel}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )}
      </Flex>
      <Stack sx={wrapperStyles}>
        <form ref={searchFormRef} onSubmit={(e) => e.preventDefault()}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.search}</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<BiSearch />}
                  color="gray.400"
                />
                <Input
                  name="keyword"
                  type="text"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>

            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                onClick={handleReset}
                variant="outline">
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.employee_name}</Th>
                <Th>{strings.active}</Th>
                <Th isNumeric>{strings.no_of_active_chat}</Th>
                <Th isNumeric>{strings.no_of_chat}</Th>
                <Th isNumeric>{strings.no_messages_received}</Th>
                <Th isNumeric>{strings.no_messages_send}</Th>
                <Th isNumeric>{strings.time_spent_in_call}</Th>
              </Tr>
            </Thead>

            <Tbody>
              {chatMetricsList?.data?.data?.map((item: any, index: number) => (
                <ChatMetricListItem
                  key={item._id}
                  startingSN={startingSN + index}
                  data={item}
                />
              ))}
              {chatMetricsList.isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={chatMetricsList}
      />
    </Stack>
  );
};

export default ChatMetrics;
