import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import BreadBookingResource from 'api/bread_booking';
import { wrapperStyles } from 'assets/css/commonStyles';
import BreadBookingForm from 'components/bms_bread_booking/BreadBookingForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { BreadBookingSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { buildFormData } from 'utils';

const AddBreadBooking: React.FC = () => {
  const { id: bookingID }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const { search } = useLocation();
  const methods = useForm<BreadBookingSchema>();

  const [errorMessage, setErrorMessage] = useState<any>();
  const [error, setError] = useState<any>('');
  const [formData, setFormData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const brotchenAPI = new BreadBookingResource();

  const createBreadBooking = useMutation((data: any) =>
    brotchenAPI.createBakeryBooking(data)
  );
  const updateBreadBooking = useMutation((data: any) =>
    brotchenAPI.updateBreadBooking(bookingID, data)
  );
  const breadBookingQuery = useQuery<BreadBookingSchema | null>(
    [`bread-booking${bookingID}`],
    () => {
      if (!bookingID) return null;
      return brotchenAPI
        .getBreadBooking(bookingID)
        .then((res) => res.data.data)
        .catch();
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const onSubmit = (data: any) => {
    let API: any;
    let apiData: any;
    if (bookingID) {
      API = updateBreadBooking;
      apiData = { service_lines: [...data.service_lines] };
    } else {
      API = createBreadBooking;
      apiData = buildFormData(data);
    }
    setIsLoading(true);
    API.mutate(apiData, {
      onSuccess: (res: any) => {
        toast({
          title: bookingID
            ? strings.bread_booking_updated
            : strings.bread_booking_created,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        queryClient.invalidateQueries('breadBookingList');
        if (bookingID) {
          history.push(
            routes.bs.breadBooking.details.replace(':id', bookingID.toString())
          );
        } else {
          history.push(routes.bs.breadBooking.list);
        }
      },
      onError: (error: any, response: any) => {
        toast({
          title: strings.invalid_data,
          status: 'error',
          isClosable: true,
        });
        setIsLoading(false);
      },
    });
  };

  if (bookingID && breadBookingQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (bookingID && breadBookingQuery.isError) {
    history.push(routes.bs.breadBooking.list);
  }
  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.bs} |
          {bookingID ? strings.edit_bread_booking : strings.add_bread_booking}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.bs}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.bs.breadBooking.list + search}>
            {strings.bread_booking}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink
            as={RouterLink}
            to={
              bookingID
                ? routes.bs.breadBooking.edit.replace(
                    ':id',
                    bookingID.toString()
                  )
                : routes.bs.breadBooking.add
            }>
            {bookingID ? `${bookingID}` : strings.add_bread_booking}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {bookingID
            ? `${strings.edit_bread_booking} -${bookingID}`
            : strings.add_bread_booking}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {errorMessage && (
                <Alert status="error">
                  <ul>
                    <AlertIcon />
                    {Object.values(errorMessage)?.map(
                      (error: any, index: number) => (
                        <li key={`${index}${error}`}>{error}</li>
                      )
                    )}
                  </ul>
                  {!errorMessage && strings.bread_booking_creation_failed}
                </Alert>
              )}
              <BreadBookingForm
                setFormData={setFormData}
                breadBookingData={breadBookingQuery?.data}
                setError={setError}
                error={error}
              />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="button"
                  disabled={
                    (formData?.service_lines?.length !== 0 ? false : true) ||
                    error
                  }
                  onClick={() => onSubmit(formData)}
                  isLoading={isLoading}>
                  {bookingID
                    ? `${strings.update_bread_booking}`
                    : strings.add_bread_booking}
                </Button>
                <Button variant="outline" onClick={() => history.goBack()}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddBreadBooking;
