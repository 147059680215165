import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from '@chakra-ui/react';
import CheckInList from 'components/bms_onboarding/CheckInList';
import CheckOutList from 'components/bms_onboarding/CheckOutList';
import { strings } from 'config/localization';
import {
  IS_SECOND_SCREEN_OPEN,
  MIRROR_SECOND_SCREEN,
  SECOND_SCREEN_CHANNEL,
  SECOND_SCREEN_STATUS,
} from 'constants/common';
import routes from 'constants/routes';
import { Binary } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FiHome, FiMonitor } from 'react-icons/fi';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

const CheckInOutList: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const broadCastAPI = useRef(new BroadcastChannel(SECOND_SCREEN_CHANNEL));

  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get('tab');
  const tabDefaultIndex = tab === 'checkout' ? 1 : 0;
  const [isSecondScreenOpen, setIsSecondScreenOpen] = useState(false);

  const handleOpenSecondScreen = () => {
    const windowFeatures = 'fullscreen=1';
    window.open(routes.secondScreen, '', windowFeatures);
    setIsSecondScreenOpen(true);
  };

  useEffect(() => {
    // check if second screen is already open
    broadCastAPI.current.postMessage({
      name: IS_SECOND_SCREEN_OPEN,
    });

    broadCastAPI.current.onmessage = (event) => {
      // when second screen is closed
      if (event.data.name === SECOND_SCREEN_STATUS) {
        setIsSecondScreenOpen(event.data.value);
      }
    };
  }, []);

  const handleTabChange = (value: number) => {
    /**
     * 0 = check in tab
     * 1 = check out tab
     */
    let checkInValue: Binary = 0;
    let urlTabValue = 'checkin';

    if (value === 1) {
      checkInValue = 1;
      urlTabValue = 'checkout';
    }

    const urlParams = `?tab=${urlTabValue}`;
    history.push(urlParams);
  };

  const handleShowWelcomeOnSecondScreen = () => {
    broadCastAPI.current.postMessage({
      name: MIRROR_SECOND_SCREEN,
      value: false,
    });
  };

  return (
    <Tabs
      isLazy
      variant="unstyled"
      defaultIndex={tabDefaultIndex}
      onChange={handleTabChange}>
      <Stack spacing="4">
        <Helmet>
          <title>
            {strings.bms} | {strings.check_in_out}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={routes.bms.checkInOut.list}>
              {strings.check_in_out}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>
              <TabPanels>
                <TabPanel>{strings.check_in}</TabPanel>
                <TabPanel>{strings.check_out}</TabPanel>
              </TabPanels>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading size="md" textTransform="capitalize">
          {`${strings.check_in} / ${strings.check_out}`}
        </Heading>
        <Flex justify="space-between">
          <TabList>
            <CheckInOutTab>{strings.check_in}</CheckInOutTab>
            <CheckInOutTab>{strings.check_out}</CheckInOutTab>
          </TabList>
          <ButtonGroup>
            {isSecondScreenOpen ? (
              <Tooltip
                hasArrow
                placement="left"
                label={strings.show_welcome_screen}>
                <Button
                  colorScheme="primary"
                  onClick={handleShowWelcomeOnSecondScreen}>
                  <FiHome />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                hasArrow
                placement="left"
                label={strings.open_second_screen}>
                <Button colorScheme="primary" onClick={handleOpenSecondScreen}>
                  <FiMonitor />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        </Flex>
        <TabPanels>
          <TabPanel p={0}>
            <CheckInList />
          </TabPanel>
          <TabPanel p={0}>
            <CheckOutList />
          </TabPanel>
        </TabPanels>
      </Stack>
    </Tabs>
  );
};

export default CheckInOutList;

function CheckInOutTab(props: { children: string }) {
  const primaryColor = 'gray.400';

  return (
    <Tab
      borderWidth="1px"
      outline="none"
      color={primaryColor}
      borderColor={primaryColor}
      fontWeight="500"
      _selected={{
        background: primaryColor,
        color: 'gray.900',
      }}>
      {props.children}
    </Tab>
  );
}
