import http from 'utils/http';
import Resource from './resource';
class DrivingOrderResource extends Resource {
  constructor() {
    super('rms/bakery-driving-orders');
  }

  updateSerialNumber(oldSerialNumber: number, newSerialNumber: number) {
    return http({
      url: '/' + this.uri + '/update-serial-number',
      method: 'put',
      data: {
        old_serial_number: oldSerialNumber,
        new_serial_number: newSerialNumber,
      },
    });
  }

  export(query: any) {
    return http({
      url: '/' + this.uri + '/export',
      params: query,
      method: 'get',
      responseType: 'blob',
    });
  }

  exportAll() {
    return http({
      url: '/' + this.uri + '/export-all',
      method: 'get',
      responseType: 'blob',
    });
  }

}

export { DrivingOrderResource as default };
