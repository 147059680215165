import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import SMSMeerSyltResource, { UpdateOrderData } from 'api/sms_meer_sylt';
import { wrapperStyles } from 'assets/css/commonStyles';
import DebounceInput from 'components/common/DebounceInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import MeerSyltListItem from 'components/sms_service/MeerSyltListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { MeerSyltSchema } from 'constants/schema';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const MeerSyltList: React.FC = () => {
  const queryClient = useQueryClient();
  const meerSyltAPI = new SMSMeerSyltResource();

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const toast = useToast();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
  });

  const meerSyltList = useQuery(
    [
      'meer-sylt-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await meerSyltAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
    }));
    history.push(routes.sms.meerSylt.list);
  };

  const updateMeerSyltItemsOrder = useMutation((data: UpdateOrderData) =>
    meerSyltAPI.updateOrder(data)
  );

  const onDragEnd = async (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination?.index === source?.index) return;
    const old_order = meerSyltList?.data?.data?.[source?.index].order;
    const new_order = meerSyltList?.data?.data?.[destination?.index].order;

    await updateMeerSyltItemsOrder.mutate(
      { old_order, new_order },
      {
        onSuccess: () => {
          toast({
            title: strings.status_changed,
            status: 'success',
            isClosable: true,
          });
          queryClient.invalidateQueries('meer-sylt-list');
        },
        onError: () => {
          toast({
            title: strings.error,
            status: 'error',
            isClosable: true,
          });
        },
      }
    );
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.sms} | {strings.meer_sylt}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.sms}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.sms.meerSylt.list}>
              {strings.meer_sylt}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.meer_sylt_tiles}
          </Heading>
          <RouterLink to={routes.sms.meerSylt.add}>
            <Button colorScheme="primary" size="sm">
              {strings.add_meer_sylt}
            </Button>
          </RouterLink>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack direction="column" spacing="4">
              <Stack
                direction={['column', 'column', 'row']}
                spacing="4"
                align={['start', 'start', 'end']}>
                <Grid
                  gap={['4', '4', '6']}
                  templateColumns={['repeat(1, 1fr)']}
                  w="100%">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.search}</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BiSearch />}
                          color="gray.400"
                        />
                        <DebounceInput
                          name="keyword"
                          setFilterParams={setFilterParams}
                          initialValue={filterParams.keyword}
                          placeholder={strings.search}
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Stack direction="row" spacing="4">
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleReset}>
                    {strings.reset_filter}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.title}</Th>
                  <Th>{strings.text}</Th>
                  <Th>{strings.link}</Th>
                  <Th>{strings.is_active}</Th>
                  <Th>{strings.is_load_on_iframe}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="MeerSyltListTable">
                  {(provided: DroppableProvided) => (
                    <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {!meerSyltList.isLoading &&
                        meerSyltList?.data?.data?.map(
                          (meerSyltData: MeerSyltSchema, index: number) => (
                            <Draggable
                              draggableId={meerSyltData.id.toString()}
                              key={meerSyltData.id}
                              index={index}>
                              {(provided: DraggableProvided) => (
                                <MeerSyltListItem
                                  meerSyltData={meerSyltData}
                                  key={meerSyltData.id}
                                  index={startingSN + index}
                                  draggableProvider={provided}
                                  search={search}
                                />
                              )}
                            </Draggable>
                          )
                        )}

                      {meerSyltList.isLoading && (
                        <TableSkeletonLoader rows={7} cols={7} />
                      )}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={meerSyltList}
        />
      </Stack>
    </>
  );
};

export default MeerSyltList;
