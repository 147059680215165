import {
  setIsLoggedIn,
  setLoggedInUser,
  setLoggingIn,
  setPermissions,
} from 'actions/data/auth';
import { getAuthPermission, getAuthUser, login } from 'api/auth';
import { invalid2FA, message2FA } from 'constants/common';
import routes from 'constants/routes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as authService from 'services/auth';

/**
 * Auth state Higher Order Component.
 * Use this HOC if you need to use/modify User state.
 */
function withAuthState(WrappedComponent: any) {
  class Auth extends Component<any, any> {
    /**
     * Login user and save tokens and user data.
     *
     * @param {string} email
     * @param {string} password
     */
    login = async (email: string, password: string, verifyCode?: string) => {
      try {
        const {
          setLoggingIn,
          setIsLoggedIn,
          setLoggedInUser,
          setPermissions,
        }: any = this.props;

        setLoggingIn(true);

        const { data } = await login({
          email,
          password,
          'verify-code': verifyCode,
        });

        if (data?.message == message2FA) {
          return 'enable_2fa';
        } else if (data?.message == invalid2FA) {
          throw new Error('Invalid Auth Code');
        }

        authService.persist({
          accessToken: data.access_token,
          refreshToken: data?.refresh_token,
          expiryTime: data?.expires_in,
        });

        let response = await getAuthUser();

        /**
         * Fetch permissions for a given users
         */
        const permissions: any = await getAuthPermission();
        setLoggingIn(false);
        setIsLoggedIn(true);
        setLoggedInUser(response.data.data);
        setPermissions(permissions?.data?.data);
        setTimeout(() => {
          window.location.href = routes.dashboard;
        }, 0);
      } catch (err) {
        authService.clearStorage();
        setLoggingIn(false);
        throw new Error('Invalid Credentials');
      }
    };

    logout = () => {
      authService.logout();
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          login={this.login}
          logout={this.logout}
        />
      );
    }
  }

  const mapStateToProps = (state: any) => {
    let { isLoggedIn, isLoggingIn, user, permissions } = state.data.auth;

    return {
      isLoggedIn,
      isLoggingIn,
      loggedInUser: user,
      permissions: permissions,
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
      { setLoggingIn, setIsLoggedIn, setLoggedInUser, setPermissions },
      dispatch
    );
  };

  return connect(mapStateToProps, mapDispatchToProps)(Auth);
}

export { withAuthState };
