import http from 'utils/http';
import Resource from './resource';
class FerryBookingResource extends Resource {
  constructor() {
    super('rms/ferry-book');
  }

  cancel(clientBookingNumber: any) {
    return http({
      url: '/' + this.uri,
      method: 'post',
      data: {
        client_booking_number: clientBookingNumber,
      },
    });
  }
}

export { FerryBookingResource as default };
