import MMSResource from 'api/mmsAPI';
import WarehouseResource from 'api/warehouses';
import { MMSUserSchema, WarehouseSchema } from 'constants/schema';
import { useQuery } from 'react-query';

type Params = {
  disableWarehouseFetch?: boolean;
  disableUserFetch?: boolean;
};

export default function useMMSUserAndWarehouseInfo(params?: Params) {
  const { disableWarehouseFetch = false, disableUserFetch = false } =
    params || {};
  const warehouseAPI = new WarehouseResource();
  const mmsUserAPI = new MMSResource();

  // Fetch available warehouses
  const { data: warehouses } = useQuery(
    'warehouses',
    async () => {
      const response = await warehouseAPI.list();
      return response?.data?.data?.data as WarehouseSchema[];
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !disableWarehouseFetch,
    }
  );

  // Fetch users
  const { data: users } = useQuery(
    'users',
    async () => {
      const response = await mmsUserAPI.list();
      return response?.data?.data as MMSUserSchema[];
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !disableUserFetch,
    }
  );

  return { warehouses, users };
}
