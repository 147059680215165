import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import WarehouseResource from 'api/warehouses';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import WarehouseForm from 'components/warehouse/WarehouseForm';
import { strings } from 'config/localization';
import { RackInput } from 'constants/interfaces';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditWarehouse: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const warehouseAPI = new WarehouseResource();

  const {
    isLoading,
    isError,
    data: warehouseData,
  } = useQuery([`warehouse${id}`, id], () =>
    warehouseAPI.get(id).then((res) => res.data?.data)
  );

  const updateWarehouse = useMutation((data: RackInput) =>
    warehouseAPI.update(id, data)
  );

  const handleUpdateWarehouse = (data: RackInput) => {
    updateWarehouse.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: `${strings.warehouse} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('warehouseList');
        history.push(routes.mms.warehouse.list);
      },
    });
  };

  const deleteShelf = useMutation(
    (id: number) => warehouseAPI.deleteShelf(id),
    {
      onSuccess: () => {
        toast({
          title: `${strings.shelf} ${strings.has_been_deleted}`,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const handleDeleteShelf = (id: number) => {
    return new Promise((resolve, reject) => {
      deleteShelf
        .mutateAsync(id)
        .then(() => resolve(true))
        .catch((error) => reject(error));
    });
  };

  const deleteRack = useMutation((id: number) => warehouseAPI.deleteRack(id), {
    onSuccess: () => {
      toast({
        title: `${strings.rack} ${strings.has_been_deleted}`,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const handleDeleteRack = (id: number) => {
    return new Promise((resolve, reject) => {
      deleteRack
        .mutateAsync(id)
        .then(() => resolve(true))
        .catch((error) => reject(error));
    });
  };

  const deleteRoom = useMutation((id: number) => warehouseAPI.deleteRoom(id), {
    onSuccess: () => {
      toast({
        title: `${strings.room} ${strings.has_been_deleted}`,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const handleDeleteRoom = (id: number) => {
    return new Promise((resolve, reject) => {
      deleteRoom
        .mutateAsync(id)
        .then(() => resolve(true))
        .catch((error) => reject(error));
    });
  };

  if (isLoading) {
    return <CenterSpinner />;
  }

  if (isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.warehouse_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.edit_warehouse}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.warehouse.list}>
              {strings.warehouse}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {warehouseData?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md">{strings.edit_warehouse}</Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form>
            <Stack direction="column" spacing="8">
              <WarehouseForm
                editData={warehouseData}
                handleSave={handleUpdateWarehouse}
                deleteShelf={handleDeleteShelf}
                deleteRack={handleDeleteRack}
                deleteRoom={handleDeleteRoom}
                isSubmitting={updateWarehouse.isLoading}
                isDeleting={
                  deleteShelf.isLoading ||
                  deleteRack.isLoading ||
                  deleteRoom.isLoading
                }
              />
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
};

export default EditWarehouse;
