import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import WarehouseResource from 'api/warehouses';
import { wrapperStyles } from 'assets/css/commonStyles';
import WarehouseForm from 'components/warehouse/WarehouseForm';
import { strings } from 'config/localization';
import { RackInput } from 'constants/interfaces';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const AddWarehouse: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const warehouseAPI = new WarehouseResource();

  const addWarehouse = useMutation(
    (data: RackInput) => warehouseAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: `${strings.warehouse} ${strings.has_been_created}`,
          description: res.data.name,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('warehouseList');
        history.push(routes.mms.warehouse.list);
      },
    }
  );

  const handleAddWarehouse = (data: RackInput) => {
    addWarehouse.mutate(data);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.add_warehouse}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.warehouse.list}>
              {strings.warehouse}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.add_warehouse}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.add_warehouse}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form>
            <Stack direction="column" spacing="8">
              <WarehouseForm
                handleSave={handleAddWarehouse}
                isSubmitting={addWarehouse.isLoading}
              />
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
};

export default AddWarehouse;
