import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import RestaurantResource from 'api/restaurant';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import { TOAST_DURATION, TOAST_STATUS } from 'constants/common';
import routes from 'constants/routes';
import { RestaurantSchema } from 'constants/schema';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  restaurantData: RestaurantSchema;
  index: number;
  search: string;
}

const RestaurantListItem: React.FC<Props> = (props) => {
  const { restaurantData, index, search } = props;
  const { id, name, is_top_pick, reservation_count, restaurant_reservation } =
    restaurantData;

  const queryClient = useQueryClient();
  const toast = useToast();
  const restaurantAPI = new RestaurantResource();

  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteRestaurant = useMutation(() => restaurantAPI.destroy(id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('restaurant-list');
      if (res.data.success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.restaurant_delete,
          status: TOAST_STATUS.SUCCESS,
          isClosable: true,
        });
      } else {
        setDeleteMsg(strings.restaurant_error);
      }
    },
    onError: () => {
      toast({
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
        status: TOAST_STATUS.ERROR,
        duration: TOAST_DURATION.LONG,
        isClosable: true,
      });
    },
  });

  const updateRestaurant = useMutation(
    (data: any) => restaurantAPI.update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('restaurant-list');
        toast({
          title: strings.success,
          status: TOAST_STATUS.SUCCESS,
          duration: TOAST_DURATION.LONG,
          isClosable: true,
        });
      },
      onError: (_err, _data, context) => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: TOAST_STATUS.ERROR,
          duration: TOAST_DURATION.LONG,
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteRestaurant.mutate();
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const data = {
      [name]: checked ? 1 : 0,
      _method: 'PATCH',
    };
    updateRestaurant.mutate(data);
  };

  return (
    <Tr key={id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.restaurant.restaurant.details.replace(':id', id.toString()) +
            search
          }>
          <CustomTagTitle type="text" status={true} name={name || '-'} />
        </RouterLink>
      </Td>
      <Td>{reservation_count ?? '-'}</Td>
      <Td>
        <Switch
          name="restaurant_reservation"
          isChecked={!!restaurant_reservation}
          onChange={handleStatusChange}
          colorScheme="primary"
        />
      </Td>
      <Td>
        <Switch
          name="is_top_pick"
          isChecked={!!is_top_pick}
          onChange={handleStatusChange}
          colorScheme="primary"
        />
      </Td>

      <Td>
        <RouterLink
          to={
            routes.restaurant.restaurant.details.replace(':id', id.toString()) +
            search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <RouterLink
          to={
            routes.restaurant.restaurant.edit.replace(':id', id.toString()) +
            search
          }>
          <Tooltip hasArrow label={strings.edit_restaurant}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_restaurant}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteRestaurant.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default RestaurantListItem;
