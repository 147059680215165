import { Box, Flex, useRadio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

function CustomRadio(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const [customCSS, setCustomCSS] = useState<any>({
    color: '',
    width: '',
    height: '',
    px: '',
    borderColor: '',
  });

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  useEffect(() => {
    switch (props?.type) {
      case 'feedback':
        setCustomCSS((prevState: any) => ({
          ...prevState,
          width: '20px',
          height: '20px',
          px: '',
          color: '',
          borderColor: '',
        }));
        break;
      case 'radioNav':
        setCustomCSS((prevState: any) => ({
          ...prevState,
          width: '',
          height: '',
          px: '2',
          color: '',
          borderColor: '',
        }));
        break;
      case 'max':
        setCustomCSS((prevState: any) => ({
          ...prevState,
          width: '',
          height: '',
          px: '2',
          color: 'gray.400',
          borderColor: ' gray.400',
        }));
        break;
      case 'min':
        setCustomCSS((prevState: any) => ({
          ...prevState,
          width: '',
          height: '',
          px: '2',
          color: 'purple.400',
          borderColor: 'purple.400',
        }));
        break;
      case 'out':
        setCustomCSS((prevState: any) => ({
          ...prevState,
          width: '',
          height: '',
          px: '2',
          color: 'red.400',
          borderColor: 'red.400',
        }));
        break;
    }
  }, [props]);

  return (
    <Flex
      as="label"
      fontSize="sm"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      color={customCSS.color}
      borderColor={customCSS.borderColor}>
      <input {...input} />

      <Box
        {...checkbox}
        isDisabled={props.isDisabled}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="full"
        boxShadow="md"
        _checked={{
          bg: 'primary.600',
          color: 'white',
          borderColor: 'primary.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        width={customCSS.width}
        height={customCSS.height}
        px={customCSS.px}
        mx="2">
        {props.children}
      </Box>
    </Flex>
  );
}
export default CustomRadio;
