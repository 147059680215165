import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  RadioGroup,
  Stack,
  useRadioGroup,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomRadio from 'components/common/CustomRadio';
import { strings } from 'config/localization';
import { InventorySearchInput, StockTypeOptions } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import { cleanData } from 'utils';

type LoadingType = 'filter' | 'reset' | '';

export interface InventorySearchProps {
  isLoading: LoadingType;
  handleAdvancedSearch: (
    filters: InventorySearchInput,
    type: LoadingType
  ) => void;
}
const InitialValues = {
  article: '',
  currentStock: '',
};

const InventorySearch: React.FC<InventorySearchProps> = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const url_article = searchParams.get('article');
  const url_currentStock = searchParams.get('currentStock');

  const defaultValues = {
    article: url_article ?? '',
    currentStock: url_currentStock ?? '',
  };

  const { isLoading, handleAdvancedSearch } = props;
  const [formValue, setFormValue] = useState<any>(defaultValues);

  const handleInputChange = (e: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      article: e.target.value,
    }));
  };

  const handleRadioInputChange = (e: any) => {
    setFormValue((prevState: any) => ({
      ...prevState,
      currentStock: e,
    }));
  };

  useEffect(() => {
    const finalData: any = cleanData(formValue);

    handleAdvancedSearch(finalData, 'filter');
  }, [formValue, handleAdvancedSearch]);

  const handleReset = () => {
    setFormValue(InitialValues);
    handleAdvancedSearch(defaultValues, 'reset');
  };
  const options: StockTypeOptions = [
    { label: 'maximum_stock', value: 'max' },
    { label: 'minimum_stock', value: 'min' },
    { label: 'out_of_stock', value: 'out' },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'SchemeTypeRadio',
    defaultValue: 'react',
    onChange: handleRadioInputChange,
  });

  const group = getRootProps();
  return (
    <Stack sx={wrapperStyles}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack direction="column" spacing="4">
          <Stack
            direction={['column', 'column', 'row']}
            spacing="4"
            align={['start', 'start', 'end']}>
            <Grid
              gap={['4', '4', '6']}
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
              ]}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.search}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<BiSearch />}
                      color="gray.400"
                    />
                    <Input
                      type="text"
                      name="article"
                      value={formValue.article}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.max_min_stock}</FormLabel>
                  <RadioGroup
                    name="currentStock"
                    value={formValue.currentStock}
                    onChange={handleRadioInputChange}>
                    <Flex {...group} my="2">
                      {options.map(({ value, label }) => {
                        const radio = getRadioProps({ value });
                        return (
                          <CustomRadio type={value} key={value} {...radio}>
                            {strings[label]}
                          </CustomRadio>
                        );
                      })}
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </GridItem>
            </Grid>
            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                variant="outline"
                onClick={handleReset}
                isLoading={isLoading === 'reset'}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default InventorySearch;
