import Resource from 'api/resource';
import http from 'utils/http';

class KeyLogAPI extends Resource {
  constructor() {
    super('keyscan/keylog');
  }
  getApartmentList() {
    return http({
      url: `/$keyscan/apartment`,
      method: 'get',
    });
  }
  getReservationList() {
    return http({
      url: `/keyscan/reservation`,
      method: 'get',
    });
  }
  getCustomerList() {
    return http({
      url: `/keyscan/customer`,
      method: 'get',
    });
  }
  getUserList() {
    return http({
      url: `/keyscan/user`,
      method: 'get',
    });
  }
}

export { KeyLogAPI as default };
