import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import Feedback from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import { PdfBtn } from 'assets/icons';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import FeedbackAnalysisPDF from 'components/feedback/FeedbackAnalysisPDF';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { changeURL, getStartingSerialNumber } from 'utils';
import {
  DateFormat,
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';
import FeedbackAnalysisItem from './FeedbackAnalysisItem';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  type: string;
  apartment_id: string;
  apartment_name: string;
  start_date: Date | null;
  end_date: Date | null;
}
interface FeedbackItem {
  id: number;
  title: string;
  type: 'rating' | 'closed' | 'open';
  total_count: number;
  result: number;
}

const FeedbackAnalysis: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const feedbackAnalysisAPI = new Feedback();
  const printRef: any = useRef();
  const toast = useToast();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const start_date = Number(searchValues.start_date);
  const end_date = Number(searchValues.end_date);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    type: searchValues.type ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    apartment_name: searchValues.apartment_name ?? '',
    start_date: start_date ? new Date(start_date) : null,
    end_date: end_date ? new Date(end_date) : null,
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      start_date: start,
      end_date: end,
    }));
  };

  const apartmentList = useQuery([`apartmentList`], () =>
    feedbackAnalysisAPI
      .getApartmentList({ limit: 500, page: 1 })
      .then((res) => res.data)
  );

  const fetchFeedbackAnalysis = async (queryParams: any) => {
    const { keyword, type, apartment_id, start_date, end_date } = filterParams;
    if (keyword) queryParams.keyword = keyword;
    if (type) queryParams.type = type;
    if (apartment_id) queryParams.apartment = apartment_id;
    if (start_date)
      queryParams.feedback_at_from = getUTCDateRangeStartDateTime(start_date);
    if (end_date)
      queryParams.feedback_at_to = getUTCDateRangeEndDateTime(end_date);
    return await feedbackAnalysisAPI
      .getFeedbackAnalysis(queryParams)
      .then((res) => {
        return res.data;
      });
  };

  const queryList = useQuery(
    [
      `feedbackAnalysisList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        type: filterParams.type,
        apartment_id: filterParams.apartment_id,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
      },
    ],
    () => {
      const initialQueryParams = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      return fetchFeedbackAnalysis(initialQueryParams);
    }
  );

  const { data: feedbackAnalysisList, isLoading } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleApartmanetChange = (e: any) => {
    const { name, value } = e.target;
    const apartmentName = e.target?.selectedOptions?.[0]?.text;

    const selectApartment = apartmentName ? apartmentName : '';

    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
      apartment_name: selectApartment,
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    const { start_date, end_date } = data;
    if (start_date) data.start_date = Date.parse(start_date);
    if (end_date) data.end_date = Date.parse(end_date);
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleReset = () => {
    setFilterParams({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      type: '',
      apartment_id: '',
      apartment_name: '',
      start_date: null,
      end_date: null,
    });
    history.push(routes.feedback.analysis);
  };

  let pdfFilterParamsDate = '';
  if (filterParams?.start_date || filterParams?.end_date) {
    pdfFilterParamsDate = `_${DateFormat(
      filterParams?.start_date
    )}-${DateFormat(filterParams?.end_date)}`;
  }

  const handleFeedbackAnalysisPrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle:
      '* { background-color: white !important } @page { padding-left: 2px; padding-right: 2px }',
    documentTitle: `${strings.feedback_analysis_detail}${pdfFilterParamsDate}`,
  });

  const {
    data: feedbackQuestionsForPDF,
    isLoading: feedbackQuestionsForPDFLoading,
    mutate: mutateFeedbackQuestionsForPDF,
  } = useMutation(
    () => {
      const initialQueryParams: any = {
        page: INITIAL_CURRENT_PAGE,
        limit: 200,
      };
      return fetchFeedbackAnalysis(initialQueryParams);
    },
    {
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (feedbackQuestionsForPDF?.data) {
      handleFeedbackAnalysisPrint();
    }
    // eslint-disable-next-line
  }, [feedbackQuestionsForPDF?.data]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.feedback} | {strings.analysis}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
            {strings.feedback}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.feedback.analysis}>
            {strings.analysis}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.analysis}
        </Heading>
        <Tooltip label={strings.download_pdf} hasArrow>
          <Button
            size="sm"
            colorScheme="red"
            variant="outline"
            isLoading={feedbackQuestionsForPDFLoading}
            onClick={() => mutateFeedbackQuestionsForPDF()}>
            <Image w="6" src={PdfBtn} alt="PDF" title="PDF" />
          </Button>
        </Tooltip>
      </Flex>
      <div style={{ display: 'none' }}>
        <FeedbackAnalysisPDF
          feedbackAnalysis={feedbackQuestionsForPDF}
          ref={printRef}
          startingSN={startingSN}
          filterParams={filterParams}
        />
      </div>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="column" spacing="4">
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <Grid
                gap={['4', '4', '6']}
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(4, 1fr)',
                ]}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.search}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.400"
                      />
                      <Input
                        type="text"
                        name="keyword"
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.type}</FormLabel>
                    <Select
                      name="type"
                      placeholder={strings.type}
                      value={filterParams.type}
                      onChange={handleInputChange}>
                      <option value="rating">{strings.rating}</option>
                      <option value="open">{strings.open_ended}</option>
                      <option value="closed">{strings.closed_ended}</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.object}</FormLabel>
                    <Select
                      name="apartment_id"
                      value={filterParams.apartment_id}
                      placeholder={strings.select_object}
                      rounded="sm"
                      onChange={handleApartmanetChange}>
                      {apartmentList?.data?.data?.map((apartment: any) => {
                        return (
                          <option key={apartment.id} value={apartment.id}>
                            {apartment.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.date}</FormLabel>
                    <InputGroup>
                      <InputRightElement
                        pointerEvents="none"
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                      <ReactDatePicker
                        placeholderText={strings.select_date}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomDateInput />}
                        onChange={handleDateChange}
                        selected={filterParams.start_date}
                        startDate={filterParams.start_date}
                        endDate={filterParams.end_date}
                        value={getFormattedRangeDate(
                          filterParams.start_date,
                          filterParams.end_date
                        )}
                        selectsRange
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
              </Grid>
            </Stack>
            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                type="button"
                variant="outline"
                onClick={handleReset}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.title}</Th>
                <Th>{strings.type}</Th>
                <Th textAlign="center">{strings.result} %</Th>
                <Th textAlign="center">{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {feedbackAnalysisList?.data?.map(
                (feedbackItem: FeedbackItem, index: number) => {
                  return (
                    <FeedbackAnalysisItem
                      key={feedbackItem.id}
                      index={startingSN + index}
                      data={feedbackItem}
                      apartmentId={filterParams.apartment_id}
                      apartmentName={filterParams.apartment_name}
                      dateFrom={filterParams.start_date}
                      dateTo={filterParams.end_date}
                    />
                  );
                }
              )}
              {isLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default FeedbackAnalysis;
