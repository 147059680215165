import http from 'utils/http';
import Resource from './resource';

class GeneralDocumentResource extends Resource {
  constructor() {
    super('dms/document');
  }

  downloadDocument(filename: string) {
    return http({
      url: '/dms/get-document/' + filename,
      method: 'get',
      responseType: 'blob',
    });
  }
}

export { GeneralDocumentResource as default };
