import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import RMSObjectsResource from 'api/rms-objects';
import RMSVOfficeResource from 'api/rms-v-office';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomSelect from 'components/common/CustomSelect';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ObjektListItems from 'components/rms/ObjektListItems';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  RESPONSIBLE_PERSON_API,
} from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ResponsibleType } from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiHelpCircle, BiInfoCircle, BiSearch } from 'react-icons/bi';
import { useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { changeURL, getStartingSerialNumber } from 'utils';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  type: string;
  status: string;
  responsible_person_id: number | null;
  responsible_person_name: string;
}

const ObjcktList: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isVsyncModalOpen, setVsyncModalOpen] = useState<boolean>(false);
  const [VsyncErrorMsg, setVsyncErrorMsg] = useState<string>('');
  const [syncing, setSyncing] = useState(false);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  let apartmentAPI = new RMSObjectsResource();
  const vOfficeAPI = new RMSVOfficeResource();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    type: searchValues.type ?? '',
    status: searchValues.status ?? '',
    responsible_person_id: searchValues.responsible_person_id ?? null,
    responsible_person_name: searchValues.responsible_person_name ?? '',
  });

  let responiblePerson: ResponsibleType | null =
    filterParams.responsible_person_id && filterParams.responsible_person_name
      ? {
          id: filterParams.responsible_person_id,
          name: filterParams.responsible_person_name,
        }
      : null;

  const apartmentList = useQuery(
    [
      'apartmentList',
      {
        keyword: filterParams.keyword,
        type: filterParams.type,
        currentPage: filterParams.currentPage,
        pageSize: filterParams.pageSize,
        status: filterParams.status,
        responsible_person: filterParams.responsible_person_id,
      },
    ],
    async () => {
      let {
        currentPage,
        pageSize,
        keyword,
        type,
        status,
        responsible_person_id,
      } = filterParams;
      let query: any = {
        page: currentPage,
        limit: pageSize,
      };
      if (keyword) query.keyword = keyword;
      if (type) query.type = type;
      if (status) query.status = status;
      if (responsible_person_id)
        query.responsible_person = responsible_person_id;
      const res = await apartmentAPI.list(query);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const onResponsibilityChange = (e: ResponsibleType) => {
    responiblePerson = e;
    setFilterParams((prevState) => ({
      ...prevState,
      responsible_person_id: e.id,
      responsible_person_name: e.name,
    }));
  };

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  function handleReset() {
    setFilterParams((prev) => ({
      ...prev,
      keyword: '',
      type: '',
      status: '',
      currentPage: INITIAL_CURRENT_PAGE,
      responsible_person_id: null,
      responsible_person_name: '',
    }));
    responiblePerson = null;
    history.push(routes.oms.objects.list);
  }

  const handleVofficeSync = async () => {
    const response = await vOfficeAPI.list();
    setSyncing(false);
    if (response?.data?.success) {
      toast({
        title: strings.data_synced,
        status: 'success',
        isClosable: true,
      });
      setVsyncModalOpen(false);
      queryClient.invalidateQueries('apartmentList');
      queryClient.invalidateQueries('vOfficeSync');
    } else {
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const { data: vOfficeSync, isLoading: isVOfficeSyncLoading } = useQuery(
    ['vOfficeSync'],
    async () => {
      const response = await vOfficeAPI.lastSync();
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const handleDelete = () => {
    setSyncing(true);
    handleVofficeSync();
  };

  const handleVsyncModal = () => {
    setVsyncErrorMsg('');
    setVsyncModalOpen(true);
  };

  const handleCancelVsync = () => {
    setVsyncErrorMsg('');
    setVsyncModalOpen(false);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.oms} | {strings.all_objects}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.oms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.all_objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack>
          <Flex justify="space-between">
            <Heading size="md" textTransform="capitalize">
              {strings.object_list}
            </Heading>
            <AccessControl
              allowedPermissions={[PermissionRequest['sync:voffice']]}
              renderNoAccess={(data: any) => data}>
              <Button
                bg="primary.400"
                colorScheme="primary"
                onClick={handleVsyncModal}
                size="sm">
                {strings.voffice_data_sync}
              </Button>
            </AccessControl>
            <Modal
              isOpen={isVsyncModalOpen}
              isCentered
              onClose={handleCancelVsync}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{strings.confirm}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {VsyncErrorMsg && (
                    <Alert status="error" mb={2}>
                      <AlertIcon />
                      {VsyncErrorMsg}
                    </Alert>
                  )}
                  {strings.confirm} {strings.voffice_data_sync}
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      onClick={handleDelete}
                      isLoading={syncing}>
                      {strings.yes}
                    </Button>
                    <Button
                      variant="outline"
                      onClick={handleCancelVsync}
                      isDisabled={syncing}>
                      {strings.cancel}
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Stack>
        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack direction="column" spacing="4">
              <Grid
                gap={['4', '4', '6']}
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(4, 1fr)',
                ]}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.search}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.400"
                      />
                      <Input
                        type="text"
                        name="keyword"
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.type}</FormLabel>
                    <Select
                      name="type"
                      value={filterParams.type}
                      placeholder={`${strings.select} ${strings.type}`}
                      onChange={handleInputChange}>
                      <option value="HOUSE">{strings.house}</option>
                      <option value="FLAT">{strings.flat}</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.website_active}</FormLabel>
                    <Select
                      name="status"
                      value={filterParams.status}
                      placeholder={`${strings.select} `}
                      onChange={handleInputChange}>
                      <option value="1">{strings.active}</option>
                      <option value="0">{strings.in_active}</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.person_in_charge}</FormLabel>
                    <CustomSelect
                      placeholder={strings.select}
                      value={responiblePerson}
                      onChange={onResponsibilityChange}
                      SEARCH_API={RESPONSIBLE_PERSON_API}
                      limit={30}
                      replaceNameInOption="name"
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack>
          <Text color="gray.300" pt="2">
            <Icon as={BiInfoCircle} color="blue.300" size="3.4em" mr="1" />
            {strings.last_v_office_data_sync}:&nbsp;
            {!isVOfficeSyncLoading && vOfficeSync?.imported_at}
          </Text>
        </Stack>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.type}</Th>
                  <Th>{strings.address}</Th>
                  <Th>{strings.responsibility}</Th>
                  <Th>{strings.payement_active}</Th>
                  <Th>{strings.website_active}</Th>
                  <Th>
                    <Flex direction="row" alignItems="center">
                      {strings.video_active}
                      <Tooltip
                        hasArrow
                        label={
                          strings.can_only_be_enabled_object_with_video_url
                        }
                        size="sm">
                        <IconButton
                          icon={<BiHelpCircle />}
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          aria-label={
                            strings.can_only_be_enabled_object_with_video_url
                          }
                          ml="1"
                        />
                      </Tooltip>
                    </Flex>
                  </Th>
                  <Th>
                    <Flex direction="row" alignItems="center">
                      {strings.three_sixty_active}
                      <Tooltip
                        hasArrow
                        label={strings.can_only_be_enabled_apartment_with_VR}
                        size="sm">
                        <IconButton
                          icon={<BiHelpCircle />}
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          aria-label={
                            strings.can_only_be_enabled_apartment_with_VR
                          }
                          ml="1"
                        />
                      </Tooltip>
                    </Flex>
                  </Th>

                  <Th>
                    <Flex direction="row" align="center">
                      {strings.seven_for_six}
                      <Tooltip
                        hasArrow
                        label={
                          strings.this_discount_will_be_applied_for_season_c
                        }
                        size="sm">
                        <IconButton
                          icon={<BiHelpCircle />}
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          aria-label={
                            strings.this_discount_will_be_applied_for_season_c
                          }
                          ml="1"
                        />
                      </Tooltip>
                    </Flex>
                  </Th>
                  <Th>{strings.sylt_go}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {apartmentList?.data?.data?.map((data: any, index: number) => (
                  <ObjektListItems
                    key={data.id}
                    data={data}
                    startingSN={startingSN + index}
                    search={search}
                  />
                ))}
                {apartmentList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={12} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        <Pagination
          dataList={apartmentList}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </Stack>
    </>
  );
};

export default ObjcktList;
