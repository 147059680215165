import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

export const CenterSpinner: React.FC = () => {
  return (
    <Center
      h="calc(100vh - 128px)"
      size="lg"
      color="primary.500"
      thickness="3px">
      <Spinner />
    </Center>
  );
};
