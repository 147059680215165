import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { WarehouseSearchSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import { cleanData } from 'utils';

export const warehouseInitialSearchValues = {
  currentPage: INITIAL_CURRENT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  keyword: '',
};

interface Props {
  handleAdvancedSearch: (data: WarehouseSearchSchema) => void;
}

const WarehouseSearch = (props: Props) => {
  const { handleAdvancedSearch } = props;

  let firstRender = useRef(true);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const url_keyword = searchParams.get('keyword');

  const defaultValues = {
    keyword: url_keyword ?? '',
  };
  const [formValue, setFormValue] = useState<any>(defaultValues);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const finalData: any = cleanData(formValue);
    handleAdvancedSearch(finalData);
  }, [formValue, handleAdvancedSearch]);

  const handleResetFilter = () => {
    setFormValue(warehouseInitialSearchValues);
    handleAdvancedSearch(warehouseInitialSearchValues);
    history.push(routes.mms.warehouse.list);
  };

  return (
    <Stack sx={wrapperStyles}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          align={['start', 'end']}>
          <FormControl>
            <FormLabel>{strings.search}</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BiSearch />}
                color="gray.400"
              />
              <Input
                type="text"
                name="keyword"
                value={formValue.keyword}
                onChange={handleInputChange}
              />
            </InputGroup>
          </FormControl>
          <Stack direction="row" spacing="4">
            <Button
              colorScheme="primary"
              type="button"
              variant="outline"
              onClick={handleResetFilter}>
              {strings.reset_filter}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default WarehouseSearch;
