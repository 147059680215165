import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Select,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import ArticleTypesResource from 'api/article-types';
import ArticleUnitsResource from 'api/article-units';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import { ArticleSchema } from 'constants/schema';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BiInfoCircle } from 'react-icons/bi';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';

interface Props {
  data?: ArticleSchema;
}

const ArticleForm = (props: Props) => {
  // props
  const { data } = props;

  // Library Init
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext<ArticleSchema>();

  // API Resource
  const articleTypesAPI = new ArticleTypesResource();
  const articleUnitsAPI = new ArticleUnitsResource();

  // Refs
  const maxStockRef = useRef({});
  const minStockRef = useRef({});

  // Query to fetch article types
  const articleTypeQuery = useQuery(
    [`artileTypeList`],
    () => articleTypesAPI.list().then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  // Query to fetch article units
  const articleUnitQuery = useQuery(
    [`artileUnitList`],
    () => articleUnitsAPI.list().then((res) => res.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  // Watch values
  minStockRef.current = Number(watch('min_stock_limit', null));
  maxStockRef.current = Number(watch('max_stock_limit', null));

  if (articleTypeQuery.isLoading || articleUnitQuery.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <form>
      <Stack direction="row" spacing="4" overflow="auto">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>{strings.article_name}</FormLabel>
              <Input
                type="text"
                placeholder={strings.article_name}
                {...register('name', {
                  required: strings.required_article_name,
                })}
                defaultValue={data?.name}
              />
              <FormErrorMessage>
                {errors.name && errors.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.article_category_id} isRequired>
              <FormLabel>{strings.type}</FormLabel>
              <Select
                placeholder={`${strings.select} ${strings.type}`}
                rounded="sm"
                {...register('article_category_id', {
                  required: strings.required_type,
                })}
                defaultValue={data?.article_category_id ?? undefined}>
                {articleTypeQuery?.data?.data?.map((articleType: any) => {
                  return (
                    <option key={articleType.id} value={articleType.id}>
                      {articleType.name}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors.article_category_id &&
                  errors.article_category_id?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.article_unit_type_id} isRequired>
              <FormLabel>{strings.unit}</FormLabel>
              <Select
                placeholder={`${strings.select} ${strings.unit}`}
                rounded="sm"
                {...register('article_unit_type_id', {
                  required: strings.required_unit,
                })}
                defaultValue={data?.article_unit_type_id ?? undefined}>
                {articleUnitQuery?.data?.data?.map((articleUnit: any) => {
                  return (
                    <option key={articleUnit.id} value={articleUnit.id}>
                      {articleUnit.name}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors.article_unit_type_id &&
                  errors.article_unit_type_id?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.min_stock_limit}>
              <FormLabel>
                {strings.minimum_stock_notification}
                <Tooltip
                  hasArrow
                  label={strings.min_stock_notification_tooltip}
                  fontSize="sm"
                  placement="top">
                  <span>
                    <Icon
                      as={BiInfoCircle}
                      ml={2}
                      _hover={{
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                </Tooltip>
              </FormLabel>
              <Input
                type="number"
                placeholder={strings.minimum_stock_notification}
                {...register('min_stock_limit', {
                  validate: (value) => {
                    // value is not required
                    if (!value) return true;
                    // value should be equal or greater than 1
                    if (value < 1) return strings.valid_data;
                    // if there is value but no value for max stock, validate true
                    if (value && !maxStockRef.current) return true;
                    return (
                      value < maxStockRef.current ||
                      strings.min_stock_vaidation_msg
                    );
                  },
                })}
                defaultValue={data?.min_stock_limit ?? undefined}
              />
              <FormErrorMessage>
                {errors.min_stock_limit && errors.min_stock_limit?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.max_stock_limit}>
              <FormLabel>{strings.maximum_stock}</FormLabel>
              <Input
                type="number"
                placeholder={strings.maximum_stock}
                {...register('max_stock_limit', {
                  validate: (value) => {
                    // if there is no value entered, validate true
                    if (!value) return true;
                    // value should be greater than 1
                    if (value < 1) return strings.valid_data;
                    if (!minStockRef.current) return true;
                    return (
                      value > minStockRef.current ||
                      strings.max_stock_vaidation_msg
                    );
                  },
                  required: false,
                })}
                defaultValue={data?.max_stock_limit ?? undefined}
              />
              <FormErrorMessage>
                {errors.max_stock_limit && errors.max_stock_limit?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.purchase_price}>
              <FormLabel>{strings.buying_price} (&euro;)</FormLabel>
              <Controller
                control={control}
                name="purchase_price"
                defaultValue={data?.purchase_price}
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <NumberFormat
                    placeholder={strings.enter_value_in_euro}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="€"
                    customInput={Input}
                    allowNegative={false}
                    onBlur={onBlur}
                    value={value}
                    onValueChange={(values) => {
                      onChange(values.floatValue);
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.purchase_price && errors.purchase_price?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem rowSpan={2} colSpan={1}>
            <FormControl isInvalid={!!errors.selling_price}>
              <FormLabel>{strings.selling_price} (&euro;)</FormLabel>
              <Controller
                control={control}
                name="selling_price"
                defaultValue={data?.selling_price}
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <NumberFormat
                    placeholder={strings.enter_value_in_euro}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="€"
                    customInput={Input}
                    allowNegative={false}
                    onBlur={onBlur}
                    value={value}
                    onValueChange={(values) => {
                      onChange(values.floatValue);
                    }}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.selling_price && errors.selling_price?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt="4">
              <FormLabel>{strings.notes}</FormLabel>
              <Textarea
                placeholder={strings.write_description}
                {...register('description')}
                defaultValue={data?.description}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>{strings.upload_article_picture}</FormLabel>
              <ImageUploader uploadedFiles={data?.file_path} accept="image/*" />
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default ArticleForm;
