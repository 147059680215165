import LocalizedStrings from 'react-localization';
import Deutsch from '../lang/de';
import english from '../lang/en';
import polish from '../lang/pl';

export const strings = new LocalizedStrings({
  de: Deutsch,
  en: english,
  pl: polish,
});
