import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import BookingTerminalResource from 'api/booking_terminal';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CheckInOut } from 'assets/icons';
import CheckInOutKeyStatus from 'components/bms_onboarding/CheckInOutKeyStatus';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import {
  BOOKING_DETAILS,
  MIRROR_SECOND_SCREEN,
  SECOND_SCREEN_CHANNEL,
} from 'constants/common';
import routes from 'constants/routes';
import { CheckInOutDetailsSchema, DataWrapperSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';

const CheckInOutDetails: React.FC = () => {
  const BookingTerminalAPI = new BookingTerminalResource();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const { search } = useLocation();
  const broadCastAPI = useRef(new BroadcastChannel(SECOND_SCREEN_CHANNEL));

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data } = useQuery([`checkInOutDetails${id}`], async () => {
    const res = await BookingTerminalAPI.get(id);
    return res.data as DataWrapperSchema<CheckInOutDetailsSchema>;
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data) {
      broadCastAPI.current.postMessage({
        name: BOOKING_DETAILS,
        value: data.data,
      });
    }
  }, [data]);

  const checkInMutation = useMutation(
    () => BookingTerminalAPI.updateCheckIn(id),
    {
      onSuccess: () => {
        toast({
          title: `${strings.check_in} ${strings.successful}.`,
          status: 'success',
          isClosable: true,
        });
        setIsModalOpen(false);
        queryClient.invalidateQueries(`checkInOutDetails${id}`);
        broadCastAPI.current.postMessage({
          name: MIRROR_SECOND_SCREEN,
          value: false,
        });
      },
      onError: () => {
        toast({
          title: `${strings.check_in} ${strings.failed}.`,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const checkOutMutation = useMutation(
    () => BookingTerminalAPI.updateCheckOut(id),
    {
      onSuccess: () => {
        toast({
          title: `${strings.check_out} ${strings.successful}.`,
          status: 'success',
          isClosable: true,
        });
        setIsModalOpen(false);
        queryClient.invalidateQueries(`checkInOutDetails${id}`);
      },
      onError: () => {
        toast({
          title: `${strings.check_out} ${strings.failed}.`,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  if (!data) {
    return <CenterSpinner />;
  }

  const { data: checkInOutDetail } = data;

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCheckInConfirm = () => {
    checkInMutation.mutate();
  };

  const handleCheckOutConfirm = () => {
    checkOutMutation.mutate();
  };

  const {
    booking: bookingDetails,
    booking_terminals,
    booking_number,
    customer_id,
    reservation_id,
  } = checkInOutDetail;
  const customerDetails = checkInOutDetail?.customer ?? {};
  const CustomerAddress = customerDetails?.mainAddress ?? {};
  const customerFullName = checkInOutDetail?.customer
    ? `${customerDetails.forename} ${customerDetails?.surname}`
    : '-';

  const displayCheckInButton = !booking_terminals?.check_in_at;
  const displayCheckOutButton =
    Boolean(booking_terminals?.check_in_at) && !booking_terminals?.check_out_at;

  return (
    <Stack justifyContent="space-between">
      <Helmet>
        <title>
          {strings.bms} | {booking_number}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.bms.reservation.list}>
              {strings.bms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.bms.checkInOut.list + search}>
              {strings.check_in_out}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {booking_number}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Stack direction="column" spacing="4">
          <Heading size="md" textTransform="capitalize">
            {strings.booking_detail}
          </Heading>

          <Stack sx={wrapperStyles}>
            <Grid
              gap="2"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.bookings}:</FormLabel>
                  <Box sx={infoStyles}>{booking_number}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.booking_period}:
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {DateFormat(bookingDetails.fromdate)}&nbsp;-&nbsp;
                    {DateFormat(bookingDetails.tilldate)}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.units}:</FormLabel>
                  <Box sx={infoStyles}>
                    {checkInOutDetail.apartment.fullname}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.adult}:</FormLabel>
                  <Box sx={infoStyles}>{bookingDetails.adults}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.children}:</FormLabel>
                  <Box sx={infoStyles}>{bookingDetails.children}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.babies}:</FormLabel>
                  <Box sx={infoStyles}>{bookingDetails.babys}</Box>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </Stack>
        <Stack direction="column" spacing="4">
          <Heading as="h3" size="md" textTransform="capitalize">
            {strings.customer_detail}
          </Heading>
          <Stack sx={wrapperStyles}>
            <Grid
              gap="2"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.first_name}:</FormLabel>
                  <Box sx={infoStyles}>{customerDetails.forename ?? '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.last_name}:</FormLabel>
                  <Box sx={infoStyles}>{customerDetails.surname ?? '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.email}:</FormLabel>
                  <Box sx={infoStyles}>{customerDetails.email ?? '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.mobile}:</FormLabel>
                  <Box sx={infoStyles}>{customerDetails.mobile ?? '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.country}:</FormLabel>
                  <Box sx={infoStyles}>{CustomerAddress.country ?? '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.street}:</FormLabel>
                  <Box sx={infoStyles}>{CustomerAddress.street ?? '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.post_code}:</FormLabel>
                  <Box sx={infoStyles}>{CustomerAddress.postalcode ?? '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.city}:</FormLabel>
                  <Box sx={infoStyles}>{CustomerAddress.city ?? '-'}</Box>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </Stack>
        <CheckInOutKeyStatus
          customerName={customerFullName}
          bookingNumber={booking_number}
          customerId={customer_id}
          reservation_id={reservation_id}
          apartment_id={checkInOutDetail.apartment.id}
        />
      </Stack>

      {displayCheckInButton && (
        <Flex alignSelf="flex-end">
          <Button colorScheme="primary" onClick={handleModalOpen}>
            <Image src={CheckInOut} w={6} mr={1} />
            {strings.check_in}
          </Button>
          <Modal isOpen={isModalOpen} isCentered onClose={handleModalClose}>
            <ModalOverlay />
            <ModalContent textAlign="center">
              <ModalHeader>
                {strings.guest} {strings.check_in}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack spacing={3}>
                  <Box fontSize="xl">
                    {strings.formatString(
                      strings.check_in_confirmation_question,
                      <Text fontWeight={600} color="heading" as="span">
                        {customerFullName}
                      </Text>
                    )}
                  </Box>
                  <Box>{strings.check_in_confirmation_message}</Box>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup w="100%">
                  <Button
                    w="50%"
                    colorScheme="primary"
                    isLoading={checkInMutation.isLoading}
                    onClick={handleCheckInConfirm}>
                    <Image src={CheckInOut} w={6} mr={1} />
                    {strings.check_in}
                  </Button>
                  <Button w="50%" variant="outline" onClick={handleModalClose}>
                    {strings.check_booking_details}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      )}
      {displayCheckOutButton && (
        <Flex alignSelf="flex-end">
          <Button colorScheme="primary" onClick={handleModalOpen}>
            <Image src={CheckInOut} w={6} mr={1} />
            {strings.check_out}
          </Button>
          <Modal isOpen={isModalOpen} isCentered onClose={handleModalClose}>
            <ModalOverlay />
            <ModalContent textAlign="center">
              <ModalHeader>
                {strings.guest} {strings.check_out}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack spacing={3}>
                  <Box fontSize="xl">
                    {strings.formatString(
                      strings.check_out_confirmation_question,
                      <Text fontWeight={600} color="heading" as="span">
                        {customerFullName}
                      </Text>
                    )}
                  </Box>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup w="100%">
                  <Button
                    w="50%"
                    colorScheme="primary"
                    isLoading={checkOutMutation.isLoading}
                    onClick={handleCheckOutConfirm}>
                    <Image src={CheckInOut} w={6} mr={1} />
                    {strings.check_out}
                  </Button>
                  <Button w="50%" variant="outline" onClick={handleModalClose}>
                    {strings.check_booking_details}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      )}
    </Stack>
  );
};

export default CheckInOutDetails;
