import Resource from 'api/resource';
import http from 'utils/http';
class WarehouseResource extends Resource {
  constructor() {
    super('mms/warehouses');
  }

  deleteShelf(id: number) {
    return http({
      url: `/mms/warehouse-shelves/${id}`,
      method: 'delete',
    });
  }

  deleteRack(id: number) {
    return http({
      url: `/mms/warehouse-racks/${id}`,
      method: 'delete',
    });
  }

  deleteRoom(id: number) {
    return http({
      url: `/mms/warehouse-rooms/${id}`,
      method: 'delete',
    });
  }

  fetchRooms(warehouseId: number | string) {
    return http({
      url: `/mms/warehouses/${warehouseId}/rooms`,
      method: 'get',
    });
  }

  fetchRacks(roomId: number | string) {
    return http({
      url: `/mms/warehouse-rooms/${roomId}/racks`,
      method: 'get',
    });
  }

  fetchShelves(rackId: number | string) {
    return http({
      url: `/mms/warehouse-racks/${rackId}/shelves`,
      method: 'get',
    });
  }
}

export { WarehouseResource as default };
