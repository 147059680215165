import {
  Button,
  Image,
  Link,
  Select,
  Stack,
  Stat,
  StatHelpText,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  messageInfoSentStyles,
  messageReceiveStyled,
  messageSentDateTimeStyled,
  messageSentStyles,
} from 'components/chat/ChatStyle';
import { strings } from 'config/localization';
import React from 'react';
import { BiFileBlank } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { TimeFormatHMA, TimeFormatHMS } from 'utils/DateFormat';

interface Props {
  message: any;
}

interface Message {
  message: string;
  message_type:
    | 'file'
    | 'image'
    | 'carousel'
    | 'choice'
    | 'text'
    | 'dropdown'
    | 'infoMessage';
  additional_parameters:
    | ChatMessageTypeFile
    | ChatMessageTypeImage
    | ChatMessageTypeCard
    | ChatMessageChoice
    | ChatMessageText;
}

interface Card {
  type: string;
  title: string;
  subtitle: string;
  imageUrl: string;
  actions: { label: string; value: string; action: string }[];
}

interface ChatMessageTypeFile {
  fileUrl: string;
  title: string;
}

interface ChatMessageTypeImage {
  imageUrl: string;
}

interface ChatMessageTypeCard {
  items: Card[];
}

interface ChatMessageChoice {
  text: string;
  options: {
    label: string;
    value: string;
  }[];
}

interface ChatMessageText {
  text: string;
}

const transformMessage = (msg: string) => {
  // Regular expression to detect URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  // Replace URLs with anchor tags
  let transformed = msg.replace(
    urlRegex,
    '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>'
  );
  // Replace newline characters with <br>
  transformed = transformed.replace(/\n/g, '<br>');
  return transformed;
};

const renderImage = (chatMessage: ChatMessageTypeImage) => (
  <Image src={chatMessage.imageUrl} height="200" />
);

const renderChoice = (chatMessage: ChatMessageChoice) => (
  <>
    {chatMessage.text}
    <Wrap spacing={1}>
      {chatMessage?.options.map((choice) => (
        <WrapItem key={choice.label}>
          <Button size="sm" variant="solid">
            {choice.label}
          </Button>
        </WrapItem>
      ))}
    </Wrap>
  </>
);

const renderCarousel = (chatMessage: ChatMessageTypeCard) =>
  chatMessage.items.map((item) => renderCard(item));

const renderCard = (chatMessage: Card) => (
  <>
    <Image src={chatMessage.imageUrl} height="200" />
    <Text>{chatMessage.title}</Text>
    <Text>{chatMessage.subtitle}</Text>

    <Wrap spacing={1}>
      {chatMessage.actions?.map((action: any) => (
        <WrapItem key={action.label}>
          <Button key={action.label}>
            <a href={action.value} target="_blank" rel="noopener noreferrer">
              {action.label}
            </a>
          </Button>
        </WrapItem>
      ))}
    </Wrap>
  </>
);

const renderDropdown = (chatMessage: ChatMessageChoice) => (
  <>
    <Text>{chatMessage.text}</Text>

    <Select placeholder="Select option">
      {chatMessage?.options?.map((choice) => (
        <option value={choice.label} key={choice.label}>
          {choice.label}
        </option>
      ))}
    </Select>
  </>
);

const renderFile = (chatMessage: ChatMessageTypeFile) => (
  <Link href={chatMessage.fileUrl} isExternal>
    <Button leftIcon={<BiFileBlank />} variant="solid" rounded="full">
      {chatMessage.title}
    </Button>
  </Link>
);

const renderText = (text: string) => {
  return (
    <Text>
      <div dangerouslySetInnerHTML={{ __html: transformMessage(text) }} />
    </Text>
  );
};

const renderInfoMessage = (text: string) => {
  return <Text>{text}</Text>;
};

const RenderChatComponent = ({
  message_type,
  additional_parameters,
  message,
}: Message) => {
  switch (message_type) {
    case 'image':
      return renderImage(additional_parameters as ChatMessageTypeImage);
    case 'carousel':
      return renderCarousel(additional_parameters as ChatMessageTypeCard);
    case 'choice':
      return renderChoice(additional_parameters as ChatMessageChoice);
    case 'dropdown':
      return renderDropdown(additional_parameters as ChatMessageChoice);
    case 'file':
      return renderFile(additional_parameters as ChatMessageTypeFile);
    case 'infoMessage':
      return renderInfoMessage(
        message ?? (additional_parameters as ChatMessageText)?.text ?? ''
      );
    default:
      return renderText(
        message ?? (additional_parameters as ChatMessageText)?.text ?? ''
      );
  }
};

const ChatMessageDetails: React.FC<Props> = (props) => {
  const { message } = props;

  const getMessage = () => {
    if (message.type === 'message') {
      if (message.sender === 'user') {
        return (
          <>
            <Stack sx={messageReceiveStyled}>
              <Text wordBreak="break-all">{message.message}</Text>
            </Stack>
            <Stack>
              <Text color="gray.300" fontSize="xs">
                {TimeFormatHMA(message.time)}
              </Text>
            </Stack>
          </>
        );
      } else {
        if (message.message_type === 'listInfoEvent') {
          return null;
        }
        return (
          <>
            <Stack
              sx={
                message.message_type === 'infoMessage'
                  ? messageInfoSentStyles
                  : messageSentStyles
              }>
              {RenderChatComponent(message)}
            </Stack>
            <Stack sx={messageSentDateTimeStyled}>
              <Text color="gray.300" fontSize="xs">
                {TimeFormatHMA(message.time)}
                {message.integration_type === 'botpress' ? ' - BOT' : ''}
              </Text>
            </Stack>
          </>
        );
      }
    } else if (message.type === 'call') {
      return (
        <Stat style={{ textAlign: 'center' }}>
          <StatHelpText color="gray.300" fontSize="xs">
            {TimeFormatHMA(message.end_time)}
          </StatHelpText>
          <StatHelpText>
            <b>{strings.call_ended}</b>
            <Text color="gray.300" fontSize="xs">
              {TimeFormatHMS(message.call_duration)}
            </Text>
          </StatHelpText>
        </Stat>
      );
    }
  };

  return <>{getMessage()}</>;
};

export default ChatMessageDetails;
