import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';

const defaultValues = {
  article: '',
};

type MyInventorySearchInput = {
  article: string;
};

export interface InventorySearchProps {
  handleAdvancedSearch: (filters: MyInventorySearchInput) => void;
}

const MyInventorySearch: React.FC<InventorySearchProps> = (props) => {
  const { handleAdvancedSearch } = props;
  const [formValue, setFormValue] = useState<any>(defaultValues);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleAdvancedSearch(formValue);
  }, [formValue, handleAdvancedSearch]);

  const handleReset = () => {
    setFormValue(defaultValues);
    handleAdvancedSearch(defaultValues);
  };

  return (
    <Stack sx={wrapperStyles}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          align={['start', 'end']}>
          <FormControl>
            <FormLabel>{strings.search}</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<BiSearch />}
                color="gray.400"
              />
              <Input
                type="text"
                name="article"
                value={formValue.article}
                onChange={handleInputChange}
              />
            </InputGroup>
          </FormControl>
          <Stack direction="row" spacing="4">
            <Button
              colorScheme="primary"
              variant="outline"
              onClick={handleReset}>
              {strings.reset_filter}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default MyInventorySearch;
