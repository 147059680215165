import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { UserSchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { maskPhone } from 'utils';
import { validEmail } from 'utils/validate';

interface Props {
  userData?: UserSchema;
}

const ProfileForm: React.FC<Props> = (props) => {
  const { userData } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<UserSchema>();

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.first_name} isRequired>
              <FormLabel>{strings.first_name}</FormLabel>
              <Input
                type="text"
                defaultValue={userData?.first_name}
                placeholder={strings.first_name}
                {...register('first_name', {
                  required: strings.required,
                })}
              />
              <FormErrorMessage>
                {errors?.first_name && errors?.first_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.last_name} isRequired>
              <FormLabel>{strings.last_name}</FormLabel>
              <Input
                defaultValue={userData?.last_name}
                type="text"
                placeholder={strings.last_name}
                {...register('last_name', { required: strings.required })}
              />
              <FormErrorMessage>
                {errors?.last_name && errors?.last_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.email} isRequired>
              <FormLabel>{strings.email_address}</FormLabel>
              <Input
                defaultValue={userData?.email}
                type="email"
                placeholder={strings.email_placeholder}
                {...register('email', {
                  required: strings.required,
                  validate: (value) =>
                    validEmail(value) || strings.valid_email_address,
                })}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.contact_number} isRequired>
              <FormLabel>{strings.cellphone}</FormLabel>
              <Input
                type="text"
                {...register('contact_number', {
                  required: strings.required,
                  validate: (value) => {
                    if (!value) return true;
                    return !(value.length < 15) || strings.invalid_cellphone;
                  },
                })}
                defaultValue={
                  userData?.contact_number
                    ? maskPhone(userData.contact_number)
                    : ''
                }
                placeholder={strings.cellphone}
                onChange={(e) =>
                  (e.target.value = `${maskPhone(e.target.value)}`)
                }
              />
              <FormErrorMessage>
                {errors?.contact_number && errors?.contact_number?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.address} isRequired>
              <FormLabel>{strings.address}</FormLabel>
              <Input
                defaultValue={userData?.address}
                type="text"
                placeholder={strings.address}
                {...register('address', {
                  required: strings.required,
                })}
              />
              <FormErrorMessage>
                {errors?.role && errors?.role?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default ProfileForm;
