import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CallbacksResource from 'api/callbacks';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import CallbackListItem from 'pages/dashboard/bms/Callback/CallbackListItem';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';

interface FilterParams {
  currentPage: number;
  pageSize: number;
}

const CallbackList: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
  });

  const CallbackAPI = new CallbacksResource();

  const callbackList = useQuery(
    [
      'callback-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
    ],
    () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      return CallbackAPI.list(queryParams).then((res) => {
        return res.data;
      });
    }
  );

  useEffect(() => {
    const data = { ...filterParams };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.bms} | {strings.all_callbacks}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.bms}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.bms.callback.list}>
            {strings.all_callbacks}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box>
        <Heading size="md" textTransform="capitalize">
          {strings.callbacks}
        </Heading>
      </Box>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.customer}</Th>
                <Th>{strings.email}</Th>
                <Th>{strings.phone_number}</Th>
                <Th>{strings.callback_date}</Th>
                <Th>{`${strings.time} (${strings.from} - ${strings.to})`}</Th>
                <Th>{strings.actions}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {callbackList?.data?.data?.map((data: any, index: number) => (
                <CallbackListItem
                  key={index}
                  data={data}
                  index={startingSN + index}
                  search={search}
                />
              ))}
              {callbackList.isLoading && (
                <TableSkeletonLoader rows={10} cols={7} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Pagination
        dataList={callbackList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default CallbackList;
