import {
  Alert,
  AlertIcon,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import SMSServiceResource from 'api/sms_services';
import {
  infoStyles,
  labelStyles,
  reactSelectStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import useWordSearch from 'hooks/useWordSearch';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { formatPrice } from 'utils';
import BreadBookingOrderFormAdd from './BreadBookingOrderFormAdd';
import BreadBookingOrderFormEdit from './BreadBookingOrderFormEdit';
interface Props {
  breadBookingData?: any;
  setFormData?: any;
  bookingID?: string;
  error?: any;
  setError?: any;
}

const RESERVATION_SEARCH_API = `/rms/reservations`;

const BreadBookingForm: React.FC<Props> = (props) => {
  const { breadBookingData, setFormData, setError, error } = props;
  let bookingID = breadBookingData?.id;

  const [selectedBooking, setSelectedBooking] = useState<any>(null);
  const [booking, setBooking] = useState<any>(null);
  const [formInfo, setFormInfo] = useState<any>([]);
  const [includeDepartureDay, setIncludeDepartureDay] = useState(true);
  const [serviceLines, setServiceLines] = useState<any[]>([]);
  const [breadPrices, setBreadPrices] = useState<any>(null);

  const servicesAPI = new SMSServiceResource();

  const [subTotal, setSubTotal] = useState(0);
  const [VAT19, setVAT19] = useState(0);
  const [VAT7, setVAT7] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalBookingFee, setTotalBookingFee] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string[]>([
    strings.cannot_change_quantity_in_past,
  ]);

  const {
    formState: { errors },
  } = useFormContext<any>();

  const {
    loading: reservationListLoading,
    result: reservationQuery,
    setQuery,
  } = useWordSearch(
    `${RESERVATION_SEARCH_API}/?status=confirmed&page=1&limit=30`
  );
  const reservationList = reservationQuery?.data;
  let reservationOptions = reservationList?.map((list: any) => ({
    value: list.booking_number,
    label: list.booking_number,
  }));

  const servicesInfos = useQuery(
    ['services'],
    async () => {
      let query = {
        page: 1,
        limit: 300,
        price_scheme_type: 'PriceSchemeByQuantity',
      };
      let result = await servicesAPI.list(query);
      let da = result.data.data.map((item: any, index: number) => ({
        id: item.id,
        data: [],
      }));
      setFormInfo(da);
      return result?.data?.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  let servicesInfo = servicesInfos?.data?.filter(
    (item: any) => item?.service_group?.name === 'Brötchen-Service'
  );

  const errorMessageHandler = (errorObject: any) => {
    let errors: string[] = [];
    for (let error in errorObject) {
      for (let e of errorObject[error]) {
        errors.push(e);
      }
    }
    setErrorMessage(errors);
    setError('error');
  };

  let quotePrice = useMutation((data: any) => servicesAPI.quotePrice(data), {
    onSuccess: () => {
      setErrorMessage([]);
    },
    onError: (error: any, response: any) => {
      if (error?.response?.data?.errors) {
        errorMessageHandler(error?.response?.data?.errors);
      } else {
        setErrorMessage([strings.invalid_data]);
      }
    },
  });

  useEffect(() => {
    if (serviceLines.length > 0) {
      quotePrice.mutate(
        { service_lines: serviceLines },
        {
          onSuccess: (res: any) => {
            setBreadPrices(res.data.data);
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceLines]);

  useEffect(() => {
    if (!breadPrices) return;

    let taxableSum = breadPrices.reduce(
      (acc: number, cur: any) => acc + cur.total_price,
      0
    );
    setTotalSum(taxableSum);

    let totalBookingFee = breadPrices.reduce(
      (acc: number, cur: any) => acc + cur.booking_fee,
      0
    );
    setTotalBookingFee(totalBookingFee);

    let amountWithoutTax = taxableSum / 1.26;
    amountWithoutTax = ~~amountWithoutTax; //round to nearest integer
    let vat19 = ~~(amountWithoutTax * 0.19);
    setVAT19(vat19);

    let vat7 = ~~(amountWithoutTax * 0.07);
    setVAT7(vat7);

    let finalSubTotal = taxableSum - vat19 - vat7;
    setSubTotal(finalSubTotal);

    let grand_total = taxableSum + totalBookingFee;
    setGrandTotal(grand_total);
  }, [breadPrices]);

  useEffect(() => {
    if (!selectedBooking) return;
    if (formInfo.length <= 0) return;
    let breads = formInfo
      .map((item: any) => {
        return item.data;
      })
      .flat();
    let BREADS = breads.flat();

    let service_lines: any = [];
    BREADS.forEach((item: any) => {
      if (item.quantity > 0) {
        service_lines.push({
          service_id: item.serviceId,
          price_scheme_type: 'PriceSchemeByQuantity',
          quantity: item.quantity,
          from_date: moment(item.startDate).format('yyyy-MM-DD'),
          till_date: moment(item.endDate).format('yyyy-MM-DD'),
        });
      }
    });
    setServiceLines(service_lines);
    let formdata = {
      booking_number: selectedBooking?.booking_number,
      service_lines,
      payments: {
        payment_method: 'INVOICE',
        amount: grandTotal,
      },
      customer: selectedBooking.customer,
    };
    setFormData(formdata);
  }, [formInfo, selectedBooking, setFormData, grandTotal]);
  useEffect(() => {
    if (!breadBookingData?.reservation?.booking_number) return;
    setSelectedBooking(breadBookingData?.reservation);

    setBooking(breadBookingData?.bookings);
  }, [breadBookingData, setQuery]);

  function onBookingChange(bookingId: any) {
    let bookingData = reservationList?.find(
      (booking: any) => booking?.booking_number == bookingId
    );
    setSelectedBooking(bookingData);
  }
  const changeExtraService = useMemo(
    () => (val: any, serviceId: string) => {
      setFormInfo((prev: any) => {
        let info = [...prev];
        let index = info.findIndex((item: any) => item.id === serviceId);
        info[index] = {
          ...info[index],
          data: val,
        };
        return info;
      });
    },
    []
  );
  const handleBookingSearch = (value: string) => {
    if (!value) return;
    setQuery({ keyword: value });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1"
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors?.booking_number} isRequired>
              <FormLabel>{strings.booking_number}</FormLabel>
              <ReactSelect
                placeholder={strings.select}
                menuPortalTarget={document.body}
                styles={reactSelectStyles}
                options={reservationOptions}
                onInputChange={handleBookingSearch}
                isLoading={reservationListLoading}
                value={{
                  label: selectedBooking?.booking_number,
                  value: selectedBooking?.booking_number,
                }}
                onChange={(e: any) => {
                  onBookingChange(e.value);
                }}
                allowClear
                isSearchable
              />
              <FormErrorMessage>
                {errors?.booking_number && errors?.booking_number?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        {selectedBooking && (
          <Grid
            gap="4"
            templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
            flex="1"
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.apartmant}</FormLabel>
                <Box sx={infoStyles}>
                  {selectedBooking?.apartment?.apartment_name}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.customer}</FormLabel>
                <Box
                  sx={
                    infoStyles
                  }>{`${selectedBooking?.customer.forename} ${selectedBooking?.customer.surname}`}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{strings.booking_period}</FormLabel>
                <Box sx={infoStyles}>{selectedBooking?.booking_period}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={!!errors?.include_departure_day}>
                <FormLabel>{strings.include_departure_day}</FormLabel>
                <Checkbox
                  defaultIsChecked
                  checked={includeDepartureDay}
                  onChange={(e) => setIncludeDepartureDay(e.target.checked)}>
                  {strings.include_departure_day}
                </Checkbox>
                <FormErrorMessage>
                  {errors?.include_departure_day &&
                    errors?.include_departure_day?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        )}
      </Stack>
      {error &&
        errorMessage.map((errors: string, index: number) => {
          return (
            <Alert status="error" key={index}>
              <AlertIcon />
              {errors}
            </Alert>
          );
        })}
      <Divider />
      {selectedBooking && (
        <Stack>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.services}</Th>
                  <Th>{strings.booking_date}</Th>
                  <Th>{strings.quantity}</Th>
                  <Th isNumeric>{strings.unit_price} (&euro;)</Th>
                  <Th isNumeric>{strings.price} (&euro;)</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {servicesInfo?.map((service: any) =>
                  bookingID ? (
                    <BreadBookingOrderFormEdit
                      key={service.id}
                      selectedBooking={selectedBooking}
                      service={service}
                      booking={booking}
                      setExtraServices={changeExtraService}
                      includeDepartureDay={includeDepartureDay}
                      setError={setError}
                    />
                  ) : (
                    <BreadBookingOrderFormAdd
                      key={service.id}
                      selectedBooking={selectedBooking}
                      service={service}
                      setExtraServices={changeExtraService}
                      includeDepartureDay={includeDepartureDay}
                      setError={setError}
                    />
                  )
                )}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th colSpan={2} textAlign="right" borderColor="transparent">
                    {strings.subtotal}
                  </Th>
                  <Th
                    isNumeric
                    colSpan={3}
                    textAlign="right"
                    borderColor="transparent">
                    {formatPrice(totalSum)} &euro;
                  </Th>
                  <Td borderColor="transparent"></Td>
                </Tr>
                <Tr>
                  <Th colSpan={2} textAlign="right" borderColor="transparent">
                    {strings.without_tax} {strings.subtotal}
                  </Th>
                  <Th
                    isNumeric
                    colSpan={3}
                    textAlign="right"
                    borderColor="transparent">
                    {formatPrice(subTotal)} &euro;
                  </Th>
                  <Td borderColor="transparent"></Td>
                </Tr>
                <Tr>
                  <Th colSpan={2} textAlign="right" borderColor="transparent">
                    {strings.vat19}
                  </Th>
                  <Th
                    isNumeric
                    colSpan={3}
                    textAlign="right"
                    borderColor="transparent">
                    {formatPrice(VAT19)} &euro;
                  </Th>
                  <Td borderColor="transparent"></Td>
                </Tr>
                <Tr>
                  <Th colSpan={2} textAlign="right" borderColor="transparent">
                    {strings.vat7}
                  </Th>
                  <Th
                    isNumeric
                    colSpan={3}
                    textAlign="right"
                    borderColor="transparent">
                    {formatPrice(VAT7)} &euro;
                  </Th>
                  <Td borderColor="transparent"></Td>
                </Tr>
                <Tr>
                  <Th colSpan={2} textAlign="right" borderColor="gray">
                    {strings.booking_fee_pound}
                  </Th>
                  <Th
                    isNumeric
                    colSpan={3}
                    textAlign="right"
                    borderColor="gray">
                    {formatPrice(totalBookingFee)} &euro;
                  </Th>
                  <Td borderColor="transparent"></Td>
                </Tr>
                <Tr>
                  <Th colSpan={2} textAlign="right" borderColor="transparent">
                    {strings.grand_total}
                  </Th>
                  <Th
                    isNumeric
                    colSpan={3}
                    textAlign="right"
                    borderColor="transparent">
                    {formatPrice(grandTotal)} &euro;
                  </Th>
                  <Td borderColor="transparent"></Td>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
          <Divider />
        </Stack>
      )}
    </>
  );
};

export default BreadBookingForm;
