import {
  Box,
  Fade,
  Flex,
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTagTitle from 'components/CustomTagTitle';
import DateFromNowToolTip from 'components/DateFromNowToolTip';
import { strings } from 'config/localization';
import React, { useState } from 'react';
import { BiCommentDots, BiConversation, BiSpreadsheet } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import CommentForm from './CommentForm';

interface Props {
  ticketId: any;
}
const TicketComment: React.FC<Props> = (props) => {
  const { ticketId } = props;
  const ticketApi = new TicketResource();
  const [ticketComments, setTicketComments] = useState<any>();
  const [limit, setLimit] = useState<number>(5);
  const [viewMore, setViewMore] = useState(false); // for button

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const fetchTicketComments = async () => {
    const response: any = await ticketApi.getTicketComment(ticketId, 1, limit);
    let data = response.data.data;
    if (response.data.current_page !== response.data.pages) setViewMore(true);
    else setViewMore(false);
    setTicketComments({ ...ticketComments, data });
  };

  const { isLoading } = useQuery(
    [`getComment-${limit}`, limit],
    () => fetchTicketComments
  );

  /**
   * Toggling Reply hide and show Reply Text Are
   * @param index
   */
  const handleToggleReply = (index: number) => {
    let display = document.querySelector<HTMLElement>(`#comment${index}`)!.style
      .display;
    if (display === '') display = 'none';
    document.querySelector<HTMLElement>(`#comment${index}`)!.style.display =
      display === 'none' ? 'block' : 'none';
  };

  // View More Under Reply
  const handleShowReply = (index: number) => {
    ticketComments.data[index].replied_by_user.map((data: any, val: number) => {
      if (val > 2) {
        const display = document.querySelector<HTMLElement>(
          `.reply${index}${val}`
        )!.style.display;
        document.querySelector<HTMLElement>(
          `.reply${index}${val}`
        )!.style.display = display === 'none' ? 'flex' : 'none';
        document.querySelector<HTMLElement>(
          `#viewMoreText${index}`
        )!.innerHTML =
          display === 'none' ? strings.view_less : strings.view_more;
      }
    });
  };

  const handleComment = (data: any, index?: number) => {
    fetchTicketComments();
  };

  const handleViewMore = () => {
    const cnt = limit + 5;
    setLimit(cnt);
  };

  if (isLoading) return <CenterSpinner />;

  const getCommentDetails = (data: any, index: number) => {
    return (
      <>
        <Box borderBottom="1px" borderColor="gray.100" overflow="auto">
          <Stack direction="row" py="2">
            <ListIcon as={BiCommentDots} color="gray.300" w="6" h="6" />
            <Flex direction="column">
              <Stack direction="row">
                <Box fontWeight="bold" color="black" d="flex">
                  <CustomTagTitle
                    type="text"
                    status={data.created_by_user?.status}
                    name={data.created_by_user?.name || 'Anonymous'}
                  />
                </Box>
                <Box>{data.comment}</Box>
              </Stack>
              <Box direction="row" fontSize="xs" color="gray.400">
                <Stack direction="row" spacing="4">
                  <Box>
                    <DateFromNowToolTip dateLabel={data.createdAt} />
                  </Box>
                </Stack>
              </Box>
            </Flex>
          </Stack>
          {/* Replied Section Start */}
          {data?.replied_by_user?.map(
            (childrenComment: any, childIndex: number) => {
              if (childIndex <= 2)
                return (
                  <Stack direction="row" pl="8" py="2" key={childrenComment.id}>
                    <ListIcon as={BiCommentDots} color="gray.300" w="6" h="6" />
                    <Flex direction="column">
                      <Stack direction="row">
                        <Box fontWeight="bold" color="black" d="flex">
                          <CustomTagTitle
                            type="text"
                            status={childrenComment.created_by_user?.status}
                            name={
                              childrenComment.created_by_user?.name ||
                              'Anonymous'
                            }
                          />
                        </Box>
                        <Box>{childrenComment.comment}</Box>
                      </Stack>
                      <Box direction="row" fontSize="xs" color="gray.400">
                        <Stack direction="row" spacing="4">
                          <Box>
                            <DateFromNowToolTip
                              dateLabel={childrenComment.createdAt}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </Flex>
                  </Stack>
                );
              else
                return (
                  <Stack
                    direction="row"
                    pl="8"
                    py="2"
                    key={childrenComment.id}
                    style={{ display: 'none' }}
                    className={`reply${index}${childIndex}`}>
                    <ListIcon as={BiCommentDots} color="gray.300" w="6" h="6" />
                    <Flex direction="column">
                      <Stack direction="row">
                        <Box fontWeight="bold" color="black" d="flex">
                          <CustomTagTitle
                            type="text"
                            status={childrenComment.created_by_user?.status}
                            name={
                              childrenComment.created_by_user?.name ||
                              'Anonymous'
                            }
                          />
                        </Box>
                        <Box>{childrenComment.comment}</Box>
                      </Stack>
                      <Box direction="row" fontSize="xs" color="gray.400">
                        <Stack direction="row" spacing="4">
                          <Box>
                            <DateFromNowToolTip
                              dateLabel={childrenComment.createdAt}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </Flex>
                  </Stack>
                );
            }
          )}
          <Flex justify="space-between">
            <Box
              color="gray.300"
              _hover={{ color: 'blue.300', cursor: 'pointer' }}
              paddingBottom="2"
              paddingLeft="8"
              onClick={() => handleToggleReply(index)}>
              <Icon as={BiConversation} />
              {strings.reply}
            </Box>
            {data?.replied_by_user.length > 3 && (
              <Box
                display="flex"
                justifyContent="flex-end"
                color="gray.300"
                id={`viewMoreText${index}`}
                _hover={{ color: 'blue.300', cursor: 'pointer' }}
                onClick={() => handleShowReply(index)}>
                {strings.view_more}
              </Box>
            )}
          </Flex>
        </Box>
        <Box id={`comment${index}`} display="none" marginLeft="8">
          <Fade in={true}>
            <CommentForm
              ticketId={ticketId}
              loggedInId={loggedInUser.id}
              commentId={data.id}
              handleComment={(data: any) => handleComment(data)}
            />
          </Fade>
        </Box>
        {/* Replied Section End */}
      </>
    );
  };

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.comment}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <List color="gray.500">
          {ticketComments &&
            ticketComments?.data?.map((data: any, index: number) => {
              return (
                <ListItem key={data.id}>
                  {getCommentDetails(data, index)}
                </ListItem>
              );
            })}
        </List>
        {viewMore && (
          <Box
            display="flex"
            justifyContent="flex-start"
            color="gray.300"
            paddingTop="2"
            cursor="pointer"
            _hover={{ color: 'blue.300', cursor: 'pointer' }}>
            <Text onClick={handleViewMore} fontSize="md">
              <Icon as={BiSpreadsheet} mr="2" />
              {strings.view_more}
            </Text>
          </Box>
        )}
        <CommentForm
          ticketId={ticketId}
          loggedInId={loggedInUser.id}
          handleComment={(data: any) => handleComment(data)}
        />
      </Stack>
    </>
  );
};
export default TicketComment;
