import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { selectColorScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { PaymentSchema } from 'constants/schema';
import React from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { identifyService } from 'utils';
import { utcToLocal } from 'utils/DateFormat';

interface Props {
  pmsPaypalData: PaymentSchema;
  index: number;
}

const PaypalListItem: React.FC<Props> = (props) => {
  const { pmsPaypalData, index } = props;
  const status: 'pending' | 'confirmed' = pmsPaypalData?.status;
  const linkStatus:
    | 'service_reservation'
    | 'apartment_reservation'
    | 'ferry_booking'
    | 'payment_link' = pmsPaypalData?.payable_type;

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>
        <Text
          fontWeight="semibold"
          cursor={
            pmsPaypalData?.payable_type !== 'payment_link' ? 'pointer' : ''
          }
          onClick={() =>
            identifyService(
              pmsPaypalData?.payable_id,
              pmsPaypalData?.payable_type
            )
          }>
          {pmsPaypalData?.customer_name || '-'}
          {pmsPaypalData?.payable_type !== 'payment_link' && (
            <Tooltip label={strings[linkStatus]} hasArrow>
              <IconButton
                icon={<BiLinkExternal />}
                variant="link"
                aria-label={strings[linkStatus]}
                color="blue.400"
                ml={1}
                w="2"
                size="sm"
              />
            </Tooltip>
          )}
        </Text>
      </Td>
      <Td isNumeric>
        {utcToLocal(pmsPaypalData?.date_time).replace(' ', ', ') || '-'}
      </Td>
      <Td>{pmsPaypalData?.customer_detail?.email_address || '-'}</Td>
      <Td>
        <Text
          color={selectColorScheme(status)}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {strings[status] || '-'}
        </Text>
      </Td>
      {pmsPaypalData?.amount ? (
        <Td isNumeric>{pmsPaypalData?.amount} &euro;</Td>
      ) : (
        <Td isNumeric>-</Td>
      )}
    </Tr>
  );
};

export default PaypalListItem;
