import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  useRadioGroup,
} from '@chakra-ui/react';
import * as stockActivitiesApi from 'api/stock-activities';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CustomRadio from 'components/common/CustomRadio';
import { strings } from 'config/localization';
import { STOCK_ACTIVITIES_ENTITIES } from 'constants/common';
import {
  SuggestedUser,
  SuggestedWarehouse,
  TransferItemSchema,
} from 'constants/schema';
import React, { useEffect, useMemo } from 'react';
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';

const { STOCK_ACTIVITY_ACTIONS, WAREHOUSE_SHELVES, APARTMENTS, USERS } =
  STOCK_ACTIVITIES_ENTITIES;

function SourceInput({ control }: { control: Control<TransferItemSchema> }) {
  const thisLocation = useLocation();
  const query = new URLSearchParams(thisLocation.search);
  let userID = query.get('from');

  const {
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useFormContext<TransferItemSchema>();

  const articleId = useWatch({
    control,
    name: 'article_id',
  });
  const quantity = useWatch({
    control,
    name: 'quantity',
  });
  const sourceEntityType = useWatch({
    control,
    name: 'source_entity_type',
  });
  const destinationEntityType = useWatch({
    control,
    name: 'destination_entity_type',
  });

  // Reset source dropdown along when user changes the destination radio buttons or article or quantity
  useEffect(() => {
    reset({
      ...getValues(),
      source_entity_id: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceEntityType, articleId, quantity]);

  // Fetch suggested warehouses based on article and quantity
  const { data: sourceWarehouses } = useQuery(
    [
      'fetchSourceWarehouses',
      {
        articleId: articleId,
        quantity: quantity,
      },
    ],
    () =>
      articleId?.id &&
      quantity &&
      stockActivitiesApi
        .getSuggestedSourceWarehouses({
          article_id: articleId?.id,
          quantity: quantity,
        })
        .then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!(articleId && quantity),
    }
  );

  // Fetch suggested users based on article and quantity
  const { data: sourceUsers } = useQuery(
    [
      'fetchSourceUsers',
      {
        articleId: articleId,
        quantity: quantity,
      },
    ],
    () =>
      articleId?.id &&
      quantity &&
      stockActivitiesApi
        .getSuggestedSourceUsers({
          article_id: articleId?.id,
          quantity: quantity,
        })
        .then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!(articleId && quantity),
    }
  );

  const sourceOptions = useMemo(() => {
    // Warehouse options if warehouse is selected
    const warehouseOptions = sourceWarehouses?.map(
      (item: SuggestedWarehouse) => ({
        label: `${item.warehouse} / ${item.room} / ${item.rack} / ${item.shelf}`,
        value: item.shelf_id,
      })
    );
    // User options if employee is selected
    const userOptions = sourceUsers?.map((item: SuggestedUser) => ({
      label: item.user,
      value: item.user_id,
    }));

    // Decide which options to display
    switch (sourceEntityType) {
      case STOCK_ACTIVITY_ACTIONS:
        return [];
      case WAREHOUSE_SHELVES:
        return warehouseOptions;
      case USERS:
        return userOptions;
    }
  }, [sourceEntityType, sourceUsers, sourceWarehouses]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'SchemeTypeRadio',
    defaultValue: 'STOCK_ACTIVITY_ACTIONS',
    onChange: (sourceEntityType) => {
      setValue('source_entity_type', sourceEntityType);
    },
    value: sourceEntityType,
  });

  useEffect(() => {
    if (userID && sourceOptions) {
      let idNumber = Number(userID);
      let defaultValue = sourceOptions?.find(
        (item: any) => item.value === idNumber
      );
      setValue('source_entity_id', defaultValue);
    }
  }, [setValue, sourceOptions, userID]);

  // Source Entity (dropdown) is not required when type is `New/Fresh`
  const isSourceEntityRequired = sourceEntityType !== STOCK_ACTIVITY_ACTIONS;
  const isInputError = !!(
    errors?.source_entity_type || errors?.source_entity_id
  );

  const group = getRootProps();
  return (
    <FormControl isRequired>
      <FormLabel>{strings.source}</FormLabel>
      <Stack direction="row" mb="2" tabIndex={3}>
        <Flex {...group}>
          <CustomRadio
            type={'radioNav'}
            {...getRadioProps({ value: STOCK_ACTIVITY_ACTIONS })}
            isDisabled={destinationEntityType === STOCK_ACTIVITY_ACTIONS}>
            {strings.fresh}
          </CustomRadio>
          <CustomRadio
            type={'radioNav'}
            {...getRadioProps({ value: WAREHOUSE_SHELVES })}>
            {strings.warehouse}
          </CustomRadio>
          <CustomRadio type={'radioNav'} {...getRadioProps({ value: USERS })}>
            {strings.employee}
          </CustomRadio>
        </Flex>
      </Stack>

      <FormControl isInvalid={isInputError} isRequired={isSourceEntityRequired}>
        <Controller
          control={control}
          name="source_entity_id"
          rules={{
            required: isSourceEntityRequired ? strings.required_source : false,
          }}
          render={({ field }) => (
            <ReactSelect
              {...field}
              noOptionsMessage={() => strings.no_options}
              placeholder={strings.select}
              styles={reactSelectStyles}
              options={sourceOptions}
              isSearchable
              isDisabled={sourceEntityType === STOCK_ACTIVITY_ACTIONS}
              tabIndex="4"
              required
            />
          )}
        />
        <FormErrorMessage>
          {isInputError && strings.required_source}
        </FormErrorMessage>
        {!(articleId?.id && quantity) && (
          <FormHelperText>
            {strings.select_article_and_quantity_first}
          </FormHelperText>
        )}
      </FormControl>
    </FormControl>
  );
}

export default SourceInput;
