import {
  Box,
  Divider,
  Flex,
  List,
  ListIcon,
  ListItem,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import {
  FEEDBACK_QUESTION_TYPE_CLOSED_ENDED,
  FEEDBACK_QUESTION_TYPE_OPEN,
  FEEDBACK_QUESTION_TYPE_RATING,
} from 'constants/common';
import * as React from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { BsArrowReturnRight } from 'react-icons/bs';

const borderStyled = {
  borderColor: 'gray.200',
  my: '2',
  _last: {
    display: 'none',
  },
};

interface Props {
  response: any;
  index: any;
}

const FeedbackQuestionTypePDF: React.FC<Props> = (props) => {
  const { response, index } = props;
  const type = response?.question_meta?.type;
  const question = response?.question_meta?.name;

  if (type === FEEDBACK_QUESTION_TYPE_RATING) {
    return (
      <>
        <Box>
          <List spacing={3}>
            <ListItem fontWeight={600}>
              <ListIcon as={AiOutlineStar} fontSize="lg" />
              {question}
            </ListItem>
            <ListItem fontSize="sm">
              <ListIcon as={BsArrowReturnRight} fontSize="lg" />
              <Tooltip
                hasArrow
                label={strings.the_rating_is_out_of_10}
                placement="bottom"
                zIndex="popover">
                {response?.response}
              </Tooltip>
            </ListItem>
          </List>
        </Box>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  if (type === FEEDBACK_QUESTION_TYPE_CLOSED_ENDED) {
    return (
      <>
        <Box>
          <List spacing={3}>
            <ListItem fontWeight={600}>
              <ListIcon as={AiOutlineStar} fontSize="lg" />
              {question}
            </ListItem>
            <ListItem fontSize="sm">
              <ListIcon as={BsArrowReturnRight} fontSize="lg" />
              <Tooltip
                hasArrow
                label={strings.this_is_closed_ended_question}
                placement="bottom"
                zIndex="popover">
                {response?.response === '1' ? strings.yes : strings.no}
              </Tooltip>
            </ListItem>
          </List>
        </Box>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  if (type === FEEDBACK_QUESTION_TYPE_OPEN) {
    return (
      <>
        <Box>
          <List spacing={3}>
            <ListItem fontWeight={600}>
              <ListIcon as={AiOutlineStar} fontSize="lg" />
              {question}
            </ListItem>

            <ListItem fontSize="sm" textAlign="left">
              <Flex>
                <ListIcon as={BsArrowReturnRight} fontSize="lg" />
                <Tooltip
                  hasArrow
                  label={strings.this_is_open_ended_question}
                  placement="bottom"
                  zIndex="popover">
                  <Box>
                    {response?.response === '' ? (
                      <Text as="em">Keine Antwort</Text>
                    ) : (
                      response.response
                    )}
                  </Box>
                </Tooltip>
              </Flex>
            </ListItem>
          </List>
        </Box>
        <Divider sx={borderStyled}></Divider>
      </>
    );
  }
  return <></>;
};

export default FeedbackQuestionTypePDF;
