import { Flex, List, ListItem, Stack, Text } from '@chakra-ui/react';
import PermissionResources from 'api/permissions';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import { Permission, PermissionGroup } from 'constants/interfaces';
import React from 'react';
import { useQuery } from 'react-query';
import { getCommonPermissions } from 'utils/listInfo';

export interface RolePermissionDisplayProps {
  rightsFromRole: Permission[];
}

const RolePermissionDisplay: React.FC<RolePermissionDisplayProps> = ({
  rightsFromRole,
}) => {
  const permissionGroupsApi = new PermissionResources();

  /**
   * Fetch Permission Groups (menus along with permissions)
   */
  const { isLoading: isPermissionGroupLoading, data: permissionGroups } =
    useQuery('permissionGroups', () =>
      permissionGroupsApi.list().then((res) => res.data.data)
    );

  const rightsStyles = {
    fontWeight: 'normal',
    position: 'relative',
    paddingRight: '4',
    paddingLeft: '4',
    textTransform: 'capitalize',
  };

  const rightsStylesWithTrailingDash = {
    fontWeight: 'normal',
    position: 'relative',
    paddingRight: '4',
    paddingLeft: '4',
    textTransform: 'capitalize',
    _after: {
      content: `""`,
      position: 'absolute',
      top: '1',
      right: '0',
      width: '1px',
      height: '10px',
      bg: 'gray.400',
    },
  };

  if (isPermissionGroupLoading) return <CenterSpinner />;

  const rightsFromRoleToDisplay = permissionGroups?.reduce(
    (acc: PermissionGroup[], currentGroup: PermissionGroup) => {
      const commonPermissions = getCommonPermissions(
        currentGroup.permissions,
        rightsFromRole
      );
      if (commonPermissions.length < 1) return [...acc];
      const newGroup = { ...currentGroup, permissions: commonPermissions };
      return [...acc, newGroup];
    },
    []
  );

  return (
    <>
      {rightsFromRole?.length > 0 && (
        <Stack shadow="xs" p="4" rounded="sm" width="100%">
          <List>
            {rightsFromRoleToDisplay?.map(
              (pGroup: PermissionGroup, index: number) => (
                <ListItem
                  display="flex"
                  mt={index !== 0 ? 4 : 0}
                  key={pGroup.id}>
                  <Text minW="24" color="gray.900" fontWeight="semibold">
                    {strings.getString(pGroup.name.split('-').join('_'))}
                  </Text>
                  <Flex wrap="wrap">
                    {pGroup.permissions?.map(
                      (per: Permission, perIndex: Number) => (
                        <Text
                          color="gray.600"
                          fontWeight="normal"
                          sx={
                            perIndex === pGroup.permissions.length - 1
                              ? rightsStyles
                              : rightsStylesWithTrailingDash
                          }
                          key={per.id}>
                          {strings.getString(per.name.split('-').join('_'))}
                        </Text>
                      )
                    )}
                  </Flex>
                </ListItem>
              )
            )}
          </List>
        </Stack>
      )}
    </>
  );
};

export default RolePermissionDisplay;
