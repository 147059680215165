import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import ArticleUnitsResource from 'api/article-units';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { ArticleUnitSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const UnitsDetails: React.FC = () => {
  const { id }: any = useParams();
  const articleUnitApi = new ArticleUnitsResource();
  const { search } = useLocation();

  // Access Control
  const checkEditTypePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.units.edit.replace(':id', id.toString())
  );

  const articleUnitQuery = useQuery<ArticleUnitSchema>(
    [`articleUnit${id}`, id],
    () => articleUnitApi.get(id).then((res) => res.data.data)
  );

  if (articleUnitQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (articleUnitQuery.isError) {
    history.push(routes.mms.units.list);
  }

  return (
    <>
      <Helmet>
        <title>
          {strings.mms} | {articleUnitQuery?.data?.name}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.units.list + search}>
              {strings.unit}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {articleUnitQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.unit_details}
          </Heading>
          <Button
            type="button"
            colorScheme="primary"
            size="sm"
            onClick={checkEditTypePermission}>
            {strings.edit}
          </Button>
        </Flex>

        <Stack sx={wrapperStyles}>
          <Stack direction="row" spacing="4">
            <Grid gap={['3', '6']} templateColumns="repeat(1, 1fr)" w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.unit_name}</FormLabel>
                  <Text sx={infoStyles}>{articleUnitQuery?.data?.name}</Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.descriptions}</FormLabel>
                  <Text sx={infoStyles} wordBreak="break-all">
                    {articleUnitQuery?.data?.name}
                  </Text>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default UnitsDetails;
