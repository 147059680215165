import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import ArticlesResource from 'api/articles';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import ImageNotFound from 'assets/images/chat-not-available.png';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { formatCurrency } from 'utils';
import history from 'utils/history';

const ArticlesDetails: React.FC = () => {
  const { id }: any = useParams();
  const articleApi = new ArticlesResource();
  const { search } = useLocation();

  // Access Control
  const checkEditArticlePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.articles.edit.replace(':id', id?.toString())
  );

  const articleQuery = useQuery([`article${id}`, id], () =>
    articleApi.get(id).then((res) => res.data.data)
  );

  if (articleQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (articleQuery.isError) {
    history.push(routes.mms.articles.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {articleQuery?.data?.name}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.mms.articles.list + search}>
              {strings.article}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {articleQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.article_details}
          </Heading>
          {!!articleQuery?.data?.status && (
            <Link onClick={checkEditArticlePermission}>
              <Button colorScheme="primary" size="sm">
                {strings.edit}
              </Button>
            </Link>
          )}
        </Flex>

        <Stack
          direction="column"
          spacing="4"
          p={['3', '6']}
          bg="white"
          shadow="box"
          rounded="sm">
          <Stack>
            <Grid
              gap={['3', '6']}
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.article_name}</FormLabel>
                  <Text sx={infoStyles}>
                    <CustomTagTitle
                      type="text"
                      status={articleQuery.data?.status}
                      name={articleQuery.data?.name || '-'}
                    />
                  </Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.type}</FormLabel>
                  <Text sx={infoStyles}>
                    {articleQuery.data?.articleCategory?.name}
                  </Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormLabel sx={labelStyles}>{strings.unit}</FormLabel>
                <Text sx={infoStyles}>
                  {articleQuery.data?.articleUnitType?.name}
                </Text>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.minimum_stock_notification}
                  </FormLabel>
                  <Text sx={infoStyles}>
                    {articleQuery.data?.min_stock_limit ?? '-'}
                  </Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.maximum_stock}
                  </FormLabel>
                  <Text sx={infoStyles}>
                    {articleQuery.data?.max_stock_limit ?? '-'}
                  </Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.buying_price} (&euro;)
                  </FormLabel>
                  <Text sx={infoStyles}>
                    {articleQuery.data?.purchase_price
                      ? formatCurrency(articleQuery.data.purchase_price)
                      : '-'}
                  </Text>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>
                    {strings.selling_price} (&euro;)
                  </FormLabel>
                  <Text sx={infoStyles}>
                    {articleQuery.data?.selling_price
                      ? formatCurrency(articleQuery.data.selling_price)
                      : '-'}
                  </Text>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack>
            <Grid gap={['3', '6']} templateColumns="repeat(1, 1fr)" w="100%">
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{strings.notes}</FormLabel>
                  <Text sx={infoStyles} wordBreak="break-word">
                    {articleQuery.data?.description ?? '-'}
                  </Text>
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
          <Stack>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.article_image}</FormLabel>
              <Box>
                <Image
                  bg="gray.100"
                  shadow="box"
                  rounded="sm"
                  p="1"
                  boxSize="200px"
                  objectFit="cover"
                  src={articleQuery?.data?.file_url}
                  fallbackSrc={ImageNotFound}
                  alt={articleQuery?.data?.name}
                />
              </Box>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ArticlesDetails;
