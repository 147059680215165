import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { PartnerGoodiesSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data?: PartnerGoodiesSchema;
}
const PartnerGoodiesForm = ({ data }: Props) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<PartnerGoodiesSchema>();

  useEffect(() => {
    if (data) {
      setValue('name', data?.name);
      setValue('description', data?.description);
    }
  }, [data, setValue]);

  return (
    <form>
      <Stack direction="column" spacing="8">
        <Stack direction="row" spacing="8">
          <Grid gap="4" templateColumns={['repeat(1, 1fr)']} w="100%">
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.name}>
                <FormLabel>{strings.name_extra_goodies}</FormLabel>
                <Input
                  {...register('name', {
                    required: strings.required_extra_goodies,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  type="text"
                  placeholder={strings.name_extra_goodies}
                  defaultValue={data?.name}
                />
                <FormErrorMessage>
                  {errors.name && errors.name?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={!!errors.description}>
                <FormLabel>{strings.description}</FormLabel>
                <Textarea
                  {...register('description', {
                    required: strings.required_descriptions,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  type="text"
                  placeholder={strings.description}
                  defaultValue={data?.description}
                />
                <FormErrorMessage>
                  {errors.description && errors.description?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </form>
  );
};

export default PartnerGoodiesForm;
