import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CompressedUploader from 'components/common/CompressedUploader';
import CustomReactDatePicker from 'components/common/CustomReactDatePicker';
import { strings } from 'config/localization';
import {
  costRecipientMap,
  EXTERNAL_COMPANY_SEARCH_API,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD_TIME,
  TICKET_ACCEPT_FILE_TYPE,
  TICKET_CUSTOMER_SEARCH_API,
} from 'constants/common';
import { ReactSelectOption } from 'constants/interfaces';
import { ExternalCompanySchema } from 'constants/schema';
import useWordSearch from 'hooks/useWordSearch';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  CompleteDateTimeFormat,
  DateFormatYMD,
  DateTimeFormat,
} from 'utils/DateFormat';
import CustomerCustomSelect from './CustomerCustomSelect';

const PRIORITIES = ['low', 'medium', 'high', 'urgent'];

const getCostRecipientOption = () => {
  let options: ReactNode[] = [];
  for (let item of costRecipientMap) {
    let [value, key] = item;
    options.push(
      <option key={value} value={value}>
        {strings.getString(key)}
      </option>
    );
  }
  return options;
};

interface Props {
  ticketData?: any;
  isBillable?: boolean;
  clearFileInputToggle?: boolean;
}

const customerQueryParams = {
  is_owner: false,
};

const TicketForm: React.FC<Props> = (props) => {
  const location = useLocation<any>();

  const { customerValue: customerObj, apartmentObj } = useSelector(
    (state: any) => ({
      customerValue: state?.data?.feedback?.customer,
      apartmentObj: state?.data?.feedback?.apartment,
    })
  );

  const {
    ticketData,
    isBillable = false,
    clearFileInputToggle = false,
  } = props;
  const ticketApi = new TicketResource();
  const ticketDueDate =
    ticketData && ticketData.due_date
      ? new Date(ticketData.due_date)
      : new Date();

  const ticketAppointmentDate =
    ticketData && ticketData.appointment_date
      ? new Date(ticketData.appointment_date.replace('Z', ''))
      : null;

  const [dueDate, setDueDate] = useState<Date | null>(ticketDueDate);
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(
    ticketAppointmentDate
  );
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<any>();

  const [companyInputKey, setCompanyInputKey] = useState(
    ticketData?.external_company?.name ?? ''
  );

  const [ownerName, setOwnerName] = useState(
    ticketData?.house_owner_customer?.name || ''
  );

  const {
    loading: customerListLoading,
    result: customerQuery,
    setQuery,
  } = useWordSearch(`${TICKET_CUSTOMER_SEARCH_API}/?is_owner=false&limit=50`);

  const {
    loading: companyListLoading,
    result: companyQuery,
    setQuery: setCompanyQuery,
  } = useWordSearch(`${EXTERNAL_COMPANY_SEARCH_API}`);

  const apartmentQuery = useQuery(
    [`apartmentList`],
    () => ticketApi.getApartmentList().then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const userQuery = useQuery(
    'userList',
    () => ticketApi.getUserList().then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const deleteAttachment = useMutation((attachmentId: number) =>
    ticketApi.destroyAttachment(attachmentId)
  );

  const handleDeleteAttachment = (attachmentId: number) => {
    deleteAttachment.mutate(attachmentId);
  };

  const apartmentList = apartmentQuery?.data?.data;
  const userList = userQuery?.data;
  const customerList = customerQuery?.data;
  const companyList = companyQuery?.data;

  const apartmentOptions = useMemo(
    () =>
      apartmentList?.map((apartment: any) => ({
        label: apartment.name,
        value: apartment.id,
        customer: apartment?.customer,
      })),
    [apartmentList]
  );

  const userOptions = userList?.map((user: any) => ({
    label: user.name,
    value: user.id,
  }));

  const companyOptions = companyList?.map((company: ExternalCompanySchema) => ({
    label: company.name,
    value: company.id,
  }));

  // Set defaultValue with setValue as the inline defaultValue is lost occasionally on page refresh

  // setValue (default value) for object/apartment
  useEffect(() => {
    if (!ticketData) return;
    const defaultValue = apartmentOptions?.find(
      (item: ReactSelectOption) => item.value === ticketData?.apartment_id
    );
    if (defaultValue?.value) setValue('apartment_id', defaultValue);
  }, [apartmentOptions, ticketData, setValue]);

  // setValue (default value) for assignee/user
  useEffect(() => {
    if (!ticketData) return;
    const defaultValue = userOptions?.find(
      (item: ReactSelectOption) => item.value === ticketData?.assigned_user_id
    );
    if (defaultValue?.value) setValue('assigned_user_id', defaultValue);
  }, [userOptions, ticketData, setValue]);

  // setValue (default value) for customer
  useEffect(() => {
    if (!ticketData) return;
    if (!ticketData.customer) return;

    let { id, name, is_intermixed } = ticketData.customer;
    let defaultValue = { value: id, label: name, isDisabled: is_intermixed };
    setQuery({ name });
    setValue('customer_id', defaultValue);
  }, [ticketData, setValue, setQuery]);

  //setValue(default value) for customer and apartment and houseOwner from feedback detail page
  useEffect(() => {
    if (location?.state?.redirectedFromFeedback) {
      setValue('customer_id', customerObj);

      const apartment = apartmentObj;
      const apartmentValue = apartmentOptions?.find(
        (item: ReactSelectOption) => item.value === apartment?.value
      );
      setValue('apartment_id', apartmentValue);

      let ownerValue = {
        value: apartment?.customer?.id,
        label: apartment?.customer?.name,
        isDisabled: apartment?.customer?.is_intermixed,
      };
      if (ownerValue.value) {
        setOwnerName(ownerValue.label);
        setValue('house_owner', {
          label: ownerValue.label,
          value: ownerValue.value,
        });
      }
    }
  }, [
    apartmentObj,
    apartmentOptions,
    customerObj,
    location?.state?.redirectedFromFeedback,
    setValue,
  ]);

  //setValue (default value) for external company
  useEffect(() => {
    if (!ticketData) return;
    if (!ticketData.external_company) return;

    let { id, name } = ticketData.external_company;
    let defaultValue = { value: id, label: name };

    setCompanyQuery({ keyword: name });
    setValue('external_company_id', defaultValue);
  }, [ticketData, setValue, setCompanyQuery]);

  // setValue (default value) for house owner
  useEffect(() => {
    if (!ticketData) return;
    if (!ticketData?.house_owner_customer) return;

    let { id, name, is_intermixed } = ticketData.house_owner_customer;
    let defaultValue = { value: id, label: name, isDisabled: is_intermixed };

    if (defaultValue?.value) setValue('house_owner', defaultValue);
  }, [ticketData, setValue]);

  // setValue (default value) for rest of the form inputs
  useEffect(() => {
    if (!ticketData) return;
    setValue('title', ticketData.title);
    setValue('cost_recipient', ticketData.cost_recipient);
    setValue('priority', ticketData.priority);
    setValue('description', ticketData.description);
    setValue('is_billable', ticketData.is_billable ? true : false);
    setValue('house_owner', ticketData.house_owner);
    if (!ticketData.due_date) {
      setValue('due_date', new Date());
    }
  }, [ticketData, setValue]);

  if (apartmentQuery.isLoading && customerListLoading && userQuery.isLoading)
    return <CenterSpinner />;

  const isTicketTypeBillable = ticketData
    ? !ticketData?.recursive_interval
    : isBillable;

  const handleCustomerInputChange = (
    id: number,
    name: string,
    is_intermixed: boolean
  ) => {
    setValue('customer_id', {
      value: id,
      label: name,
      isDisabled: is_intermixed,
    });
  };

  const handleCompanyChange = (value: string, action: any) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setCompanyInputKey(value);
      setCompanyQuery({ keyword: value });
    }
  };

  const handleApartment = (apartment: any) => {
    if (apartment?.customer?.id) {
      setOwnerName(apartment?.customer?.name || '');
      setValue('house_owner', {
        label: apartment?.customer?.name,
        value: apartment?.customer?.id,
      });
    } else {
      setOwnerName('');
      setValue('house_owner', {
        label: '',
        value: '',
      });
    }
  };
  const handleDateChange = (date: Date) => {
    setDueDate(date);
    setValue('due_date', date);
  };

  const handleAppointmentDateTimeChange = (date: Date) => {
    setAppointmentDate(date);
    setValue('appointment_date', CompleteDateTimeFormat(date));
  };

  return (
    <Stack direction="column" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl isInvalid={!!errors?.title} isRequired>
            <FormLabel>{strings.ticket_name}</FormLabel>
            <Input
              id="title"
              type="text"
              placeholder={strings.title}
              {...register('title', {
                validate: (value) => {
                  if (!value) return strings.title_required;
                  if (value.trim() === '') return strings.title_required;
                },
              })}
              tabIndex={1}
            />
            <FormErrorMessage>
              {errors?.title && errors?.title?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.assigned_user_id}>
            <FormLabel>{strings.assignee}</FormLabel>
            <Controller
              control={control}
              name="assigned_user_id"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="assigned_user_id"
                  placeholder={strings.select_assignee}
                  options={userOptions}
                  styles={reactSelectStyles}
                  tabIndex="9"
                />
              )}
            />
            <FormErrorMessage>
              {errors?.assigned_user_id &&
                (errors.assigned_user_id as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <Stack direction={['column', 'row']} spacing="2">
            <FormControl isInvalid={!!errors?.apartment_id}>
              <FormLabel>{strings.object}</FormLabel>
              <Controller
                control={control}
                name="apartment_id"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="apartment_id"
                    placeholder={strings.select_apartment}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      handleApartment(selectedOption);
                    }}
                    options={apartmentOptions}
                    styles={reactSelectStyles}
                    tabIndex="2"
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.apartment_id &&
                  (errors.apartment_id as FieldError)?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.house_owner}</FormLabel>
              <Input
                id="house_owner"
                type="hidden"
                {...register('house_owner')}
              />
              <Input id="house_owner" value={ownerName} disabled />
            </FormControl>
          </Stack>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.cost_recipient}>
            <FormLabel>{strings.cost_recipient}</FormLabel>
            <Select
              id="cost_recipient"
              placeholder={strings.select_cost_recepient}
              {...register('cost_recipient')}
              tabIndex={10}>
              {getCostRecipientOption()}
            </Select>
            <FormErrorMessage>
              {errors?.cost_recipient && errors?.cost_recipient?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <Stack direction={['column', 'row']} spacing="2">
            <FormControl>
              <FormLabel>{strings.due_date}</FormLabel>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  children={<BiCalendar />}
                  color="gray.400"
                />
                <CustomReactDatePicker
                  placeholderText={strings.select_date}
                  onChange={handleDateChange}
                  selected={dueDate}
                  minDate={new Date()}
                  value={DateFormatYMD(dueDate)}
                  tabIndex={3}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{strings.appointment_date_time}</FormLabel>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  children={<BiCalendar />}
                  color="gray.400"
                />

                <CustomReactDatePicker
                  placeholderText={strings.enter_appointment_date_time}
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD_TIME}
                  onChange={handleAppointmentDateTimeChange}
                  selected={appointmentDate}
                  minDate={new Date()}
                  value={DateTimeFormat(appointmentDate)}
                  tabIndex={4}
                  showTimeSelect={true}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption={strings.select_time}
                />
              </InputGroup>
            </FormControl>
          </Stack>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.customer_id}>
            <FormLabel>{strings.customer}</FormLabel>
            <Controller
              control={control}
              name="customer_id"
              render={({ field }) => (
                <CustomerCustomSelect
                  id="customer_id"
                  placeholder={strings.select_customer}
                  value={field.value ? field.value.label : null}
                  onChange={handleCustomerInputChange}
                  parentQueries={customerQueryParams}
                  tabIndex={11}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.customer_id &&
                (errors.customer_id as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <Stack direction="column" spacing="4">
            <FormControl isInvalid={!!errors?.external_company_id}>
              <FormLabel>{strings.external_company}</FormLabel>
              <Controller
                control={control}
                name="external_company_id"
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="external_company_id"
                    placeholder={strings.select}
                    isLoading={companyListLoading}
                    options={companyOptions}
                    styles={reactSelectStyles}
                    onInputChange={handleCompanyChange}
                    inputValue={companyInputKey}
                    isSearchable
                    tabIndex="5"
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.external_company_id &&
                  (errors.external_company_id as FieldError)?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.priority}>
              <FormLabel>{strings.priority_label}</FormLabel>
              <Select
                id="priority"
                placeholder={strings.select_priority}
                {...register('priority')}
                tabIndex={6}>
                {PRIORITIES.map((priority: any) => {
                  return (
                    <option key={priority} value={priority}>
                      {strings
                        .getString(priority.split('-').join(''))
                        .toUpperCase()}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>
                {errors?.priority && errors?.priority?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors?.description}>
              <FormLabel>{strings.descriptions}</FormLabel>
              <Textarea
                id="description"
                placeholder={strings.write_description}
                {...register('description')}
                tabIndex={7}
              />
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>{strings.upload_files}</FormLabel>
            <CompressedUploader
              uploadedFiles={ticketData?.ticket_attachments}
              deleteAttachment={(attachmentId: number) =>
                handleDeleteAttachment(attachmentId)
              }
              acceptFileType={TICKET_ACCEPT_FILE_TYPE}
              clearFileInputToggle={clearFileInputToggle}
              fileKey="files"
              tabIndex={12}
            />
          </FormControl>
        </GridItem>
      </Grid>
      {isTicketTypeBillable && (
        <FormControl>
          <FormLabel>{strings.billability}</FormLabel>
          <Controller
            name="is_billable"
            control={control}
            render={({ field }) => (
              <Checkbox
                onChange={(e) => field.onChange(e.target.checked)}
                isChecked={field.value}
                tabIndex={8}>
                {strings.billable}?
              </Checkbox>
            )}
          />
        </FormControl>
      )}
    </Stack>
  );
};

export default TicketForm;
