import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Doc, Files, ImageFile, Pdf } from 'assets/icons';
import React from 'react';
import { formatBytes } from 'utils';

interface Props {
  fileName: string;
  fileSize: any;
  mimeType: any;
}

const FileDetails: React.FC<Props> = (props) => {
  const { fileName, fileSize, mimeType } = props;

  const getFiles = () => {
    let fileDetails: any = fileName;
    const fileType = mimeType.split('/');
    switch (fileType[0]) {
      case 'image':
        fileDetails += `.${fileType[1]}`;
        return (
          <>
            <Flex>
              <Box w="4" h="4" mr={1} mt={1}>
                <Image
                  color="gray.400"
                  w="100%"
                  src={ImageFile}
                  alt="Image"
                  title="Image"
                  className="logo-icon"
                />
              </Box>
              <Text>
                {fileDetails} - {formatBytes(fileSize)}
              </Text>
            </Flex>
          </>
        );

      case 'application':
        if (fileType[1] === 'pdf') {
          fileDetails += `.pdf`;
          return (
            <>
              <Flex>
                <Box w="4" h="4" mr={1} mt={1}>
                  <Image
                    color="gray.400"
                    w="100%"
                    src={Pdf}
                    alt="PDF"
                    title="PDF"
                    className="logo-icon"
                  />
                </Box>
                <Text>
                  {fileDetails} - {formatBytes(fileSize)}
                </Text>
              </Flex>
            </>
          );
        }
        if (
          fileType[1] ===
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          fileDetails += '.xlsx';
          return (
            <>
              <Flex>
                <Box w="4" h="4" mr={1} mt={1}>
                  <Image
                    color="gray.400"
                    w="100%"
                    src={Files}
                    alt="Excel"
                    title="Excel"
                    className="logo-icon"
                  />
                </Box>
                <Text>
                  {fileDetails} - {formatBytes(fileSize)}
                </Text>
              </Flex>
            </>
          );
        }
        if (
          fileType[1] ===
          'vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          fileDetails += '.docx';
          return (
            <>
              <Flex>
                <Box w="4" h="4" mr={1} mt={1}>
                  <Image
                    color="gray.400"
                    w="100%"
                    src={Doc}
                    alt="Document"
                    title="Document"
                    className="logo-icon"
                  />
                </Box>
                <Text>
                  {fileDetails} - {formatBytes(fileSize)}
                </Text>
              </Flex>
            </>
          );
        }

        return '';
      default:
        return (
          <>
            <Flex>
              <Box w="4" h="4" mr={1} mt={1}>
                <Image
                  color="gray.400"
                  w="100%"
                  src={Files}
                  alt="File"
                  title="File"
                  className="logo-icon"
                />
              </Box>
              <Text>
                {fileDetails} - {formatBytes(fileSize)}
              </Text>
            </Flex>
          </>
        );
    }
  };

  return <>{getFiles()}</>;
};

export default FileDetails;
