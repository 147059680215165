import { Stack, Td, Text, Tr } from '@chakra-ui/react';
import { ContactCenterTotalCallsSchema } from 'constants/schema';
import React from 'react';
import { utcToLocal } from 'utils/DateFormat';
import { getCallDirectionIcon } from 'utils/getCallDirectionIcon';

interface Props {
  data: ContactCenterTotalCallsSchema;
  index: number;
  search?: string;
}

const CallForUserListItem: React.FC<Props> = (props) => {
  const { data, index } = props;
  const {
    contact,
    calledDate,
    duration,
    user: aircall_user,
    customerPhoneNumber,
    direction,
  } = data;

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>{aircall_user?.name || '-'}</Td>
      <Td>{customerPhoneNumber || '-'}</Td>
      <Td>{contact?.customer_full_name || '-'}</Td>
      <Td>{calledDate ? utcToLocal(calledDate).replace(' ', ', ') : '-'}</Td>
      <Td>
        <Stack direction="row" alignItems="center" spacing="8px">
          {getCallDirectionIcon(direction)}
          <Text>{duration || '-'}</Text>
        </Stack>
      </Td>
    </Tr>
  );
};

export default CallForUserListItem;
