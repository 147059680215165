import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import MembershipLevelResource from 'api/membership-level';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { MembershipLevelSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { truncateString } from 'utils';

interface Props {
  membershipLevelData: MembershipLevelSchema;
  index: number;
  search: string;
}

const MembershipLevelListItem: React.FC<Props> = (props) => {
  const { membershipLevelData, index, search } = props;
  const {
    id,
    name,
    description,
    is_internal,
    is_published,
    number_of_booking,
  } = membershipLevelData;

  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [titleMsg, setTitleMsg] = useState('');
  const [updatedValues, setUpdatedValues] = useState({
    is_published: is_published,
    is_internal: is_internal,
    number_of_booking: number_of_booking,
  });

  useEffect(() => {
    if (membershipLevelData) {
      setUpdatedValues({
        is_published: membershipLevelData?.is_published || false,
        is_internal: membershipLevelData?.is_internal || false,
        number_of_booking: membershipLevelData?.number_of_booking || 0,
      });
    }
  }, [membershipLevelData]);

  const queryClient = useQueryClient();
  const toast = useToast();
  const membershipLevelAPI = new MembershipLevelResource();

  const deleteMembershipLevel = useMutation(
    () => membershipLevelAPI.destroy(id),
    {
      onSuccess: (res) => {
        if (res.data.success) {
          setDeleteModalOpen(false);
          queryClient.invalidateQueries('membership-level-list');
          toast({
            title: strings.membership_delete,
            status: 'success',
            isClosable: true,
          });
        } else {
          setDeleteMsg(strings.membership_delete_error);
        }
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const updateMembershipLevel = useMutation(
    (data: any) => membershipLevelAPI.update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('membership-level-list');
        setUpdateModalOpen(false);
        toast({
          title: strings.updated,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteMembershipLevel.mutate();
  };

  const handlePublishedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setTitleMsg(isChecked ? strings.publish : strings.un_publish);
    setUpdatedValues((prevState) => ({
      ...prevState,
      is_published: isChecked,
    }));

    setUpdateModalOpen(true);
  };

  const handleInternalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setTitleMsg(
      isChecked ? strings.mark_as_internal : strings.remove_from_internal
    );
    setUpdatedValues((prevState) => ({
      ...prevState,
      is_internal: isChecked,
    }));
    setUpdateModalOpen(true);
  };

  const handleBookingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfBooking = parseInt(event.target.value);
    const title: string = strings.formatString(
      strings.update_number_of_booking_in_level,
      strings.update.toLocaleLowerCase()
    ) as string;
    setTitleMsg(title);
    setUpdatedValues((prevState) => ({
      ...prevState,
      number_of_booking: numberOfBooking,
    }));
    setUpdateModalOpen(true);
  };

  const onUpdateModalClose = () => {
    setUpdatedValues({
      is_published: membershipLevelData?.is_published || false,
      is_internal: membershipLevelData?.is_internal || false,
      number_of_booking: membershipLevelData?.number_of_booking || 0,
    });
    setUpdateModalOpen(false);
  };

  const onUpdate = () => {
    updateMembershipLevel.mutate({ _method: 'PUT', ...updatedValues });
  };

  return (
    <Tr key={id}>
      <Td>{index}</Td>
      <Td>{name ?? '-'}</Td>
      <Td>{description ? truncateString(description, 50) : '-'}</Td>
      <Td>
        <Input
          type="number"
          min={0}
          defaultValue={updatedValues?.number_of_booking}
          onBlur={handleBookingChange}
          maxWidth={'150'}
        />
      </Td>
      <Td>
        <Checkbox
          isChecked={updatedValues?.is_published}
          onChange={handlePublishedChange}
        />
      </Td>
      <Td>
        <Checkbox
          isChecked={updatedValues?.is_internal}
          onChange={handleInternalChange}
        />
      </Td>

      <Td>
        <RouterLink
          to={
            routes.thirdPartyPartner.membershipLevel.details.replace(
              ':id',
              id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <RouterLink
          to={
            routes.thirdPartyPartner.membershipLevel.edit.replace(
              ':id',
              id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.edit_member_level}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit_member_level}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>

        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteMembershipLevel.isLoading}>
                  {strings.delete}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isUpdateModalOpen}
          isCentered
          onClose={onUpdateModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {strings.update} {}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {' '}
              {strings.formatString(
                strings.message_update_membership_value,
                titleMsg
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="green"
                  onClick={onUpdate}
                  isLoading={updateMembershipLevel.isLoading}>
                  {strings.update}
                </Button>

                <Button variant="outline" onClick={onUpdateModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default MembershipLevelListItem;
