import {
  Box,
  Button,
  Center,
  FormControl,
  Grid,
  GridItem,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import CRMEventLogResource from 'api/crm-eventLogs';
import { linkStyle, wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import { INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import {
  ContactSchema,
  DataWrapperSchema,
  EventLogSchema,
} from 'constants/schema';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';
import { useInfiniteQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { changeURL, cleanData } from 'utils';
import {
  DateFormat,
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface Props {
  eventLogs: ContactSchema;
}

type EventType = 'ticket' | 'call' | 'document' | 'booking' | '';

type EventLogsFilterParams = {
  page: number;
  event: EventType;
  event_time_from: Date | null;
  event_time_to: Date | null;
};
const DEFAULT_PAGE_LIMIT = 15;

interface RenderTableLabelProps {
  event: EventLogSchema;
}
const RenderTableLabel: React.FC<RenderTableLabelProps> = (props) => {
  const { event } = props;

  if (event.event === 'booking') {
    return (
      <Link
        to={routes.bms.reservation.details.replace(
          ':id',
          event?.event_description.id.toString()
        )}
        target="_blank"
        rel="noopener noreferrer">
        {event?.event_description.booking_number}
      </Link>
    );
  }

  if (event.event === 'document') {
    return (
      <Link
        to={routes.dms.customerDocuments.customer.list.replace(
          ':id',
          event.contact_id.toString()
        )}
        target="_blank"
        rel="noopener noreferrer">
        {event?.event_description.name}
      </Link>
    );
  }
  if (event.event === 'ticket') {
    return (
      <Link
        target="_blank"
        rel="noopener noreferrers"
        to={routes.ticket.task.view.replace(':id', event.event_id.toString())}>
        {`${strings[event.event]} #${event.event_id}`}
      </Link>
    );
  }
  return <Text>{strings[event.event]}</Text>;
};

const EventLogs: React.FC<Props> = (props) => {
  let { eventLogs } = props;
  let contactID: any = [];

  if (eventLogs.contactDetail.is_merged) {
    contactID = eventLogs.contactMergeDetail?.details.event;
  } else {
    contactID = [eventLogs.contactDetail.contact_id];
  }

  const { search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);

  const eventSearch = searchParams.get('event') ?? '';
  const emailTimeFromSearch = searchParams.get('event_time_from')
    ? new Date(Number(searchParams.get('event_time_from')))
    : null;
  const emailTimeToSearch = searchParams.get('event_time_to')
    ? new Date(Number(searchParams.get('event_time_to')))
    : null;

  const eventLogAPI = new CRMEventLogResource();

  const [filterParams, setFilterParams] = useState<EventLogsFilterParams>({
    page: INITIAL_CURRENT_PAGE,
    event: eventSearch as EventType,
    event_time_from: emailTimeFromSearch,
    event_time_to: emailTimeToSearch,
  });

  const {
    data: eventList,
    hasNextPage,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery<DataWrapperSchema<EventLogSchema[]>>({
    queryKey: ['event-logs', contactID, filterParams],
    queryFn: async ({ pageParam = 1 }) => {
      const queryParams = {
        page: pageParam,
        limit: DEFAULT_PAGE_LIMIT,
        event: filterParams.event,
        event_time_from: filterParams.event_time_from
          ? getUTCDateRangeStartDateTime(filterParams.event_time_from)
          : null,
        event_time_to: filterParams.event_time_to
          ? getUTCDateRangeEndDateTime(filterParams.event_time_to)
          : null,
      };
      const cleanQuery = cleanData(queryParams);
      const res = await eventLogAPI.getEventList(contactID, cleanQuery);
      return res?.data?.data;
    },
    getNextPageParam: (page) => {
      const { current_page, last_page } = page.meta;
      if (last_page > current_page) return current_page + 1;
      return null;
    },
  });

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.event_time_from)
      data['event_time_from'] = Date.parse(data.event_time_from);
    if (data.event_time_to)
      data['event_time_to'] = Date.parse(data.event_time_to);
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [filterParams, history]);

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prev) => {
      return {
        ...prev,
        page: INITIAL_CURRENT_PAGE,
        event_time_from: start,
        event_time_to: end,
      };
    });
  };

  const onEventChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = e.currentTarget.value as EventType;
    setFilterParams((prev) => ({
      ...prev,
      page: INITIAL_CURRENT_PAGE,
      event: value,
    }));
  };

  const handleReset = () => {
    setFilterParams({
      page: INITIAL_CURRENT_PAGE,
      event: '',
      event_time_from: null,
      event_time_to: null,
    });
  };

  const { visible, observer } = useIntersectionObserver(isLoading, {
    rootMargin: '0px 0px 30% 0px',
  });

  useEffect(() => {
    if (!visible) return;
    fetchNextPage();
  }, [visible, fetchNextPage]);

  return (
    <Stack sx={wrapperStyles}>
      <form>
        <Stack direction="row" spacing="4" align="end">
          <Grid
            flex={1}
            gap="4"
            templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}>
            <GridItem>
              <FormControl>
                <Select
                  rounded="sm"
                  value={filterParams.event}
                  onChange={onEventChange}
                  placeholder={strings.select}>
                  <option value="ticket">{strings.ticket}</option>
                  <option value="call">{strings.call}</option>
                  <option value="document">{strings.documents}</option>
                  <option value="booking">{strings.booking}</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<BiCalendar />}
                    color="gray.400"
                  />
                  <ReactDatePicker
                    placeholderText={strings.select_date}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomDateInput />}
                    onChange={handleDateChange}
                    selected={filterParams.event_time_from}
                    startDate={filterParams.event_time_from}
                    endDate={filterParams.event_time_to}
                    selectsRange
                    value={getFormattedRangeDate(
                      filterParams.event_time_from,
                      filterParams.event_time_to
                    )}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
            </GridItem>
          </Grid>
          <Stack direction={['column', 'row']}>
            <Button
              colorScheme="primary"
              type="button"
              variant="outline"
              fontSize="xs"
              onClick={handleReset}>
              {strings.reset_filter}
            </Button>
          </Stack>
        </Stack>
      </form>

      <Box overflow="auto" h="28rem">
        <Table>
          <Thead>
            <Tr>
              <Th>{strings.channel}</Th>
              <Th isNumeric>{strings.date}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              eventList?.pages.map((page) =>
                page?.data?.map((item) => (
                  <Tr key={item.id}>
                    <Td sx={linkStyle}>{<RenderTableLabel event={item} />}</Td>
                    <Td isNumeric>{DateFormat(item.event_time)}</Td>
                  </Tr>
                ))
              )}

            {isLoading && (
              <Tr>
                <Td colSpan={2}>
                  <Center size="lg" color="primary.500" thickness="3px">
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            )}
            {hasNextPage && <Tr ref={observer}></Tr>}
            {eventList?.pages?.[0]?.data?.length === 0 && !isLoading && (
              <Tr>
                <Td colSpan={2} textAlign="center" color="gray.500">
                  {strings.no_data_found}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  );
};

export default EventLogs;
