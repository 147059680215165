import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ArticleUnitsResource from 'api/article-units';
import { wrapperStyles } from 'assets/css/commonStyles';
import UnitForm from 'components/article-unit/UnitForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ArticleUnitSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const EditUnit: React.FC = () => {
  const { id }: any = useParams();

  const methods = useForm<ArticleUnitSchema>();
  const articleUnitsAPI = new ArticleUnitsResource();
  const toast = useToast();
  const [errMsg, setErrMsg] = useState('');

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const articleUnitQuery = useQuery<ArticleUnitSchema>(
    [`articleUnit${id}`, id],
    () => articleUnitsAPI.get(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateArticleUnit = useMutation((data: any) =>
    articleUnitsAPI.update(id, data)
  );

  if (articleUnitQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (articleUnitQuery.isError) {
    history.push(routes.mms.units.list);
  }

  const onSubmit = (data: ArticleUnitSchema) => {
    data.updated_by = loggedInUser.id;
    updateArticleUnit.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.unit} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.mms.units.list);
      },
      onError: () =>
        setErrMsg(`${strings.unit} ${strings.has_not_been_updated}`),
    });
  };

  const handleReset = () => {
    methods.reset();
    setErrMsg('');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.edit_unit}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.units.list}>
              {strings.unit}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {articleUnitQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.add_unit}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <Stack direction="column" spacing="4">
                <UnitForm data={articleUnitQuery?.data} />

                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    isLoading={updateArticleUnit.isLoading}
                    type="submit">
                    {strings.update}
                  </Button>
                  <Button variant="outline" onClick={handleReset}>
                    {strings.clear}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditUnit;
