import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import { strings } from 'config/localization';
import { ThirdPartyPartnerSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  data?: ThirdPartyPartnerSchema;
}

const ThirdPartyPartnerForm: React.FC<Props> = (props) => {
  const { data } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<ThirdPartyPartnerSchema>();

  const isActive = watch('is_active');
  // Set form values when data prop changes
  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('brief_description', data.brief_description);
      setValue('description', data.description);
      setValue('is_active', !!data.is_active);
    }
  }, [data, setValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('is_active', event.target.checked);
  };

  return (
    <form>
      <Stack direction="column" spacing="8">
        <Grid gap="4" templateColumns={['repeat(1, 1fr)']} w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel>{strings.name_third_party_partner}</FormLabel>
              <Input
                {...register('name', {
                  required: strings.required_third_party_partner,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.name_third_party_partner}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>{strings.brief_description}</FormLabel>
              <Textarea
                {...register('brief_description')}
                placeholder={strings.brief_description}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description')}
                placeholder={strings.description}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{strings.active}</FormLabel>
              <Checkbox isChecked={isActive} onChange={handleChange} />
            </FormControl>
          </GridItem>
          <GridItem w={'20%'}>
            <FormControl>
              <FormLabel>{strings.icon}</FormLabel>
              <ImageUploader
                uploadedFiles={data?.icon}
                accept="image/png, image/svg"
                fileKey="icons"
              />
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default ThirdPartyPartnerForm;
