import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tag,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { QRScan } from 'assets/icons';
import QRScanDetailPDF from 'components/qr-scan/QRScanDetailPDF';
import { strings } from 'config/localization';
import { ROLE_ADMIN } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import { QRCodeSVG } from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { AiOutlinePrinter } from 'react-icons/ai';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import AccessControl from 'services/AccessControl';
import { maskPhone } from 'utils';

interface Props {
  userData: UserSchema;
  index: number;
  search?: any;
}

const UserListItem: React.FC<Props> = (props) => {
  const { userData, index, search = '' } = props;

  const printRef: any = useRef();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isQRModelOpen, setIsQRModelOpen] = useState(false);
  const [is2FADisableModalOpen, setIs2FADisableModalOpen] = useState(false);

  const is2FAEnabled: boolean = userData?.has_2fa_enabled;

  const userAPI = new UserResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const checkEditUserPermission = useCheckPermission(
    [PermissionRequest['manage:user']],
    routes.users.edit.replace(':id', userData.id.toString())
  );

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const isDisable2faAllowed: boolean =
    loggedInUser.role === ROLE_ADMIN && is2FAEnabled;

  const qrSignature = [
    {
      value: JSON.stringify({
        email: userData.email,
      }),
      title: `${userData.first_name} ${userData.last_name}`,
    },
  ];

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteUser = useMutation(() => userAPI.destroy(userData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('userList');
      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.user_deleted,
          status: 'success',
          isClosable: true,
        });
      } else {
        setDeleteMsg(strings.user_delete_error);
      }
    },
  });

  const disable2fa = useMutation(
    (data: any) => userAPI.disable2FAByAdmin(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userList');
        toast({
          title: strings.fa_Disable,
          status: 'success',
          isClosable: true,
        });
        setIs2FADisableModalOpen(false);
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteUser.mutate();
  };

  const saveQRScan = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '* {background-color:white !important} ',
    documentTitle: `${userData.first_name} ${userData.last_name} QR Code`,
    onAfterPrint: () => setIsQRModelOpen(false),
  });

  const handleDisable2fa = () => {
    disable2fa.mutate({ user_id: userData.id });
  };

  return (
    <Tr key={userData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.users.profile.replace(':id', userData.id.toString()) + search
          }>
          <Stack direction="row" alignItems="center">
            <Avatar
              size="sm"
              rounded="full"
              src={userData.profile_picture_url}
            />
            <Text
              fontWeight="medium"
              color="gray.500"
              _hover={{ color: 'gray.600' }}>
              {userData.first_name} {userData.last_name}
            </Text>
          </Stack>
        </RouterLink>
      </Td>
      <Td>{userData.email}</Td>
      <Td isNumeric>{maskPhone(userData.contact_number)}</Td>
      <Td>
        <Stack direction="row">
          {userData?.foreign_language ? (
            userData?.foreign_language?.map((language, index: number) => (
              <Tooltip key={index} hasArrow label={strings[language]}>
                <Tag background="gray.100">{language.toUpperCase()}</Tag>
              </Tooltip>
            ))
          ) : (
            <Text>-</Text>
          )}
        </Stack>
      </Td>
      <Td>
        <RouterLink
          to={
            routes.users.profile.replace(':id', userData.id.toString()) + search
          }></RouterLink>
        <Text textTransform="capitalize">
          {strings.getString(userData?.role?.split(' ').join('_'))}
        </Text>
      </Td>

      <Td>
        <Stack position="relative" direction="column">
          <Switch
            size="md"
            name="has_payment_enable"
            colorScheme="green"
            isChecked={is2FAEnabled}
            isDisabled={!isDisable2faAllowed}
            onChange={() => setIs2FADisableModalOpen(true)}
          />
        </Stack>
        <Modal
          isOpen={is2FADisableModalOpen}
          isCentered
          onClose={() => setIs2FADisableModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.disable_2FA}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.disable_2FA_confimation_message}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  onClick={handleDisable2fa}
                  isLoading={disable2fa.isLoading}>
                  {strings.confirm}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setIs2FADisableModalOpen(false)}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
      <Td>
        <Stack direction="row" alignItems="baseline">
          <RouterLink
            to={
              routes.users.profile.replace(':id', userData.id.toString()) +
              search
            }>
            <Tooltip hasArrow label={strings.view_user}>
              <IconButton
                icon={<BiShow />}
                variant="link"
                aria-label={strings.view_user}
                color="green.300"
                minW="6"
              />
            </Tooltip>
          </RouterLink>
          <Link onClick={checkEditUserPermission}>
            <Tooltip hasArrow label={strings.edit_user}>
              <IconButton
                icon={<BiEdit />}
                variant="link"
                aria-label={strings.edit_user}
                color="blue.300"
                minW="6"
              />
            </Tooltip>
          </Link>
          <Tooltip hasArrow label={strings.delete_user}>
            <IconButton
              icon={<BiTrash />}
              variant="link"
              aria-label={strings.delete_user}
              color="red.300"
              minW="6"
              onClick={onDeletePress}
            />
          </Tooltip>
          <Modal
            isOpen={isDeleteModalOpen}
            isCentered
            onClose={onDeleteModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{strings.delete_user}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {strings.confirm_delete}
                {deleteMsg && (
                  <Alert status="error" mb={2}>
                    <AlertIcon />
                    {deleteMsg}
                  </Alert>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <AccessControl
                    allowedPermissions={[PermissionRequest['manage:user']]}
                    renderNoAccess={(data: any) => data}>
                    <Button
                      colorScheme="red"
                      onClick={onDeleteConfirm}
                      isLoading={deleteUser.isLoading}>
                      {strings.delete}
                    </Button>
                  </AccessControl>

                  <Button variant="outline" onClick={onDeleteModalClose}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* QR-scan  */}
          <Tooltip hasArrow label={strings.qr_code}>
            <Image
              src={QRScan}
              alt="Get QrSCan"
              onClick={() => setIsQRModelOpen(true)}
              w="4"
              _hover={{ cursor: 'pointer' }}
            />
          </Tooltip>

          <Modal
            isOpen={isQRModelOpen}
            isCentered
            size="xl"
            onClose={() => setIsQRModelOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Text as="p" color="paragraph" fontSize="18px">
                  {userData.first_name} {userData.last_name} {strings.qr_code}
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack
                  direction="column"
                  alignItems="center"
                  border="1px solid black"
                  p={4}
                  spacing={5}>
                  <QRCodeSVG
                    value={qrSignature[0].value}
                    height="80%"
                    width="80%"
                  />
                  <Text color="grey.700" fontSize="24px">
                    {userData.first_name} {userData.last_name}
                  </Text>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    leftIcon={<AiOutlinePrinter />}
                    minW="8"
                    variant="solid"
                    onClick={saveQRScan}>
                    {strings.print_qr}
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="primary"
                    onClick={() => setIsQRModelOpen(false)}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <div style={{ display: 'none' }}>
            <QRScanDetailPDF qrSignature={qrSignature} ref={printRef} />
          </div>
        </Stack>
      </Td>
    </Tr>
  );
};

export default UserListItem;
