import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { RackInput, RoomInput, ShelfInput } from 'constants/interfaces';
import routes from 'constants/routes';
import { WarehouseSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { generateUniqueId } from 'utils';
import {
  getDefaultRoomData,
  getNewRackName,
  getNewShelfName,
  getRoomDataFromEditData,
} from 'utils/listInfo';
import RoomsAccordion from './RoomsAccordion';

export interface WarehouseFormProps {
  editData?: WarehouseSchema;
  isSubmitting: boolean;
  isDeleting?: boolean;
  handleSave: (data: any) => void;
  deleteShelf?: (id: number) => void;
  deleteRack?: (id: number) => void;
  deleteRoom?: (id: number) => void;
}

export type EntityToDeleteType = {
  entity: string;
  generatedRoomId?: string;
  rackName?: string;
  shelfName?: string;
  id?: number; // roomId/rackId/shelfId based on entity (only on edit mode)
};

type ValidationErrorType = {
  name: Boolean;
  description: Boolean;
};

const WarehouseForm: React.FC<WarehouseFormProps> = (props) => {
  const {
    editData,
    handleSave,
    isSubmitting,
    isDeleting,
    deleteShelf,
    deleteRack,
    deleteRoom,
  } = props;

  const isWarehouseSetToEdit = !!editData?.id;

  const toast = useToast();
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  useState<boolean>(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<string>('');

  const [entityToDelete, setEntityToDelete] = useState<EntityToDeleteType>({
    entity: '',
    generatedRoomId: '',
    rackName: '',
    shelfName: '',
  });

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [validationError, setValidationError] = useState<ValidationErrorType>({
    name: false,
    description: false,
  });

  const [roomsInput, setRoomsInput] = useState<RoomInput[]>([]);

  /**
   * Add shelf to the specific rack of specific room
   *
   * @param roomId
   * @param rackName
   */
  const handleAddShelf = (roomId: string, rackName: string) => {
    // Find the shelf belonging to the specific room and rack
    const room = roomsInput.find((r) => r.generatedId === roomId);
    if (!room) return;
    const rack = room.racks.find((rack) => rack.name === rackName);
    if (!rack) return;
    // Add the new Shelf
    const newShelfName = getNewShelfName(rack.shelves);
    if (!newShelfName) return;
    const newShelf: ShelfInput = {
      name: newShelfName.toString(),
    };
    // Add isNew: true flag for new shelf on edit mode
    if (isWarehouseSetToEdit) newShelf.isNew = true;
    rack.shelves = [...rack.shelves, newShelf];
    // Update the room list
    const updatedRooms = roomsInput.map((r) =>
      r.generatedId === room.generatedId ? room : r
    );
    setRoomsInput(updatedRooms);
  };

  /**
   * Add rack to the specific room
   *
   * @param roomId
   */
  const handleAddRack = (roomId: string) => {
    // Find the rack belonging to the specific room
    const room = roomsInput.find((r) => r.generatedId === roomId);
    if (!room) return;
    // Get a new rack; a new rack consists of a shelf by default
    const rackName = getNewRackName(room.racks);
    if (!rackName) return;
    const newRack: RackInput = {
      name: rackName,
      shelves: [{ name: '1' }],
    };
    // Add isNew: true flag for new rack and its default shelf on edit mode
    if (isWarehouseSetToEdit) {
      newRack.isNew = true;
      newRack.shelves[0].isNew = true;
    }
    room.racks = [...room.racks, newRack];
    // Update the room list
    const updatedRooms = roomsInput.map((r) =>
      r.generatedId === room.generatedId ? room : r
    );
    setRoomsInput(updatedRooms);
  };

  /**
   * Add a room
   */
  const handleAddRoom = () => {
    const newRoom: RoomInput = {
      generatedId: generateUniqueId(),
      name: `${strings.enter_room_name}`,
      racks: [
        {
          name: 'A',
          shelves: [
            {
              name: '1',
            },
          ],
        },
      ],
    };
    if (isWarehouseSetToEdit) {
      newRoom.isNew = true;
      newRoom.racks[0].isNew = true;
      newRoom.racks[0].shelves[0].isNew = true;
    }
    setRoomsInput([...roomsInput, newRoom]);
  };

  /**
   * Set the necessary entity (room/rack/shelf) to delete when user clicks on trash can
   *
   * @param entity
   * @param detail
   */
  const handleDeleteEntity = (
    entity: string,
    detail: {
      generatedRoomId: string;
      rackName?: string;
      shelfName?: string;
      id?: number;
    }
  ) => {
    setEntityToDelete({
      entity: entity,
      generatedRoomId: detail.generatedRoomId,
      rackName: detail.rackName,
      shelfName: detail.shelfName,
      id: detail.id,
    });
  };

  /**
   * Clear the entity set to delete
   * Close the delete confirmation modal
   *
   */
  const handleCloseDelete = () => {
    setEntityToDelete({
      entity: '',
      generatedRoomId: '',
      rackName: '',
      shelfName: '',
    });
    setIsModalOpen(false);
  };

  const handleDeleteShelf = async () => {
    const { generatedRoomId, rackName, shelfName, id } = entityToDelete;
    try {
      if (isWarehouseSetToEdit && deleteShelf !== undefined && id) {
        await deleteShelf(id);
      }
      // Find the shelf belonging to the specific room and rack
      const room = roomsInput.find((r) => r.generatedId === generatedRoomId);
      if (!room) return;
      const rack = room.racks.find((rack) => rack.name === rackName);
      if (!rack) return;
      // Remove the shelf
      rack.shelves = rack.shelves.filter((shelf) => shelf.name !== shelfName);
      // Update the room list
      const updatedRooms = roomsInput.map((r) =>
        r.generatedId === room.generatedId ? room : r
      );
      setRoomsInput(updatedRooms);
      // Reset entityToDelete and close delete confirmation modal
      handleCloseDelete();
    } catch (e) {
      setDeleteErrorMsg(strings.shelf_delete_error);
    }
  };

  const handleDeleteRack = async () => {
    const { generatedRoomId, rackName, id } = entityToDelete;
    try {
      if (isWarehouseSetToEdit && deleteRack !== undefined && id) {
        await deleteRack(id);
      }
      // Find the rack belonging to the specific room
      const room = roomsInput.find((r) => r.generatedId === generatedRoomId);
      if (!room) return;
      // Remove the rack
      room.racks = room.racks.filter((rack) => rack.name !== rackName);
      // Update the room list
      const updatedRooms = roomsInput.map((r) =>
        r.generatedId === room.generatedId ? room : r
      );
      setRoomsInput(updatedRooms);
      // Reset entityToDelete and close delete confirmation modal
      handleCloseDelete();
    } catch (e) {
      setDeleteErrorMsg(strings.rack_delete_error);
    }
  };

  const handleDeleteRoom = async () => {
    const { generatedRoomId, id } = entityToDelete;

    try {
      if (isWarehouseSetToEdit && deleteRoom !== undefined && id) {
        await deleteRoom(id);
      }
      setRoomsInput((prevState) =>
        prevState.filter((room) => room.generatedId !== generatedRoomId)
      );
      handleCloseDelete();
    } catch (e) {
      setDeleteErrorMsg(strings.room_delete_error);
    }
  };

  const handleConfirmDelete = () => {
    if (entityToDelete.entity === strings.delete_shelf) {
      handleDeleteShelf();
      return;
    }
    if (entityToDelete.entity === strings.delete_rack) {
      handleDeleteRack();
      return;
    }
    handleDeleteRoom();
  };

  const handleRoomNameChange = (value: string, generatedRoomId: string) => {
    setRoomsInput((prevState) =>
      prevState.map((room) => {
        if (room.generatedId === generatedRoomId)
          return {
            ...room,
            name: value,
            isUpdated: isWarehouseSetToEdit ? true : undefined,
          };
        return room;
      })
    );
  };

  const checkIfValidated = () => {
    const isNameEmpty = name.trim() === '';
    const isDescriptionEmpty = description.trim() === '';
    setValidationError({
      name: isNameEmpty,
      description: isDescriptionEmpty,
    });
    if (isNameEmpty || isDescriptionEmpty) return false;
    return true;
  };

  const handleAddWarehouseSubmit = () => {
    // Validation
    const isValidated = checkIfValidated();
    if (!isValidated) return;
    // Remove properties not required for payload
    const rooms = roomsInput.map(
      ({ generatedId, warehouse_id, ...roomRest }: any) => {
        roomRest.racks = roomRest.racks.map(
          ({ warehouse_room_id, ...rackRest }: any) => {
            rackRest.shelves = rackRest.shelves.map(
              ({ id, warehouse_rack_id, ...shelfRest }: any) => shelfRest
            );
            return rackRest;
          }
        );
        return roomRest;
      }
    );
    const payload: any = {
      name: name,
      description: description,
      rooms: rooms,
    };
    if (isWarehouseSetToEdit) {
      payload.updated_by = loggedInUser.id;
    } else {
      payload.created_by = loggedInUser.id;
    }
    handleSave(payload);
  };

  useEffect(() => {
    if (entityToDelete.entity) {
      setIsModalOpen(true);
      setDeleteErrorMsg('');
    }
  }, [entityToDelete]);

  useEffect(() => {
    setName(editData?.name ? editData.name : '');
    setDescription(editData?.description ? editData.description : '');
    setRoomsInput(
      editData?.id ? getRoomDataFromEditData(editData) : getDefaultRoomData()
    );
  }, [editData]);

  return (
    <>
      <Grid gap="4" w="100%">
        <GridItem>
          <FormControl isInvalid={!!validationError.name} isRequired>
            <FormLabel>{strings.warehouse_name}</FormLabel>
            <Input
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setValidationError((prevState) => ({
                  ...prevState,
                  name: false,
                }));
              }}
            />
            <FormErrorMessage>
              {validationError.name ? strings.required_warehouse_name : ''}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!validationError.description} isRequired>
            <FormLabel>{strings.description}</FormLabel>
            <Textarea
              placeholder=""
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setValidationError((prevState) => ({
                  ...prevState,
                  description: false,
                }));
              }}
            />
            <FormErrorMessage>
              {validationError.description ? strings.required_descriptions : ''}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <RoomsAccordion
        roomsInput={roomsInput}
        viewOnly={false}
        showQR={false}
        handleDeleteEntity={handleDeleteEntity}
        handleAddShelf={handleAddShelf}
        handleAddRack={handleAddRack}
        handleAddRoom={handleAddRoom}
        handleRoomNameChange={handleRoomNameChange}
      />

      <ButtonGroup>
        <Button
          colorScheme="primary"
          type="button"
          onClick={handleAddWarehouseSubmit}
          isLoading={isSubmitting}>
          {isWarehouseSetToEdit ? strings.save_now : strings.add_now}
        </Button>
        <RouterLink to={routes.mms.warehouse.list}>
          <Button variant="outline" isDisabled={isSubmitting}>
            {strings.cancel}
          </Button>
        </RouterLink>
      </ButtonGroup>

      <Modal isOpen={isModalOpen} isCentered onClose={handleCloseDelete}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{entityToDelete.entity}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {deleteErrorMsg && (
              <Alert status="error" mb={2}>
                <AlertIcon />
                {deleteErrorMsg}
              </Alert>
            )}

            {strings.confirm_delete}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={handleConfirmDelete}
                isLoading={isDeleting}>
                {strings.delete}
              </Button>
              <Button
                variant="outline"
                onClick={handleCloseDelete}
                isDisabled={isDeleting}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WarehouseForm;
