import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  DashAccordionButtonStyle,
  DashHeadingStyle,
  ObjectDashboardColumnStyle,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { useDebounce } from 'hooks/useDebounce';
import React, { useMemo, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import InfoArea from './InfoArea';
import OtherObjectsArea from './OtherObjectsArea';
import OwnerDocumentArea from './OwnerDocumentArea';

interface Props {
  vOfficeOwnerId: number | null;
}

const OwnerDash: React.FC<Props> = (props) => {
  const { vOfficeOwnerId } = props;
  const [ownerId, setOwnerId] = useState<number | null>(null);

  const [search, setSearch] = useState('');
  const keyword = useDebounce(search);

  const accordionItemsList = useMemo(
    () =>
      [
        {
          id: 1,
          accordionLabel: 'infos',
          accordionContent: (
            <InfoArea setOwnerId={setOwnerId} vOfficeOwnerId={vOfficeOwnerId} />
          ),
        },
        {
          id: 2,
          accordionLabel: 'event_history',
          accordionContent: <div>{strings.no_data_found} </div>,
        },
        {
          id: 3,
          accordionLabel: 'documents',
          accordionContent: <OwnerDocumentArea ownerId={ownerId} />,
        },

        {
          id: 4,
          accordionLabel: 'other_objects',
          accordionContent: (
            <OtherObjectsArea vOfficeOwnerId={vOfficeOwnerId} />
          ),
        },
      ] as const,
    [vOfficeOwnerId, ownerId]
  );

  return (
    <Stack sx={ObjectDashboardColumnStyle}>
      <Heading sx={DashHeadingStyle}>{strings.owner}</Heading>
      <FormControl>
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            children={<BiSearch />}
            color="gray.400"
          />
          <Input
            name="keyword"
            placeholder={strings.search}
            value={search}
            type="text"
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </InputGroup>
      </FormControl>

      <Accordion defaultIndex={[0]} allowToggle width="100%">
        {accordionItemsList.map((item) => (
          <AccordionItem key={item.id} bg="gray.50" title={item.accordionLabel}>
            <AccordionButton sx={DashAccordionButtonStyle}>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Text as="h4" fontWeight="medium" color="white">
                    {strings[item.accordionLabel]}
                  </Text>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Stack gridGap="10px">{item.accordionContent}</Stack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
};

export default OwnerDash;
