import Resource from 'api/resource';
import http from 'utils/http';

class FAQResource extends Resource {
  constructor() {
    super('faq/faqs');
  }
  updateFAQ(id:any,data:any) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'POST',
      data: data,
    });
  };
}

export { FAQResource as default };
