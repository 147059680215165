import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ArticlesResource from 'api/articles';
import { wrapperStyles } from 'assets/css/commonStyles';
import ArticleForm from 'components/article/ArticleForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ArticleSchema, UploadFileSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { cleanData } from 'utils';
import history from 'utils/history';

const EditArticle: React.FC = () => {
  // params
  const { id }: any = useParams();

  // Library Init
  const methods = useForm<ArticleSchema>();
  const toast = useToast();
  const queryClient = useQueryClient();

  // API Resource
  const articlesAPI = new ArticlesResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Redux Selector
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const fetchArticle = async () => {
    const response = await articlesAPI.get(id);
    methods.setValue('min_stock_limit', response.data?.data?.min_stock_limit, {
      shouldValidate: true,
    });
    methods.setValue('max_stock_limit', response.data?.data?.max_stock_limit, {
      shouldValidate: true,
    });
    return response.data.data;
  };

  // Query to fetch article
  const articleQuery = useQuery<any>(`fetchArticle`, () => fetchArticle(), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  // Mutation to update article
  const updateArticle = useMutation((data: any) =>
    articlesAPI.update(id, data)
  );

  const handleUploadFiles = async (file: any) => {
    const formData = new FormData();
    formData.append('image', file);
    const uploads: any = await articlesAPI.uploadToBucket(formData);
    const { status, file_name, mime_type, file_size, file_path } =
      uploads?.data;
    if (status) {
      return {
        file_name: file_name,
        mime_type: mime_type,
        file_size: file_size,
        file_path: file_path,
      };
    }
  };

  const onSubmit = async (
    formData: ArticleSchema & { files?: UploadFileSchema }
  ) => {
    // upload to Bucket First
    let files: any = [];
    setIsLoading(true);
    if (formData?.files && formData?.files?.size > 0) {
      files = await handleUploadFiles(formData.files);
      delete formData['files'];
      formData.file_name = files.file_name;
      formData.mime_type = files.mime_type;
      formData.file_size = files.file_size;
      formData.file_path = files.file_path;
    }
    formData.updated_by = loggedInUser.id;
    const data: any = cleanData(formData);
    if (!formData.max_stock_limit) data.max_stock_limit = null;
    updateArticle.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchArticle');
        toast({
          title: `${strings.article} ${strings.has_been_updated}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.mms.articles.details.replace(':id', id));
      },
      onError: () => {
        setErrMsg(`${strings.article} ${strings.has_not_been_updated}`);
        setIsLoading(false);
      },
    });
  };

  const handleReset = () => {
    methods.reset();
    setErrMsg('');
    history.push(routes.mms.articles.list);
  };

  if (articleQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (articleQuery.isError) {
    history.push(routes.mms.articles.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.edit_article}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.articles.list}>
              {strings.articles}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {articleQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.edit_article}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <Stack direction="column" spacing="4">
                <ArticleForm data={articleQuery?.data} />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {strings.update}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={handleReset}
                    isDisabled={isLoading}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditArticle;
