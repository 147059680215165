import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ArticlesResource from 'api/articles';
import { wrapperStyles } from 'assets/css/commonStyles';
import ArticleForm from 'components/article/ArticleForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ArticleSchema, UploadFileSchema } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { cleanData } from 'utils';
import history from 'utils/history';

const defaultValues = {
  name: '',
  article_category_id: null,
  article_unit_type_id: null,
  min_stock_limit: null,
  max_stock_limit: null,
  purchase_price: '',
  description: '',
};

const AddArticle: React.FC = () => {
  // Library Init
  const methods = useForm<ArticleSchema>({
    defaultValues,
  });
  const toast = useToast();

  // API Resource
  const articlesAPI = new ArticlesResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Redux Selector
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const createArticle = useMutation((data: any) => articlesAPI.store(data));

  const handleUploadFiles = async (file: any) => {
    const formData = new FormData();
    formData.append('image', file);
    const uploads: any = await articlesAPI.uploadToBucket(formData);
    const { status, file_name, mime_type, file_size, file_path } =
      uploads?.data;
    if (status) {
      return {
        file_name: file_name,
        mime_type: mime_type,
        file_size: file_size,
        file_path: file_path,
      };
    }
  };

  const onSubmit = async (
    formData: ArticleSchema & { files?: UploadFileSchema }
  ) => {
    // upload to Bucket First
    let files: any = [];
    setIsLoading(true);
    if (formData.files && formData.files?.size > 0) {
      files = await handleUploadFiles(formData.files);
      delete formData['files'];
      formData.file_name = files.file_name;
      formData.mime_type = files.mime_type;
      formData.file_size = files.file_size;
      formData.file_path = files.file_path;
    }
    formData.created_by = loggedInUser.id;
    const data = cleanData(formData);
    createArticle.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.article} ${strings.has_been_created}`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.mms.articles.list);
      },
      onError: () => {
        setErrMsg(`${strings.article} ${strings.has_not_been_created}`);
        setIsLoading(false);
      },
    });
  };

  const handleReset = () => {
    methods.reset(defaultValues);
    setErrMsg('');
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {strings.add_article}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.articles.list}>
              {strings.articles}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.mms.articles.add}>
              {strings.add_article}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.add_article}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <Stack direction="column" spacing="4">
                <ArticleForm />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {strings.add_now}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={handleReset}
                    isDisabled={isLoading}>
                    {strings.clear}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddArticle;
