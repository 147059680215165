import { strings } from 'config/localization';
import moment from 'moment';
import 'moment/locale/de';

moment.locale(strings.lang);

type momentDurationTypes = 'days' | 'weeks' | 'months' | 'years';

export const DateFormat = (date: any): string => {
  if (!date) return date;
  return moment(date).format('DD.MM.YYYY');
};

export const DateTimeFormat = (date: any): string => {
  if (!date) return date;
  return moment(date).format('DD.MM.YYYY HH:mm');
};

export const CompleteDateTimeFormat = (date: any): string => {
  if (!date) return date;
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

/**
 * @param {Date}
 * @returns {string} - Date in the given format YYYY-MM-DD HH:mm:ss
 * @example Tue Nov 22 2022 08:30:00 GMT+0545 => '2022-11-22 02:45'
 */
export const DateTimeFormatToUTC = (date: any): string => {
  if (!date) return date;
  const utcDate = moment.utc(date).format('DD.MM.YYYY HH:mm');
  return utcDate;
};

export const DateFormatYMD = (date: any): string => {
  if (!date) return date;
  return moment(date).format('YYYY-MM-DD');
};

export const DateFormatFromNow = (date: Date): string => {
  if (!date) return date;
  return moment(date).fromNow();
};

/**
 * @param {string} date - Date in format YYYY-MM-DD HH:mm:ss *
 * @returns {string} - Date in the given format DD:MM:YYYY HH:mm
 * @example utcToLocal('2020-01-01 00:00:00') => 01.01.2020 02:00 , for German locale ie +2 GMT
 */

export const utcToLocal = (date: any): string => {
  if (!date) return date;
  let utcDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  let stillUTC = moment.utc(utcDate).toDate();
  let localDate = moment(stillUTC).local().format('DD.MM.YYYY HH:mm');
  return localDate;
};

/**
 * returns the number of days between two dates
 * @param startDate: Date
 * @param endDate: Date
 * @returns number of days:number
 */
export const getNumberOfDays = (startDate: Date, endDate: Date) => {
  let a = moment(startDate);
  let b = moment(endDate);
  let diff = b.diff(a, 'days') + 1;
  return diff;
};

/**
 * returns the number of dates between two days
 * @param startDate: Date
 * @param endDate: Date
 * @returns list of dates:string[]
 */
export const enumerateDaysBetweenDates = (start: Date, end: Date) => {
  let dates = [];

  let currDate = moment(start).startOf('day');
  let lastDate = moment(end).endOf('day');
  dates.push(currDate.clone().toDate().toString());

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate().toString());
  }

  return dates;
};

/**
 * returns the number of dates between two days
 * @param startDate: Date | string
 * @param endDate: Date | string
 * @returns list of dates:string[]
 */
export const enumerateDaysBetweenDatesYMD = (
  startDate: string | Date,
  endDate: string | Date
) => {
  let dates = [];
  let currDate = moment(startDate);
  let lastDate = moment(endDate);
  while (currDate <= lastDate) {
    dates.push(currDate.format('YYYY-MM-DD'));
    currDate.add(1, 'days');
  }
  return dates;
};

/**
 * Example: 9:45 AM
 * @param time
 * @returns string
 */
export const TimeFormatHMA = (time: any): string => {
  return moment(time).format('h:mm A');
};

/**
 * Example: 1h 2m 3s
 * @param time
 * @returns string
 */
export const TimeFormatHMS = (time: any): string => {
  return moment
    .utc(moment.duration(time).asMilliseconds())
    .format('HH[h] mm[m] ss[s]')
    .replace('00h', '')
    .replace('00m', '');
};

/**
 * Check if a date is before than another date
 *
 * @param dateOne date which is supposed to come before
 * @param dateTwo
 * @returns  true if dateOne is before dateTwo
 */
export const checkIfBefore = (
  dateOne: Date | string,
  dateTwo: Date | string
) => {
  return moment(dateOne).isBefore(dateTwo);
};

/**
 * Check if the difference between two dates isl less than the days specified
 *
 * @param dateOne date which is supposed to come before
 * @param dateTwo
 * @returns  true if dateTwo is after certain days specified from dateone
 */
export const checkIsBeforeCertainDays = (
  dateOne: Date | string | null,
  dateTwo: Date | string | null,
  days: number
) => {
  const dateOneMoment = moment(dateOne);
  const dateTwoMoment = moment(dateTwo);
  return dateTwoMoment.diff(dateOneMoment, 'days') < days;
};

/**
 * Get Date after N days/weeks/months/years
 *
 * @param date Date obj or string
 * @param object {type: 'days' | 'weeks' | 'months' | 'years', quantity: number of respective duration}
 * @returns
 */

export const getDateAfter = (
  date: Date | string | null,
  { duration, quantity }: { duration: momentDurationTypes; quantity: number }
) => {
  const currentDate = typeof date === 'string' ? new Date(date) : date;
  let nextDate = moment(currentDate, '').add(quantity, duration);
  return moment(nextDate).toDate();
};

/**
 * Get local to UTC date and time for searching by created_at, updated_at, etc.
 * @param {Date} date Date object
 * @returns {string} - Date in the given format DD:MM:YYYY HH:mm:ss
 * @example Tue Nov 08 2022 00:00:00 GMT+0545 (Nepal Time) => 2022-11-07 18:15:00 (UTC Time)
 */
export const getUTCDateRangeStartDateTime = (date: Date | null) => {
  if (!date) return date;
  const formattedUTCDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  return formattedUTCDate;
};

/**
 * Get date and time for searching. Eg: appointment date in ticket
 * @param {Date} date Date object
 * @returns {string} - Date in the given format DD:MM:YYYY HH:mm:ss
 * @example Tue Nov 08 2022 00:00:00 GMT+0545 (Nepal Time) => 2022-11-08 00:00:00 (UTC Time)
 */
export const getDateRangeStartDateTime = (date: Date | null) => {
  if (!date) return date;
  const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
  return formattedDate;
};

/**
 * Get local to UTC date and time of end of the day (+24 hours) for searching by created_at, updated_at, etc.
 * @param {Date} date Date object
 * @returns {string} - Date in the given format DD:MM:YYYY HH:mm:ss
 * @example Tue Nov 08 2022 00:00:00 GMT+0545 (Nepal Time) => 2022-11-08 18:15:00 (UTC Time)
 */
export const getUTCDateRangeEndDateTime = (date: Date | null) => {
  if (!date) return date;
  const formattedUTCDate = moment
    .utc(date)
    .add(24, 'hours')
    .format('YYYY-MM-DD HH:mm:ss');
  return formattedUTCDate;
};

/**
 * Get date and time of end of the day (+24 hours) Eg: appointment date in ticket
 * @param {Date} date Date object
 * @returns {string} - Date in the given format DD:MM:YYYY HH:mm:ss
 * @example Tue Nov 08 2022 00:00:00 GMT+0545 (Nepal Time) => 2022-11-09 00:00:00
 */
export const getDateRangeEndDateTime = (date: Date | null) => {
  if (!date) return date;
  const formattedDate = moment(date)
    .add(24, 'hours')
    .format('YYYY-MM-DD HH:mm:ss');
  return formattedDate;
};

/**
 * Used for date range filters
 * @param {Date} sDate Date Object
 * @param {Date} eDate Date Object
 * @returns {string} (YYYY-MM-DD - YYYY-MM-DD)
 */
export const getFormattedRangeDate = (
  sDate: Date | null,
  eDate: Date | null
) => {
  if (!sDate && !eDate) return '';
  if (!eDate) return DateFormatYMD(sDate);
  return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
};
