import { RestaurantSchema } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

class RestaurantResource extends Resource {
  constructor() {
    super('rms/restaurant');
  }

  // This function performs an update operation by sending a POST request to the server with the provided resource data.
  // Note: Since FormData cannot be sent in a PUT request, we're using a POST request to mimic the update operation.
  update(id: number, resource: RestaurantSchema) {
    return http({
      url: `/${this.uri}/${id}`,
      method: 'post',
      data: resource,
    });
  }
}

export { RestaurantResource as default };
