// import { LogoWithText } from 'assets/images';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  ScaleFade,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { forgotPassword } from 'api/auth';
import { DE, EN, PL } from 'assets/icons';
import { Logo } from 'assets/images';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { LanguageTypesSchema } from 'constants/schema';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronDown, BiChevronLeft, BiEnvelope } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { validEmail } from 'utils/validate';

const ForgotPassword: React.FC = () => {
  const currentLanguage = strings.getLanguage();
  const [currentFlag, setCurrentFlag] = useState(() =>
    currentLanguage === 'pl' ? PL : currentLanguage === 'de' ? DE : EN
  );
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm();
  const forgotMutation = useMutation((email) => forgotPassword(email), {
    onSuccess: (res: any) => {
      reset();
      toast({
        title: strings.msg_password_reset_link,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onSubmit = (data: any) => {
    forgotMutation.mutate(data);
  };
  const handleLanguage = (Language: LanguageTypesSchema) => {
    setCurrentFlag(Language === 'en' ? EN : Language === 'de' ? DE : PL);

    strings.setLanguage(Language);
    localStorage.setItem('language', Language);
  };
  return (
    <Box bg="gray.50">
      <Stack pos="absolute" right="5%">
        <Menu>
          <MenuButton
            p={2}
            transition="all 0.2s"
            _hover={{ borderBottom: 'gray.300' }}
            _expanded={{ borderBottom: 'gray.400' }}>
            <Flex>
              <MenuItem icon={<Image src={currentFlag} w="6" h="6" />}>
                {currentLanguage?.toLocaleUpperCase()}
              </MenuItem>
              <Box pt="2">
                <BiChevronDown />
              </Box>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => handleLanguage('de')}
              icon={<Image src={DE} w="6" h="6" />}>
              DE
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('en')}
              icon={<Image src={EN} w="6" h="6" />}>
              EN
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => handleLanguage('pl')}
              icon={<Image src={PL} w="6" h="6" />}>
              PL
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
      <Center h="100vh">
        <Stack direction="column" spacing="12">
          <Stack spacing="4">
            <Center>
              <Image src={Logo} w="16" />
            </Center>
            <Stack>
              <Text align="center" fontSize="lg" fontWeight="medium">
                {strings.listinfo_service_center}
              </Text>
            </Stack>
          </Stack>
          <Box bg="white" p="14" shadow="box" rounded="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="8">
                <Stack direction="column" spacing="4">
                  <Heading as="h1" size="xl">
                    {strings.forgot_password}
                  </Heading>
                  <Text color="gray.500">{strings.forgot_message}</Text>
                  {forgotMutation.isError && (
                    <ScaleFade in={forgotMutation.isError}>
                      <Alert status="error">
                        <AlertIcon />
                        {strings.email_not_found}
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>

                <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                  <FormLabel>{strings.email}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiEnvelope} />
                      }
                    />
                    <Input
                      id="email"
                      type="email"
                      placeholder={strings.your_email_address}
                      {...register('email', {
                        required: strings.email_required,
                        validate: (value) =>
                          validEmail(value) || strings.valid_email_address,
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.email && errors.email?.message}
                  </FormErrorMessage>
                </FormControl>
                <VStack spacing="6">
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={forgotMutation.isLoading}>
                    {strings.password_reset}
                  </Button>
                  <Link to={routes.auth.login}>
                    <Button
                      variant="link"
                      color="gray.400"
                      leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                      {strings.back_to_login}
                    </Button>
                  </Link>
                </VStack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default ForgotPassword;
