import { Box, List } from '@chakra-ui/react';
import {
  THEME_AERIAL_MAP,
  THEME_BMS,
  THEME_BS,
  THEME_CHAT,
  THEME_CONTACT_CENTER,
  THEME_CRM,
  THEME_DMS,
  THEME_FAQ,
  THEME_FEEDBACK,
  THEME_KEY,
  THEME_MDM,
  THEME_MMS,
  THEME_NEWS,
  THEME_OBJECT_DASHBOARD,
  THEME_OMS,
  THEME_PMS,
  THEME_PNS,
  THEME_RESTAURANT,
  THEME_SMS,
  THEME_THIRD_PARTY_PARTNER,
  THEME_TICKET,
  THEME_USER,
} from 'constants/common';
import { ThemeContext } from 'context/ThemeContext';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomTheme } from 'utils/CustomTheme';
import {
  BMSSubMenuList,
  BSSubMenuList,
  ChatSubMenuList,
  ContactCenterSubMenuList,
  CrmSubMenuList,
  DMSSubMenuList,
  FAQSubMenuList,
  FeedbackSubMenuList,
  KeySubMenuList,
  MdmSubMenuList,
  MmsSubMenuList,
  NewsletterSubMenuList,
  ObjectDashboardSubMenu,
  OmsSubMenuList,
  PMSSubMenuList,
  PNSSubMenuList,
  RestaurantSubMenuList,
  SMSSubMenuList,
  ThirdPartyPartnerSubMenuList,
  TicketSubMenuList,
  UserSubMenuList,
} from './SubMenus';

const SidebarSubMenu: React.FC = () => {
  const { setTheme } = useContext(ThemeContext);
  const location = useLocation();
  const theme = CustomTheme(location.pathname);

  useEffect(() => {
    setTheme(theme);
  }, [theme, setTheme]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]);

  return (
    <Box position="relative" width="max-content">
      {theme && (
        <List
          p="4"
          spacing="8"
          fontSize="xs"
          color="white"
          height={['115vh', '120vh']}>
          {theme === THEME_USER && <UserSubMenuList />}
          {theme === THEME_CHAT && <ChatSubMenuList />}
          {theme === THEME_TICKET && <TicketSubMenuList />}
          {theme === THEME_CRM && <CrmSubMenuList />}
          {theme === THEME_MMS && <MmsSubMenuList />}
          {theme === THEME_MDM && <MdmSubMenuList />}
          {theme === THEME_OMS && <OmsSubMenuList />}
          {theme === THEME_SMS && <SMSSubMenuList />}
          {theme === THEME_BMS && <BMSSubMenuList />}
          {theme === THEME_BS && <BSSubMenuList />}
          {theme === THEME_FAQ && <FAQSubMenuList />}
          {theme === THEME_DMS && <DMSSubMenuList />}
          {theme === THEME_PMS && <PMSSubMenuList />}
          {theme === THEME_PNS && <PNSSubMenuList />}
          {theme === THEME_CONTACT_CENTER && <ContactCenterSubMenuList />}
          {theme === THEME_FEEDBACK && <FeedbackSubMenuList />}
          {theme === THEME_NEWS && <NewsletterSubMenuList />}
          {theme === THEME_KEY && <KeySubMenuList />}
          {theme === THEME_AERIAL_MAP}
          {theme === THEME_OBJECT_DASHBOARD && <ObjectDashboardSubMenu />}
          {theme === THEME_THIRD_PARTY_PARTNER && (
            <ThirdPartyPartnerSubMenuList />
          )}
          {theme === THEME_RESTAURANT && <RestaurantSubMenuList />}
        </List>
      )}
    </Box>
  );
};

export default SidebarSubMenu;
