import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import CRMEventLogResource from 'api/crm-eventLogs';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import RecursiveInfoForm from 'components/ticket/RecursiveInfoForm';
import TicketForm from 'components/ticket/TicketForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { ReactSelectOptionSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { DateFormatYMD } from 'utils/DateFormat';

const TICKET_TYPE_NORMAL = 'normal';

interface TicketFormInputs {
  title: string;
  appointment_date: string;
  apartment_id: ReactSelectOptionSchema | null;
  assigned_user_id: ReactSelectOptionSchema | null;
  customer_id: ReactSelectOptionSchema | null;
  external_company_id: ReactSelectOptionSchema | null;
  cost_recipient: string;
  house_owner: ReactSelectOptionSchema | null;
  priority: string;
  due_date: Date;
  description: string;
  recursive_interval: string;
  recursive_from: string | null;
  recursive_until: string | null;
  is_billable: boolean;
  files: FileList | null;
  createdBy?: string;
  updatedBy?: string;
}

const defaultValues = {
  title: '',
  apartment_id: null,
  assigned_user_id: null,
  customer_id: null,
  cost_recipient: '',
  house_owner: null,
  priority: '',
  due_date: new Date(),
  description: '',
  recursive_interval: '',
  recursive_from: null,
  recursive_until: null,
  is_billable: false,
  files: null,
};

const CreateTicket: React.FC = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { ticketType } = location.state || TICKET_TYPE_NORMAL;
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isLargerThan1140] = useMediaQuery('(min-width: 1140px)');

  const ticketAPI = new TicketResource();
  const eventLogAPI = new CRMEventLogResource();

  const methods = useForm<TicketFormInputs>({ defaultValues });
  const { reset, setValue } = methods;
  const ticketForm = useRef() as React.MutableRefObject<HTMLFormElement>;
  const [isLoading, setIsLoading] = useState<string | null>();
  const [clearFileInputToggle, setClearFileInputToggle] =
    useState<boolean>(false); // Toggle this field to clear the file input (of child comp: Uploader)

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const createTicket = useMutation((data: any) => ticketAPI.store(data));
  const createEventLog = useMutation((data: any) => eventLogAPI.store(data));

  const uploadFiles = useMutation((formData: any) =>
    ticketAPI.uploadToBucket(formData)
  );

  const handleUploadFiles = async (files: any) => {
    const result: {
      file_name: any;
      mime_type: any;
      file_size: any;
      file_path: any;
    }[] = [];
    for (let index = 0; index < files.length; index += 1) {
      const formData = new FormData();
      formData.append('image', files[index]);
      const uploads: any = await uploadFiles.mutateAsync(formData);
      const { status, file_name, mime_type, file_size, file_path } =
        uploads?.data;
      if (status) {
        result.push({
          file_name: file_name,
          mime_type: mime_type,
          file_size: file_size,
          file_path: file_path,
        });
      }
    }
    return result;
  };

  const showActionButton = () => {
    return (
      <ButtonGroup>
        <Button
          colorScheme="primary"
          isLoading={isLoading === 'save'}
          isDisabled={isLoading === 'saveandcontinue' || uploadFiles.isLoading}
          type="button"
          onClick={methods.handleSubmit(handleCreateTicket)}>
          {strings.save_now}
        </Button>
        <Button
          colorScheme="primary"
          isLoading={isLoading === 'saveandcontinue'}
          isDisabled={isLoading === 'save' || uploadFiles.isLoading}
          onClick={methods.handleSubmit(handleCreateTicketAndContinue)}>
          {strings.save_and_create_new_ticket}
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            handleReset();
            history.goBack();
          }}
          type="button"
          isDisabled={isLoading === 'save' || isLoading === 'saveandcontinue'}>
          {strings.cancel}
        </Button>
      </ButtonGroup>
    );
  };

  /**
   * Remove keys if no data
   */
  const setFormData = (data: TicketFormInputs) => {
    let formData: any = {
      title: data.title,
      appointment_date: data.appointment_date,
      apartment_id: data.apartment_id?.value,
      assigned_user_id: data.assigned_user_id?.value,
      cost_recipient: data.cost_recipient,
      house_owner: data.house_owner?.value,
      customer_id: data.customer_id?.value,
      external_company_id: data.external_company_id?.value,
      due_date: data.due_date,
      description: data.description,
      is_billable: data.is_billable,
      priority: data.priority,
      files: data.files,
      created_by: loggedInUser.id,
      updated_by: loggedInUser.id,
    };

    if (ticketType !== TICKET_TYPE_NORMAL) {
      formData.recursive_interval = data.recursive_interval;
      formData.recursive_from = DateFormatYMD(data.recursive_from);
      formData.recursive_until = DateFormatYMD(data.recursive_until);
    }

    let cleanObj = {};
    Object.keys(formData).forEach((val: any) => {
      const newVal = formData[val];
      cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
    });
    return cleanObj;
  };

  /**
   * Actual save ticket
   */
  const saveTicket = (formData: any, whichBtnClick: string) => {
    createTicket.mutate(formData, {
      onSuccess: (res) => {
        toast({
          title: strings.ticket_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('tickets');
        //Create event log from here
        const eventLogData = {
          event: 'ticket',
          event_id: res.data.id,
          contact_id: res.data.customer_id,
          event_time: res.data.createdAt,
        };
        const eventLogDataOwner = {
          event: 'ticket',
          event_id: res.data.id,
          contact_id: formData.house_owner,
          event_time: res.data.createdAt,
        };

        if (formData.customer_id) {
          createEventLog.mutate(eventLogData, {});
        }
        if (formData.house_owner) {
          createEventLog.mutate(eventLogDataOwner, {});
        }

        whichBtnClick === 'saveNow'
          ? history.push(routes.ticket.task.board)
          : handleReset();
      },
      onError: () => {
        toast({
          title: strings.ticket_error,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: () => {
        setIsLoading(() => null);
      },
    });
  };

  /**
   * Create Ticket and redirect to index page
   */
  const handleCreateTicket = async (data: TicketFormInputs) => {
    setIsLoading(() => 'save');
    // upload to Bucket First
    let files: any = [];
    if (data.files && data.files?.length > 0) {
      files = await handleUploadFiles(data.files);
      data.files = files;
    }
    // Create Ticket
    let cleanData: any = setFormData(data);

    if (cleanData) {
      cleanData.created_by = loggedInUser.id;
      await saveTicket(cleanData, 'saveNow');
    }
  };

  /**
   * Create Ticket and Continue
   */
  const handleCreateTicketAndContinue = async (data: TicketFormInputs) => {
    setIsLoading(() => 'saveandcontinue');
    // upload to Bucket First
    let files: any = [];
    if (data.files && data.files?.length > 0) {
      files = await handleUploadFiles(data.files);
      data.files = files;
    }
    const cleanData: any = setFormData(data);
    cleanData && (await saveTicket(cleanData, 'saveAndContinue'));
  };

  /**
   * Reset inputs
   */
  const handleReset = () => {
    reset(defaultValues);
    setClearFileInputToggle((prevState) => !prevState);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.ticket} | {strings.create_ticket}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.ticket.task.board}>
              {strings.ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.ticket.task.create}>
              {strings.create_ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between" flexWrap="wrap">
          <Heading size="md" textTransform="capitalize">
            {strings.create_ticket}
          </Heading>
          {!isLargerThan1140 && showActionButton()}
        </Flex>

        <FormProvider {...methods}>
          <form ref={ticketForm}>
            <Box mb={4}>
              <Stack sx={wrapperStyles}>
                <TicketForm
                  isBillable={ticketType === TICKET_TYPE_NORMAL}
                  clearFileInputToggle={clearFileInputToggle}
                />
              </Stack>
            </Box>
            {ticketType !== TICKET_TYPE_NORMAL && <RecursiveInfoForm />}
          </form>
        </FormProvider>

        {isLargerThan1140 && showActionButton()}
      </Stack>
    </>
  );
};

export default CreateTicket;
