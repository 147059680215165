import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

const SettingList: React.FC = () => {
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;

  const queryClient = useQueryClient();

  const { register, handleSubmit } = useForm();
  const toast = useToast();
  const feedbackResourceAPI = new FeedbackResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: feedbackDaysSetting } = useQuery(
    [`feedbackDaysSettingList`],
    async () =>
      await feedbackResourceAPI
        .getFeedbackDaysSetting()
        .then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const updateFeedbackQuestionDays = useMutation((data: any) =>
    feedbackResourceAPI.setFeedbackDaysSetting(data)
  );

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    updateFeedbackQuestionDays.mutate(
      { setting: data },
      {
        onSuccess: () => {
          toast({
            title: `${strings.feedback} ${strings.days} ${strings.has_been_updated}`,
            status: 'success',
            isClosable: true,
          });
          setIsLoading(false);
          setErrMsg('');
          queryClient.invalidateQueries('feedbackDaysSettingList');
        },
        onError: () => {
          setErrMsg(
            `${strings.feedback} ${strings.days} ${strings.has_not_been_updated}`
          );
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.feedback} | {strings.setting}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
              {strings.feedback}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.feedback.setting.list}>
              {strings.setting}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.setting}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form ref={form} onSubmit={handleSubmit(onSubmit)}>
            {errMsg && (
              <Alert status="error">
                <AlertIcon />
                {errMsg}
              </Alert>
            )}

            <Stack direction={['column', 'row']} justifyContent="space-between">
              <Text>
                {
                  strings.how_many_days_after_departure_should_the_feedback_mail_be_sent
                }
              </Text>
              <FormControl w="10%">
                <Input
                  type="number"
                  {...register('email_notification_days', {
                    required: strings.required,
                    validate: (value) => {
                      if (value?.trim() === '') return strings.required;
                    },
                  })}
                  defaultValue={
                    feedbackDaysSetting?.settings?.email_notification_days
                  }
                  rounded="sm"
                />
              </FormControl>
            </Stack>
            <ButtonGroup pt="2">
              <Button colorScheme="primary" type="submit" isLoading={isLoading}>
                {strings.save_now}
              </Button>
              <RouterLink to={routes.feedback.feedbacks.list}>
                <Button variant="outline" isDisabled={isLoading}>
                  {strings.cancel}
                </Button>
              </RouterLink>
            </ButtonGroup>
          </form>
        </Stack>
      </Stack>
    </>
  );
};

export default SettingList;
