import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PartnerGoodiesResource from 'api/partner_goodies';
import { strings } from 'config/localization';
import { PartnerGoodiesSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { cleanData } from 'utils';
import PartnerGoodiesForm from './PartnerGoodiesForm';

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  partnerGoodiesData?: PartnerGoodiesSchema;
  thirdPartyPartnerId?: string;
}

const PartnerGoodiesModal = ({
  isOpen,
  handleCancel,
  partnerGoodiesData,
  thirdPartyPartnerId,
}: Props) => {
  const methods = useForm<any>();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let partnerGoodiesApi = new PartnerGoodiesResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const createPartnerGoodies = useMutation(
    (data: any) => partnerGoodiesApi.store(data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries();
        handleCancel();
        methods.reset();
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const updatePartnerGoodies = useMutation(
    (data: any) => partnerGoodiesApi.update(partnerGoodiesData?.id, data),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries();
        handleCancel();
        methods.reset();
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const onSubmit = (formData: any) => {
    let API;
    let data;
    if (partnerGoodiesData) {
      API = updatePartnerGoodies;
      data = { ...formData };
    } else {
      API = createPartnerGoodies;
      data = cleanData(formData);
    }
    data.third_party_partner_id = thirdPartyPartnerId;
    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.extra_goodies} ${
            partnerGoodiesData
              ? strings.has_been_updated
              : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(
          `${strings.contact} ${
            partnerGoodiesData
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };
  return (
    <Modal isOpen={isOpen} isCentered onClose={handleCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{strings.extra_goodies}</ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                <PartnerGoodiesForm data={partnerGoodiesData} />
                <ButtonGroup>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={isLoading}>
                      {!partnerGoodiesData ? strings.add_now : strings.update}
                    </Button>
                    <Button
                      variant="outline"
                      isDisabled={isLoading}
                      onClick={handleCancel}>
                      {strings.cancel}
                    </Button>
                  </ButtonGroup>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PartnerGoodiesModal;
