import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import NewsletterAPI from 'api/newsletter';
import ComposeNewsletterForm from 'components/newsletter/ComposeNewsletterForm';
import { strings } from 'config/localization';
import { NEWSLETTER_STATUS_CONFIRMED } from 'constants/common';
import routes from 'constants/routes';
import { NewsletterForm } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import history from 'utils/history';

const ComposeMessage: React.FC = (props) => {
  const newsletterAPI = new NewsletterAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const [errMsg, setErrMsg] = useState('');
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm<NewsletterForm>();
  const queryClient = useQueryClient();
  const { id: newsletterId }: any = useParams();
  const [preview, setPreview] = useState<boolean>(false);

  const { data: newsletterDetail } = useQuery(
    ['newsletter-query', newsletterId],
    () => {
      if (!newsletterId) return null;
      return newsletterAPI
        .get(newsletterId)
        .then((res) => res.data.data)
        .catch();
    }
  );

  const composeNewsletter = useMutation((data: any) =>
    newsletterAPI.store(data)
  );

  const uploadFiles = useMutation((formData: any) =>
    newsletterAPI.uploadToBucket(formData)
  );

  const handleUploadFiles = async (files: any) => {
    const result: {
      file_id: string;
      file_name: string;
      mime_type: string;
      file_size: string;
      file_path: string;
    }[] = [];

    for (let index = 0; index < files.length; index += 1) {
      const formData = new FormData();
      formData.append('image', files[index], files[index].name);

      const uploads: any = await uploadFiles.mutateAsync(formData);
      if (uploads.status === 201) {
        const { file_id, file_name, mime_type, file_size, file_path } =
          uploads.data;

        result.push({
          file_id: file_id,
          file_name: file_name,
          file_path: file_path,
          file_size: file_size,
          mime_type: mime_type,
        });
      }
    }
    return result;
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    if (data?.file && data?.file?.length > 0) {
      let uploadedFiles = await handleUploadFiles(data.file);
      let uploadedFilesIds = uploadedFiles.map((file: any) => file.file_id);
      data.file = uploadedFilesIds;
    }

    composeNewsletter.mutate(data, {
      onSuccess: () => {
        const newsletterStatus = data.status;
        const confirmedNewsletterRoute = routes.newsletter.newsletters.list;
        const draftNewsletterRoute = routes.newsletter.draft.list;
        let selectRoute: string, successMsg: string, listToRedirect: string;

        if (newsletterStatus === NEWSLETTER_STATUS_CONFIRMED) {
          selectRoute = confirmedNewsletterRoute;
          successMsg = strings.newsletter_sent_sucessfully;
          listToRedirect = 'newsLetterList';
        } else {
          selectRoute = draftNewsletterRoute;
          successMsg = strings.newsletter_saved_to_draft_sucessfully;
          listToRedirect = 'newsLetterDraftList';
        }
        queryClient.invalidateQueries(listToRedirect);
        toast({
          title: successMsg,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(selectRoute);
      },
      onError: () => {
        setErrMsg(`${strings.newsletter} error `);
        setIsLoading(false);
      },
    });
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.newsletter} | &nbsp;
          {preview ? strings.preview_message : strings.new_newsletter}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={RouterLink}
            to={routes.newsletter.newsletters.list}>
            {strings.newsletters}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink onClick={() => setPreview(false)}>
            {strings.new_newsletter}
          </BreadcrumbLink>
        </BreadcrumbItem>
        {preview && (
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.preview_message}</BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {preview ? strings.preview_message : strings.compose_message}
        </Heading>
      </Flex>
      <FormProvider {...methods}>
        <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          <ComposeNewsletterForm
            data={newsletterDetail}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </form>
      </FormProvider>
    </Stack>
  );
};

export default ComposeMessage;
