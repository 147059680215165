import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PMSInvoiceResource from 'api/pms_invoice';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PMSCreditCardListItem from 'components/pms/PMSCreditCardListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  PMS_STATUS_OPTIONS,
  REACT_DATE_PICKER_FORMAT,
} from 'constants/common';
import routes from 'constants/routes';
import {
  DataWrapperSchema,
  PaymentSchema,
  PAYMENT_STATUS,
} from 'constants/schema';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: any;
  date_time: Date | null;
  status: PAYMENT_STATUS | '';
  amount: string;
}

const InvoiceList: React.FC = () => {
  const pmsCreditCardAPI = new PMSInvoiceResource();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const date_time = Number(searchValues.date_time);

  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    date_time: date_time ? new Date(date_time) : null,
    status: searchValues.status ?? '',
    amount: searchValues.amount ?? '',
  });

  const pmsInvoiceList = useQuery<DataWrapperSchema<PaymentSchema[]>>(
    [
      'pms-invoice-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        date_time: filterParams.date_time,
        status: filterParams.status,
        amount: filterParams.amount,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;
      if (filterParams.date_time)
        queryParams.date_time = DateFormatYMD(filterParams.date_time);
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.amount) queryParams.amount = filterParams.amount;

      const response = await pmsCreditCardAPI.list(queryParams);
      return response?.data;
    }
  );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Date) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      date_time: date,
    }));
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      date_time: null,
      status: '',
      amount: '',
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    const { date_time } = data;
    if (date_time) data.date_time = Date.parse(date_time);
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.pms} | {strings.invoice_payment}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.pms.invoice.list}>
              {strings.pms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.pms.invoice.list}>
              {strings.invoice_payment}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.invoice_payment}
          </Heading>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          defaultIndex={[defaultIndex]}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="16px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack>
                <Stack sx={wrapperStyles}>
                  <form autoComplete="off">
                    <Stack direction="row" spacing="4" align="end">
                      <Grid
                        gap="4"
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(4, 1fr)',
                        ]}
                        flex="1">
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.search}</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                children={<BiSearch />}
                                color="gray.400"
                              />
                              <Input
                                name="keyword"
                                value={filterParams.keyword}
                                type="text"
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.date_time}</FormLabel>
                            <InputGroup>
                              <InputRightElement
                                children={<BiCalendar />}
                                color="gray.400"
                              />
                              <ReactDatePicker
                                dateFormat={REACT_DATE_PICKER_FORMAT}
                                placeholderText={strings.select_date}
                                value={DateFormatYMD(filterParams.date_time)}
                                selected={filterParams.date_time}
                                customInput={<CustomDateInput />}
                                onChange={handleDateChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.status}</FormLabel>
                            <Select
                              placeholder={strings.status}
                              rounded="sm"
                              name="status"
                              value={filterParams.status}
                              onChange={handleInputChange}>
                              {PMS_STATUS_OPTIONS?.map((status) => {
                                return (
                                  <option
                                    key={status.value}
                                    value={status.value}>
                                    {strings[status.text]}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>
                              {strings.amount}({strings.euro_symbol})
                            </FormLabel>
                            <Input
                              type="text"
                              name="amount"
                              value={filterParams.amount}
                              onChange={handleInputChange}
                              placeholder={strings.amount}
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </Stack>

                    <Stack direction="row" spacing="4" pt="4">
                      <Button
                        colorScheme="primary"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.customer_name}</Th>
                  <Th isNumeric>{strings.date_time}</Th>
                  <Th>{strings.status}</Th>
                  <Th isNumeric>
                    {strings.amount}({strings.euro_symbol})
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {pmsInvoiceList?.data?.data?.map((pmsCreditCardData, index) => (
                  <PMSCreditCardListItem
                    key={pmsCreditCardData.id}
                    index={startingSN + index}
                    pmsCreditCardData={pmsCreditCardData}
                    isCreditCardPage={false}
                  />
                ))}

                {pmsInvoiceList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={pmsInvoiceList}
        />
      </Stack>
    </>
  );
};

export default InvoiceList;
