import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import { BiTrash } from 'react-icons/bi';

interface Props {
  modalHeader?: string;
  modalBody?: string;
  onDelete: () => void;
  isLoading: boolean;
}

export default function DeleteButton(props: Props) {
  const {
    modalHeader = strings.delete,
    modalBody = strings.confirm_delete,
    isLoading,
    onDelete,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip hasArrow label={strings.delete}>
        <IconButton
          icon={<BiTrash />}
          variant="link"
          aria-label={strings.delete}
          color="red.300"
          minW="6"
          onClick={onOpen}
        />
      </Tooltip>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalBody}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={onDelete}
                isLoading={isLoading}>
                {strings.delete}
              </Button>
              <Button variant="outline" onClick={onClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
