import BookingDash from 'components/object-dashboard/dashboard/booking/BookingDash';
import FeedbackDash from 'components/object-dashboard/dashboard/feedback/FeedbackDash';
import ObjectDash from 'components/object-dashboard/dashboard/object/ObjectDash';
import OwnerDash from 'components/object-dashboard/dashboard/owner/OwnerDash';
import TicketDash from 'components/object-dashboard/dashboard/ticket/TicketDash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  setTitle: any;
}

const Objects: React.FC<Props> = (props) => {
  const { setTitle } = props;
  const { id } = useParams<{ id: string; dashboard: string }>();
  let resourceId = Number(id);

  const [vOfficeOwnerId, setVOfficeOwnerId] = useState<null | number>(null);

  return (
    <>
      <ObjectDash
        setTitle={setTitle}
        resourceId={resourceId}
        setVOfficeOwnerId={setVOfficeOwnerId}
      />
      <BookingDash dashboardType="object" resourceId={resourceId} />
      <TicketDash dashboardType="object" resourceId={resourceId} />
      <OwnerDash vOfficeOwnerId={vOfficeOwnerId} />
      <FeedbackDash dashboardType="object" resourceId={resourceId} />
    </>
  );
};

export default Objects;
