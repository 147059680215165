import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  recursiveInterval: any;
  recursiveFrom: any;
  recursiveUntil: any;
}

const RecursiveInfo: React.FC<Props> = (props) => {
  const { recursiveInterval, recursiveFrom, recursiveUntil } = props;

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.repeating_info}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Stack direction="row" spacing="4">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.recursive_interval}
                </FormLabel>
                <Box sx={infoStyles}>
                  {strings.getString(
                    recursiveInterval.toLowerCase().split('-').join('_')
                  )}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.recursive_start}
                </FormLabel>
                <Box sx={infoStyles}>{DateFormat(recursiveFrom)}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  {strings.recursive_untill}
                </FormLabel>
                <Box sx={infoStyles}>{DateFormat(recursiveUntil)}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};
export default RecursiveInfo;
