import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CustomerDocumentResource from 'api/customer_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomerDocumentForm from 'components/customer_document/CustomerDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { default as React } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

const EditCustomersDocument: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<any>();

  const customerDocumentAPI = new CustomerDocumentResource();

  const customerDocumentQuery = useQuery(
    [`customer-document${id}-edit`, id],
    () => customerDocumentAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const updateCustomerDocument = useMutation((data: any) =>
    customerDocumentAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    data._method = 'PUT';
    data.customer_id = data.customer_id?.value;
    updateCustomerDocument.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: strings.general_document_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('customerDocumentList');
        history.goBack();
      },
    });
  };

  if (customerDocumentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (customerDocumentQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.general_document_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.dms} | {strings.edit_document_customer}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.generalList.list}>
              {strings.dms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.customerDocuments.list}>
              {strings.document_customers}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.dms.customerDocuments.customer.list.replace(
                ':id',
                customerDocumentQuery?.data?.data?.customer?.id?.toString()
              )}>
              {customerDocumentQuery?.data?.data?.customer?.full_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>
              {customerDocumentQuery?.data?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md">{strings.edit_document_customer}</Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {updateCustomerDocument.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.general_document_udpate_failed}
                  </Alert>
                )}
                <CustomerDocumentForm
                  customerDocumentData={customerDocumentQuery.data.data}
                />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={updateCustomerDocument.isLoading}>
                    {strings.save_now}
                  </Button>
                  <Button variant="outline" onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditCustomersDocument;
