import { Button, ButtonGroup } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import Icon from '@chakra-ui/icon';
import { Box, Divider, Flex, Stack, Text } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import RMSNotesResource from 'api/rms-notes';
import { strings } from 'config/localization';
import { NotesSchema } from 'constants/schemas/objektSchema';
import React, { useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { TiDeleteOutline } from 'react-icons/ti';
import { useMutation, useQueryClient } from 'react-query';
import { DateFormat, TimeFormatHMA } from 'utils/DateFormat';
import NoteForm from './NoteForm';

interface Props {
  objectId: number;
  note: NotesSchema;
}

const labelStyle = {
  fontSize: 'sm',
  fontWeight: 500,
  color: 'paragraph',
};

const Note: React.FC<Props> = (props) => {
  const { note, objectId } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const display = useBreakpointValue({
    md: 'flex',
    lg: 'none',
  });

  const noteApi = new RMSNotesResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const updatedBy = note.user?.name;
  const updateOn = note?.updated_at
    ? `${DateFormat(note.updated_at)}, ${TimeFormatHMA(note.updated_at)}`
    : '-';

  const apartmentDetailKey = `apartmentDetails-${objectId}`;

  const deleteNote = useMutation(() => noteApi.destroy(note.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(apartmentDetailKey);
      toast({
        status: 'success',
        title: strings.successfully_deleted_note,
      });
      setOpenDeleteModal(false);
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
      });
    },
  });

  return (
    <Stack
      direction="column"
      borderWidth="2px"
      borderColor="white.100"
      role="group"
      fontFamily="heading"
      _hover={{
        backgroundColor: 'blue.10',
      }}>
      <Box height={['200px', '120px', '180px']} padding="0.5rem">
        <Text
          fontSize="md"
          fontWeight="400"
          color="heading"
          fontFamily="heading"
          padding="0.5rem">
          {note.description}
        </Text>
      </Box>
      <Stack direction="column" spacing="1rem">
        <Divider />
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Box padding="0.5rem">
            <Text sx={labelStyle}>
              {strings.latest_update}: {updateOn}
            </Text>
            <Text sx={labelStyle}>
              {strings.updated_by}: {updatedBy}
            </Text>
          </Box>
          <Flex
            direction="row"
            display={display}
            _groupHover={{
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
            }}>
            <Tooltip hasArrow label={strings.edit}>
              <Box mr="1rem">
                <AiOutlineEdit fontSize="1.4rem" onClick={onOpen} />
              </Box>
            </Tooltip>
            <Tooltip hasArrow label={strings.delete}>
              <Box mr="0.5rem">
                <AiOutlineDelete
                  fontSize="1.4rem"
                  color="red"
                  onClick={() => setOpenDeleteModal(true)}
                />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Stack>
      <NoteForm
        isOpen={isOpen}
        onClose={onClose}
        objectID={objectId}
        note={note}
      />
      <Modal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        isCentered
        size="2xl">
        <ModalOverlay />
        <ModalContent padding="1rem">
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="column" alignItems="center" width="100%">
              <Icon as={TiDeleteOutline} fontSize="3rem" color="red.500" />
              <Text fontSize="3xl" color="heading" fontWeight="400">
                {strings.are_you_sure}
              </Text>
              <Text fontSize="lg">
                {
                  strings.do_you_really_want_to_delete_this_note_this_cannot_be_undone
                }
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup width="100%" justifyContent="center" spacing="1rem">
              <Button
                colorScheme="red"
                onClick={() => deleteNote.mutate()}
                isLoading={deleteNote.isLoading}
                isDisabled={deleteNote.isLoading}>
                {strings.delete}
              </Button>
              <Button
                variant="outline"
                colorScheme="primary"
                onClick={() => setOpenDeleteModal(false)}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Note;
