import { CustomerDocumentEmailPayloadInterface } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

class DocumentDashboardResource extends Resource {
  constructor() {
    super(`dms/dashboard`);
  }

  dashboardCustomerDocuments(id: number, query: any = {}) {
    return http({
      url: `${this.uri}/customer/${id}/customer-document`,
      method: 'GET',
      params: query,
    });
  }

  dashboardOwnerDocument(owner_id: number, query: any = {}) {
    return http({
      url: `${this.uri}/owner/${owner_id}/owner-document`,
      method: 'GET',
      params: query,
    });
  }

  dashboardApartmentDocuments(apartmentId: number, query: any = {}) {
    return http({
      url: `${this.uri}/apartment/${apartmentId}/object-document`,
      method: 'GET',
      params: query,
    });
  }

  dashboardEmailDocuments(data: CustomerDocumentEmailPayloadInterface) {
    return http({
      url: `${this.uri}/sent-email/customer-document`,
      method: 'POST',
      data: data,
    });
  }
}

export { DocumentDashboardResource as default };
