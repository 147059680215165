import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import WarehouseResource from 'api/warehouses';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import RoomsAccordion from 'components/warehouse/RoomsAccordion';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

const ViewWarehouse: React.FC = () => {
  const { id }: any = useParams();
  const warehouseAPI = new WarehouseResource();
  const { search } = useLocation();
  // Access Control
  const checkEditWarehousePermission = useCheckPermission(
    [PermissionRequest['read:mms'], PermissionRequest['manage:article']],
    routes.mms.warehouse.edit.replace(':id', id.toString())
  );
  const {
    isLoading,
    isError,
    data: warehouseData,
  } = useQuery([`warehouse${id}`, id], () =>
    warehouseAPI.get(id).then((res) => res.data?.data)
  );

  if (isLoading) {
    return <CenterSpinner />;
  }

  if (isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.warehouse_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.mms} | {warehouseData?.name}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.mms.inventory.list}>
              {strings.mms}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.mms.warehouse.list + search}>
              {strings.warehouse}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{warehouseData?.name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.warehouse_details}
          </Heading>
          <Stack direction="row">
            <Button
              type="button"
              colorScheme="primary"
              size="sm"
              onClick={checkEditWarehousePermission}>
              {strings.edit_warehouse}
            </Button>
          </Stack>
        </Flex>

        <Stack sx={wrapperStyles}>
          <Stack direction="column" spacing="8">
            <Stack direction="row" spacing="20">
              <Box minWidth="10rem">
                <Text fontWeight="medium">{strings.warehouse_name}</Text>
              </Box>
              <Box maxWidth="35rem">
                <Text>{warehouseData.name}</Text>
              </Box>
            </Stack>
            <Stack direction="row" spacing="20">
              <Box minWidth="10rem">
                <Text fontWeight="medium">{strings.description}</Text>
              </Box>
              <Text maxWidth="35rem">{warehouseData.description}</Text>
            </Stack>
            <RoomsAccordion
              roomsInput={warehouseData.rooms}
              viewOnly={true}
              showQR={true}
              warehouseName={warehouseData.name}
              warehouseId={warehouseData.id}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewWarehouse;
