import { Td, Text, Tr } from '@chakra-ui/react';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link as RouterLink } from 'react-router-dom';
import { utcToLocal } from 'utils/DateFormat';
interface Props {
  logData: any;
  index: number;
}

const TransferLogItems: React.FC<Props> = (props) => {
  const { logData, index } = props;

  /**
   * Display endpoint (source or destination)
   * Source can be either Fresh, Warehouse, Employee
   * Destination can be either Fresh, Warehouse, Employee
   *
   * Fresh, Consumed and Employee have same data format
   *
   */
  const displaySourceOrDestination = (endpoint: any) => {
    if (!endpoint) return '-';
    // Fresh / Consumed / Employee
    if (endpoint.name) {
      if (endpoint.name === 'Fresh') return strings.fresh;
      if (endpoint.name === 'Consumed') return strings.consumed;
      return endpoint.name;
    }
    // Warehouse
    const isWarehouseInactive = endpoint.warehouse?.status === 0;
    return (
      <Text
        color={
          isWarehouseInactive ? 'gray.300' : 'gray.900'
        }>{`${endpoint.warehouse?.name} / ${endpoint.room?.name} / ${endpoint.rack?.name} / ${endpoint.shelf?.name}`}</Text>
    );
  };

  const handleActionType = (action: string, action_id: number) => {
    if (action === 'Ticket' && action_id) {
      return (
        <RouterLink
          to={routes.ticket.task.view.replace(
            ':id',
            logData?.action_id.toString()
          )}>
          {action}
        </RouterLink>
      );
    }
    return action;
  };

  return (
    <Tr key={index}>
      <Td>{index}</Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={logData.article_status}
          name={logData.article_name || '-'}
        />
      </Td>
      <Td>{displaySourceOrDestination(logData.source)}</Td>
      <Td>{displaySourceOrDestination(logData.destination)}</Td>
      <Td isNumeric>{logData.transferred_quantity}</Td>
      <Td>
        {logData.transferred_by?.name ? logData.transferred_by.name : '-'}
      </Td>
      <Td isNumeric>
        {utcToLocal(logData.transferred_date).replace(' ', ', ')}
      </Td>
      <Td>{handleActionType(logData.action_type, logData.action_id)}</Td>
    </Tr>
  );
};

export default TransferLogItems;
