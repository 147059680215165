import { Td, Tr } from '@chakra-ui/react';
import { DrivingOrderSchema } from 'constants/schema';
import React from 'react';

interface Props {
  drivingOrderData: DrivingOrderSchema;
  index: number;
  draggableProvider: any;
}
const DrivingOrderListItem: React.FC<Props> = (props) => {
  const { drivingOrderData, index, draggableProvider } = props;

  const getAddress = () => {
    return `${drivingOrderData?.apartment?.info?.address?.housenumber} ${drivingOrderData?.apartment?.info?.address?.street}`;
  };

  return (
    <Tr
      key={drivingOrderData.id}
      ref={draggableProvider.innerRef}
      {...draggableProvider.draggableProps}
      {...draggableProvider.dragHandleProps}>
      <Td>{index}</Td>
      <Td>{drivingOrderData?.apartment?.apartment_name}</Td>
      <Td>{getAddress()}</Td>
    </Tr>
  );
};

export default DrivingOrderListItem;
