import { Grid, GridItem, Stack, Text } from '@chakra-ui/layout';
import { SystemProps, useDisclosure, useToast } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { NotesSchema } from 'constants/schemas/objektSchema';
import React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { useParams } from 'react-router';
import Note from './Note';
import NoteForm from './NoteForm';

interface Props {
  notes: NotesSchema[] | null;
  gridTemplateColumns?: SystemProps['gridTemplateColumns'];
}

const NotesPanel: React.FC<Props> = (props) => {
  const {
    notes,
    gridTemplateColumns = [
      'repeat(1,1fr)',
      'repeat(1,1fr)',
      'repeat(2,1fr)',
      'repeat(2,1fr)',
      'repeat(3,1fr)',
      'repeat(4,1fr)',
    ],
  } = props;
  const { id } = useParams<{ id: string }>();
  const objectID = Number(id);
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleAddNote = () => {
    if (notes && notes?.length >= 15) {
      toast({
        status: 'info',
        title: strings.maximum_notes_range_reached,
      });
      return;
    }
    onOpen();
  };

  return (
    <Grid
      gridTemplateColumns={gridTemplateColumns}
      gap="1%"
      padding="1%"
      overflowY="auto"
      maxH="40vh">
      <GridItem>
        <Stack
          direction="column"
          height={['250px', '200px', '255px']}
          justifyContent="center"
          alignItems="center"
          spacing="1rem"
          borderWidth="3px"
          borderStyle="dashed"
          borderColor="white.100"
          color="blue.400"
          onClick={handleAddNote}
          _hover={{
            cursor: 'pointer',
            backgroundColor: 'blue.10',
          }}>
          <IoMdAdd fontSize="2rem" />
          <Text as="span" color="blue.400" fontSize="2xl">
            {strings.add_note}
          </Text>
        </Stack>
      </GridItem>
      {notes?.map((item) => (
        <GridItem key={item.id}>
          <Note objectId={objectID} note={item} />
        </GridItem>
      ))}
      <NoteForm isOpen={isOpen} onClose={onClose} objectID={objectID} />
    </Grid>
  );
};

export default NotesPanel;
