/**
 * Rooms, racks and shelves input for particular warehouse
 * These fields are required only if warehouse is selected as destination source type
 *
 */

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from '@chakra-ui/react';
import WarehouseResource from 'api/warehouses';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { STOCK_ACTIVITIES_ENTITIES } from 'constants/common';
import {
  RackItemSchema,
  RoomItemSchema,
  ShelfItemSchema,
  TransferItemSchema,
} from 'constants/schema';
import React, { useEffect } from 'react';
import {
  Control,
  Controller,
  FieldError,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const { WAREHOUSE_SHELVES } = STOCK_ACTIVITIES_ENTITIES;

function DestinationWarehouseInputs({
  control,
}: {
  control: Control<TransferItemSchema>;
}) {
  const {
    formState: { errors },
    reset,
    getValues,
  } = useFormContext<TransferItemSchema>();

  const warehouseAPI = new WarehouseResource();

  const destinationEntityType = useWatch({
    control,
    name: 'destination_entity_type',
  });

  // destinationEntityId is warehouseId when warehouse is selected
  const destinationEntityId = useWatch({
    control,
    name: 'destination_entity_id',
  });

  const roomId = useWatch({
    control,
    name: 'room_id',
  });

  const rackId = useWatch({
    control,
    name: 'rack_id',
  });

  // Reset room, rack and shelf when user changes the warehouse
  useEffect(() => {
    reset({
      ...getValues(),
      room_id: null,
      rack_id: null,
      shelf_id: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationEntityId?.value]);

  // Reset rack and shelf when user changes the room
  useEffect(() => {
    reset({
      ...getValues(),
      rack_id: null,
      shelf_id: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId?.value]);

  // Reset shelf when user changes the rack
  useEffect(() => {
    reset({
      ...getValues(),
      shelf_id: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rackId?.value]);

  // Fetch rooms for given warehouse
  const { data: rooms } = useQuery(
    ['warehouseRooms', { warehouseId: destinationEntityId }],
    async () => {
      if (!destinationEntityId?.value) return;
      const response = await warehouseAPI.fetchRooms(destinationEntityId.value);
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!(
        destinationEntityType === WAREHOUSE_SHELVES &&
        destinationEntityId?.value
      ),
    }
  );

  // Fetch racks for given room
  const { data: racks } = useQuery(
    ['warehouseRacks', { roomId: roomId }],
    async () => {
      if (!roomId?.value) return;
      const response = await warehouseAPI.fetchRacks(roomId.value);
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!(destinationEntityType === WAREHOUSE_SHELVES && roomId?.value),
    }
  );

  // Fetch shelves for given rack
  const { data: shelves } = useQuery(
    ['warehouseShelves', { rackId: rackId }],
    async () => {
      if (!rackId?.value) return;
      const response = await warehouseAPI.fetchShelves(rackId.value);
      return response?.data?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!(destinationEntityType === WAREHOUSE_SHELVES && rackId?.value),
    }
  );

  const isDestinationTypeWarehouse =
    destinationEntityType === WAREHOUSE_SHELVES;

  if (!isDestinationTypeWarehouse) return <></>;

  const roomOptions = rooms
    ? rooms.map((item: RoomItemSchema) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  const rackOptions = racks
    ? racks.map((item: RackItemSchema) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  const shelfOptions = shelves
    ? shelves.map((item: ShelfItemSchema) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  return (
    <Stack direction="row">
      <FormControl isInvalid={!!errors?.room_id} isRequired>
        <FormLabel>{strings.room}</FormLabel>
        <Controller
          control={control}
          name="room_id"
          rules={{
            required: isDestinationTypeWarehouse
              ? strings.required_room
              : false,
          }}
          render={({ field }) => (
            <ReactSelect
              {...field}
              styles={reactSelectStyles}
              options={roomOptions}
              isSearchable
              tabIndex="7"
            />
          )}
        />
        <FormErrorMessage>
          {errors?.room_id && (errors.room_id as FieldError)?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.rack_id} isRequired>
        <FormLabel>{strings.rack}</FormLabel>
        <Controller
          control={control}
          name="rack_id"
          rules={{
            required: isDestinationTypeWarehouse
              ? strings.required_rack
              : false,
          }}
          render={({ field }) => (
            <ReactSelect
              {...field}
              styles={reactSelectStyles}
              options={rackOptions}
              isSearchable
              tabIndex="8"
            />
          )}
        />
        <FormErrorMessage>
          {errors?.rack_id && (errors.rack_id as FieldError)?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.shelf_id} isRequired>
        <FormLabel>{strings.shelf}</FormLabel>
        <Controller
          control={control}
          name="shelf_id"
          rules={{
            required: isDestinationTypeWarehouse
              ? strings.required_shelf
              : false,
          }}
          render={({ field }) => (
            <ReactSelect
              {...field}
              styles={reactSelectStyles}
              options={shelfOptions}
              isSearchable
              tabIndex="9"
            />
          )}
        />
        <FormErrorMessage>
          {errors?.shelf_id && (errors.shelf_id as FieldError)?.message}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  );
}

export default DestinationWarehouseInputs;
