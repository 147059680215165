import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react';
import { setLoggedInUser } from 'actions/data/auth';
import { getAuthPermission, getAuthUser } from 'api/auth';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import AlertBox from 'components/alert/AlertBox';
import { CenterSpinner } from 'components/common/CenterSpinner';
import UserPermissionDisplay from 'components/common/UserPermissionDisplay';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { BiImageAdd, BiTrash } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { capitalizeFirstLetter, maskPhone } from 'utils';
import { validateFile, validateFileSize } from 'utils/validate';
import TwoFactorAuthentication from './TwoFactorAuthentication';

const MyProfile: React.FC = () => {
  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  /**
   * Referencing visually hidden input to upload button.
   */
  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * Loading state for upload and delete.
   */
  const [isLoading, setIsLoading] = useState<string | null>();

  const userApi = new UserResource();

  const userQuery = useQuery<UserSchema>(`user-auth`, () =>
    getAuthUser().then((res) => res.data.data)
  );

  /**
   * Dispatch the current changes to the store.
   */

  const dispatch = useDispatch();
  useEffect(() => {
    userQuery?.data && dispatch(setLoggedInUser(userQuery?.data));
  }, [userQuery, dispatch]);

  /**
   * fetching the Auth permission
   */

  const userAuthPermissions = useQuery('auth-permission', () =>
    getAuthPermission().then((res) => res.data.data)
  );
  const listItemStyles = {
    d: 'flex',
    mb: '4',
  };

  const titleStyles = {
    minW: '40',
    color: 'gray.900',
    fontWeight: 'bold',
  };

  const descriptionStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };
  if (userQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  /**
   * Event handlers for file uploading.
   */
  const handleUploadButton = () => {
    fileInputRef?.current?.click();
  };

  /**
   * delete files and refetch the userQuery.
   */
  const clearFile = async () => {
    setIsLoading(() => 'delete');
    try {
      await userApi.deleteProfile();
      userQuery.refetch();
    } catch (error) {
      return;
    } finally {
      setIsLoading(() => null);
      onClose();
    }
  };

  /**
   * uploads file on bucket and refetches userQuery on success.
   */

  const handleProfileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    if (!e.target.files) return;
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('profile_picture', file);
    /**
     * validates the file type and size.
     */
    const validateFileType: boolean = validateFile(file?.type);
    const validateSize: boolean = validateFileSize(file?.size);

    if (validateFileType && validateSize) {
      setIsLoading(() => 'upload');
      try {
        await userApi.uploadProfile(formData);
        userQuery.refetch();
      } catch (error) {
        return;
      } finally {
        setIsLoading(() => null);
      }
    }
    if (!validateFileType) {
      toast({
        title: strings.invalid_profile_upload,
        description: strings.file_must_be_an_image_file,
        status: 'error',
        isClosable: true,
      });
    }
    /**
     * shows toast only if file type is valid.
     */
    if (!validateSize && validateFileType) {
      toast({
        title: strings.invalid_file_size,
        description: strings.file_size_info,
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack direction="column" spacing="6">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.profile.myProfile}>
              {strings.my_profile}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.my_profile}
          </Heading>
          <Flex justify="space-between" alignItems="center">
            <Stack>
              <TwoFactorAuthentication userInfo={userQuery?.data} />
            </Stack>
            <Stack ml="4">
              <RouterLink to={routes.profile.edit}>
                <Button size="sm" colorScheme="primary">
                  {strings.edit_profile}
                </Button>
              </RouterLink>
            </Stack>
          </Flex>
        </Flex>
        <Stack direction="row" spacing="4">
          <Box bg="white" shadow="box" p={['3', '6']} rounded="sm" maxH="220px">
            <Avatar
              boxSize="180px"
              src={userQuery?.data?.profile_picture_url}
              rounded="full"
              boxShadow="sm"
              bg="primary.50"
              p="2">
              <IconButton
                rounded="full"
                position="absolute"
                _hover={{ bg: 'primary.400', color: 'white' }}
                right="4"
                top="4"
                size="sm"
                aria-label={strings.upload_label}
                icon={<BiImageAdd />}
                onClick={handleUploadButton}
              />
              <VisuallyHidden>
                <Input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleProfileUpload}
                />
              </VisuallyHidden>

              <IconButton
                rounded="full"
                position="absolute"
                size="sm"
                disabled={!userQuery?.data?.profile_picture_url}
                _hover={{ bg: 'red.300', color: 'white' }}
                right="4"
                bottom="4"
                aria-label={strings.remove_label}
                icon={<BiTrash />}
                onClick={onOpen}
              />
            </Avatar>
            {/**
             * Alert for file deletion.
             */}
            <AlertBox
              isOpen={isOpen}
              onClose={onClose}
              isLoading={isLoading === 'delete'}
              onDelete={clearFile}
              alertDialogHeader={strings.clear_profile_pic}
              alertDialogBody={strings.are_you_sure_you_cannot_undo_this_action}
            />
            {/**
             * Modal is for showing image upload progress
             */}
            <Modal
              isOpen={isLoading === 'upload'}
              onClose={() => setIsLoading(() => null)}
              isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{strings.uploading_profile}</ModalHeader>
                <ModalBody>
                  <Skeleton height="20px" />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
          <Stack direction="column" spacing="4" flex="1">
            <Box sx={wrapperStyles}>
              <List>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.name}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.first_name} {userQuery.data?.last_name}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.email}</Text>
                  <Text sx={descriptionStyles}>{userQuery.data?.email}</Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.cellphone}</Text>
                  <Text sx={descriptionStyles}>
                    {userQuery.data?.contact_number
                      ? maskPhone(userQuery.data.contact_number)
                      : ''}
                  </Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.address}</Text>
                  <Text sx={descriptionStyles}>{userQuery.data?.address}</Text>
                </ListItem>
                <ListItem sx={listItemStyles}>
                  <Text sx={titleStyles}>{strings.role}</Text>
                  <Text sx={descriptionStyles}>
                    <Tag colorScheme="primary">
                      {userQuery.data?.role
                        ? capitalizeFirstLetter(
                            strings.getString(
                              userQuery.data.role.split(' ').join('_')
                            )
                          )
                        : ''}
                    </Tag>
                  </Text>
                </ListItem>
              </List>
            </Box>
          </Stack>
        </Stack>

        <Stack direction="column" spacing="4">
          {userAuthPermissions?.data && (
            <UserPermissionDisplay
              userId={loggedInUser.id}
              userRole={loggedInUser.role}
              userPermissions={userAuthPermissions.data}
              isUserPermissionLoading={userAuthPermissions?.isLoading}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default MyProfile;
