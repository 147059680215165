import {
  Center,
  Flex,
  Icon,
  Image,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import imageCompression from 'browser-image-compression';
import { strings } from 'config/localization';
import { imageCompressionOptions } from 'constants/common';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import { FaFileAlt } from 'react-icons/fa';

interface Props {
  title?: string;
  description?: string;
  uploadedFiles?: any;
  fileKey?: any;
  required?: boolean;
  multiple?: boolean;
  accept?: string | string[];
}
const ImageUploader: React.FC<Props> = (props) => {
  const {
    title = strings.upload_files,
    description = strings.upload_image,
    uploadedFiles,
    fileKey = 'files',
    multiple = false,
    accept = 'image/*',
  } = props;

  const toast = useToast();
  const [myFiles, setMyFiles] = useState<any>([]);
  const [previewFile, setPreviewFile] = useState<any>();
  const [imageName, setImageName] = useState<any>();
  const { setValue, clearErrors } = useFormContext();
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (multiple) {
        setMyFiles([...myFiles, ...acceptedFiles]);
      } else {
        setMyFiles(acceptedFiles);
      }
      if (fileRejections.length > 0) {
        let title = strings.max_upload_size_15;
        if (fileRejections[0]?.errors[0]?.code === 'too-many-files') {
          title = strings.only_1_file_is_allowed;
        }
        toast({
          title: title,
          status: 'error',
          isClosable: true,
        });
      }
    },
    [multiple, myFiles, toast]
  );

  React.useMemo(async () => {
    if (myFiles.length === 0) return;
    let files: File[] = [];
    for (let i = 0; i < myFiles.length; i++) {
      let file = myFiles[i];
      if (
        !file.type.includes('svg') &&
        !file.type.includes('application/pdf')
      ) {
        let compressedFile = await imageCompression(
          file,
          imageCompressionOptions
        );
        files.push(compressedFile);
      } else {
        files.push(file);
      }
    }
    if (multiple) {
      setValue(fileKey, files);
    } else {
      let fl = new File([files[0]], fileKey);
      setValue(fileKey, fl);
    }
    setPreviewFile(URL.createObjectURL(files[0]));
    setImageName(files[0].name);
    clearErrors(fileKey);
  }, [multiple, setValue, fileKey, myFiles, clearErrors]);

  const fileSizeValidator = (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 15) {
      //Limit 15 MB
      return {
        code: 'file-size-too-large',
        message: strings.max_upload_size_15,
      };
    }

    return null;
  };
  const getPreview = () => {
    if (previewFile) {
      const isImage = imageName?.match(/\.(jpeg|jpg|gif|png|svg)$/i);

      if (isImage) {
        // Render the image component for image files
        return <Image src={previewFile} alt={imageName} title={imageName} />;
      } else {
        return (
          <Flex align="center" direction="column">
            <FaFileAlt size="3em" />
            <p>{imageName}</p>
          </Flex>
        );
      }
    }
    if (uploadedFiles) {
      const isImage = uploadedFiles?.match(/\.(jpeg|jpg|gif|png|svg)$/i);
      if (isImage) {
        return (
          <Image
            src={
              fileKey !== 'files'
                ? uploadedFiles
                : ` ${process.env.REACT_APP_PUBLIC_FILE_PATH}/lbs-mms/${uploadedFiles}`
            }
            alt={uploadedFiles}
            title={uploadedFiles}
          />
        );
      } else {
        return (
          <Flex align="center" direction="column">
            <FaFileAlt size="3em" />
          </Flex>
        );
      }
    }
    return (
      <VStack>
        <Icon as={BiPlus} h="16" w="16" color="gray.300" />
        {isDragActive ? (
          <Text textAlign="center" color="gray.400">
            {title}
          </Text>
        ) : (
          <Text textAlign="center" color="gray.400">
            {description}
          </Text>
        )}
      </VStack>
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    onDrop,
    maxFiles: 1,
    validator: fileSizeValidator,
    multiple: multiple,
  });

  return (
    <>
      <Center
        {...getRootProps()}
        cursor="pointer"
        w="100%"
        h="160px"
        bg="gray.50"
        overflow="hidden"
        objectFit="cover"
        shadow="box"
        rounded="sm"
        border="2px"
        borderStyle="dashed"
        borderColor="gray.100">
        <input {...getInputProps()} />
        {getPreview()}
      </Center>
    </>
  );
};

export default ImageUploader;
