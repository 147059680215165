import Resource from 'api/resource';
import http from 'utils/http';

class InventoryResource extends Resource {
  constructor() {
    super('mms/inventories');
  }

  fetchByUserId(id: string | number, query: any) {
    return http({
      url: `/mms/inventories/users/${id}`,
      method: 'GET',
      params: query,
    });
  }
}

export { InventoryResource as default };
