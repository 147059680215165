import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import CRMContactsResource from 'api/crm-contacts';
import { Merge } from 'assets/icons';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ContactDetailsPreview from 'components/crm/ContactDetailsPreview';
import ContactSearch from 'components/crm/ContactSearch';
import LabelTags from 'components/crm/LabelTags';
import MergeContactList from 'components/crm/MergeContactList';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { ContactSchema, DataWrapperSchema } from 'constants/schema';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter, BiMerge, BiShow, BiXCircle } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  changeURL,
  cleanData,
  defaultIndexIdentifier,
  getStartingSerialNumber,
} from 'utils';
import {
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  first_name: string | null;
  last_name: string | null;
  location: string | null;
  street: string | null;
  type: string | null;
  source: string | null;
  updated_at_from: Date | null;
  updated_at_to: Date | null;
  is_merged: string | null;
  email: string | null;
  phone: string | null;
  mobile: string | null;
}

const MergeContact: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const crmAPI = new CRMContactsResource();

  const [isMergeLoading, setIsMergeLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<ContactSchema[]>([]);
  const [mergeMode, setMergeMode] = useState<string>('edit');
  const [Iagree, setIagree] = useState<boolean>(false);
  const [mergeColumns, setMergeColumns] = useState<any>({});

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const defaultIndex: number = defaultIndexIdentifier(searchValues);

  const updated_at_from = Number(searchValues.updated_at_from);
  const updated_at_to = Number(searchValues.updated_at_to);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    first_name: searchValues.first_name ?? null,
    last_name: searchValues.last_name ?? null,
    location: searchValues.location ?? null,
    street: searchValues.street ?? null,
    type: searchValues.type ?? null,
    source: searchValues.source ?? null,
    updated_at_from: updated_at_from ? new Date(updated_at_from) : null,
    updated_at_to: updated_at_to ? new Date(updated_at_to) : null,
    is_merged: searchValues.is_merged ?? null,
    email: searchValues.email ?? null,
    phone: searchValues.phone ?? null,
    mobile: searchValues.mobile ?? null,
  });

  const submitMergeContact = useMutation((data: any) =>
    crmAPI.mergeContacts(data)
  );

  const contactList = useQuery<DataWrapperSchema<ContactSchema[]>>(
    [
      'list-crm-merge-contacts',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        first_name: filterParams.first_name,
        last_name: filterParams.last_name,
        location: filterParams.location,
        street: filterParams.street,
        type: filterParams.type,
        source: filterParams.source,
        updated_at_from: filterParams.updated_at_from,
        updated_at_to: filterParams.updated_at_to,
        is_merged: filterParams.is_merged,
        email: filterParams.email,
        phone: filterParams.phone,
        mobile: filterParams.mobile,
      },
      refetch,
    ],
    () => {
      let {
        first_name,
        last_name,
        location,
        source,
        street,
        type,
        updated_at_from,
        updated_at_to,
        is_merged,
        email,
        mobile,
        phone,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (first_name) queryParams.first_name = first_name;
      if (last_name) queryParams.last_name = last_name;
      if (location) queryParams.location = location;
      if (street) queryParams.street = street;
      if (type) queryParams.type = type;
      if (source) queryParams.source = source;
      if (updated_at_from)
        queryParams.updated_at_from =
          getUTCDateRangeStartDateTime(updated_at_from);
      if (updated_at_to)
        queryParams.updated_at_to = getUTCDateRangeEndDateTime(updated_at_to);
      if (is_merged) queryParams.is_merged = is_merged;
      if (email) queryParams.email = email;
      if (phone) queryParams.phone = phone;
      if (mobile) queryParams.mobile = mobile;
      return crmAPI.list(queryParams).then((res) => {
        return res.data.data;
      });
    }
  );

  const handleAdvancedSearch = useCallback((data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      first_name: data.first_name,
      last_name: data.last_name,
      location: data.location,
      street: data.street,
      type: data.type,
      source: data.source,
      updated_at_from: data.updated_at_from,
      updated_at_to: data.updated_at_to,
      is_merged: data.is_merged,
      email: data.email,
      phone: data.phone,
      mobile: data.mobile,
    }));
  }, []);

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.updated_at_from) {
      data.updated_at_from = Date.parse(data.updated_at_from);
    }
    if (data.updated_at_to) {
      data.updated_at_to = Date.parse(data.updated_at_to);
    }
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const onChange = (checked: boolean, id: number) => {
    if (checked) {
      let contact = contactList?.data?.data?.filter((ele) => ele.id === id)[0]!;
      setSelectedContacts([...selectedContacts, contact]);
    } else {
      let removedContact = selectedContacts.filter((ele) => ele.id !== id);
      setSelectedContacts(removedContact);
    }
  };

  function modifyObject(columns: any) {
    let entries = Object.entries(columns);
    let uniqueID = selectedContacts.map((ele: any) => ele.id);
    let dataList = uniqueID.map((id) => {
      let columns = entries
        .filter(([key, val]: any) => val.id === id)
        .map(([key, val]) => key);
      return {
        contact_id: id,
        columns,
      };
    });
    let formdata = {
      merge_list: dataList,
    };
    return formdata;
  }

  function onSubmit() {
    let formData = modifyObject(mergeColumns);

    let API = submitMergeContact;
    const data = cleanData(formData);
    setIsMergeLoading(true);

    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.contacts} ${strings.has_been_merged}`,
          status: 'success',
          isClosable: true,
        });
        setRefetch((prev) => !prev);
        setIsMergeLoading(false);
        setSelectedContacts([]);
        onClose();
        history.push(routes.crm.merge.list);
      },
      onError: () => {
        setIsMergeLoading(false);
        toast({
          title: `${strings.contacts} ${strings.has_not_been_merged}`,
          status: 'error',
          isClosable: true,
        });
      },
    });
  }
  function closeModal() {
    onClose();
    setMergeColumns({});
    setMergeMode('edit');
  }
  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.crm} | {strings.merge_contact}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.crm.contact.list}>
            {strings.crm}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.crm.merge.list}>
            {strings.all_contacts}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.merge_contact}
        </Heading>
      </Flex>
      <Accordion
        bg="white"
        borderColor="white"
        allowToggle
        defaultIndex={[defaultIndex]}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Flex justify="space-between">
                  <Heading fontSize="16px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <ContactSearch
              filterParams={filterParams}
              handleAdvancedSearch={handleAdvancedSearch}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Stack>
        <Flex justifyContent="end">
          {selectedContacts.length >= 2 && (
            <Button
              colorScheme="cyan"
              variant="outline"
              size="sm"
              mr="2"
              alignSelf="end"
              disabled={selectedContacts.length <= 1}
              rightIcon={<Icon as={BiXCircle} color="blue.300" w="6" h="6" />}
              onClick={() => setSelectedContacts([])}>
              {strings.deselect_all}
            </Button>
          )}
          <Button
            colorScheme="primary"
            size="sm"
            alignSelf="end"
            disabled={selectedContacts.length <= 1}
            leftIcon={<Icon as={BiMerge} w="6" h="6" />}
            onClick={onOpen}>
            {strings.merge_contact}
          </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={closeModal} size="6xl">
          <Box p="2">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {mergeMode === 'edit'
                  ? strings.merge_contact
                  : strings.merge_preview}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {mergeMode === 'edit' ? (
                  <MergeContactList
                    data={selectedContacts}
                    setMergeColumns={setMergeColumns}
                    value={mergeColumns}
                  />
                ) : (
                  <ContactDetailsPreview data={mergeColumns} />
                )}
              </ModalBody>

              <ModalFooter>
                {mergeMode === 'edit' ? (
                  <Fragment>
                    <Button
                      colorScheme="primary"
                      mr={3}
                      onClick={() => setMergeMode('preview')}>
                      {strings.continue}
                    </Button>
                    <Button variant="ghost" mr={3} onClick={closeModal}>
                      {strings.cancel}
                    </Button>
                  </Fragment>
                ) : (
                  <>
                    <FormControl>
                      <Checkbox
                        onChange={(e) => setIagree(e.target.checked)}
                        color="red.400">
                        {strings.merge_contact_is_permanent}
                      </Checkbox>
                    </FormControl>
                    <Button
                      disabled={!Iagree || isMergeLoading}
                      isLoading={isMergeLoading}
                      colorScheme="primary"
                      mr={3}
                      onClick={onSubmit}>
                      {strings.save_now}
                    </Button>
                    <Button
                      isDisabled={isMergeLoading}
                      colorScheme="primary"
                      variant="outline"
                      onClick={() => setMergeMode('edit')}>
                      {strings.edit}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </ModalContent>
          </Box>
        </Modal>

        <Modal isOpen={false} onClose={onClose} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody></ModalBody>
          </ModalContent>
        </Modal>

        <Stack bg="white" p="3" shadow="box" rounded="sm" overflow="auto">
          <form>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{strings.sn}</Th>
                    <Th>{strings.select}</Th>
                    <Th>{strings.name}</Th>
                    <Th>{strings.label}</Th>
                    <Th>{strings.source}</Th>
                    <Th>{strings.city}</Th>
                    <Th>{strings.street}</Th>
                    <Th>{strings.email}</Th>
                    <Th>{strings.phone_number}</Th>
                    <Th>{strings.action}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {contactList?.data?.data?.map(
                    (
                      {
                        id,
                        first_name,
                        last_name,
                        type,
                        source,
                        location,
                        street,
                        email,
                        phone,
                        contactDetail,
                      },
                      index
                    ) => (
                      <Tr key={id}>
                        <Td>{startingSN + index}</Td>
                        <Td>
                          <Checkbox
                            name={'contact-check ' + id}
                            onChange={(e) => onChange(e.target.checked, id)}
                            isChecked={selectedContacts
                              .map((ele) => ele.id)
                              .includes(id)}
                          />
                        </Td>
                        <Td
                          fontWeight="medium"
                          color="gray.500"
                          _hover={{ color: 'gray.600' }}>
                          <RouterLink
                            to={
                              routes.crm.contact.details.replace(
                                ':id',
                                id.toString()
                              ) + search
                            }>
                            <Flex>
                              {`${first_name} ${last_name} `}
                              {contactDetail?.is_merged && (
                                <Box w="6" h="6" ml="2" color="orange.300">
                                  <Image src={Merge} />
                                </Box>
                              )}
                            </Flex>
                          </RouterLink>
                        </Td>
                        <Td>
                          <LabelTags label={type} />
                        </Td>
                        <Td>{strings[source]}</Td>
                        <Td>{location ?? '-'}</Td>
                        <Td>{street ?? '-'}</Td>
                        <Td>{email ?? '-'}</Td>
                        <Td>{phone ?? '-'}</Td>
                        <Td>
                          <RouterLink
                            to={
                              routes.crm.contact.details.replace(
                                ':id',
                                id.toString()
                              ) + search
                            }>
                            <Tooltip hasArrow label={strings.view_contact}>
                              <IconButton
                                icon={<BiShow />}
                                variant="link"
                                aria-label={strings.view_contact}
                                color="green.300"
                                minW="6"
                              />
                            </Tooltip>
                          </RouterLink>
                        </Td>
                      </Tr>
                    )
                  )}
                  {contactList.isLoading && (
                    <TableSkeletonLoader rows={7} cols={10} />
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </form>
        </Stack>
      </Stack>

      <Pagination
        dataList={contactList}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
    </Stack>
  );
};

export default MergeContact;
