import http from 'utils/http';
import Resource from './resource';

class TicketDashboardResource extends Resource {
  constructor() {
    super(`ticket/dashboard`);
  }

  dashboardObjectTickets(id: number, query: any = {}) {
    return http({
      url: `ticket/dashboard/apartment/${id}/tickets`,
      method: 'GET',
      params: query,
    });
  }
  dashboardCustomerTickets(id: number, query: any = {}) {
    return http({
      url: `ticket/dashboard/customer/${id}/tickets`,
      method: 'GET',
      params: query,
    });
  }
}

export { TicketDashboardResource as default };
