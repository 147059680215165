const en = {
  /*login Credential*/
  login: 'Login',
  log_in: 'Log In',
  password: 'Password',
  forgot_your_password: 'Forgot your password',
  email: 'Email',
  your_email_address: 'Your Email Address',
  remember_me: 'Remember Me',
  forgot_password: 'Forgot Password?',
  forgot_password1: 'Forgot Password',
  back_to_login: 'Back to Login',
  password_reset: 'Request Password Reset',
  login_message:
    'Enter your credentials to login into Clausen Business Solutions',
  forgot_message: 'Please enter the email you use to sign in to your account.',
  listinfo_service_center: 'Listinfo Service Center',
  appartmentvermittlung_familie_clausen:
    'Appartmentvermittlung Familie Clausen',
  password_info:
    'Password must have at-least 1 number, 1 special character, 1 capital letter and 5 others characters.',
  invalid_login_msg: 'Email or password did not match.',
  user_delete_error: 'User cannot be deleted User is holding MMS Stock',
  change_language: 'Change Language',
  invalid_token: 'This password reset token is invalid.',
  two_factor_authentication: 'Two Factor Authentication',
  enter_the_pin_from_google_authentication:
    'Enter the pin from Google Authentication',
  authenticate: 'Authenticate',
  one_time_password: 'One Time Password',
  FA_status: '2FA Status',
  disable_2FA: 'Disable 2FA',
  enable_2FA: 'Enable 2FA',
  fa_message:
    'Scan this barcode with your Google Authenticator App and enter those pin code to enable 2FA',
  fa_enable_status_tooltip: 'Generate secret key to enable 2FA',
  fa_disable_status_tooltip: 'Enter your password to disable 2FA',
  authenticator_code: 'Authenticator Code',
  FA_successfully: '2FA is Enable Successfully',
  FA_enable: '2FA is Currently Enabled for your account',
  fa_Disable: '2FA is now Disable',
  authenticator_code_error: 'Authenticator Code Error',
  required_authenticator_code: 'Required Authenticator Code',
  fa_disable_title:
    'If you are looking to disable two factor authentication. Please confirm your password and click Disable 2FA button.',
  disable_2FA_confimation_message:
    'Do you really want to deactivate 2FA for this user?',

  /*button*/
  save_now: 'Save Now',
  filter: 'Filter',
  back: 'Back',
  apply_filter: 'Apply Filter',
  reset_filter: 'Reset Filter',
  more_filter: 'More Filter',
  cancel: 'Cancel',
  clear: 'Clear',
  reply: 'Reply',
  comment: 'Comment',
  save_and_create_new_ticket: 'Save and Create New Ticket',
  confirm_and_close: 'Confirm and Close',
  confirm_and_transfer_another_items: 'Confirm and Transfer Another Items',
  edit_article: 'Edit Article',
  confirm: 'Confirm',
  profile: 'Profile',
  my_profile: 'My Profile',
  edit_profile: 'Edit Profile',
  profile_updated: 'Profile Updated.',
  profle_update_failed: 'Profile Update Failed.',
  password_updated: 'Password Updated.',
  not_match_new_confirm_password: 'The passwords do not match.',
  incorrect_password: 'Password is not correct.',
  setting: 'Setting',
  settings: 'Settings',
  logout: 'Logout',
  loggedOut: 'Logout',
  change_password: 'Change Password',
  change_now: 'Change Now',
  Current_password: 'Current Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  invalid_current_password: 'Current password is incorrect.',
  euro: 'Euro',
  euro_symbol: '€',
  less_than_zero: 'Value less than zero.',
  email_not_found: 'Email address not found.',
  email_required: 'Email address is required',
  upcoming: 'Upcoming',
  past: 'Past',
  send_email: 'Send E-mail',
  stored_mail: 'Stored mail',
  email_sent_successfully: 'E-mail sent successfully',
  failed_to_send_email: 'Failed to send E-mail',
  /*Module*/
  user_management: 'User Management',
  chat: 'Chats',
  ticket: 'Tickets',
  crm: 'CRM',
  contact_center: 'Contact Center',
  feedback: 'Feedbacks',
  mms: 'MMS',
  dashboard: 'Dashboard',
  access_dashboard: 'Access Dashboard',
  travelytics: 'Travelytics',
  access_travelytics: 'Access Travelytics',
  /*Splash Screen*/
  choose_subsystem: 'Choose Subsystem',
  /*User Management*/
  user_management_system: 'User Management System',
  user: 'Users',
  user_list: 'User Lists',
  user_rights: 'User Rights',
  user_roles: 'User Roles',
  user_name: 'User Name',
  user_profile: 'User Profile',
  role: 'Role',
  add_additional_role: 'Add Additional Role',
  add_additional_rights: 'Add Additional Rights',
  additional_rights: 'Additional Rights',
  manage_extra_rights: 'Manage extra rights',
  right_from_role: 'Rights From Role',
  add_role: 'Add Role',
  role_setup: 'Role Setup',
  system: 'System',
  role_name: 'Role Name',
  privillage: 'Privillage',
  add_user: 'Add User',
  all_users: 'All Users',
  all_user_with_that_role: 'All user with that role',
  add_new_user: 'Add New User',
  add_new_role: 'Add New Role',
  view_user: 'View User',
  edit_user: 'Edit User',
  delete_user: 'Delete User',
  save_user: 'Save User',
  user_deatils: 'User Details',
  upload_image: 'Upload Image',
  assigned_tickets: 'Assigned Tickets',
  action: 'Action',
  first_name: 'First Name',
  last_name: 'Last Name',
  email_address: 'Email Address',
  valid_email_address: 'Please provide a valid email address.',
  contact_info: 'Contact Info',
  address: 'Address',
  view: 'View',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  search: 'Search',
  new_user: 'New User',
  has_been_assigned: 'has been assigned to you',
  has_been_transferred: 'has been transferred to you',
  login_with_new_password: 'Please login with new password',
  msg_valid_password: 'Please provide valid password.',
  msg_password_reset_link: 'We have emailed your password reset link!',
  select_role: 'Select Role',
  search_username: 'Search Username',
  rights: 'Rights',
  reset_password: 'Reset Password',
  reset_password_subtitle:
    'Please enter the fields you use to reset to your account.',
  save_reset_password: 'Save Reset Password',
  create_password: 'Create Password',
  create_password_subtitle:
    'Please enter the fields to create a password for your account.',
  save_password: 'Save Password',
  password_helper_text:
    'Minimum 8 chars, at least one uppercase, one lowercase, one number and one special char.',
  resend_verification_email: 'Resend verification email',
  verification_email_sent: 'Verification email sent',
  email_verification_success: 'Email verified successfully.',
  email_verification_failed: 'Email verification failed.',
  resend_verify_email_error: 'Error resending email verification.',
  resend_verify_email_success:
    'Verification email has been sent successfully. Check your inbox.',
  resend_verify_email_success_to:
    'Verification email has been sent successfully to',
  email_already_verified: 'Email is already verified.',
  login_to_continue: 'Please Login to continue.',
  create_password_to_proceed: 'Create a password to proceed.',
  change_confirm: 'Are you sure you want to change this ?',
  cellphone: 'Cellphone',
  invalid_cellphone: 'Invalid Cellphone',
  email_placeholder: 'youremail@example.com',
  admin: 'Admin',
  chat_supporter: 'Chat Supporter',
  ticket_supporter: 'Ticket Supporter',
  mms_employee: 'MMS Employee',
  verified: 'Verified',
  not_yet_verified: 'Not yet verified',
  external_companies: 'External Companies',
  external_company: 'External Company',
  company_name: 'Company Name',
  add_new_company: 'Add New Company',
  company_created: 'Company has been created.',
  company_name_is_required: 'Company name is required.',
  company_email_is_required: 'Company email is required.',
  please_enter_a_valid_phone_number: 'Please enter a valid phone number.',
  enter_a_country_code_at_the_start: 'Enter a country code at the start.',
  company_creation_failed: 'Company creation failed.',
  the_email_has_already_been_taken: 'The email has already been taken.',
  edit_company: 'Edit Company',
  company_updated: 'Company updated successfully.',
  company_udpate_failed: 'Company update failed.',
  delete_company: 'Delete Company',
  company_deleted: 'Company has been deleted.',
  company_delete_error: 'Company cannot be deleted',
  company_not_found: 'Company not found.',
  company_profile: 'Company Profile',
  all_companies: 'All Companies',
  company_list: 'Company Lists',
  companies: 'Companies',
  view_company: 'View Company',
  all_roles: 'All Roles',
  user_login_log: 'User Login Logs',
  all_login_logs: 'All Login Logs',
  access_user_log: 'Access Login Logs',
  foreign_language: 'Foreign Language',
  select_language: 'Select Language',

  /*Chat System*/
  chat_system: 'Chat System',
  closed_chat: 'Closed Chats',
  all_closed_chats: 'All Closed Chats',
  closed_chat_details: 'Closed Chat Details',
  archived_chat: 'Archived Chats',
  all_archived_chats: 'All Archived Chats',
  archived_chat_details: 'Archived Chat Details',
  chat_metrics: 'Chat Metrics',
  all_chat_metrics: 'All Chat Metrics',
  sn: '#',
  id: 'ID',
  customer_name: 'Customer Name',
  employee_name: 'Employee Name',
  closed_at: 'Closed At',
  last_message: 'Last Message',
  actions: 'Action',
  no_of_chat: 'No. of Chat',
  no_of_active_chat: 'Active Chats',
  no_of_message: 'No. of Message',
  no_messages_received: 'No. Messages Received',
  no_messages_send: 'No. Messages Send',
  time_spent_in_call: 'Time Spent In Call',
  role_descripition: 'Role Descripition',
  name: 'Name',
  assignee: 'Assignee',
  description: 'Description',
  transfer_chat: 'Transfer Chat',
  select_user: 'Select User',
  transfer_closed_chat: 'Close Chat?',
  transfer_archived_chat: 'Archive Chat',
  transfer_modal_closed_message: 'Chat will be closed.',
  transfer_modal_archived_message: 'Are you sure you want archived this ?',
  yes: 'Yes',
  no: 'No',
  call: 'Call',
  miss_called: 'User missed the call.',
  active: 'Active',
  in_active: 'Inactive',
  select_apartment: 'Select Apartment',
  select_assignee: 'Select Assignee',
  select_customer: 'Select Customer',
  select_cost_recepient: 'Select Cost Recepient',
  select_house_owner: 'Select House Owner',
  select_priority: 'Select Priority',
  select_source: 'Select Source',
  write_description: 'Write Description',
  call_ended: 'Call ended',
  message_not_sent: 'You message could not be sent',
  join_room_error: 'Could not join the room at the moment',
  make_call_error: 'Could not make call at the moment',
  cancel_call_error: 'Could not cancel call at the moment',
  chat_not_found: 'There is no chat in your feed.',
  chat_not_selected: 'No chat selected!',
  chat_active_toggle_tooltip_message:
    'New chats can be assigned or transferred to you if active. While you are in a call, the toggle will be set to inactive automatically.',
  chat_users_not_available_for_transferral:
    'There is no other user available right now.',
  call_in_progress: 'Call in progress.',
  click_to_cancel: 'Click again to cancel.',
  call_cancelled: 'Call cancelled.',
  make_call: 'Make a phone call',
  chat_active_info: 'Chat Active Info',
  no_chat_assigned: 'There is no chat assigned to you at the moment.',
  is_typing: 'is typing...',

  /*Ticket Board*/
  ticket_system: 'Ticket System',
  all_tickets: 'All Tickets',
  ticket_board: 'Ticket Boards',
  add_task: 'Add Task',
  open: 'Open',
  inprogress: 'In Progress',
  done: 'Done',
  closed: 'Closed',
  recursive_task: 'Recursive Task',
  normal_task: 'Normal Task',
  create_ticket: 'Create Ticket',
  ticket_details: 'Ticket Details',
  title: 'Title',
  ticket_title: 'Ticket Title',
  object: 'Object',
  all_objects: 'All Objects',
  customer: 'Customer',
  cost_recipient: 'Cost Recipient',
  house_owner: 'House Owner',
  priority_label: 'Priority Label',
  priority: 'Priority',
  upload_files: 'Upload Files',
  descriptions: 'Descriptions',
  save_create_new_ticket: 'Save and Create New Ticket',
  author: 'Author',
  created_by: 'Created By',
  created_at: 'Created At',
  last_changed_by: 'Last Change By',
  last_changed_at: 'Last Change At',
  status: 'Status',
  ticket_log: 'Ticket Log',
  ticket_list: 'Ticket Lists',
  repeat_task: 'Repeat Task',
  recursive_interval: 'Recursive Interval',
  recursive_untill: 'Recursive Untill',
  recursive_start: 'Recursive Start',
  apartment: 'Apartment',
  ticket_created: 'Ticket has been created.',
  has_been_created: 'has been created.',
  has_not_been_created: 'has not been created.',
  has_been_merged: 'has been merged',
  has_not_been_merged: 'has not been merged',
  has_been_updated: 'has been updated.',
  has_not_been_updated: 'has not been updated.',
  has_been_deleted: 'has been deleted.',
  has_not_been_deleted: 'has not been deleted.',
  ticket_error: 'Ticket has not been created.',
  expand: 'Expand',
  ticket_list_error: 'There was an error processing your request.',
  select_recursive_interval: 'Select Recursive Interval',
  title_required: 'Title is required.',
  repeating_info: 'Repeating Info',
  interval: 'Interval',
  repeat_from: 'Repeat From',
  repeat_until: 'Repeat Until',
  validate_recursive_date: 'Please enter valid recursive date.',
  validate_recursive_until: 'Please enter date later than Repeat From',
  comment_here: 'Comment Here...',
  comment_required: 'Comment is required.',
  id_not_found: 'ID not found.',
  edit_ticket: 'Edit Ticket',
  ticket_updated: 'Ticket updated successfully',
  change_status: 'Change Status',
  status_updated: 'Status updated successfully',
  select_status: 'Select Status',
  comment_error: 'Comment error',
  view_more: 'View More',
  view_less: 'View Less',
  billability: 'Billability',
  billable: 'Billable',
  spent_time: 'Spent Time',
  correct_spent_time: 'Correct Spent Time',
  spent_time_message: 'The spent time has been correct manually.',
  task: 'Task',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  urgent: 'Urgent',
  normal: 'Normal',
  repeating: 'Repeating',
  select_type: 'Select Type',
  ticket_detail: 'Ticket Detail',
  edit_task: 'Edit Task',
  created_ticket: 'created ticket',
  updated_ticket: 'updated ticket',
  changed_status: 'changed status',
  assigned_ticket: 'assigned ticket',
  commented: 'commented',
  order_by: 'Order By',
  createdat: 'Created At',
  order: 'Order',
  asc: 'Ascending',
  desc: 'Descending',
  select_sort: 'Select Sort',
  select_order: 'Select Order',
  ticket_name: 'Name ',
  select_external_company: 'Select External Company',
  showing_all_customers: 'Showing all customers',
  showing_all_options: 'Showing all options',
  appointment: 'Appointment',
  appointment_date_time: 'Appointment date and time',
  enter_appointment_date_time: 'Enter appointment date time',
  full_screen: 'Fullscreen',
  extenal_company_ticket: 'External Company Tickets',
  external_company_button_tooltip:
    'To generate external company todo list,select external company and appointment date  in filter.',
  external_company_filter_message:
    'First select external company and then  appointment date.',
  todo_list: 'Todo List',
  assignee_button_tooltip:
    'To generate assignee todo list,select assignee and appointment date  in filter.',
  todo_button_tooltip: 'To generate assignee and external company todo list.',

  /*CRM*/
  customer_relation_management: 'Customer Reletionship Management',
  contact_list: 'Contact Lists',
  all_contacts: 'All Contacts',
  merge_list: 'Merge Lists',
  add_contact: 'Add Contact',
  label: 'Label',
  source: 'Source',
  destination: 'Destination',
  added: 'Added',
  last_activities: 'Last Activities',
  last_ticket: 'Last Ticket',
  street: 'Street',
  house_no: 'House No.',
  post_code: 'Post Code',
  town: 'Town',
  country: 'Country',
  phone: 'Phone No.',
  mobile: 'Mobile No.',
  company: 'Company',
  fellow_travelers: 'Fellow Travelers',
  pets: 'Pets',
  equipment_features: 'Equipment Features',
  services: 'Services',
  service: 'Service',
  notes_external: 'Notes External',
  notes_internal: 'Notes Internal',
  add_now: 'Add Now',
  added_on: 'Added On',
  edit_contact: 'Edit Contact',
  event_history: 'Event History',
  channel: 'Channel',
  date_time: 'Date/Time',
  contact_details: 'Contact Details',
  merge: 'Merge',
  unmerge: 'Unmerge',
  updated: 'Updated',
  city: 'City',
  select_channel: 'Select Channel',
  merge_contact: 'Merge Contacts',
  merge_contact_sidebar: 'Merge Contact',
  select_contact: 'Select Contact',
  logs: 'Logs',
  merged_date: 'Merged Date',
  updated_date: 'Updated Date',
  uploaded_by: 'Uploaded By',
  file: 'File',
  image: 'Image',
  images: 'Images',
  progress_status: 'Progress Status',
  upload_file: 'Upload File',
  continue: 'Continue',
  view_contact: 'View Contact',
  delete_contact: 'Delete Contact',
  merge_detail: ' Contact Merge Detail Information.',
  merge_preview: 'Merge Preview',
  merge_contact_is_permanent:
    'Merging contacts is permanent, this action cannot be revert.',
  janitor: 'Janitor',
  support: 'Support',
  deleted_customer: 'Deleted Customer',

  /*Contact Center*/
  call_for_user: 'Call for User',
  total_calls: 'Total Calls',
  aircall_user_name: 'Aircall User Name',
  incoming_call: 'Incoming Call',
  outgoing_call: 'Outgoing Call',
  total_incoming_call: 'Total Incoming Call',
  incoming_call_duration: 'Incoming Call Duration',
  total_outgoing_call: 'Total Outgoing Call',
  outgoing_call_duration: 'Outgoing Call Duration',
  average: 'Average',
  total_duration: 'Total Duration',
  internal_phone_number: 'Internal Phone Number',
  _customer_is_calling: 'Customer {0} is calling.',

  /*Feedback*/
  feedback_system: 'Feedback System',
  access_feedback: 'Manage Feedback and Mail ',
  access_feedback_questions: 'Manage Questions and Settings',
  access_feedback_resend: 'Access Resend Feedback',
  all_feedbacks: 'All Feedbacks',
  feedback_lists: 'Feedback Lists',
  mails: 'Mails',
  all_mails: 'All Mails',
  mail_lists: 'Mail Lists',
  questions: 'Questions',
  all_questions: 'All Questions',
  question_lists: 'Question Lists',
  date: 'Date',
  closed_ended: 'Closed Ended',
  open_ended: 'Open Ended',
  rating: 'Rating',
  time_period: 'Time Period',
  feedback_detail: 'Feedback Detail',
  mark_as_done: 'Mark as Done',
  publish_feedback: 'Publish Feedback',
  publish_feedback_on_object_page: 'Publish Feedback on Object Page',
  do_not_publish_feedback: 'Do Not Publish Feedback',
  do_not_publish_feedback_on_object_page:
    'Do Not Publish Feedback on Object Page',
  use_for_score_calculation: 'Use for Score Calculation',
  do_not_use_for_score_calculation: 'Do Not Use for Score Calculation',
  contact_customer: 'Contact Customer',
  contact_house_owner: 'Contact House Owner',
  resend_mail: 'Resend Mail',
  new_question: 'New Question',
  edit_question: 'Edit Question',
  add_question: 'Add Question',
  delete_question: 'Delete Question',
  edit_feedback: 'Edit Feedback',
  view_feedback: 'View Feedback',
  feedback_question: 'Feedback question',
  feedback_question_deleted_successfully:
    'Feedback question deleted successfully',
  sent: 'Sent',
  received: 'Received',
  feedback_mail_sent_sucessfully: 'Feedback mail sent successfully',
  feedback_mail_not_sent: 'Feedback mail not sent',
  how_many_days_after_departure_should_the_feedback_mail_be_sent:
    'How many days after departure should the feedback mail be sent?',
  select_days: 'Select Days',
  days: 'Days',
  feedback_received: 'Feedback Received',
  question: 'Question',
  response: 'Response',
  the_rating_is_out_of_10: 'The rating is out of 10',
  this_is_closed_ended_question: 'This is yes/no question',
  this_is_open_ended_question: 'This is open ended question',
  feedback_has_been_published_successfully:
    'Feedback has been published successfully',
  feedback_publish_error: 'Feedback publish error',
  published_feedback_on_object_page_successfully:
    'Published feedback on object page successfully',
  publish_feedback_on_object_page_error:
    'Published feedback on object page error',
  feedback_has_been_unpublished_successfully:
    'Feedback has been unpublished successfully',
  feedback_unpublish_error: 'Feedback unpublish error',
  feedback_has_been_marked_as_done_successfully:
    'Feedback has been marked as done successfully',
  feedback_mark_as_done_error: 'Feedback mark as done error',
  How_would_you_rate_your_holiday_in_List_on_Sylt_and_the_service_provided_by_the_Clausen_family_as_a_whole:
    'How would you rate your holiday in List on Sylt and the service provided by the Clausen family as a whole?',
  publish_status: 'Publish Status',
  published_on_website: 'Published on Website',
  published_on_object_detail_page: 'Published on Object Detail Page',
  score_calculation: 'Score Calculation',
  feedback_on_object_page_has_been_unpublished_successfully:
    'Feedback on Object Page has been Unpublished Successfully',
  feedback_on_object_page_unpublish_error:
    'Feedback on Object Page Unpublish Error',
  score_calculation_used: 'Score Calculation Used',
  score_calculation_use_error: 'Score Calculation Use Error',
  score_calculation_unused: 'Score Calculation Unused',
  score_calculation_unuse_error: 'Score Calculation Unuse Error',
  object_publish: 'Object Published',
  website_publish: 'Website Published',
  not_publish: 'Not Published',
  score_total: 'Score Total',
  answer_rate: 'Answer Rate',
  public_score_calculation: 'Public Score Calculation',
  real_score: 'Real Score',
  public_score: 'Public Score',
  detail_reviews_general: 'Detail-Reviews General',
  detail_review_object_page: 'Detail-Review Object Page',
  reviews_general: 'Reviews General',
  reviews_object_page: 'Reviews Object Page',
  score_general: 'Score General',
  score_objects: 'Score Objects',
  no_mail: 'No E-mail',
  no_email: 'No E-mail',
  analysis: 'Analysis',
  average_rating: 'Average Rating.',
  percentage_of_closed_ended: 'Percentage of Yes / No.',
  percentage_of_open_ended:
    'Percentage of customers written feedback / not written feedback.',
  feedback_analysis: 'Feedback Analysis',
  delete_mail: 'Delete Mail',
  feedback_mail_deleted_successfully: 'Feedback Mail Deleted Successfully',
  feedback_analysis_detail: 'Feedback_Analysis_Detail',
  text_search: 'Text Search',
  feedback_response_detail: 'Feedback_Response_Detail',
  resend_feedback: 'Resend Feedback',
  resent: 'Resent',
  feedback_deleted_successfully: 'Feedback Deleted Successfully',
  delete_feedback: 'Delete Feedback',
  publish: 'Publish',
  select_publish: 'Select Publish',
  publish_is_required: 'Publish is required',
  start_page: 'Start page',

  /*Inventory*/
  inventory: 'Inventory',
  inventories: 'Inventories',
  all_inventory: 'All Inventories',
  inventory_name: 'Inventory Name',
  used_item: 'Used Item',
  quantity: 'Quantity',
  total_quantity: 'Total Quantity',
  from: 'From',
  to: 'To',
  add_item: 'Add Item',
  deselect_all: 'Deselect All',

  /*MMS*/
  merchandise_management_system: 'Merchandise Management System',
  transfer_item: 'Transfer Items',
  all_transfer_item: 'All Transfer Items',
  all_transfer_logs: 'All Transfer Logs',
  select_articles: 'Select Articles',
  search_article: 'Search Article',
  stock_type: 'Stock Type',
  units: 'Units',
  unit: 'Unit',
  all_units: 'All Units',
  unit_details: 'Unit Details',
  select_source_warehouse: 'Select Source Warehouse',
  rooms: 'Rooms',
  room: 'Room',
  delete_room: 'Delete Room',
  add_room: 'Add Room',
  edit_room: 'Edit Room',
  room_name: 'Room Name',
  enter_room_name: 'Enter Room Name',
  racks: 'Racks',
  add_rack: 'Add Rack',
  rack: 'Rack',
  delete_rack: 'Delete Rack',
  shelf: 'Shelf',
  add_shelf: 'Add Shelf',
  delete_shelf: 'Delete Shelf',
  shelves: 'Shelves',
  select_destination_warehouse: 'Select Destination Warehouse',
  warehouse: 'Warehouse',
  warehouse_name: 'Warehouse Name',
  warehouses: 'Warehouses',
  all_warehouses: 'All Warehouses',
  add_warehouse: 'Add Warehouse',
  edit_warehouse: 'Edit Warehouse',
  delete_warehouse: 'Delete Warehouse',
  warehouse_details: 'Warehouse Details',
  warehouse_information: 'Warehouse Information',
  warehouse_not_found: 'Warehouse Not Found',
  shelf_delete_error: 'There was an error deleting the shelf.',
  rack_delete_error: 'There was an error deleting the rack.',
  room_delete_error: 'There was an error deleting the room.',
  articles: 'Articles',
  article: 'Article',
  all_articles: 'All Articles',
  add_article: 'Add Article',
  articles_names: 'Articles Names',
  article_name: 'Articles Name',
  upload_article_picture: 'Upload Article Picture',
  article_image: 'Article Image',
  type: 'Type',
  types: 'Types',
  all_types: 'All Types',
  type_details: 'Type Details',
  buying_price: 'Buying Price',
  selling_price: 'Selling Price',
  seller: 'Seller',
  seller_home_page_shop: 'Seller Home Page/Shop',
  minimum_stock: 'Minimum Stock',
  maximum_stock: 'Maximum Stock',
  out_of_stock: 'Out of Stock',
  minimum_stock_notification: 'Minimum Stock Notification',
  ean_number: 'EAN Number',
  notes: 'Notes',
  article_details: 'Article Details',
  objects: 'Objects',
  object_id: 'Object ID',
  object_name: 'Object Name',
  booking: 'Booking',
  tickets: 'Tickets',
  inventory_load: 'Invenory Load',
  object_details: 'Object Details',
  ticket_open_inprogress_done: 'Ticket (Open / Inprogress / Done)',
  abbreviation: 'Abbreviation',
  street_number: 'Street Number',
  is_active: 'Is Active',
  type_name: 'Type Name',
  add_type: 'Add Type',
  unit_name: 'Unit Name',
  add_unit: 'Add Unit',
  edit_unit: 'Edit Unit',
  transfer_log: 'Transfer Log',
  transfer_logs: 'Transfer Logs',
  my_transfer_log: 'My Log',
  my_transfer_logs: 'My Logs',
  all_my_logs: 'All Logs',
  ticket_source: 'Ticket Source',
  done_by: 'Done By',
  required: 'Required',
  user_created: 'User has been created',
  user_creation_failed: 'User creation failed.',
  user_udpate_failed: 'User update failed.',
  password_update_failed: 'Password update failed.',
  user_not_found: 'User not found',
  update: 'Update',
  user_updated: 'User updated successfully',
  user_deleted: 'User has been deleted.',
  roles_not_found: 'Roles not found',
  confirm_delete: 'Are you sure you want to delete this ?',
  edit_type: 'Edit Type',
  select: 'Select',
  min_stock_notification_tooltip:
    'You will be notified when the number of minimum stock is reached.',
  valid_data: 'Please enter a valid data.',
  valid_only_number: 'Please enters only numbers.',
  min_stock_vaidation_msg: 'Value should be less than maximum stock.',
  max_stock_vaidation_msg: 'Value should be greater than min stock.',
  phone_call: 'Phone Call',
  collapse_sidebar: 'Collapse Sidebar',
  expand_sidebar: 'Expand Sidebar',
  article_type: 'Select Type',
  article_unit: 'Select Unit',
  max_upload_size_5: 'Maximum upload attachments size upto 5 MB.',
  max_upload_size_10: 'Maximum upload attachments size upto 10 MB.',
  max_upload_size_15: 'Maximum upload attachments size upto 15 MB.',
  max_characters_exceeded: 'Maximum character limit exceeded',
  selected_files: 'Selected Files',
  drag_drop_files_msg:
    'Drag & Drop some files here, or click to select files. Upload files upto {0}MB.',
  files: 'Files',
  delete_file: 'Delete File',
  only_1_file_is_allowed: 'Only 1 file is allowed.',
  access_user: 'Access User',
  manage_role: 'Manage Role',
  manage_user: 'Manage User',
  access_external_companies: 'Access External Companies',
  manage_external_companies: 'Manage External Companies',
  access_chat: 'Access Chat',
  access_closed_chat: 'Access Closed Chat',
  access_archived_chat: 'Access Archived Chat',
  access_chat_metrics: 'Access Chat Metrics',
  access_chat_assign: 'Access Chat Assign',
  access_chat_mediasoup: 'Access Chat Mediasoup',
  access_ticket: 'Access Ticket',
  access_mms: 'Access MMS',
  can_hold_item: 'Can Hold Item',
  manage_article: 'Manage Article',
  stocktaking_mode: 'Stocktaking Mode',
  stocktaking: 'Stocktaking',
  upload_new: 'Upload New',
  remove: 'Remove',
  uploading: 'Uploading',
  invalid_profile_upload: 'Invalid Profile Upload',
  file_must_be_an_image_file: 'File must be an image type.',
  clear_profile_pic: 'Clear Profile Image',
  are_you_sure_you_cannot_undo_this_action:
    "Are you sure? You can't undo this action afterwards.",
  upload_label: 'Upload Profile',
  remove_label: 'Remove Profile',
  invalid_file_size: 'Invalid file size',
  file_size_info: 'File size must be less than 5MB',
  uploading_profile: 'Uploading Profile',
  no_permission_msg:
    'You do not have permission to access it. Please contact administrator.',
  transfer_date: 'Transfer Date',
  transfer_source: 'Transfer Source',
  transfer_by: 'Transfer By',
  transfer_quantity: 'Transfer Quantity',
  category: 'Category',
  select_category: 'Select Category',
  house_number: 'House number',
  object_image: 'Object Image',
  house: 'House',
  apartmant: 'Apartment',
  flat: 'Flat',
  new: 'New',
  fresh: 'Fresh',
  consumed: 'Consumed',
  employee: 'Employee',
  transfer_item_success: 'Transfer item successful.',
  transfer_item_failed: 'Transfer item failed.',
  none: 'None',
  reconciled: 'Reconciled',
  web_ui: 'Web UI',
  app: 'App',
  web_app: 'Web/App',
  select_article_and_quantity_first:
    'Please select article and quantity first.',
  min_stock_reached: 'Min Stock Reached',
  min_stock_reached_description:
    'This article has reached the minimum stock which was set to ',
  max_stock_reached: 'Max Stock Reached',
  max_stock_reached_description:
    'This article has reached the maximum stock which was set to ',
  stocktaking_mode_message: ' Really enter Stocktaking mode.',
  my_inventory: 'My Inventories',
  all_my_inventories: 'All Inventories',
  transfer: 'Transfer',
  max_min_stock: 'Max-/Min-Stock',
  item: 'Item',
  inventory_detail: 'Inventory Details',
  qr_code: 'QR Code',
  warehouse_qr_code_confirm:
    'Do you want to print {noOfShelves} QR code of {warehouseName}?',

  //object-dahboard and cross-selling

  access_object_dashboard: 'Access dasbhoard',
  manage_cross_selling: 'Manage cross selling services',
  enter_object_name_here: 'Enter object name here',

  // common
  lang: 'en',
  result: 'Result',
  no_results_found: 'No results found.',
  no_records_found: 'No records found',
  error: 'Error',
  no_options: ' No Options',
  showing: 'Showing',
  manually: 'Manually',
  general: 'General',
  gotoDetails: 'Go to Details',
  out_of: 'out of',
  loading: 'Loading...',
  show_rows: 'Show rows',
  error_boundary_heading_text: 'Sorry, something went wrong.',
  error_boundary_paragraph_text:
    'Our team has been notified and we will get it fixed as soon as we can.',
  reload_page: 'Reload page',
  take_me_home: 'Take me home',
  manual: 'Manual',
  ['v-office']: 'V-Office',
  guest: 'Guest',
  owner: 'Owner',
  lead: 'Lead',
  supplier: 'Supplier',
  test: 'Test',
  other: 'Other',
  daily: 'Daily',
  weekly: 'Weekly',
  bi_weekly: 'Bi-Weekly',
  monthly: 'Monthly',
  semi_anually: 'Semi-Anually',
  anually: 'Anually',
  previous: 'Previous',
  next: 'Next',
  date_validation_msg: 'Please enter a valid date',
  freeze_inventory: 'Freeze Inventory',
  create_inventory_list: 'Create Inventory Lists',
  correct_inventory: 'Correct Inventory',
  create_delta_list: 'Create Delta Lists',
  unfreeze_inventory_end: 'Unfreeze Inventory & End Stocktaking',
  download_pdf: 'Download PDF',
  download_excel: 'Download Excel',
  download__delta_list_pdf: 'Download Delta List PDF',
  unfreeze_inventory: 'Unfreeze Inventory',
  no_transferring: '(No Transferring of Items possible.)',
  download_as: '(Download as PDF and save, then print.)',
  transfer_items: '(Transfer Items from or to Stocktaking correction.)',
  what_change: '(What changes have been made during Stocktaking.)',
  step_1: 'Step 1',
  step_2: 'Step 2',
  step_3: 'Step 3',
  step_4: 'Step 4',
  step_5: 'Step 5',
  actual_quantity: 'Actual Quantity',
  delta: 'Delta',
  no_data_found: 'No data found',
  successful: 'Successful',

  /*MDM*/
  master_data_magnagement: 'Master Data Management',
  mdm: 'MDM',
  contacts: 'Contacts',
  contact: 'Contact',
  upload_csv: 'Upload CSV',
  business: 'Business',
  language: 'Language',
  letter_salutation: 'Letter Salutation',
  letter_closure: 'Letter Closure',
  salutation: 'Salutation',
  birthdate: 'Birthdate',
  phone_number: 'Phone Number',
  business_phone_number: 'Business Phone Number',
  mobile_number: 'Mobile Number',
  fax: 'Fax',
  im: '-im-',
  website: 'Website',
  both: 'both',
  self: 'self',

  region: 'Region',
  plant: 'Plant',
  categories: 'Categories',
  online: 'Online',
  deactivated: 'Deactivated',
  sleeping_facilities: 'Sleeping Facilities',
  longitude: 'Longitude',
  latitude: 'Latitude',
  bedrooms: 'Bedrooms',

  bookings: 'Bookings',
  booking_date: 'Booking Date',
  invoices: 'Invoices',
  invoice: 'Invoice',
  number: 'Number',
  until: 'Until',
  received_at: 'Received At',
  changed_at: 'Changed At',
  total: 'Total',
  grand_total: 'Grand Total',
  marketing_channel: 'Marketing Channel',
  down_payment: 'Down Payment',
  down_payment_until: 'Down Payment Until',
  final_payment_until: 'Final Payment Until',
  ex_price: 'Ex-Price',
  total_occupancy: 'Total Occupancy',
  adult: 'Adult',
  children: 'Children',
  babies: 'Babies',
  age_children: 'Age of Children',
  country_customer: 'Country Customer',
  town_code_customer: 'Town Code Customer',
  country_objectives: 'Country Objectives',
  rent_statement_exit: 'Rent Statement Exit',
  invoice_date: 'Invoice Date',
  net: 'Net',
  due_date: 'Due Date',
  cancelled_on: 'Cancelled On',
  calculation: 'Calculation',
  select_calculation: 'Select Calculation',
  provider: 'Provider',
  select_provider: 'Select Provider',
  invoice_recepient: 'Invoice Recepient',
  select_invoice_recepient: 'Select Invoice Recepient',
  warehouse_holds_inventories: 'Warehouse holds inventories',
  uploads: 'Uploads',
  all_uploads: 'All Uploads',
  all_bookings: 'All Bookings',
  all_invoices: 'All Invoices',
  all_services: 'All Services',
  all_logs: 'All Logs',
  drag_drop_csv_files_msg:
    'Drag & Drop only .csv files here, or click to select .csv files. Upload files upto 10MB.',
  drag_drop_csv_15MB_files_msg:
    'Drag & Drop only .csv files here, or click to select .csv files. Upload files upto 15MB.',
  processing_data: 'Processing data',
  failed: 'Failed',
  access_mdm: 'Access MDM',
  mdm_supporter: 'MDM Supporter',
  file_uploaded_successfully: 'File uploaded successfullly.',
  uploaded_on: 'Last Uploaded on',
  by: 'by',
  delete_article_unit_error:
    'Unable to delete article unit type. It contains many articles.',
  delete_type_error:
    'Unable to delete article category. It contains many articles.',
  transferred: 'transferred',
  internal_server_error: 'Internal Server Error',
  cannot_upload_csv_in_worng_category: 'Cannot Upload CSV in wrong category',
  upload_date: 'Upload Date',
  crm_employee: 'CRM Employee',
  access_crm: 'Access CRM',
  merge_contacts: 'Merge Contacts',
  delete_contacts: 'Delete Contacts',

  access_oms: 'Access OMS',
  access_bms: 'Access BMS',
  access_bread_service: 'Access Bread Service',
  access_sms: 'Access SMS',
  location: 'Location',
  letter_completion: 'Letter Completion',
  homepage: 'Homepage',

  imports: 'Imports',
  all_imports: 'All Imports',
  import_type: 'Import Type',
  last_id: 'Last Id',
  total_records_imported: 'Total Records Imported',
  imported_at: 'Imported At',
  availabilities: 'Availabilities',

  /* OMS */
  object_management_system: 'Object Management System',
  oms: 'OMS',
  object_list: 'Object Lists',
  status_changed: 'Status changed',
  payement_active: 'Payement active',
  website_active: 'Website active',
  video_active: 'Video active',
  seven_for_six: '7 for 6',
  this_discount_will_be_applied_for_season_c:
    'This discount will only be applied during Season C',
  voffice_data_sync: 'VOffice Data Sync',
  features: 'Features',
  picture: 'Picture',
  text: 'Text',
  cannot_get_apartment_details: 'Cannot get apartment details',

  /**SMS Service */
  service_management_system: 'Service Management System',
  sms: 'SMS',
  add_service: 'Add Service',
  scheme_type: 'Scheme Type',
  service_created: 'Service has been created',
  service_creation_failed: 'Service creation failed.',
  service_udpate_failed: 'Service update failed.',
  add_new_service: 'Add New Service',
  edit_service: 'Edit Service',
  service_updated: 'Service updated successfully',
  service_not_found: 'Service not found',
  service_details: 'Service Details',
  product_code: 'Product Code',
  v_office_service: 'VOffice Service',
  price_scheme_type: 'Price Scheme Type',
  by_quantity: 'By Quantity',
  by_duration: 'By Duration',
  by_day: 'By Day',
  newspaper: 'Newspaper',
  one_time_price: 'One Time Price',
  by_request: 'By Request',
  beach_chair: 'Beach Chair',
  unit_price: 'Unit Price',
  price_a: 'Price A',
  price_b: 'Price B',
  view_service: 'View Service',
  delete_service: 'Delete Service',
  service_deleted: 'Service has been deleted.',
  day: 'Day',
  price: 'Price',
  duration: 'Duration',
  priceschemebynone: 'None',
  priceschemebyday: 'By Day',
  priceschemeonetime: 'One Time',
  priceschemebyquantity: 'By Quantity',
  priceschemebyduration: 'By Duration',
  priceschemebeachchair: 'Beach Chair',
  priceschemebyrequest: 'By Request',
  per_week: 'Per Week',
  sun: 'SUN',
  mon_fri: 'MON-FRI',
  additional_day: 'Additional Day',
  annual_price: 'Annual Price',
  duplicate_price_scheme: 'Duplicate Price Scheme',

  /**SMS Service Group */
  service_group: 'Service Groups',
  all_service_groups: 'All Service Groups',
  service_group_list: 'Service Group Lists',
  add_service_group: 'Add Service Group',
  booking_type: 'Booking Type',
  booking_fee: 'Booking Fee',
  booking_fee_calculation: 'Booking Fee Calculation',
  service_group_created: 'Service Group has been created',
  service_group_creation_failed: 'Service Group creation failed.',
  service_group_udpate_failed: 'Service Group update failed.',
  add_new_service_group: 'Add New Service Group',
  service_name: 'Service Name',
  frontend_color: 'Frontend Color',
  price_per_night: 'Price Per Night',
  show_on_landing_page: 'Show on Landing Page',
  default_service: 'Default Service',
  early_deliverable: 'Early Deliverable',
  early_deliverable_on_start: 'Early Deliverable on Start',
  pay_to_vendor: 'Pay to Vendor',
  dog_service: 'Dog Service',
  icon: 'Icon',
  cover_image: 'Cover Image',
  drag_icon_or_click_to_select_icon: 'Drag icon or click to select icon',
  drag_image_or_click_to_select_image: 'Drag image or click to select image',
  edit_service_group: 'Edit Service Group',
  service_group_updated: 'Service Group updated successfully',
  service_group_not_found: 'Service Group not found',
  service_group_details: 'Service Group Details',
  one_time: 'One Time',
  per_day: 'Per Day',
  per_night: 'Per Night',
  view_service_group: 'View Service Group',
  delete_service_group: 'Delete Service Group',
  service_group_deleted: 'Service Group has been deleted.',
  accept_only_png: 'Accept only *.png file',
  enter_booking_fee_in_cents: 'Enter Booking Fee in Cents',
  enter_price_per_night_in_cents: 'Enter Price in Cents',
  enter_value_in_cents: 'Enter Value in Cents',
  enter_value_in_euro: 'Enter Value in Euro',
  light: 'Light',
  dark: 'Dark',
  price_calculation: 'Price Calculation',

  /**DMS Service */
  document_management_system: 'Document Management System',
  dms: 'DMS',
  general_documents: 'General Documents',
  document_objects: 'Document Objects',
  document_customers: 'Document Customers',
  document_owners: 'Document Owners',
  document_users: 'Document Users',
  all_documents: 'All General Documents',
  all_customers: 'All Customer Documents',
  all_owners: 'All Owner Documents',
  all_object_documents: 'All Object Documents',
  all_user_documents: 'All User Documents',
  upload_document: 'Upload Document',
  documents: 'Documents',
  document_name: 'Document Name',
  owner_name: 'Owner Name',
  availability: 'Availability',
  edit_general_document: 'Edit Document',
  edit_document_object: 'Edit Upload Document to Object',
  edit_document_owner: 'Edit Upload Document to Owner',
  edit_document_customer: 'Edit Upload Document to Customer',
  upload_general_document: 'Upload General Document',
  upload_document_object: 'Upload Document to Object',
  upload_document_owner: 'Upload Document to Owner',
  upload_document_customer: 'Upload Document to Customer',
  to_objects: 'to Objects',
  no_of_documents: 'No. of Documents',
  select_object: 'Select Object',
  general_document_deleted: 'Document has been deleted.',
  delete_general_document: 'Delete Document',
  general_document_not_found: 'Document not found',
  general_document_udpate_failed: 'Document update failed.',
  general_document_created: 'Document has been created',
  general_document_creation_failed: 'Document creation failed.',
  general_document_updated: 'Document updated successfully',
  customer_documents: 'Customer Documents',
  owner_documents: 'Owner Documents',
  object_documents: 'Object Documents',
  users_document: 'Users Document',
  upload_user_document: 'Upload User Document',
  edit_user_document: 'Edit User Document',
  import_file: 'Import File',
  the_file_field_is_required: 'The file field is required.',

  /**BMS Service */
  booking_management_system: 'Booking Management System',
  bms: 'BMS',
  subtotal: 'Sub Total',
  vat19: '19%VAT on delivery service',
  vat7: '7% VAT',
  payment_method: 'Payment Method',
  bank_transfer: 'Bank Transfer',
  paid_amount: 'Paid Amount',
  without_tax: 'Without Tax',
  including: 'Including',
  callback: 'Callback',
  callbacks: 'Callbacks',
  all_callbacks: 'All Callbacks',
  callback_date: 'Callback Date',
  callback_detail: 'Callback Detail',
  callback_deleted_successfully: 'Callback Deleted Successfully',
  no_callback_details_found: 'No Callback Details Found',
  inquiry: 'Inquiry',
  mr: 'Mr.',
  mrs: 'Mrs.',
  misc: 'Misc.',
  check_in_out: 'Check In/Out',
  check_in: 'Check-In',
  check_out: 'Check-Out',
  check_in_warning_message:
    'In the next step, the booking details are displayed to the guest for checking. Adjustments or additions can still be made afterwards.',
  did_you_choose_right_guest: 'Did you choose the right guest?',
  display_booking_details_confirmation_question:
    'Should the booking details of the following guest be displayed?',
  mirror_booking_details: 'Mirror Booking Details',
  check_out_confirmation_question: 'Would you like to check out guest {0} now?',
  check_in_confirmation_question: 'Would you like to check in guest {0} now?',
  check_in_confirmation_message:
    'Please make sure that the guest has read the booking information.',
  check_booking_details: 'Check Booking Details',
  marketing_email: 'E-Mail-Marketing',
  marketing_email_settings: 'E-Mail-Marketing Settings',
  how_many_days_before_arrival_should_the_marketing_mail_be_sent:
    'How many days before guest’s arrival should marketing mail be send out?',

  /**BS Service */
  bread_system: 'Bread System',
  bs: 'BS',
  all_billings: 'All Billings',
  all_daily_view: 'All Daily Views',
  all_driving_order: 'All Driving Orders',

  /**PMS Service */
  payment_management_system: 'Payment Management System',
  pms: 'PMS',
  credit_card: 'Credit Cards Payment',
  all_credit_cards: 'Credit Cards Payment',
  paypal: 'Paypal Payment',
  invoice_payment: 'Invoice Payment',
  all_paypals: 'Paypal Payment',
  credit_card_no: 'Credit Card Number',
  amount: 'Amount',
  paypal_address: 'Paypal Address',
  access_pms: 'Access PMS',
  payment_links: 'Payment Links',
  send_on: 'Send On',
  first_sent_on: 'First Sent On',
  last_sent_on: 'Last Sent On',
  paid_on: 'Paid On',
  all_payment_links: 'All Payment Links',
  create_payment_links: 'Create Payment Link',
  send_payment_link: 'Send Payment Link',
  payment_link_sending_failed: 'Payment link sending failed.',
  payment_link_send_successfully: 'Payment link send successfully',
  identifier: 'Identifier',
  amount_is_required: 'Amount is required',
  required_booking_number: 'Booking number is required.',
  required_customer_name: 'Customer name is required.',
  paid: 'Paid',
  resent_mail_success: 'Email Resent Successfully',
  resent_mail_error: 'Email Resent Failed',
  confirm_resend_email: 'Are you sure you want to resend email?',
  service_reservation: 'Service booking',
  apartment_reservation: 'Object booking',
  ferry_booking: 'Ferry booking',
  payment_link: 'Payment link',

  /**PNS Service */
  push_notification_system: 'Push Notification System',
  all_notifications: 'All Notifications',
  pns: 'PNS',
  notifications: 'Notifications',
  push_notification: 'Push Notification',
  add_new_push_notification: 'New Push Notification',
  link: 'Link',
  total_read: 'Total/Read',
  add_push_notification: 'Add Push Notification',
  notification_created: 'Push Notification has been created',
  notification_creation_failed: 'Push Notification creation failed.',
  send: 'Send',
  access_pns: 'Access PNS',
  manage_pns: 'Send Push-Notifications',
  image_link: 'Image Link',
  pns_notification_confirm: 'Attention: Notification will be sent, continue?',

  /**FAQ Service */
  frequently_ask_question: 'Frequently Ask Question',
  faq: 'FAQs',
  all_faq: 'All FAQs',
  internal_faq: 'Internal FAQs',
  all_internals: 'All Intenals',
  success: 'Success',
  group: 'Groups',
  all_groups: 'All Groups',
  add_faq: 'Add FAQ',
  edit_faq: 'Edit FAQ',
  delete_faq: 'Delete FAQ',
  select_group: 'Select Group',
  alias: 'Alias',
  web: 'Web',
  internes_wissensdokument: 'Internal Knowledge Document',
  dateien_hochladen: 'Dateien_Hochladen',
  pdf_upload: 'PDF Upload',
  faq_deleted_successfully: 'FAQ deleted successfully',

  faq_group: 'FAQ Group',
  add_group: 'Add Group',
  edit_group: 'Edit Group',
  delete_group: 'Delete Group',
  faq_group_deleted_successfully: 'FAQ Group deleted successfully',

  reservations: 'Reservations',
  all_reservations: 'All Reservations',
  all_requests: 'All Requests',
  reservation_requests: 'Reservation requests',
  ferry_bookings: 'Ferry bookings',
  bread_bookings: 'Bread bookings',
  bread_daily_view: 'Bread daily views',
  bread_driving_order: 'Bread driving orders',
  save_inorder_to_apply_changes: 'Save inorder to apply changes',

  /** Bread Boking */
  bread_booking: 'Bread Bookings',
  add_bread_booking: 'Add Bread Booking',
  update_bread_booking: 'Update Bread Booking',
  pending: 'Pending',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled',
  booking_number: 'Booking Number',
  add_new_bread_booking: 'Add New Bread Booking',
  edit_bread_booking: 'Edit Bread Booking',
  bread_booking_updated: 'Bread Booking updated successfully',
  bread_booking_not_found: 'Bread Booking not found',
  cannot_change_quantity_in_past:
    'You cannot change quantity of a booking in the past',
  multiple_bookings_in_same_date_not_allowed:
    'Multiple bookings in same date is not allowed',
  bread_booking_details: 'Bread Booking Details',
  bread_booking_deleted: 'Bread Booking has been deleted.',
  view_bread_booking: 'View Bread Booking',
  delete_bread_booking: 'Delete Bread Booking',
  edit_order: 'Edit Order',
  cancel_order: 'Cancel Order',
  property_name: 'Property Name',
  customer_contact: 'Customer Contact',
  booking_period: 'Booking Period',
  timespan: 'Time Span',
  bread_booking_created: 'Bread Booking has been created',
  bread_booking_creation_failed: 'Bread Booking creation failed.',
  bread_booking_udpate_failed: 'Bread Booking update failed.',
  include_departure_day: 'Include Departure Day',
  price_pound: 'Price (€)',
  booking_fee_pound: 'Booking Fee (€)',
  total_pound: 'Total (€)',
  must_be_date_after_today_or_equal_to_today:
    'Must be date after today or equal to today.',
  invalid_data: 'The given data is invalid',
  booking_invalid: 'Booking Number is not correct',
  service_invalid: 'Service is not correct',
  customer_invalid: 'Customer Name is correct',
  /** Driving Order */
  driving_order: 'Driving Orders',
  import_driving_order: 'Import Driving Orders',
  import_driving_success: 'Import Driving Success',
  import_driving_failed: 'Import Driving Failed',

  /** Bread Daily View */
  bread_booking_billing: 'Bread Booking Billings',
  bread_booking_billing_detils: 'Bread Booking Billing Details',
  bread_booking_billing_edit: 'Bread Booking Billing Edit',
  bread_type: 'Bread Type',
  old_price: 'Old Price',
  new_price: 'New Price',
  price_difference: 'Price Difference',
  total_bread: 'Total Bread',
  total_fee: 'Total Fee',
  total_price_difference: 'Total Price Difference',
  bread_quantity_difference: 'Bread Quantity Difference',
  total_fee_difference: 'Total Fee Difference',
  grand_total_difference: 'Grand Total Difference',
  update_bill: 'Update Bill',
  close_bill: 'Close Bill',
  this_bill_will_be_closed_permanently:
    'This bill will be closed permanently. You will not be able to make changes to it once its closed.',

  /** Bread Daily View */
  property: 'Property',
  guest_number: 'Guest Number',
  brotchen: 'Brötchen',
  bread_daily_view_details: 'Bread Daily View Details',
  view_bread_daily_view: 'View Bread Daily View',
  download: 'Download',
  download_image: 'Download Image',
  download_driving_order: 'Download Driving Order',

  /** Reservation Request */
  reservation_request_details: 'Reservation Request Details',
  booking_detail: 'Booking Detail',
  reservation_detail: 'Reservation Detail',
  adults: 'Adults',
  customer_detail: 'Customer Detail',
  sur_name: 'Sur Name',
  reservation_details: 'Reservation Details',
  service_line: 'Service Line',
  view_reservation_request_view: 'View Reservation Request',
  dog: 'Dog',
  dog_breed: 'Dog Breed',

  /** Ferry Booking */
  view_ferry_booking_view: 'View Ferry Booking',
  ferry_booking_details: 'Ferry Booking Details',
  booking_amount: 'Booking Amount',
  trip_date: 'Trip Date',
  time: 'Time',
  trip_voyage: 'Trip Voyage',
  departure_harbor: 'Departure Harbor',
  destination_harbor: 'Destination Harbor',
  trip_return_date: 'Trip Return Date',
  vehicle_type: 'Vehicle Type',
  licence_plate: 'Licence Plate',
  length: 'Length',
  customer_email: 'Customer Email',
  trip_detail: 'Trip Detail',
  vehicle_detail: 'Vehicle Detail',
  cm: 'cm',
  ferry_booking_cancel_success: 'Ferry Booking has been cancelled',
  ferry_booking_cancel_failed: 'Ferry Booking cancelled failed.',

  data_synced: 'Data synced.',
  last_v_office_data_sync: 'Last v-office data sync',
  access_reservations: 'Reservations',
  access_bookings: 'Bookings',
  manage_bread_service: 'Manage Bread Service',
  access_bread_driving_order: 'Bread Driving Order',
  access_callbacks: 'Callbacks',
  booking_terminals: 'Booking Terminal',

  access_faq: 'Access FAQ',
  manage_faq: 'Manage FAQ',
  access_faq_internal: 'Access Internal FAQ',
  access_faq_group: 'Access FAQ Group',
  manage_faq_group: 'Manage FAQ Group',

  access_dms_general: 'Access General Document',
  manage_dms_general: 'Manage General Document',
  access_dms_object: 'Access Document Object',
  manage_dms_object: 'Manage Document Object',
  access_dms_customer: 'Access Document Customer',
  manage_dms_customer: 'Manage Document Customer',
  access_dms_owner: 'Access Document Owner',
  manage_dms_owner: 'Manage Document Owner',
  access_dms_user: 'Access Document User',
  manage_dms_user: 'Manage Document User',
  select_date: 'Select Date',
  select_date_time: 'Select Date/Time',

  /*Internet Status*/
  wifi: 'Internet connection was restored.',
  nowifi: 'You are browsing on offline mode.',
  refresh: 'Refresh',

  /*Contact Center*/
  access_contact_center: 'Access Contact Center',

  /*Newsletter*/
  newsletter: 'Newsletter',
  newsletter_contact: 'Newsletter',
  newsletters: 'Newsletters',
  draft: 'Draft',
  drafts: 'Drafts',
  group_of_recipients: 'Group of Recipients',
  all_newsletters: 'All Newsletters',
  new_newsletter: 'New Newsletter',
  subject: 'Subject',
  all_draft: 'All Drafts',
  message: 'Message',
  attach_file: 'Attach File',
  attachments: 'Attachments:',
  preview: 'Preview',
  save_as_draft: 'Save as Draft',
  compose_message: 'Compose Message',
  preview_message: 'Preview Newsletter',
  download_our_app_now: 'Download our app now ',
  contact_with_us: 'Contact with us',
  about_us: 'About Us',
  customer_care: 'Customer Care',
  contact_us: 'Contact Us',
  privacy_policy: 'Privacy Policy',
  subscribe: 'Subscribe',
  unsubscribe: 'Unsubscribe',
  select_recipient_group: 'Select Recipient',
  edit_newsletter: 'Edit Newsletter',
  delete_newsletter: 'Delete Newsletter',
  newsletter_deleted: 'Newsletter deleted.',
  newsletter_delete_error: 'Newsletter delete failed.',
  newsletter_sent_sucessfully: 'Newsletter sent successfully.',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter saved to draft successfully.',
  newsletter_updated_sucessfully: 'Newsletter updated successfully.',
  hello: 'Hello',
  to_send: 'To Send',
  save_and_send: 'Save and Send',
  recipient_group_field_is_required: 'Recipient Group field is required.',
  subject_field_is_required: 'Subject field is required.',
  message_field_is_required: 'Message field is required.',
  send_newsletter: 'Send Newsletter',
  attention_notification_will_be_send_continue:
    'Attention! Notification will be send continue',
  privacy: 'Privacy',
  imprint: 'Imprint',

  /*required*/
  required_first_name: 'First name is required.',
  required_last_name: 'Last name is required.',
  required_email: 'Email is required.',
  required_cellphone: 'Cellphone is required.',
  required_role: 'Role is required.',
  required_address: 'Address is required.',
  required_repate_from: 'Repeat from  is required.',
  required_repeate_until: 'Repeat unit is required.',
  required_interval: 'Recursive interval is required.',
  required_label: 'Label is required.',
  required_type: 'Type is required.',
  required_article: 'Article is required.',
  required_destination: 'Destination is required.',
  required_source: 'Source is required.',
  required_quantity: 'Quantity is required.',
  required_article_name: 'Article name is required.',
  required_unit: 'Unit is required.',
  required_type_name: 'Type name is required.',
  required_unit_name: 'Unit name is required.',
  required_descriptions: 'Descriptions is required.',
  required_document_name: 'Document name is required.',
  required_Object: 'Object is required.',
  required_upload: 'Upload file is required.',
  required_service_name: 'Service name is required.',
  required_voffice_service: 'VOffice service is required.',
  required_service_group: 'Service Group is required.',
  required_cover_image: 'Cover image is required.',
  required_booking_type: 'Booking fee type is required.',
  required_booking_fee: 'Booking fee is required.',
  required_price_per_night: 'Price per night is required.',
  required_icon: 'Upload icon is required.',
  required_group_name: 'Group name is required.',
  required_status: 'Status is required.',
  required_group_recipients: 'Group of recipients is required.',
  required_subject: 'Subject is required.',
  required_message: 'Message is required.',
  required_room: 'Room is required.',
  required_rack: 'Rack is required.',
  required_shelf: 'Shelf is required.',
  required_warehouse_name: 'Warehouse name is required.',
  file_size_is_too_large: 'File size is too large.',
  required_customer: 'Customer is required.',
  required_owner: 'Owner is required.',
  required_user: 'User is required.',
  required_front_color: 'Frontend color is required.',
  only_one_file_is_allowed: 'Only 1 file is allowed.',
  please_fill_in_all_mandatory_fields: 'Please fill in all mandatory fields.',

  /*Message*/
  email_taken: 'The email has already been taken.',

  access_newsletter: 'See Newsletters and drafts',
  manage_newsletter: 'Edit Newsletters and drafts ',

  /*Key*/
  key: 'Key',
  keyscan: 'Keyscan',
  access_keyscan_keylist: 'Access Key List',
  access_keyscan_keylog: 'Access Key Log',
  key_management_system: 'Key Management System',
  key_list: 'Key List',
  key_logs: 'Key Logs',
  key_management: 'Key Management',
  all_key_logs: 'All Key Logs',
  search_customer: 'Search Customer / User',
  key_id: 'Key Id',
  scan_key: 'Scan Key',
  key_handling: 'Key Handling',
  key_information: 'Key Information',
  key_current_status: 'Key Current Status',
  new_status: 'New Status',
  rfid_message: 'RFID Scan Successfully',
  rescan_key: 'Rescan Key',
  key_new_status: 'Key New Status',
  place_rfid: 'Place Key on RFID Reader. ',
  all_key_list: 'All Key List',
  add_key: 'Add Key',
  registered_date: 'Registered Date',
  registered_by: 'Registered By',
  edit_key: 'Edit Key',
  delete_key: 'Delete Key',
  view_key: 'View Key',
  given_out: 'Given Out',
  in_stock: 'In Stock',
  lost: 'Lost',
  key_history: 'Key History',
  key_created_successfully: 'Key Created Successfully',
  key_created_error: 'Key Creation Error',
  key_updated_successfully: 'Key Updated Successfully',
  key_updated_error: 'Key Update Error',
  key_deleted_successfully: 'Key Deleted Successfully',
  key_deleted_error: 'Key Deletion Error',
  rescan: 'Rescan',
  rfid_taken_error: 'RFID Key Already Taken',
  scanner_title_message: 'Please place the tag near the Scanner.',
  scanner_message: 'Key placed correctly?',
  key_no_registered: 'The key has not been registered yet.',
  print_qr: 'Print QR Code',
  key_scanned_successfully: 'Key scanned successfully',
  key_removed_successfully: 'Key removed successfully',
  invalid_object_key: 'Invalid object key',

  /*Aerial Map */
  aerial_map: 'Aerial Map',
  access_aerial_map: 'Access Aerial Map',
  three_sixty_degree: '360°',
  standard: 'Standard',
  map: 'Map',

  /*Language*/
  description_german: 'Description German',
  description_english: 'Description English',
  description_polish: 'Description Polish',
  description_danish: 'Description Danish',
  description_chinese: 'Description Chinese',
  de: 'German',
  en: 'English',
  pl: 'Polish',
  da: 'Danish',
  zh: 'Chinese',
  no_of_print: 'Number of Print',
  article_inactive: 'Article is  not active',
  second_screen_welcome_text: 'Welcome To List On Sylt',
  open_second_screen: 'Open Second Screen',
  show_welcome_screen: 'Show Welcome Screen',
  welcome: 'Welcome',
  booked_service: 'Booked Services',
  people: 'People',
  three_sixty_active: '360° Active',
  can_only_be_enabled_apartment_with_VR:
    'Can only be enabled for object with VR tour.',
  can_only_be_enabled_object_with_video_url:
    'Can only be enabled for object with video url.',
  other_see_description: 'Other (See description)',
  app_vermittl_fam_clausen: 'App.Vermittl.Fam.Clausen',
  select_time: 'Select Time',
  close_all_active_chats: 'Close all active chats',
  confirm_close_all_active_chats:
    'Are you sure you want to close all active chats?',
  successfully_reset_all_active_chats: 'Successfully reset all active chats.',
  responsibility: 'Responsibility',
  person_in_charge: 'Responsible person',
  substitute_person_in_charge: 'Substitute responsible person',
  successfully_updated_responsible_person:
    'Successfully updated responsible person.',
  responsible_person_cannot_be_same:
    'Responsible person and Substitute responsible cannot be identical.',
  select_responsible_person: 'Select Responsible person',
  voffice_contact_detail_page: 'VOffice contact detail page',
  repairs: 'Repairs',
  owner_allowed_repair: 'Owner allowed repairs?',
  inform_owner: 'Inform owner?',
  how_expensive_repairs_can_be_commissioned_without_consulting_with_the_owner:
    'How expensive repairs can be commissioned without consulting with the owner?',
  add_note: 'Add Note',
  write_note_here: 'Write notes here',
  characters: 'Characters',
  maximum_200_character: 'Maximum 200 characters',
  latest_update: 'Latest update',
  updated_by: 'Updated by',
  successfully_added_note: 'Successfully added a note',
  successfully_deleted_note: 'Successfully deleted a note',
  successfully_edited_note: 'Successfully edited a note',
  edit_note: 'Edit Note',
  are_you_sure: 'Are you sure?',
  do_you_really_want_to_delete_this_note_this_cannot_be_undone:
    'Do you really want to delete this note? This cannot be undone',
  in_euro: 'In Euro',
  maximum_notes_range_reached: 'Maximum note range reached',
  object_dashboard: 'Object Dashboard',
  cross_selling: 'Cross Selling',
  all_cross_selling: 'All Cross Selling',
  add_cross_selling: 'Add New Cross Selling',
  edit_cross_selling: 'Edit Cross Selling',
  delete_cross_selling: 'Delete Cross Selling',
  cross_selling_not_found: 'Cross Selling not found',
  successfully_added_cross_selling: 'Successfully added a new cross selling.',
  successfully_deleted_cross_selling: 'Successfully deleted cross selling.',
  successfully_edited_cross_selling: 'Successfully edited cross selling.',
  select_language_description: 'Please select language description',
  saved_email: 'Saved email',
  infos: 'Infos',
  other_objects: 'Other Objects',
  successfully_updated_marketing_email_days:
    'Successfully updated marketing email days.',
  email_date: 'Email date',
  planned: 'Planned',
  classification: 'Classification',
  object_classification: 'Object classification',
  select_tier: 'Select Tier',
  tier: 'Tier',
  failed_to_update_object_tier: 'Failed to update object tier.',
  successfully_updated_object_tier: 'Sccessfully updated object tier.',
  contract_signing_required: 'Contract Signing Required',
  contract_signing_message:
    'It appears the contract has not been signed yet. Please proceed to sign the contract to continue',
  signed: 'Signed',
  contract_signed_successfully: 'Contract Signed Successfully',
  contract_signed_failed: 'Contract Signed Failed',
  //Membership Level
  membership_level: 'Membership Level',
  membership: 'Membership',
  membership_list: 'Membership List',
  list: 'List',
  number_of_booking_in_level: 'Number of booking in level',
  update_number_of_booking_in_level: '{0} number of booking in level',

  published: 'Published',
  internal: 'Internal',
  add_new_member_level: 'Add New Member Level',
  name_membership_level: 'Name Membership Level',
  brief_description: 'Breif Description',
  edit_member_level: 'Edit Member Level',
  required_membership_level: 'Required Membership Level',
  detail_member_level: 'Membership Level Details',
  booking_discount: '% discount on: Booking Fee',
  final_cleaning_discount: '% discount on: Final Cleaning',
  laundry_discount: '% discount on: Laundry fee',
  website_condition_discount:
    'Booking discount (in %) an overnight stay (without extra services) 10 Special conditions on the website',
  membership_delete: 'Membership Level Deleted',
  membership_delete_error: 'Error while deleting membership Level',
  message_update_membership_value: 'Are you sure want to {0}?',
  special_conditions_on_the_website: 'Special conditions on the website:',

  un_publish: 'unpublish',
  mark_as_internal: 'mark as internal',
  remove_from_internal: 'remove from internal',

  //Third Party Partners
  access_third_party_partner: 'Access Third Party Partner',
  manage_third_party_partner: 'Manage Third Party Partner',
  third_party_partner: 'Third Party Partner',
  add_new_third_party_partner: 'Add New Third Party Partner',
  edit_third_party_partner: 'Edit Third Party Partner',
  name_third_party_partner: 'Name Third Party Partner',
  required_third_party_partner: 'Required Third Party Partner',
  detail_third_party_partner: 'Detail Third Party Partner',
  third_party_partner_delete: 'Third Party Partner Deleted',
  third_party_partner_error: 'Error while deleting Third Party Partner',

  //Extra Goodies
  extra_goodies: 'Extra Goodies',
  name_extra_goodies: 'Name Extra Goodies',
  required_extra_goodies: 'Required Extra Goodies',
  extra_goodies_delete: 'Extra Goodies Deleted',
  extra_goodies_error: 'Error while deleting Extra Goodies',
  add_extra_goodies: 'Add Extra Goodie',
  extra_goodies_delete_msg:
    'Do you really want to permanently delete the goodie {0} goodie permanently',

  message_active_inactive: 'Are you sure you want to set the status to {0}?',

  //Restaurant Management
  restaurant: 'Restaurant',
  restaurant_list: 'Restaurant List',
  reservation_count: 'Reservation count',
  add_new_restaurant: 'Add New Restaurant',
  edit_restaurant: 'Edit Restaurant',
  is_top_pick: 'Is Top Pick',
  required_restaurant_name: ' Required Restaurant Name',
  short_description: 'Short description',
  logo: 'Logo',
  zip_code: 'Zip code',
  restaurant_delete: 'Restaurant Deleted Successfully',
  restaurant_error: 'Error while deleting the restaurant',
  restaurant_detail: 'Restaurant details',
  menu: 'Menu',
  opening_time: 'Opening time',
  timing: 'Timing',
  view_menu: 'View menu',
  view_website: 'View website',
  required_street: 'Street is required',
  required_house_number: 'House number is required',
  required_zip_code: 'Zip code is required',
  required_location: 'Location is required',

  message_add_remove_from_top_pick:
    'Are you sure want to {0} the restaurant from top pick?',

  restaurant_reservation: 'Restaurant Reservation',
  restaurant_reservation_list: 'Restaurant Reservation List',
  booking_time: 'Booking time',
  restaurant_name: 'Restaurant Name',
  restaurant_reservation_detail: 'Restaurant Reservation Details',
  no_of_people: 'No of people',
  alternative_booking_date: 'Alternative booking date',
  alternative_booking_time: 'Alternative booking time',
  additional_request: 'Additional Request',

  sylt_go: 'Sylt Go',

  //Meer Sylt
  meer_sylt: 'Meer Sylt Tiles',
  add_meer_sylt: 'Add Meer Sylt Tiles',
  is_load_on_iframe: 'load within an iframe',
  edit_meer_sylt: 'Edit Meer Sylt Tiles',
  delete_meer_sylt: 'Delete Meer Sylt Tiles',
  view_meer_sylt: 'View Meer Sylt Tiles',
  meer_sylt_deleted_successfully: 'Meer Sylt Tiles Deleted Successfully',
  meer_sylt_details: 'Meer Sylt Tiles Details',
  visit_link: 'Visit Link',
  valid_link: 'Please provide a valid link.',
  message_update_with_value: 'Are you sure you  {0}?',
  activate_message: ' want to activate meer sylt tiles',
  deactivate_message: 'want to deactivate meer sylt tiles',
  load_within_iframe_message: 'want to show this within the iframe',
  website_link_message: 'donot want to show this within the iframe',

  link_is_required: 'Link is required',
  meer_sylt_tiles: 'Meer Sylt Tiles',
  meer_sylt_updated_successfully: 'Meer Sylt Tiles Updated Successfully',
  meer_sylt_created_successfully: 'Meer Sylt Tiles Created Successfully',

  failed_to_update: 'Failed to update image details.',
  successfully_updated_image_details: 'Successfully updated image detail',
  update_image_detail: 'Update image detail',
  fileName: 'Filename',
  altTag: 'Alt-tag',
  imageCaption: 'Image Caption',
  filename_already_exists: 'Filename already exists.',

  add_images: 'Add Images',
  successfully_added_images: 'Successfully uploaded an images.',
  failed_to_upload_images: 'Failed to upload images.',
  successfully_deleted_image: 'Successfully deleted an image',
  delete_image: 'Delete Image',
  manage_restaurant: 'Manage Restaurant',
  slug: 'Slug',
  possible: 'Possible',
};

export default en;
