import axios from 'axios';
import { TransferItemSchema, TransferLogQueryParams } from 'constants/schema';
import http from 'utils/http';

/**
 *  Get suggested source warehouse on basis of articleId and quantity
 *
 */

const CancelToken = axios.CancelToken;
let cancel: any;
export function getSuggestedSourceWarehouses(query: {
  article_id: number | string;
  quantity: number;
}) {
  return http({
    url: `/mms/suggest-source-warehouses`,
    method: 'get',
    params: query,
  });
}

/**
 *  Get suggested source users on basis of articleId and quantity
 *
 */
export function getSuggestedSourceUsers(query: {
  article_id: number | string;
  quantity: number;
}) {
  return http({
    url: `/mms/suggest-source-users`,
    method: 'get',
    params: query,
  });
}

/**
 *  Transfer items from target to destination
 *
 */
export function transferItems(data: TransferItemSchema) {
  return http({
    url: `/mms/stock-activities`,
    method: 'post',
    data: data,
  });
}

/**
 * Fetch transfer logs
 *
 */
export function getTransferLogs(query: TransferLogQueryParams) {
  if (cancel) {
    cancel();
  }
  return http({
    url: `/mms/stock-activities`,
    method: 'get',
    params: query,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}
