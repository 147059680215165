import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { InventorySchema } from 'constants/schema';
import React from 'react';
import { BiArrowFromLeft } from 'react-icons/bi';
import { Link as RouterLink, useHistory } from 'react-router-dom';

export interface InventoryListItemProps {
  inventory: InventorySchema;
  index: number;
  userID: number | string;
}

const MyInventoryListItem: React.FC<InventoryListItemProps> = (props) => {
  const { inventory, index, userID } = props;
  const status = inventory?.status;

  const history = useHistory();

  const handleRoute = (): void => {
    status &&
      history.push(
        `${routes.mms.transferItems}?from=${userID}&article_id=${inventory.id}&quantity=${inventory.total_quantity}`
      );
  };

  const getTransferItemLabel = (): string => {
    if (status) {
      return `${strings.transfer} ${strings.item}`;
    }
    return strings.article_inactive;
  };

  return (
    <Tr key={inventory.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.mms.articles.details.replace(
            ':id',
            inventory.id.toString()
          )}>
          <CustomTagTitle
            type="text"
            status={status}
            name={inventory.name || '-'}
          />
        </RouterLink>
      </Td>
      <Td isNumeric>
        <Text as="span" ml="2">
          {inventory.total_quantity}
        </Text>
      </Td>
      <Td>{inventory.unit_name}</Td>
      <Td>
        <Tooltip
          hasArrow
          label={getTransferItemLabel()}
          fontSize="sm"
          placement="top">
          <IconButton
            icon={<BiArrowFromLeft />}
            variant="link"
            aria-label={`${strings.transfer} ${strings.item}`}
            color={status ? 'gray.500' : 'gray.300'}
            minW="6"
            onClick={handleRoute}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
};

export default MyInventoryListItem;
