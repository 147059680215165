import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  DashAccordionButtonStyle,
  DashHeadingStyle,
  ObjectDashboardColumnStyle,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { useDebounce } from 'hooks/useDebounce';
import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import TicketArea from './TicketArea';

interface Props {
  resourceId: number;
  dashboardType: 'customer' | 'object';
}
const TicketDash: React.FC<Props> = (props) => {
  const { dashboardType, resourceId } = props;

  const [search, setSearch] = useState('');
  const keyword = useDebounce(search);

  return (
    <Stack sx={ObjectDashboardColumnStyle}>
      <Heading sx={DashHeadingStyle}>{strings.ticket}</Heading>
      <FormControl>
        <InputGroup>
          <InputRightElement
            pointerEvents="none"
            children={<BiSearch />}
            color="gray.400"
          />
          <Input
            name="keyword"
            placeholder={strings.search}
            value={search}
            type="text"
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </InputGroup>
      </FormControl>
      <Accordion defaultIndex={[0]} allowToggle width="100%">
        <AccordionItem bg="gray.50">
          <AccordionButton sx={DashAccordionButtonStyle}>
            <Box flex="1" textAlign="left">
              <Flex justify="space-between">
                <Text as="h4" fontWeight="medium" color="white">
                  {strings.open}
                </Text>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Stack gridGap="10px">
              <TicketArea
                resourceId={resourceId}
                dashboardType={dashboardType}
                type="open"
                keyword={keyword}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem bg="gray.50">
          <AccordionButton sx={DashAccordionButtonStyle}>
            <Box flex="1" textAlign="left">
              <Flex justify="space-between">
                <Text as="h4" fontWeight="medium" color="white">
                  {strings.inprogress}
                </Text>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Stack gridGap="10px">
              <TicketArea
                resourceId={resourceId}
                dashboardType={dashboardType}
                type="in-progress"
                keyword={keyword}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem bg="gray.50">
          <AccordionButton sx={DashAccordionButtonStyle}>
            <Box flex="1" textAlign="left">
              <Flex justify="space-between">
                <Text as="h4" fontWeight="medium" color="white">
                  {strings.done}
                </Text>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Stack gridGap="10px">
              <TicketArea
                resourceId={resourceId}
                dashboardType={dashboardType}
                type="done"
                keyword={keyword}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem bg="gray.50">
          <AccordionButton sx={DashAccordionButtonStyle}>
            <Box flex="1" textAlign="left">
              <Flex justify="space-between">
                <Text as="h4" fontWeight="medium" color="white">
                  {strings.closed}
                </Text>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Stack gridGap="10px">
              <TicketArea
                resourceId={resourceId}
                dashboardType={dashboardType}
                type="closed"
                keyword={keyword}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default TicketDash;
