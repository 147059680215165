import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import Uploader from 'components/common/Uploader';
import { strings } from 'config/localization';
import { MenuType, OpeningTime, RestaurantSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { determineContentType } from 'utils';

interface Props {
  data?: RestaurantSchema;
}

const RestaurantForm: React.FC<Props> = (props) => {
  const { data } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<any>();

  const opening_times = watch('opening_time');

  useEffect(() => {
    if (!data) return;
    setValue('name', data.name);
    setValue('short_description', data.short_description);
    setValue('description', data.description);
    setValue('is_top_pick', !!data.is_top_pick);
    setValue('website', data.website);
    setValue('zip_code', data.zip_code);
    setValue('street', data.street);
    setValue('house_number', data.house_number);
    setValue('menu', data.menu);
    setValue('location', data.location);
    setValue('opening_time', data.opening_time);
    setValue('openingTimes', JSON.parse(data.opening_time));
    setValue('slug', data.slug);
    setValue('restaurant_reservation', data.restaurant_reservation);
  }, [data, setValue]);

  const { remove } = useFieldArray({
    control: control,
    name: 'openingTimes',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.name, event.target.checked);
  };

  const [option, setOption] = useState('link');
  useEffect(() => {
    if (data?.menu) {
      const contentType = determineContentType(data?.menu);
      setOption(contentType);
    }
  }, [data?.menu]);

  const handleOptionChange = (newValue: any) => {
    setOption(newValue);
  };

  const [openingTimes, setOpeningTimes] = useState<OpeningTime[]>([
    { day: '', timing: '' },
  ]);

  const handleAddFields = () => {
    setOpeningTimes([...openingTimes, { day: '', timing: '' }]);
  };

  const handleRemoveFields = (indexToRemove: number) => {
    const updatedOpeningTimes = openingTimes.filter(
      (_, index) => index !== indexToRemove
    );
    remove(indexToRemove);
    setOpeningTimes(updatedOpeningTimes);
  };

  useEffect(() => {
    if (opening_times) {
      const parsedOpeningTimes = JSON.parse(opening_times) as OpeningTime[];
      setOpeningTimes(parsedOpeningTimes);
    }
  }, [opening_times]);

  return (
    <form>
      <Stack direction="column" spacing="8">
        <Grid
          gap="4"
          gridTemplateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors?.name}>
              <FormLabel>{strings.name}</FormLabel>
              <Input
                {...register('name', {
                  required: strings.required_restaurant_name,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.name}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired isInvalid={!!errors?.slug}>
              <FormLabel>{strings.slug}</FormLabel>
              <Input
                {...register('slug', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.slug}
              />
              {errors?.slug && (
                <FormErrorMessage>{errors.slug.message}</FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          gridTemplateColumns={['repeat(1, 1fr)', 'repeat(3, 2fr)']}
          w="100%">
          <GridItem colSpan={[1, 2]}>
            <FormControl isRequired isInvalid={!!errors.street}>
              <FormLabel>{strings.street}</FormLabel>
              <Input
                {...register('street', {
                  required: strings.required_street,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.street}
              />
              <FormErrorMessage>{errors.street?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isRequired isInvalid={!!errors.house_number}>
              <FormLabel>{strings.house_number}</FormLabel>
              <Input
                {...register('house_number', {
                  required: strings.required_house_number,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.house_number}
              />
              <FormErrorMessage>
                {errors.house_number?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isRequired isInvalid={!!errors.zip_code}>
              <FormLabel>{strings.zip_code}</FormLabel>
              <Input
                {...register('zip_code', {
                  required: strings.required_zip_code,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.zip_code}
              />
              <FormErrorMessage>{errors.zip_code?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={[1, 2]}>
            <FormControl isRequired isInvalid={!!errors.location}>
              <FormLabel>{strings.location}</FormLabel>
              <Input
                {...register('location', {
                  required: strings.required_location,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                type="text"
                placeholder={strings.location}
              />
              <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={[1, 3]}>
            <FormControl isInvalid={!!errors.website}>
              <FormLabel>{strings.website}</FormLabel>
              <Input
                {...register('website')}
                type="text"
                placeholder={strings.website}
              />
              <FormErrorMessage>{errors.website?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid gridTemplateColumns={['repeat(2,1fr)']} alignItems="center">
          <GridItem>
            <FormControl isInvalid={!!errors.restaurant_reservation}>
              <FormLabel
                htmlFor="restaurant_reservation"
                mb="2"
                width="fit-content">
                {strings.possible}
              </FormLabel>
              <Switch
                id="restaurant_reservation"
                {...register('restaurant_reservation')}
                size="lg"
                onChange={handleChange}
                defaultChecked={!!data?.restaurant_reservation}
                colorScheme="primary"
              />
              {errors?.restaurant_reservation && (
                <FormErrorMessage>
                  {errors.restaurant_reservation?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.is_top_pick}>
              <FormLabel htmlFor="is_top_pick" mb="2" width="fit-content">
                {strings.is_top_pick}
              </FormLabel>
              <Switch
                id="is_top_pick"
                {...register('is_top_pick')}
                size="lg"
                onChange={handleChange}
                defaultChecked={!!data?.is_top_pick}
                colorScheme="primary"
              />
              <FormErrorMessage>{errors.is_top_pick?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Heading size="16px"> Opening Time </Heading>
        <Grid gap="4" templateColumns={['repeat(5, 2fr)']}>
          {openingTimes.map((openingTime, index) => (
            <React.Fragment key={index}>
              <GridItem colSpan={2}>
                <FormControl isInvalid={!!errors.opening_time}>
                  <FormLabel>{strings.day}</FormLabel>
                  <Input
                    {...register(`openingTimes[${index}].day`)}
                    type="text"
                    defaultValue={openingTime.day}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>{strings.timing}</FormLabel>
                  <Input
                    type="text"
                    {...register(`openingTimes[${index}].timing`)}
                    defaultValue={openingTime.timing}
                  />
                </FormControl>
              </GridItem>
              {index !== 0 && (
                <GridItem colSpan={1}>
                  <Button
                    colorScheme="red"
                    mt="28px"
                    onClick={() => handleRemoveFields(index)}>
                    <FaTrash />
                  </Button>
                </GridItem>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Button
          colorScheme="green"
          onClick={() => handleAddFields()}
          width="10%">
          <FaPlus />
        </Button>
        <Heading size="16px">{strings.menu}</Heading>
        <Grid gap="4" templateColumns={['repeat(1, 1fr)']} w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors.menu || !!errors.menu_file}>
              <RadioGroup
                defaultValue={MenuType.LINK}
                onChange={handleOptionChange}
                value={option}
                mb="4">
                <Stack spacing={5} direction="row">
                  <Radio value={MenuType.LINK}>{strings.link}</Radio>
                  <Radio value={MenuType.FILE}>{strings.file}</Radio>
                </Stack>
              </RadioGroup>
              {option === MenuType.LINK ? (
                <Input
                  {...register('menu')}
                  type="text"
                  placeholder={strings.menu}
                />
              ) : (
                <ImageUploader
                  uploadedFiles={data?.menu}
                  accept="image/png, image/svg, image/jpg, image/jpeg, application/pdf"
                  fileKey="menu_file"
                  description={strings.upload_file}
                />
              )}
              <FormErrorMessage>
                {errors.menu?.message || errors.menu_file?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors.short_description}>
              <FormLabel>{strings.short_description}</FormLabel>
              <Textarea
                {...register('short_description')}
                placeholder={strings.short_description}
              />
              <FormErrorMessage>
                {errors.short_description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.description}>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description')}
                placeholder={strings.description}
              />
              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Grid gap="4" templateColumns={['repeat(2, 2fr)']} w="100%">
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors?.logo} isRequired>
              <FormLabel>{strings.logo}</FormLabel>
              <ImageUploader
                required={true}
                uploadedFiles={data?.logo}
                accept="image/png, image/svg"
                fileKey="logo"
              />
              <FormErrorMessage>{errors.logo?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors?.cover} isRequired>
              <FormLabel>{strings.cover_image}</FormLabel>
              <ImageUploader
                required={true}
                uploadedFiles={data?.cover}
                accept="image/png, image/svg, image/jpg, image/jpeg"
                fileKey="cover"
              />
              <FormErrorMessage>{errors.cover?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem w="100%">
            <FormControl>
              <FormLabel>{strings.images}</FormLabel>
              <Uploader
                uploadedFiles={data?.images}
                acceptFileType="image/png, image/svg, image/jpg, image/jpeg"
                fileKey="images"
                multiple={true}
                width="100%"
              />
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default RestaurantForm;
