import {
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalOverlay,
  Switch,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import Disable2FA from 'components/user/TwoFactorAuth/Disable2FA';
import Enable2FA from 'components/user/TwoFactorAuth/Enable2FA';
import { strings } from 'config/localization';
import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';

interface Props {
  userInfo: any;
}

const TwoFactorAuthentication: React.FC<Props> = (props) => {
  const { userInfo } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb="0" display="flex">
          {userInfo.has_2fa_enabled ? strings.disable_2FA : strings.enable_2FA}
          <Tooltip
            hasArrow
            label={
              userInfo?.has_2fa_enabled
                ? strings.fa_disable_status_tooltip
                : strings.fa_enable_status_tooltip
            }
            fontWeight="normal"
            bg="white"
            placement="left"
            color="gray.500"
            p={4}>
            <IconButton
              icon={<BiInfoCircle />}
              variant="link"
              aria-label={
                userInfo?.has_2fa_enabled
                  ? strings.fa_disable_status_tooltip
                  : strings.fa_enable_status_tooltip
              }
              color="blue.400"
              ml={1}
              minW="4"
            />
          </Tooltip>
        </FormLabel>
        <Switch
          colorScheme="green"
          onChange={onOpen}
          isChecked={userInfo?.has_2fa_enabled}
        />
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        {!userInfo?.has_2fa_enabled ? (
          <Enable2FA onClose={onClose} />
        ) : (
          <Disable2FA onClose={onClose} />
        )}
      </Modal>
    </>
  );
};

export default TwoFactorAuthentication;
