import Icon from '@chakra-ui/icon';
import { Flex, Text } from '@chakra-ui/layout';
import DocumentDashboardResource from 'api/dms-dashboard';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { DataWrapperSchema, DMSObjectDocumentSchema } from 'constants/schema';
import React from 'react';
import { BiShowAlt } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

interface Props {
  objectId: number;
}

const ObjectDocumentArea: React.FC<Props> = (props) => {
  const { objectId } = props;

  const documentDashboardAPI = new DocumentDashboardResource();

  const { data: objectDocument, isLoading } = useQuery<
    DataWrapperSchema<DMSObjectDocumentSchema[]>
  >({
    queryKey: ['dashboard-object-document', objectId],
    queryFn: async () => {
      const response = await documentDashboardAPI.dashboardApartmentDocuments(
        objectId
      );
      return response?.data;
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <TicketSkeletonLoader rows={3} />;
  }

  if (!objectDocument?.data?.length) {
    return <Text>{strings.no_data_found}</Text>;
  }

  return (
    <>
      {objectDocument.data.map((item) => (
        <Flex
          key={item.id}
          alignItems="center"
          justifyContent="space-between"
          p="10px"
          border="1px solid #EBECF2"
          borderRadius="md">
          <Flex flexDirection="column">
            <Text color="heading">{item.name}</Text>
            <Text color="heading">{item.created_at}</Text>
          </Flex>

          <Link
            target="_blank"
            to={routes.dms.objectDocuments.objects.list.replace(
              ':id',
              objectId.toString()
            )}>
            <Icon as={BiShowAlt} title={strings.view} w="8" h="8" />
          </Link>
        </Flex>
      ))}
    </>
  );
};

export default ObjectDocumentArea;
