import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import BreadDailyViewResource from 'api/bread_daily_view';
import DrivingOrderResource from 'api/driving_order';
import { wrapperStyles } from 'assets/css/commonStyles';
import { ExcelFile } from 'assets/icons';
import BreadDailyViewListItem from 'components/bms_bread_daily_view/BreadDailyViewListItem';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import { BreadDailyViewSchema } from 'constants/schema';
import * as FileSaver from 'file-saver';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  delivery_date: Date;
  keyword: string;
}

const FILEEXTENSION = '.xlsx';

const BreadDailyViewList: React.FC = () => {
  let { search } = useLocation();
  let history = useHistory();
  let searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const delivery_date: any = searchValues.delivery_date
    ? new Date(searchValues.delivery_date)
    : new Date(Date.now());

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
    delivery_date: delivery_date,
    keyword: searchValues.keyword ?? '',
  });
  const breadDailyViewAPI = new BreadDailyViewResource();
  const drivingOrderAPI = new DrivingOrderResource();

  const breadDailyViewList = useQuery(
    [
      'breadDailyViewList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        delivery_date: filterParams.delivery_date,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };

      if (filterParams.delivery_date)
        queryParams.delivery_date = filterParams.delivery_date;
      if (filterParams.keyword) queryParams.keyword = filterParams.keyword;

      const response = await breadDailyViewAPI.list(queryParams);
      return response?.data;
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  const handleKeywordChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };
  const handleDeliveryDateChange = (date: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      delivery_date: date,
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    data.delivery_date = DateFormatYMD(data.delivery_date);

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  /**
   * Handle reset filter params
   */
  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      delivery_date: new Date(),
      keyword: '',
    }));
    history.push(`?delivery_date=${DateFormatYMD(new Date())}`);
  };

  const handleDownload = async (downloadType: string) => {
    let response: any;
    var title: string = '';
    let queryParam = {
      delivery_date: filterParams.delivery_date,
    };
    if (downloadType === 'driving_order') {
      response = await drivingOrderAPI.export(queryParam);
      title =
        `Brötchen_Fahrstrecke_${DateFormat(delivery_date)}` + FILEEXTENSION;
    } else if (downloadType === 'breadDailyView') {
      response = await breadDailyViewAPI.export(queryParam);
      title =
        `Brötchen_Tagesansicht_${DateFormat(delivery_date)}` + FILEEXTENSION;
    }
    if (response.data) {
      let headers = response.headers;
      let blob = new Blob([response.data], { type: headers['content-type'] });
      FileSaver.saveAs(blob, title);
    }
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.bs} | {strings.all_daily_view}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.bs}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.bs.breadDailyView.list}>
              {strings.all_daily_view}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.bread_daily_view}
          </Heading>
          <Stack direction="row" spacing="4" align="end">
            <Tooltip label={strings.download_excel} hasArrow>
              <Button
                size="sm"
                colorScheme="green"
                variant="outline"
                onClick={() => handleDownload('breadDailyView')}>
                <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
              </Button>
            </Tooltip>
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={() => handleDownload('driving_order')}
              size="sm">
              <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
              {strings.download_driving_order}
            </Button>
          </Stack>
        </Flex>

        <Stack sx={wrapperStyles}>
          <form>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="keyword"
                    value={filterParams.keyword}
                    onChange={handleKeywordChange}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.booking_date}</FormLabel>
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<BiCalendar />}
                    color="gray.400"
                  />
                  <ReactDatePicker
                    dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                    placeholderText={strings.select_date}
                    name="delivery_date"
                    value={DateFormatYMD(filterParams.delivery_date)}
                    selected={filterParams.delivery_date}
                    customInput={
                      <CustomDateInput value={filterParams.delivery_date} />
                    }
                    onChange={handleDeliveryDateChange}
                  />
                </InputGroup>
              </FormControl>

              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.customer}</Th>
                  <Th>{strings.property}</Th>
                  <Th>{strings.booking_number}</Th>
                  <Th>{strings.guest_number}</Th>
                  <Th>{strings.brotchen}</Th>
                  <Th>{strings.actions}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!breadDailyViewList.isLoading &&
                  breadDailyViewList?.data?.data?.map(
                    (
                      breadDailyViewData: BreadDailyViewSchema,
                      index: number
                    ) => (
                      <BreadDailyViewListItem
                        breadDailyViewData={breadDailyViewData}
                        key={breadDailyViewData.id}
                        index={startingSN + index}
                        search={search}
                      />
                    )
                  )}
                {breadDailyViewList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={breadDailyViewList}
        />
      </Stack>
    </>
  );
};

export default BreadDailyViewList;
