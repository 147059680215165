import http from 'utils/http';
import Resource from './resource';
class RMSVOfficeResource extends Resource {
  constructor() {
    super(`rms/v-office/apartment`);
  }

  lastSync() {
    return http({
      url: '/rms/v-office/last-sync?type=units',
      method: 'get',
    });
  }
}

export { RMSVOfficeResource as default };
