import Icon from '@chakra-ui/icon';
import { Flex, Text } from '@chakra-ui/layout';
import DocumentDashboardResource from 'api/dms-dashboard';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { DashboardOwnerDocument, DataWrapperSchema } from 'constants/schema';
import React from 'react';
import { BiShowAlt } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
interface Props {
  ownerId: number | null;
}

const OwnerDocumentArea: React.FC<Props> = (props) => {
  const { ownerId } = props;
  const documentDashboardAPI = new DocumentDashboardResource();

  const { data: ownerDocument, isLoading } = useQuery<
    DataWrapperSchema<DashboardOwnerDocument[]>
  >({
    queryKey: ['dashboard-owner-document', ownerId],
    queryFn: async () => {
      if (!ownerId) return;

      const response = await documentDashboardAPI.dashboardOwnerDocument(
        ownerId
      );
      return response?.data;
    },
    enabled: !!ownerId,
    refetchOnWindowFocus: false,
  });

  if (isLoading || !ownerId) {
    return <TicketSkeletonLoader rows={3} />;
  }

  if (!ownerDocument?.data?.length) {
    return <Text>{strings.no_data_found}</Text>;
  }

  return (
    <>
      {ownerDocument.data.map((item) => (
        <Flex
          key={item.id}
          alignItems="center"
          justifyContent="space-between"
          p="10px"
          border="1px solid #EBECF2"
          borderRadius="md">
          <Flex flexDirection="column">
            <Text color="heading">{item.name}</Text>
            <Text color="heading">{item.created_at}</Text>
          </Flex>

          <Link
            target="_blank"
            to={routes.dms.ownerDocuments.owner.list.replace(
              ':id',
              ownerId.toString()
            )}>
            <Icon
              as={BiShowAlt}
              title={strings.view}
              w="8"
              h="8"
              _hover={{ cursor: 'pointer' }}
            />
          </Link>
        </Flex>
      ))}
    </>
  );
};

export default OwnerDocumentArea;
