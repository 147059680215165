import {
  THEME_AERIAL_MAP,
  THEME_BMS,
  THEME_BS,
  THEME_CHAT,
  THEME_CONTACT_CENTER,
  THEME_CRM,
  THEME_DMS,
  THEME_FAQ,
  THEME_FEEDBACK,
  THEME_KEY,
  THEME_MDM,
  THEME_MMS,
  THEME_NEWS,
  THEME_OBJECT_DASHBOARD,
  THEME_OMS,
  THEME_PMS,
  THEME_PNS,
  THEME_RESTAURANT,
  THEME_SMS,
  THEME_THIRD_PARTY_PARTNER,
  THEME_TICKET,
  THEME_USER,
} from 'constants/common';
export const CustomTheme = (pathName: string): string => {
  const path = pathName.split('/');
  switch (path[1]) {
    case 'users':
      return THEME_USER;
    case 'chat':
      return THEME_CHAT;
    case 'ticket':
      return THEME_TICKET;
    case 'crm':
      return THEME_CRM;
    case 'contact-center':
      return THEME_CONTACT_CENTER;
    case 'feedback':
      return THEME_FEEDBACK;
    case 'mms':
      return THEME_MMS;
    case 'mdm':
      return THEME_MDM;
    case 'dms':
      return THEME_DMS;
    case 'oms':
      return THEME_OMS;
    case 'sms':
      return THEME_SMS;
    case 'bms':
      return THEME_BMS;
    case 'bs':
      return THEME_BS;
    case 'pms':
      return THEME_PMS;
    case 'pns':
      return THEME_PNS;
    case 'faq':
      return THEME_FAQ;
    case 'newsletter':
      return THEME_NEWS;
    case 'key':
      return THEME_KEY;
    case 'aerial-map':
      return THEME_AERIAL_MAP;
    case 'object-dashboard':
      return THEME_OBJECT_DASHBOARD;
    case 'third-party-partner':
      return THEME_THIRD_PARTY_PARTNER;
    case 'restaurant':
      return THEME_RESTAURANT;
    default:
      return '';
  }
};
