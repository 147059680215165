import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import NewsletterAPI from 'api/newsletter';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import NewsletterListItem from 'components/newsletter/NewsletterListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  capitalizeFirstLetter,
  changeURL,
  getStartingSerialNumber,
  GroupOfRecipientOptionLocalization,
} from 'utils';
import {
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';
interface FilterParams {
  currentPage: number;
  pageSize: number;
  title: string;
  created_at_start: Date | null;
  created_at_end: Date | null;
  group_of_recipients: string[];
}

const NewsletterList: React.FC = () => {
  const newsletterAPI = new NewsletterAPI();
  const groupOfRecipientRef = useRef<any>(null);
  const groupOfRecipientOptions = GroupOfRecipientOptionLocalization();

  const checkAddNewsletterPermission = useCheckPermission(
    [
      PermissionRequest['read:newsletter'],
      PermissionRequest['manage:newsletter'],
    ],
    routes.newsletter.newsletters.add
  );

  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const createdAtStart = Number(searchValues.created_at_start);
  const createdAtEnd = Number(searchValues.created_at_end);

  const group_of_recipients_url: string[] =
    searchValues?.group_of_recipients?.split(',') ?? [];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    title: searchValues.title ?? '',
    created_at_start: createdAtStart ? new Date(createdAtStart) : null,
    created_at_end: createdAtEnd ? new Date(createdAtEnd) : null,
    group_of_recipients: group_of_recipients_url ?? [],
  });

  const recipient_group_value = filterParams?.group_of_recipients?.map(
    (item: any) => {
      const newLabel = capitalizeFirstLetter(item);
      return {
        value: item,
        label: newLabel,
      };
    }
  );

  const queryList = useQuery(
    [
      'newsletterList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        title: filterParams.title,
        created_at_start: filterParams.created_at_start,
        created_at_end: filterParams.created_at_end,
        recipient_group: filterParams.group_of_recipients,
      },
    ],
    async () => {
      const { title, created_at_start, created_at_end, group_of_recipients } =
        filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        status: 'confirmed',
      };
      if (title) queryParams.keyword = title;
      if (created_at_start)
        queryParams.created_at_start =
          getUTCDateRangeStartDateTime(created_at_start);
      if (created_at_end)
        queryParams.created_at_end = getUTCDateRangeEndDateTime(created_at_end);
      if (group_of_recipients)
        queryParams.recipient_group = group_of_recipients;
      const response = await newsletterAPI.list(queryParams);
      return response?.data;
    }
  );

  const { data: newsLetterQuery, isLoading: newsLetterLoading } = queryList;

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      created_at_start: start,
      created_at_end: end,
    }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  useEffect(() => {
    const data: any = { ...filterParams };
    if (data.created_at_start)
      data.created_at_start = Date.parse(data.created_at_start);
    if (data.created_at_end)
      data.created_at_end = Date.parse(data.created_at_end);

    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleGroupOfRecipientsChange = (recipient: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      group_of_recipients: recipient.map((item: any) => item.value),
    }));
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      title: '',
      created_at_start: null,
      created_at_end: null,
      group_of_recipients: [],
    }));
    groupOfRecipientRef.current.select.clearValue();
    history.push(routes.newsletter.newsletters.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.newsletter} | {strings.all_newsletters}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.newsletter.newsletters.list}>
              {strings.all_newsletters}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.newsletters}
          </Heading>
          <Button
            size="sm"
            colorScheme="primary"
            type="button"
            onClick={checkAddNewsletterPermission}>
            {strings.new_newsletter}
          </Button>
        </Flex>
        <Stack sx={wrapperStyles}>
          <form>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    name="title"
                    value={filterParams.title}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.date}</FormLabel>
                <InputGroup>
                  <InputRightElement
                    children={<BiCalendar />}
                    color="gray.400"
                  />
                  <ReactDatePicker
                    placeholderText={strings.select_date}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomDateInput />}
                    onChange={handleDateChange}
                    selected={filterParams.created_at_start}
                    startDate={filterParams.created_at_start}
                    endDate={filterParams.created_at_end}
                    selectsRange
                    value={getFormattedRangeDate(
                      filterParams.created_at_start,
                      filterParams.created_at_end
                    )}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.group_of_recipients}</FormLabel>
                <ReactSelect
                  name="group_of_recipients"
                  closeMenuOnSelect={false}
                  placeholder={strings.select_recipient_group}
                  ref={groupOfRecipientRef}
                  isMulti
                  options={groupOfRecipientOptions}
                  defaultValue={recipient_group_value}
                  onChange={handleGroupOfRecipientsChange}
                  styles={reactSelectStyles}
                />
              </FormControl>

              <Stack direction="row" spacing="4" pt={['2', '8']}>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th isNumeric>{strings.date}</Th>
                  <Th>{strings.group_of_recipients}</Th>
                  <Th>{strings.subject}</Th>
                  <Th isNumeric>{strings.sent}</Th>
                  <Th>{strings.action}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {newsLetterQuery?.data.map(
                  (newsletterItem: any, index: number) => (
                    <NewsletterListItem
                      newsletterItem={newsletterItem}
                      key={newsletterItem.id}
                      index={startingSN + index}
                      status={newsletterItem.status}
                      search={search}
                    />
                  )
                )}
                {newsLetterLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default NewsletterList;
