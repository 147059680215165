import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import RolesResource from 'api/roles';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import RolePermissionDisplay from 'components/user/RolePermissionDisplay';
import RoleUsers from 'components/user/RoleUsers';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { RoleSchema } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';

const UserRole: React.FC = () => {
  const { id }: any = useParams();
  const rolesApi = new RolesResource();

  const roleQuery = useQuery<RoleSchema>([`userRole${id}`, id], () =>
    rolesApi.get(id).then((res) => res.data.data)
  );

  const rolePermissions = useQuery([`rolePermissions${id}`, id], () =>
    rolesApi.getPermissions(id).then((res: any) => res.data.data)
  );

  const listItemStyles = {
    d: 'flex',
    mb: '6',
  };
  const titleStyles = {
    minW: '40',
    color: 'gray.900',
    fontWeight: 'bold',
  };

  const descriptionStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };
  if (roleQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (roleQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {strings.user} | {roleQuery?.data?.name}
        </title>
      </Helmet>
      <Stack direction="column" spacing="6">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.roles.list}>
              {strings.user_roles}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform={'capitalize'}>
              {roleQuery?.data?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {strings.user_roles}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <Stack p={['3', '6']} flex="1" rounded="sm">
            <List>
              <ListItem sx={listItemStyles}>
                <Text sx={titleStyles}>{strings.name}</Text>

                <Text sx={descriptionStyles} textTransform="capitalize">
                  {strings.getString(
                    roleQuery.data?.name?.split(' ').join('_') || ' '
                  )}
                </Text>
              </ListItem>
              <ListItem sx={listItemStyles}>
                <Text sx={titleStyles}>{strings.description}</Text>
                <Text sx={descriptionStyles}>
                  {roleQuery.data?.description}
                </Text>
              </ListItem>

              <ListItem sx={listItemStyles}>
                <Text sx={titleStyles}>{strings.rights}</Text>
                <RolePermissionDisplay rightsFromRole={rolePermissions.data} />
              </ListItem>
            </List>
          </Stack>
        </Stack>

        <RoleUsers roleId={id} />
      </Stack>
    </>
  );
};

export default UserRole;
