import { List, PopoverArrow, PopoverContent } from '@chakra-ui/react';
import { subMenusComp } from 'constants/schema';
import React from 'react';
import * as SubMenu from './SubMenus';

const hoverMenuStyles = {
  width: '200px',
  borderRadius: 'sm',
  boxShadow: 'md',
  zIndex: 'popover',
};
type FilterSubMenuLinkProps = {
  subMenuType: subMenusComp;
  shouldShowHoverSubMenus: boolean;
  themeColor: string;
};

export const FilterSubMenuLink: React.FC<FilterSubMenuLinkProps> = (props) => {
  const { subMenuType, shouldShowHoverSubMenus, themeColor } = props;
  if (shouldShowHoverSubMenus && subMenuType !== 'null') {
    const CurrentSubMenu = SubMenu[subMenuType];

    return (
      <PopoverContent
        sx={hoverMenuStyles}
        bg={`${themeColor}.400`}
        _groupHover={{ display: 'block' }}>
        <PopoverArrow bg={`${themeColor}.400`} />
        <List p="4" spacing="8" fontSize="xs" color="white">
          <CurrentSubMenu />
        </List>
      </PopoverContent>
    );
  } else {
    return null;
  }
};
