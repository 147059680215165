/**
 * Endpoint (Source/Destination) filter input
 */

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { STOCK_ACTIVITIES_ENTITIES } from 'constants/common';
import { WarehouseSchema } from 'constants/schema';
import useMMSUserAndWarehouseInfo from 'hooks/query-hooks/useMMSUserAndWarehouseInfo';
import React, { useMemo, useState } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import ReactSelect from 'react-select';

const { STOCK_ACTIVITY_ACTIONS, WAREHOUSE_SHELVES, USERS } =
  STOCK_ACTIVITIES_ENTITIES;

const ENDPOINT_TYPE_MAP = {
  source: {
    radioFieldName: 'source_entity_type',
    selectFieldName: 'source_entity_id',
    label: strings.source,
  },
  destination: {
    radioFieldName: 'destination_entity_type',
    selectFieldName: 'destination_entity_id',
    label: strings.destination,
  },
};

function EndpointInput({
  control,
  type,
}: {
  control: Control<any>;
  type: 'source' | 'destination';
}) {
  const {
    formState: { errors },
    setValue,
  } = useFormContext<any>();

  const sourceEntityType = useWatch({
    control,
    name: 'source_entity_type',
  });

  const destinationEntityType = useWatch({
    control,
    name: 'destination_entity_type',
  });

  const entityTypeForWatch =
    type === 'source' ? sourceEntityType : destinationEntityType;

  const [dropdownValue, setDropdownValue] = useState(null);

  const { warehouses, users } = useMMSUserAndWarehouseInfo();

  const selectOptions = useMemo(() => {
    /**
     * Warehouse data comes in deeply nested structure from API
     * warehouse -> room -> rack -> shelf
     * Format data: warehouse/room/rack/shelf
     */
    const getFormattedShelfData = (data: WarehouseSchema[]) => {
      const shelves: any = [];
      data.forEach((warehouse) => {
        warehouse.rooms.forEach((room) => {
          room.racks.forEach((rack) => {
            rack.shelves.forEach((shelf) => {
              shelves.push({
                label: `${warehouse.name}/${room.name}/${rack.name}/${shelf.name}`,
                value: shelf.id,
              });
            });
          });
        });
      });
      return shelves;
    };

    // Warehouse options if warehouse is selected
    const warehouseOptions = warehouses
      ? getFormattedShelfData(warehouses)
      : [];

    const userOptions = users?.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    // Decide which options to display
    switch (entityTypeForWatch) {
      case STOCK_ACTIVITY_ACTIONS:
        return [];
      case '':
        setDropdownValue(null);
        return [];
      case WAREHOUSE_SHELVES:
        return warehouseOptions;
      case USERS:
        return userOptions;
    }
  }, [entityTypeForWatch, warehouses, users]);

  // Source Entity (dropdown) is required when type is `Warehouse` or `Employee`
  const isEntityRequired =
    entityTypeForWatch === WAREHOUSE_SHELVES || entityTypeForWatch === USERS;
  const isInputError =
    type == 'source'
      ? !!errors.source_entity_id
      : !!errors.destination_entity_id;

  const handleRadioChange = (e: string, field: ControllerRenderProps) => {
    if (type === 'source') setValue('source_entity_id', '');
    else if (type === 'destination') setValue('destination_entity_id', '');
    field.onChange(e);
    setDropdownValue(null);
  };

  const handleDropdownChange = (e: any, field: ControllerRenderProps) => {
    field.onChange(e);
    setDropdownValue(e);
  };

  return (
    <FormControl>
      <FormLabel>{ENDPOINT_TYPE_MAP[type].label}</FormLabel>
      <Controller
        name={ENDPOINT_TYPE_MAP[type].radioFieldName}
        control={control}
        render={({ field }) => (
          <RadioGroup
            {...field}
            mb="2"
            onChange={(e) => handleRadioChange(e, field)}>
            <Grid
              columnGap={4}
              templateColumns={[
                'repeat(2, 1fr)',
                'repeat(4, minmax(min-content, max-content))',
              ]}>
              {type === 'source' ? (
                <Radio
                  value={STOCK_ACTIVITY_ACTIONS}
                  isDisabled={destinationEntityType === STOCK_ACTIVITY_ACTIONS}>
                  {strings.fresh}
                </Radio>
              ) : (
                <Radio
                  value={STOCK_ACTIVITY_ACTIONS}
                  isDisabled={sourceEntityType === STOCK_ACTIVITY_ACTIONS}>
                  {strings.consumed}
                </Radio>
              )}
              <Radio value={WAREHOUSE_SHELVES}>{strings.warehouse}</Radio>
              <Radio value={USERS}>{strings.employee}</Radio>
              <Radio value="">{strings.none}</Radio>
            </Grid>
          </RadioGroup>
        )}
      />
      <FormControl isInvalid={isInputError}>
        <Controller
          control={control}
          name={ENDPOINT_TYPE_MAP[type].selectFieldName}
          rules={{
            required: isEntityRequired ? strings.required : false,
          }}
          render={({ field }) => (
            <ReactSelect
              {...field}
              styles={reactSelectStyles}
              value={dropdownValue}
              onChange={(e) => handleDropdownChange(e, field)}
              options={selectOptions}
              isSearchable
              isDisabled={
                entityTypeForWatch === STOCK_ACTIVITY_ACTIONS ||
                entityTypeForWatch === ''
              }
            />
          )}
        />
        <FormErrorMessage>{isInputError && strings.required}</FormErrorMessage>
      </FormControl>
    </FormControl>
  );
}

export default EndpointInput;
