import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import RMSNotesResource from 'api/rms-notes';
import { strings } from 'config/localization';
import { UpdateNoteDataSchema } from 'constants/schema';
import { NotesSchema } from 'constants/schemas/objektSchema';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  objectID: number;
  note?: NotesSchema;
}

interface FormInputs {
  description: string;
}
const NoteForm: React.FC<Props> = (props) => {
  const { isOpen, onClose, objectID, note } = props;
  const noteDescription = note?.description ?? '';
  const toast = useToast();
  const queryClient = useQueryClient();
  const noteAPI = new RMSNotesResource();

  const methods = useForm<FormInputs>({
    defaultValues: {
      description: noteDescription,
    },
  });

  const { register, watch, setValue } = methods;
  const description = watch('description', noteDescription);

  const mutation = useMutation(
    (data: UpdateNoteDataSchema) => {
      if (noteDescription) return noteAPI.update(note?.id, data);
      return noteAPI.store(data);
    },
    {
      onSuccess: () => {
        handelModalClose();
        toast({
          status: 'success',
          title: !noteDescription
            ? strings.successfully_added_note
            : strings.successfully_edited_note,
        });
        onClose();
        queryClient.invalidateQueries(`apartmentDetails-${objectID}`);
      },
      onError: () => {
        toast({
          status: 'error',
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
        });
      },
    }
  );

  const handelModalClose = () => {
    setValue('description', noteDescription);
    onClose();
  };

  const handleNoteSubmit = () => {
    const data: UpdateNoteDataSchema = {
      description,
    };
    if (!noteDescription) data.apartment_id = objectID;
    mutation.mutate(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={handelModalClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="h3" fontSize="3xl">
            {!noteDescription ? strings.add_note : strings.edit_note}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            {...register('description')}
            placeholder={strings.write_note_here}
            size="md"
            isRequired
            maxLength={200}
            autoFocus
          />
          <Box textAlign="right">
            {description?.length}&nbsp;
            {strings.characters}&nbsp;({strings.maximum_200_character})
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="primary"
            mr={3}
            onClick={handleNoteSubmit}
            isLoading={mutation.isLoading}
            isDisabled={!description?.length}>
            {strings.save_now}
          </Button>
          <Button
            variant="outline"
            colorScheme="primary"
            onClick={handelModalClose}>
            {strings.cancel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NoteForm;
