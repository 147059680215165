import { Grid } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import RMSObjectsResource from 'api/rms-objects';
import { strings } from 'config/localization';
import { ImageSchema } from 'constants/schemas/objektSchema';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import ObjektPictureEdit from './ObjektPictureEdit';

export type ObjektImageOrderPayload = {
  old_order: number;
  new_order: number;
};
type Props = {
  images: ImageSchema[];
  isApartmentDetailLoading: boolean;
};

function PicturePanel(props: Props) {
  const { images, isApartmentDetailLoading } = props;
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'bottom',
  });

  const objectAPI = new RMSObjectsResource();
  const queryClient = useQueryClient();

  const params = useParams<{ id: string }>();
  const apartmentId = Number(params.id);

  const [localImages, setLocalImages] = useState<ImageSchema[]>([]);
  const [draggingImage, setDraggingImage] = useState<ImageSchema | null>(null);

  useEffect(() => {
    setLocalImages(images);
  }, [images]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor)
  );

  const updateOrderMutation = useMutation({
    mutationFn: (data: ObjektImageOrderPayload) =>
      objectAPI.updateImageOrder({
        apartmentId,
        data,
      }),
    onSuccess: () => {
      toast({
        status: 'success',
        title: strings.status_changed,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.error_boundary_heading_text,
        description: strings.error_boundary_paragraph_text,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`apartmentDetails-${apartmentId}`);
    },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id === over?.id) return;
    if (!over?.id) return;
    const oldIndex = localImages.findIndex((item) => item.id === active.id);
    const newIndex = localImages.findIndex((item) => item.id === over.id);
    const activeItem = active.data.current as ImageSchema;
    const overItem = over.data.current as ImageSchema;
    setLocalImages((items) => arrayMove(items, oldIndex, newIndex));
    updateOrderMutation.mutate({
      old_order: activeItem.order_column,
      new_order: overItem.order_column,
    });
    setDraggingImage(null);
  };
  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setDraggingImage(active.data.current as ImageSchema);
  }, []);

  const handleDragCancel = useCallback(() => {
    setDraggingImage(null);
  }, []);

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}>
        <SortableContext
          items={localImages.map((img) => img)}
          strategy={rectSortingStrategy}>
          <Grid
            templateColumns={{
              base: 'repeat(1,1fr)',
              md: 'repeat(2,1fr)',
              lg: 'repeat(3,1fr)',
              xl: 'repeat(4,1fr)',
            }}
            gridGap="4"
            gridRowGap="8">
            {localImages.map((picture) => (
              <ObjektPictureEdit
                key={picture.id}
                picture={picture}
                isDisabled={
                  isApartmentDetailLoading || updateOrderMutation.isLoading
                }
              />
            ))}
          </Grid>
        </SortableContext>
        <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
          {draggingImage && <ObjektPictureEdit picture={draggingImage} />}
        </DragOverlay>
      </DndContext>
    </>
  );
}

export default PicturePanel;
