import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import Feedback from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import { PdfBtn } from 'assets/icons';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import CustomTagTitle from 'components/CustomTagTitle';
import FeedbackResponseDetailsPDF from 'components/feedback/FeedbackResponseDetailsPDF';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { getStartingSerialNumber } from 'utils';
import {
  DateFormat,
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  orderBy:
    | 'apartment'
    | 'total_feedback_received'
    | 'total_feedback_received_rating'
    | 'total_score'
    | 'published_score'
    | 'total_review'
    | 'total_apartment_review';
  order: 'asc' | 'desc';
  feedback_at_from: Date | null;
  feedback_at_to: Date | null;
}

const selectBGScheme = (value: number) => {
  if (value < 5) {
    return 'red.50';
  } else if (value >= 5 && value < 8) {
    return 'orange.50';
  } else {
    return 'green.50';
  }
};

const selectColorScheme = (value: number) => {
  if (value < 5) {
    return 'red.400';
  } else if (value >= 5 && value < 8) {
    return 'orange.400';
  } else {
    return 'green.400';
  }
};

const FeedbackDashboard: React.FC = () => {
  const feedbackAPI = new Feedback();
  const printRef: any = useRef();
  const history = useHistory();
  const toast = useToast();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
    orderBy: 'total_score',
    order: 'desc',
    feedback_at_from: null,
    feedback_at_to: null,
  });

  const generalScore = useQuery(['generalScore'], () =>
    feedbackAPI.getGeneralScore().then((res: any) => res.data.data)
  );

  const fetchFeedbackDashboard = async (queryParams: any) => {
    const { keyword, order, orderBy, feedback_at_from, feedback_at_to } =
      filterParams;
    if (keyword) queryParams.keyword = keyword;
    if (orderBy) queryParams.order_by = orderBy;
    if (order) queryParams.order = order;
    if (feedback_at_from)
      queryParams.feedback_at_from =
        getUTCDateRangeStartDateTime(feedback_at_from);
    if (feedback_at_to)
      queryParams.feedback_at_to = getUTCDateRangeEndDateTime(feedback_at_to);

    const res = await feedbackAPI.apartmentScore(queryParams);
    return res.data;
  };

  const queryList = useQuery(
    [
      `apartmentScore`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        orderBy: filterParams.orderBy,
        order: filterParams.order,
        feedback_at_from: filterParams.feedback_at_from,
        feedback_at_to: filterParams.feedback_at_to,
      },
    ],
    () => {
      const initialQueryParams = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      return fetchFeedbackDashboard(initialQueryParams);
    }
  );

  const { data: apartmentScoreData } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      feedback_at_from: start,
      feedback_at_to: end,
    }));
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      feedback_at_from: null,
      feedback_at_to: null,
      keyword: '',
    }));
    history.push(routes.feedback.dashboard);
  };

  const handleTableHeader = (tableHeaderName: any) => {
    setFilterParams((prevState) => {
      if (prevState.orderBy === tableHeaderName) {
        return {
          ...prevState,
          currentPage: INITIAL_CURRENT_PAGE,
          order: prevState.order === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        ...prevState,
        currentPage: INITIAL_CURRENT_PAGE,
        orderBy: tableHeaderName,
        order: 'desc',
      };
    });
  };

  let pdfFilterParamsDate = '';
  if (filterParams?.feedback_at_from || filterParams?.feedback_at_to) {
    pdfFilterParamsDate = `_${DateFormat(
      filterParams?.feedback_at_from
    )}-${DateFormat(filterParams?.feedback_at_to)}`;
  }

  const handlePrintFeedbackResponse = useReactToPrint({
    content: () => printRef.current,
    pageStyle:
      '* {background-color:white !important} @page { padding-left:2px;padding-right:2px;size:landscape !important }',
    documentTitle: `${strings.feedback_response_detail}${pdfFilterParamsDate}`,
  });

  const {
    data: feedbackScoreForPDF,
    isLoading: feedbackScoreForPDFLoading,
    mutate: mutateFeedbackScoreForPDF,
  } = useMutation(
    () => {
      const initialQueryParams: any = {
        page: INITIAL_CURRENT_PAGE,
        limit: 300,
      };
      return fetchFeedbackDashboard(initialQueryParams);
    },
    {
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (feedbackScoreForPDF?.data) {
      handlePrintFeedbackResponse();
    }
    // eslint-disable-next-line
  }, [feedbackScoreForPDF?.data]);

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.feedback} | {strings.dashboard}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.feedback.dashboard}>
            {strings.feedback}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink as={RouterLink} to={routes.feedback.dashboard}>
            {strings.dashboard}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.dashboard}
        </Heading>
        <Tooltip label={strings.download_pdf} hasArrow>
          <Button
            size="sm"
            colorScheme="red"
            variant="outline"
            isLoading={feedbackScoreForPDFLoading}
            onClick={() => mutateFeedbackScoreForPDF()}>
            <Image w="6" src={PdfBtn} alt="PDF" title="PDF" />
          </Button>
        </Tooltip>
      </Flex>
      <div style={{ display: 'none' }}>
        <FeedbackResponseDetailsPDF
          apartmentScoreData={feedbackScoreForPDF}
          ref={printRef}
          filterParams={filterParams}
        />
      </div>
      <Stack direction="column" spacing="4">
        <Heading size="sm" textTransform="capitalize">
          {strings.score_general}
        </Heading>
        <Stack direction="column" spacing="4" bg="white" p="3" shadow="box">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th isNumeric>{strings.score_total}</Th>
                  <Th isNumeric>{strings.answer_rate}</Th>
                  <Th isNumeric>{strings.public_score_calculation}</Th>
                  <Th isNumeric>{strings.real_score}</Th>
                  <Th isNumeric>{strings.public_score}</Th>
                  <Th isNumeric>{strings.detail_reviews_general}</Th>
                  <Th isNumeric>{strings.detail_review_object_page}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {generalScore?.data?.map((item: any, index: number) => (
                  <Tr key={index}>
                    <Td>{startingSN + index}</Td>
                    <Td isNumeric>{item.total_feedback_received || '-'}</Td>
                    <Td isNumeric>
                      {item.answer_ratio.toLocaleString() || '-'}%
                    </Td>
                    <Td isNumeric>
                      {item.total_feedback_received_rating || '-'}
                    </Td>
                    <Td isNumeric>
                      <CustomTagTitle
                        bg={selectBGScheme(Number(item.total_score))}
                        rounded="full"
                        color={selectColorScheme(Number(item.total_score))}
                        fontSize="10px"
                        size="sm"
                        name={Number(item.total_score).toFixed(1)}
                      />
                    </Td>
                    <Td isNumeric>
                      <CustomTagTitle
                        bg={selectBGScheme(Number(item.published_score))}
                        rounded="full"
                        color={selectColorScheme(Number(item.published_score))}
                        fontSize="10px"
                        size="sm"
                        name={Number(item.published_score).toFixed(1)}
                      />
                    </Td>
                    <Td isNumeric>{item.total_review}</Td>
                    <Td isNumeric>{item.total_apartment_review}</Td>
                  </Tr>
                ))}
                {generalScore.isLoading && (
                  <TableSkeletonLoader rows={1} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Stack direction="column" spacing="4">
        <Heading size="sm" textTransform="capitalize">
          {strings.score_objects}
        </Heading>
        <Stack sx={wrapperStyles}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <FormControl>
                <FormLabel>{strings.search}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiSearch />}
                    color="gray.400"
                  />
                  <Input
                    type="text"
                    placeholder={strings.search}
                    name="keyword"
                    value={filterParams.keyword}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>{strings.date}</FormLabel>
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<BiCalendar />}
                    color="gray.400"
                  />
                  <ReactDatePicker
                    dateFormat="yyyy-MM-dd"
                    placeholderText={strings.select_date}
                    customInput={
                      <CustomDateInput _placeholder={{ color: 'black' }} />
                    }
                    onChange={handleDateChange}
                    selected={filterParams.feedback_at_from}
                    startDate={filterParams.feedback_at_from}
                    endDate={filterParams.feedback_at_to}
                    selectsRange
                    value={getFormattedRangeDate(
                      filterParams.feedback_at_from,
                      filterParams.feedback_at_to
                    )}
                    shouldCloseOnSelect={false}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormControl>
              <Stack direction="row" spacing="4">
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>

        <Stack direction="column" spacing="4" bg="white" p="3" shadow="box">
          <TableContainer>
            <Table className="dataTable">
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th
                    className={`sorting ${
                      filterParams.orderBy === 'apartment' &&
                      `sorting_${filterParams.order}`
                    }`}
                    onClick={() => handleTableHeader('apartment')}>
                    {strings.objects}
                  </Th>
                  <Th
                    isNumeric
                    className={`sorting ${
                      filterParams.orderBy === 'total_feedback_received' &&
                      `sorting_${filterParams.order}`
                    }`}
                    onClick={() =>
                      handleTableHeader('total_feedback_received')
                    }>
                    {strings.score_total}
                  </Th>
                  <Th
                    isNumeric
                    className={`sorting ${
                      filterParams.orderBy === 'total_review' &&
                      `sorting_${filterParams.order}`
                    }`}
                    onClick={() => handleTableHeader('total_review')}>
                    {strings.reviews_general}
                  </Th>
                  <Th
                    isNumeric
                    className={`sorting ${
                      filterParams.orderBy === 'total_apartment_review' &&
                      `sorting_${filterParams.order}`
                    }`}
                    onClick={() => handleTableHeader('total_apartment_review')}>
                    {strings.reviews_object_page}
                  </Th>
                  <Th
                    isNumeric
                    className={`sorting ${
                      filterParams.orderBy ===
                        'total_feedback_received_rating' &&
                      `sorting_${filterParams.order}`
                    }`}
                    onClick={() =>
                      handleTableHeader('total_feedback_received_rating')
                    }>
                    {strings.public_score_calculation}
                  </Th>
                  <Th
                    isNumeric
                    className={`sorting ${
                      filterParams.orderBy === 'total_score' &&
                      `sorting_${filterParams.order}`
                    }`}
                    onClick={() => handleTableHeader('total_score')}>
                    {strings.real_score}
                  </Th>
                  <Th
                    isNumeric
                    className={`sorting ${
                      filterParams.orderBy === 'published_score' &&
                      `sorting_${filterParams.order}`
                    }`}
                    onClick={() => handleTableHeader('published_score')}>
                    {strings.public_score}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {apartmentScoreData?.data?.map((item: any, index: number) => (
                  <Tr key={item.apartment_id}>
                    <Td>{startingSN + index}</Td>
                    <Td alignText="left">{item.apartment}</Td>
                    <Td isNumeric>{item.total_feedback_received}</Td>
                    <Td isNumeric>{item.total_review}</Td>
                    <Td isNumeric>{item.total_apartment_review}</Td>
                    <Td isNumeric>{item.total_feedback_received_rating}</Td>
                    <Td isNumeric>
                      <CustomTagTitle
                        bg={selectBGScheme(Number(item.published_score))}
                        rounded="full"
                        color={selectColorScheme(Number(item.published_score))}
                        fontSize="10px"
                        size="sm"
                        name={Number(item.total_score).toFixed(1)}
                      />
                    </Td>
                    <Td isNumeric>
                      <CustomTagTitle
                        bg={selectBGScheme(Number(item.published_score))}
                        rounded="full"
                        color={selectColorScheme(Number(item.published_score))}
                        fontSize="10px"
                        size="sm"
                        name={Number(item.published_score).toFixed(1)}
                      />
                    </Td>
                  </Tr>
                ))}
                {queryList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default FeedbackDashboard;
