import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import {
  BiBuildingHouse,
  BiCalculator,
  BiDesktop,
  BiShow,
  BiTrash,
} from 'react-icons/bi';
import { FiCheckCircle } from 'react-icons/fi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { DateFormat } from 'utils/DateFormat';

interface Props {
  data: any;
  index: number;
  search?: string;
}

const FeedbackListItem: React.FC<Props> = (props) => {
  const { data, index, search } = props;
  const {
    reservation_id: feedbackId,
    reservation,
    feedback_at,
    publish,
    rating_calculation_flag,
    feedback_updated_at,
  } = data;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const status: 'pending' | 'confirmed' | 'send' | 'closed' | 'received' =
    data.status;
  const { customer_info, booking_number, apartment, from_date, to_date } =
    reservation;

  const selectColorScheme = (status: any) => {
    const statusName = status.toLowerCase();
    const statusColorScheme: { [key: string]: string } = {
      pending: 'orange.400',
      confirmed: 'green.400',
      send: 'green.400',
      closed: 'green.400',
      received: 'yellow.400',
    };
    return statusColorScheme[statusName];
  };
  const from_date_DD_MM_YY = DateFormat(from_date);
  const to_date_DD_MM_YY = DateFormat(to_date);
  const feedback_at_DD_MM_YY = DateFormat(feedback_at);

  // Library Init
  const toast = useToast();

  const feedbackAPI = new FeedbackResource();

  const deleteFeedbackAPI = useMutation(
    (id: number) => feedbackAPI.deleteFeedbackMail(id),
    {
      onSuccess: () => {
        setIsDeleteLoading(false);
        setDeleteModalOpen(false);
        toast({
          title: strings.success,
          description: strings.feedback_deleted_successfully,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries('feedbacksList');
      },
      onError: () => {
        setIsDeleteLoading(false);
        setDeleteModalOpen(false);
        toast({
          title: strings.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setIsDeleteLoading(false);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    setIsDeleteLoading(true);
    deleteFeedbackAPI.mutate(feedbackId);
  };

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={
            routes.feedback.feedbacks.details.replace(
              ':id',
              feedbackId.toString()
            ) + search
          }>
          <Text
            fontWeight="medium"
            color="gray.500"
            _hover={{ color: 'gray.600' }}>
            {`${customer_info?.forename} ${customer_info?.surname}`}
          </Text>
        </RouterLink>
      </Td>
      <Td>{booking_number}</Td>
      <Td>{apartment?.name}</Td>
      <Td isNumeric>{`${from_date_DD_MM_YY} - ${to_date_DD_MM_YY}`}</Td>
      <Td isNumeric> {feedback_at_DD_MM_YY}</Td>
      <Td>
        <Text
          display="flex"
          color={selectColorScheme(status) ?? 'blackAlpha.700'}
          fontWeight="medium">
          {strings[status]}&nbsp;
          {status === 'received' && feedback_updated_at && <FiCheckCircle />}
        </Text>
      </Td>
      <Td>
        {publish?.feedback === 1 && (
          <Tooltip
            label={strings.published_on_website}
            aria-label="A tooltip"
            hasArrow>
            <IconButton
              icon={<BiDesktop />}
              variant="link"
              aria-label={strings.published_on_website}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        )}
        {publish?.feedback_apartment === 1 && (
          <Tooltip
            label={strings.published_on_object_detail_page}
            aria-label="A tooltip"
            hasArrow>
            <IconButton
              icon={<BiBuildingHouse />}
              variant="link"
              aria-label={strings.published_on_object_detail_page}
              color="orange.300"
              minW="6"
            />
          </Tooltip>
        )}
        {rating_calculation_flag === 1 && (
          <Tooltip
            label={strings.score_calculation}
            aria-label="A tooltip"
            hasArrow>
            <IconButton
              icon={<BiCalculator />}
              variant="link"
              aria-label={strings.score_calculation}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        )}
      </Td>
      <Td>
        <RouterLink
          to={
            routes.feedback.feedbacks.details.replace(
              ':id',
              feedbackId.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view_feedback}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view_feedback}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={strings.delete_feedback}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={strings.delete_feedback}
            color="red.300"
            minW="6"
            onClick={onDeletePress}
          />
        </Tooltip>
      </Td>
      <Modal
        isOpen={isDeleteModalOpen}
        isCentered
        closeOnOverlayClick={!isDeleteLoading}
        onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.delete_feedback}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.confirm_delete}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                isLoading={isDeleteLoading}
                onClick={onDeleteConfirm}>
                {strings.delete}
              </Button>
              <Button
                variant="outline"
                isDisabled={isDeleteLoading}
                onClick={onDeleteModalClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr>
  );
};

export default FeedbackListItem;
