/**
 * Source New -> Disable Destination Delete and source dropdown
 * Destination Delete -> Disable destination dropdown
 * Destination Warehouse -> Enable Room, Rack and Shelf
 */

import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { setTicketLogInfo } from 'actions/data/ticket';
import ArticlesResource from 'api/articles';
import * as stockActivitiesApi from 'api/stock-activities';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import {
  ARTICLE_SEARCH_API,
  STOCK_ACTIVITIES_ENTITIES,
  STOCK_ACTIVITIES_TRANSFER_SOURCE,
} from 'constants/common';
import routes from 'constants/routes';
import { TransferItemSchema } from 'constants/schema';
import React, { useEffect } from 'react';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ArticleCustomSelect from './ArticleCustomSelect';
import DestinationInput from './DestinationInput';
import DestinationWarehouseInputs from './DestinationWarehouseInputs';
import QuantityUnitDisplay from './QuantityUnitDisplay';
import SourceInput from './SourceInput';

const { STOCK_ACTIVITY_ACTIONS, WAREHOUSE_SHELVES } = STOCK_ACTIVITIES_ENTITIES;

const defaultValues = {
  article_id: null,
  quantity: null,
  source_entity_type: '',
  source_entity_id: null,
  destination_entity_type: '',
  destination_entity_id: null,
  room_id: null,
  rack_id: null,
  shelf_id: null,
};

const defaultParams = {
  status: 1,
};
interface Props {
  formName?: string;
  formID?: any;
}

const TransferItemForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { formName, formID } = props;
  const ticketAPI = new TicketResource();

  const thisLocation = useLocation();
  const query = new URLSearchParams(thisLocation.search);
  let articleId = query.get('article_id');
  let UrlQuantity = query.get('quantity');
  const articlesAPI = new ArticlesResource();

  const methods = useForm<TransferItemSchema>({
    defaultValues,
  });
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setValue,
  } = methods;

  const toast = useToast();
  const queryClient = useQueryClient();
  const location = useLocation();

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  useQuery(
    'fetchArticles',
    () => articlesAPI.get(articleId).then((res) => res?.data),
    {
      onSuccess: (res) => {
        const { articleUnitType, name, id } = res?.data;
        const formatedValue = {
          id,
          name,
          unit: articleUnitType?.name,
        };
        setValue('article_id', formatedValue);
        setValue('source_entity_type', STOCK_ACTIVITIES_ENTITIES.USERS);
      },
      enabled: !!articleId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const handleArticleInputChange = (value: any) => {
    setValue('article_id', value);
  };

  useEffect(() => {
    if (!UrlQuantity) return;
    setValue('quantity', Number(UrlQuantity));
  }, [UrlQuantity, setValue]);

  const transferItemMutation = useMutation(
    async (data: any) => await stockActivitiesApi.transferItems(data),
    {
      onSuccess: () => {
        reset(defaultValues);
        toast({
          title: strings.transfer_item_success,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('fetchTransferLogListLatestFifteen');
      },
      onError: (error: any) => {
        toast({
          title: strings.transfer_item_failed,
          status: 'error',
          description: error?.response?.data?.error?.message,
          isClosable: true,
        });
      },
    }
  );

  /**
   * The output of react-select comes in form: {label: '', value: ''}
   * but we only need its value
   */
  const cleanData = (data: TransferItemSchema) => {
    const cleanedData: any = { ...data };

    cleanedData.article_id = cleanedData.article_id?.id;
    cleanedData.source_entity_id =
      data.source_entity_type === STOCK_ACTIVITY_ACTIONS
        ? 1
        : data.source_entity_id?.value;
    cleanedData.destination_entity_id =
      data.destination_entity_type === STOCK_ACTIVITY_ACTIONS
        ? 2
        : data.destination_entity_id?.value;
    if (data.destination_entity_type === WAREHOUSE_SHELVES)
      cleanedData.destination_entity_id = data.shelf_id?.value;
    // Right now, we can transfer items from two sources only
    cleanedData.action_type =
      location.pathname === routes.mms.transferItems
        ? STOCK_ACTIVITIES_TRANSFER_SOURCE.WEB_UI
        : STOCK_ACTIVITIES_TRANSFER_SOURCE.TICKET;
    const { room_id, rack_id, shelf_id, ...dataToSubmit } = cleanedData;
    return dataToSubmit;
  };

  /**
   * Log when MMS Transfer
   */
  const createTransferLog = useMutation(
    (data: any) => ticketAPI.createTransferLog(Number(formID), data),
    {
      onSuccess: (res: any) => {
        dispatch(setTicketLogInfo(res.data));
      },
    }
  );

  /**
   * if (source_entity_type === 'stock_activity_actions') source_entity_id = 1 -> New/Fresh
   * if (destination_entity_type === 'stock_activity_actions') destination_entity_id = 2 -> Deleted/Consumed
   *
   * if(destination_entity_type === 'warehouse_shelves') destination_entity_id = shelf_id
   *
   */
  const submitTransferItems = async (data: any) => {
    const dataToSubmit = cleanData(data);
    let logData: any;
    if (formName === 'ticket') {
      dataToSubmit.action_id = formID;
      dataToSubmit.action_type = 'Ticket';
      logData = {
        transfer: data,
        updated_by: loggedInUser.id,
      };
    }

    transferItemMutation.mutate(dataToSubmit);
    if (formName === 'ticket') {
      // Also save data in ticket Activities
      createTransferLog.mutate(logData);
    }
  };

  return (
    <Box sx={wrapperStyles}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitTransferItems)}>
          <Stack direction="column" spacing="4">
            <Grid
              gap={['2', '4']}
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
              ]}
              w="100%">
              <GridItem>
                <FormControl isInvalid={!!errors.article_id} mt={2} isRequired>
                  <FormLabel marginBottom="9">
                    {strings.select_articles}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="article_id"
                    rules={{
                      required: strings.required_article,
                    }}
                    render={({ field }) => (
                      <ArticleCustomSelect
                        placeholder={strings.select_articles}
                        value={field?.value}
                        onChange={handleArticleInputChange}
                        parentQueries={defaultParams}
                        SEARCH_API={ARTICLE_SEARCH_API}
                        limit={15}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors?.article_id &&
                      (errors.article_id as FieldError)?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <SourceInput control={control} />
              </GridItem>
              <GridItem>
                <DestinationInput control={control} />
              </GridItem>
              <GridItem>
                <Stack direction="row" spacing="8">
                  <FormControl isInvalid={!!errors.quantity} isRequired>
                    <FormLabel>{strings.quantity}</FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        placeholder={strings.quantity}
                        tabIndex={2}
                        {...register('quantity', {
                          validate: (value) => {
                            // value is required
                            if (!value) return strings.required_quantity;
                            // value should be equal or greater than 1
                            if (value < 1) return strings.valid_data;
                          },
                        })}
                      />
                      <InputRightAddon
                        children={<QuantityUnitDisplay control={control} />}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {errors?.quantity && errors?.quantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </GridItem>
              <GridItem></GridItem>
              <GridItem>
                <DestinationWarehouseInputs control={control} />
              </GridItem>
            </Grid>

            <ButtonGroup>
              <Button
                colorScheme="primary"
                type="submit"
                isLoading={transferItemMutation.isLoading}>
                {strings.confirm}
              </Button>
              <RouterLink to={routes.mms.transferItems}>
                <Button
                  variant="outline"
                  onClick={() => reset(defaultValues)}
                  isDisabled={transferItemMutation.isLoading}>
                  {strings.cancel}
                </Button>
              </RouterLink>
            </ButtonGroup>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default TransferItemForm;
