import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import RMSObjectsResource from 'api/rms-objects';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { ObjektTierIcons, TIER_LIST } from 'constants/common';
import { ObjectClassificationType } from 'constants/schemas/objektSchema';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

interface FormType {
  classification: ObjectClassificationType | undefined;
}

interface Props {
  apartmentId: number;
  classification: ObjectClassificationType | null;
  apartmentDetailKey: string;
}

function ObjektTiers(props: Props) {
  const { apartmentId, classification, apartmentDetailKey } = props;
  const objectAPI = new RMSObjectsResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { control, handleSubmit, watch } = useForm<FormType>({
    defaultValues: {
      classification: classification ?? undefined,
    },
  });

  const currentClassification = watch('classification');

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload: FormType) => objectAPI.update(apartmentId, payload),
    onSuccess: () => {
      toast({
        status: 'success',
        title: strings.successfully_updated_object_tier,
      });
      queryClient.invalidateQueries(apartmentDetailKey);
    },
    onError: () => {
      toast({
        status: 'error',
        title: strings.failed_to_update_object_tier,
      });
    },
  });

  const onSubmit: SubmitHandler<FormType> = (formData) => {
    if (!formData.classification) return;

    mutate(formData);
  };

  return (
    <>
      <Heading size="sm">{strings.classification}</Heading>
      <Stack sx={wrapperStyles}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction={['column', 'row']}
            spacing="4"
            align={['start', 'end']}>
            <FormControl>
              <FormLabel>{strings.object_classification}</FormLabel>
              <Controller
                name="classification"
                control={control}
                render={({ field }) => (
                  <Select {...field} placeholder={strings.select_tier} required>
                    {TIER_LIST.map((item) => (
                      <option key={item.value} value={item.value}>
                        {strings.tier} {item.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {currentClassification && (
              <Image
                src={ObjektTierIcons[currentClassification]}
                w="10"
                h="10"
                alt={currentClassification}
              />
            )}
            <Stack direction="row" spacing="4">
              <Button
                bg="primary.400"
                type="submit"
                colorScheme="primary"
                isLoading={isLoading}
                isDisabled={isLoading || !currentClassification}>
                {strings.save_now}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
}

export default ObjektTiers;
