export const messageSentStyles = {
  alignSelf: 'flex-end',
  textAlign: 'left',
  maxW: 'calc(100% - 80px)',
  direction: 'column',
  bg: 'green.100',
  p: '2',
  borderTopLeftRadius: 'md',
  borderTopRightRadius: '2xl',
  borderBottomRightRadius: 'sm',
  borderBottomLeftRadius: '2xl',

  a: {
    color: 'blue.500',
    textDecoration: 'underline',
    '&:hover': {
      color: 'blue.700',
    },
  },
};

export const messageInfoSentStyles = {
  textAlign: 'center',
  direction: 'column',
  p: '2',
};

export const messageSentDateTimeStyled = {
  alignSelf: 'flex-end',
  textAlign: 'right',
  maxW: 'calc(100% - 80px)',
  direction: 'column',
};
export const messageReceiveStyled = {
  direction: 'column',
  spacing: '1',
  bg: 'gray.100',
  width: 'fit-content',
  maxW: 'calc(100% - 80px)',
  p: '2',
  borderTopLeftRadius: '2xl',
  borderTopRightRadius: 'md',
  borderBottomRightRadius: '2xl',
  borderBottomLeftRadius: 'sm',
};
