import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import MailsListItem from 'components/feedback/MailsListItem';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import routes from 'constants/routes';
import { FeedbackMailSchema } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import {
  getFormattedRangeDate,
  getUTCDateRangeEndDateTime,
  getUTCDateRangeStartDateTime,
} from 'utils/DateFormat';

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
  apartment_id: string;
  status: string;
  last_email_sent_at_from: Date | null;
  last_email_sent_at_to: Date | null;
}

const MailsList: React.FC = () => {
  const feedbackResourceAPI = new FeedbackResource();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();
  const searchValues: any = {};
  for (let pair of searchEntries) searchValues[pair[0]] = pair[1];

  const apartmentList = useQuery([`apartmentList`], () =>
    feedbackResourceAPI
      .getApartmentList({ limit: 300, page: 1 })
      .then((res) => res.data)
  );

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    apartment_id: searchValues.apartment ?? '',
    status: searchValues.status ?? '',
    last_email_sent_at_from: null,
    last_email_sent_at_to: null,
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setFilterParams((prevState) => ({
      ...prevState,
      last_email_sent_at_from: start,
      last_email_sent_at_to: end,
    }));
  };

  const queryList = useQuery(
    [
      `feedbackMailsList`,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        customer_name: filterParams.keyword,
        apartment_id: filterParams.apartment_id,
        status: filterParams.status,
        start_date: filterParams.last_email_sent_at_from,
        end_date: filterParams.last_email_sent_at_to,
      },
    ],
    () => {
      let {
        keyword,
        apartment_id,
        status,
        last_email_sent_at_from,
        last_email_sent_at_to,
      } = filterParams;
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (keyword) queryParams.customer_name = keyword;
      if (apartment_id) queryParams.apartment_id = apartment_id;
      if (last_email_sent_at_from)
        queryParams.last_email_sent_at_from = getUTCDateRangeStartDateTime(
          last_email_sent_at_from
        );
      if (last_email_sent_at_to)
        queryParams.last_email_sent_at_to = getUTCDateRangeEndDateTime(
          last_email_sent_at_to
        );
      if (status) queryParams.status = status;
      return feedbackResourceAPI
        .feedbackMailList(queryParams)
        .then((res) => {
          return res.data;
        })
        .catch((err) => err.response.data);
    }
  );

  const { data: feedbackMailQuery, isLoading: feedbackLoading } = queryList;

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilterParams(() => ({
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      keyword: '',
      apartment_id: '',
      status: '',
      last_email_sent_at_from: null,
      last_email_sent_at_to: null,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.feedback} | {strings.all_mails}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.feedback.feedbacks.list}>
            {strings.feedback}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.all_mails}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.mails}
        </Heading>
      </Flex>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack direction="column" spacing="4">
            <Stack
              direction={['column', 'row']}
              spacing="4"
              align={['start', 'end']}>
              <Grid
                gap={['4', '4', '6']}
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(4, 1fr)',
                ]}
                w="100%">
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.search}</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiSearch />}
                        color="gray.400"
                      />
                      <Input
                        type="text"
                        name="keyword"
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.object}</FormLabel>
                    <Select
                      placeholder={strings.select_apartment}
                      id="apartment_id"
                      name="apartment_id"
                      rounded="sm"
                      value={filterParams.apartment_id}
                      onChange={handleInputChange}>
                      {apartmentList?.data?.data?.map((apartment: any) => {
                        return (
                          <option key={apartment.id} value={apartment.id}>
                            {apartment.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.date}</FormLabel>
                    <InputGroup>
                      <InputRightElement
                        pointerEvents="none"
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                      <ReactDatePicker
                        placeholderText={strings.select_date}
                        dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                        customInput={<CustomDateInput />}
                        onChange={handleDateChange}
                        selected={filterParams.last_email_sent_at_from}
                        startDate={filterParams.last_email_sent_at_from}
                        endDate={filterParams.last_email_sent_at_to}
                        selectsRange
                        value={getFormattedRangeDate(
                          filterParams.last_email_sent_at_from,
                          filterParams.last_email_sent_at_to
                        )}
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.status}</FormLabel>
                    <Select
                      name="status"
                      value={filterParams.status}
                      placeholder={strings.select_status}
                      rounded="sm"
                      onChange={handleInputChange}>
                      <option value="pending">{strings.pending}</option>
                      <option value="sent">{strings.sent}</option>
                      <option value="resent">{strings.resent}</option>
                      <option value="received">{strings.received}</option>
                      <option value="closed">{strings.closed}</option>
                      <option value="no_email">{strings.no_mail}</option>
                    </Select>
                  </FormControl>
                </GridItem>
              </Grid>
            </Stack>
            <Stack direction="row" spacing="4">
              <Button
                colorScheme="primary"
                type="button"
                variant="outline"
                onClick={() => handleReset()}>
                {strings.reset_filter}
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.customer_name}</Th>
                <Th>{strings.booking_number}</Th>
                <Th>{strings.object}</Th>
                <Th isNumeric>{strings.time_period}</Th>
                <Th isNumeric>{strings.date}</Th>
                <Th>{strings.status}</Th>
                <Th>{strings.action}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {feedbackMailQuery?.data?.map(
                (feedbackMailItem: FeedbackMailSchema, index: number) => (
                  <MailsListItem
                    data={feedbackMailItem}
                    key={feedbackMailItem?.reservation_id}
                    index={startingSN + index}
                  />
                )
              )}
              {feedbackLoading && (
                <TableSkeletonLoader rows={filterParams.pageSize} cols={8} />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Pagination
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        dataList={queryList}
      />
    </Stack>
  );
};

export default MailsList;
