import { IconButton, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { selectColorScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiEdit, BiShow } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
interface Props {
  breadDailyViewData: any;
  index: number;
  search: string;
}
const BreadBookingBillingListItem: React.FC<Props> = (props) => {
  const { breadDailyViewData, index, search } = props;

  let { reservation_id, reservation, guest_allocation } = breadDailyViewData;
  let { booking_number, customer, apartment } = reservation;
  let status: 'confirmed' | 'pending' | 'cancelled' = breadDailyViewData.status;

  return (
    <Tr>
      <Td>{index}</Td>
      <Td>{booking_number}</Td>
      <Td>{`${customer?.forename} ${customer?.surname}`}</Td>
      <Td>{apartment?.info?.fullName ?? '-'}</Td>
      <Td>{guest_allocation?.allocation_number ?? '-'}</Td>
      <Td>
        <Text
          color={selectColorScheme(status)}
          textTransform="uppercase"
          fontWeight="medium"
          fontSize="sm">
          {strings[status] ?? '-'}
        </Text>
      </Td>
      <Td>
        <RouterLink
          to={
            routes.bs.breadBookingBilling.details.replace(
              ':id',
              reservation_id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.view}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={strings.view}
              color="green.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
        <RouterLink
          to={
            routes.bs.breadBookingBilling.edit.replace(
              ':id',
              reservation_id.toString()
            ) + search
          }>
          <Tooltip hasArrow label={strings.edit}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={strings.edit}
              color="blue.300"
              minW="6"
            />
          </Tooltip>
        </RouterLink>
      </Td>
    </Tr>
  );
};

export default BreadBookingBillingListItem;
