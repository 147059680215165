import {
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { Booking, Payment } from 'constants/schemas/reservationBooking';
import React, { useEffect, useState } from 'react';
import { formatPrice } from 'utils';
import { enumerateDaysBetweenDates } from 'utils/DateFormat';

interface Props {
  breadBookingData: Booking[];
  paymentData: Payment;
  status: 'pending' | 'confirmed' | 'cancelled';
}

const BreadBookingServiceInfo: React.FC<Props> = (props) => {
  const { breadBookingData, paymentData, status } = props;

  const [subTotal, setSubTotal] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [VAT19, setVAT19] = useState(0);
  const [VAT7, setVAT7] = useState(0);
  const [totalBookingFee, setTotalBookingFee] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    const booking_fee = breadBookingData[0].unit_booking_fee;

    let days = breadBookingData
      .map((booki: any) =>
        enumerateDaysBetweenDates(booki.from_date, booki.till_date)
      )
      .flat();
    let uniqueDays = days.filter(function (item: any, pos: number) {
      return days.indexOf(item) === pos;
    });
    const totalDays = uniqueDays.length;

    let totalBookingFee = booking_fee * totalDays;
    setTotalBookingFee(totalBookingFee);

    // sum of price including all taxes.
    let sum = breadBookingData.reduce(
      (acc: number, cur: { total_price: number }) => acc + cur.total_price,
      0
    );
    setTotalSum(sum);

    const finalTotal =
      breadBookingData.reduce((acc: number, cur: any) => {
        return acc + (cur.total_price || 0);
      }, 0) + totalBookingFee;
    setGrandTotal(finalTotal);

    let amountWithoutTax = sum / 1.26;
    amountWithoutTax = ~~amountWithoutTax; //Bitwise not operator: round to nearest integer
    let vat19 = ~~(amountWithoutTax * 0.19);
    setVAT19(vat19);

    let vat7 = ~~(amountWithoutTax * 0.07);
    setVAT7(vat7);

    let finalSubTotal = sum - vat19 - vat7;
    setSubTotal(finalSubTotal);
  }, [breadBookingData]);

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.service}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th fontWeight="bold" color="blackAlpha.900">
                  {strings.name}
                </Th>
                <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
                  {strings.booking_period}
                </Th>
                <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
                  {strings.price_pound}
                </Th>
                <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
                  {strings.quantity}
                </Th>
                <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
                  {strings.timespan}
                </Th>
                <Th fontWeight="bold" color="blackAlpha.900" isNumeric>
                  {strings.total_pound}
                </Th>
              </Tr>
            </Thead>

            {breadBookingData?.map((bookings: any, index: number) => {
              return (
                <Tbody key={index}>
                  <Tr>
                    <Td>{bookings?.service_name}</Td>
                    <Td isNumeric>{bookings?.booking_period || '-'}</Td>
                    <Td isNumeric>
                      {formatPrice(bookings?.unit_price)} &euro;
                    </Td>
                    <Td isNumeric>{bookings?.quantity}</Td>
                    <Td isNumeric>{bookings?.timespan}</Td>
                    <Td isNumeric>
                      {formatPrice(bookings?.total_price)} &euro;
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
            <Tfoot>
              <Tr>
                <Th
                  fontWeight="bold"
                  color="blackAlpha.900"
                  borderColor="gray"
                  colspan="5"
                  textAlign="right"
                  borderStyle="dashed">
                  {strings.total} (&euro;)
                </Th>
                <Th borderColor="gray" isNumeric borderStyle="dashed">
                  {formatPrice(totalSum)} &euro;
                </Th>
              </Tr>
              <Tr>
                <Th
                  fontWeight="bold"
                  color="blackAlpha.900"
                  borderColor="transparent"
                  colspan="5"
                  textAlign="right">
                  {strings.without_tax} {strings.subtotal} (&euro;)
                </Th>
                <Th borderColor="transparent" isNumeric>
                  {formatPrice(subTotal)} &euro;
                </Th>
              </Tr>
              <Tr>
                <Th
                  fontWeight="bold"
                  color="blackAlpha.900"
                  colspan="5"
                  textAlign="right"
                  borderColor="transparent">
                  {strings.vat19}(&euro;)
                </Th>
                <Th isNumeric borderColor="transparent">
                  {formatPrice(VAT19)} &euro;
                </Th>
              </Tr>
              <Tr>
                <Th
                  fontWeight="bold"
                  color="blackAlpha.900"
                  colspan="5"
                  textAlign="right"
                  borderColor="gray"
                  borderStyle="dashed">
                  {strings.vat7} (&euro;)
                </Th>
                <Th isNumeric borderColor="gray" borderStyle="dashed">
                  {formatPrice(VAT7)} &euro;
                </Th>
              </Tr>
              <Tr>
                <Th
                  fontWeight="bold"
                  color="blackAlpha.900"
                  colspan="5"
                  textAlign="right"
                  borderColor="gray"
                  borderStyle="dashed">
                  {strings.booking_fee_pound}
                </Th>
                <Th isNumeric borderColor="gray" borderStyle="dashed">
                  {formatPrice(totalBookingFee)} &euro;
                </Th>
              </Tr>
              <Tr>
                <Th
                  fontWeight="bold"
                  color="blackAlpha.900"
                  colspan="5"
                  textAlign="right"
                  borderColor="gray">
                  {strings.grand_total} ({strings.euro_symbol})
                </Th>
                <Th isNumeric borderColor="gray">
                  {formatPrice(grandTotal)} &euro;
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};
export default BreadBookingServiceInfo;
