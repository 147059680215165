import { Checkbox, Flex, Icon } from '@chakra-ui/react';
import DocumentDashboardResource from 'api/dms-dashboard';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { BiShowAlt } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

interface Props {
  keyword?: string;
  resourceId: number;
  setSelectedDocs: React.Dispatch<React.SetStateAction<number[]>>;
}
const DocumentArea: React.FC<Props> = (props) => {
  const { keyword, resourceId, setSelectedDocs } = props;

  const dashboardAPI = new DocumentDashboardResource();

  const { data: bookingInfo, isLoading } = useQuery(
    [`dashboard-documents-info`, keyword, resourceId],
    () => {
      return dashboardAPI
        .dashboardCustomerDocuments(resourceId, {
          keyword,
        })
        .then((res) => res.data?.data);
    },
    { refetchOnWindowFocus: false }
  );
  const handleChange = (checked: boolean, id: number) => {
    setSelectedDocs((prev) =>
      checked ? [...prev, id] : prev.filter((docId) => docId !== id)
    );
  };

  if (isLoading) {
    return <TicketSkeletonLoader rows={3} />;
  } else if (!bookingInfo) return null;
  return bookingInfo.map((item: any) => (
    <Flex key={`${item.booking_date} ${item.id}`} gridGap="2">
      <Flex
        key={`${item.booking_date} ${item.id}`}
        width="100%"
        p="10px"
        border="1px solid #EBECF2"
        borderRadius="10px"
        alignItems="center"
        justifyContent="space-between">
        <div>
          <div>{item?.name}</div>
          <div>{item?.created_at}</div>
        </div>
        <Link
          rel="noopener noreferrer"
          target="_blank"
          to={routes.dms.customerDocuments.customer.list.replace(
            ':id',
            resourceId.toString()
          )}>
          <Icon
            as={BiShowAlt}
            title={strings.view}
            w="8"
            h="8"
            _hover={{ cursor: 'pointer' }}
          />
        </Link>
      </Flex>
      <Checkbox
        onChange={(e) => handleChange(e.target.checked, item.id)}></Checkbox>
    </Flex>
  ));
};

export default DocumentArea;
