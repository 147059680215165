import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ThirdPartyPartnerResource from 'api/third-party-partner';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ThirdPartyPartnerForm from 'components/third_party_partner/ThirdPartyPartnerForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import history from 'utils/history';

const AddThirdPartyPartner: React.FC = () => {
  const { id: thirdPartyPartnerId }: any = useParams();
  const { search } = useLocation();

  const methods = useForm<any>();
  const toast = useToast();
  let thirdPartyPartnerAPI = new ThirdPartyPartnerResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: thirdPartyPartnerDetail } = useQuery(
    ['third-party-partner-details-page', thirdPartyPartnerId],
    () => {
      if (!thirdPartyPartnerId) return null; // { data: null };
      return thirdPartyPartnerAPI
        .get(thirdPartyPartnerId)
        .then((res) => res.data.data)
        .catch(() => {
          toast({
            title: strings.error_boundary_heading_text,
            description: strings.error_boundary_paragraph_text,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    },
    {
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const createThirdPartyPartner = useMutation(
    (data: any) => thirdPartyPartnerAPI.store(data),
    {
      onSuccess: (res: any) => {
        const id = res?.data?.data?.id;
        history.push(
          routes.thirdPartyPartner.thirdPartyPartner.details.replace(
            ':id',
            id.toString()
          )
        );
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );
  const updateThirdPartyPartner = useMutation(
    (data: any) => thirdPartyPartnerAPI.update(thirdPartyPartnerId, data),
    {
      onSuccess: () => {
        history.push(
          routes.thirdPartyPartner.thirdPartyPartner.details.replace(
            ':id',
            thirdPartyPartnerId.toString()
          )
        );
      },
      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          description: strings.error_boundary_paragraph_text,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    }
  );

  const onSubmit = async (formData: any) => {
    let API;
    const data = new FormData();

    if (formData.icons && formData.icons?.size > 0) {
      data.append('icon', formData.icons);
    }
    data.append('name', formData.name);
    if (formData.description) data.append('description', formData.description);
    if (formData.brief_description)
      data.append('brief_description', formData.brief_description);
    data.append('is_active', formData.is_active ? String(1) : String(0));

    // Append other form data

    if (thirdPartyPartnerId) {
      API = updateThirdPartyPartner;
      data.append('_method', 'PUT');
    } else {
      API = createThirdPartyPartner;
    }

    setIsLoading(true);
    API.mutate(data, {
      onSuccess: () => {
        toast({
          title: `${strings.third_party_partner} ${
            thirdPartyPartnerId
              ? strings.has_been_updated
              : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
      },
      onError: () => {
        setErrMsg(
          `${strings.third_party_partner} ${
            thirdPartyPartnerId
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  if (thirdPartyPartnerDetail?.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Helmet>
          <title>
            {strings.membership} | &nbsp;
            {thirdPartyPartnerId
              ? strings.edit_third_party_partner
              : strings.add_new_third_party_partner}
          </title>
        </Helmet>
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.bms.booking.list}>
              {strings.membership}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {thirdPartyPartnerId
                ? strings.edit_third_party_partner
                : strings.add_new_third_party_partner}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {thirdPartyPartnerId
              ? strings.edit_third_party_partner
              : strings.add_new_third_party_partner}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {errMsg && (
                  <Alert status="error">
                    <AlertIcon />
                    {errMsg}
                  </Alert>
                )}
                <ThirdPartyPartnerForm data={thirdPartyPartnerDetail} />

                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading}>
                    {!thirdPartyPartnerId ? strings.add_now : strings.update}
                  </Button>
                  <Button
                    variant="outline"
                    isDisabled={isLoading}
                    onClick={() => history.goBack()}>
                    {strings.cancel}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddThirdPartyPartner;
