import { Box, FormControl, FormLabel, GridItem } from '@chakra-ui/react';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
import { DateFormat } from 'utils/DateFormat';
interface Props {
  tripDetail: any;
}

const FerryBookingTripItemInfo: React.FC<Props> = (props) => {
  const { tripDetail } = props;

  return (
    <>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>
            {tripDetail?.trip_part === 'RET'
              ? strings.trip_return_date
              : strings.trip_date}
          </FormLabel>
          <Box sx={infoStyles}>{DateFormat(tripDetail?.date) || '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.time}</FormLabel>
          <Box sx={infoStyles}>{tripDetail?.time || '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.trip_voyage}</FormLabel>
          <Box sx={infoStyles}>{tripDetail?.selected_voyage || '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.departure_harbor}</FormLabel>
          <Box sx={infoStyles}>{tripDetail?.departure_harbor || '-'}</Box>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel sx={labelStyles}>{strings.destination_harbor}</FormLabel>
          <Box sx={infoStyles}>{tripDetail?.destination_harbor || '-'}</Box>
        </FormControl>
      </GridItem>
    </>
  );
};
export default FerryBookingTripItemInfo;
