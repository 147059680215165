import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import {
  MMSSearchFormDefaultValues,
  STOCK_ACTIVITIES_ENTITIES,
  STOCK_ACTIVITIES_TRANSFER_SOURCE,
} from 'constants/common';
import { TransferLogSearchFilterInputs } from 'constants/schema';
import useMMSUserAndWarehouseInfo from 'hooks/query-hooks/useMMSUserAndWarehouseInfo';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';
import { BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { getFormattedRangeDate } from 'utils/DateFormat';
import EndpointInput from './EndpointInput';

const { STOCK_ACTIVITY_ACTIONS } = STOCK_ACTIVITIES_ENTITIES;
const { TICKET, RECONCILED, WEB_UI, APP } = STOCK_ACTIVITIES_TRANSFER_SOURCE;
const TRANSFER_SOURCE = [
  { value: TICKET, name: strings.ticket },
  { value: RECONCILED, name: strings.reconciled },
  { value: WEB_UI, name: strings.web_ui },
  { value: APP, name: strings.app },
];

interface Props {
  handleAdvancedSearch: any;
  isLoading?: string | null;
}

const TransferLogsSerarch: React.FC<Props> = (props) => {
  const { handleAdvancedSearch } = props;
  /**
   * Initialize
   */
  const methods = useForm<TransferLogSearchFilterInputs>({
    defaultValues: MMSSearchFormDefaultValues,
  });
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { users } = useMMSUserAndWarehouseInfo({
    disableWarehouseFetch: true,
  });

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setValue('transferred_from', start);
    setValue('transferred_to', end);
  };

  useEffect(() => {
    /**
     * if (source_entity_type === 'stock_activity_actions') source_entity_id = 1 -> New/Fresh
     * if (destination_entity_type === 'stock_activity_actions') destination_entity_id = 2 -> Deleted/Consumed
     *
     * if(destination_entity_type === 'warehouse_shelves') destination_entity_id = shelf_id
     *
     */
    const cleanData = (data: any) => {
      const formattedData = { ...data };

      if (data.source_entity_type === STOCK_ACTIVITY_ACTIONS) {
        formattedData.source = {
          item: formattedData.source_entity_type,
          id: 1,
        };
      } else if (data.source_entity_type && data.source_entity_id) {
        formattedData.source = {
          item: formattedData.source_entity_type,
          id: formattedData.source_entity_id.value,
        };
      }

      if (data.destination_entity_type === STOCK_ACTIVITY_ACTIONS) {
        formattedData.destination = {
          item: formattedData.destination_entity_type,
          id: 2,
        };
      } else if (data.destination_entity_type && data.destination_entity_id) {
        formattedData.destination = {
          item: formattedData.destination_entity_type,
          id: formattedData.destination_entity_id.value,
        };
      }

      return formattedData;
    };

    const handleAdvancedFilter = async (data: any) => {
      const cleanedData = cleanData(data);
      const {
        source_entity_type,
        source_entity_id,
        destination_entity_type,
        destination_entity_id,
        ...dataToSubmit
      } = cleanedData;
      handleAdvancedSearch(dataToSubmit);
    };

    const subscription = watch((value) => {
      handleAdvancedFilter(value);
    });

    return () => subscription.unsubscribe();
  }, [handleAdvancedSearch, watch]);

  const handleReset = () => {
    methods.reset(MMSSearchFormDefaultValues);
    setStartDate(null);
    setEndDate(null);
    handleAdvancedSearch({});
  };

  return (
    <>
      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form>
            <Stack direction="row" spacing="4">
              <Grid
                gap="4"
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(4, 1fr)',
                ]}
                flex="1">
                <GridItem>
                  <FormControl isInvalid={!!errors.article}>
                    <FormLabel>{strings.article}</FormLabel>
                    <Input
                      type="text"
                      id="article"
                      {...register('article')}
                      placeholder={strings.article}
                    />
                    <FormErrorMessage>
                      {errors?.article && errors?.article?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.transfer_by}</FormLabel>
                    <Select
                      placeholder={strings.select}
                      {...register('transferred_by')}>
                      {users?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.transfer_date}</FormLabel>
                    <InputGroup>
                      <InputRightElement
                        pointerEvents="none"
                        children={<BiCalendar />}
                        color="gray.400"
                      />
                      <ReactDatePicker
                        placeholderText={strings.select_date}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomDateInput />}
                        onChange={handleDateChange}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        value={getFormattedRangeDate(startDate, endDate)}
                        shouldCloseOnSelect={false}
                        autoComplete="off"
                      />
                    </InputGroup>
                  </FormControl>
                </GridItem>

                <GridItem>
                  <FormControl isInvalid={!!errors.transfer_source}>
                    <FormLabel>{strings.transfer_source}</FormLabel>
                    <Select
                      placeholder={strings.select}
                      id="transfer-source"
                      {...register('transfer_source')}>
                      {TRANSFER_SOURCE.map((source) => (
                        <option key={source.value} value={source.value}>
                          {source.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors?.transfer_source &&
                        errors?.transfer_source?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem>
                  <EndpointInput type="source" control={control} />
                </GridItem>

                <GridItem>
                  <EndpointInput type="destination" control={control} />
                </GridItem>
              </Grid>
            </Stack>
            <Stack direction="row" spacing="4" pt="4">
              <Button
                colorScheme="primary"
                onClick={handleReset}
                variant="outline">
                {strings.reset_filter}
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </>
  );
};

export default TransferLogsSerarch;
