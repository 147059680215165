import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  ScaleFade,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  setTwoFA: any;
  isError: boolean;
  setIsError: (error: boolean) => void;
}

const GoogleAuthentication: React.FC<Props> = (props) => {
  const { setTwoFA, isError, setIsError } = props;

  const {
    register,
    formState: { errors },
    formState,
    setValue,
  } = useFormContext<any>();

  const cancel2FAHandler = () => {
    setValue('verifyCode', '');
    setIsError(false);
    setTwoFA(false);
  };

  return (
    <Box bg="white" p="14" shadow="box" rounded="sm">
      <Stack direction="column" spacing="8">
        <Stack direction="column" spacing="4">
          <Heading as="h1" size="xl">
            {strings.two_factor_authentication}
          </Heading>
          <Text color="gray.500">
            {strings.enter_the_pin_from_google_authentication}
          </Text>
          {isError && (
            <ScaleFade in={isError}>
              <Alert status="error">
                <AlertIcon />
                {strings.authenticator_code_error}
              </Alert>
            </ScaleFade>
          )}
        </Stack>

        <FormControl colorScheme="primary" isInvalid={!!errors.verifyCode}>
          <FormLabel>{strings.authenticator_code}</FormLabel>
          <InputGroup>
            <Input
              autoFocus
              type="number"
              placeholder={strings.authenticator_code}
              {...register('verifyCode', {
                required: strings.required_authenticator_code,
                validate: (value) => {
                  if (value?.trim() === '')
                    return strings.required_authenticator_code;
                },
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.verifyCode && errors.verifyCode?.message}
          </FormErrorMessage>
        </FormControl>
        <VStack spacing="6">
          <Button
            w="full"
            type="submit"
            size="lg"
            colorScheme="primary"
            isLoading={formState.isSubmitting}>
            {strings.authenticate}
          </Button>
          <Button
            variant="link"
            color="gray.400"
            onClick={cancel2FAHandler}
            isDisabled={formState.isSubmitting}>
            {strings.cancel}
          </Button>
        </VStack>
      </Stack>
    </Box>
  );
};

export default GoogleAuthentication;
