import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import React from 'react';
interface Props {
  reservationRequestData: any;
  bookingPeriod: string;
  apartmentName: string;
}

const ReservationRequestInfo: React.FC<Props> = (props) => {
  const { reservationRequestData, bookingPeriod, apartmentName } = props;

  return (
    <>
      <Flex justify="space-between">
        <Heading size="md" textTransform="capitalize">
          {strings.reservation_request_details}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
          ]}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.property_name}</FormLabel>
              <Box sx={infoStyles}>{apartmentName || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.booking_period}</FormLabel>
              <Box sx={infoStyles}>{bookingPeriod || '-'}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.units}</FormLabel>
              <Box sx={infoStyles}>{reservationRequestData?.unit || 0}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.adults}</FormLabel>
              <Box sx={infoStyles}>{reservationRequestData?.adults || 0}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.children}</FormLabel>
              <Box sx={infoStyles}>{reservationRequestData?.children || 0}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.babies}</FormLabel>
              <Box sx={infoStyles}>{reservationRequestData?.babys || 0}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.dog}</FormLabel>
              <Box sx={infoStyles}>{reservationRequestData?.dogs || 0}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.dog_breed}</FormLabel>
              <Box sx={infoStyles}>
                {reservationRequestData?.dog_breed || '-'}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid gap="2" templateColumns={['repeat(1, 1fr)']} w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.message}</FormLabel>
              <Box sx={infoStyles}>{reservationRequestData?.notes || '-'}</Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};
export default ReservationRequestInfo;
