import { Td, Tr } from '@chakra-ui/react';
import KeyStatus from 'pages/dashboard/Key/KeyStatus';
import React from 'react';
import { utcToLocal } from 'utils/DateFormat';

interface Props {
  keyLogItem: any;
  index: number;
  search?: string;
}
const KeysListIterm: React.FunctionComponent<Props> = (props) => {
  const { keyLogItem, index, search } = props;
  const {
    id: keyLogItemId,
    key,
    keyId,
    statusTo,
    customer,
    user,
    updatedAt,
    externalCompany,
  } = keyLogItem;

  return (
    <Tr key={keyLogItemId}>
      <Td>{index}</Td>
      <Td>{key?.apartment?.name}</Td>
      <Td>
        <KeyStatus status={statusTo} />
      </Td>
      <Td>{customer?.fullName ?? '-'}</Td>
      <Td>{externalCompany?.name ?? '-'}</Td>
      <Td>{utcToLocal(updatedAt).replace(' ', ', ')}</Td>
      <Td>{user?.fullName}</Td>
      <Td>{key?.rfid}</Td>
    </Tr>
  );
};
export default KeysListIterm;
